import React, { useState } from "react";
import {
  Alert,
  Snackbar,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
  Table,
  IconButton,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  TableCell,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
  Divider,
  LinearProgress,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import GridOnIcon from "@mui/icons-material/GridOn";
import ExtensionIcon from "@mui/icons-material/Extension";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import "./styles.css";
import { useUser } from "../../../context/UserContext";
import { Chessboard } from "react-chessboard";
import { tokens } from "../../../styles/theme";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import {
  updateUserData,
  checkUsernameExists,
} from "../../../features/Firestore"; // Import the new function
import Fade from "@mui/material/Fade";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const [username, setUsername] = useState(userData?.username);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation("Other");

  const showAlert = () => {
    setOpen(true);
  };

  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [selectedThemeColor, setSelectedThemeColor] = useState(
    userData?.theme || "Modern Minimal"
  );
  const [selectedPieceSet, setSelectedPieceSet] = useState(
    userData?.pieceset || "Maestro"
  );

  const handleBoardPreferenceSaveClick = () => {
    userData.theme = selectedThemeColor;
    userData.pieceset = selectedPieceSet;
    userData.username = username || "User";
    updateUserData(userRef, userData);
    showAlert();
  };

  const rows = Object.entries(userData?.Puzzles || {}).map(
    ([puzzleName, puzzleData]) => ({
      puzzleName,
      playCount: puzzleData.Played || 0,
      completedCount: puzzleData.Completed || 0,
      completedPercentage: puzzleData.Played
        ? Math.round((puzzleData.Completed / puzzleData.Played) * 100)
        : 0,
    })
  );

  const [puzzleOrder, setPuzzleOrder] = useState("desc");
  const [puzzleOrderBy, setPuzzleOrderBy] = useState("playCount");

  const handlePuzzleRequestSort = (property) => {
    const isAsc = puzzleOrderBy === property && puzzleOrder === "asc";
    setPuzzleOrder(isAsc ? "desc" : "asc");
    setPuzzleOrderBy(property);
  };

  const getPuzzleComparator = (order, orderBy) => {
    return (a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return b[orderBy] < a[orderBy] ? -1 : 1;
      }
    };
  };

  const sortedRows = rows.sort(getPuzzleComparator(puzzleOrder, puzzleOrderBy));

  const handleThemeColorChange = (event) => {
    setSelectedThemeColor(event.target.value);
  };

  const handlePieceSetChange = (event) => {
    setSelectedPieceSet(event.target.value);
  };

  const handleProfileEditClick = () => {
    setIsEditingProfile(true);
  };

  const handleProfileSaveClick = async () => {
    if (username !== userData.username) {
      const exists = await checkUsernameExists(username);
      if (exists) {
        setErrorMessage(t("Profile.alerts.usernameTaken"));
        return;
      }
    }
    setIsEditingProfile(false);
    handleBoardPreferenceSaveClick();
  };

  const handleProfileDiscardClick = () => {
    setIsEditingProfile(false);
    setErrorMessage("");
  };

  const sortedThemeNames = Object.keys(themeColors).sort();

  return (
    <>
      <ContentHeader
        title={t("Profile.header.title")}
        subtitle={t("Profile.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        showAds={false}
      />
      <Helmet>
        <title>User Profile</title>
        <meta
          name="description"
          content="Edit your profile, preferences, and track your progress and statistics"
        />
        <meta property="og:title" content="Notation Trainer" />
        <meta
          property="og:description"
          content="Edit your profile, preferences, and track your progress and statistics"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/chessboardmagic-social.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/notationtrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Notation Trainer" />
        <meta
          name="twitter:description"
          content="Edit your profile, preferences, and track your progress and statistics"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/chessboardmagic-social.png`}
        />
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleAlert}
        TransitionComponent={Fade}
      >
        <Alert
          onClose={handleAlert}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {t("Profile.alerts.saveSuccessful")}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  avatar={
                    <AccountCircleIcon style={{ color: colors.black[900] }} />
                  }
                  action={
                    <IconButton
                      onClick={handleProfileEditClick}
                      aria-label="settings"
                    >
                      <EditIcon />
                    </IconButton>
                  }
                  title={t("Profile.labels.profileDetails")}
                />
                <Divider />
                <CardContent>
                  <Typography>
                    {t("Profile.labels.email")}: {userData?.email}
                  </Typography>
                  {isEditingProfile ? (
                    <>
                      <TextField
                        label={t("Profile.labels.username")}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                      />
                    </>
                  ) : (
                    <>
                      <Typography>
                        {t("Profile.labels.username")}: {userData?.username}
                      </Typography>
                    </>
                  )}
                </CardContent>
                <CardActions style={{ justifyContent: "flex-end" }}>
                  {isEditingProfile ? (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleProfileSaveClick}
                      >
                        {t("Profile.buttons.save")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleProfileDiscardClick}
                      >
                        {t("Profile.buttons.discard")}
                      </Button>
                    </>
                  ) : null}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  avatar={<GridOnIcon style={{ color: colors.black[900] }} />}
                  title={t("Profile.labels.boardPreference")}
                />
                <Divider />
                <CardContent>
                  <FormControl fullWidth>
                    <Typography>{t("Profile.labels.themeColor")}</Typography>
                    <Select
                      labelId="theme-color-label"
                      id="theme-color-select"
                      value={selectedThemeColor}
                      onChange={handleThemeColorChange}
                      style={{ marginBottom: 16 }}
                    >
                      {sortedThemeNames.map((themeName) => (
                        <MenuItem key={themeName} value={themeName}>
                          {themeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography>
                      {t("Profile.labels.selectPieceSet")}
                    </Typography>
                    <Select
                      labelId="piece-set-label"
                      id="piece-set-select"
                      value={selectedPieceSet}
                      onChange={handlePieceSetChange}
                    >
                      {Object.keys(pieceSets).map((pieceSetName) => (
                        <MenuItem key={pieceSetName} value={pieceSetName}>
                          {pieceSetName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>

                <CardContent>
                  <Chessboard
                    customLightSquareStyle={{
                      backgroundColor:
                        themeColors[selectedThemeColor].lightSquare,
                      backgroundImage:
                        themeColors[selectedThemeColor].lightSquarePattern,
                    }}
                    customDarkSquareStyle={{
                      backgroundColor:
                        themeColors[selectedThemeColor].darkSquare,
                      backgroundImage:
                        themeColors[selectedThemeColor].darkSquarePattern,
                    }}
                    customPieces={pieceSets[selectedPieceSet]}
                    areArrowsAllowed={false}
                  />
                </CardContent>
                <CardActions style={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={handleBoardPreferenceSaveClick}
                  >
                    {t("Profile.buttons.save")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  avatar={
                    <ExtensionIcon style={{ color: colors.black[900] }} />
                  }
                  title={t("Profile.labels.games")}
                />
                <Divider />
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={puzzleOrderBy === "puzzleName"}
                            direction={
                              puzzleOrderBy === "puzzleName"
                                ? puzzleOrder
                                : "asc"
                            }
                            onClick={() =>
                              handlePuzzleRequestSort("puzzleName")
                            }
                          >
                            {t("Profile.labels.puzzleName")}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={puzzleOrderBy === "playCount"}
                            direction={
                              puzzleOrderBy === "playCount"
                                ? puzzleOrder
                                : "asc"
                            }
                            onClick={() => handlePuzzleRequestSort("playCount")}
                          >
                            {t("Profile.labels.playCount")}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={puzzleOrderBy === "completedCount"}
                            direction={
                              puzzleOrderBy === "completedCount"
                                ? puzzleOrder
                                : "asc"
                            }
                            onClick={() =>
                              handlePuzzleRequestSort("completedCount")
                            }
                          >
                            {t("Profile.labels.score")}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={puzzleOrderBy === "completedPercentage"}
                            direction={
                              puzzleOrderBy === "completedPercentage"
                                ? puzzleOrder
                                : "asc"
                            }
                            onClick={() =>
                              handlePuzzleRequestSort("completedPercentage")
                            }
                          >
                            {t("Profile.labels.completedPercentage")}
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.puzzleName}</TableCell>
                          <TableCell>{row.playCount}</TableCell>
                          <TableCell>{row.completedCount}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <LinearProgress
                                variant="determinate"
                                value={row.completedPercentage}
                                sx={{
                                  flex: 1,
                                  height: 20,
                                  marginRight: 1,
                                  backgroundColor: colors.grey[400], // Background color
                                  "& .MuiLinearProgress-bar": {
                                    backgroundColor: colors.purple[500], // Foreground color (progress bar)
                                  },
                                }} // Adjust the styling here
                              />
                              <Typography
                                variant="body2"
                                style={{ width: "20px" }}
                              >
                                {row.completedPercentage}%
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
