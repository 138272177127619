import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChessClockHelp = () => {
  const { t } = useTranslation("Tools");

  return (
    <>
      <Typography>{t("ChessClock.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("ChessClock.helpDetails.instructions.startStop.title")}
            secondary={t(
              "ChessClock.helpDetails.instructions.startStop.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessClock.helpDetails.instructions.switchPlayer.title"
            )}
            secondary={t(
              "ChessClock.helpDetails.instructions.switchPlayer.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("ChessClock.helpDetails.instructions.reset.title")}
            secondary={t(
              "ChessClock.helpDetails.instructions.reset.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "ChessClock.helpDetails.instructions.configureTime.title"
            )}
            secondary={t(
              "ChessClock.helpDetails.instructions.configureTime.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("ChessClock.helpDetails.instructions.editTimes.title")}
            secondary={t(
              "ChessClock.helpDetails.instructions.editTimes.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default ChessClockHelp;
