import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  fetchRepertoires,
  addRepertoire,
  deleteRepertoire,
} from "../../../config/firebaseDB";
import { ref, set } from "firebase/database";
import { rt } from "../../../config/firebase";

import { Helmet } from "react-helmet";

import {
  Alert,
  Box,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Button,
  Divider,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Tab,
  TextField,
  List,
  ListItem,
  ListItemText,
  Switch,
  ListItemSecondaryAction,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  DialogActions,
} from "@mui/material";

// Imported Icons
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import SubjectIcon from "@mui/icons-material/Subject";
import CommentIcon from "@mui/icons-material/Comment";
import EngineIcon from "../../../icons/EngineIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";

import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { themeColors } from "../../../styles/boardtheme";
import { tokens } from "../../../styles/theme";
import { pieceSets } from "../../../styles/pieceset";
import { openings } from "../../../data/eco/eco";
import { v4 as uuidv4 } from "uuid";

/**
 * Arrow Component
 *
 * This component renders a straight arrow on a chessboard from one square to another.
 * The arrow is represented as an SVG `<line>` element with customizable start and end positions,
 * color, and an optional marker (e.g., arrowhead) at the end of the line.
 *
 * Props:
 * - `start` (string): The starting square of the arrow in algebraic notation (e.g., "e2").
 * - `end` (string): The ending square of the arrow in algebraic notation (e.g., "e4").
 * - `color` (string): The color of the arrow's stroke (e.g., "#ff0000" for red).
 * - `markerId` (string): The ID of the SVG marker to use for the arrowhead. This allows you
 *   to dynamically assign an arrowhead style by linking to an `<marker>` defined in your SVG.
 *
 * Internal Functions:
 * - `getSquarePosition(square: string): { x: number, y: number }`
 *   Converts a chessboard square (e.g., "e2") into normalized SVG coordinates.
 *   - `square` (string): A chessboard square in algebraic notation.
 *   - Returns an object with `x` and `y` coordinates normalized to percentages of the board size.
 *
 * Example Usage:
 * ```jsx
 * <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 *   <defs>
 *     <marker
 *       id="arrowhead"
 *       markerWidth="10"
 *       markerHeight="10"
 *       refX="10"
 *       refY="5"
 *       orient="auto"
 *     >
 *       <path d="M0,0 L10,5 L0,10 Z" fill="#ff0000" />
 *     </marker>
 *   </defs>
 *   <Arrow start="e2" end="e4" color="#ff0000" markerId="arrowhead" />
 * </svg>
 * ```
 */
const Arrow = ({ start, end, color, markerId }) => {
  /**
   * Converts a chessboard square (e.g., "e2") into normalized SVG coordinates.
   *
   * @param {string} square - A chessboard square in algebraic notation.
   * @returns {{x: number, y: number}} An object containing normalized `x` and `y` coordinates.
   */
  const getSquarePosition = (square) => {
    const file = square.charCodeAt(0) - 97; // Convert 'a'-'h' to 0-7
    const rank = 8 - parseInt(square[1], 10); // Convert '1'-'8' to 7-0 (invert ranks)
    return { x: (file + 0.5) / 8, y: (rank + 0.5) / 8 }; // Normalize to percentages
  };

  // Calculate normalized start and end positions
  const startPos = getSquarePosition(start);
  const endPos = getSquarePosition(end);

  return (
    <line
      x1={`${startPos.x * 100}%`} // Start x-coordinate in percentage
      y1={`${startPos.y * 100}%`} // Start y-coordinate in percentage
      x2={`${endPos.x * 100}%`} // End x-coordinate in percentage
      y2={`${endPos.y * 100}%`} // End y-coordinate in percentage
      stroke={color} // Set the stroke color
      strokeWidth="2" // Set the stroke width
      markerEnd={`url(#${markerId})`} // Dynamically assign the marker ID for the arrowhead
    />
  );
};

function RepertoireBuilder() {
  /**
   * Application State and Chessboard Management
   *
   * This section initializes and manages various states, hooks, and references
   * used throughout the chess application. It includes theme management, game state,
   * URL parameters, repertoire management, Stockfish engine interactions, and UI elements
   * like menus and annotations.
   */

  // Theme and Color Management
  /**
   * Retrieve the current theme (light or dark) using Material-UI's `useTheme` hook.
   */
  const theme = useTheme();

  /**
   * Get color tokens based on the current theme mode (light or dark).
   */
  const colors = tokens(theme.palette.mode);

  // Game State Management
  /**
   * Initialize a new Chess.js instance to represent the game's state.
   */
  const [game, setGame] = useState(new Chess());

  /**
   * Access user data using a custom `useUser` hook.
   */
  const { userData } = useUser();

  /**
   * Extract the user's unique ID or default to an empty string if unavailable.
   */
  const userId = userData?.uid || "";

  // URL Parameters
  /**
   * Access URL query parameters using React Router's `useSearchParams` hook.
   */
  const [searchParams] = useSearchParams();

  /**
   * Track if the component is being loaded for the first time.
   */
  const isInitialLoad = useRef(true);

  /**
   * Enable or disable debug mode based on the presence of a `debug=true` query parameter.
   */
  const debugMode = searchParams.get("debug") === "true";

  /**
   * Control the visibility of debug mode elements in the UI.
   */
  const [showDebugMode, setShowDebugMode] = useState(true);

  // DOM References
  /**
   * Reference to the chessboard DOM element for direct manipulation.
   */
  const chessboardRef = useRef(null);

  // Repertoire Management States
  /**
   * Store all user repertoires as an object with unique IDs as keys.
   */
  const [repertoires, setRepertoires] = useState({});

  /**
   * Track the currently selected repertoire by its unique ID.
   */
  const [selectedRepertoireId, setSelectedRepertoireId] = useState(null);

  /**
   * Track whether the user is confirming a repertoire deletion.
   */
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  /**
   * Store the input value for naming new repertoires.
   */
  const [inputRepertoireName, setInputRepertoireName] = useState("");

  /**
   * Manage error states, such as invalid inputs or failed operations.
   */
  const [showError, setShowError] = useState(false);

  /**
   * Control whether the repertoire modal is open.
   */
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Title of the current chapter or repertoire.
   */
  const [chapterTitle, setChapterTitle] = useState("Repertoire");

  /**
   * Description of the current chapter or repertoire.
   */
  const [chapterDescription, setChapterDescription] = useState("");

  /**
   * Author of the repertoire or chapter.
   */
  const [author, setAuthor] = useState("");

  /**
   * Visibility setting of the repertoire (e.g., "Public" or "Private").
   */
  const [visibility, setVisibility] = useState("Private");

  /**
   * Control whether copying of the repertoire is allowed.
   */
  const [allowCopy, setAllowCopy] = useState("No");

  /**
   * Set the board orientation (e.g., "white" or "black").
   */
  const [boardOrientation, setBoardOrientation] = useState("white");

  // Stockfish and Evaluation Management
  /**
   * Toggle the Stockfish engine on or off.
   */
  const [engineOn, setEngineOn] = useState(false);

  /**
   * Store the Stockfish Web Worker instance for engine analysis.
   */
  const [stockfish, setStockfish] = useState(null);

  /**
   * Abort controller to handle evaluation cancellations.
   */
  const evaluationAbortController = useRef(null);

  /**
   * Number of variations (multiPV) to analyze with Stockfish.
   */
  const [multiPV, setMultiPV] = useState(5);

  /**
   * Depth for Stockfish analysis.
   */
  const [depth, setDepth] = useState(15);

  /**
   * Store the current analysis lines returned by Stockfish.
   */
  const [currentLines, setCurrentLines] = useState([]);

  // Move and Node Management
  /**
   * Represent the move tree with a root node structure.
   */
  const [lines, setLines] = useState({
    id: "root", // Root node ID
    san: "", // Standard algebraic notation of the move
    next: null, // Pointer to the next move
  });

  /**
   * Track the currently selected node in the move tree.
   */
  const [selectedNode, setSelectedNode] = useState(lines);

  /**
   * Path to the current node in the move tree.
   */
  const [nodePath, setNodePath] = useState(["root"]);

  /**
   * Track the move currently selected by the user.
   */
  const [selectedMove, setSelectedMove] = useState(null);

  /**
   * Track the current tab value in the UI.
   */
  const [tabValue, setTabValue] = useState(0);

  /**
   * User's comment on the currently selected move.
   */
  const [comment, setComment] = useState("");

  /**
   * Toggle the visibility of comments in the UI.
   */
  const [showComments, setShowComments] = useState(true);

  /**
   * Toggle comments visibility when the user interacts with the UI.
   */
  const handleShowCommentsChange = () => {
    setShowComments((prevShowComments) => !prevShowComments);
  };

  /**
   * User's annotation on the currently selected move.
   */
  const [annotation, setAnnotation] = useState("");

  /**
   * Toggle the visibility of annotations in the UI.
   */
  const [showAnnotations, setShowAnnotations] = useState(true);

  /**
   * Toggle annotations visibility when the user interacts with the UI.
   */
  const handleShowAnnotationChange = () => {
    setShowAnnotations((prevShowAnnotations) => !prevShowAnnotations);
  };

  // Drag and Drop
  /**
   * Track the starting square for drag-and-drop actions.
   */
  const dragStartRef = useRef(null);

  // Arrows and Highlights
  /**
   * Store arrows drawn on the chessboard.
   */
  const [arrows, setArrows] = useState([]);

  /**
   * Store square highlights on the chessboard.
   */
  const [highlights, setHighlights] = useState([]);

  // Opening Details
  /**
   * Store details of the current opening, such as its name and ECO code.
   */
  const [openingDetails, setOpeningDetails] = useState(null);

  // Menu Management
  /**
   * Reference for interactions with the menu.
   */
  const menuRef = useRef(null);

  /**
   * Anchor element for the lines menu.
   */
  const [linesMenuAnchor, setLinesMenuAnchor] = useState(null);

  /**
   * Anchor element for the move menu.
   */
  const [moveMenuAnchor, setMoveMenuAnchor] = useState(null);

  /**
   * `categories` is a memoized object that contains predefined categories of annotations
   * used for analyzing chess moves, positions, and observations. Each category includes
   * symbols, descriptions, and associated colors for better visualization.
   *
   * The object is memoized using React's `useMemo` to avoid unnecessary re-creation
   * unless dependencies (in this case, none) change.
   */
  const categories = useMemo(
    () => ({
      move: [
        { symbol: "!", description: "Good Move", color: "#1b5e20" },
        { symbol: "?", description: "Mistake", color: "#ffa726" },
        { symbol: "!!", description: "Brilliant Move", color: "#009688" },
        { symbol: "??", description: "Blunder", color: "#d50000" },
        { symbol: "!?", description: "Interesting Move", color: "#ec407a" },
        { symbol: "?!", description: "Dubious Move", color: "#2196f3" },
        { symbol: "□", description: "Only Move", color: "#7986cb" },
        { symbol: "⨀", description: "Zugzwang", color: "#6200ea" },
      ],
      position: [
        { symbol: "=", description: "Equal Position", color: "#0d47a1" },
        { symbol: "∞", description: "Unclear Position", color: "#0d47a1" },
        {
          symbol: "⩲",
          description: "White is Slightly Better",
          color: "#0d47a1",
        },
        {
          symbol: "⩱",
          description: "Black is Slightly Better",
          color: "#0d47a1",
        },
        { symbol: "±", description: "White is Better", color: "#0d47a1" },
        { symbol: "∓", description: "Black is Better", color: "#0d47a1" },
        { symbol: "+−", description: "White is Winning", color: "#0d47a1" },
        { symbol: "-+", description: "Black is Winning", color: "#0d47a1" },
      ],
      observation: [
        { symbol: "N", description: "Novelty", color: "#0d47a1" },
        { symbol: "↑↑", description: "Development", color: "#0d47a1" },
        { symbol: "↑", description: "Initiative", color: "#0d47a1" },
        { symbol: "→", description: "Attack", color: "#0d47a1" },
        { symbol: "⇆", description: "Counterplay", color: "#0d47a1" },
        { symbol: "⊕", description: "Time Trouble", color: "#0d47a1" },
        { symbol: "=∞", description: "With Compensation", color: "#0d47a1" },
        { symbol: "∆", description: "With the Idea", color: "#0d47a1" },
      ],
    }),
    [] // Dependencies array: the categories object will not change
  );

  // Functionality for the Chessboard and Lines Section

  /**
   * Opens the context menu for the lines section.
   *
   * @param {Event} event - The event object triggered by the menu open action.
   */
  const handleLinesMenuOpen = (event) => {
    setLinesMenuAnchor(event.currentTarget); // Set the anchor element for the lines menu
  };

  /**
   * Closes the context menu for the lines section.
   */
  const handleLinesMenuClose = () => {
    setLinesMenuAnchor(null); // Clear the anchor element, effectively closing the menu
  };

  /**
   * Opens the context menu for a specific move in the lines section.
   *
   * @param {Event} event - The event object triggered by the menu open action.
   * @param {Object} move - The move object representing the selected move.
   */
  const handleMoveMenuOpen = (event, move) => {
    event.preventDefault(); // Prevent the default right-click behavior (e.g., browser's context menu)
    setMoveMenuAnchor(event.currentTarget); // Set the anchor element for the move menu
    setSelectedMove(move); // Track the currently selected move
  };

  /**
   * Closes the context menu for a specific move in the lines section.
   */
  const handleMoveMenuClose = () => {
    setMoveMenuAnchor(null); // Clear the anchor element, effectively closing the menu
    setSelectedMove(null); // Reset the selected move to null
  };

  /**
   * Handles changes to the active tab in the UI.
   *
   * @param {Event} event - The event object triggered by the tab change action.
   * @param {number} newValue - The index of the newly selected tab.
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update the state to reflect the new active tab
  };

  /**
   * Recursively renders chess moves, annotations, comments, and variations in a tree structure.
   *
   * @param {Object} node - The current node in the move tree.
   * @param {number} depth - The current depth of the node in the tree (used for indentation).
   * @param {boolean} isVariation - Whether the node is part of a variation.
   * @param {boolean} isFirstMoveOfVariation - Whether the node is the first move in a variation.
   * @returns {Array} An array of JSX elements representing the moves, comments, and variations.
   */
  const displayMoves = (
    node,
    depth = 0,
    isVariation = false,
    isFirstMoveOfVariation = true
  ) => {
    // Return null if the node is invalid or not the root
    if (!node || (!node.san && node.id !== "root")) return null;

    const elements = []; // Store JSX elements for rendering

    /**
     * Adds a move element to the list of elements.
     *
     * @param {Object} node - The node representing the move.
     * @param {string} movePrefix - The prefix for the move (e.g., "1.", "1..").
     */
    const addMoveElement = (node, movePrefix) => {
      elements.push(
        <span
          key={node.id}
          style={{
            paddingRight: "5px",
            display: "inline",
            whiteSpace: "pre-wrap",
            cursor: "pointer",
            fontWeight: selectedNode?.id === node.id ? "bold" : null,
            textDecoration: selectedNode?.id === node.id ? "underline" : null,
            color: showAnnotations
              ? getCategoryColorBySymbol(node.annotation) // Annotation color
              : "white", // Default color
          }}
          onClick={() => handleMoveClick(node)} // Left-click to select the move
          onContextMenu={(event) => {
            event.preventDefault(); // Prevent default right-click behavior
            handleMoveClick(node); // Select the move
            handleMoveMenuOpen(event, node); // Open the move menu
            handleRightClick(event, node); // Handle additional right-click behavior
          }}
        >
          {`${movePrefix}${node.san}${
            node.annotation && showAnnotations ? ` ${node.annotation}` : ""
          }`}
        </span>
      );

      // Add a comment if the node has one and comments are enabled
      if (node.comment && showComments) {
        elements.push(
          <span
            key={`${node.id}-comment`}
            style={{
              paddingLeft: "4px",
              paddingRight: "8px",
              color: "gray",
              fontSize: "16px",
            }}
          >
            {`${node.comment}`}
          </span>
        );
      }
    };

    // Add the current move to the elements
    if (node.san) {
      let movePrefix = "";

      if (node.moveNumber) {
        if (
          node.moveNumber % 2 === 0 &&
          isVariation &&
          isFirstMoveOfVariation
        ) {
          // Black's first move in a variation
          movePrefix = `${Math.ceil(node.moveNumber / 2)}.. `;
        } else if (node.moveNumber % 2 !== 0) {
          // White's move
          movePrefix = `${Math.ceil(node.moveNumber / 2)}. `;
        }
      }

      addMoveElement(node, movePrefix);
    }

    // Handle variations
    if (Array.isArray(node.variations) && node.variations.length > 0) {
      node.variations.forEach((variation) => {
        elements.push(
          <div
            key={`variation-${variation.id}`}
            style={{
              marginLeft: "15px", // Indent variations for better readability
              whiteSpace: "pre-wrap",
            }}
          >
            {displayMoves(variation, depth + 1, true, true)}
          </div>
        );
      });
    }

    // Recursively add mainline moves
    if (node.next) {
      elements.push(displayMoves(node.next, depth, isVariation, false));
    }

    return elements;
  };

  /**
   * Finds a node in the move tree by its ID.
   *
   * @param {Object} node - The current node in the move tree.
   * @param {string} id - The ID of the node to find.
   * @returns {Object|null} The node with the matching ID, or null if not found.
   */
  const findNodeById = useCallback((node, id) => {
    if (!node) return null;

    if (node.id === id) return node;

    // Search in the mainline moves
    if (node.next) {
      const foundInNext = findNodeById(node.next, id);
      if (foundInNext) return foundInNext;
    }

    // Search in variations
    if (node.variations && node.variations.length > 0) {
      for (const variation of node.variations) {
        const foundInVariation = findNodeById(variation, id);
        if (foundInVariation) return foundInVariation;
      }
    }

    return null; // Node not found
  }, []);

  /**
   * Handles changes to the annotation of a selected node.
   *
   * @param {string} newSymbol - The new annotation symbol.
   */
  const handleAnnotationChange = (newSymbol) => {
    setAnnotation((prevAnnotation) => {
      const updatedAnnotation = prevAnnotation === newSymbol ? "" : newSymbol; // Toggle annotation

      if (selectedNode) {
        setLines((prevLines) => {
          const newLines = { ...prevLines };
          const nodeToUpdate = findNodeById(newLines, selectedNode.id);
          if (nodeToUpdate) {
            nodeToUpdate.annotation = updatedAnnotation; // Update annotation
          }
          return newLines;
        });
      }

      return updatedAnnotation; // Update state with the new annotation value
    });
  };

  /**
   * Retrieves an evaluation from the chess engine (Stockfish) for a given FEN position.
   * It uses multiPV (Multi Principal Variation) and depth to calculate the best lines and evaluations.
   *
   * @param {string} fen - The FEN string representing the current board position.
   * @returns {Promise} A promise that resolves with evaluation data or rejects if an error occurs.
   */
  const getEvaluation = useCallback(
    (fen) => {
      if (!engineOn) {
        return Promise.reject("Engine is off"); // Reject if the engine is turned off
      }

      if (!stockfish) {
        return Promise.reject("Stockfish not ready"); // Reject if Stockfish instance is unavailable
      }

      // Abort any ongoing evaluation to prevent overlap
      if (evaluationAbortController.current) {
        evaluationAbortController.current.abort(); // Abort the previous request
      }

      // Create a new AbortController for the current evaluation
      const controller = new AbortController();
      evaluationAbortController.current = controller;

      const { signal } = controller; // Extract the signal for abort handling

      return new Promise((resolve, reject) => {
        if (signal.aborted) {
          reject("Evaluation aborted."); // Immediately reject if already aborted
          return;
        }

        // Prepare an array to store multiple lines for multiPV analysis
        const lines = Array.from({ length: multiPV }, () => ({}));

        stockfish.postMessage("stop"); // Stop any ongoing calculations

        // Short delay to ensure "stop" command is processed
        setTimeout(() => {
          if (signal.aborted) {
            reject("Evaluation aborted."); // Reject if aborted during the delay
            return;
          }

          // Configure Stockfish options and start analysis
          stockfish.postMessage(`setoption name MultiPV value ${multiPV}`); // Set multiPV
          stockfish.postMessage(`position fen ${fen}`); // Load the given position
          stockfish.postMessage(`go depth ${depth}`); // Set analysis depth

          // Determine if it's Black's turn
          const isBlackTurn = fen.split(" ")[1] === "b";

          // Listener for messages from Stockfish
          const handleMessage = (event) => {
            if (signal.aborted) {
              stockfish.removeEventListener("message", handleMessage);
              reject("Evaluation aborted."); // Reject if aborted during processing
              return;
            }

            const message = event.data;

            // Parse the Stockfish message for depth, score, and moves
            if (message.startsWith("info depth")) {
              const match = message.match(
                /depth (\d+).*score (mate|cp) (-?\d+).* pv (.+)/
              );

              if (match) {
                const depth = parseInt(match[1], 10); // Analysis depth
                const evalType = match[2]; // Evaluation type (mate or cp)
                const evalValue = parseInt(match[3], 10); // Evaluation value
                const uciMoves = match[4].split(" "); // Best moves (in UCI notation)
                const pvIndex =
                  parseInt(message.match(/multipv (\d+)/)?.[1] || "1", 10) - 1; // Line index

                if (pvIndex >= 0 && pvIndex < multiPV) {
                  const tempChess = new Chess(fen); // Clone the game for SAN conversion
                  const sanMoves = [];
                  uciMoves.forEach((uciMove) => {
                    const move = tempChess.move({
                      from: uciMove.slice(0, 2),
                      to: uciMove.slice(2, 4),
                      promotion: uciMove.length > 4 ? uciMove[4] : undefined,
                    });
                    if (move) sanMoves.push(move.san); // Convert to SAN
                  });

                  let evalScore;
                  if (evalType === "mate") {
                    // Mates are shown as M+/-X
                    evalScore =
                      evalValue > 0
                        ? `M${evalValue}`
                        : `-M${Math.abs(evalValue)}`;
                  } else {
                    // Convert centipawns to numeric evaluation
                    evalScore = evalValue / 100;
                    if (isBlackTurn) evalScore = -evalScore; // Reverse for Black
                    evalScore = evalScore.toFixed(2); // Format to 2 decimal places
                  }

                  /**
                   * Converts evaluation score to win probability.
                   * @param {number|string} evaluation - The evaluation score or mate string.
                   * @param {number} k - Slope of the logistic function.
                   * @param {number} positionalFactor - Additional factors affecting probability.
                   * @returns {number} Win probability as a percentage (0-100).
                   */
                  /**
                   * Converts an evaluation score to win probability using a logistic function.
                   *
                   * @param {number|string} evaluation - The evaluation score (centipawns) or mate string (e.g., "M3").
                   * @param {number} k - The slope of the logistic function.
                   * @param {number} positionalFactor - Additional factors affecting probability.
                   * @returns {number} Win probability as a percentage (0-100).
                   */
                  const convertEvalToWinProbability = (
                    evaluation,
                    k = 0.2,
                    positionalFactor = 0
                  ) => {
                    // Handle mate evaluations
                    if (
                      typeof evaluation === "string" &&
                      evaluation.startsWith("M")
                    ) {
                      const mateValue = parseInt(evaluation.substring(1), 10); // Extract mate value
                      if (mateValue > 0) {
                        // Positive mate (winning)
                        if (mateValue <= 3) return 99; // Mate in 1-3 moves
                        else if (mateValue <= 7) return 96; // Mate in 4-7 moves
                        else if (mateValue <= 10)
                          return 95; // Mate in 8-10 moves
                        else return 90; // Mate in more than 10 moves
                      } else {
                        // Negative mate (losing)
                        const absMateValue = Math.abs(mateValue);
                        if (absMateValue <= 3)
                          return 1; // Opponent mates in 1-3 moves
                        else if (absMateValue <= 7)
                          return 4; // Opponent mates in 4-7 moves
                        else if (absMateValue <= 10)
                          return 5; // Opponent mates in 8-10 moves
                        else return 10; // Opponent mates in more than 10 moves
                      }
                    }

                    // Clip extreme evaluations
                    const maxEval = 1500; // Define the maximum evaluation value for scaling
                    evaluation = Math.max(
                      -maxEval,
                      Math.min(maxEval, evaluation)
                    );

                    // Add positional factor and adjust slope dynamically
                    evaluation += positionalFactor;
                    const adjustedK = Math.abs(evaluation) > 500 ? k * 1.5 : k; // Increase slope for extreme evaluations

                    // Calculate probability using logistic function
                    const probability =
                      1 / (1 + Math.exp(-adjustedK * evaluation));
                    return probability * 100; // Convert to percentage
                  };

                  let winProbability = convertEvalToWinProbability(evalScore);

                  // Reverse probability for Black's turn
                  if (isBlackTurn) {
                    winProbability = 100 - winProbability;
                  }

                  // Update the line's data
                  lines[pvIndex] = {
                    eval: evalScore,
                    moves: sanMoves,
                    depth,
                    win_probability: winProbability.toFixed(2), // Add win probability
                  };

                  setCurrentLines([...lines]); // Update the state with current lines
                }
              }
            }
          };

          stockfish.addEventListener("message", handleMessage);

          // Cleanup listener and resolve/reject on timeout or abort
          const timeoutId = setTimeout(() => {
            stockfish.removeEventListener("message", handleMessage);
            reject("Stockfish evaluation timed out.");
          }, 10000); // Timeout after 10 seconds

          signal.addEventListener("abort", () => {
            clearTimeout(timeoutId);
            stockfish.removeEventListener("message", handleMessage);
            reject("Evaluation aborted.");
          });
        }, 100); // Ensure "stop" command has processed
      });
    },
    [stockfish, setCurrentLines, engineOn, multiPV, depth] // Dependencies
  );

  /**
   * Finds the parent node of a given node in the move tree.
   *
   * @param {Object} lines - The root of the move tree.
   * @param {string} nodeId - The ID of the node whose parent is to be found.
   * @returns {Object|null} The parent node, or null if not found.
   */
  const findParentNode = useCallback((lines, nodeId) => {
    const traverse = (node) => {
      if (!node) return null;

      if (node.next && node.next.id === nodeId) return node;

      if (Array.isArray(node.variations)) {
        for (const variation of node.variations) {
          if (variation.id === nodeId) return node;

          const parentInVariation = traverse(variation);
          if (parentInVariation) return parentInVariation;
        }
      }

      return node.next ? traverse(node.next) : null;
    };

    return traverse(lines);
  }, []);

  /**
   * Generates the path from the root to a given node in the move tree.
   *
   * @param {Object} lines - The root of the move tree.
   * @param {string} nodeId - The ID of the target node.
   * @returns {Array} An array of node IDs representing the path.
   */
  const generateNodePath = useCallback(
    (lines, nodeId) => {
      const path = [];
      let currentNode = findNodeById(lines, nodeId);

      while (currentNode) {
        path.unshift(currentNode.id); // Add the current node ID to the path
        currentNode = findParentNode(lines, currentNode.id); // Move to the parent node
      }

      return path;
    },
    [findNodeById, findParentNode]
  );

  /**
   * Generates a history of moves from the given node back to the root.
   *
   * @param {Object} node - The starting node for generating the move history.
   * @returns {Array} An array of moves (SAN strings) leading to the node.
   */
  const generateHistoryFromNode = useCallback(
    (node) => {
      const moves = [];
      let currentNode = node;

      while (currentNode && currentNode.id !== "root") {
        if (currentNode.san) {
          moves.unshift(currentNode.san); // Add the move to the beginning of the array
        }

        const parentNode = findParentNode(lines, currentNode.id);

        if (parentNode) {
          const currentNodeId = currentNode.id;

          // Check if the current node is part of a variation
          const isInVariation = Array.isArray(parentNode.variations)
            ? parentNode.variations.some(
                (variation) => variation.id === currentNodeId
              )
            : false;

          if (isInVariation) {
            currentNode = findParentNode(lines, parentNode.id); // Move to the grandparent
            continue;
          }

          currentNode = parentNode; // Move to the parent node
        } else {
          currentNode = null; // Terminate if no parent is found
        }
      }

      return moves; // Return the generated history
    },
    [lines, findParentNode]
  );

  /**
   * Moves to the next node in the mainline and updates the game state.
   */
  const handleNext = useCallback(() => {
    if (!selectedNode?.next) return; // Do nothing if there is no next node

    const nextNode = selectedNode.next;

    setSelectedNode(nextNode); // Update the selected node
    setComment(nextNode.comment || ""); // Update the comment
    setAnnotation(nextNode.annotation || ""); // Update the annotation

    // Generate the game state from the move history
    const history = generateHistoryFromNode(nextNode);
    setGame(() => {
      const newGame = new Chess();
      history.forEach((san) => newGame.move(san)); // Replay moves to reach the new state
      return newGame;
    });

    setNodePath(generateNodePath(lines, nextNode.id)); // Update the path to the current node
  }, [selectedNode, lines, generateHistoryFromNode, generateNodePath]);

  /**
   * Moves to the previous node in the mainline or variation and updates the game state.
   */
  const handleBack = useCallback(() => {
    if (!selectedNode || selectedNode.id === "root") return; // Do nothing if at the root

    const parentNode = findParentNode(lines, selectedNode.id);

    if (!parentNode) return; // Do nothing if no parent is found

    // Check if the current node is part of a variation
    const isInVariation =
      Array.isArray(parentNode.variations) &&
      parentNode.variations.some(
        (variation) => variation.id === selectedNode.id
      );

    let targetNode = parentNode;

    if (isInVariation) {
      targetNode = findParentNode(lines, parentNode.id); // Skip to the grandparent
    }

    if (targetNode) {
      setSelectedNode(targetNode); // Update the selected node
      setComment(targetNode.comment || ""); // Update the comment
      setAnnotation(targetNode.annotation || ""); // Update the annotation

      // Generate the game state from the move history
      const history = generateHistoryFromNode(targetNode);
      setGame(() => {
        const newGame = new Chess();
        history.forEach((san) => newGame.move(san)); // Replay moves to reach the new state
        return newGame;
      });

      setNodePath(generateNodePath(lines, targetNode.id)); // Update the path to the current node
    }
  }, [
    selectedNode,
    lines,
    findParentNode,
    generateHistoryFromNode,
    generateNodePath,
  ]);

  /**
   * Retrieves the color associated with a specific annotation symbol.
   *
   * @param {string} symbol - The annotation symbol.
   * @returns {string|null} The color associated with the symbol, or null if not found.
   */
  const getCategoryColorBySymbol = useCallback(
    (symbol) => {
      const allItems = Object.values(categories).flat(); // Flatten all category items
      const item = allItems.find((item) => item.symbol === symbol); // Find the matching symbol
      return item ? item.color : null; // Return the color if found, else null
    },
    [categories]
  );

  /**
   * Converts a chessboard square (e.g., "e2") to normalized coordinates.
   *
   * @param {string} square - The square in algebraic notation.
   * @returns {Object} An object containing normalized `x` and `y` coordinates.
   */
  const getSquarePosition = (square) => {
    const file = square.charCodeAt(0) - 97; // Convert file (a-h) to 0-7
    const rank = 8 - parseInt(square[1], 10); // Convert rank (1-8) to 7-0
    return { x: (file + 0.5) / 8, y: (rank + 0.5) / 8 }; // Normalize to percentages
  };

  /**
   * Retrieves the destination square of the last move in the game's history.
   *
   * @returns {string|null} The destination square of the last move, or null if no moves exist.
   */
  const getLastMoveSquare = useCallback(() => {
    const history = game.history({ verbose: true }); // Retrieve detailed move history
    if (history.length === 0) return null; // Return null if no moves exist
    return history[history.length - 1].to; // Return the destination square of the last move
  }, [game]);

  /**
   * Handles the right-click event on a move.
   *
   * @param {Event} event - The right-click event.
   * @param {Object} move - The move associated with the right-click.
   */
  const handleRightClick = (event, move) => {
    event.preventDefault(); // Prevent the default context menu from appearing
    setSelectedMove(move); // Track the move associated with the context menu
    handleMoveClick(move); // Handle the click action for the move
  };

  /**
   * Closes the context menu and clears the selected move.
   */
  const handleMenuClose = useCallback(() => {
    setSelectedMove(null); // Clear the currently selected move
  }, []);

  /**
   * Promotes a selected variation within the move tree.
   * If the selected variation is not the first, swaps it with the one above.
   * Currently, promotion to the mainline is not implemented.
   */
  const handlePromoteVariation = () => {
    if (!selectedMove || !lines) {
      console.error("No selected move or lines found.");
      return;
    }

    setLines((prevLines) => {
      const newLines = { ...prevLines }; // Clone the current state
      const parentNode = findParentNode(newLines, selectedMove.id);

      if (!parentNode || !Array.isArray(parentNode.variations)) {
        console.error("Parent node or variations not found.");
        return newLines;
      }

      // Find the index of the selected variation
      const selectedIndex = parentNode.variations.findIndex(
        (variation) => variation.id === selectedMove.id
      );

      if (selectedIndex === -1) {
        console.error("Selected move not found in variations.");
        return newLines;
      }

      // Swap the selected variation with the one above if possible
      if (selectedIndex > 0) {
        const variations = parentNode.variations;
        const temp = variations[selectedIndex - 1];
        variations[selectedIndex - 1] = variations[selectedIndex];
        variations[selectedIndex] = temp;
      }

      // TODO: Implement promotion to the mainline if the selected variation is the first

      return newLines;
    });
  };

  /**
   * Deletes the selected move from the move tree.
   */
  const handleDeleteMove = () => {
    if (!selectedMove) {
      return;
    }

    setLines((prevLines) => {
      let newSelectedNode = null;

      // Helper function to recursively traverse the tree and delete the selected node
      const traverseAndDelete = (node, parent = null) => {
        if (!node) return;

        // Check if the current node matches the selectedMove
        if (node.id === selectedMove.id) {
          if (parent) {
            // Remove the node from the parent's next or variations
            if (parent.next && parent.next.id === selectedMove.id) {
              parent.next = null; // Remove as next node
              newSelectedNode = parent;
            } else if (Array.isArray(parent.variations)) {
              const variationIndex = parent.variations.findIndex(
                (variation) => variation.id === selectedMove.id
              );
              if (variationIndex !== -1) {
                parent.variations.splice(variationIndex, 1);

                // Select the first sibling or the parent if no siblings remain
                newSelectedNode =
                  parent.variations.length > 0 ? parent.variations[0] : parent;
              }
            }
          }
          return true; // Node found and deleted
        }

        // Recursively check next and variations
        if (node.next) traverseAndDelete(node.next, node);
        if (Array.isArray(node.variations)) {
          node.variations.forEach((variation) =>
            traverseAndDelete(variation, node)
          );
        }
      };

      const newLines = { ...prevLines };
      traverseAndDelete(newLines);

      // Update the game state and UI
      if (newSelectedNode) {
        const updatedGame = new Chess();
        const moves = generateHistoryFromNode(newSelectedNode);
        moves.forEach((san) => {
          try {
            updatedGame.move(san);
          } catch (error) {}
        });

        setGame(updatedGame);
        setSelectedNode(newSelectedNode);
        setNodePath(generateNodePath(newLines, newSelectedNode.id));
      } else {
        // Reset to the root if no nodes remain
        setGame(new Chess());
        setSelectedNode(newLines);
        setNodePath(["root"]);
      }

      return newLines;
    });
  };

  /**
   * Deletes the comments associated with the selected node.
   */
  const handleDeleteComments = () => {
    if (!selectedNode) return;

    setLines((prevLines) => {
      const newLines = { ...prevLines };
      const nodeToUpdate = findNodeById(newLines, selectedNode.id);

      if (nodeToUpdate) {
        nodeToUpdate.comment = ""; // Clear the comment
      }

      setComment(""); // Clear the comment in the UI
      return newLines;
    });

    handleMenuClose(); // Close the context menu
  };

  /**
   * Deletes all arrows associated with the selected node.
   */
  const handleDeleteArrows = () => {
    if (!selectedNode) return;

    setLines((prevLines) => {
      const newLines = { ...prevLines };
      const nodeToUpdate = findNodeById(newLines, selectedNode.id);

      if (nodeToUpdate) {
        nodeToUpdate.arrows = []; // Clear all arrows
      }

      setArrows([]); // Update the arrows state
      return newLines;
    });

    handleMenuClose(); // Close the context menu
  };

  /**
   * Deletes all highlights associated with the selected node.
   */
  const handleDeleteHighlights = () => {
    if (!selectedNode) return;

    setLines((prevLines) => {
      const newLines = { ...prevLines };
      const nodeToUpdate = findNodeById(newLines, selectedNode.id);

      if (nodeToUpdate) {
        nodeToUpdate.highlightSquares = []; // Clear all highlights
      }

      setHighlights([]); // Update the highlights state
      return newLines;
    });

    handleMenuClose(); // Close the context menu
  };

  /**
   * Deletes the annotation associated with the selected node.
   */
  const handleDeleteAnnotation = () => {
    if (!selectedNode) return;

    setLines((prevLines) => {
      const newLines = { ...prevLines };
      const nodeToUpdate = findNodeById(newLines, selectedNode.id);

      if (nodeToUpdate) {
        delete nodeToUpdate.annotation; // Remove the annotation
        if (selectedNode.id === nodeToUpdate.id) {
          setAnnotation(null); // Clear the annotation state if it matches the current node
        }
      }

      return newLines;
    });

    handleMenuClose(); // Close the context menu
  };

  /**
   * Utility Functions Section
   *
   * This section contains reusable helper functions that support other operations
   * across the application. These functions provide common functionality that
   * can be used in multiple contexts.
   *
   * Functions:
   * - `getSquareFromMouseEvent(event)`: Converts a mouse event into a chessboard square in algebraic notation.
   * - Any additional helper functions that do not directly belong to specific sections.
   */

  /**
   * Converts a mouse event into a chessboard square in algebraic notation.
   *
   * @param {Event} event - The mouse event.
   * @returns {string|null} The square in chess notation (e.g., 'e2'), or null if outside the board.
   */
  const getSquareFromMouseEvent = (event) => {
    const rect = chessboardRef.current.getBoundingClientRect(); // Get the chessboard dimensions
    const x = event.clientX - rect.left; // X position relative to the board
    const y = event.clientY - rect.top; // Y position relative to the board

    const file = Math.floor((x / rect.width) * 8); // Determine file (a-h) as 0-7
    const rank = Math.floor(8 - (y / rect.height) * 8); // Determine rank (1-8) as 7-0

    if (file >= 0 && file <= 7 && rank >= 0 && rank <= 7) {
      return String.fromCharCode(97 + file) + (rank + 1); // Convert to algebraic notation
    }
    return null; // Return null if outside the board
  };

  /**
   * Opening Name Section
   *
   * This section contains functionality related to identifying chess openings
   * based on the game's move history. It matches the game's move sequence
   * to known openings using PGN or FEN data.
   *
   * Functions:
   * - `findOpening(gameHistory)`: Finds the chess opening name based on the game's move history.
   */

  /**
   * Finds the chess opening name based on the game's move history.
   *
   * @param {Array} gameHistory - An array of SAN (Standard Algebraic Notation) strings representing the game's move history.
   * @returns {Object|null} An object representing the matched opening, including its name, PGN, FEN, and transposition status, or null if no match is found.
   */
  function findOpening(gameHistory) {
    const maxMoves = 15; // Limit the search to the first 15 moves to reduce computation
    const chess = new Chess(); // Create a new instance of the Chess.js library

    // Variable to store the last matched opening
    let lastMatchedOpening = null;

    /**
     * Builds a properly formatted PGN (Portable Game Notation) string from a history of moves.
     *
     * @param {Array} history - An array of SAN strings representing a sequence of moves.
     * @returns {string} A formatted PGN string.
     */
    const buildPGN = (history) => {
      let pgn = ""; // Initialize an empty PGN string
      for (let i = 0; i < history.length; i++) {
        if (i % 2 === 0) {
          // Add the move number for White's turn
          pgn += `${Math.floor(i / 2) + 1}. `;
        }
        pgn += `${history[i]} `; // Append the move to the PGN string
      }
      return pgn.trim(); // Remove any trailing whitespace
    };

    /**
     * Extracts the position part of a FEN (Forsyth-Edwards Notation) string.
     * The position part includes only the piece placement on the board.
     *
     * @param {string} fen - A full FEN string.
     * @returns {string} The position part of the FEN string.
     */
    const extractPositionFromFEN = (fen) => fen.split(" ")[0]; // Split by space and return the first part

    // Iterate through the moves in the game's history, up to the maximum allowed moves
    for (let i = 0; i < Math.min(gameHistory.length, maxMoves); i++) {
      const currentMoves = buildPGN(gameHistory.slice(0, i + 1)); // Build PGN for the current move sequence
      chess.reset(); // Reset the board to the starting position
      chess.loadPgn(currentMoves); // Load the PGN string into the chess instance

      // Check for a direct PGN match in the openings dataset
      const pgnMatch = openings.find((opening) =>
        opening.pgn.startsWith(currentMoves)
      );
      if (pgnMatch) {
        lastMatchedOpening = {
          ...pgnMatch, // Include all details of the matched opening
          transposition: false, // Indicates a direct match with no transposition
        };
      } else {
        // If no PGN match, check for a FEN match (possible transposition)
        const currentFEN = extractPositionFromFEN(chess.fen()); // Get the FEN of the current position
        const fenMatch = openings.find(
          (opening) => extractPositionFromFEN(opening.fen) === currentFEN
        );
        if (fenMatch) {
          lastMatchedOpening = {
            ...fenMatch, // Include all details of the matched opening
            transposition: true, // Indicates a match via transposition
          };
        }
      }
    }

    // Return the last matched opening or null if no match was found
    return lastMatchedOpening;
  }

  /**
   * Move and Node Management Section
   *
   * This section handles operations related to individual moves and nodes
   * within the chess game's move tree. It includes functionality to navigate
   * through moves, modify nodes, and manage their annotations or comments.
   *
   * Functions:
   * - `handleMoveClick(node)`: Handles clicking on a move, updating the game state and UI.
   * - `handleCommentChange(event)`: Updates the comment for the currently selected move.
   * - `getCurrentNode(lines, nodePath)`: Retrieves the current node in the move tree based on the path.
   * - `handleMove(sourceSquare, targetSquare)`: Handles a move on the chessboard, updating the game state.
   * - `addMoveToLines(move)`: Adds a move to the move tree, handling mainlines and variations.
   */

  /**
   * Handles the click event on a move, updating the game and UI states.
   *
   * @param {Object} node - The move node that was clicked.
   */
  const handleMoveClick = (node) => {
    if (!node) return;

    setSelectedNode(node); // Update the selected node
    setComment(node.comment || ""); // Update the comment field
    setAnnotation(node.annotation || ""); // Update the annotation field

    // Update the path to the selected node
    const path = generateNodePath(lines, node.id);
    setNodePath(path);

    // Generate the game history from the selected node
    const history = generateHistoryFromNode(node);

    // Update the chessboard state based on the history
    setGame(() => {
      const newGame = new Chess();
      history.forEach((san) => {
        try {
          newGame.move(san); // Apply each move
        } catch (error) {
          // Handle invalid moves gracefully
        }
      });
      return newGame;
    });
  };

  /**
   * Handles changes to the comment field, updating both the state and the move tree.
   *
   * @param {Event} event - The input change event.
   */
  const handleCommentChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment); // Update the comment field state

    if (selectedNode) {
      setLines((prevLines) => {
        const newLines = { ...prevLines };
        const nodeToUpdate = findNodeById(newLines, selectedNode.id);
        if (nodeToUpdate) {
          nodeToUpdate.comment = newComment; // Save the comment in the move tree
        }
        return newLines;
      });
    }
  };

  /**
   * Retrieves the current node in the move tree based on the provided path.
   *
   * @param {Object} lines - The root of the move tree.
   * @param {Array} nodePath - The path to the current node.
   * @returns {Object|null} The current node, or null if not found.
   */
  const getCurrentNode = (lines, nodePath) => {
    const currentId = nodePath[nodePath.length - 1]; // Get the last ID in the path

    // Inner function to recursively find a node by ID
    const findNodeById = (node, id) => {
      if (!node) return null;

      if (node.id === id) return node; // Match found

      // Search in the mainline
      if (node.next) {
        const foundInNext = findNodeById(node.next, id);
        if (foundInNext) return foundInNext;
      }

      // Search in variations
      if (node.variations && node.variations.length > 0) {
        for (const variation of node.variations) {
          const foundInVariation = findNodeById(variation, id);
          if (foundInVariation) return foundInVariation;
        }
      }

      return null; // Not found
    };

    return findNodeById(lines, currentId); // Locate the current node
  };

  /**
   * Handles a move on the chessboard, updating the game state and move tree.
   *
   * @param {string} sourceSquare - The starting square of the move.
   * @param {string} targetSquare - The destination square of the move.
   * @returns {boolean} True if the move was valid, otherwise false.
   */
  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const newGame = new Chess();
      newGame.loadPgn(game.pgn()); // Load the current game history

      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // Default to queen promotion
      });

      if (!move) {
        throw new Error("Illegal move");
      }

      setGame(newGame); // Update the game state
      setAnnotation(""); // Clear the annotation field
      addMoveToLines(move.san); // Update the move tree with the new move

      return true; // Move was successful
    } catch (error) {
      return false; // Move was invalid
    }
  };

  /**
   * Adds a move to the move tree, handling mainlines and variations.
   *
   * @param {string} move - The SAN notation of the move to add.
   */
  const addMoveToLines = (move) => {
    setLines((prevLines) => {
      const newLines = { ...prevLines };
      const currentNode = getCurrentNode(newLines, nodePath);

      if (!currentNode) {
        return newLines;
      }

      if (!Array.isArray(currentNode.variations)) {
        currentNode.variations = []; // Ensure variations is an array
      }

      if (currentNode.next && !Array.isArray(currentNode.next.variations)) {
        currentNode.next.variations = []; // Ensure next's variations is initialized
      }

      const parentMoveNumber = currentNode.moveNumber || 0;
      let newSelectedNode = null;

      if (!currentNode.next) {
        // Add as the next mainline move
        const newMove = {
          id: uuidv4(),
          san: move,
          moveNumber: parentMoveNumber + 1,
          next: null,
          variations: [],
        };
        currentNode.next = newMove;
        setNodePath((prev) => [...prev, newMove.id]);
        newSelectedNode = newMove;
      } else if (currentNode.next.san === move) {
        setNodePath((prev) => [...prev, currentNode.next.id]); // Select existing mainline move
        newSelectedNode = currentNode.next;
      } else {
        // Handle variations
        const existingVariation = currentNode.next.variations.find(
          (variation) => variation.san === move
        );

        if (existingVariation) {
          setNodePath((prev) => [...prev, existingVariation.id]); // Select existing variation
          newSelectedNode = existingVariation;
        } else if (nodePath[nodePath.length - 1] === currentNode.id) {
          const newMove = {
            id: uuidv4(),
            san: move,
            moveNumber: parentMoveNumber + 1,
            next: null,
            variations: [],
          };
          currentNode.next.variations.push(newMove);
          setNodePath((prev) => [...prev, newMove.id]);
          newSelectedNode = newMove;
        } else {
          // Handle deeper variations
          const focusedVariation = currentNode.variations.find(
            (variation) => variation.id === nodePath[nodePath.length - 1]
          );
          if (focusedVariation) {
            if (!focusedVariation.next) {
              const newMove = {
                id: uuidv4(),
                san: move,
                moveNumber:
                  focusedVariation.moveNumber + 1 || parentMoveNumber + 1,
                next: null,
                variations: [],
              };
              focusedVariation.next = newMove;
              setNodePath((prev) => [...prev, newMove.id]);
              newSelectedNode = newMove;
            }
          }
        }
      }

      if (newSelectedNode) {
        setSelectedNode(newSelectedNode);
        setComment(newSelectedNode.comment || ""); // Update comment if applicable
      }

      return newLines;
    });
  };

  /**
   * Board Interaction Section
   *
   * This section handles user interactions with the chessboard, such as drawing
   * highlights, arrows, or annotations. It also manages right-click actions
   * and their corresponding visual updates.
   *
   * Functions:
   * - `addHighlights(start, end, event)`: Adds highlights or arrows to the board.
   * - `handleMouseDown(event)`: Handles the mouse down event to initiate a right-click action.
   * - `handleMouseUp(event)`: Handles the mouse up event to finalize a right-click action.
   * - `renderAnnotation()`: Renders an annotation symbol on the board at the square of the last move.
   */

  /**
   * Adds highlights or arrows to the board based on the user's action.
   *
   * @param {string} start - The starting square.
   * @param {string} end - The ending square.
   * @param {Event} event - The triggering event.
   */
  const addHighlights = useCallback(
    (start, end, event) => {
      if (selectedNode) {
        setLines((prevLines) => {
          const newLines = { ...prevLines };
          const nodeToUpdate = findNodeById(newLines, selectedNode.id);

          if (nodeToUpdate) {
            let color = "#0D47A1"; // Default color
            if (event?.shiftKey) color = "#C62828";
            else if (event?.ctrlKey || event?.metaKey) color = "#1B5E20";
            else if (event?.altKey) color = "#F57C00";

            if (start === end) {
              // Handle square highlights
              if (!nodeToUpdate.highlightSquares) {
                nodeToUpdate.highlightSquares = [];
              }

              const existingHighlightIndex =
                nodeToUpdate.highlightSquares.findIndex(
                  (highlight) => highlight[0] === start
                );

              if (existingHighlightIndex >= 0) {
                if (
                  nodeToUpdate.highlightSquares[existingHighlightIndex][1] ===
                  color
                ) {
                  nodeToUpdate.highlightSquares.splice(
                    existingHighlightIndex,
                    1
                  );
                } else {
                  nodeToUpdate.highlightSquares[existingHighlightIndex][1] =
                    color;
                }
              } else {
                nodeToUpdate.highlightSquares.push([start, color]);
              }

              setHighlights(nodeToUpdate.highlightSquares);
            } else {
              // Handle arrows
              if (!nodeToUpdate.arrows) {
                nodeToUpdate.arrows = [];
              }

              const existingIndex = nodeToUpdate.arrows.findIndex(
                (arrow) => arrow[0] === start && arrow[1] === end
              );

              if (existingIndex >= 0) {
                if (nodeToUpdate.arrows[existingIndex][2] === color) {
                  nodeToUpdate.arrows.splice(existingIndex, 1);
                } else {
                  nodeToUpdate.arrows[existingIndex][2] = color;
                }
              } else {
                nodeToUpdate.arrows.push([start, end, color]);
              }

              setArrows(nodeToUpdate.arrows);
            }
          }

          return newLines;
        });
      }
    },
    [selectedNode, setLines, findNodeById, setArrows, setHighlights]
  );

  /**
   * Handles the mouse down event to initiate a right-click action for adding highlights or arrows.
   *
   * @param {Event} event - The mouse down event.
   */
  const handleMouseDown = useCallback((event) => {
    if (event.button !== 2) return; // Only trigger on right-click
    const square = getSquareFromMouseEvent(event); // Get the square from the mouse event
    if (square) {
      dragStartRef.current = square; // Set the drag start square in the ref
    }
  }, []);

  /**
   * Handles the mouse up event to finalize a right-click action.
   * This action can add highlights or arrows depending on the starting and ending squares.
   *
   * @param {Event} event - The mouse up event.
   */
  const handleMouseUp = useCallback(
    (event) => {
      if (event.button !== 2) return; // Only trigger on right-click
      const square = getSquareFromMouseEvent(event); // Get the square where the mouse was released

      const dragStart = dragStartRef.current; // Retrieve the drag start square

      if (dragStart && square) {
        addHighlights(dragStart, square, event); // Add highlights or arrows
      }

      dragStartRef.current = null; // Clear the drag start reference
    },
    [addHighlights]
  );

  /**
   * Renders an annotation symbol on the chessboard at the square of the last move.
   *
   * @returns {JSX.Element|null} An SVG element representing the annotation, or null if not applicable.
   */
  const renderAnnotation = useCallback(() => {
    if (!annotation || !game || !chessboardRef?.current) {
      console.log("No annotation, game, or chessboardRef available.");
      return null;
    }

    const chessboardSize = chessboardRef.current.getBoundingClientRect(); // Get the chessboard dimensions
    const squareSize = chessboardSize.width / 8; // Calculate the size of a square

    const lastMoveSquare = getLastMoveSquare(); // Get the square of the last move
    if (!lastMoveSquare) return null;

    const { x, y } = getSquarePosition(lastMoveSquare);

    // Adjust coordinates based on board orientation
    const adjustedX = boardOrientation === "white" ? x : 1 - x;
    const adjustedY = boardOrientation === "white" ? y : 1 - y;

    const cx =
      chessboardSize.left + adjustedX * chessboardSize.width + squareSize / 2; // Circle x-coordinate
    const cy =
      chessboardSize.top + adjustedY * chessboardSize.height - squareSize / 2; // Circle y-coordinate

    return (
      <>
        <defs>
          <filter id="drop-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow
              dx="2"
              dy="2"
              stdDeviation="2"
              floodColor="black"
              floodOpacity="0.5"
            />
          </filter>
        </defs>
        <circle
          cx={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
          cy={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
          r="20"
          stroke="white"
          strokeWidth="1.5"
          fill={getCategoryColorBySymbol(annotation)}
          fillOpacity="0.7"
          filter="url(#drop-shadow)"
        />
        <text
          x={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
          y={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
          fill="white"
          fontSize="26px"
          textAnchor="middle"
          fontWeight="bold"
          dominantBaseline="middle"
        >
          {annotation}
        </text>
      </>
    );
  }, [
    annotation,
    game,
    chessboardRef,
    boardOrientation,
    getCategoryColorBySymbol,
    getLastMoveSquare,
  ]);

  /**
   * Repertoire Management Section
   *
   * This section handles creating, updating, deleting, and managing chess repertoires.
   * Repertoires represent a collection of moves or strategies for chess players, and
   * this section includes functions to manage their state and order.
   *
   * Functions:
   * - `reorderItem(id, direction)`: Reorders a repertoire in the list.
   * - `handleRepertoireClick(id)`: Loads the details of the selected repertoire into the state.
   * - `handleAddRepertoire()`: Adds a new repertoire to the list and updates Firebase.
   * - `handleSaveRepertoire()`: Saves the current repertoire to Firebase, updating or creating it.
   * - `handleDeleteRepertoire()`: Deletes the selected repertoire from the list and Firebase.
   * - `handleDeleteClick()`: Opens the delete confirmation dialog.
   * - `handleCancelDelete()`: Cancels the delete confirmation dialog.
   * - `handleConfirmDelete()`: Confirms deletion of the repertoire if the input matches the title.
   */

  /**
   * Reorders a repertoire item up or down in the list.
   *
   * @param {string} id - The ID of the repertoire to reorder.
   * @param {number} direction - The direction of the move (-1 for up, +1 for down).
   */
  const reorderItem = (id, direction) => {
    setRepertoires((prevRepertoires) => {
      // Convert repertoires object to a sorted array
      const items = Object.entries(prevRepertoires).sort(
        ([, a], [, b]) => a.order - b.order
      );

      // Find the current index of the selected item
      const currentIndex = items.findIndex(([key]) => key === id);
      const newIndex = currentIndex + direction;

      // Check if the new index is within bounds
      if (newIndex >= 0 && newIndex < items.length) {
        // Swap the current item with the one at the new index
        const updatedItems = items.map(([key, value], index) => {
          if (index === currentIndex) {
            return [key, { ...value, order: newIndex }];
          } else if (index === newIndex) {
            return [key, { ...value, order: currentIndex }];
          }
          return [key, value];
        });

        // Convert the updated array back to an object
        const updatedRepertoires = Object.fromEntries(updatedItems);

        // Update Firebase with the new order
        Object.entries(updatedRepertoires).forEach(([key, repertoire]) => {
          set(ref(rt, `users/${userId}/repertoires/${key}`), repertoire);
        });

        return updatedRepertoires;
      }

      return prevRepertoires; // Return unchanged if no reorder occurs
    });
  };

  /**
   * Handles clicking on a repertoire, loading its details into the state.
   *
   * @param {string} id - The ID of the selected repertoire.
   */
  const handleRepertoireClick = (id) => {
    setSelectedRepertoireId(id); // Set the selected repertoire ID

    const selectedRepertoire = repertoires[id]; // Retrieve the selected repertoire

    if (selectedRepertoire) {
      // Update states with repertoire details
      setChapterTitle(selectedRepertoire.title || "");
      setChapterDescription(selectedRepertoire.description || "");
      setAuthor(selectedRepertoire.author || "");
      setBoardOrientation(selectedRepertoire.boardOrientation || "white");
      setLines(
        selectedRepertoire.lines || {
          id: "root",
          san: "",
          next: null,
          variations: [],
          arrows: [],
          highlightSquares: [],
        }
      );

      // Reset related states
      setGame(new Chess()); // Reset the chess game
      setSelectedNode(selectedRepertoire.lines);
      setNodePath(["root"]);
      setArrows([]);
      setHighlights([]);
      setComment("");
      setAnnotation("");
    }
  };

  /**
   * Adds a new repertoire to the list and Firebase.
   */
  const handleAddRepertoire = async () => {
    const newRepertoireId = `${uuidv4()}`;
    const newRepertoire = {
      id: newRepertoireId,
      title: `Repertoire ${Object.keys(repertoires).length + 1}`,
      description: "",
      author: userData?.username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: Object.keys(repertoires).length,
      lines: {
        id: "root",
        san: "",
        next: null,
        variations: [],
        arrows: [],
        highlightSquares: [],
      },
    };

    try {
      await addRepertoire(userId, newRepertoire); // Save the new repertoire to Firebase

      setRepertoires((prevRepertoires) => ({
        ...prevRepertoires,
        [newRepertoireId]: newRepertoire,
      }));

      // Set the new repertoire as the selected one
      setSelectedRepertoireId(newRepertoireId);
      setChapterTitle(newRepertoire.title);
      setChapterDescription(newRepertoire.description);
      setAuthor(newRepertoire.author);
      setBoardOrientation(newRepertoire.boardOrientation);
      setVisibility(newRepertoire.visibility);
      setAllowCopy(newRepertoire.allowCopy);

      setLines(newRepertoire.lines); // Reset move lines
      setGame(new Chess()); // Reset the chess game
      setNodePath(["root"]);

      setIsModalOpen(true); // Open the modal dialog
    } catch (error) {
      console.error("Failed to add repertoire:", error);
    }
  };

  /**
   * Saves the current repertoire details to Firebase.
   * Adds a new repertoire or updates an existing one.
   */
  const handleSaveRepertoire = async () => {
    const updatedRepertoire = {
      title: chapterTitle,
      description: chapterDescription,
      author: author,
      boardOrientation: boardOrientation,
      visibility: visibility,
      allowCopy: allowCopy,
      lines: lines,
      createdAt: selectedRepertoireId
        ? repertoires[selectedRepertoireId]?.createdAt
        : Date.now(),
      order: selectedRepertoireId
        ? repertoires[selectedRepertoireId]?.order
        : Object.keys(repertoires).length,
    };

    try {
      if (selectedRepertoireId) {
        // Update an existing repertoire
        await set(
          ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}`),
          updatedRepertoire
        );
      } else {
        // Add a new repertoire
        const newRepertoireId = `rep-${Date.now()}`;
        await addRepertoire(userId, {
          ...updatedRepertoire,
          id: newRepertoireId,
        });
      }

      // Update the state with the new or updated repertoire
      setRepertoires((prev) => ({
        ...prev,
        [selectedRepertoireId || `rep-${Date.now()}`]: updatedRepertoire,
      }));

      setIsModalOpen(false); // Close the modal dialog
    } catch (error) {
      console.error("Failed to save repertoire:", error);
    }
  };

  /**
   * Deletes the selected repertoire from the list and Firebase.
   */
  const handleDeleteRepertoire = async () => {
    if (!selectedRepertoireId) {
      console.error("No repertoire selected for deletion.");
      return;
    }

    try {
      setRepertoires((prevRepertoires) => {
        const updatedRepertoires = { ...prevRepertoires };
        delete updatedRepertoires[selectedRepertoireId];

        const remainingRepertoires = Object.entries(updatedRepertoires).sort(
          ([, a], [, b]) => a.order - b.order
        );

        // Select another repertoire or reset the state
        if (remainingRepertoires.length > 0) {
          const [nextRepertoireId, nextRepertoire] = remainingRepertoires[0];
          setSelectedRepertoireId(nextRepertoireId);
          setChapterTitle(nextRepertoire.title || "");
          setChapterDescription(nextRepertoire.description || "");
          setAuthor(nextRepertoire.author || "");
          setBoardOrientation(nextRepertoire.boardOrientation || "white");
          setVisibility(nextRepertoire.visibility || "Private");
          setAllowCopy(nextRepertoire.allowCopy || "No");
          setLines(
            nextRepertoire.lines || {
              id: "root",
              san: "",
              next: null,
              variations: [],
              arrows: [],
              highlightSquares: [],
            }
          );
          setGame(new Chess());
          setNodePath(["root"]);
        } else {
          // Reset to default state if no repertoires remain
          setSelectedRepertoireId(null);
          setChapterTitle("");
          setChapterDescription("");
          setAuthor("");
          setBoardOrientation("white");
          setVisibility("Private");
          setAllowCopy("No");
          setLines({
            id: "root",
            san: "",
            next: null,
            variations: [],
            arrows: [],
            highlightSquares: [],
          });
          setGame(new Chess());
          setNodePath(["root"]);
        }

        return updatedRepertoires;
      });

      // Delete the repertoire from Firebase
      await deleteRepertoire(userId, selectedRepertoireId);

      setIsModalOpen(false); // Close the modal dialog
    } catch (error) {
      console.error("Failed to delete repertoire:", error);
      alert("Failed to delete repertoire. Please try again.");
    }
  };

  /**
   * Opens the delete confirmation dialog.
   */
  const handleDeleteClick = () => {
    setIsConfirmingDelete(true);
  };

  /**
   * Cancels the delete confirmation dialog.
   */
  const handleCancelDelete = () => {
    setIsConfirmingDelete(false);
    setInputRepertoireName(""); // Clear the input field
  };

  /**
   * Confirms deletion of the repertoire if the input matches the title.
   */
  const handleConfirmDelete = () => {
    if (inputRepertoireName === chapterTitle) {
      handleDeleteRepertoire(); // Proceed with deletion
      setIsConfirmingDelete(false);
      setShowError(false);
    } else {
      setShowError(true); // Show error if names do not match
    }
  };

  // Update the selected repertoire in Firebase whenever the relevant states change
  useEffect(() => {
    if (!selectedRepertoireId || !userId) return;

    // Create the updated repertoire object with updated properties
    const updatedRepertoire = {
      ...repertoires[selectedRepertoireId], // Preserve existing properties
      title: chapterTitle,
      description: chapterDescription,
      author: author,
      lines: lines,
    };

    // Function to update the selected repertoire in Firebase
    const updateRepertoireInFirebase = async () => {
      try {
        await set(
          ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}`),
          updatedRepertoire
        );
      } catch (error) {
        // Handle any errors (optional)
      }
    };

    updateRepertoireInFirebase();
  }, [
    chapterTitle,
    chapterDescription,
    author,
    lines,
    selectedRepertoireId,
    userId,
    repertoires,
  ]);

  // Initialize Stockfish engine when the component mounts and clean up on unmount
  useEffect(() => {
    const stockfishInstance = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    setStockfish(stockfishInstance); // Save Stockfish instance to state

    // Terminate the Stockfish instance on cleanup
    return () => {
      stockfishInstance.terminate();
    };
  }, []);

  // Close menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && // Check if menuRef is set
        !menuRef.current.contains(event.target) // Detect clicks outside the menu
      ) {
        handleMenuClose(); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleMenuClose]);

  // Fetch repertoires from Firebase and manage initial and subsequent data loading
  useEffect(() => {
    const unsubscribe = fetchRepertoires(userId, (data) => {
      setRepertoires(data);

      // Sort repertoires by their order property
      const sortedRepertoires = Object.entries(data).sort(
        ([, a], [, b]) => a.order - b.order
      );

      // Handle initial load or when repertoires are empty
      if (sortedRepertoires.length > 0 && isInitialLoad.current) {
        const [firstRepertoireId, firstRepertoire] = sortedRepertoires[0];

        // Set states for the first repertoire
        setSelectedRepertoireId(firstRepertoireId);
        setChapterTitle(firstRepertoire.title || "");
        setChapterDescription(firstRepertoire.description || "");
        setAuthor(firstRepertoire.author || "");
        setLines(
          firstRepertoire.lines || {
            id: "root",
            san: "",
            next: null,
            variations: [],
            arrows: [],
            highlightSquares: [],
          }
        );
        setGame(new Chess());
        setSelectedNode(firstRepertoire.lines || null);
        setNodePath(["root"]);
        setArrows([]);
        setHighlights([]);
        setComment("");
        setAnnotation("");
        isInitialLoad.current = false; // Mark initial load complete
      } else if (sortedRepertoires.length === 0) {
        // Clear states if no repertoires are available
        setSelectedRepertoireId(null);
        setChapterTitle("");
        setChapterDescription("");
        setAuthor("");
        setLines({
          id: "root",
          san: "",
          next: null,
          variations: [],
          arrows: [],
          highlightSquares: [],
        });
        setGame(new Chess());
        setSelectedNode(null);
        setNodePath(["root"]);
        setArrows([]);
        setHighlights([]);
        setComment("");
        setAnnotation("");
      }
    });

    // Cleanup by unsubscribing from the Firebase listener
    return () => {
      unsubscribe();
    };
  }, [userId]);

  // Update arrows and highlights based on the selected node
  useEffect(() => {
    if (selectedNode) {
      setArrows(selectedNode.arrows || []);
      setHighlights(selectedNode.highlightSquares || []);
    } else {
      setArrows([]);
      setHighlights([]);
    }
  }, [selectedNode, selectedNode?.arrows, selectedNode?.highlightSquares]);

  // Evaluate the current position using Stockfish
  useEffect(() => {
    if (stockfish && selectedNode) {
      const currentFen = game.fen();

      getEvaluation(currentFen).catch((err) => {
        if (err !== "Evaluation aborted.") {
          console.error(err);
        }
      });
    }

    // Cleanup the abort controller on unmount or dependency change
    return () => {
      if (evaluationAbortController.current) {
        evaluationAbortController.current.abort();
      }
    };
  }, [game, selectedNode, getEvaluation, stockfish, depth, multiPV]);

  // Add and remove event listeners for chessboard interactions
  useEffect(() => {
    const chessboardElement = chessboardRef.current;

    if (chessboardElement) {
      chessboardElement.addEventListener("mousedown", handleMouseDown);
      chessboardElement.addEventListener("mouseup", handleMouseUp);

      return () => {
        chessboardElement.removeEventListener("mousedown", handleMouseDown);
        chessboardElement.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [handleMouseUp, handleMouseDown]);

  // Handle keyboard navigation for previous and next actions
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft": // Navigate back
          handleBack();
          break;
        case "ArrowRight": // Navigate forward
          handleNext();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleBack, handleNext]);

  // Identify and update the opening details based on the game's history
  useEffect(() => {
    if (!game) return;

    const opening = findOpening(game.history());

    if (opening) {
      setOpeningDetails({
        name: opening.name,
        eco: opening.eco,
        pgn: opening.pgn,
        transposition: opening.transposition,
      });
    } else {
      setOpeningDetails(null);
    }
  }, [game]);

  // Share page data via the custom `useShare` hook
  const { setShareData } = useShare();
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/#Page URL#",
      title: "Chessboard Magic - #Page Name#",
      description: "#Page Description#",
    };

    setShareData(newShareData);
  }, [setShareData]);

  return (
    <Box>
      <Helmet>
        <title>Repertiore Builder</title>
        <meta name="description" content="#Page Description#" />
      </Helmet>
      <Box display="flex" flexDirection="row" sx={{ p: 3, gap: 1 }}>
        <Box
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
            minWidth: "400px",
            maxWidth: "400px",
            mb: 10,
          }}
        >
          {/** This renders the Chessboard */}
          <Box
            sx={{
              width: "100%", // Use percentage or a fixed value like '400px'
              height: "100%", // Match the height as needed
              position: "relative", // Ensures any absolute positioning within the Box works
            }}
          >
            <Box ref={chessboardRef}>
              <Chessboard
                position={game.fen()}
                onPieceDrop={handleMove}
                areArrowsAllowed={false}
                boardOrientation={boardOrientation}
                customLightSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .lightSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .lightSquarePattern,
                }}
                customDarkSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "Modern Minimal"].darkSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "Modern Minimal"]
                      .darkSquarePattern,
                }}
                customPieces={pieceSets[userData?.pieceset || "Maestro"]}
              />
            </Box>
            <svg
              style={{
                position: "absolute",
                top: chessboardRef.current?.offsetTop || 0,
                left: chessboardRef.current?.offsetLeft || 0,
                width: chessboardRef.current?.offsetWidth || "100%",
                height: chessboardRef.current?.offsetHeight || "100%",
                pointerEvents: "none",
                overflow: "visible",
                zIndex: 9999,
              }}
            >
              <defs>
                {arrows.map(([start, end, color], index) => (
                  <marker
                    key={`arrowhead-${index}`}
                    id={`arrowhead-${index}`}
                    markerWidth="10"
                    markerHeight="7"
                    refX="10"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon points="0 0, 10 3.5, 0 7" fill={color} />
                  </marker>
                ))}
              </defs>
              {arrows.map(([start, end, color], index) => {
                // Adjust the positions of the arrows based on board orientation
                const getAdjustedPosition = (square) => {
                  const file = square.charCodeAt(0) - 97; // 'a' = 0, 'b' = 1, ...
                  const rank = 8 - parseInt(square[1], 10); // Chess ranks are inverted (8 at the top)
                  const adjustedFile =
                    boardOrientation === "white" ? file : 7 - file;
                  const adjustedRank =
                    boardOrientation === "white" ? rank : 7 - rank;
                  return {
                    file: adjustedFile,
                    rank: adjustedRank,
                  };
                };

                const startPos = getAdjustedPosition(start);
                const endPos = getAdjustedPosition(end);

                return (
                  <Arrow
                    key={index}
                    start={`${String.fromCharCode(97 + startPos.file)}${
                      8 - startPos.rank
                    }`}
                    end={`${String.fromCharCode(97 + endPos.file)}${
                      8 - endPos.rank
                    }`}
                    color={color}
                    markerId={`arrowhead-${index}`}
                  />
                );
              })}
              {highlights.map(([square, color], index) => {
                // Calculate the position of the square based on the chessboard square
                const getAdjustedPosition = (square) => {
                  const file = square.charCodeAt(0) - 97; // 'a' = 0, 'b' = 1, ...
                  const rank = 8 - parseInt(square[1], 10); // Chess ranks are inverted (8 at the top)
                  const adjustedFile =
                    boardOrientation === "white" ? file : 7 - file;
                  const adjustedRank =
                    boardOrientation === "white" ? rank : 7 - rank;
                  return { file: adjustedFile, rank: adjustedRank };
                };

                const { file, rank } = getAdjustedPosition(square);

                const squareSize = 12.5; // Percentage size of a chessboard square
                const padding = 0.5; // Padding to make the square 99% of the chessboard square
                const size = squareSize - padding; // Adjusted size for 99% width
                const x = file * squareSize + padding / 2; // Center the smaller square horizontally
                const y = rank * squareSize + padding / 2; // Center the smaller square vertically

                return (
                  <rect
                    key={`highlight-${index}`}
                    x={`${x}%`} // Convert to percentage for positioning
                    y={`${y}%`} // Convert to percentage for positioning
                    width={`${size}%`} // Set width to 99% of the chessboard square
                    height={`${size}%`} // Set height to 99% of the chessboard square
                    rx="1%" // Rounded corner radius (adjust as needed)
                    ry="1%" // Rounded corner radius (adjust as needed)
                    stroke={color} // Use the color for the border
                    strokeWidth="3" // Adjust the stroke width
                    fill="none" // Make the rectangle transparent
                    opacity="0.9" // Optional: Adjust opacity for the stroke
                  />
                );
              })}
              {renderAnnotation()} {/* Render the annotation */}
            </svg>
            {/** Chessboard Buttons */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start" // Align items to the top-left
              justifyContent="flex-start" // Align items to the left
              textAlign="left" // Ensure text content is left-aligned
              sx={{
                pt: 1,
                pb: 1,
                width: "100%", // Ensure the container spans the full width
              }}
            >
              <IconButton
                onClick={() => {
                  setSelectedNode(lines); // Set the selected node to root
                  setComment(""); // Clear comments
                  setAnnotation(""); // Clear annotations
                  setGame(new Chess()); // Reset the game state
                  setNodePath(["root"]); // Reset the node path
                }}
                disabled={!selectedNode || selectedNode.id === "root"} // Disable if already at the root
              >
                <FirstPageIcon />
              </IconButton>
              <IconButton
                onClick={handleBack}
                disabled={!selectedNode || selectedNode.id === "root"} // Disabled if no parent
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                disabled={!selectedNode?.next} // Disabled if no next node
              >
                <NavigateNextIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (!selectedNode) return;

                  let currentNode = selectedNode; // Start from the currently selected node
                  const path = [...nodePath]; // Clone the existing path

                  // Traverse to the last node
                  while (currentNode?.next) {
                    currentNode = currentNode.next;
                    path.push(currentNode.id); // Add to the path
                  }

                  setSelectedNode(currentNode); // Set the selected node to the last node
                  setNodePath(path); // Update the node path

                  // Generate move history and update game state
                  const history = generateHistoryFromNode(currentNode);
                  setGame(() => {
                    const newGame = new Chess();
                    history.forEach((san) => {
                      newGame.move(san);
                    });
                    return newGame;
                  });
                }}
                disabled={!selectedNode} // Disable if no node is selected
              >
                <LastPageIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  setBoardOrientation((prev) =>
                    prev === "white" ? "black" : "white"
                  )
                }
              >
                <SwapVertRoundedIcon />
              </IconButton>
            </Box>
            <Card
              sx={{
                position: "relative", // Required for pseudo-element positioning
                backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
                color: "white", // Text color
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
                "::before": {
                  content: '""', // Required for pseudo-element
                  position: "absolute", // Position relative to the parent
                  top: 0, // Align with the top of the parent
                  left: 0, // Align with the left of the parent
                  width: "100%", // Match the width of the parent
                  height: "100%", // Match the height of the parent
                  backgroundColor: "transparent", // Transparent background to show the card
                  border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                  clipPath:
                    "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                  zIndex: -1, // Place behind the card content
                  pointerEvents: "none", // Ensure the outline does not interfere with interactions
                },
                p: 0, // Padding for the card content
              }}
            >
              <CardHeader
                avatar={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SubjectIcon />
                  </Box>
                }
                title={
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    Repertoire
                  </Typography>
                }
                action={
                  <IconButton color="primary" onClick={handleAddRepertoire}>
                    <ControlPointIcon sx={{ color: "white" }} />
                  </IconButton>
                }
                sx={{ pt: 1, pb: 1 }}
              />

              <Divider />
              <CardContent
                sx={{
                  padding: "16px !important", // Apply even padding to all sides
                }}
              >
                {/* Render Repertoires */}
                <List sx={{ p: 0 }}>
                  {Object.entries(repertoires)
                    .sort(([, a], [, b]) => a.order - b.order) // Sort by `order` field
                    .map(([id, repertoire], index, array) => (
                      <ListItem
                        key={id}
                        onClick={() => handleRepertoireClick(id)}
                        sx={{
                          mb: 1, // Margin bottom
                          position: "relative", // Required for pseudo-element positioning
                          color: "white", // Ensure text color is white
                          backgroundColor:
                            selectedRepertoireId === id
                              ? "rgba(255, 255, 255, 0.05)" // Background color for selected item
                              : "transparent", // Transparent background for non-selected items
                          clipPath:
                            "polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px)", // Clipping corners
                          "&:hover": {
                            backgroundColor:
                              selectedRepertoireId === id
                                ? "rgba(255, 255, 255, 0.05)" // Maintain the same background color on hover for selected
                                : "rgba(255, 255, 255, 0.05)", // Subtle hover effect for non-selected items
                          },
                          "::before": {
                            content: '""', // Required for pseudo-element
                            position: "absolute", // Position relative to the parent
                            top: 0, // Align with the top of the parent
                            left: 0, // Align with the left of the parent
                            width: "100%", // Match the width of the parent
                            height: "100%", // Match the height of the parent
                            backgroundColor: "transparent", // Transparent background to show ListItem background
                            border: "1px solid rgba(0, 0, 0, 0.25)",
                            clipPath:
                              "polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px)", // Match the clipping path
                            zIndex: -1, // Place behind the ListItem content
                            pointerEvents: "none", // Ensure the outline doesn't interfere with interactions
                          },
                        }}
                      >
                        <ListItemText
                          primary={repertoire.title}
                          primaryTypographyProps={{
                            variant: "body1",
                            color: "white", // Set text color explicitly to white
                          }}
                          sx={{ p: 0 }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="default"
                            disabled={index === 0} // Disable "Up" button for the first item
                            onClick={() => reorderItem(id, -1)} // Move up
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            color="default"
                            disabled={index === array.length - 1} // Disable "Down" button for the last item
                            onClick={() => reorderItem(id, 1)} // Move down
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={() => {
                              const repertoire = repertoires[id];
                              setSelectedRepertoireId(id);
                              setChapterTitle(repertoire.title);
                              setChapterDescription(repertoire.description);
                              setAuthor(repertoire.author);
                              setBoardOrientation(
                                repertoire.boardOrientation || "white"
                              );
                              setVisibility(repertoire.visibility || "Private");
                              setAllowCopy(repertoire.allowCopy || "No");
                              setIsModalOpen(true); // Open dialog
                              setInputRepertoireName("");
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </CardContent>
            </Card>
            <Dialog
              open={isModalOpen}
              sx={{
                "& .MuiBackdrop-root": {
                  backgroundColor: "rgba(0, 0, 0, 0.9)", // Darker background with 80% opacity
                },
                "& .MuiPaper-root": {
                  position: "relative", // Required for pseudo-element positioning
                  backgroundColor: "#0D0D0D !important", // Card background color
                  backgroundImage: "none",
                  color: "white", // Text color
                  clipPath:
                    "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
                  "::before": {
                    content: '""', // Required for pseudo-element
                    position: "absolute", // Position relative to the parent
                    top: 0, // Align with the top of the parent
                    left: 0, // Align with the left of the parent
                    width: "100%", // Match the width of the parent
                    height: "100%", // Match the height of the parent
                    backgroundColor: "transparent", // Transparent background to show the card
                    border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                    clipPath:
                      "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                    zIndex: -1, // Place behind the card content
                    pointerEvents: "none", // Ensure the outline does not interfere with interactions
                  },
                  p: 1, // Padding for the card content
                },
              }}
              onClose={() => setIsModalOpen(false)}
            >
              <DialogTitle sx={{ pt: 1, pb: 1 }}>Edit Repertoire</DialogTitle>
              <Divider />
              <DialogContent sx={{ p: 2, mb: 1 }}>
                <TextField
                  fullWidth
                  label="Name"
                  value={chapterTitle}
                  onChange={(e) => setChapterTitle(e.target.value)}
                  variant="outlined"
                  sx={{ pb: 3, mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={chapterDescription}
                  onChange={(e) => setChapterDescription(e.target.value)}
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={{ pb: 3 }}
                />
                <TextField
                  fullWidth
                  label="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  variant="outlined"
                  sx={{ pb: 3 }}
                />
                <Box
                  sx={{
                    display: "flex", // Arrange items in a row
                    gap: 2, // Add spacing between groups
                    alignItems: "center", // Vertically align items
                    flexWrap: "wrap", // Allow wrapping for responsiveness
                  }}
                >
                  {/* Board Orientation */}
                  <FormControl component="fieldset" sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.5, fontSize: "0.8rem" }}
                    >
                      Orientation
                    </Typography>
                    <ToggleButtonGroup
                      value={boardOrientation}
                      exclusive // Ensures only one button can be selected
                      onChange={(e, newValue) => {
                        if (newValue !== null) setBoardOrientation(newValue); // Prevent null value on deselect
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiToggleButton-root": {
                          color: "#FFFFFF", // Unselected color
                          border: "1px solid rgba(255, 255, 255, 0.3)", // Border color
                          textTransform: "none", // Ensure no underline or other decorations
                          "&.Mui-selected": {
                            color: "#FFFFFF", // Selected text color
                            backgroundColor: "#0d47a1", // Selected background
                          },
                          "&:hover": {
                            color: "#FFFFFF", // Hover text color
                            backgroundColor: "rgba(13, 71, 161, 0.3)", // Slightly darker hover color
                          },
                        },
                      }}
                    >
                      <ToggleButton value="white">White</ToggleButton>
                      <ToggleButton value="black">Black</ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>

                  {/* Visibility */}
                  <FormControl component="fieldset" sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.5, fontSize: "0.8rem" }}
                    >
                      Visibility
                    </Typography>
                    <ToggleButtonGroup
                      value={visibility}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) setVisibility(newValue);
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiToggleButton-root": {
                          color: "#FFFFFF", // Unselected color
                          border: "1px solid rgba(255, 255, 255, 0.3)", // Border color
                          textTransform: "none",
                          "&.Mui-selected": {
                            color: "#FFFFFF", // Selected text color
                            backgroundColor: "#0d47a1", // Selected background
                          },
                          "&:hover": {
                            color: "#FFFFFF", // Selected text color
                            backgroundColor: "rgba(13, 71, 161, 0.3)", // Slightly darker hover color
                          },
                        },
                      }}
                    >
                      <ToggleButton value="Public">Public</ToggleButton>
                      <ToggleButton value="Private">Private</ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>

                  {/* Allow Copy */}
                  <FormControl component="fieldset" sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.5, fontSize: "0.8rem" }}
                    >
                      Allow Copy
                    </Typography>
                    <ToggleButtonGroup
                      value={allowCopy}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) setAllowCopy(newValue);
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiToggleButton-root": {
                          color: "#FFFFFF", // Unselected color
                          border: "1px solid rgba(255, 255, 255, 0.3)", // Border color
                          textTransform: "none",
                          "&.Mui-selected": {
                            color: "#FFFFFF", // Selected text color
                            backgroundColor: "#0d47a1", // Selected background
                          },
                          "&:hover": {
                            color: "#FFFFFF", // Selected text color
                            backgroundColor: "rgba(13, 71, 161, 0.3)", // Slightly darker hover color
                          },
                        },
                      }}
                    >
                      <ToggleButton value="Yes">Yes</ToggleButton>
                      <ToggleButton value="No">No</ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </DialogContent>
              <Divider />
              <DialogActions
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* Conditional rendering based on delete confirmation */}
                {!isConfirmingDelete ? (
                  <>
                    {/* Delete Text */}
                    {selectedRepertoireId && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: colors.red[100],
                          cursor: "pointer",
                          "&:hover": {
                            textDecoration: "underline",
                            color: colors.red[200],
                          },
                        }}
                        onClick={handleDeleteClick}
                      >
                        Delete this Repertoire
                      </Typography>
                    )}

                    {/* Save and Cancel Buttons */}
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        onClick={handleSaveRepertoire}
                        sx={{
                          backgroundColor: colors.green[100],
                          "&:hover": {
                            backgroundColor: colors.green[200],
                          },
                        }}
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setIsModalOpen(false)}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {/* Confirmation Input and Buttons */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="body2">
                        Please type{" "}
                        <strong
                          style={{
                            backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red with transparency (20%)
                            border: "1px solid rgba(255, 0, 0, 0.2)", // Slightly darker red with transparency (50%)
                            padding: "2px 4px", // Add some padding for better visual appearance
                            borderRadius: "2px", // Optional: round the edges
                            display: "inline-block", // Ensure the styling wraps properly
                          }}
                        >
                          {chapterTitle}
                        </strong>{" "}
                        to confirm deletion.
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={inputRepertoireName}
                        onChange={(e) => setInputRepertoireName(e.target.value)}
                        placeholder="Repertoire name"
                        autoComplete="off"
                      />
                      {showError && (
                        <Box mt={1}>
                          <Alert severity="error">
                            The repertoire name does not match. Please try
                            again.
                          </Alert>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Stacks the buttons vertically
                        gap: 1, // Adds spacing between the buttons
                        alignItems: "flex-start", // Aligns buttons to the top
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleConfirmDelete}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelDelete}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </Box>

        <Box
          sx={{
            overflowY: "auto",
            display: "flex", // Use flexbox
            flexDirection: "column", // Arrange children in a column
            gap: 1, // Apply spacing between children
            minWidth: "400px",
            width: "100%",
          }}
        >
          {/** Shows the Moves Panel */}
          <Card
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
              color: "white", // Text color
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 1, // Padding for the card content
            }}
          >
            <CardHeader
              avatar={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <KeyboardOptionKeyIcon />
                </Box>
              }
              sx={{ pt: 1, pb: 1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Lines
                </Typography>
              }
              action={
                <Box>
                  <IconButton onClick={handleLinesMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={linesMenuAnchor}
                    open={Boolean(linesMenuAnchor)}
                    onClose={handleLinesMenuClose}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "#0D0D0D !important", // Match the background color of your app
                        backgroundImage: "none",
                        color: "white", // Set text color to white
                      },
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor:
                            "rgba(255, 255, 255, 0.1) !important", // Subtle hover effect
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleShowCommentsChange}>
                      {showComments ? "Hide Comments" : "Show Comments"}
                    </MenuItem>
                    <MenuItem onClick={handleShowAnnotationChange}>
                      {showAnnotations
                        ? "Hide Annotations"
                        : "Show Annotations"}
                    </MenuItem>
                  </Menu>
                </Box>
              }
            />

            <Divider />
            <CardContent
              sx={{
                padding: "16px !important", // Apply even padding to all sides
              }}
            >
              <Box sx={{ mt: 1 }}>{displayMoves(lines)}</Box>
              {/** Drop Down Menu */}
              <Menu
                anchorEl={moveMenuAnchor}
                open={Boolean(moveMenuAnchor)}
                onClose={handleMoveMenuClose}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#0D0D0D !important", // Match the background color of your app
                    backgroundImage: "none",
                    color: "white", // Set text color to white
                  },
                  "& .MuiMenuItem-root": {
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.05) !important", // Subtle hover effect
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDeleteMove();
                    handleMoveMenuClose();
                  }}
                >
                  Delete Move
                </MenuItem>
                <Divider
                  sx={{
                    margin: 0, // Set margin to 0
                    padding: 0, // Set padding to 0
                    "&.MuiDivider-root": {
                      margin: 0, // Ensure root styles are also overridden
                      padding: 0, // Ensure padding is overridden
                    },
                  }}
                />
                <MenuItem
                  onClick={() => {
                    handlePromoteVariation();
                    handleMoveMenuClose();
                  }}
                >
                  Promote Variation
                </MenuItem>

                <Divider
                  sx={{
                    margin: 0, // Set margin to 0
                    padding: 0, // Set padding to 0
                    "&.MuiDivider-root": {
                      margin: 0, // Ensure root styles are also overridden
                      padding: 0, // Ensure padding is overridden
                    },
                  }}
                />
                <MenuItem
                  onClick={() => {
                    handleDeleteHighlights();
                    handleMoveMenuClose();
                  }}
                >
                  Delete Highlights
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteComments();
                    handleMoveMenuClose();
                  }}
                >
                  Delete Comments
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteArrows();
                    handleMoveMenuClose();
                  }}
                >
                  Delete Arrows
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteAnnotation();
                    handleMoveMenuClose();
                  }}
                >
                  Delete Annotation
                </MenuItem>
              </Menu>

              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="custom styled tabs"
                  sx={{
                    "& .MuiTab-root": {
                      color: "gray", // Text color for unselected tabs
                      pt: 1, // Adjust padding
                      pb: 1,
                      transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
                      minWidth: "50px", // Set tab width
                      maxWidth: "50px", // Ensure consistent width
                      textAlign: "center", // Center align text or icon
                      "&:hover": {
                        color: "white", // Change text color to white on hover
                      },
                    },
                    "& .Mui-selected": {
                      color: "white !important", // Text color for the selected tab
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "white", // White sliding indicator
                      height: "2px", // Thickness of the line
                    },
                    borderBottom: 1,
                    borderColor: "divider", // Add bottom border to the tabs container
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <Tab
                    icon={
                      <Box
                        sx={{
                          width: "20px", // Set desired width
                          height: "20px", // Set desired height
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CommentIcon />
                      </Box>
                    }
                    aria-label="Comment"
                    sx={{
                      fontSize: "10px", // Adjust font size for the icon label
                    }}
                  />
                  <Tab
                    label="!?"
                    sx={{
                      fontSize: "22px", // Larger font size for text

                      textTransform: "none", // Prevent uppercase transformation
                    }}
                  />
                </Tabs>

                {/* Details Tab Panel
              <Box role="tabpanel" hidden={tabValue !== 0}>
                {tabValue === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Arrange fields vertically
                      gap: 1, // Add spacing between fields
                      width: "100%", // Full width of the container
                      pt: 1, // Padding around the box
                    }}
                  >
                    <TextField
                      label="Chapter Title" // Add label
                      value={chapterTitle}
                      onChange={(e) => setChapterTitle(e.target.value)}
                      placeholder="Enter Title"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        autoComplete: "off", // Disable autocomplete
                      }}
                    />

                    <TextField
                      label="Chapter Description" // Add label
                      value={chapterDescription}
                      onChange={(e) => setChapterDescription(e.target.value)}
                      placeholder="Enter description"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        autoComplete: "off", // Disable autocomplete
                      }}
                    />

                    <TextField
                      label="Author" // Add label
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      placeholder="Enter Author Name"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        autoComplete: "off", // Disable autocomplete
                      }}
                    />
                  </Box>
                )}
              </Box>*/}

                {/* Comment Tab Panels */}
                <Box role="tabpanel" hidden={tabValue !== 0}>
                  {tabValue === 0 && (
                    <Box sx={{ width: "100%", pt: 1 }}>
                      <TextField
                        value={comment}
                        onChange={handleCommentChange}
                        multiline
                        rows={4} // Set the number of visible rows
                        placeholder="Add comments..."
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  )}
                </Box>
                {/* Annotate Tab Panel */}
                <Box role="tabpanel" hidden={tabValue !== 1}>
                  {tabValue === 1 && (
                    <Box sx={{ display: "flex", gap: 0, width: "100%", pt: 1 }}>
                      {selectedNode?.id === "root" ? (
                        <Typography
                          sx={{
                            color: "gray", // Text color for the message
                            fontSize: "16px", // Font size for the message
                            textAlign: "center", // Center-align the message
                            width: "100%", // Take the full width of the panel
                            margin: "20px 0", // Add some vertical spacing
                          }}
                        >
                          Please make a move before adding annotations.
                        </Typography>
                      ) : (
                        Object.values(categories).map((category, colIndex) => (
                          <Box key={colIndex} sx={{ flex: 1 }}>
                            {category.map((item, index) => (
                              <Button
                                key={index}
                                fullWidth
                                variant="menu"
                                sx={{
                                  justifyContent: "flex-start",
                                  textTransform: "none", // Prevent text from transforming to uppercase
                                  p: 0,
                                  height: "30px", // Adjust button height
                                  alignItems: "stretch", // Stretch content to fill height
                                }}
                                onClick={() =>
                                  handleAnnotationChange(item.symbol)
                                }
                              >
                                <Box
                                  sx={{
                                    backgroundColor:
                                      annotation === item.symbol
                                        ? getCategoryColorBySymbol(item.symbol)
                                        : "rgba(255, 255, 255, 0.1)", // Highlight in blue if matched
                                    color:
                                      annotation === item.symbol
                                        ? "white"
                                        : "gray", // White text on blue background
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    width: "30px", // Fixed width for the symbol area
                                    display: "flex",
                                    alignItems: "center", // Center the symbol vertically
                                    justifyContent: "center", // Center the symbol horizontally
                                  }}
                                >
                                  {item.symbol}
                                </Box>
                                <Typography
                                  sx={{
                                    color:
                                      annotation === item.symbol
                                        ? getCategoryColorBySymbol(item.symbol)
                                        : "gray", // Highlight in blue if matched
                                    fontSize: "12px", // Reduced font size for description
                                    paddingLeft: "8px", // Add spacing between symbol and description
                                    display: "flex",
                                    alignItems: "center", // Align text vertically
                                  }}
                                >
                                  {item.description}
                                </Typography>
                              </Button>
                            ))}
                          </Box>
                        ))
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box
          sx={{
            p: 0,
            overflowY: "auto",
            minWidth: "400px",
            display: "flex", // Use flexbox
            flexDirection: "column", // Arrange children in a column
            gap: 1, // Apply spacing between children
          }}
        >
          {/** Shows the Opening Name Panel */}
          <Card
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
              color: "white", // Text color
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 1, // Padding for the card content
            }}
          >
            <CardHeader
              avatar={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LocalLibraryOutlinedIcon />
                  {/* Replace `SomeIcon` with your desired icon */}
                </Box>
              }
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Opening Details
                </Typography>
              }
              sx={{ pt: 1, pb: 1 }}
            />
            <Divider />
            <CardContent
              sx={{
                padding: "16px !important", // Apply even padding to all sides
              }}
            >
              {openingDetails ? (
                <>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {openingDetails.eco}: {openingDetails.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {openingDetails.pgn}
                  </Typography>
                  {openingDetails.transposition && (
                    <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                      (via Transposition)
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>No opening found</Typography>
              )}
            </CardContent>
          </Card>

          {/** Shows the Engine Panel */}
          <Card
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
              color: "white", // Text color
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 1, // Padding for the card content
            }}
          >
            <CardHeader
              avatar={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EngineIcon />
                  {/* Replace `SomeIcon` with your desired icon */}
                </Box>
              }
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {engineOn ? "Stockfish On" : "Stockfish Off"}
                </Typography>
              }
              sx={{ pt: 1, pb: 1 }}
              action={
                <Switch
                  checked={engineOn} // State variable
                  onChange={(event) => setEngineOn(event.target.checked)} // Update the state
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "white", // Circle color when off
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#0d47a1", // Circle color when on
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "grey", // Track color when on
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "grey", // Track color when off
                    },
                  }}
                />
              }
            />

            {engineOn && ( // Render only if engineOn is true
              <>
                <Divider />
                <CardContent
                  sx={{
                    padding: "16px !important", // Apply even padding to all sides
                  }}
                >
                  {/* Engine Status Text */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px", // Add some spacing below the text
                      color: "rgba(255, 255, 255, 0.7)", // Slightly lighter text color
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        marginRight: "5px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Stockfish running at depth{" "}
                    </Typography>
                    <Box
                      sx={{
                        display: "inline-block",
                        cursor: "pointer",
                        borderBottom: "1px dashed white", // Indicate editable text
                        color: "white",
                      }}
                    >
                      <input
                        type="number"
                        value={depth}
                        onChange={(e) => setDepth(Number(e.target.value))}
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "white",
                          textAlign: "center",
                          width: "40px",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ marginX: "5px" }}>
                      for
                    </Typography>
                    <Box
                      sx={{
                        display: "inline-block",
                        cursor: "pointer",
                        borderBottom: "1px dashed white", // Indicate editable text
                        color: "white",
                      }}
                    >
                      <input
                        type="number"
                        value={multiPV}
                        onChange={(e) => setMultiPV(Number(e.target.value))}
                        style={{
                          background: "transparent",
                          border: "none",
                          color: "white",
                          textAlign: "center",
                          width: "40px",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                      lines.
                    </Typography>
                  </Box>
                  {/* Engine Output Section */}

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            p: 0,
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "6%",
                              fontSize: "0.75em",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            Eval
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "6%",
                              fontSize: "0.75em",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            Win %
                          </TableCell>
                          {/*<TableCell
                            sx={{
                              width: "6%",
                              fontSize: "0.75em",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            Depth
                          </TableCell>*/}
                          <TableCell
                            sx={{
                              width: "82%",
                              fontSize: "0.75em",
                              color: "rgba(255, 255, 255, 0.7)",
                            }} // Remaining space for Moves
                          >
                            Moves
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentLines.map((line, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              p: 0,
                              cursor: "pointer", // Make the entire row clickable
                              "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.1)", // Add a hover effect
                              },
                            }}
                            onClick={() => {
                              if (line.moves.length > 0) {
                                const firstMove = line.moves[0];

                                // Update the game state with the first move
                                setGame((prevGame) => {
                                  const tempChess = new Chess(prevGame.fen());
                                  tempChess.move(firstMove); // Apply the first move
                                  return tempChess;
                                });

                                // Call addMoveToLines for the first move
                                addMoveToLines(firstMove);
                              }
                            }}
                          >
                            <TableCell
                              sx={{
                                padding: "8px 16px", // Adjust top, bottom, left, and right padding
                              }}
                            >
                              <Typography variant="body2">
                                {line.eval}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "8px 16px", // Adjust top, bottom, left, and right padding
                              }}
                            >
                              <Typography variant="body2">
                                {Number(line.win_probability).toFixed(1)}%
                              </Typography>
                            </TableCell>
                            {/*<TableCell
                              sx={{
                                padding: "8px 16px", // Adjust top, bottom, left, and right padding
                              }}
                            >
                              <Typography variant="body2">
                                {line.depth}
                              </Typography>
                            </TableCell>*/}
                            <TableCell
                              sx={{
                                padding: "8px 16px", // Adjust top, bottom, left, and right padding
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: "rgba(255, 255, 255, 0.7)" }} // Lighter text for moves
                              >
                                {line.moves && Array.isArray(line.moves)
                                  ? line.moves.slice(0, 12).join(" ")
                                  : "No moves available"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </>
            )}
          </Card>

          {debugMode && (
            <Card
              sx={{
                position: "relative", // Required for pseudo-element positioning
                backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
                color: "white", // Text color
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
                "::before": {
                  content: '""', // Required for pseudo-element
                  position: "absolute", // Position relative to the parent
                  top: 0, // Align with the top of the parent
                  left: 0, // Align with the left of the parent
                  width: "100%", // Match the width of the parent
                  height: "100%", // Match the height of the parent
                  backgroundColor: "transparent", // Transparent background to show the card
                  border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
                  clipPath:
                    "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                  zIndex: -1, // Place behind the card content
                  pointerEvents: "none", // Ensure the outline does not interfere with interactions
                },
                p: 1, // Padding for the card content
              }}
            >
              <CardHeader
                avatar={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SettingsEthernetIcon />
                    {/* Replace `SomeIcon` with your desired icon */}
                  </Box>
                }
                title={
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    Debug Information
                  </Typography>
                }
                action={
                  <Switch
                    checked={showDebugMode} // Toggle debug mode
                    onChange={(event) => setShowDebugMode(event.target.checked)} // Update the debug mode state
                    sx={{
                      "& .MuiSwitch-switchBase": {
                        color: "white", // Circle color when off
                      },
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#0d47a1", // Circle color when on
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "grey", // Track color when on
                        },
                      "& .MuiSwitch-track": {
                        backgroundColor: "grey", // Track color when off
                      },
                    }}
                  />
                }
                sx={{ pt: 1, pb: 1 }}
              />

              {showDebugMode && (
                <>
                  <Divider />
                  <CardContent
                    sx={{
                      padding: "16px !important", // Apply even padding to all sides
                    }}
                  >
                    {/* Debug Selected Node */}
                    <Box sx={{ mt: 0, mb: 2 }}>
                      <Typography
                        component="pre"
                        sx={{
                          whiteSpace: "pre-wrap",
                          fontSize: "14px",
                          color: "rgba(255, 255, 255, 0.8)", // Slightly lighter color
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1", // Blue color for the label
                            display: "block", // Make it a block element for separation
                          }}
                        >
                          SELECTED NODE
                        </Typography>
                        {selectedNode?.id || "No node selected"}
                      </Typography>
                    </Box>

                    {/* Debug Lines */}
                    <Typography
                      component="pre"
                      sx={{
                        whiteSpace: "pre-wrap",
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.8)", // Slightly lighter color
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "bold",
                          color: "#0d47a1", // Blue color for the label
                          display: "block", // Make it a block element for separation
                        }}
                      >
                        REPERTOIRE DATA
                      </Typography>
                      {JSON.stringify(lines, null, 2)}
                    </Typography>
                  </CardContent>
                </>
              )}
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default RepertoireBuilder;
