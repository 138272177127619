export const guesstheeval = [
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 g2g3 f8b4 c1d2 b4e7",
    DEPTH: 63,
  },
  {
    FEN: "rnbqk1nr/pp2p1bp/3p2p1/2pP1p2/2P5/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 64,
    MOVES: "g2g3 g8f6 f1g2 e8h8 e1h1 b8a6 f1e1 a8b8 h2h3 a6c7",
    DEPTH: 26,
  },
  {
    FEN: "2Rb2k1/3q1rpp/p3Q3/2N1p3/4P3/3Pp3/1P4PP/1K6 b - -",
    EVAL: 0,
    MOVES: "d7c8 e6c8 e3e2 c8d8 f7f8 d8d5 g8h8 c5e6 e2e1q b1a2",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "b1d2 e7e6 c2c3 c7c5 e2e3 f8d6 g1f3 e8h8 f3e5 d8c7",
    DEPTH: 53,
  },
  {
    FEN: "2b1k3/pr5R/1p2pr2/5p2/2pP4/2P5/PP3PB1/R5K1 w - -",
    EVAL: 994,
    MOVES: "g2b7 f6g6 g1f1 c8d7 f1e2 e8d8 a2a4 d8c7 b7a6 c7d6",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -",
    EVAL: 14,
    MOVES: "e7e5 g1f3 b8c6 f1b5 g8f6 e1h1 f6e4 f1e1 e4d6 f3e5",
    DEPTH: 67,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 31,
    MOVES: "d4b3 f8e7 f2f3 c8e6 d1d2 b8d7 g2g4 b7b5 e1a1 e8h8",
    DEPTH: 49,
  },
  {
    FEN: "rnbq1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R w KQ -",
    EVAL: 0,
    MOVES: "c1g5 b8a6 d1c2 c7c6 f1e2 d8e7 a1d1 a6c5 g5e3 a7a5",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "e4d5 f6d5 c3d5 d8d5 c2c3 c7c5 d4c5 d5c5 c1e3 c5c7",
    DEPTH: 40,
  },
  {
    FEN: "5rk1/p2p1p1p/2p3p1/3pr3/8/1PN3P1/P4PBP/R4K2 w - -",
    EVAL: 0,
    MOVES: "c3a4 f8b8 f2f4 e5e7 f1f2 g8f8 h2h4 a7a5 a1d1 f8e8",
    DEPTH: 56,
  },
  {
    FEN: "r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R w KQkq -",
    EVAL: 60,
    MOVES: "e1a1 c6d4 d2d4 a7a6 h2h4 h7h6 g5e3 b7b5 f1e2 e6e5",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2N3p1/8/2B1n3/2Q1B3/PPP2PPP/R3K2R b KQkq -",
    EVAL: -131,
    MOVES: "g7c3 b2c3 d7c6 f2f3 e4d6 c4b3 c8e6 e1f2 e8d7 h1e1",
    DEPTH: 31,
  },
  {
    FEN: "5rk1/pp1b1ppp/1q2p3/3pP3/1B3Pn1/3B1N2/P2KQ1PP/RN3r2 w - -",
    EVAL: -467,
    MOVES: "e2f1 b6b4 b1c3 f8c8 f1c1 d5d4 a1b1 d4c3 d2e2 b4c5",
    DEPTH: 32,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/5bN1/8/2Np1Q2/PPP2PPP/R1B1KB1R w KQkq -",
    EVAL: -64,
    MOVES: "f1d3 b8c6 d3f5 e6f5 e1h1 h7h6 g5h3 d8d7 f1e1 f8e7",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/1p1ppppp/p1n5/1Bp5/4P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 58,
    MOVES: "b5c6 d7c6 f2f4 c5c4 g1f3 g8f6 e1h1 a6a5 b2b3 c4b3",
    DEPTH: 39,
  },
  {
    FEN: "5rk1/pp1b1ppp/1q2p3/3pP3/1B3Pn1/3B1N2/P2K2PP/RN3Q2 b - -",
    EVAL: -815,
    MOVES: "b6b4 d2e2 d7b5 b1d2 b5d3 e2d3 b4b5 d3d4 b5b6 d4d3",
    DEPTH: 52,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/5bN1/4p3/2N5/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: -94,
    MOVES: "d2d3 f5g4 d1d2 e4d3 f1d3 e7e6 g5e4 f6e4 d3e4 d8d2",
    DEPTH: 45,
  },
  {
    FEN: "5k1r/p4p2/3N3p/3pP3/8/4BPb1/1r4P1/2R3K1 w - -",
    EVAL: 498,
    MOVES: "d6f5 f8e8 f5g3 e8d8 e5e6 h8g8 e3d4 b2b8 d4e5 d8e7",
    DEPTH: 52,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3pP3/3P4/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 35,
    MOVES: "f6e4 f1d3 e4c3 b2c3 c7c5 e1h1 b8c6 h2h3 c5c4 d3e2",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 41,
    MOVES: "d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 g1f3 b8c6 c1e3",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "e4e5 c8d7 c2c3 c7c5 g1f3 b8c6 f1e2 g8e7 e1h1 e7g6",
    DEPTH: 58,
  },
  {
    FEN: "r1br2k1/p2qppbp/6p1/nppP4/4PB2/2PB4/P3NPPP/2RQ1RK1 w - -",
    EVAL: 53,
    MOVES: "f4g5 h7h6 g5e3 e7e6 e3c5 d7c7 c5d4 e6d5 e4d5 c8b7",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 63,
    MOVES: "d8d5 b1c3 d5a5 g1f3 c8f5 f3e5 c7c6 c1f4 b8d7 e5c4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 12,
    MOVES: "g1f3 c7c5 b1d2 d8b6 a1b1 c5d4 e3d4 c8d7 c2c3 d7b5",
    DEPTH: 48,
  },
  {
    FEN: "r1b5/4kq2/p1Bbp1Qp/6p1/8/4B1P1/PPP4P/6K1 w - -",
    EVAL: 778,
    MOVES: "g6h6 c8d7 e3g5 e7e8 h6h8 d6f8 c6a8 d7b5 a8g2 f7a7",
    DEPTH: 58,
  },
  {
    FEN: "5k1r/p4p2/7p/3pPN2/8/4BPb1/1r4P1/2R3K1 b - -",
    EVAL: 483,
    MOVES: "b2b8 f5g3 h6h5 g3f5 h5h4 e3a7 b8a8 g1h2 h8h5 a7c5",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq -",
    EVAL: 11,
    MOVES: "c7c6 g1f3 f8g7 f1g2 d7d5 e1h1 e8h8 c4d5 c6d5 b1c3",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 41,
    MOVES: "b8d7 g1f3 g8f6 e4f6 d7f6 c1e3 f6g4 d1e2 g4e3 e2e3",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/6N1/4p3/8/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -116,
    MOVES: "e7e5 g5e4 f7f5 d2d4 e5d4 e4g5 b8c6 c2c3 g8f6 g5f3",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 41,
    MOVES: "g1f3 e7e5 f1c4 f8e7 d2d3 g8f6 f3d2 d7d6 d2f1 c8g4",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b5/4P3/3P3P/PPP2PP1/RNBQKB1R b KQkq -",
    EVAL: 148,
    MOVES: "c5f2 e1f2 f6e4 f2f3 e8h8 d1e1 d8f6 c1f4 f6b2 b1d2",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3bpn2/3p4/3P1B2/2N1P3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 21,
    MOVES: "d1f3 e8h8 g2g4 c7c5 f4d6 d8d6 e1a1 c5c4 g4g5 f6e8",
    DEPTH: 48,
  },
  {
    FEN: "r1b1k1nr/pp1pppbp/1qn3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 33,
    MOVES: "d4b5 b6a5 b1c3 g8f6 f2f3 e8h8 f1e2 a7a6 b5d4 f6h5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk1nr/ppp2p1p/3p3b/8/3PPppP/2N5/PPP3P1/R1BQKBNR b KQkq -",
    EVAL: -6,
    MOVES: "c7c6 g1e2 d8f6 e4e5 d6e5 c3e4 f6e6 d4e5 b8d7 d1d4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "c3d5 d8d5 c1e3 e8h8 c2c3 b8c6 f1e2 e7e5 d4e5 c8e6",
    DEPTH: 34,
  },
  {
    FEN: "3r2k1/1pp4p/p1n1q1p1/2Q5/1P2B3/P3P1Pb/3N1R1P/6K1 b - -",
    EVAL: -740,
    MOVES: "e6e4 c5f8 d8f8 d2e4 f8d8 f2d2 d8d2 e4d2 c6e5 d2e4",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/1p1ppppp/p1B5/2p5/4P3/2N5/PPPP1PPP/R1BQK1NR b KQkq -",
    EVAL: 87,
    MOVES: "d7c6 f2f4 e7e5 f4e5 g8e7 d2d3 e7g6 g1f3 c8g4 d1d2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3bpn2/3p4/3P1B2/2N1PQ2/PPP2PPP/R3KBNR b KQkq -",
    EVAL: 0,
    MOVES: "e8h8 g2g4 c7c5 f4d6 d8d6 e1a1 c5c4 g1e2 b8a6 e3e4",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4p3/3n4/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 69,
    MOVES: "f1e2 c7c5 e1h1 b8c6 c2c4 d5f6 c1e3 f8e7 b1c3 c5d4",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/2PP4/6P1/PP2PPBP/RNBQK1NR w KQ -",
    EVAL: 31,
    MOVES: "b1c3 d7d6 g1f3 c7c5 e1h1 b8c6 d4d5 c6a5 f3d2 a7a6",
    DEPTH: 47,
  },
  {
    FEN: "8/5ppp/8/5PPP/8/k7/8/K7 w - -",
    EVAL: "M19",
    MOVES: "g5g6 h7g6 f5f6 g7f6 h5h6 a3b4 h6h7 f6f5 h7h8q b4c5",
    DEPTH: 75,
  },
  {
    FEN: "rnbqk2r/pp3ppp/2pbpn2/3p4/3P1B2/2N1PQ2/PPP2PPP/R3KBNR w KQkq -",
    EVAL: 79,
    MOVES: "g2g4 d6b4 f1d3 c6c5 g4g5 b4c3 b2c3 f6d7 f3g3 d8a5",
    DEPTH: 28,
  },
  {
    FEN: "2b1kr2/pr5R/1p2pQ2/5p2/2pP4/2P5/PP3PB1/R5K1 b - -",
    EVAL: 789,
    MOVES: "b7h7 f6e5 f8f7 g1f1 c8d7 a1e1 f7e7 d4d5 e8f8 e5b8",
    DEPTH: 27,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/6p1/3n4/3PN3/5N2/PPP2PPP/R1BQKB1R w KQ -",
    EVAL: 14,
    MOVES: "f1e2 b8c6 e1h1 d5f6 e4f6 g7f6 c2c3 e7e5 d4e5 c6e5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/4pn2/2p5/3P4/3B1N2/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 33,
    MOVES: "c1e3 d8c7 d1e2 a7a6 h2h4 c8d7 e1a1 a8c8 f3e5 d7a4",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q2N1/4p3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d2d3 d5d8 b1c3 b8c6 d3e4 d8d1 e1d1 c8g4 d1e1 h7h6",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/8/2PP4/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 54,
    MOVES: "c7c5 c1e3 c5d4 f3d4 a7a6 g2g3 e6e5 d4e2 d8d1 a1d1",
    DEPTH: 36,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R b KQ -",
    EVAL: 10,
    MOVES: "c7c6 b2b3 d7d5 c1b2 c8f5 e1h1 b8d7 b1d2 a7a5 a2a4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp1n1ppp/2p5/4p3/2B1P3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 75,
    MOVES: "f3g5 g8h6 e1h1 f8c5 a2a4 e8h8 a4a5 c5b4 c1d2 b4d2",
    DEPTH: 39,
  },
  {
    FEN: "r1b5/4kq2/p1Bbp2Q/6p1/8/4B1P1/PPP4P/6K1 b - -",
    EVAL: 789,
    MOVES: "c8d7 e3g5 e7e8 h6h8 d6f8 c6a8 f7g6 h2h4 d7b5 a8g2",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/1p2pppp/p1p5/2p5/4PP2/2N5/PPPP2PP/R1BQK1NR b KQkq -",
    EVAL: 72,
    MOVES: "e7e5 f4e5 g8e7 d2d3 e7g6 g1f3 c8g4 d1d2 f8e7 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/P7/8/1PPPPPPP/RNBQKBNR b KQkq -",
    EVAL: -21,
    MOVES: "g8f6 d2d4 e7e6 e2e3 c7c5 g1f3 b8c6 b1d2 b7b6 f1d3",
    DEPTH: 49,
  },
  {
    FEN: "8/8/6k1/pp2q3/3p1QP1/P2P2K1/2P5/8 b - -",
    EVAL: -6180,
    MOVES: "e5f4 g3f4 a5a4 f4e5 b5b4 a3b4 a4a3 e5d4 a3a2 d4e4",
    DEPTH: 46,
  },
  {
    FEN: "r7/3bkq2/p1Bbp2Q/6p1/8/4B1P1/PPP4P/6K1 w - -",
    EVAL: 728,
    MOVES: "e3g5 e7e8 h6h8 d6f8 c6a8 d7b5 a8g2 f7g6 h2h4 e8f7",
    DEPTH: 49,
  },
  {
    FEN: "8/8/6k1/pp6/3p1KP1/P2P4/2P5/8 b - -",
    EVAL: "M-22",
    MOVES: "a5a4 f4e5 b5b4 a3b4 a4a3 e5d4 a3a2 d4c5 a2a1q c2c4",
    DEPTH: 52,
  },
  {
    FEN: "3k4/7R/p2P4/2p1b3/8/2P3rB/P4r2/1K2R3 w - -",
    EVAL: 627,
    MOVES: "h7d7 d8c8 d7f7 g3h3 f7f2 e5d6 f2f7 h3h8 e1e6 h8d8",
    DEPTH: 63,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3Bpn2/3p4/3P4/2N1P3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: -8,
    MOVES: "c7d6 g1e2 b8c6 e2f4 e8h8 d1d2 a7a6 f1e2 b7b5 a2a3",
    DEPTH: 35,
  },
  {
    FEN: "3r2k1/1pp4p/p1n3p1/2Q5/1P2q3/P3P1Pb/3N1R1P/6K1 w - -",
    EVAL: -701,
    MOVES: "c5f8 d8f8 d2e4 f8d8 f2d2 d8d2 e4d2 g8f7 d2e4 h7h6",
    DEPTH: 40,
  },
  {
    FEN: "r1b1k1nr/pp1pppbp/1qn3p1/8/2P1P3/1N2B3/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 58,
    MOVES: "b6d8 d1d2 g8f6 b1c3 f6g4 e3f4 e8h8 f2f3 g4e5 f4h6",
    DEPTH: 49,
  },
  {
    FEN: "rnbk2nr/ppp2pbp/6p1/4p3/4P3/2N2N2/PPP2PPP/R1B1KB1R w KQ -",
    EVAL: 144,
    MOVES: "c1e3 c8e6 e1a1 b8d7 f3g5 a7a6 f2f3 g8f6 h2h4 h7h6",
    DEPTH: 36,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b - -",
    EVAL: 36,
    MOVES: "c7c6 b1c3 c8f5 f3d2 d8b6 d2b3 a7a5 c4c5 d6c5 b3c5",
    DEPTH: 45,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R b KQ -",
    EVAL: 32,
    MOVES: "e7e5 d4d5 f6h5 g2g3 f7f5 e4f5 g6f5 f3g5 h5f6 g3g4",
    DEPTH: 45,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQ -",
    EVAL: 150,
    MOVES: "e4e5 f6e8 h2h4 d7d6 h4h5 d6e5 h5g6 h7g6 c1h6 g7h6",
    DEPTH: 29,
  },
  {
    FEN: "2kr3r/pBpp1ppp/1p6/2b1PP2/8/1QPp1P1q/PP1P3P/RNBR3K b - -",
    EVAL: 516,
    MOVES: "c8b7 b3d5 b7b8 d5d3 d7d6 d3f1 h3f1 d1f1 d6e5 b2b4",
    DEPTH: 27,
  },
  {
    FEN: "2b1k3/pB5R/1p2pr2/5p2/2pP4/2P5/PP3P2/R5K1 b - -",
    EVAL: 994,
    MOVES: "f6g6 g1f1 c8d7 f1e2 e8d8 b7a6 d7c6 a2a4 c6d5 a4a5",
    DEPTH: 27,
  },
  {
    FEN: "3r1Qk1/1pp4p/p1n3p1/8/1P2q3/P3P1Pb/3N1R1P/6K1 b - -",
    EVAL: -723,
    MOVES: "d8f8 f2f8 g8f8 d2e4 f8g7 e4f2 h3c8 g1f1 c6e5 f1e2",
    DEPTH: 42,
  },
  {
    FEN: "r3k3/3b1q2/p1Bbp2Q/6B1/8/6P1/PPP4P/6K1 w - -",
    EVAL: 724,
    MOVES: "h6h8 d6f8 c6a8 d7b5 a8g2 f7g7 h8g7 f8g7 b2b3 g7d4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 39,
    MOVES: "f8g7 c1e3 d7d6 b1c3 c6d4 e3d4 g7d4 d1d4 g8f6 f1e2",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/1n1P4/8/8/1B6/PPPP1PPP/RNBQK1NR b KQkq -",
    EVAL: 14,
    MOVES: "e7d6 d2d4 d6d5 g1f3 a7a5 c2c3 f8d6 e1h1 e8h8 b3c2",
    DEPTH: 44,
  },
  {
    FEN: "3r3r/pkpp1ppp/1p6/2bQPP2/8/2Pp1P1q/PP1P3P/RNBR3K b - -",
    EVAL: 379,
    MOVES: "c7c6 d5d3 f7f6 d3e4 f6e5 e4g4 h3g4 f3g4 h7h5 d2d4",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 21,
    MOVES: "g8f6 e1h1 f6e4 f1e1 e4d6 f3e5 f8e7 b5f1 c6e5 e1e5",
    DEPTH: 51,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2N3p1/8/2B5/2n1B3/PPP2PPP/R3K2R w KQkq -",
    EVAL: -21,
    MOVES: "c6a7 c3a4 c4b5 a4b2 e1d2 e8h8 h1b1 d7d5 c2c3 b2c4",
    DEPTH: 32,
  },
  {
    FEN: "5rk1/1pp4p/p1n3p1/8/1P2N3/P3P1Pb/5R1P/6K1 b - -",
    EVAL: -700,
    MOVES: "f8d8 f2d2 d8d2 e4d2 c6e5 d2e4 g8g7 e4d2 e5g4 e3e4",
    DEPTH: 35,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 47,
    MOVES: "d8a5 b1c3 d7d6 f1e2 g7d4 e3d4 c6d4 d1d4 g8f6 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "2r3k1/q5pp/4p3/2rp1p2/1p1B1P2/1P1QP3/P1R3PP/6K1 w - -",
    EVAL: 616,
    MOVES: "d3b5 c5c2 d4a7 c2a2 a7c5 a2a1 g1f2 h7h6 f2g3 c8c5",
    DEPTH: 49,
  },
  {
    FEN: "r1b1k1nr/pp1pppbp/1qn3p1/5N2/2P1P3/4B3/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: -86,
    MOVES: "b6b2 f5g7 b2g7 b1d2 g8f6 f1e2 d7d6 e1h1 b7b6 f2f4",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/8/P1N5/1PPPPPPP/R1BQKBNR b KQkq -",
    EVAL: -37,
    MOVES: "d7d5 d2d4 e5e4 c1f4 c7c6 e2e3 f8d6 g1e2 g8e7 f2f3",
    DEPTH: 43,
  },
  {
    FEN: "r1bq1rk1/p4ppp/2p2n2/3p2B1/1b6/2NB4/PPP2PPP/R2Q1RK1 w - -",
    EVAL: 0,
    MOVES: "d1f3 a7a5 h2h3 h7h6 g5f6 d8f6 f3f6 g7f6 c3e2 a5a4",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n5/2b1P3/2Bpn3/2P2N2/PP3PPP/RNBQK2R w KQkq -",
    EVAL: 201,
    MOVES: "c4d5 e4f2 e1f2 d4c3 f2g3 c3b2 c1b2 d7d6 d5c6 b7c6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/P7/1PPPPPPP/RNBQKBNR b KQkq -",
    EVAL: -14,
    MOVES: "c7c5 c2c3 g8f6 d2d4 e7e6 g1f3 d7d5 c1f4 f8d6 f4g3",
    DEPTH: 56,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2nbp3/3n4/3P4/5N2/PPP1BPPP/RNBQ1RK1 w kq -",
    EVAL: 98,
    MOVES: "f1e1 e8h8 c2c4 d5f4 e2f1 f4g6 c1g5 c6e7 c4c5 d6f4",
    DEPTH: 27,
  },
  {
    FEN: "r7/2k1Pp1p/p1n2p2/P1b1r3/2p5/2P3P1/5P1P/1R1Q2K1 w - -",
    EVAL: 728,
    MOVES: "b1b7 c7b7 d1d7 b7b8 e7e8q e5e8 d7e8 b8c7 e8a8 c6a5",
    DEPTH: 62,
  },
  {
    FEN: "2b1kr2/p6r/1p2pQ2/5p2/2pP4/2P5/PP3PB1/R5K1 w - -",
    EVAL: 1109,
    MOVES: "g2c6 h7d7 f6h4 f8g8 g1h2 e8f7 c6f3 g8g7 h4h8 e6e5",
    DEPTH: 27,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/4P3/3P4/2N2N2/PPP2PPP/R1BQKB1R b KQ -",
    EVAL: 137,
    MOVES: "f6e8 h2h4 d7d6 h4h5 d6e5 h5g6 h7g6 c1h6 g7h6 h1h6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 75,
    MOVES: "g8e7 f2f4 b8c6 g1f3 c8e6 c1d2 d5d4 d1b3 d8d7 b1a3",
    DEPTH: 48,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1 w - -",
    EVAL: 73,
    MOVES: "d4c2 d7e6 a1c1 f6d7 d1d2 d8a5 b2b4 c6b4 c3d5 b4c6",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/ppp2p1p/2np4/4n1p1/2P5/2B1P3/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: 42,
    MOVES: "b2b4 f8g7 b4b5 c6e7 g1f3 e7g6 f3e5 g7e5 c3e5 d6e5",
    DEPTH: 25,
  },
  {
    FEN: "rn1qk2r/pb1p1pp1/1p2pn1p/2p5/1bPP3B/2N2N2/PPQ1PPPP/R3KB1R w KQkq -",
    EVAL: 61,
    MOVES: "d4d5 b4c3 c2c3 e6d5 c4d5 b7d5 a1d1 d5a2 b2b3 b8c6",
    DEPTH: 23,
  },
  {
    FEN: "r4rk1/pbpp1ppp/1p6/2bBPP2/8/1QPp1P1q/PP1P3P/RNB3RK b - -",
    EVAL: -1721,
    MOVES: "c5g1 h1g1 a8e8 d5b7 e8e5 b7e4 f8e8 b3c4 d7d5 c4d4",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4P3/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 16,
    MOVES: "g1f3 c7c5 f1e2 b8c6 e1h1 e7e6 c2c4 d5c4 d4c5 d8d1",
    DEPTH: 45,
  },
  {
    FEN: "r1bq1rk1/pppnppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 56,
    MOVES: "b1c3 e7e5 e2e4 e5d4 f3d4 d7c5 h2h3 b7b6 d1c2 c8b7",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 14,
    MOVES: "d7d5 e4d5 d8d5 b1a3 b8c6 d2d4 g8f6 c1e3 c5d4 a3b5",
    DEPTH: 49,
  },
  {
    FEN: "rnbqk2r/pp3pbp/3ppnp1/2pP4/2P1PP2/2N5/PP4PP/R1BQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "d5e6 f7e6 e4e5 d6e5 d1d8 e8d8 f4e5 f6g4 c1g5 d8c7",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1b2/3P1B2/2P1PN2/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f8d6 f4d6 c7d6 d1b3 d8e7 c3c4 d5c4 f1c4 b8c6 e1h1",
    DEPTH: 40,
  },
  {
    FEN: "2r3k1/q5pp/4p3/2Bp1p2/1p3P2/1P1QP3/P1R3PP/6K1 b - -",
    EVAL: 0,
    MOVES: "c8c5 d3d4 c5c7 c2c7 a7c7 d4b4 c7c1 g1f2 c1c2 f2g3",
    DEPTH: 51,
  },
  {
    FEN: "6k1/q5pp/4p3/2rp1p2/1p3P2/1P1QP3/P1R3PP/6K1 w - -",
    EVAL: 0,
    MOVES: "d3d4 c5c7 c2c7 a7c7 d4b4 c7c1 g1f2 c1c2 f2f3 c2d1",
    DEPTH: 54,
  },
  {
    FEN: "2b1kr2/p6r/1pB1pQ2/5p2/2pP4/2P5/PP3P2/R5K1 b - -",
    EVAL: "M26",
    MOVES: "h7d7 f6g5 e8f7 g1f1 f8g8 g5h5 g8g6 f1e2 d7d8 h5h2",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3pp3/8/P1N5/1PPPPPPP/R1BQKBNR w KQkq -",
    EVAL: -20,
    MOVES: "d2d4 e5d4 d1d4 g8f6 e2e4 b8c6 f1b5 c8d7 b5c6 d7c6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3P4/8/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e6d5 d2d4 g8f6 g1f3 f8d6 c3b5 d6b4 c2c3 b4a5 a2a4",
    DEPTH: 44,
  },
  {
    FEN: "r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -",
    EVAL: 42,
    MOVES: "f8e8 e2e4 e7e5 h2h3 e5d4 f3d4 d7c5 f1e1 a7a6 d1c2",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 22,
    MOVES: "d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 e7e5 d4b3",
    DEPTH: 59,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/2p5/3PP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 19,
    MOVES: "d7d5 e4d5 d8d5 f1e2 g8f6 e1h1 c5d4 f3d4 c8d7 c1e3",
    DEPTH: 44,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/1N2B3/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 39,
    MOVES: "d1d2 g8f6 b1c3 f6g4 e3f4 e8h8 h2h3 g4f6 f1e2 d7d6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2P5/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 53,
    MOVES: "d4d5 c6b8 f1d3 f8c5 b2b4 c5e7 g1f3 d7d6 f3d2 c8g4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR b KQkq -",
    EVAL: 17,
    MOVES: "g8f6 g1f3 d7d5 c2c4 e7e6 g2g3 f8b4 c1d2 b4e7 f1g2",
    DEPTH: 57,
  },
  {
    FEN: "r1bq1rk1/p4ppp/2pb1n2/3p2B1/8/2NB1Q2/PPP2PPP/R3R1K1 b - -",
    EVAL: 0,
    MOVES: "a8b8 b2b3 d6b4 g5d2 c8g4 f3g3 g4e6 a2a3 b4d6 g3h4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 17,
    MOVES: "g1f3 d7d6 b1c3 g8f6 d2d4 c5d4 f3d4 a7a6 f1e2 e7e5",
    DEPTH: 58,
  },
  {
    FEN: "r3k2r/pBpp1ppp/1p6/2b1Pq2/8/1QPp1P2/PP1P3P/RNBR3K b kq -",
    EVAL: 401,
    MOVES: "c7c6 d1f1 a8b8 b7a6 b6b5 c3c4 b8b6 c4b5 c6b5 b3c3",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 25,
    MOVES: "f1e1 e4d6 f3e5 c6e5 e1e5 f8e7 b5f1 e8h8 d2d4 e7f6",
    DEPTH: 56,
  },
  {
    FEN: "rk6/3QPp1p/p1n2p2/P1b1r3/2p5/2P3P1/5P1P/6K1 w - -",
    EVAL: 747,
    MOVES: "e7e8r e5e8 d7e8 b8c7 e8a8 c6a5 a8a6 c5b6 a6b5 h7h6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 e4e5 f6d7 f2f4 c7c5 g1f3 b8c6 c1e3 c5d4 f3d4",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 16,
    MOVES: "c2c4 e7e6 g1f3 d7d5 b1c3 f8b4 c1g5 h7h6 g5f6 d8f6",
    DEPTH: 61,
  },
  {
    FEN: "r1b1k1nr/ppqpppbp/2n3p1/8/2P1P3/1N2B3/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 78,
    MOVES: "d1d2 g8f6 b1c3 e8h8 f1e2 d7d6 e1h1 a7a5 b3d4 c6d4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 64,
    MOVES: "d2d4 c5d4 f3d4 b8c6 c2c4 d7d6 b1c3 g8f6 f1e2 c6d4",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1pn2/3P4/3P4/2NB4/PPP2PPP/R1BQK1NR b KQkq -",
    EVAL: 20,
    MOVES: "e6d5 g1f3 f8d6 d1e2 d6e7 e1h1 e8h8 c1g5 f8e8 e2d2",
    DEPTH: 22,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/3pP3/3P4/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 42,
    MOVES: "g8e7 f1b5 c8d7 b1c3 e7f5 e1h1 d8b6 b5a4 h7h6 c3e2",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 37,
    MOVES: "e2e3 f8g7 g1f3 e8h8 f1e2 c7c5 d4d5 d7d6 b1c3 e7e6",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 9,
    MOVES: "f8c5 d2d3 g8f6 e1h1 e8h8 c2c3 d7d6 f1e1 a7a5 h2h3",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 31,
    MOVES: "d7d5 c4d5 f6d5 c1d2 c7c5 a1c1 d5c3 d2c3 f8h6 e2e3",
    DEPTH: 50,
  },
  {
    FEN: "1r2k2r/pbpp1ppp/1p6/2bBPP2/8/1QPp1P1q/PP1P3P/RNB3RK b k -",
    EVAL: -332,
    MOVES: "c7c6 d5e4 d7d5 e4d5 c6d5 b3a4 e8d8 a4g4 h3g4 g1g4",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq -",
    EVAL: -35,
    MOVES: "e5d4 g1f3 f8b4 c2c3 d4c3 b2c3 d7d5 e4d5 b4e7 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/2P1P3/2N1B3/PPN1BPPP/R2Q1RK1 w - -",
    EVAL: 40,
    MOVES: "a1c1 a7a6 b2b3 f6g4 e2g4 d7g4 d1d2 d8a5 c2d4 f8e8",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 74,
    MOVES: "e2e4 d7d6 h2h3 e7e5 d4d5 a7a5 c1e3 b8a6 g1f3 e8h8",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/5N1P/PP1P1PP1/RNBQKB1R w KQkq -",
    EVAL: -66,
    MOVES: "b1c3 d7d6 f1e2 e7e6 e1h1 g8e7 d2d3 e8h8 c1e3 c6d4",
    DEPTH: 27,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 67,
    MOVES: "d7d6 g1f3 e8h8 f1e2 e7e5 e1h1 c8g4 d4d5 a7a5 c1e3",
    DEPTH: 47,
  },
  {
    FEN: "r1bq1rk1/pp1nppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -",
    EVAL: 88,
    MOVES: "d4d5 f6e8 a2a4 b7b6 e2e4 d7e5 f3e5 d6e5 b2b3 e8d6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/3P1N1P/PP3PP1/RNBQKB1R b KQkq -",
    EVAL: -48,
    MOVES: "e7e6 b1c3 d7d6 f1e2 g8e7 c1f4 h7h6 c3b5 e6e5 f4d2",
    DEPTH: 30,
  },
  {
    FEN: "r1bqk1nr/ppp2pp1/1bnp3p/4p3/1PB1P3/2PP1N2/P4PPP/RNBQK2R w KQkq -",
    EVAL: 50,
    MOVES: "a2a4 a7a6 d1b3 d8f6 c1e3 g8e7 b1d2 e8h8 e1h1 e7g6",
    DEPTH: 29,
  },
  {
    FEN: "r3Q3/2k2p1p/p1n2p2/P1b5/2p5/2P3P1/5P1P/6K1 w - -",
    EVAL: 779,
    MOVES: "e8a8 c6b8 g1g2 h7h6 a8d5 c5d6 d5c4 c7d8 c4f7 b8d7",
    DEPTH: 51,
  },
  {
    FEN: "6k1/q5pp/4p3/2rp1p2/1p1B1P2/1P1QP3/P5PP/6K1 w - -",
    EVAL: 0,
    MOVES: "d3d2 a7a5 d4c5 a5c5 g1f2 g8f7 f2f3 h7h6 d2d4 c5d4",
    DEPTH: 55,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R b KQkq -",
    EVAL: 19,
    MOVES: "g7g6 d2d4 g8f6 f1g2 f8g7 e1h1 e8h8 c2c4 c7c6 c4d5",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 20,
    MOVES: "g1f3 b8c6 f1c4 g8f6 d2d3 f8c5 e1h1 e8h8 c2c3 d7d6",
    DEPTH: 55,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 58,
    MOVES: "h2h3 e8h8 c1e3 c7c5 d4d5 e7e6 g1f3 b8a6 d5e6 c8e6",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 59,
    MOVES: "e4e5 g8e7 a2a3 b4c3 b2c3 c7c5 d1g4 e7f5 f1d3 h7h5",
    DEPTH: 52,
  },
  {
    FEN: "2Q5/1p4q1/p7/4k1p1/P3b3/7P/5PP1/6K1 w - -",
    EVAL: 4466,
    MOVES: "c8c3 e5e6 c3g7 e4d5 g7g5 e6d6 a4a5 d6c5 g5e5 c5c6",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/5N1P/PPPP1PP1/RNBQKB1R w KQkq -",
    EVAL: 16,
    MOVES: "b1c3 f8b4 c3d5 f6d5 e4d5 c6d4 f3d4 e5d4 a2a3 b4e7",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3ppn2/6N1/2BpP3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -67,
    MOVES: "d1d4 b8c6 d4e3 f8e7 e1h1 e8h8 c4d3 d6d5 g5f3 d5e4",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR b KQkq -",
    EVAL: 13,
    MOVES: "e8h8 h2h3 f8e8 g1f3 e7e5 d4e5 d6e5 d1d8 e8d8 c1g5",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2pp4/3PP3/2P2N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 16,
    MOVES: "e4e5 c8d7 f1e2 c5d4 c3d4 g8e7 e1h1 e7f5 b1c3 a7a6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f1b5 g8f6 e1h1 f6e4 f1e1 e4d6 f3e5 f8e7 b5f1 c6e5",
    DEPTH: 63,
  },
  {
    FEN: "1r2k2r/p1pp1ppp/1p6/2bQPP2/8/2Pp1P1q/PP1P3P/RNB3RK b k -",
    EVAL: -164,
    MOVES: "c5g1 h1g1 h3f5 b1a3 e8h8 a3c4 f8e8 c4e3 f5g5 g1h1",
    DEPTH: 24,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/2P5/2P1P3/2N5/PP2BPPP/R1BQK1NR b KQkq -",
    EVAL: 87,
    MOVES: "d8a5 c1d2 a5c5 a1c1 c5b6 g1f3 e8h8 e1h1 c8g4 h2h3",
    DEPTH: 33,
  },
  {
    FEN: "8/1b1Q1ppk/p3p2p/1p1q4/3Ppb2/1P2B1P1/P6P/2R3K1 w - -",
    EVAL: -835,
    MOVES: "d7d5 f4e3 g1f1 b7d5 c1d1 h7g6 f1e2 e3g5 d1g1 h6h5",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 71,
    MOVES: "d2d4 d7d6 g1f3 f8g7 c2c3 g8f6 f1d3 b8d7 e1h1 e7e5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 44,
    MOVES: "c7c5 a2a3 b4c3 b2c3 g8e7 d1g4 c5d4 f1d3 d8a5 a1b1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 45,
    MOVES: "e2e4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 f8e7 g1f3 c7c5",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2nn4/1B2N3/8/8/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 27,
    MOVES: "b5f1 c6e5 e1e5 e8h8 b1c3 e7f6 e5e1 f8e8 c3d5 e8e1",
    DEPTH: 55,
  },
  {
    FEN: "8/p7/8/8/2K5/5k2/2P5/8 w - -",
    EVAL: "M22",
    MOVES: "c4d4 f3g4 c2c4 g4g5 d4c5 g5f4 c5c6 a7a5 c6b5 a5a4",
    DEPTH: 73,
  },
  {
    FEN: "8/p7/8/8/3K4/5k2/2P5/8 b - -",
    EVAL: "M21",
    MOVES: "f3f4 c2c4 f4g5 d4c5 g5f4 c5c6 a7a5 c6b5 a5a4 b5a4",
    DEPTH: 81,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R b KQkq -",
    EVAL: 20,
    MOVES: "e7e6 c2c4 d7d5 d2d4 g8f6 b1c3 f8e7 c1f4 e8h8 e2e3",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 g1f3 b8c6",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 31,
    MOVES: "f8e7 e1h1 g8f6 f1e1 b7b5 a4b3 e8h8 c2c3 d7d6 h2h3",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n5/1Bp1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 43,
    MOVES: "f8d6 c2c3 a7a6 b5a4 b7b5 a4b3 c5c4 b3c2 g8f6 d2d4",
    DEPTH: 46,
  },
  {
    FEN: "8/1bRQ1ppk/p3p2p/1p1q4/3Pp3/1P2b1P1/P6P/6K1 w - -",
    EVAL: -905,
    MOVES: "g1g2 d5f5 d7f7 f5f3 g2h3 f3f7 c7f7 b7d5 h3g2 e3d4",
    DEPTH: 27,
  },
  {
    FEN: "r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P1P3/3P1N1P/PP3PP1/RNBQKB1R w KQkq -",
    EVAL: -56,
    MOVES: "b1c3 g8e7 c1e3 c6d4 f1e2 d7d6 d1d2 e8h8 e1h1 b7b6",
    DEPTH: 22,
  },
  {
    FEN: "rnbqkbnr/p4ppp/2p1p3/1p6/P1pPP3/2N2N2/1P3PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b5b4 c3e2 c8a6 c1e3 f8e7 e2g3 g8f6 f3e5 c6c5 a1c1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P1P3/2NP1N1P/PP3PP1/R1BQKB1R b KQkq -",
    EVAL: -58,
    MOVES: "d7d6 c1e3 c6d4 d1d2 g8e7 f1e2 e8h8 e2d1 e7c6 e1h1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR b KQkq -",
    EVAL: 47,
    MOVES: "g8e7 h2h4 d8c7 h1h3 b8c6 h4h5 h7h6 g1f3 c7a5 c1d2",
    DEPTH: 53,
  },
  {
    FEN: "rBbqk2r/pp3ppp/5n2/8/1bpP4/8/PP2B1PP/RN1Q1KNR b kq -",
    EVAL: -230,
    MOVES: "f6d5 f1f2 a8b8 e2c4 e8h8 c4d5 d8d5 g1f3 c8g4 b1c3",
    DEPTH: 63,
  },
  {
    FEN: "8/p7/8/8/1K6/4k3/2P5/8 w - -",
    EVAL: "M20",
    MOVES: "c2c4 e3d4 c4c5 d4e5 b4a5 e5e6 a5a6 e6d5 a6b5 a7a6",
    DEPTH: 76,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q2N1/4p3/3P4/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -17,
    MOVES: "d5d8 b1c3 e7e5 d3e4 d8d1 c3d1 h7h6 g5f3 b8c6 h2h3",
    DEPTH: 43,
  },
  {
    FEN: "rnb1k1nr/pppp1ppp/8/4P3/4P2q/8/PPPP2PP/RNBQKBR1 w Qkq -",
    EVAL: 0,
    MOVES: "g2g3 h4e4 d1e2 e4e2 f1e2 b8c6 g1f1 g8e7 b1a3 a7a6",
    DEPTH: 33,
  },
  {
    FEN: "rnbqk1nr/pp2ppbp/3p2p1/2p3B1/2PP4/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 c5d4 f3d4 b8c6 d4c6 b7c6 a1c1 g8f6 g2g3 h7h6",
    DEPTH: 30,
  },
  {
    FEN: "8/p7/8/8/1KPk4/8/8/8 w - -",
    EVAL: "M19",
    MOVES: "c4c5 d4e5 b4a5 e5e6 a5a6 e6d5 a6b5 d5e6 b5c6 a7a5",
    DEPTH: 68,
  },
  {
    FEN: "r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P1P3/P2P1N1P/1P3PP1/RNBQKB1R b KQkq -",
    EVAL: -94,
    MOVES: "g8e7 f1e2 b7b6 b1c3 e8h8 e1h1 f7f5 c1d2 c8b7 b2b4",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/8/2B1P3/5N2/PB3PPP/RN1QK2R b KQkq -",
    EVAL: 0,
    MOVES: "f8b4 b1c3 b4c3 b2c3 g8f6 e1h1 e8h8 f3g5 h7h6 g5f7",
    DEPTH: 72,
  },
  {
    FEN: "rnb1k1nr/ppq2ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq -",
    EVAL: 47,
    MOVES: "d1g4 g8e7 g4g7 h8g8 g7h7 c5d4 g1e2 d4c3 f2f4 c8d7",
    DEPTH: 54,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/6B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: -3,
    MOVES: "c7c5 b1c3 h7h6 g5e3 c5d4 f3d4 g8f6 h2h3 b7b6 d1d2",
    DEPTH: 28,
  },
  {
    FEN: "rn1q1r1k/pp3p1p/2p5/3p3P/5Q2/2NP2R1/PPP3P1/4R1K1 w - -",
    EVAL: "M5",
    MOVES: "f4h6 f8g8 e1e8 d8b6 g1h1 b6g1 h1g1 g8e8 h6f6",
    DEPTH: 164,
  },
  {
    FEN: "r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "e1h1 d8f6 d2d4 e5d4 c1g5 f6d6 f3d4 f8e7 g5e3 g8f6",
    DEPTH: 58,
  },
  {
    FEN: "rn1q2rk/pp3p1p/2p4Q/3p3P/8/2NP2R1/PPP3P1/4R1K1 w - -",
    EVAL: "M4",
    MOVES: "e1e8 d8b6 g1h1 b6g1 h1g1 g8e8 h6f6",
    DEPTH: 99,
  },
  {
    FEN: "rnbqk1nr/p4ppp/2p1p3/1p6/PbpPP3/2N2N2/1P3PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1e2 c8b7 e1h1 a7a6 c1d2 g8e7 b2b3 c4b3 d1b3 b4a5",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2np4/2p5/8/5NP1/PPPPPPBP/RNBQK2R w KQkq -",
    EVAL: 62,
    MOVES: "d2d4 c5d4 f3d4 c8d7 c2c4 g7g6 b2b3 f8g7 c1b2 g8f6",
    DEPTH: 40,
  },
  {
    FEN: "r1b1kbnr/1pp2ppp/p1p5/4N3/3qP3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -67,
    MOVES: "e5g4 d4e4 g4e3 e4g6 d2d3 c8d7 c1d2 e8a8 b1c3 d8e8",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/1P6/8/P1PPPPPP/RNBQKBNR b KQkq -",
    EVAL: -28,
    MOVES: "e7e5 c1b2 f8b4 b2e5 g8f6 c2c3 b4e7 e5f6 e7f6 d2d4",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQ1RK1 w kq -",
    EVAL: 58,
    MOVES: "f1e1 e8h8 d2d4 b7b5 a4c2 e5d4 c3d4 c6b4 d4d5 c7c5",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1bB1P3/5N2/PB3PPP/RN1QK2R w KQkq -",
    EVAL: -23,
    MOVES: "b1c3 b4c3 b2c3 g8f6 e1h1 e8h8 f3g5 h7h6 c4f7 f8f7",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 24,
    MOVES: "d2d4 d7d5 e4e5 c8f5 g1f3 e7e6 c2c3 c6c5 f1e2 b8c6",
    DEPTH: 54,
  },
  {
    FEN: "rnb1k1nr/ppq2ppp/4p3/2ppP3/3P3P/P1P5/2P2PP1/R1BQKBNR b KQkq -",
    EVAL: 46,
    MOVES: "g8e7 g1f3 b7b6 f1b5 c8d7 b5d3 d7c8 a3a4 c8a6 e1h1",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5d3 f6d5 g5f3",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 44,
    MOVES: "e5d4 f3d4 g8f6 b1c3 b8c6 f1e2 f8e7 d4c6 b7c6 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/2P1P3/2N1B3/PPN1BPPP/2RQ1RK1 b - -",
    EVAL: 83,
    MOVES: "c6e5 b2b3 a7a6 g1h1 b7b5 f2f4 e5c6 d1e1 b5c4 e2c4",
    DEPTH: 40,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q2N1/8/3B4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -85,
    MOVES: "g8f6 e1h1 c8g4 b1c3 d5d7 f2f3 g4f5 g5e4 b8c6 c1e3",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 25,
    MOVES: "d2d4 f6e4 f1d3 d7d5 f3e5 b8d7 e5d7 c8d7 e1h1 f8d6",
    DEPTH: 65,
  },
  {
    FEN: "r5k1/ppp2p1p/6pB/4n2n/3bP2q/2NB1p1P/PPP3PK/R2Q1R2 w - -",
    EVAL: -562,
    MOVES: "f1f3 e5g4 h2h1 h5g3 f3g3 g4f2 h1h2 f2d1 c3e2 d4b2",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n1p3/3n4/3P4/5N2/PPP1BPPP/RNBQ1RK1 b kq -",
    EVAL: 69,
    MOVES: "f8d6 f1e1 e8h8 e2d3 d5f6 b1d2 b7b6 c2c3 c6e7 d2c4",
    DEPTH: 28,
  },
  {
    FEN: "rBbqk2r/pp3ppp/8/3n4/1bpP4/8/PP2B1PP/RN1Q1KNR w kq -",
    EVAL: -194,
    MOVES: "f1f2 a8b8 e2c4 e8h8 c4d5 d8d5 b1c3 d5d6 g1f3 c8g4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/3pP3/3p4/2P2N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 36,
    MOVES: "c3d4 g8e7 f1b5 e7f5 b1c3 c8d7 e1h1 a8c8 b5a4 d8b6",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 19,
    MOVES: "f1e1 b7b5 a4b3 e8h8 h2h3 c8b7 d2d3 d7d5 e4d5 f6d5",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/4p3/8/2NP1N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 29,
    MOVES: "f8e7 f1e2 e8h8 e1h1 h7h6 f1e1 d8c7 a2a3 a7a6 h2h3",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p5/4P3/4n3/3B4/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 62,
    MOVES: "d8d4 e1h1 f8e7 b1c3 e4c5 d3e2 c8f5 d2d3 c5e6 f1e1",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 75,
    MOVES: "f3e5 c6e5 d2d4 c5d6 d4e5 d6e5 f1d3 g8f6 e1h1 e8h8",
    DEPTH: 49,
  },
  {
    FEN: "1q6/4pp1k/6pP/2QPbb2/8/2N5/P7/K5R1 b - -",
    EVAL: "M-13",
    MOVES: "b8a7 c5b4 a7g1 a1b2 g1b1 b2a3 b1d3 a3a4 e5c3 b4c3",
    DEPTH: 77,
  },
  {
    FEN: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 b kq -",
    EVAL: 46,
    MOVES: "b7b5 a4b3 e8h8 c2c3 d7d6 h2h3 f8e8 d2d4 c8b7 b1d2",
    DEPTH: 48,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2p5/4P3/3qn3/3B4/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 66,
    MOVES: "e1h1 f8e7 d3e4 d4e4 f1e1 e4g6 b1c3 h7h5 c3e2 c8e6",
    DEPTH: 34,
  },
  {
    FEN: "rBbqk2r/pp3ppp/8/3n4/1bpP4/8/PP2BKPP/RN1Q2NR b kq -",
    EVAL: -325,
    MOVES: "a8b8 e2c4 e8h8 c4d5 d8d5 g1f3 c8g4 b1c3 d5d6 d1d3",
    DEPTH: 56,
  },
  {
    FEN: "4kr2/p2b3r/1pB1pQ2/5p2/2pP4/2P5/PP3P2/R5K1 w - -",
    EVAL: "M18",
    MOVES: "f6e6 e8d8 e6d6 f8g8 g1f1 g8g4 a1e1 g4e4 d6b8 d7c8",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 52,
    MOVES: "c3e4 b8d7 g1f3 g8f6 e4f6 d7f6 g2g3 b7b6 f1b5 c8d7",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 18,
    MOVES: "e1h1 g8f6 d2d3 a7a6 c2c3 d7d6 h2h3 c5a7 a2a4 e8h8",
    DEPTH: 55,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2p5/4P3/3qn3/3B4/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 75,
    MOVES: "c8e6 d1h5 e4c5 d3e2 g7g6 c2c3 d4e4 h5f3 e4f3 g2f3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n1p3/8/3Pp3/2N1B3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 67,
    MOVES: "c3e4 f7f5 e4c3 f8d6 f1c4 g8f6 g1f3 c6b4 e1h1 e8h8",
    DEPTH: 23,
  },
  {
    FEN: "1NBK4/8/8/8/8/8/8/3k4 w - -",
    EVAL: "M30",
    MOVES: "d8c7 d1e2 c8f5 e2e3 c7d7 e3d4 d7d6 d4c4 b8c6 c4c3",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 73,
    MOVES: "g5f7 e8f7 d1f3 f7e6 b1c3 c6b4 e1h1 c7c6 d2d4 d8f6",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 36,
    MOVES: "c1e3 g8f6 e4f6 d7f6 g1f3 c8d7 f1d3 f6d5 e3d2 c7c5",
    DEPTH: 55,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 19,
    MOVES: "g8f6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 c7c5 a1b1",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n1p3/8/3PN3/4B3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 25,
    MOVES: "f8e7 g1f3 g8f6 e4f6 e7f6 c2c3 e6e5 f3e5 c6e5 d4e5",
    DEPTH: 39,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/3pP3/3P4/5N2/PP1B1PPP/RN1QKB1R b KQkq -",
    EVAL: -74,
    MOVES: "c6d4 f3d4 b6d4 f1b5 c8d7 b5d7 e8d7 e1h1 g8e7 b1c3",
    DEPTH: 24,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 65,
    MOVES: "e2e4 e7e6 b1c3 g8e7 g1f3 e8h8 f1d3 d7d6 e1h1 e6e5",
    DEPTH: 46,
  },
  {
    FEN: "r3kb1r/ppp2ppp/2p1b3/4P3/3qn3/3B4/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 71,
    MOVES: "d3e4 d4e4 f1e1 e4f5 d2d3 e8a8 b1d2 e6d5 d2f1 h7h5",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 13,
    MOVES: "c6a5 c4b5 c7c6 d5c6 b7c6 d1f3 c6b5 f3a8 f8e7 e1h1",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -18,
    MOVES: "f8b4 c2c3 d4c3 b2c3 d7d5 e4d5 b4d6 e1h1 e8h8 c4b3",
    DEPTH: 56,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n1p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 120,
    MOVES: "h7h6 f1d3 c6b4 d3c4 g8f6 e4f6 d8f6 c2c3 b4c6 e1h1",
    DEPTH: 38,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/2PPP3/8/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: 64,
    MOVES: "d7d6 b1c3 e7e5 g1f3 b8c6 d4e5 c6e5 f3e5 g7e5 f1e2",
    DEPTH: 44,
  },
  {
    FEN: "8/q3pp1k/6pP/2QPbb2/8/2N5/P7/K5R1 w - -",
    EVAL: "M-12",
    MOVES: "c5b4 a7g1 a1b2 g1b1 b2a3 b1d3 a3a4 e5c3 b4c3 d3c3",
    DEPTH: 93,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n1pN2/8/3P4/5N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 74,
    MOVES: "g7f6 c2c3 b7b6 f1b5 c8b7 d1e2 d8d7 e1h1 a7a6 b5d3",
    DEPTH: 29,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2np1n1p/2b1p3/2B1P3/2PP1N2/PP1N1PPP/R1BQK2R w KQkq -",
    EVAL: 33,
    MOVES: "e1h1 a7a6 f1e1 c5a7 c4b3 c6a5 b3c2 c7c5 d2f1 a5c6",
    DEPTH: 34,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1pq2/8/3P4/5N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 94,
    MOVES: "c1g5 f6g6 f1d3 g6h5 h2h3 f7f6 g5f4 f8d6 f4d6 c7d6",
    DEPTH: 47,
  },
  {
    FEN: "rn3rk1/pbpp1ppp/1p2p3/6q1/2PP4/2n3P1/PPQ1PPBP/R3K2R w KQ -",
    EVAL: 80,
    MOVES: "g2b7 b8c6 b7a8 c3e2 c2e2 f8a8 a1d1 g5a5 e1f1 d7d5",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 52,
    MOVES: "g1f3 g8f6 g2g3 f8e7 f1g2 e8h8 e1h1 f6e4 d4d5 d7d6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 26,
    MOVES: "e7e6 b1c3 c7c6 e2e3 g8f6 g1f3 b8d7 d1c2 f8d6 f1d3",
    DEPTH: 61,
  },
  {
    FEN: "4kr2/p2b3r/1pB1Q3/5p2/2pP4/2P5/PP3P2/R5K1 b - -",
    EVAL: "M16",
    MOVES: "e8d8 e6d6 f8g8 g1f1 g8g4 a1e1 g4e4 c6e4 f5e4 d6b8",
    DEPTH: 76,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1pq2/6B1/3P4/5N2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 91,
    MOVES: "f6g6 f1d3 g6h5 h2h3 f7f6 g5e3 c8d7 d3e2 h5f7 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "2r2rk1/pp1b1ppp/1q2p3/3pP3/1B3Pn1/3B1N2/P3Q1PP/RN2KR2 b Q -",
    EVAL: -744,
    MOVES: "c8c1 e1d2 c1f1 e2f1 b6b4 d2e2 d7b5 b1d2 b5d3 e2d3",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 68,
    MOVES: "g8f6 a2a3 g7g6 g1f3 f8g7 b2b4 a7a5 c1b2 d8e7 d1b3",
    DEPTH: 43,
  },
  {
    FEN: "6k1/1p1b3p/2pp2p1/p7/2Pb2Pq/1P1PpK2/P1N3RP/1RQ5 b - -",
    EVAL: "M-4",
    MOVES: "d7g4 g2g4 h4f2 f3e4 f2f5 e4d4 f5e5",
    DEPTH: 245,
  },
  {
    FEN: "rn3rk1/pBpp1ppp/1p2p3/6q1/2PP4/2n3P1/PPQ1PP1P/R3K2R b KQ -",
    EVAL: 42,
    MOVES: "b8c6 b7a8 c3e2 c2e2 f8a8 e2d3 d7d5 e1h1 g5d8 f1d1",
    DEPTH: 52,
  },
  {
    FEN: "2r3k1/q5pp/4p3/1Qrp1p2/1p1B1P2/1P2P3/P1R3PP/6K1 b - -",
    EVAL: 569,
    MOVES: "c5c2 d4a7 c2a2 a7c5 a2c2 c5d4 c2c1 g1f2 c1c7 b5b4",
    DEPTH: 47,
  },
  {
    FEN: "3r4/1R1P1k1p/1p1q2p1/1Pp5/2P5/6P1/4Q1KP/8 w - -",
    EVAL: "M20",
    MOVES: "e2e8 d8e8 d7d8q d6e7 d8b6 e7b7 b6b7 f7f6 b7c6 e8e6",
    DEPTH: 85,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR b KQkq -",
    EVAL: 49,
    MOVES: "f8b4 b1c3 b4c3 b2c3 b8c6 g1f3 d8e7 d1d5 f7f6 e5f6",
    DEPTH: 48,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/5qB1/3P4/5N2/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 151,
    MOVES: "c2c3 f8e7 f1d3 f5d5 h2h4 f7f6 g5e3 c8d7 a2a3 d5h5",
    DEPTH: 32,
  },
  {
    FEN: "2r3k1/q5pp/4p3/1Q1p1p2/1p1B1P2/1P2P3/P1r3PP/6K1 w - -",
    EVAL: 571,
    MOVES: "d4a7 c2a2 a7c5 h7h6 h2h3 a2a1 g1f2 a1d1 f2f3 c8f8",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pppp1p1p/2n5/4n1p1/2P5/2B5/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: 55,
    MOVES: "g1f3 d7d6 f3e5 d6e5 d1d8 e8d8 e2e3 a7a5 a2a3 f8e7",
    DEPTH: 41,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/5qB1/3P4/3B1N2/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 145,
    MOVES: "f5d5 c2c3 f8e7 g5f4 e7d6 f4g3 e8h8 e1h1 b7b6 f1e1",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pppp1p1p/2n5/4n1p1/2P5/2B1P3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: 36,
    MOVES: "d7d6 h2h4 h8g8 d1c2 h7h6 b1a3 a7a6 g1e2 d8f6 f2f4",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/4p3/4p3/3B1N2/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d3e4 f8d6 e1h1 e8h8 f1e1 c8g4 c2c3 g8h8 h2h3 g4h5",
    DEPTH: 53,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/6B1/3P2q1/3B1N2/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 640,
    MOVES: "h2h3 g4g2 h1h2 g2h2 f3h2 f8d6 h2f3 c8d7 c2c4 e8h8",
    DEPTH: 47,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/6B1/3P2q1/3B1N1P/PPP2PP1/R2QK2R b KQkq -",
    EVAL: 661,
    MOVES: "g4g2 h1h2 g2h2 f3h2 f8d6 h2f3 c8d7 c2c4 f7f6 g5e3",
    DEPTH: 28,
  },
  {
    FEN: "r3r1k1/pp3pbp/1qp3p1/2B5/2BP2b1/Q1n2N2/P4PPP/3R1K1R b - -",
    EVAL: -344,
    MOVES: "g4e6 a3c3 b6c5 d4c5 g7c3 c4e6 e8e6 g2g3 a8e8 d1b1",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np1N1/2B5/5Q2/PPPP1PPP/RNB1K2R b KQkq -",
    EVAL: -259,
    MOVES: "d8g5 c4d5 c6d4 f3f7 e8d8 e1h1 c8g4 d2d3 d4e2 g1h1",
    DEPTH: 39,
  },
  {
    FEN: "2r2r1k/1pN1Qpbp/p4pp1/qb6/8/1B6/PP3PPP/2RR2K1 w - -",
    EVAL: 510,
    MOVES: "b3e6 b5e2 e6c8 e2d1 c1d1 a5a2 g2g3 a2b2 d1d8 f8g8",
    DEPTH: 61,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/6B1/3P4/3B1N1P/PPP2Pq1/R2QK2R w KQkq -",
    EVAL: 557,
    MOVES: "h1h2 g2h2 f3h2 f8d6 h2f3 c8d7 a2a3 f7f6 g5e3 e8a8",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1b1r/ppp2kpp/2n5/3np3/2B5/5Q2/PPPP1PPP/RNB1K2R b KQ -",
    EVAL: 87,
    MOVES: "f7e6 b1c3 c6b4 e1h1 c7c6 d2d4 d8f6 f3e2 e6e7 c3e4",
    DEPTH: 51,
  },
  {
    FEN: "r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 25,
    MOVES: "f1c4 c6e5 c4e2 f6g6 e1h1 d7d6 f2f3 e8h8 b1d2 d6d5",
    DEPTH: 40,
  },
  {
    FEN: "2k1rb1r/ppp3p1/2n2q2/7p/4BP2/2P1BQ2/PP3P1P/2KR3R b - -",
    EVAL: 480,
    MOVES: "c8b8 h1g1 f6e6 e4d5 e6d7 g1g5 f8d6 d1g1 e8e7 g5h5",
    DEPTH: 26,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n1p3/6B1/3P4/3B1N1P/PPP2PqR/R2QK3 b Qkq -",
    EVAL: 676,
    MOVES: "g2h2 f3h2 f8d6 h2f3 c8d7 c2c4 f7f6 g5e3 e8a8 a2a3",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk1nr/pppp2pp/4p3/5p2/1bPP4/2N5/PP1BPPPP/R2QKBNR b KQkq -",
    EVAL: 43,
    MOVES: "g8f6 e2e3 d7d6 f1d3 e8h8 g1e2 c7c5 e1h1 b8c6 a1c1",
    DEPTH: 39,
  },
  {
    FEN: "r5k1/ppp2p1p/6pB/8/3bP1nq/2NB2RP/PPP3P1/R2Q3K b - -",
    EVAL: -530,
    MOVES: "g4f2 h1h2 f2d1 c3e2 d4b2 a1d1 h4h6 g3f3 b2e5 h2h1",
    DEPTH: 39,
  },
  {
    FEN: "rn1qkb1r/pp3pp1/2p1pn1p/3p3b/2PP1B2/2N1PN1P/PP3PP1/R2QKB1R w KQkq -",
    EVAL: 95,
    MOVES: "d1b3 d8b6 f3e5 f6d7 c4c5 b6b3 a2b3 d7e5 f4e5 b8d7",
    DEPTH: 23,
  },
  {
    FEN: "r1bqkbnr/2pp1ppp/p1n5/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 24,
    MOVES: "g8f6 e1h1 d7d6 c2c3 f8e7 d2d4 e8h8 h2h3 c8b7 f1e1",
    DEPTH: 59,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 f8b4 d2d3 b4c5 f3e5 c6e5 d3d4 c5b4 d4e5 f6e4",
    DEPTH: 38,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/2b5/3qP1p1/2N5/PPPPBPP1/R1BQ1RK1 w kq -",
    EVAL: -480,
    MOVES: "g2g3 d4e5 g1g2 c5f2 g2f2 h8h2 f2e3 e5g3 e2f3 c8e6",
    DEPTH: 48,
  },
  {
    FEN: "8/1p4q1/p2k4/6p1/P3b3/2Q4P/5PP1/6K1 w - -",
    EVAL: 5438,
    MOVES: "c3g7 g5g4 g7g4 e4c6 g4g5 b7b6 g5d8 d6c5 d8c8 b6b5",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/2N5/PP1BPPPP/R2QKBNR w KQkq -",
    EVAL: 60,
    MOVES: "e2e3 d7d6 f1d3 c7c5 a1c1 e8h8 g1e2 b8c6 e1h1 e6e5",
    DEPTH: 36,
  },
  {
    FEN: "rn1qkbnr/ppp2ppp/4p3/3p1b2/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 20,
    MOVES: "g1f3 f8d6 f4g3 g8f6 c2c4 e8h8 b1c3 h7h6 c4d5 f6d5",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/pp2ppbp/3p2p1/2p3B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: -9,
    MOVES: "h7h6 g5e3 c5d4 f3d4 g8f6 h2h3 b7b6 f2f3 d6d5 d4b5",
    DEPTH: 24,
  },
  {
    FEN: "rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 67,
    MOVES: "g1f3 b8c6 d4e5 c6e5 f3e5 g7e5 f1e2 g8f6 e1h1 c7c6",
    DEPTH: 38,
  },
  {
    FEN: "r5k1/ppp2p1p/6pB/8/3bP2q/2NB2RP/PPP2nP1/R2Q3K w - -",
    EVAL: -431,
    MOVES: "h1h2 f2d1 c3e2 d4b2 a1d1 h4h6 g3f3 b2e5 h2h1 a8f8",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/8/4b3/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 73,
    MOVES: "f1d3 d7d6 e1h1 d8h4 g2g3 h4d8 c3d5 g8f6 d5e3 f6d7",
    DEPTH: 43,
  },
  {
    FEN: "rnbqk1nr/pp2ppbp/3p2p1/6B1/2Pp4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f3d4 h7h6 g5h4 d8b6 e2e3 b6b2 c3d5 e8f8 a1b1 b2a2",
    DEPTH: 43,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2n5/3Bp1q1/8/5Q2/PPPP1PPP/RNB1K2R b KQkq -",
    EVAL: -71,
    MOVES: "c6d4 f3f7 e8d8 e1h1 d4c2 d5e4 c2a1 d2d4 g5e7 f7c4",
    DEPTH: 39,
  },
  {
    FEN: "8/5k2/8/2B5/8/r6P/8/6K1 w - -",
    EVAL: "M17",
    MOVES: "c5a3 f7g6 g1g2 g6h5 g2g3 h5g5 h3h4 g5h5 a3b2 h5h6",
    DEPTH: 98,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1b2/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 22,
    MOVES: "c2c4 f8d6 f4d6 c7d6 d1b3 f5b1 a1b1 d8a5 f3d2 b8d7",
    DEPTH: 45,
  },
  {
    FEN: "r3k3/ppp2pp1/2p1b3/8/3KP1p1/2N3q1/PPPPB2r/R1BQ1R2 w q -",
    EVAL: -1020,
    MOVES: "c3d5 c6d5 d2d3 c7c5 d4c3 g3e5 c3d2 g4g3 d2e1 g3g2",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk1nr/ppp3bp/2np1pp1/4p3/2PPP3/2N1BN2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 77,
    MOVES: "e5d4 f3d4 g8e7 f1e2 f6f5 e1h1 e8h8 d1d2 g7d4 e3d4",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1b2/3P1B2/3BPN2/PPP2PPP/RN1QK2R b KQkq -",
    EVAL: 7,
    MOVES: "f8b4 c2c3 f5d3 c3b4 d3b1 a1b1 c7c6 e1h1 e8h8 h2h3",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 30,
    MOVES: "b1c3 a7a6 c1e3 e7e5 d4b3 f8e7 h2h3 c8e6 d1f3 b8d7",
    DEPTH: 51,
  },
  {
    FEN: "4k2r/2n2p1p/6p1/3n4/5Q2/8/5PPP/6K1 w - -",
    EVAL: 453,
    MOVES: "f4e5 e8d7 e5h8 h7h5 h8d4 d7e6 d4c4 e6d7 f2f4 d7d6",
    DEPTH: 68,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "f1e1 d7d5 c4d5 d8d5 b1c3 d5h5 c3e4 c8e6 c1g5 f8d6",
    DEPTH: 60,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 18,
    MOVES: "g1f3 g8f6 e2e3 a7a6 c4c5 b7b6 c5b6 d8b6 f1d3 a6a5",
    DEPTH: 59,
  },
  {
    FEN: "r1bq1b1r/ppp3pp/2n1k3/3np3/2B5/5Q2/PPPP1PPP/RNB1K2R w KQ -",
    EVAL: 95,
    MOVES: "b1c3 c6b4 e1h1 c7c6 d2d4 d8f6 f3e2 e6e7 d4e5 f6g6",
    DEPTH: 54,
  },
  {
    FEN: "r1b1k1nr/pppp1ppp/2n5/8/1b1NP2q/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 87,
    MOVES: "f1e2 h4e4 d4b5 e8d8 c1g5 g8e7 e1h1 e4g6 g5e7 c6e7",
    DEPTH: 49,
  },
  {
    FEN: "5k2/1pb2p1b/2pr3p/5B1N/1P5P/8/4N3/3R3K w - -",
    EVAL: 336,
    MOVES: "d1d6 c7d6 f5h7 d6e7 h1g2 e7h4 h5f4 f8e7 f4d3 e7d6",
    DEPTH: 33,
  },
  {
    FEN: "rnb1kb1r/ppppqppp/3n4/4N3/2B5/8/PPPPQPPP/RNB1K2R w KQkq -",
    EVAL: 12,
    MOVES: "c4b3 b8c6 e5c6 d7c6 d2d3 a7a5 a2a4 d6f5 c2c3 b7b6",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1b1r/ppp3pp/2n1k3/3np3/2B5/2N2Q2/PPPP1PPP/R1B1K2R b KQ -",
    EVAL: 56,
    MOVES: "c6b4 c4b3 c7c6 a2a3 b4a6 c3d5 c6d5 d2d4 a6c7 c2c4",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 16,
    MOVES: "g8f6 g1f3 e7e6 c1g5 h7h6 g5f6 d8f6 e2e3 b8d7 f1d3",
    DEPTH: 50,
  },
  {
    FEN: "8/1q6/p3p1k1/2P1Q2p/P3P2P/2P5/4r1PK/8 w - -",
    EVAL: 5218,
    MOVES: "e5g5 g6h7 g5h5 h7g7 h5e2 b7e7 e2g4 g7f8 g4g5 e7c7",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 29,
    MOVES: "f8d6 e1h1 e8h8 c2c4 c7c5 c4d5 e4f6 d3c2 c5d4 d1d4",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 111,
    MOVES: "d4d5 c6b8 g1f3 c8g4 f1e2 b8d7 e1h1 c7c6 a2a4 g8f6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 22,
    MOVES: "f8e7 f1e1 b7b5 a4b3 e8h8 h2h3 c8b7 d2d3 d7d5 e4d5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 24,
    MOVES: "f1e2 e7e5 d4b3 f8e7 d1d3 e8h8 e1h1 b8c6 a2a3 c8d7",
    DEPTH: 53,
  },
  {
    FEN: "r2qk2r/pppn1ppp/3bpn2/3p1b2/3P4/2P1PNB1/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "h7h6 f1e2 e8h8 c3c4 a7a5 e1h1 d6g3 h2g3 d8e7 d1b3",
    DEPTH: 27,
  },
  {
    FEN: "r1bq1b1r/ppp3pp/4k3/3np3/1nB5/2N2Q2/PPPP1PPP/R1B1K2R w KQ -",
    EVAL: 115,
    MOVES: "e1h1 c7c6 d2d4 d8f6 f3d1 e6e7 f1e1 f6g6 e1e5 e7d8",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/8/2BNn3/8/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: -87,
    MOVES: "d7d5 c4b5 c8d7 c2c4 f8c5 d4b3 c5f2 f1f2 e4f2 g1f2",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "g2g3 f8b4 c1d2 b4e7 f1g2 e8h8 d1c2 d7d5 g1f3 c7c6",
    DEPTH: 53,
  },
  {
    FEN: "r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 40,
    MOVES: "c8g4 c3e2 e7e6 d4d5 e6d5 c4d5 c6e5 f3e5 g7e5 h2h3",
    DEPTH: 36,
  },
  {
    FEN: "8/8/8/3p4/1k6/1P6/K1P5/8 w - -",
    EVAL: 6050,
    MOVES: "a2a1 b4c3 a1b1 c3b4 b1c1 b4c3 c1d1 d5d4 d1c1 d4d3",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/2pp1ppp/p1n5/1p2p3/4n3/1B3N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 94,
    MOVES: "f1e1 e4c5 b3d5 c5e6 f3e5 c8b7 d2d4 f8d6 e5c6 d7c6",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 23,
    MOVES: "f6g4 e3c1 g4f6 f2f3 e7e5 d4b3 c8e6 c1e3 f8e7 d1d2",
    DEPTH: 47,
  },
  {
    FEN: "r2qk1nr/ppp1ppbp/2np2p1/8/2PPP1b1/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 45,
    MOVES: "c1e3 e7e5 d4d5 c6d4 f1e2 g4f3 e2f3 c7c5 d5c6 b7c6",
    DEPTH: 42,
  },
  {
    FEN: "4k2r/2n2p1p/6p1/3nQ3/8/8/5PPP/6K1 b - -",
    EVAL: 455,
    MOVES: "e8d7 e5h8 h7h5 h8f8 f7f5 f8f7 d5e7 f2f3 c7e6 g1f2",
    DEPTH: 60,
  },
  {
    FEN: "r3k3/ppp2pp1/2p1b3/3N4/3KP1p1/6q1/PPPPB2r/R1BQ1R2 b q -",
    EVAL: -982,
    MOVES: "c6d5 d2d3 c7c5 d4c3 g3e5 c3d2 d5e4 d1e1 e4d3 c2d3",
    DEPTH: 30,
  },
  {
    FEN: "5q2/3k2pp/8/8/8/5N2/6PP/5RK1 w - -",
    EVAL: 12838,
    MOVES: "f3e5 d7e7 f1f8 e7f8 g1f2 f8e8 f2g3 e8e7 g3f3 e7f6",
    DEPTH: 69,
  },
  {
    FEN: "7r/2nk1p1p/6p1/3nQ3/8/8/5PPP/6K1 w - -",
    EVAL: 464,
    MOVES: "e5h8 h7h5 h8d4 d7e6 d4c4 e6d6 f2f4 h5h4 c4e4 f7f5",
    DEPTH: 65,
  },
  {
    FEN: "r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 37,
    MOVES: "d2d4 d7d6 c2c3 f8e7 e1h1 e8h8 h2h3 c8b7 f1e1 f8e8",
    DEPTH: 47,
  },
  {
    FEN: "r3k3/ppp2pp1/4b3/3p4/3KP1p1/6q1/PPPPB2r/R1BQ1R2 w q -",
    EVAL: -713,
    MOVES: "d2d3 c7c5 d4c3 g3e5 c3d2 e5g5 d2e1 g5h4 e1d2 g4g3",
    DEPTH: 34,
  },
  {
    FEN: "r2qk1nr/ppp2pbp/2np2p1/4p3/2PPP1b1/2N1BN2/PP3PPP/R2QKB1R w KQkq -",
    EVAL: 72,
    MOVES: "d4d5 c6d4 f1e2 g4f3 e2f3 c7c5 d5c6 b7c6 e1h1 a8b8",
    DEPTH: 37,
  },
  {
    FEN: "7Q/2nk1p2/6p1/3n3p/8/8/5PPP/6K1 w - -",
    EVAL: 528,
    MOVES: "h8f8 f7f5 f8f7 d5e7 g2g3 c7e8 g1g2 e8d6 f7a2 d7e8",
    DEPTH: 73,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/3p4/3Np3/6P1/PP1PPP1P/RNBQKB1R w KQkq -",
    EVAL: 54,
    MOVES: "d2d3 g8f6 f1g2 f8c5 d4b3 c5b6 e1h1 e8h8 b1c3 e4e3",
    DEPTH: 42,
  },
  {
    FEN: "r3k3/ppp2pp1/4b3/3p4/3KP1p1/3P2q1/PPP1B2r/R1BQ1R2 b q -",
    EVAL: -756,
    MOVES: "c7c5 d4c3 g3e5 c3d2 e5g5 d2e1 g5h4 e1d2 g4g3 e4d5",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 d7d5 c4d5 e6d5 f1b5 c7c6 b5d3 f8d6 e1h1 b8d7",
    DEPTH: 51,
  },
  {
    FEN: "r2q1rk1/pppn1ppp/3bpn2/3p1b2/3P4/2P1PNB1/PP1N1PPP/R2QKB1R w KQ -",
    EVAL: -3,
    MOVES: "g3d6 c7d6 f1e2 e6e5 e1h1 e5e4 f3e1 d7b6 a2a4 a8c8",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQR1K1 b kq -",
    EVAL: 0,
    MOVES: "d7d5 c4d5 d8d5 b1c3 d5h5 c3e4 c8e6 c1g5 f8d6 e4d6",
    DEPTH: 61,
  },
  {
    FEN: "2r2r1k/1p2Qpbp/p4pp1/qN6/8/1B6/PP3PPP/2RR2K1 b - -",
    EVAL: 6,
    MOVES: "c8c1 d1c1 a5b5 c1d1 b7b6 d1d8 b5c5 e7c5 b6c5 d8f8",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R b KQkq -",
    EVAL: 7,
    MOVES: "f8b4 c1d2 b4e7 f1g2 e8h8 d1c2 c7c6 e1h1 b7b6 b1c3",
    DEPTH: 53,
  },
  {
    FEN: "r1b1k1nr/1ppp1ppp/p1n5/b7/4P2q/N1NB4/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: -50,
    MOVES: "b7b5 a3b1 c6e5 e1h1 g8f6 b1d2 c8b7 d2f3 e5f3 d1f3",
    DEPTH: 35,
  },
  {
    FEN: "5r1k/1p2Qpbp/p4pp1/qN6/8/1B6/PP3PPP/2rR2K1 w - -",
    EVAL: 0,
    MOVES: "d1c1 a5b5 c1d1 b7b6 d1d8 b5c5 e7c5 b6c5 d8f8 g7f8",
    DEPTH: 66,
  },
  {
    FEN: "r3k3/pp3pp1/4b3/2pp4/3KP1p1/3P2q1/PPP1B2r/R1BQ1R2 w q -",
    EVAL: -873,
    MOVES: "d4c3 g3e5 c3d2 g4g3 d2e1 g3g2 f1g1 e5g3 e1d2 h2h1",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 11,
    MOVES: "c4b5 c7c6 d5c6 b7c6 b5d3 f6d5 g5f3 f8d6 e1h1 d5f4",
    DEPTH: 55,
  },
  {
    FEN: "8/8/8/8/1k1p4/1P6/1KP5/8 w - -",
    EVAL: 0,
    MOVES: "c2c3 d4c3 b2c2 b4c5 c2c3 c5b5 b3b4 b5b6 c3c4 b6c6",
    DEPTH: 68,
  },
  {
    FEN: "r2qkb1r/ppp1pppp/2n2n2/3p1b2/3P4/5NP1/PPP1PPBP/RNBQK2R w KQkq -",
    EVAL: 48,
    MOVES: "e1h1 e7e6 c2c4 f8e7 f3e5 e8h8 b1c3 h7h6 c4d5 e6d5",
    DEPTH: 53,
  },
  {
    FEN: "8/1N2bp2/2p4p/1k6/1P4B1/3N1K2/8/8 b - -",
    EVAL: 494,
    MOVES: "e7f8 b7c5 b5c4 f3e4 f8d6 g4h5 f7f6 h5f7 c4b5 e4f5",
    DEPTH: 35,
  },
  {
    FEN: "5r1k/1p2Qpbp/p4pp1/1q6/8/1B6/PP3PPP/2R3K1 w - -",
    EVAL: 0,
    MOVES: "c1d1 b7b6 d1d8 b5c5 e7c5 b6c5 d8f8 g7f8 b3f7 f6f5",
    DEPTH: 41,
  },
  {
    FEN: "rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/4PN2/PP2BPPP/RNBQK2R w KQkq -",
    EVAL: 46,
    MOVES: "b1c3 c5d4 e3d4 f8b4 e1h1 e8h8 d1b3 b4e7 d4d5 b8a6",
    DEPTH: 34,
  },
  {
    FEN: "r3k3/pp3pp1/4b3/2pp4/4P1p1/2KP2q1/PPP1B2r/R1BQ1R2 b q -",
    EVAL: -751,
    MOVES: "g3e5 c3d2 g4g3 d2e1 g3g2 f1g1 e5g3 e1d2 h2h1 c2c4",
    DEPTH: 25,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/2np1np1/8/3P1B2/2P1PN1P/PP2BPP1/RN1QK2R b KQ -",
    EVAL: 0,
    MOVES: "f6d7 f4h2 e7e5 e1h1 f7f5 a2a4 g8h8 a4a5 g6g5 a5a6",
    DEPTH: 28,
  },
  {
    FEN: "8/2nknp2/6p1/4Q2p/8/8/5PPP/6K1 w - -",
    EVAL: 323,
    MOVES: "e5f4 e7f5 f4a4 d7d8 a4a5 d8d7 a5a7 f5e7 f2f3 f7f6",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d5c6 b7c6 b5d3 f6d5 h2h4 h7h6 d1h5 d8f6 g5e4 f6e6",
    DEPTH: 48,
  },
  {
    FEN: "r1bqk1nr/ppp1ppbp/3p2p1/3P4/2PnP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 74,
    MOVES: "c1e3 c7c5 g1e2 d8b6 c3a4 b6a5 e3d2 a5c7 d2c3 e7e5",
    DEPTH: 64,
  },
  {
    FEN: "rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 50,
    MOVES: "c1f4 b7b6 b1c3 b8d7 a1c1 c7c6 c4d5 e6d5 b2b4 c8b7",
    DEPTH: 38,
  },
  {
    FEN: "r3k3/pp3pp1/4b3/2ppq3/4P1p1/2KP4/PPP1B2r/R1BQ1R2 w q -",
    EVAL: -672,
    MOVES: "c3d2 e5g5 d2e1 g5h4 e1d2 g4g3 e4d5 e6g4 f1f4 g4e2",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 27,
    MOVES: "f8c5 a2a4 a8b8 c2c3 d7d6 d2d4 c5b6 d1d3 c8d7 a4b5",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/3p2p1/2pP4/2PnP3/2N1B3/PP3PPP/R2QKBNR w KQkq c6",
    EVAL: 88,
    MOVES: "g1e2 d8b6 c3a4 b6a5 e3d2 a5c7 d2c3 e7e5 d5e6 d4e6",
    DEPTH: 33,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q2N1/8/3p4/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 7,
    MOVES: "b1c3 d5e5 c1e3 g8f6 d1d3 b8c6 c3b5 a7a6 d3b3 c8e6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 77,
    MOVES: "a2a3 b4c3 b2c3 g8e7 d1g4 d8c7 g4g7 h8g8 g7h7 c5d4",
    DEPTH: 51,
  },
  {
    FEN: "5q2/3k2pp/8/4N3/8/8/6PP/5RK1 b - -",
    EVAL: 15265,
    MOVES: "d7e8 f1f8 e8f8 g1f2 f8e7 f2g3 e7e6 g3f4 e6f6 e5f3",
    DEPTH: 54,
  },
  {
    FEN: "8/8/8/3p4/1k6/1P6/2P5/1K6 b - -",
    EVAL: 0,
    MOVES: "b4c3 b1c1 d5d4 c1d1 d4d3 c2d3 c3d3 b3b4 d3c4 b4b5",
    DEPTH: 66,
  },
  {
    FEN: "r3k3/pp3pp1/4b3/2ppq3/4P1p1/3P4/PPPKB2r/R1BQ1R2 b q -",
    EVAL: -1003,
    MOVES: "d5e4 d1e1 e4d3 c2d3 c5c4 d2d1 a8d8 c1d2 c4d3 e2d3",
    DEPTH: 25,
  },
  {
    FEN: "rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1 b - -",
    EVAL: 43,
    MOVES: "f8e8 c1b2 b7b6 f3e5 c8b7 b1c3 d8e7 a1c1 e8d8 c4d5",
    DEPTH: 31,
  },
  {
    FEN: "4kq2/6pp/8/4N3/8/8/6PP/5RK1 w - -",
    EVAL: 8583,
    MOVES: "f1f8 e8f8 g1f2 f8e7 f2f3 e7d6 f3f4 d6c5 e5f7 c5d5",
    DEPTH: 59,
  },
  {
    FEN: "r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 13,
    MOVES: "d1f3 f8e7 b5d3 e8h8 b1c3 h7h6 g5e4 f6d5 e4g3 d5b4",
    DEPTH: 56,
  },
  {
    FEN: "r2q1rk1/pppnppbp/2np2p1/6B1/3PP1b1/2N1QN2/PPP2PPP/2KR1B1R b - -",
    EVAL: 53,
    MOVES: "f7f6 g5h6 e7e5 h6g7 g8g7 d4d5 c6e7 h2h4 h7h6 d1e1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR w KQkq -",
    EVAL: 66,
    MOVES: "b2c3 g8e7 d1g4 e7f5 f1d3 h7h5 g4f4 d8h4 f4h4 f5h4",
    DEPTH: 49,
  },
  {
    FEN: "5k2/6pp/8/4N3/8/8/6PP/6K1 w - -",
    EVAL: 6843,
    MOVES: "g1f2 f8e7 f2f3 e7f6 f3f4 f6e6 e5g4 e6f7 f4f5 f7e8",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/ppp2Npp/2n5/3np3/2B5/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 55,
    MOVES: "e8f7 d1f3 f7e6 b1c3 c6b4 e1h1 c7c6 d2d4 d8f6 f3e2",
    DEPTH: 49,
  },
  {
    FEN: "5k2/3q2pp/8/8/8/5N2/6PP/5RK1 w - -",
    EVAL: "M18",
    MOVES: "f3e5 f8e8 e5d7 e8d7 f1f7 d7e6 f7g7 h7h6 g7g6 e6f5",
    DEPTH: 88,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -",
    EVAL: 42,
    MOVES: "g1f3 b8c6 f1g2 d7d6 e1h1 e8h8 e2e3 f8e8 d2d4 c5b6",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/3p2p1/2pP4/2PnP3/2N1B3/PP2NPPP/R2QKB1R b KQkq -",
    EVAL: 130,
    MOVES: "d4e2 f1e2 f7f5 e4f5 c8f5 e1h1 g8h6 d1d2 h6f7 a2a4",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2nb1n2/3p4/8/P3PN2/1BPP1PPP/RN1QKB1R w KQ -",
    EVAL: 40,
    MOVES: "c2c4 d5c4 f1c4 c6a5 c4e2 b7b6 e1h1 c7c5 d2d4 d8e7",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/ppp1ppbp/2np2p1/3P4/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 95,
    MOVES: "c6b8 f1d3 e7e6 g1f3 b8d7 d5e6 f7e6 d3c2 g8f6 e4e5",
    DEPTH: 35,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1P3/2Bp4/2P2N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 c4b5 f6e4 c3d4 c5b6 b1c3 e8h8 c1e3 c8g4 h2h3",
    DEPTH: 55,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 35,
    MOVES: "a2a3 f8e7 h1g1 e6e5 d4b3 f6g4 e3d2 c8e6 c3d5 b8d7",
    DEPTH: 52,
  },
  {
    FEN: "r3r1k1/pp3pbp/1qp1b1p1/2B5/2BP4/Q1n2N2/P4PPP/3R1K1R w - -",
    EVAL: -271,
    MOVES: "a3c3 b6c5 d4c5 g7c3 c4e6 e8e6 g2g3 b7b5 c5b6 a7b6",
    DEPTH: 51,
  },
  {
    FEN: "rnbq1rk1/ppp2pbp/5pp1/3p4/3PP3/5N2/PPPN1PPP/R2QKB1R w KQ -",
    EVAL: -27,
    MOVES: "e4d5 d8d5 f1e2 c7c5 d2b3 c5d4 e1h1 f6f5 f3d4 d5d8",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/8/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 55,
    MOVES: "b1c3 e7e5 g1e2 c8e6 g2g3 c7c6 d4d5 c6d5 c4d5 e6d7",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/4p3/8/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 95,
    MOVES: "f1b5 f8d6 d2d4 e8h8 b5c6 e5d4 d1d4 b7c6 c1e3 c6c5",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P5/4PN2/PP2BPPP/RNBQ1RK1 b - -",
    EVAL: 0,
    MOVES: "e7e5 d5e6 c8e6 b1c3 b8c6 e3e4 c6a5 d1a4 f8e8 c1e3",
    DEPTH: 54,
  },
  {
    FEN: "r3k3/7p/6p1/5p2/5r2/2NP4/PPP2PPP/R5K1 w - -",
    EVAL: 870,
    MOVES: "c3d5 f4a4 d5c7 e8d8 c7a8 a4a8 a2a4 a8b8 b2b3 d8c7",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/pp1p1ppp/2p2n2/2b1p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R b KQ -",
    EVAL: 0,
    MOVES: "d7d5 c4d5 f6d5 c3d5 c6d5 d2d4 e5d4 e3d4 c5b6 c1e3",
    DEPTH: 43,
  },
  {
    FEN: "r2qkb1r/ppp2ppp/2n1pn2/3p1bB1/3P4/5NP1/PPP1PPBP/RN1Q1RK1 b kq -",
    EVAL: 13,
    MOVES: "f8e7 b1d2 e8h8 c2c3 f6e4 g5e7 c6e7 a2a4 a7a5 f3e5",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n2n2/2b1p3/2P1P3/3B1N2/PP1P1PPP/RNBQ1RK1 w - -",
    EVAL: -40,
    MOVES: "b1c3 d7d6 d3e2 c8g4 d2d3 g4f3 e2f3 c6d4 c1e3 a7a5",
    DEPTH: 24,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 16,
    MOVES: "e7e6 g1f3 d7d5 b1c3 f8b4 c4d5 e6d5 c1g5 h7h6 g5h4",
    DEPTH: 51,
  },
  {
    FEN: "r3qrk1/pppnppbp/2np2p1/6B1/3PP1b1/2N1QN2/PPP2PPP/2KR1B1R w - -",
    EVAL: 165,
    MOVES: "g5h6 e7e5 d4e5 d6e5 h2h4 f7f6 f1c4 g4e6 c4e6 e8e6",
    DEPTH: 37,
  },
  {
    FEN: "r4k2/2r2pp1/7p/3P4/4B3/5N2/6PP/5RK1 w - -",
    EVAL: 744,
    MOVES: "d5d6 a8c8 d6c7 c8c7 f3e5 f7f6 e5g6 f8e8 e4f5 c7c5",
    DEPTH: 52,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/3P4/2P3b1/5N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 83,
    MOVES: "g4f3 d1f3 e7e6 d5e6 b8c6 b1c3 f7e6 f1e2 c6d4 f3d3",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: -17,
    MOVES: "e5d4 g1f3 f8b4 c1d2 b4d2 b1d2 b8c6 f1b5 a7a6 b5c6",
    DEPTH: 55,
  },
  {
    FEN: "8/8/8/3p4/1k6/1P6/2P5/1K6 w - -",
    EVAL: "M28",
    MOVES: "b1c1 b4c3 c1d1 d5d4 d1c1 d4d3 c2d3 c3d3 c1b2 d3d4",
    DEPTH: 83,
  },
  {
    FEN: "1rb2rk1/p1q2ppp/1pnbp3/2pn4/3P4/2P2NP1/PP1N1PBP/R1BQR1K1 w - -",
    EVAL: 44,
    MOVES: "d2e4 c8a6 e4d6 c7d6 d4c5 d6c5 c1d2 b8c8 b2b4 c5e7",
    DEPTH: 36,
  },
  {
    FEN: "r3k3/7p/6p1/3N1p2/5r2/3P4/PPP2PPP/R5K1 b - -",
    EVAL: 860,
    MOVES: "f4a4 d5c7 e8d7 c7a8 a4a8 a2a4 a8b8 b2b3 d7c6 g1f1",
    DEPTH: 42,
  },
  {
    FEN: "2rq1r1k/pp3pRp/1b1p4/3p4/4P3/3B1P2/Pn2NP1P/6RK w - -",
    EVAL: -1566,
    MOVES: "d3b1 b2d1 g1d1 h8g7 h1g2 d5e4 b1e4 f7f5 e4b1 f5f4",
    DEPTH: 30,
  },
  {
    FEN: "8/1q6/p3p1k1/2P3Qp/P3P2P/2P5/4r1PK/8 b - -",
    EVAL: 1171,
    MOVES: "g6f7 g5h5 f7g7 h5e2 b7c7 e4e5 c7c5 e2g4 g7f7 g4g5",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/pp3pbp/3ppnp1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "e8h8 f1e2 e6d5 c4d5 f8e8 e4e5 d6e5 f4e5 f6g4 e5e6",
    DEPTH: 39,
  },
  {
    FEN: "r3k3/7p/6p1/3N1p2/r7/3P4/PPP2PPP/R5K1 w - -",
    EVAL: 917,
    MOVES: "d5c7 e8d7 c7a8 a4a8 a2a3 a8b8 b2b4 d7c7 d3d4 b8d8",
    DEPTH: 43,
  },
  {
    FEN: "r3qrk1/pppnppbp/2np2pB/8/3PP1b1/2N1QN2/PPP2PPP/2KR1B1R b - -",
    EVAL: 149,
    MOVES: "e7e6 d4d5 e6d5 c3d5 a8c8 h2h4 f7f6 c1b1 g7h6 e3h6",
    DEPTH: 34,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/2p2n2/3P4/2PP2b1/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "c6d5 b1c3 b8c6 c4d5 f6d5 d1b3 g4f3 g2f3 e7e6 b3b7",
    DEPTH: 24,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/3bp3/3p4/3P4/4P1B1/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 11,
    MOVES: "c7c5 d4c5 d6c5 g1f3 g8f6 a2a3 e8h8 b1d2 f6h5 g3h4",
    DEPTH: 41,
  },
  {
    FEN: "r2qkb1r/ppp2ppp/2n1pn2/3p1b2/3P4/5NP1/PPP1PPBP/RNBQ1RK1 w kq -",
    EVAL: 52,
    MOVES: "c2c4 f8e7 b1c3 f6e4 c4d5 e6d5 f3d2 e4f6 d2b3 c6b4",
    DEPTH: 36,
  },
  {
    FEN: "1rb2rk1/p1q2ppp/1pnNp3/3n4/2pP4/2P2NP1/PP3PBP/R1BQR1K1 b - -",
    EVAL: 101,
    MOVES: "c7d6 f3d2 c8a6 g2f1 b6b5 d2e4 d6c7 e4c5 a6c8 a2a4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: 6,
    MOVES: "b8c6 f1c4 g8f6 d2d3 f8c5 g1f3 h7h6 e1h1 d7d6 c3a4",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/5n2/3p4/2PP2b1/5N2/PP2BPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d5c4 e1h1 e7e6 b1d2 f8e7 d2c4 e8h8 d1b3 d8c7 c4e3",
    DEPTH: 32,
  },
  {
    FEN: "r3k3/2N4p/6p1/5p2/r7/3P4/PPP2PPP/R5K1 b - -",
    EVAL: 896,
    MOVES: "e8d7 c7a8 a4a8 a2a4 a8b8 b2b3 b8c8 c2c4 c8b8 a4a5",
    DEPTH: 47,
  },
  {
    FEN: "r7/2Nk3p/6p1/5p2/r7/3P4/PPP2PPP/R5K1 w - -",
    EVAL: 892,
    MOVES: "c7a8 a4a8 a2a3 f5f4 b2b4 a8a4 c2c4 d7c6 g1f1 h7h6",
    DEPTH: 41,
  },
  {
    FEN: "r4k2/2r3p1/6pp/3P4/8/5N2/6PP/5RK1 w - -",
    EVAL: -97,
    MOVES: "d5d6 c7c5 f3e5 f8g8 f1e1 c5d5 d6d7 g6g5 h2h3 g8h7",
    DEPTH: 31,
  },
  {
    FEN: "rn1q1rk1/pbpp1ppp/1p2p3/6N1/2PPn3/6P1/PPQ1PPBP/R3K2R w KQ -",
    EVAL: 59,
    MOVES: "g5e4 b8c6 d4d5 f7f5 e4c3 c6e5 b2b3 c7c6 e1h1 c6d5",
    DEPTH: 32,
  },
  {
    FEN: "1rb2rk1/p4ppp/1pnqp3/3n2N1/2pP4/2P3P1/PP3PBP/R1BQR1K1 b - -",
    EVAL: 33,
    MOVES: "h7h6 g5e4 d6d7 e4d2 c8a6 g2f1 c6a5 d2f3 a5c6 a2a4",
    DEPTH: 37,
  },
  {
    FEN: "r2qkb1r/ppp2ppp/2n1pn2/3p1b2/3P4/2P2NP1/PP2PPBP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "f8e7 c3c4 e8h8 b2b3 c6b4 b1c3 b4c2 a1b1 c2b4",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/3QP3/8/PPP2PPP/RNB1KBNR b KQkq -",
    EVAL: -5,
    MOVES: "b8c6 d4e3 g8f6 c1d2 f8e7 b1c3 d7d5 c3d5 f6d5 e4d5",
    DEPTH: 50,
  },
  {
    FEN: "r7/3k3p/6p1/5p2/8/3P4/PPP2PPP/R5K1 w - -",
    EVAL: 908,
    MOVES: "a2a4 a8b8 b2b3 b8c8 a1a2 c8b8 a4a5 d7c7 a2a1 b8b5",
    DEPTH: 43,
  },
  {
    FEN: "8/8/8/3p4/8/1Pk5/2P5/2K5 w - -",
    EVAL: "M27",
    MOVES: "c1d1 d5d4 d1c1 d4d3 c2d3 c3d3 c1b2 d3d4 b2a3 d4c5",
    DEPTH: 77,
  },
  {
    FEN: "8/8/8/8/3p4/1Pk5/2P5/3K4 w - -",
    EVAL: "M26",
    MOVES: "d1c1 d4d3 c2d3 c3d3 c1b2 d3d4 b2a3 d4c5 a3a4 c5b6",
    DEPTH: 75,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 11,
    MOVES: "f6e4 f1e1 e4d6 f3e5 c6e5 e1e5 f8e7 b1c3 e8h8 b5f1",
    DEPTH: 67,
  },
  {
    FEN: "3r1k2/pp1n2pb/q1p1Qp2/2P2r2/3Pp1Np/P1P1B2P/6P1/1R1R2K1 w - -",
    EVAL: 2033,
    MOVES: "g4h6 a6c4 e6c4 f5d5 b1b7 h7g8 d1e1 g8e6 c4a4 d8c8",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 15,
    MOVES: "c4d5 f6d5 f1d3 f8e7 e1h1 e8h8 c3d5 d8d5 d1c2 d5h5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 69,
    MOVES: "b1c3 d7d6 c1e3 g8f6 d1d2 b8d7 g1f3 e8h8 h2h3 c7c6",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkb1r/pp1bpppp/5n2/3P4/2B5/8/PP1P1PPP/RNBQK1NR b KQkq -",
    EVAL: 29,
    MOVES: "b7b5 c4b3 a7a5 a2a3 a5a4 b3a2 b5b4 a2c4 d8c7 d2d3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/P1N2N2/1PPP1PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7d6 h2h3 e8h8 e1h1 c8e6 c4e6 f7e6 d2d3 c6d4 c1e3",
    DEPTH: 38,
  },
  {
    FEN: "r4k2/2r2pp1/3P3p/8/4B3/5N2/6PP/5RK1 b - -",
    EVAL: 633,
    MOVES: "a8a7 d6c7 a7c7 f3e5 f7f6 e5c6 f8f7 g1f2 g7g6 e4d5",
    DEPTH: 42,
  },
  {
    FEN: "1rb2rk1/p4ppp/1p1qp1n1/3n2N1/2pP3P/2P3P1/PPQ2PB1/R1B1R1K1 b - -",
    EVAL: 220,
    MOVES: "f7f6 g5e4 d6d7 h4h5 g6h8 b2b3 c8a6 b3c4 a6c4 e4d2",
    DEPTH: 28,
  },
  {
    FEN: "2r2r1k/1pN1Qpbp/p4pp1/1bR5/1q6/1B6/PP3PPP/3R2K1 w - -",
    EVAL: -161,
    MOVES: "c5e5 b4f4 e5c5 b5c6 c7d5 f4h4 c5c4 h4h5 d5f6 h5f5",
    DEPTH: 34,
  },
  {
    FEN: "rn1q1rk1/pbpp1ppp/1p2p3/6N1/2PPB3/6P1/PPQ1PP1P/R3K2R b KQ -",
    EVAL: 50,
    MOVES: "b7e4 c2e4 d8g5 e4a8 b8c6 a8b7 c6d4 a1d1 c7c5 e2e3",
    DEPTH: 39,
  },
  {
    FEN: "r4k2/3r1pp1/3P3p/8/4B3/5N2/6PP/5RK1 w - -",
    EVAL: 709,
    MOVES: "e4a8 d7d6 g1f2 d6a6 a8d5 a6a5 f1d1 f8e7 f2e3 e7f6",
    DEPTH: 36,
  },
  {
    FEN: "8/8/8/8/8/1Pkp4/2P5/2K5 w - -",
    EVAL: "M25",
    MOVES: "c2d3 c3d3 c1b2 d3d4 b2a3 d4c5 a3a4 c5b6 a4b4 b6c6",
    DEPTH: 77,
  },
  {
    FEN: "rn1qkb1r/p2bpppp/5n2/1p1P4/8/1B6/PP1P1PPP/RNBQK1NR b KQkq -",
    EVAL: 34,
    MOVES: "a7a5 a2a3 a5a4 b3a2 b5b4 a2c4 d8c8 d2d3 c8b7 g1f3",
    DEPTH: 33,
  },
  {
    FEN: "8/1bRQ1ppk/p3p2p/1p1q4/3Pp3/1P2b1P1/P5KP/8 b - -",
    EVAL: -851,
    MOVES: "d5d7 c7d7 b7d5 d7f7 e3d4 g2f1 h7g6 f7f8 g6h5 h2h3",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkb1r/3bpppp/5n2/pp1P4/8/1B6/PP1P1PPP/RNBQK1NR w KQkq -",
    EVAL: 26,
    MOVES: "a2a3 a5a4 b3a2 b5b4 a2c4 d8c8 d2d3 c8b7 g1f3 f6d5",
    DEPTH: 34,
  },
  {
    FEN: "rnbqk1nr/1pp1ppbp/p2p2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 76,
    MOVES: "f2f3 b7b5 d1d2 b8d7 h2h4 g8f6 e1a1 d7b6 g1h3 h7h5",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/8/4P3/4Q3/PPP2PPP/RNB1KBNR b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 c1d2 f8e7 b1c3 d7d5 c3d5 f6d5 e4d5 d8d5 g1e2",
    DEPTH: 55,
  },
  {
    FEN: "2b2rk1/p3q2p/1p1p2p1/2pP1r2/2B5/6QP/PPP2RP1/5RK1 b - -",
    EVAL: -113,
    MOVES: "f5f2 f1f2 e7e1 c4f1 e1f2 g3f2 f8f2 g1f2 g6g5 f2e3",
    DEPTH: 56,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 15,
    MOVES: "d7d5 e4d5 f6d5 c3d5 d8d5 f1e2 b8c6 c2c3 e7e5 d4e5",
    DEPTH: 49,
  },
  {
    FEN: "4r3/pp1nr3/2p4p/4pkb1/4RP2/1PBN2P1/P1P1K3/3R4 w - -",
    EVAL: 568,
    MOVES: "g3g4 f5g4 f4g5 g4f5 e4h4 f5g5 h4h3 g5g6 d3f2 d7f6",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2nn4/1B2p3/8/5N2/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 24,
    MOVES: "f3e5 f8e7 b5f1 c6e5 e1e5 e8h8 d2d4 e7f6 e5e1 f8e8",
    DEPTH: 55,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR b KQkq -",
    EVAL: -13,
    MOVES: "d5c4 a2a4 d8b6 d1d2 b6a5 e2e3 b7b5 f1e2 g8f6 g1f3",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk2r/pppp1p1p/2n2np1/2bNp3/2B1P1Q1/8/PPPP1PPP/R1B1K1NR w KQkq -",
    EVAL: 0,
    MOVES: "g4f3 f6d5 c4d5 e8h8 g1e2 a7a5 a2a3 d8h4 d2d3 d7d6",
    DEPTH: 25,
  },
  {
    FEN: "rn1q1rk1/p1pp1ppp/1p2p3/6N1/2PPb3/6P1/PPQ1PP1P/R3K2R w KQ -",
    EVAL: 46,
    MOVES: "c2e4 d8g5 e4a8 b8c6 a8b7 c6d4 a1d1 c7c5 e2e3 d4c2",
    DEPTH: 39,
  },
  {
    FEN: "1rb2rk1/p4ppn/1p1qp1n1/7P/2pP4/2P3P1/PPQ2PB1/R1B1R1K1 b - -",
    EVAL: 272,
    MOVES: "g6h4 c1f4 d6e7 g3h4 b8b7 f4g3 f7f5 c2d2 f5f4 g3f4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2p2n2/2b4p/4P3/2N4P/PPPPBPP1/R1BQK2R b KQkq -",
    EVAL: -81,
    MOVES: "d8d4 h1f1 f6e4 e2f3 e4c3 d2c3 d4d1 e1d1 c8e6 c1e3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 54,
    MOVES: "d2d4 d7d6 g1f3 g8f6 f1e2 e8h8 e1h1 c7c5 d4d5 e7e6",
    DEPTH: 36,
  },
  {
    FEN: "3r1k2/pp4pb/q1p1Qn2/2P2r2/3Pp2p/P1P1B2P/6P1/1R1R2K1 w - -",
    EVAL: -485,
    MOVES: "d4d5 d8e8 e6d6 f8g8 d5c6 a6c6 d1e1 f6d5 e3d4 f5f7",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 f4e5 f6e4 d1f3 b8c6 f1b5 e4c3 d2c3 a7a6 b5c6",
    DEPTH: 56,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p2n2/2b4p/3qP3/2N4P/PPPPBPP1/R1BQ1RK1 b kq -",
    EVAL: -256,
    MOVES: "f6g4 e2g4 h5g4 d2d3 d4d6 d3d4 c5d4 e4e5 d6c5 c3e4",
    DEPTH: 41,
  },
  {
    FEN: "8/8/8/8/3p4/1Pk5/2P5/2K5 b - -",
    EVAL: "M25",
    MOVES: "d4d3 c2d3 c3d3 c1b2 d3d4 b2a3 d4c5 a3a4 c5b6 a4b4",
    DEPTH: 72,
  },
  {
    FEN: "5Bk1/1b1r2p1/q4p1p/2Q5/2P5/6PP/P4P2/4R1K1 b - -",
    EVAL: -1178,
    MOVES: "a6e6 c5b4 e6h3 b4b7 d7b7 f8c5 b7c7 c5b4 c7c4 e1e8",
    DEPTH: 52,
  },
  {
    FEN: "1rbq1rk1/p4ppn/1p2p3/7P/2pP1B1n/2P3P1/PPQ2PB1/R3R1K1 w - -",
    EVAL: 197,
    MOVES: "g3h4 b8b7 h5h6 d8h4 h6g7 f8d8 f4g3 h4g4 e1e4 g4g6",
    DEPTH: 36,
  },
  {
    FEN: "1rbq1rk1/p4ppn/1p2p3/7P/2pP1B1P/2P5/PPQ2PB1/R3R1K1 b - -",
    EVAL: 312,
    MOVES: "b8b7 g2e4 f7f5 e4b7 c8b7 f2f3 b7f3 e1e6 d8h4 c2h2",
    DEPTH: 43,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 51,
    MOVES: "a7a6 g1f3 g8f6 h2h3 b8c6 d1d2 e8h8 d4d5 c6b8 a2a4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
    EVAL: 25,
    MOVES: "c2c4 g8f6 b1c3 f8b4 c1g5 h7h6 g5f6 d8f6 e2e3 e8h8",
    DEPTH: 47,
  },
  {
    FEN: "2bq1rk1/pr3ppn/1p2p2P/8/2pP1B1P/2P5/PPQ2PB1/R3R1K1 b - -",
    EVAL: 192,
    MOVES: "d8h4 h6g7 f8d8 f4g3 h4g5 c2a4 b7d7 a4c4 c8b7 g2b7",
    DEPTH: 44,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/4q3/4P1p1/2N3P1/PPPPBbK1/R1BQ1R2 w kq -",
    EVAL: -598,
    MOVES: "g2f2 h8h2 f2e3 e5g3 e2f3 c8e6 c3e2 g3h4 d1e1 h4h6",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 72,
    MOVES: "g1f3 g8f6 c1e3 e8h8 d1d2 c7c6 a2a4 d6d5 e4e5 f6e4",
    DEPTH: 48,
  },
  {
    FEN: "2b2rk1/pr3ppn/1p2p2P/8/2pP1B1q/2P5/PPQ2PB1/R3R1K1 w - -",
    EVAL: 149,
    MOVES: "h6g7 f8e8 f4g3 h4g5 e1e3 f7f6 f2f4 g5g4 g2c6 e8e7",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2nn4/1B2p3/P7/5N2/1PPP1PPP/RNBQR1K1 b kq -",
    EVAL: 15,
    MOVES: "f8e7 b1c3 a7a6 b5f1 f7f6 d2d4 d6f7 c3d5 d7d6 b2b4",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: 17,
    MOVES: "g8f6 d2d3 d7d6 e1h1 e8h8 f1e1 a7a5 h2h3 h7h6 c4b5",
    DEPTH: 48,
  },
  {
    FEN: "2qb2k1/5rpp/p3Q3/2N1p3/4P3/3Pp3/1P4PP/1K6 w - -",
    EVAL: 0,
    MOVES: "e6c8 e3e2 b1a2 e2e1q c8d8 f7f8 d8d5 g8h8 c5e6 f8g8",
    DEPTH: 52,
  },
  {
    FEN: "2b2rk1/pr3pPn/1p2p3/8/2pP1B1q/2P5/PPQ2PB1/R3R1K1 b - -",
    EVAL: 188,
    MOVES: "f8e8 f4g3 h4g5 e1e3 b7d7 c2a4 h7f6 a1e1 c8b7 g2b7",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N1BN2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 f1c4 e8h8 e1h1 b8c6 c4b3 c6a5 e3g5 c8e6 g5f6",
    DEPTH: 50,
  },
  {
    FEN: "2b2r2/pr3pkn/1p2p3/8/2pP1B1q/2P5/PPQ2PB1/R3R1K1 w - -",
    EVAL: 405,
    MOVES: "e1e4 h4h5 e4e3 e6e5 e3g3 g7h8 g2f3 e5f4 f3h5 f4g3",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1n2p3/P7/5N2/1PPP1PPP/RNBQR1K1 w kq -",
    EVAL: 194,
    MOVES: "a4b5 c6e7 f3e5 d7d6 e5f3 g7g6 d1e2 h7h6 d2d4 f8g7",
    DEPTH: 31,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq -",
    EVAL: 55,
    MOVES: "g8f6 g1f3 e8h8 f1d3 b8c6 d3e2 c8g4 d4d5 c6b8 e1h1",
    DEPTH: 42,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/4q3/4P1p1/2N3P1/PPPPBRK1/R1BQ4 b kq -",
    EVAL: -528,
    MOVES: "e5h5 f2f5 h5h2 g2f1 c8f5 e4f5 h2g3 e2f3 g4f3 d1e1",
    DEPTH: 41,
  },
  {
    FEN: "8/8/8/8/8/1P1k4/8/2K5 w - -",
    EVAL: "M24",
    MOVES: "c1b2 d3d4 b2a3 d4c5 a3a4 c5b6 a4b4 b6c6 b4a5 c6b7",
    DEPTH: 75,
  },
  {
    FEN: "2r2r1k/1pN1Qpbp/p3Bpp1/q7/8/8/PP2bPPP/2RR2K1 w - -",
    EVAL: 551,
    MOVES: "e6c8 e2d1 c1d1 a5a2 g2g3 h7h6 d1d8 f8d8 e7d8 h8h7",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 28,
    MOVES: "c2c3 e8h8 h2h3 c8b7 d2d4 f8e8 b1d2 e7f8 a2a3 h7h6",
    DEPTH: 47,
  },
  {
    FEN: "rnb1k1nr/ppq2ppp/4p3/3pP3/3p3P/P1P5/2P2PP1/R1BQKBNR w KQkq -",
    EVAL: 97,
    MOVES: "c3d4 c7c3 c1d2 c3d4 g1f3 d4b6 f1d3 b8c6 h4h5 c8d7",
    DEPTH: 35,
  },
  {
    FEN: "8/8/8/8/8/1P1k4/1K6/8 b - -",
    EVAL: "M23",
    MOVES: "d3d4 b2a3 d4c5 a3a4 c5b6 a4b4 b6c6 b4a5 c6b7 a5b5",
    DEPTH: 69,
  },
  {
    FEN: "8/8/8/8/3k4/1P6/1K6/8 w - -",
    EVAL: "M23",
    MOVES: "b2a3 d4c5 a3a4 c5b6 a4b4 b6c6 b4a5 c6b7 a5b5 b7a7",
    DEPTH: 78,
  },
  {
    FEN: "rnb1kbnr/ppp2ppp/8/3qp1N1/4p3/3P4/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 6,
    MOVES: "b1c3 f8b4 g5e4 b8c6 a2a3 b4c3 e4c3 d5c5 c1e3 c6d4",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1P2p3/8/5N2/1PPP1PPP/RNBQR1K1 b kq -",
    EVAL: 175,
    MOVES: "c6e7 f3e5 d7d6 e5f3 g7g6 d1e2 f8g7 d2d4 h7h6 c2c4",
    DEPTH: 26,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/7q/4P1p1/2N3P1/PPPPBRK1/R1BQ4 w kq -",
    EVAL: -738,
    MOVES: "f2f5 h5h2 g2f1 c8f5 e2g4 h2g3 d1f3 g3g4 f3g4 f5g4",
    DEPTH: 44,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/5R1q/4P1p1/2N3P1/PPPPB1K1/R1BQ4 b kq -",
    EVAL: -746,
    MOVES: "h5h2 g2f1 c8f5 e2g4 h2g3 d1f3 g3g4 f3g4 f5g4 f1g2",
    DEPTH: 33,
  },
  {
    FEN: "4r3/1R1P1k1p/1p1q2p1/1Pp5/2P5/6P1/6KP/8 w - -",
    EVAL: "M22",
    MOVES: "d7d8q d6e7 b7e7 e8e7 d8b6 g6g5 b6c6 e7e6 c6c7 f7g6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/pp3pbp/3p1np1/2pp4/2P1PP2/2N2N2/PP4PP/R1BQKB1R w KQkq -",
    EVAL: 43,
    MOVES: "c4d5 e8h8 f1e2 f8e8 e4e5 d6e5 f4e5 f6g4 e5e6 f7e6",
    DEPTH: 29,
  },
  {
    FEN: "8/8/1k6/8/K7/1P6/8/8 w - -",
    EVAL: "M21",
    MOVES: "a4b4 b6c7 b4c5 c7b7 c5b5 b7a7 b5c6 a7a8 b3b4 a8b8",
    DEPTH: 99,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/5R2/4P1p1/2N3P1/PPPPB1Kq/R1BQ4 w kq -",
    EVAL: -686,
    MOVES: "g2f1 c8f5 e2g4 f5g4 d1g4 h2h1 f1f2 h8h2 f2e3 h1g1",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 64,
    MOVES: "d2d4 e5d4 f3d4 f8e7 b1c3 g8f6 c1f4 b8c6 d1d2 e8h8",
    DEPTH: 54,
  },
  {
    FEN: "r1b2rk1/ppp2ppp/2p2q2/8/4K3/3P3P/PPP3P1/RNBQ1B1R w - -",
    EVAL: 157,
    MOVES: "c1f4 f8e8 e4f3 g7g5 d1d2 g5f4 b1c3 e8e3 f3f2 f6d4",
    DEPTH: 41,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/5R2/4P1p1/2N3P1/PPPPB2q/R1BQ1K2 b kq -",
    EVAL: -568,
    MOVES: "h2h3 f1f2 h3h2 f2f1 c8f5 e2g4 f5g4 d1g4 h2h1 f1e2",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 8,
    MOVES: "g1f3 f8c5 e1h1 c7c6 b5c4 d4f3 d1f3 g8f6 d2d3 d7d6",
    DEPTH: 35,
  },
  {
    FEN: "2b2r2/pr3pkn/1p2p3/8/2pPRB1q/2P5/PPQ2PB1/R5K1 b - -",
    EVAL: 405,
    MOVES: "h4h5 e4e3 e6e5 e3e5 h5g4 c2d2 g7h8 e5e4 g4f5 f4g3",
    DEPTH: 36,
  },
  {
    FEN: "8/8/4N1B1/8/8/4k3/8/2K5 w - -",
    EVAL: "M16",
    MOVES: "c1d1 e3f2 d1d2 f2f3 d2d3 f3g3 g6h5 g3h4 h5f3 h4h3",
    DEPTH: 83,
  },
  {
    FEN: "8/8/k7/8/KP6/8/8/8 w - -",
    EVAL: 0,
    MOVES: "b4b5 a6b6 a4b4 b6c7 b4c5 c7b7 b5b6 b7b8 c5d5 b8b7",
    DEPTH: 83,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/2NP4/PPP2PPP/R1BQK1NR b KQkq -",
    EVAL: -56,
    MOVES: "c7c6 b5a4 b7b5 a4b3 a7a5 g1f3 d7d6 a2a4 d4b3 c2b3",
    DEPTH: 25,
  },
  {
    FEN: "2B2r1k/1pN1Qpbp/p4pp1/q7/8/8/PP3PPP/2Rb2K1 w - -",
    EVAL: 562,
    MOVES: "c1d1 a5a2 g2g3 a2b2 d1d8 f8g8 c7e8 f6f5 c8b7 g7d4",
    DEPTH: 48,
  },
  {
    FEN: "r3kb1r/pp1n1ppp/1qp1pn2/3p1b2/3P1B2/1QP1PN2/PP1N1PPP/R3KB1R w KQkq -",
    EVAL: 47,
    MOVES: "f3h4 f5g6 h4g6 h7g6 h2h4 a7a5 b3c2 a5a4 f1e2 c6c5",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 41,
    MOVES: "d2d4 c5d4 f3d4 b8c6 b1c3 a7a6 d4c6 b7c6 d1f3 d7d5",
    DEPTH: 52,
  },
  {
    FEN: "8/8/1k6/8/KP6/8/8/8 b - -",
    EVAL: 0,
    MOVES: "b6a6 b4b5 a6b7 a4a5 b7a7 b5b6 a7b8 a5b5 b8b7 b5c5",
    DEPTH: 82,
  },
  {
    FEN: "rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/2N1BN2/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 25,
    MOVES: "f1c4 e8h8 c4b3 b8c6 e3g5 f6d7 g5e7 d8e7 d1d2 d7f6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "b8c6 f1b5 g8f6 e1h1 f6e4 f1e1 e4d6 f3e5 f8e7 b5f1",
    DEPTH: 59,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -",
    EVAL: 20,
    MOVES: "d2d4 e7e6 c2c4 g8f6 b1c3 f8b4 c1g5 h7h6 g5f6 d8f6",
    DEPTH: 52,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQ -",
    EVAL: 11,
    MOVES: "c4d5 f6d5 f1c4 d5c3 b2c3 c7c5 e1h1 b7b6 d1e2 b8c6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 57,
    MOVES: "e4d5 g8f6 d2d4 f6d5 g1f3 c8f5 f1d3 f5d3 d1d3 c7c6",
    DEPTH: 56,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/7q/4PRp1/2N3P1/PPPPB1K1/R1BQ4 b kq -",
    EVAL: "M-27",
    MOVES: "h5h2 g2f1 h2g3 e2f3 g3f4 d2d4 h8h1 f1e2 f4f3 e2d2",
    DEPTH: 44,
  },
  {
    FEN: "r1b2rk1/pp2ppbp/1qnp1np1/4P3/3N1P2/2N1B3/PPP1B1PP/R2Q1RK1 b - -",
    EVAL: -31,
    MOVES: "d6e5 f4e5 c6e5 d4f5 b6b2 f5e7 g8h8 e3d4 b2b4 d4e5",
    DEPTH: 57,
  },
  {
    FEN: "rnbqk2r/1p2bppp/p2p1n2/4p3/2B1P3/2N1BN2/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 0,
    MOVES: "e8h8 c4b3 c8e6 e1h1 b7b5 c3d5 b8d7 a2a4 f6d5 b3d5",
    DEPTH: 46,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/8/4PN2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 45,
    MOVES: "c2c4 c7c6 d1b3 d8c8 b1c3 e7e6 f3h4 f5g6 d2d4 g8f6",
    DEPTH: 39,
  },
  {
    FEN: "3Qr3/1R2qk1p/1p4p1/1Pp5/2P5/6P1/6KP/8 w - -",
    EVAL: "M21",
    MOVES: "b7e7 e8e7 d8b6 g6g5 b6c6 e7e6 c6c7 f7g6 b5b6 e6e2",
    DEPTH: 48,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/8/4PRp1/2N3P1/PPPPB1Kq/R1BQ4 w kq -",
    EVAL: "M-20",
    MOVES: "g2f1 h2g3 e2f3 g3f4 d2d3 h8h1 f1f2 h1h2 f2f1 f4g3",
    DEPTH: 33,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 21,
    MOVES: "g1f3 e7e6 c2c4 d5c4 b1d2 g8f6 d2c4 f8b4 f4d2 b4e7",
    DEPTH: 47,
  },
  {
    FEN: "8/1k6/8/1K6/1P6/8/8/8 w - -",
    EVAL: 0,
    MOVES: "b5a4 b7a6 b4b5 a6b6 a4b4 b6a7 b4c5 a7b7 c5d4 b7b6",
    DEPTH: 93,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/8/4PRp1/2N3q1/PPPPB3/R1BQ1K2 w kq -",
    EVAL: "M-17",
    MOVES: "e2f3 g3f4 d2d4 h8h1 f1e2 f4f3 e2d2 f3f4 d2e2 h1h2",
    DEPTH: 64,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/2P5/4PN2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 40,
    MOVES: "c7c6 d1b3 d8c7 b1c3 e7e6 f3h4 f5g6 h4g6 h7g6 d2d4",
    DEPTH: 36,
  },
  {
    FEN: "8/2k5/8/1K6/1P6/8/8/8 b - -",
    EVAL: 0,
    MOVES: "c7b7 b5a5 b7a7 b4b5 a7b7 b5b6 b7b8 b6b7 b8b7 a5a4",
    DEPTH: 81,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/3P1B2/4P3/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 22,
    MOVES: "e7e6 g1f3 f8d6 f4d6 c7d6 c2c4 g8f6 c4d5 f6d5 b1d2",
    DEPTH: 49,
  },
  {
    FEN: "8/1k6/8/2K5/1P6/8/8/8 b - -",
    EVAL: 0,
    MOVES: "b7c7 b4b5 c7b7 b5b6 b7b8 c5d4 b8b7 d4e5 b7a6 e5f5",
    DEPTH: 87,
  },
  {
    FEN: "7k/pbp1r2p/1p3q2/4nP2/1PP1p2p/P3P3/4B1R1/B3Q2K w - -",
    EVAL: 1344,
    MOVES: "e1d2 e7e8 e2h5 e8g8 h5f7 g8g3 d2d8 h8g7 d8c7 f6f7",
    DEPTH: 32,
  },
  {
    FEN: "8/1k6/8/2K5/8/1P6/8/8 w - -",
    EVAL: "M19",
    MOVES: "c5b5 b7a7 b5c6 a7a8 b3b4 a8b8 c6b6 b8a8 b4b5 a8b8",
    DEPTH: 99,
  },
  {
    FEN: "rn1qkbnr/ppp2ppp/4p3/3p1b2/2P5/4PN2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 58,
    MOVES: "d1b3 b7b6 c4d5 d8d5 f1c4 d5b7 b1c3 g8f6 f3h4 f5e4",
    DEPTH: 38,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbn2/4p3/2B1P3/2N1BN2/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 0,
    MOVES: "c4e6 f7e6 f3g5 d8d7 d1f3 b8c6 f3h3 c6d8 e3b6 h7h6",
    DEPTH: 38,
  },
  {
    FEN: "8/k7/2K5/8/8/1P6/8/8 b - -",
    EVAL: "M17",
    MOVES: "a7a8 c6b6 a8b8 b3b4 b8a8 b6a6 a8b8 b4b5 b8c7 b5b6",
    DEPTH: 99,
  },
  {
    FEN: "k7/8/2K5/8/8/1P6/8/8 w - -",
    EVAL: "M17",
    MOVES: "b3b4 a8b8 c6b6 b8a8 b4b5 a8b8 b6a6 b8c7 b5b6 c7c8",
    DEPTH: 99,
  },
  {
    FEN: "5Bk1/1b1r2p1/4qp1p/2Q5/2P5/6PP/P4P2/4R1K1 w - -",
    EVAL: -987,
    MOVES: "c5b4 e6h3 b4b7 d7b7 f8d6 h3f5 d6f4 b7b1 e1b1 f5b1",
    DEPTH: 43,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/4p3/2pp1b2/3P1B2/4P3/PPPN1PPP/R2QKBNR w KQkq -",
    EVAL: 43,
    MOVES: "f1b5 b8c6 g1f3 f8d6 f4d6 d8d6 c2c4 g8e7 c4d5 d6d5",
    DEPTH: 27,
  },
  {
    FEN: "1k6/8/2K5/8/1P6/8/8/8 w - -",
    EVAL: "M16",
    MOVES: "c6b6 b8a8 b6a6 a8b8 b4b5 b8c7 b5b6 c7c8 a6a7 c8d7",
    DEPTH: 99,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 50,
    MOVES: "d1d2 c7c6 g1f3 e8h8 e3h6 d6d5 e4e5 f6e4 c3e4 d5e4",
    DEPTH: 53,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1B3/PPPQ1PPP/R3KBNR b KQkq -",
    EVAL: 68,
    MOVES: "a7a6 e3h6 g7h6 d2h6 e7e5 d4e5 d6e5 g1f3 d8e7 f1c4",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk1nr/1pppbppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 36,
    MOVES: "e1h1 g8f6 f1e1 b7b5 a4b3 e8h8 d2d3 d7d6 a2a3 h7h6",
    DEPTH: 51,
  },
  {
    FEN: "k7/8/1K6/8/1P6/8/8/8 w - -",
    EVAL: "M15",
    MOVES: "b4b5 a8b8 b6a6 b8c7 b5b6 c7c8 a6a7 c8d7 b6b7 d7e6",
    DEPTH: 122,
  },
  {
    FEN: "r2qkbnr/pp3ppp/2n1p3/2pp1b2/3P1B2/4PN2/PPPN1PPP/R2QKB1R w KQkq -",
    EVAL: 28,
    MOVES: "f1b5 f8d6 f4d6 d8d6 c2c4 g8e7 c4d5 d6d5 d1b3 c5d4",
    DEPTH: 31,
  },
  {
    FEN: "2b2r2/pr3pkn/1p2p3/7q/2pPRB2/2P5/PPQ2PB1/R5K1 w - -",
    EVAL: 550,
    MOVES: "e4e3 e6e5 d4e5 b7e7 e3g3 g7h8 c2d1 h5d1 a1d1 e7d7",
    DEPTH: 38,
  },
  {
    FEN: "rn1qkbnr/p1p2ppp/1p6/3p1b2/8/1Q2PN2/PP1P1PPP/RNB1KB1R w KQkq -",
    EVAL: 108,
    MOVES: "f3d4 g8e7 d4f5 e7f5 d2d4 g7g6 b1c3 c7c6 f1d3 d8d7",
    DEPTH: 27,
  },
  {
    FEN: "8/k1K5/8/8/1P6/8/8/8 w - -",
    EVAL: "M16",
    MOVES: "b4b5 a7a8 c7b6 a8b8 b6a6 b8c7 b5b6 c7c8 a6a7 c8d7",
    DEPTH: 219,
  },
  {
    FEN: "2b2r2/pr3pkn/1p2p3/7q/2pP1B2/2P1R3/PPQ2PB1/R5K1 b - -",
    EVAL: 405,
    MOVES: "e6e5 e3e5 h5g4 c2d2 g7h8 e5e4 g4f5 f4g3 f7f6 e4h4",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 30,
    MOVES: "g1f3 e7e6 e2e3 g8f6 f1c4 c7c5 e1h1 a7a6 d4c5 d8d1",
    DEPTH: 52,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1BN1BN2/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 18,
    MOVES: "b8d7 f3h4 b7b5 h4f5 e8h8 e1h1 e6f5 e4f5 d7c5 c3d5",
    DEPTH: 34,
  },
  {
    FEN: "r3kbnr/pp3ppp/1qn1p3/2pp1b2/3P1B2/2P1PN2/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f3h4 f5e4 f2f3 e4g6 h4g6 h7g6 d1b3 f8e7 f1e2 e7h4",
    DEPTH: 42,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/P1N2P2/1PP3PP/R1BQKB1R b KQkq -",
    EVAL: -38,
    MOVES: "b7b5 c1e3 f8e7 f3f4 b5b4 a3b4 e7b4 d4b5 a6b5 a1a8",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3P4/4p3/2N2P2/PPP3PP/R1BQKBNR b KQkq -",
    EVAL: -10,
    MOVES: "e7e6 c1f4 g8f6 d5e6 d8d1 a1d1 c8e6 f4c7 b8c6 a2a3",
    DEPTH: 41,
  },
  {
    FEN: "8/8/4N1B1/8/8/3K1k2/8/8 b - -",
    EVAL: "M14",
    MOVES: "f3g3 g6h5 g3h4 h5f3 h4g3 d3e3 g3h3 e6g7 h3h4 e3f4",
    DEPTH: 71,
  },
  {
    FEN: "8/3kr3/8/3KQ3/8/8/8/8 w - -",
    EVAL: "M18",
    MOVES: "e5b8 e7f7 b8b7 d7e8 b7c8 e8e7 d5e5 f7g7 c8c7 e7f8",
    DEPTH: 99,
  },
  {
    FEN: "k7/2K5/8/1P6/8/8/8/8 w - -",
    EVAL: "M15",
    MOVES: "c7b6 a8b8 b6a6 b8c7 b5b6 c7c8 a6a7 c8d7 b6b7 d7e6",
    DEPTH: 146,
  },
  {
    FEN: "8/k1K5/8/1P6/8/8/8/8 b - -",
    EVAL: "M15",
    MOVES: "a7a8 c7b6 a8b8 b6a6 b8c7 b5b6 c7c8 a6a7 c8d7 b6b7",
    DEPTH: 99,
  },
  {
    FEN: "r1bq1rk1/1pp2ppp/2np1n2/p1b1p3/2B1P3/2PP1N2/PP3PPP/RNBQR1K1 w - -",
    EVAL: 0,
    MOVES: "h2h3 h7h6 c4b5 c8d7 b1d2 c6e7 b5d7 d8d7 d2f1 a5a4",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 54,
    MOVES: "f8g7 b1c3 d7d5 e4d5 g8f6 f1c4 c7c6 d5c6 b8c6 g1e2",
    DEPTH: 49,
  },
  {
    FEN: "2b2r2/pr4kn/1p2p3/5p1q/2pP1B2/2P1R3/PPQ2PB1/R5K1 w - -",
    EVAL: 853,
    MOVES: "e3h3 h5h3 g2h3 g7g8 c2e2 b7g7 g1f1 f8f7 f4e5 h7f6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 8,
    MOVES: "f8b4 e2e3 e8h8 g1f3 d7d5 f1d3 d5c4 d3c4 c7c5 e1h1",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2ppP3/8/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 22,
    MOVES: "b8c6 d2d4 d8b6 a2a3 c5c4 b1d2 c6a5 a1b1 c8d7 h2h4",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2p5/8/4n3/3PK2P/PPP3P1/RNBQ1B1R w - -",
    EVAL: 307,
    MOVES: "d1f3 e4g5 f3f4 g5e6 f4g3 e6d4 e3d2 f8e8 d2d1 c6c5",
    DEPTH: 35,
  },
  {
    FEN: "k7/8/1K6/1P6/8/8/8/8 b - -",
    EVAL: "M14",
    MOVES: "a8b8 b6a6 b8c7 b5b6 c7c8 a6a7 c8d7 b6b7 d7e6 a7b6",
    DEPTH: 106,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2nn4/1B2p3/P7/5N2/1PPP1PPP/RNBQR1K1 w kq -",
    EVAL: 12,
    MOVES: "b1c3 e8h8 d2d4 c6d4 f3d4 e5d4 c3d5 e7h4 b5d3 b7b6",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/ppp1pp1p/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 53,
    MOVES: "a7a6 c1e3 f8g7 d1d2 b7b5 a2a4 b5b4 c3d5 a6a5 h2h4",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/ppp1pp1p/3p2p1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 56,
    MOVES: "c1e3 g8f6 f2f3 f8g7 g1e2 c7c6 a2a4 e8h8 d1d2 b8d7",
    DEPTH: 48,
  },
  {
    FEN: "1k6/8/1K6/1P6/8/8/8/8 w - -",
    EVAL: "M14",
    MOVES: "b6a6 b8c7 b5b6 c7c8 a6a7 c8d7 b6b7 d7e6 b7b8q e6f5",
    DEPTH: 114,
  },
  {
    FEN: "2b2r2/pr4kn/1p2p3/5p1q/2pP1B2/2P4R/PPQ2PB1/R5K1 b - -",
    EVAL: 709,
    MOVES: "h5g4 h3g3 g4g3 f4g3 b7f7 g3e5 h7f6 f2f3 g7g6 g1f2",
    DEPTH: 29,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p5/8/4n3/3PK2P/PPP3P1/RNBQ1B1R b kq -",
    EVAL: 223,
    MOVES: "e4g3 e3f2 g3e4 f2f3 e8h8 d1e1 d8f6 c1f4 f6b2 b1d2",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2pp4/2PP4/2N1PN2/PP2BPPP/R1BQK2R w KQ -",
    EVAL: -13,
    MOVES: "d4c5 d5c4 d1a4 b8a6 a4c4 c8e6 c4h4 a6c5 e1h1 h7h6",
    DEPTH: 45,
  },
  {
    FEN: "8/2k5/K7/1P6/8/8/8/8 w - -",
    EVAL: "M13",
    MOVES: "b5b6 c7c8 a6a7 c8d7 b6b7 d7e6 b7b8q e6f5 b8g3 f5e4",
    DEPTH: 99,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2nn4/1B2p3/P7/2N2N2/1PPP1PPP/R1BQR1K1 b kq -",
    EVAL: 7,
    MOVES: "a7a6 b5f1 e5e4 c3e4 d6e4 e1e4 d7d5 e4e1 d5d4 f1d3",
    DEPTH: 50,
  },
  {
    FEN: "r2q1bnr/pp3kpp/2Np1p2/1Bp3B1/8/2Pp4/PP3PPP/RN1bR1K1 w - -",
    EVAL: 0,
    MOVES: "c6d8 a8d8 g5f6 g8f6 e1d1 d6d5 b5d3 f8d6 b1d2 c5c4",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2p1P3/3p4/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1a3 b8c6 f1d3 g8e7 e1h1 e7g6 f1e1 f8e7 a3c4 b7b5",
    DEPTH: 56,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2N3p1/8/2B1n3/2q1B3/PPPQ1PPP/R3K2R w KQkq -",
    EVAL: -67,
    MOVES: "d2c3 g7c3 b2c3 d7c6 f2f3 e4d6 c4b3 c8e6 e3d4 e8h8",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2np1n2/2b5/2B1P3/2N2N2/PP3PPP/R1BQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "c1g5 h7h6 g5f6 d8f6 c3d5 f6d8 b2b4 c5b6 a2a4 c6e5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/1pp2ppp/2np1n2/p1b1p3/2B1P3/2PP1N2/PP3PPP/RNBQ1RK1 w kq -",
    EVAL: 14,
    MOVES: "b1d2 e8h8 f1e1 c5b6 h2h3 c8e6 c4b5 c6e7 d3d4 e7g6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 26,
    MOVES: "g1f3 c7c6 c1g5 f8e7 e2e3 e8h8 f1d3 d5c4 d3c4 h7h6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq -",
    EVAL: 10,
    MOVES: "e7e5 g1f3 b8c6 e2e4 f8b4 d2d3 b4c5 f3e5 c6e5 d3d4",
    DEPTH: 55,
  },
  {
    FEN: "8/KP6/8/8/3k4/8/8/8 w - -",
    EVAL: "M10",
    MOVES: "b7b8q d4e3 b8g3 e3e4 a7b6 e4f5 g3h4 f5e5 b6c6 e5f5",
    DEPTH: 99,
  },
  {
    FEN: "rnb1k2r/1pqpbppp/p3pn2/8/3NP3/P1N2P2/1PP3PP/R1BQKB1R w KQkq -",
    EVAL: 1,
    MOVES: "f3f4 e8h8 c1e3 b8c6 f1e2 f6e8 e1h1 b7b5 d4c6 c7c6",
    DEPTH: 35,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2p5/8/4K3/3P3P/PPP3P1/RNBQ1B1R b - -",
    EVAL: 0,
    MOVES: "d8h4 e4f3 h4h5 g2g4 c8g4 h3g4 h5h1 f3f2 f7f5 g4g5",
    DEPTH: 69,
  },
  {
    FEN: "2b1qr2/pr4k1/1p2pn2/4Bp2/2pP4/2P4R/PPQ2PB1/R5K1 w - -",
    EVAL: 826,
    MOVES: "c2d2 e8g6 h3g3 g6g3 e5g3 f8g8 g2b7 c8b7 d2f4 b7e4",
    DEPTH: 30,
  },
  {
    FEN: "8/K1k5/8/1P6/8/8/8/8 b - -",
    EVAL: "M12",
    MOVES: "c7d6 b5b6 d6c5 b6b7 c5d4 a7b6 d4d5 b7b8q d5e4 b6c5",
    DEPTH: 111,
  },
  {
    FEN: "rnbqk1nr/2p1ppbp/p2p2p1/1p6/3PP3/2N1B3/PPPQ1PPP/R3KBNR w KQkq -",
    EVAL: 74,
    MOVES: "e1a1 g8f6 f2f3 b8d7 h2h4 h7h5 g1h3 d7b6 d2e1 c8h3",
    DEPTH: 47,
  },
  {
    FEN: "2b1qr2/pr4k1/1p2pn2/4Bp2/2pP4/2P4R/PP1Q1PB1/R5K1 b - -",
    EVAL: 883,
    MOVES: "e8g6 h3g3 g6g3 f2g3 g7g6 e5f6 f8f6 g3g4 b7e7 d2f4",
    DEPTH: 30,
  },
  {
    FEN: "3K4/3P2k1/8/8/8/8/2r5/5R2 w - -",
    EVAL: "M18",
    MOVES: "f1f4 g7g6 d8e7 c2e2 e7d6 e2b2 d7d8q b2d2 d6e7 d2d8",
    DEPTH: 99,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pp4/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 9,
    MOVES: "e4d5 d8d5 d2d4 g8f6 b1a3 b8c6 c1e3 c5d4 a3b5 d5d7",
    DEPTH: 48,
  },
  {
    FEN: "3r4/1R1P2kp/1p1q2p1/1Pp5/2P5/6P1/5QKP/8 w - -",
    EVAL: 0,
    MOVES: "f2b2 g7f7 h2h4 d6e6 b2f2 f7g7 f2f4 d8d7 b7d7 e6d7",
    DEPTH: 44,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2pP4/3P4/2N1PN2/PP2BPPP/R1BQK2R b KQ -",
    EVAL: 0,
    MOVES: "f6d5 d1b3 d5b4 e1h1 c8e6 b3a4 e6d7 a4b3",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/5n2/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR b KQkq -",
    EVAL: 16,
    MOVES: "b8c6 g1e2 e7e6 d2d4 c5d4 e2d4 f8c5 d4b3 c5b4 f1d3",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk1nr/2p1ppbp/p2p2p1/1p6/3PP2P/2N1B3/PPPQ1PP1/R3KBNR b KQkq -",
    EVAL: 36,
    MOVES: "g8f6 f2f3 h7h5 a2a4 b5b4 c3e2 a6a5 e2f4 c8a6 f1e2",
    DEPTH: 45,
  },
  {
    FEN: "5rk1/1R3R1p/4N1p1/p7/8/1P3nP1/r5KP/8 w - -",
    EVAL: 503,
    MOVES: "g2f1 f3h2 f1e1 h2f3 e1d1 a2a1 d1c2 f3d4 c2b2 d4e6",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b5/4P3/3P4/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 257,
    MOVES: "f1e2 f6g4 e2g4 d8h4 d1f3 h4g4 f3g4 c8g4 b1c3 g4e6",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pp1ppppp/2n2n2/2p5/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e7e5 d2d3 a7a6 f1e2 f8e7 a2a4 e8h8 e1h1 d7d6 f3e1",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/3P4/3p4/2N1PN2/PP2BPPP/R1BQK2R w KQ -",
    EVAL: -20,
    MOVES: "e3d4 f6d5 d1b3 d5b6 d4d5 e7e6 c1g5 d8d7 d5e6 d7e6",
    DEPTH: 34,
  },
  {
    FEN: "2b1qr2/pr3k2/1p2pn2/4BpQ1/2pP4/2P4R/PP3PB1/R5K1 b - -",
    EVAL: 2091,
    MOVES: "e8e7 h3h6 f7e8 e5f6 e7f7 h6g6 b7d7 a1e1 d7d6 f6e5",
    DEPTH: 33,
  },
  {
    FEN: "r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 w - -",
    EVAL: 20,
    MOVES: "h2h3 c8b7 d2d3 d7d5 e4d5 f6d5 f3e5 c6d4 b1c3 d5b4",
    DEPTH: 53,
  },
  {
    FEN: "2b2r2/pr2qk2/1p2pn2/4BpQ1/2pP4/2P4R/PP3PB1/R5K1 w - -",
    EVAL: 6864,
    MOVES: "h3h6 f7e8 e5f6 f8f6 h6f6 e8d8 a1e1 d8c7 g5g3 e7d6",
    DEPTH: 49,
  },
  {
    FEN: "2b2r2/pr2qk2/1p2pB2/5pQ1/2pP4/2P4R/PP3PB1/R5K1 b - -",
    EVAL: 660,
    MOVES: "e7f6 h3h7 f7e8 g5f6 f8f6 g2b7 c8d7 b7f3 d7a4 g1h2",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/3b4/1B1p4/3P4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 6,
    MOVES: "c7c6 b5d3 g8e7 e1h1 e8h8 f1e1 b8a6 c2c3 c8f5 c1g5",
    DEPTH: 32,
  },
  {
    FEN: "2b2r2/pr3k1R/1p2pq2/5pQ1/2pP4/2P5/PP3PB1/R5K1 b - -",
    EVAL: 912,
    MOVES: "f7e8 g5f6 f8f6 g2b7 c8d7 b7f3 e8d8 g1h2 f6g6 a1d1",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 12,
    MOVES: "e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5d3 f6d5 g5f3 f8d6",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 g1f3 e8h8 b2b3 d5c4 b3c4 c7c5 f1d3 b7b6 e1h1",
    DEPTH: 41,
  },
  {
    FEN: "3K4/3P2k1/8/8/8/8/2r5/R7 b - -",
    EVAL: "M22",
    MOVES: "g7f7 a1a8 c2c1 a8c8 c1d1 d8c7 d1c1 c7b6 c1b1 b6c5",
    DEPTH: 46,
  },
  {
    FEN: "r1b2rk1/ppp2ppp/2p2q2/8/4KB2/3P3P/PPP3P1/RN1Q1B1R b - -",
    EVAL: 144,
    MOVES: "g7g5 d1d2 f8e8 e4f3 g5f4 b1c3 e8e3 f3f2 f6d4 c3e2",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/2p5/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R w KQkq -",
    EVAL: 54,
    MOVES: "d2d4 c5d4 f3d4 g7g6 d4c2 f8g7 f1e2 e8h8 c1e3 c8e6",
    DEPTH: 44,
  },
  {
    FEN: "r1b2rk1/ppqn1pbp/5np1/3p4/3BP3/1P1B1P1P/P2N2PN/R2Q1RK1 b - -",
    EVAL: -474,
    MOVES: "f6e4 f3e4 g7d4 g1h1 d4a1 d1a1 d7c5 a1d4 d5e4 d2e4",
    DEPTH: 56,
  },
  {
    FEN: "rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 50,
    MOVES: "b8c6 d4e5 c6e5 f3e5 g7e5 f1e2 g8f6 e1h1 e8h8 c1g5",
    DEPTH: 39,
  },
  {
    FEN: "r1bq1rk1/3np1bp/p2p1pp1/1PpP3n/4PP1B/2N2Q2/PP1N2PP/R3KB1R b KQ -",
    EVAL: -145,
    MOVES: "h5f4 f3f4 g6g5 f4g3 g5h4 g3h4 f6f5 e4f5 d8a5 f1e2",
    DEPTH: 56,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 9,
    MOVES: "g1f3 e8h8 b2b3 c7c5 d4c5 e7c5 c1b2 b8c6 a2a3 d5c4",
    DEPTH: 43,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2N3p1/8/2B1n3/2P1B3/P1PQ1PPP/R3K2R b KQkq -",
    EVAL: -157,
    MOVES: "e4d2 e1d2 d7c6 a2a4 h7h5 c4d3 c8e6 h1b1 b7b6 a4a5",
    DEPTH: 40,
  },
  {
    FEN: "rn1qk2r/pp2nppp/2pb4/3p1b2/3P4/3B1N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 1,
    MOVES: "d3f5 e7f5 c1g5 f7f6 f1e1 e8f7 d1d3 d8d7 g5d2 b8a6",
    DEPTH: 32,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2N3p1/8/2B5/2P1B3/P1Pn1PPP/R3K2R w KQkq -",
    EVAL: -103,
    MOVES: "e1d2 d7c6 a2a4 b7b6 c4d3 e8h8 a1a3 f7f5 d3c4 g8h8",
    DEPTH: 30,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e8h8 b2b3 c7c5 c1b2 b8c6 d4c5 e7c5 c4d5 e6d5 f1e2",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk1nr/ppp2pbp/2np2p1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 47,
    MOVES: "d4e5 c6e5 f3e5 g7e5 f1e2 g8f6 e1h1 e8h8 c1g5 c7c6",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3P4/8/2N2N2/PPP3PP/R1BQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "g8f6 f1c4 e7e5 d1e2 f8e7 c1g5 e8h8 e1a1 b8d7 f3e5",
    DEPTH: 26,
  },
  {
    FEN: "r1b2q1k/ppp3pp/2np1r2/5P2/2B2P2/2Q4P/PB4P1/4RRK1 w - -",
    EVAL: 939,
    MOVES: "e1e8 f8e8 c3f6 c6e5 f6g5 h7h6 g5h4 c8f5 f4e5 d6d5",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 41,
    MOVES: "c7c5 g1e2 c5d4 e2d4 b8c6 c1e3 c6d4 e3d4 g7d4 d1d4",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4N3/3PP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 87,
    MOVES: "d8e7 b1c3 c6e5 d4e5 e7e5 f2f4 e5e6 d1d4 d7d5 c1e3",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/2p5/2P1P3/1PN2N2/P2P1PPP/R1BQKB1R b KQkq -",
    EVAL: -79,
    MOVES: "g7g6 d2d3 f8g7 c1d2 e8h8 f1e2 c8g4 e1h1 f6d7 a1c1",
    DEPTH: 24,
  },
  {
    FEN: "3rqb2/1pR3pk/p3n1pp/3p4/3B4/PP6/1P4PP/4RQK1 w - -",
    EVAL: 798,
    MOVES: "e1e6 e8e6 f1f8 d8f8 c7g7 h7h8 g7g6 h8h7 g6e6 f8f7",
    DEPTH: 32,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQ -",
    EVAL: 7,
    MOVES: "b2b3 c7c5 d4c5 d5c4 f1c4 d8d1 e1d1 e7c5 d1e2 b7b6",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/ppp1qpp1/2np1n1p/2b1p3/2B1P3/2PP1N2/PP1N1PPP/R1BQ1RK1 w kq -",
    EVAL: 48,
    MOVES: "b2b4 c5b6 a2a4 a7a6 f1e1 f6g4 e1e2 g4f6 c4b3 e8h8",
    DEPTH: 26,
  },
  {
    FEN: "rn1qkbnr/p1p2ppp/1p2p3/3p1b2/2P5/1Q2PN2/PP1P1PPP/RNB1KB1R w KQkq -",
    EVAL: 84,
    MOVES: "c4d5 d8d5 f1c4 d5b7 b1c3 g8f6 f3h4 f5e4 c3e4 f6e4",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk1nr/pppnppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 90,
    MOVES: "f1e2 g8f6 g1f3 e8h8 e1h1 e7e5 d1c2 f8e8 c1e3 e5d4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "b5a4 f8c5 g1f3 c5a7 d2d3 d7d6 c1e3 b7b5 a4b3 a7e3",
    DEPTH: 48,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP2BPPP/R1BQK2R b KQ -",
    EVAL: -13,
    MOVES: "c7c5 d4c5 d5c4 e1h1 b8a6 c5c6 d8c7 d1a4 b7c6 a4c4",
    DEPTH: 39,
  },
  {
    FEN: "r1bqk1nr/pppn1pbp/3p2p1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 91,
    MOVES: "c1e3 g8f6 f1e2 e8h8 e1h1 e5d4 f3d4 c7c6 d1c2 a7a6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/8/1bB1P3/2N2N2/PB3PPP/R2QK2R w KQkq -",
    EVAL: -32,
    MOVES: "e1h1 b4c3 b2c3 e8h8 f3g5 h7h6 c4f7 f8f7 g5f7 g8f7",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 32,
    MOVES: "f6e4 f3e5 d7d5 f1d3 b8d7 e5d7 c8d7 e1h1 f8e7 c2c4",
    DEPTH: 54,
  },
  {
    FEN: "2RK4/3P1k2/8/8/8/8/8/r7 w - -",
    EVAL: "M20",
    MOVES: "c8c6 a1a8 d8c7 f7e7 c6a6 a8a6 d7d8q e7f7 d8d7 f7f6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/pppn1pbp/3p2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R b KQkq -",
    EVAL: 94,
    MOVES: "d8e7 e1h1 g8f6 d4e5 d7e5 f3d4 a7a6 c1g5 h7h6 c3d5",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/2n2n2/1p1Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 88,
    MOVES: "c4f1 f6d5 f1b5 c8b7 d2d4 e5d4 e1h1 f8e7 d1h5 g7g6",
    DEPTH: 54,
  },
  {
    FEN: "2R5/2KP1k2/8/8/8/8/8/2r5 w - -",
    EVAL: "M19",
    MOVES: "c7b6 c1b1 b6c5 b1c1 c5b4 c1b1 b4c3 b1d1 d7d8q d1d8",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d5c4 e2e4 f8b4 c1g5 h7h6 g5f6 d8f6 f1c4 c7c5 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 42,
    MOVES: "c5d4 f3d4 b8c6 b1c3 g8f6 d4c6 b7c6 e4e5 f6d5 c3e4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 c4d5 e6d5 c1f4 c7c6 e2e3 c8f5 b1c3 b8d7 a2a3",
    DEPTH: 54,
  },
  {
    FEN: "3rQ3/1R1P1k1p/1p1q2p1/1Pp5/2P5/6P1/6KP/8 b - -",
    EVAL: 2107,
    MOVES: "d8e8 d7d8q d6e7 b7e7 e8e7 d8b6 h7h5 b6c5 g6g5 b5b6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2nbpn2/3p4/2PP4/4PNB1/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 60,
    MOVES: "e8h8 b1c3 b7b6 c4d5 f6d5 c3d5 e6d5 f1d3 g7g6 e1h1",
    DEPTH: 48,
  },
  {
    FEN: "rn2k2r/pp3ppp/4p3/qb2P3/3P4/2R2N2/2Q2PPP/5RK1 w - -",
    EVAL: 530,
    MOVES: "c3c8 e8d7 c8h8 a5c7 c2c7 d7c7 f1c1 c7b6 c1b1 a7a6",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3P4/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e6d5 d2d4 f8d6 f1d3 g8f6 d1e2 d6e7 e1h1 e8h8 f1e1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/pppnnpbp/3p2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQkq -",
    EVAL: 60,
    MOVES: "d4e5 d7e5 f3e5 g7e5 c1g5 h7h6 g5e3 f7f5 d1d2 f5e4",
    DEPTH: 62,
  },
  {
    FEN: "rnR1k2r/pp3ppp/4p3/qb2P3/3P4/5N2/2Q2PPP/5RK1 b - -",
    EVAL: 507,
    MOVES: "e8d7 c8h8 a5c7 c2c7 d7c7 f1c1 c7b6 c1b1 a7a6 f3g5",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d6 f4e5 d6e5 d1h5 d8d4 h5h4 c5e7 h4g3 g8f6 f1b5",
    DEPTH: 50,
  },
  {
    FEN: "3r3k/R4p1p/1Q2bqpP/8/8/3p3N/5PPK/8 w - -",
    EVAL: "M8",
    MOVES: "a7f7 f6e5 f2f4 d8b8 f7b7 b8b7 b6d8 e6g8 f4e5 b7f7",
    DEPTH: 115,
  },
  {
    FEN: "r1bqk2r/pppnnpbp/3p2p1/4p3/2PPP2P/2N2N2/PP2BPP1/R1BQK2R b KQkq -",
    EVAL: 55,
    MOVES: "e5d4 f3d4 d7c5 d4b3 c5e6 c1e3 e8h8 d1d2 f7f5 h4h5",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p2B1/2PP4/8/PP2PPPP/RN1QKBNR b KQkq -",
    EVAL: -92,
    MOVES: "f6e4 g1f3 e4g5 f3g5 d5c4 b1c3 e7e6 g5f3 c7c6 g2g3",
    DEPTH: 46,
  },
  {
    FEN: "1k1r4/pp5p/3bBp2/2p2r2/P6P/2BnP3/1P6/5RKR b - -",
    EVAL: -40,
    MOVES: "f5f1 g1f1 d6e5 c3e5 d3e5 f1e2 d8e8 e6f5 h7h6 b2b3",
    DEPTH: 52,
  },
  {
    FEN: "rnbqk1nr/pppp1ppp/8/2b5/4Pp2/2N5/PPPP2PP/R1BQKBNR w KQkq -",
    EVAL: 51,
    MOVES: "g1f3 b8c6 f1c4 c6d4 b2b4 d4f3 d1f3 c5d4 a1b1 g8f6",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2p5/2b1q3/6p1/3P3P/PPP2PP1/RNBQ1RK1 w kq -",
    EVAL: 589,
    MOVES: "f1e1 e5e1 d1e1 c8e6 e1e5 c5b6 h3g4 e8a8 c1e3 e6g4",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/3p2B1/2PPn3/8/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: -85,
    MOVES: "g1f3 e4g5 f3g5 d5c4 g5f3 c7c6 a2a4 e7e6 g2g3 f8b4",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/8/1bB1P3/2N2N2/PBQ2PPP/R3K2R b KQkq -",
    EVAL: -102,
    MOVES: "e8h8 e1h1 d7d6 c3d5 f6d5 e4d5 c6e5 f3e5 d6e5 b2e5",
    DEPTH: 37,
  },
  {
    FEN: "4r2k/5p2/pp3Pp1/2pbr2Q/3p4/2P5/P5PP/2B3K1 b - -",
    EVAL: "M-9",
    MOVES: "g6h5 c3c4 e5e1 g1f2 e8e2 f2g3 e2g2 g3h4 e1c1 c4d5",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 21,
    MOVES: "b5a4 f8e7 c2c4 g8f6 d2d3 e8h8 e1h1 e7c5 b1d2 d8e7",
    DEPTH: 54,
  },
  {
    FEN: "rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1BN1BN2/PPP2PPP/R2Q1RK1 b - -",
    EVAL: -13,
    MOVES: "d8c8 f3g5 e6c4 b3c4 c8c4 d1d3 c4d3 c2d3 b8d7 g5f3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1b1NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 42,
    MOVES: "c2c3 b4c5 c1e3 c5b6 d4f5 d7d5 f5g7 e8f8 g7h5 d8h4",
    DEPTH: 44,
  },
  {
    FEN: "4r2k/5p2/pp3P2/2pbr2p/3p4/2P5/P5PP/2B3K1 w - -",
    EVAL: "M-7",
    MOVES: "c3c4 d5a8 c1d2 e5e2 d2g5 e2b2 g5d2 b2d2 h2h4 d2g2",
    DEPTH: 64,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 78,
    MOVES: "f2f4 d6e5 f4e5 c8f5 g1f3 e7e6 b1c3 f8e7 c1e3 b8c6",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk1nr/pp1n1pbp/2pp2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQkq -",
    EVAL: 106,
    MOVES: "d4d5 d8e7 c1e3 g8f6 e1h1 e8h8 f3d2 f6e8 f2f3 f7f5",
    DEPTH: 27,
  },
  {
    FEN: "6rk/6pq/8/8/8/7Q/6PP/6K1 w - -",
    EVAL: -5313,
    MOVES: "h3b3 h7h6 g2g3 h6c1 g1g2 g8d8 b3e6 d8d2 g2h3 c1f1",
    DEPTH: 26,
  },
  {
    FEN: "6r1/6pk/8/8/8/8/6PP/6K1 w - -",
    EVAL: "M-20",
    MOVES: "g1f2 g8a8 f2f3 h7g6 f3e4 a8a2 e4f3 g6g5 f3g3 a2a3",
    DEPTH: 49,
  },
  {
    FEN: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R w KQkq -",
    EVAL: 30,
    MOVES: "f1g2 f8b4 c1d2 c7c5 e1h1 e8h8 d2b4 c5b4 d1d3 d7d5",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk1nr/pp1p1ppp/2n1p3/2b5/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 75,
    MOVES: "d4b3 c5b4 d1g4 g8f6 g4g7 h8g8 g7h6 g8g6 h6h4 g6g4",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/5n2/3p4/3P4/2N1PN2/PP3PPP/R1BQKB1R w KQ -",
    EVAL: 0,
    MOVES: "f1d3 f8e8 e1h1 e7d6 c3b5 d6f8 b5c3",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pp1ppp1p/2n2np1/2p5/2P1P3/2N3P1/PP1P1P1P/R1BQKBNR w KQkq -",
    EVAL: 41,
    MOVES: "d2d3 a8b8 f1g2 f8g7 g1e2 d7d6 a2a3 a7a6 a1b1 e8h8",
    DEPTH: 37,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n3p1/q7/2BNn3/2N1B3/PPPQ1PPP/R3K2R w KQkq -",
    EVAL: -75,
    MOVES: "d4c6 a5c3 d2c3 g7c3 b2c3 d7c6 f2f3 e4d6 c4b3 b7b6",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 12,
    MOVES: "b8c6 d2d4 e5d4 f3d4 f8b4 d4c6 b7c6 f1d3 d7d5 e4d5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 24,
    MOVES: "g1f3 c8g4 h2h3 g4f3 d1f3 e7e6 f1e2 b8d7 d2d4 d8b6",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/pp1n1pbp/2pp2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 b kq -",
    EVAL: 109,
    MOVES: "g8f6 d4d5 d8e7 c1g5 h7h6 g5e3 e8h8 d1c2 a7a5 a1d1",
    DEPTH: 24,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/8/4PN2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 4,
    MOVES: "c7c5 f1e2 g8f6 e1h1 b8c6 d2d4 c8f5 c2c4 d5c4 d1a4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk1nr/pp1p1ppp/2n1p3/2b5/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 2,
    MOVES: "d8b6 c3a4 b6a5 c2c3 c5d4 e3d4 c6d4 b2b4 d4f3 g2f3",
    DEPTH: 39,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b1n2p/8/3P3P/PPP1BPP1/RNBQ1RK1 w kq -",
    EVAL: 27,
    MOVES: "d1d2 c8f5 b1c3 d6g6 c3e4 f5e4 d3e4 g6e4 f1e1 e4d4",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/pp1n1pbp/2pp2pn/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w kq -",
    EVAL: 166,
    MOVES: "c4c5 e5d4 c1h6 g7h6 d1d4 e8h8 c5d6 d8b6 a1d1 f8e8",
    DEPTH: 24,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 26,
    MOVES: "f3d4 g8f6 b1c3 b8c6 c1f4 d7d6 d4c6 b7c6 e4e5 f6d5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 27,
    MOVES: "d5e4 c3e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3 b8d7 f1d3",
    DEPTH: 51,
  },
  {
    FEN: "r2q1rk1/1p2bppp/p1npbn2/4p3/4P3/1BN1BN2/PPP2PPP/R2Q1RK1 w - -",
    EVAL: 17,
    MOVES: "d1e2 c6a5 f1d1 d8e8 f3d2 a8c8 e3g5 b7b5 a2a4 a5b3",
    DEPTH: 39,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/5n2/3p4/3P4/2NBPN2/PP3PPP/R1BQK2R b KQ -",
    EVAL: 0,
    MOVES: "f8e8 e1h1 e7d6 c3b5 d6f8 b5c3 f8d6",
    DEPTH: 47,
  },
  {
    FEN: "r1b1k1nr/pp1p1ppp/1qn1p3/2b5/3NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "c3a4 b6a5 c2c3 c5d4 e3d4 c6d4 b2b4 d4f3 g2f3 a5e5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b5/4P3/2N5/PPPPBPPP/R1BQK2R b KQkq -",
    EVAL: 13,
    MOVES: "d8d4 e1h1 f6e4 e2f3 e4g5 d2d3 g5f3 d1f3 d4g4 f1e1",
    DEPTH: 49,
  },
  {
    FEN: "4rr1k/ppqb2p1/3b4/2p2n2/2PpBP1P/PP4P1/2QBN3/R3K2R b KQ -",
    EVAL: -751,
    MOVES: "e8e4 c2e4 d7c6 e4d3 c6h1 g3g4 f5h6 e1a1 c7c6 d1e1",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 34,
    MOVES: "b8c6 b1c3 a7a6 d4c6 b7c6 f1d3 d7d5 e1h1 g8f6 f1e1",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/4N3/5N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 24,
    MOVES: "g8f6 d1e2 f6e4 e2e4 d8a5 b2b4 a5d5 e4e3 c8f5 c2c4",
    DEPTH: 58,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/3p1n2/q1pP4/5B2/2P2P2/PP2P1PP/RN1QKBNR w KQkq -",
    EVAL: 65,
    MOVES: "e2e4 e7e6 d5e6 c8e6 b1a3 d6d5 d1b3 c5c4 b3b5 a5b5",
    DEPTH: 35,
  },
  {
    FEN: "rnbq1rk1/pp2bppp/2p2n2/3p4/3P4/2NBPN2/PP3PPP/R1BQK2R w KQ -",
    EVAL: 21,
    MOVES: "e1h1 b8d7 h2h3 e7d6 d1c2 f8e8 f1e1 h7h6 c1d2 d7f8",
    DEPTH: 34,
  },
  {
    FEN: "rnb1kb1r/pp1ppppp/5n2/q1pP4/5B2/2P2P2/PP2P1PP/RN1QKBNR b KQkq -",
    EVAL: 37,
    MOVES: "a5b6 f4c1 e7e6 e2e4 e6d5 e4d5 d7d6 a2a4 f8e7 g1e2",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 37,
    MOVES: "b1c3 g8f6 d4c6 b7c6 e4e5 f6d5 c3e4 c8b7 f1e2 c6c5",
    DEPTH: 52,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/2n2n2/1B1qp1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 5,
    MOVES: "b5c6 d5c6 e1h1 c8b7 d1f3 e5e4 f1e1 f8e7 f3h3 c6c2",
    DEPTH: 49,
  },
  {
    FEN: "3Qr3/1R3k1p/1p1q2p1/1Pp5/2P5/6P1/6KP/8 b - -",
    EVAL: "M18",
    MOVES: "d6e7 d8b6 e7b7 b6b7 f7f6 b7c6 f6f7 c6c5 e8e2 g2h3",
    DEPTH: 67,
  },
  {
    FEN: "r1b2rk1/ppqn1pbp/6p1/3p4/3BP3/1P1B3P/P2N2PN/R2Q1RK1 b - -",
    EVAL: -459,
    MOVES: "g7d4 g1h1 d4a1 d1a1 d7e5 a1d4 c8e6 e4d5 e6d5 d2e4",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR b KQkq -",
    EVAL: 14,
    MOVES: "c7c5 e4d5 d8d5 g1f3 c5d4 f1c4 d5d7 e1h1 b8c6 d2b3",
    DEPTH: 57,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n3p1/q7/2BNN3/4B3/PPPQ1PPP/R3K2R b KQkq -",
    EVAL: -109,
    MOVES: "a5d2 e3d2 g7d4 e1a1 h7h6 h1e1 e8f8 c4d5 d7d6 f2f3",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p2pp3/2p5/4P3/1P3N2/PBPP1PPP/RN1QKB1R w KQkq -",
    EVAL: 33,
    MOVES: "d2d4 c5d4 f3d4 g8f6 f1d3 f8e7 e1h1 c8d7 c2c4 b8c6",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq -",
    EVAL: 16,
    MOVES: "e7e5 g2g3 g8f6 f1g2 f8c5 d2d3 d7d5 c4d5 f6d5 b1c3",
    DEPTH: 62,
  },
  {
    FEN: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 60,
    MOVES: "e2e3 e7e6 d5e6 f7e6 g1f3 d7d5 b1c3 f8e7 f1e2 e8h8",
    DEPTH: 55,
  },
  {
    FEN: "rnbqk1nr/ppp2p1p/3b4/6p1/4P3/5N2/PPPP2PP/RNBQKB1R b KQkq -",
    EVAL: -190,
    MOVES: "g5g4 d2d4 g4f3 d1f3 b8c6 c2c3 h7h5 b1d2 d8h4 g2g3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1pP3/4n3/2N2Q2/PPPP2PP/R1B1K1NR b KQkq -",
    EVAL: 0,
    MOVES: "e4c3 d2c3 d8h4 g2g3 h4e7 c1f4 c8d7 g1e2 e8a8 e1a1",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 19,
    MOVES: "g1f3 g8f6 f1d3 f8d6 d1e2 c8e6 f3g5 d8e7 g5e6 e7e6",
    DEPTH: 54,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/5b2/8/5NN1/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 59,
    MOVES: "f5g4 c2c3 g4f3 d1f3 d8d5 f1e2 b8d7 e1h1 d5f3 e2f3",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b5/4P1p1/3P3P/PPP2PP1/RNBQ1RK1 w kq -",
    EVAL: -459,
    MOVES: "d3d4 c5d4 e4e5 d6d8 c2c3 g4h3 d1d4 d8d4 c3d4 h3g2",
    DEPTH: 44,
  },
  {
    FEN: "rnb1kb1r/pp1ppppp/1q3n2/2pP4/5B2/2P2P2/PP2P1PP/RN1QKBNR w KQkq -",
    EVAL: 70,
    MOVES: "f4c1 e7e6 e2e4 e6d5 e4d5 d7d6 a2a4 f8e7 g1e2 h7h5",
    DEPTH: 44,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n3p1/8/2BNN3/4B3/PPPq1PPP/R3K2R w KQkq -",
    EVAL: -117,
    MOVES: "e3d2 g7d4 e1a1 h7h6 h1e1 e8f8 c4d5 d7d6 h2h4 c8f5",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "a7a6 c1f4 d7d6 d4c6 b7c6 f1e2 f8e7 e1h1 e6e5 f4g3",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/ppp2pp1/2n4p/3p4/3P4/3B1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 48,
    MOVES: "e1h1 f8d6 b1c3 g8e7 c3b5 e8h8 c2c3 c8f5 f3e1 f8e8",
    DEPTH: 26,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p3b1/8/3P4/5NN1/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "b8d7 h2h4 h7h6 f1d3 g6d3 d1d3 e7e6 c1f4 d8a5 f4d2",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1pP3/8/2n2Q2/PPPP2PP/R1B1K1NR w KQkq -",
    EVAL: 0,
    MOVES: "d2c3 d8h4 g2g3 h4e4 f3e4 d5e4 g1e2 c8d7 b5c6 d7c6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 21,
    MOVES: "c8g4 d2d4 g8f6 h2h3 g4f3 g2f3 e7e6 d1d3 b8d7 h3h4",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p2pp3/8/3pP3/1P3N2/PBP2PPP/RN1QKB1R w KQkq -",
    EVAL: 27,
    MOVES: "f3d4 g8f6 f1d3 b7b5 e1h1 b8d7 c2c4 b5c4 d3c4 d7e5",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkb1r/ppp2pp1/2n2n1p/3p4/3P4/3B1N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 38,
    MOVES: "c1f4 f8d6 f1e1 c8e6 f4d6 d8d6 b1d2 e8h8 c2c3 c6e7",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "c8b7 f1g2 f8b4 c1d2 b4e7 b1c3 c7c6 d2f4 d7d5 c4d5",
    DEPTH: 53,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/2pb1p2/8/3P4/3B1N2/PPP2PPP/R1BQ1RK1 b - -",
    EVAL: 9,
    MOVES: "c8g4 h2h3 g4h5 d3f5 b8a6 c2c3 a6c7 d1d3 h5g6 c1d2",
    DEPTH: 41,
  },
  {
    FEN: "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 40,
    MOVES: "c1e3 a7a6 d1f3 b7b5 d4c6 c7c6 f1d3 b5b4 c3b1 a6a5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/3p1n2/1P2p3/8/5N2/PBPPPPPP/RN1QKB1R w KQkq -",
    EVAL: -20,
    MOVES: "c2c4 e5e4 f3d4 c7c5 b5c6 b8c6 e2e3 e8h8 f1e2 d6d5",
    DEPTH: 25,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPPB1PPP/R2QKBNR b KQkq -",
    EVAL: -13,
    MOVES: "d5e4 d1g4 g8f6 g4g7 h8g8 g7h6 d8d4 g1e2 d4e5 e1a1",
    DEPTH: 47,
  },
  {
    FEN: "r1b2rk1/ppqn1p1p/6p1/3p4/3bP3/1P1B3P/P2N2PN/R2Q1R1K b - -",
    EVAL: -671,
    MOVES: "d4a1 d1a1 d7e5 d3e2 c8e6 h2f3 e5f3 e2f3 d5d4 a1d4",
    DEPTH: 53,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/2n2n2/1B2p1N1/8/2N5/PPPP1PqP/R1BQK2R w KQkq -",
    EVAL: -58,
    MOVES: "d1f3 g2f3 g5f3 c8d7 d2d3 a8b8 h1g1 c6d4 b5d7 f6d7",
    DEPTH: 31,
  },
  {
    FEN: "r1bqk2r/pppp1pp1/2n2n1p/2b1p3/8/2PP1NP1/PP2PPBP/RNBQ1RK1 b kq -",
    EVAL: 12,
    MOVES: "d7d5 b2b4 c5d6 b4b5 c6a5 c3c4 a7a6 b5a6 a8a6 c4d5",
    DEPTH: 24,
  },
  {
    FEN: "r1bqk2r/ppp1bpp1/2n2n1p/3p4/3P4/3B1N2/PPP2PPP/RNBQR1K1 w kq -",
    EVAL: 46,
    MOVES: "c2c3 e8h8 c1f4 f6h5 f4g3 g7g6 b1d2 h5g3 h2g3 g8g7",
    DEPTH: 23,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2N5/PPPB1PPP/R2QKBNR w KQkq -",
    EVAL: -17,
    MOVES: "d1g4 g8f6 g4g7 h8g8 g7h6 d8d4 g1e2 d4e5 e1a1 b8d7",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/4p3/8/1b1PN3/8/PPPB1PPP/R2QKBNR b KQkq -",
    EVAL: -68,
    MOVES: "d8d4 e4c3 g8f6 g1f3 d4d6 f1d3 c8d7 d1e2 d7c6 a2a3",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/8/1bBPP3/2N2N2/PP3PPP/R1BQK2R b KQkq -",
    EVAL: -32,
    MOVES: "f6e4 e1h1 b4c3 d4d5 c6e5 d1e2 e8h8 b2c3 e5c4 e2c4",
    DEPTH: 51,
  },
  {
    FEN: "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq -",
    EVAL: 14,
    MOVES: "a7a6 d4c6 b7c6 d1d3 g8f6 d3g3 c7g3 h2g3 d7d5 g3g4",
    DEPTH: 46,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p1b2/2PP1B2/8/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: 62,
    MOVES: "d1b3 d8b6 b3b6 a7b6 c4d5 c6d5 b1c3 e7e6 e2e3 b8c6",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p2pp3/8/3NP3/1P6/PBP2PPP/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 f1d3 b7b5 e1h1 c8b7 c2c4 b5b4 a2a3 a6a5 a3b4",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR b KQkq -",
    EVAL: -26,
    MOVES: "e7e6 e2e3 d7d5 b2b3 f8e7 d2d4 c7c5 g1f3 g8h6 f1d3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/4pp2/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 98,
    MOVES: "f8b4 c2c3 b4e7 a2a4 d7d6 a4a5 d8d7 b1d2 a7a6 h2h3",
    DEPTH: 59,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 a7a6 d2d4 c5d4 f3d4 g8f6 c1e3 e7e5 d4e2 d8a5",
    DEPTH: 54,
  },
  {
    FEN: "2bq1rk1/pr3ppn/1p2p3/7P/2pP1B1P/2P5/PPQ2PB1/R3R1K1 w - -",
    EVAL: 311,
    MOVES: "g2e4 f7f5 e4b7 c8b7 f2f3 b7f3 e1e6 d8h4 c2h2 h4g4",
    DEPTH: 36,
  },
  {
    FEN: "rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq -",
    EVAL: 66,
    MOVES: "d7d6 e1h1 e8h8 b1c3 c7c6 d1b3 b8a6 c1f4 d8b6 b3c2",
    DEPTH: 40,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n3p1/8/2BN4/4B3/PPPN1PPP/R3K2R b KQkq -",
    EVAL: -197,
    MOVES: "c6d4 e1a1 d7d6 h1e1 d4c6 c4d5 c8e6 d5e6 f7e6 c1b1",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: -23,
    MOVES: "c2c4 c7c5 b1c3 d7d5 c4d5 f6d5 e2e3 b8c6 f1b5 d5b4",
    DEPTH: 49,
  },
  {
    FEN: "1Q3b2/5pk1/2p3p1/1p1bN2p/4n2P/8/r5PK/8 w - -",
    EVAL: "M-14",
    MOVES: "e5f7 g7f7 b8f4 f7g8 f4b8 e4f6 h2g3 g8f7 b8c7 f8e7",
    DEPTH: 57,
  },
  {
    FEN: "r1bqk1nr/pp1nppbp/3p2p1/2p1P3/3P1P2/2P2N2/PP4PP/RNBQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "g8h6 f1d3 e8h8 e1h1 c5d4 c3d4 d6e5 f4e5 d7b6 d3e4",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3p4/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 12,
    MOVES: "f8d6 c2c4 g8f6 c4c5 d6e7 f1e2 e8h8 e1h1 c8f5 b1c3",
    DEPTH: 48,
  },
  {
    FEN: "r3kb1r/p1pb1ppp/2n2n2/1B2p3/8/2N2N2/PPPP1P1P/R1B1K2R w KQkq -",
    EVAL: -13,
    MOVES: "h1g1 e8a8 d2d3 c6d4 b5d7 d8d7 f3d4 e5d4 c3e2 g7g6",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2N2/PP3PPP/R2QKB1R b KQ -",
    EVAL: 13,
    MOVES: "h7h6 g5f4 f6g4 f1e2 b8c6 e1h1 e7e5 d4e5 g4e5 f4e3",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk1nr/ppp2pbp/3p2p1/3Pp3/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 29,
    MOVES: "d8e7 g1f3 b8d7 f1e2 g8f6 d1c2 e8h8 c1g5 h7h6 g5e3",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 46,
    MOVES: "c2c4 b8c6 d2d4 c5d4 f3d4 e7e5 d4f5 d7d5 c4d5 c8f5",
    DEPTH: 53,
  },
  {
    FEN: "5r1k/ppqb2p1/3b4/2p2n2/2P1rP1P/PP1Q2P1/3BN3/R3K2R w KQ -",
    EVAL: -510,
    MOVES: "d3e4 d7c6 e4d3 c6h1 g3g4 f5d4 e2d4 c5d4 e1a1 c7c6",
    DEPTH: 24,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "g2g3 g7g6 g1f3 f8g7 f1g2 g8f6 e1h1 e8h8 b1c3 f8e8",
    DEPTH: 42,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p1b2/2PP1B2/4P3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: 35,
    MOVES: "g8f6 g1f3 d8b6 d1c1 b8d7 b1c3 e7e6 c4c5 b6d8 h2h3",
    DEPTH: 55,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2NP4/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: -6,
    MOVES: "d5d4 c3e2 e7e5 g2g3 f8d6 f1g2 g8e7 f2f4 c6c5 g1f3",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/ppp3bp/3p2p1/3Ppp2/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 56,
    MOVES: "e4f5 c8f5 g1e2 g8f6 e2g3 e8h8 f1e2 b8a6 c1g5 d8e8",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -",
    EVAL: -56,
    MOVES: "f5e4 d2d3 e4d3 f1d3 g8f6 g1f3 b8c6 b1c3 d7d5 e1h1",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/2N5/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 e2e3 c5d4 e3d4 c8g4 f1e2 g4e2 d1e2 a7a6 g1f3",
    DEPTH: 64,
  },
  {
    FEN: "rnbqk1nr/1pp2pbp/3p2p1/p2Pp3/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 61,
    MOVES: "f1d3 b8a6 d3c2 d8h4 g1f3 h4h5 c3e2 c8g4 e2g3 g4f3",
    DEPTH: 39,
  },
  {
    FEN: "3k4/3R4/p2P4/2p1b3/8/2P3rB/P4r2/1K2R3 b - -",
    EVAL: 618,
    MOVES: "d8c8 d7f7 g3h3 f7f2 e5d6 f2f7 h3h8 b1c2 c5c4 e1e6",
    DEPTH: 48,
  },
  {
    FEN: "r1b3nr/pppk2qp/1bnp4/3Pp1BQ/4P3/2P5/PP3PPP/RN3RK1 w - -",
    EVAL: -648,
    MOVES: "a2a4 a7a5 d5c6 b7c6 b1d2 g8f6 g5f6 g7f6 d2c4 c8a6",
    DEPTH: 38,
  },
  {
    FEN: "5r1k/ppqb2p1/3b4/2p2n2/2P1QP1P/PP4P1/3BN3/R3K2R b KQ -",
    EVAL: -548,
    MOVES: "d7c6 e4d3 c6h1 e1a1 c7c6 e2c3 f5d4 d1e1 d4b3 c1b2",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 140,
    MOVES: "e5c6 b7c6 e4e5 f6d5 d2d4 d7d6 f1d3 g7g6 e5d6 f8d6",
    DEPTH: 52,
  },
  {
    FEN: "2k5/3R4/p2P4/2p1b3/8/2P3rB/P4r2/1K2R3 w - -",
    EVAL: 601,
    MOVES: "d7f7 g3h3 f7f2 e5d6 f2f7 d6c7 e1e6 c7d8 e6d6 h3h8",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/2pb4/3p4/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 15,
    MOVES: "f1d3 g8e7 c3e2 e8h8 e1h1 c8f5 c1f4 f5e4 f4d6 d8d6",
    DEPTH: 38,
  },
  {
    FEN: "rnbq1rk1/pp1pbppp/4pn2/8/2PP4/P1N5/1P2NPPP/R1BQKB1R w KQ -",
    EVAL: 27,
    MOVES: "e2f4 d7d5 c4d5 f6d5 c3d5 e6d5 f1d3 b8c6 e1h1 g7g6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -",
    EVAL: 18,
    MOVES: "d2d3 d7d6 e1h1 a7a6 a2a4 c5a7 b2b4 e8h8 f1e1 c6e7",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p4/3P1B2/8/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: 28,
    MOVES: "e2e3 b8c6 b1c3 a7a6 f1d3 c8g4 g1e2 e7e6 e1h1 f8d6",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 150,
    MOVES: "d2d3 c8e6 b1c3 d8e7 f1e2 e8a8 c1e3 g7g6 d1d2 f6g4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/1N1p4/3P1B2/8/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8a6 e2e3 f8e7 g1f3 e8h8 f1e2 c7c6 b5c3 c8d7 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "rn1qk1nr/ppp2pbp/3p2p1/3Pp3/2P1P1b1/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "b8d7 h2h3 g4f3 d1f3 h7h5 f1d3 g7h6 c1h6 g8h6 d3c2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP2PPBP/RNBQK1NR w KQkq -",
    EVAL: 13,
    MOVES: "c1d2 b4e7 g1f3 e8h8 e1h1 d5c4 d1a4 b8d7 a4c4 c7c5",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p2n2/4N3/3pP3/2NP4/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: -174,
    MOVES: "e5f7 e8f7 c3e2 c6c5 h2h3 f8e7 g2g4 f6e8 f1g2 b8c6",
    DEPTH: 42,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: 66,
    MOVES: "e2e3 e7e6 a2a3 c8d7 f1d3 f8e7 a1b1 e8h8 h2h4 d7c6",
    DEPTH: 37,
  },
  {
    FEN: "2b1qr2/pr4kn/1p2p3/5p2/2pP1B2/2P4R/PPQ2PB1/R5K1 w - -",
    EVAL: 789,
    MOVES: "g2b7 c8b7 c2e2 h7f6 f4h6 g7f7 h6f8 e8f8 f2f3 f7e7",
    DEPTH: 33,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP1B2/4P3/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: 36,
    MOVES: "g1f3 f8d6 f4d6 d8d6 d1b3 b7b6 b1c3 g8e7 f1e2 b8d7",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR b KQ -",
    EVAL: 4,
    MOVES: "d7d5 g1f3 d5c4 d3c4 c7c5 e1h1 c5d4 e3d4 b7b6 d1e2",
    DEPTH: 50,
  },
  {
    FEN: "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 18,
    MOVES: "g8f6 d1e2 f8e7 e1h1 e8h8 f2f4 d7d6 d4c6 b7c6 a1e1",
    DEPTH: 43,
  },
  {
    FEN: "6k1/6n1/8/7p/4q1pP/6PN/5rRQ/7K b - -",
    EVAL: "M-13",
    MOVES: "g4h3 h2h3 f2f1 h1h2 e4e1 h3c8 g8h7 c8c2 h7h8 c2c8",
    DEPTH: 82,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP1B2/2N1P3/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 26,
    MOVES: "f8d6 g2g4 f5g6 g1h3 g8e7 f4d6 d8d6 h3f4 b8d7 h2h4",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk2r/ppp3bp/2np1ppn/4p3/2PPP3/2N1BN2/PP3PPP/R2QKB1R w KQkq -",
    EVAL: 74,
    MOVES: "d4e5 d6e5 d1d8 e8d8 a1d1 c8d7 c3d5 h6g4 e3d2 c6e7",
    DEPTH: 34,
  },
  {
    FEN: "6k1/6n1/8/7p/4q1pP/6P1/5rRQ/6NK w - -",
    EVAL: "M-17",
    MOVES: "g1h3 f2f1 h2g1 f1g1 h3g1 g7f5 h1h2 e4e1 g1e2 f5e3",
    DEPTH: 63,
  },
  {
    FEN: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 19,
    MOVES: "g2g3 c8a6 b1d2 d7d5 f1g2 f8e7 e1h1 e8h8 b2b3 c7c5",
    DEPTH: 57,
  },
  {
    FEN: "5r1k/ppqb2p1/3b4/2p2n2/2PprP1P/PP4P1/2QBN3/R3K2R w KQ -",
    EVAL: -755,
    MOVES: "c2e4 d7c6 e4d3 c6h1 g3g4 f5h6 e1a1 c7c6 d1e1 h1e4",
    DEPTH: 49,
  },
  {
    FEN: "rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR b KQ -",
    EVAL: 4,
    MOVES: "b7b6 c1g5 h7h6 g5h4 c8b7 e2e3 d7d5 g1f3 b8d7 c4d5",
    DEPTH: 50,
  },
  {
    FEN: "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq -",
    EVAL: 39,
    MOVES: "d4c6 b7c6 d1d3 g8f6 d3g3 c7g3 h2g3 d7d5 g3g4 f6e4",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/n3pn2/1N1p4/3P1B2/8/PPP1PPPP/R2QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "e2e3 f8e7 g1f3 c7c6 b5c3 d8b6 a1b1 c6c5 a2a3 e8h8",
    DEPTH: 57,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2p5/2b4p/4P1n1/3P3P/PPP1BPP1/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "d8d6 e4e5 g4e5 d1d2 c8f5 b1c3 d6g6 d2f4 c5d6 f4h4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2p1P1B1/3P4/8/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 6,
    MOVES: "h7h6 g5c1 f6d5 c2c4 d5e7 d4c5 e7g6 c1e3 d8a5 d1d2",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/2N5/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 180,
    MOVES: "d8e7 d1e2 c8e6 f2f3 f6d7 e2f2 f7f5 d2d3 f5e4 c3e4",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/3p4/4P3/2NP4/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "e4e5 d5d4 e5f6 d4c3 b2c3 e7f6 d3d4 f8d6 f1d3 e8h8",
    DEPTH: 34,
  },
  {
    FEN: "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R b KQkq -",
    EVAL: 13,
    MOVES: "g8f6 e1h1 f8e7 f2f4 d7d6 d1e1 e8h8 e1g3 c6d4 e3d4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2p2n2/2p1N3/4P3/2N5/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 69,
    MOVES: "f6e4 c3e4 d8d5 d1e2 d5e5 f2f4 e5e7 f4f5 g7g6 f5g6",
    DEPTH: 45,
  },
  {
    FEN: "r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R w KQkq -",
    EVAL: 32,
    MOVES: "e1h1 f8e7 f2f4 d7d6 d1e1 e8h8 e1g3 c6d4 e3d4 b7b5",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/n3pn2/1N1p4/3P1B2/4P3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 g1f3 e8h8 f1e2 c7c6 b5c3 c6c5 f3e5 f6d7 e5f3",
    DEPTH: 53,
  },
  {
    FEN: "r1k4r/ppp1bq1p/2n1N3/6B1/3p2Q1/8/PPP2PPP/R5K1 w - -",
    EVAL: "M6",
    MOVES: "e6c5 f7e6 g4e6 c8b8 c5d7 b8c8 d7b6 c8b8 e6c8 h8c8",
    DEPTH: 245,
  },
  {
    FEN: "5r1k/ppqb2p1/3b4/2p2n2/2PpQP1P/PP4P1/3BN3/R3K2R b KQ -",
    EVAL: -706,
    MOVES: "d7c6 e4d3 c6h1 g3g4 f5h6 e1a1 c7c6 d1e1 f8e8 c1b2",
    DEPTH: 47,
  },
  {
    FEN: "r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1 b kq -",
    EVAL: 13,
    MOVES: "f8e7 f2f4 d7d6 d1e1 c6d4 e3d4 e8h8 e1g3 b7b5 g1h1",
    DEPTH: 39,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/4pn2/2pP4/1b1P4/2N2NP1/PP2PPBP/R1BQK2R b KQ -",
    EVAL: 22,
    MOVES: "c5d4 d1d4 f6d5 e1h1 b8c6 d4c4 d5c3 b2c3 b4e7 c1f4",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 23,
    MOVES: "g8f6 g1f3 d7d5 b1c3 f8b4 c4d5 e6d5 c1g5 b8d7 e2e3",
    DEPTH: 52,
  },
  {
    FEN: "rnbq1rk1/pp1p1ppp/4pn2/8/2Pp4/P1Q1P3/1P3PPP/R1B1KBNR w KQ -",
    EVAL: -32,
    MOVES: "e3d4 d7d5 g1f3 d5c4 f1c4 b8c6 e1h1 b7b6 c4e6 c8e6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/1B2pp2/8/1P2P3/PBPP1PPP/RN1QK1NR b KQkq -",
    EVAL: 24,
    MOVES: "d7d6 d2d4 c8d7 d4e5 c6e5 b5d7 d8d7 g1f3 e5f3 d1f3",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
    EVAL: 24,
    MOVES: "c2c4 e7e6 b1c3 f8b4 d1a4 b8c6 e2e3 e8h8 a4c2 f8e8",
    DEPTH: 50,
  },
  {
    FEN: "r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1 w kq -",
    EVAL: 37,
    MOVES: "f2f4 d7d6 d1e1 e8h8 e1g3 c6d4 e3d4 b7b5 a2a3 c8b7",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk2r/pp3ppp/n1p1pn2/3p4/1b1P1B2/2N1P3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 20,
    MOVES: "f1a6 b7a6 a2a3 b4e7 c3a4 d8a5 c2c3 a5b5 g1e2 a6a5",
    DEPTH: 49,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/3p1b2/3P1B2/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 27,
    MOVES: "e7e6 c2c4 f8d6 f4d6 c7d6 d1b3 d5c4 f1c4 d8b6 b3b6",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/n1p1pn2/3p4/3P1B2/2N1P3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: -5,
    MOVES: "d8b6 a1b1 c6c5 g1f3 f8e7 f3e5 e8h8 f1e2 c8d7 e5d7",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/3P4/PPP2PPP/RNBQK1NR w KQkq -",
    EVAL: 28,
    MOVES: "g1f3 e8h8 e1h1 d7d6 h2h3 a7a6 a2a4 c8e6 c4e6 f7e6",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/2p3B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: -3,
    MOVES: "h7h6 g5f6 d8f6 a1c1 c5d4 f3d4 e8h8 e2e3 b8c6 a2a3",
    DEPTH: 50,
  },
  {
    FEN: "5r1k/ppq3p1/2bb4/2p2n2/2PpQP1P/PP4P1/3BN3/R3K2R w KQ -",
    EVAL: -744,
    MOVES: "e4d3 c6h1 g3g4 f5h6 e1a1 c7c6 d1e1 f8e8 c1b2 h1e4",
    DEPTH: 42,
  },
  {
    FEN: "rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R w KQkq -",
    EVAL: 52,
    MOVES: "c1d2 c7c5 d2b4 c5b4 e1h1 e8h8 d1d3 d7d5 c4d5 e6d5",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/1n2pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1 w kq -",
    EVAL: 89,
    MOVES: "b1d2 c7c5 d2c4 b6c4 d1a4 c8d7 a4c4 b7b5 c4c3 f6d5",
    DEPTH: 44,
  },
  {
    FEN: "r1b1k2r/1pqpbppp/p1n1pn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1 b kq -",
    EVAL: 25,
    MOVES: "d7d6 d1e1 e8h8 g1h1 c6d4 e3d4 b7b5 e1g3 c8b7 a2a3",
    DEPTH: 42,
  },
  {
    FEN: "r1b1k2r/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1 w kq -",
    EVAL: 26,
    MOVES: "g1h1 e8h8 d1e1 c6d4 e3d4 b7b5 e1g3 c8b7 a2a3 a8c8",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/pp1p1ppp/2n1pn2/2p3B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 62,
    MOVES: "d4d5 c6e7 g5f6 g7f6 a1c1 d7d6 a2a3 b4c3 c1c3 e6e5",
    DEPTH: 31,
  },
  {
    FEN: "r1b1k2r/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1 b kq -",
    EVAL: 17,
    MOVES: "e8h8 g1h1 c6d4 e3d4 b7b5 e1g3 c8b7 a2a3 a8d8 a1e1",
    DEPTH: 46,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 13,
    MOVES: "b6b2 f1b5 c8d7 b5d7 b8d7 g1e2 b2a3 a1b1 b7b6 f2f3",
    DEPTH: 40,
  },
  {
    FEN: "r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1 w - -",
    EVAL: 40,
    MOVES: "a2a3 c6d4 e3d4 b7b5 e1g3 c8b7 g1h1 f8d8 e2f3 b7c6",
    DEPTH: 31,
  },
  {
    FEN: "8/3qkb2/8/8/4KB2/5Q2/8/8 b - -",
    EVAL: "M-12",
    MOVES: "f7d5 e4e3 d5f3 e3f3 d7d3 f3g4 e7e6 f4h6 d3f5 g4g3",
    DEPTH: 99,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 23,
    MOVES: "b1c3 h7h6 g5h4 d5c4 e2e3 c7c5 d4c5 d8d1 a1d1 e7c5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f3d4 g8f6 b1c3 a7a6 f1d3 e7e5 d4e2 b8c6 e1h1 c8e6",
    DEPTH: 57,
  },
  {
    FEN: "r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B1Q1/PPP1B1PP/R4RK1 b - -",
    EVAL: 0,
    MOVES: "c6d4 e3d4 b7b5 a2a3 c8b7 g1h1 a8d8 e2f3 b7c6 a1e1",
    DEPTH: 49,
  },
  {
    FEN: "r1b2rk1/1pq1bppp/p2ppn2/8/3nPP2/2N1B1Q1/PPP1B1PP/R4RK1 w - -",
    EVAL: 33,
    MOVES: "e3d4 b7b5 a2a3 c8b7 a1d1 a8c8 g1h1 f8d8 e2f3 c7b8",
    DEPTH: 28,
  },
  {
    FEN: "rnbq1rk1/pppp2pp/4pn2/5p2/1bPP4/2NBP3/PP1B1PPP/R2QK1NR b KQ -",
    EVAL: 35,
    MOVES: "d7d6 d1c2 c7c5 a2a3 b4a5 g1f3 b8c6 e1h1 c5d4 f3d4",
    DEPTH: 27,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/5nn1/4P3/1b1P4/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 51,
    MOVES: "e5f6 d8f6 d1e2 e8d8 e2c4 h8e8 c1e3 b4c3 c4c3 g6f4",
    DEPTH: 35,
  },
  {
    FEN: "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQ -",
    EVAL: 30,
    MOVES: "d1c2 h7h6 g5h4 c7c5 d4c5 d7c5 c4d5 e6d5 f1d3 c8g4",
    DEPTH: 45,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/1qn2n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR w KQkq -",
    EVAL: 80,
    MOVES: "f1d3 a7a6 g1f3 b6b2 a1c1 e7e5 f4e5 f8b4 d1d2 b2d2",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/p4ppp/p1p1pn2/3p4/1b1P1B2/2N1P3/PPP2PPP/R2QK1NR w KQkq -",
    EVAL: 13,
    MOVES: "a2a3 b4e7 c3a4 d8a5 c2c3 a5b5 b2b4 a6a5 a4c5 a5b4",
    DEPTH: 44,
  },
  {
    FEN: "1Q6/5pk1/2p3p1/1p2N2p/1b5P/1bn5/r5P1/2K5 b - -",
    EVAL: "M-1",
    MOVES: "b4a3",
    DEPTH: 245,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/5Q2/PPPP1PPP/RNB1KBNR w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 d5d4 f1c4 e7e6 c3e2 c6c5 d2d3 b8c6 a2a3 g8f6",
    DEPTH: 43,
  },
  {
    FEN: "r1b2rk1/2q1bppp/p2ppn2/1p6/3BPP2/2N3Q1/PPP1B1PP/R4RK1 w - -",
    EVAL: 13,
    MOVES: "a1d1 c8b7 a2a3 a8d8 e2f3 g8h8 d1d2 h7h6 f1d1 f8e8",
    DEPTH: 37,
  },
  {
    FEN: "r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R w KQ -",
    EVAL: 0,
    MOVES: "d4b3 d7d5 c3d5 f6d5 e4d5 c6b4 d1d2 b4d5 e1a1 c8e6",
    DEPTH: 52,
  },
  {
    FEN: "r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R w K -",
    EVAL: 18,
    MOVES: "d1c2 h7h6 g5h4 f6e4 h4e7 d8e7 f1d3 e4c3 c2c3 d5c4",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 a7a6 c4c5 b8d7",
    DEPTH: 53,
  },
  {
    FEN: "2k5/5R2/p2P4/2p1b3/8/2P4r/P4r2/1K2R3 w - -",
    EVAL: 690,
    MOVES: "f7f2 e5d6 f2f7 d6c7 e1e7 c7a5 e7e6 a5d8 e6a6 c8b8",
    DEPTH: 52,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/4p3/2B1P3/3PP3/PPP3PP/RN1QK1NR b KQkq -",
    EVAL: -14,
    MOVES: "c7c6 c4b3 d7d6 g1f3 b8d7 c2c4 a7a6 b1c3 b7b5 e1h1",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/4P3/2B5/2N2N2/PP3PPP/R1BQK2R b KQkq -",
    EVAL: 45,
    MOVES: "d7d5 e5d6 f8d6 e1h1 a7a6 c3e4 d6e7 d1d8 e8d8 c1f4",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 32,
    MOVES: "g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 d1c2 h7h6 b2b3 f8b4",
    DEPTH: 58,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/2n2n2/1p1Pp1N1/8/8/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 93,
    MOVES: "c6d4 c2c3 f6d5 c3d4 d8g5 f1b5 e8d8 e1h1 c8b7 d1f3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/5Q2/PPP2PPP/RNB1KBNR w KQkq -",
    EVAL: 2,
    MOVES: "f3e4 g8f6 e4d3 g7g6 g1f3 f8g7 f1e2 e8h8 e1h1 d8c7",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -27,
    MOVES: "b8c6 f1b5 c8d7 e5d6 e7e6 d2d4 f8d6 d4c5 d6c5 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rn1q1rk1/p1pp1ppp/1p2p3/6N1/2PPQ3/6P1/PP2PP1P/R3K2R b KQ -",
    EVAL: 45,
    MOVES: "d8g5 e4a8 b8c6 a8b7 c6d4 a1d1 c7c5 e2e3 d4c2 e1d2",
    DEPTH: 41,
  },
  {
    FEN: "8/8/4np2/4pk1p/RNr4P/P3KP2/1P6/8 w - -",
    EVAL: 1053,
    MOVES: "b4d5 c4c7 d5c7 e6c7 a4c4 c7d5 e3f2 f5e6 a3a4 e6d6",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3PQ3/8/PPP2PPP/RNB1KBNR b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 e4d3 g7g6 g1f3 f8g7 f1e2 d8c7 e1h1 e8h8 c2c4",
    DEPTH: 31,
  },
  {
    FEN: "5r2/5pkp/5N2/8/8/8/1B6/7K w - -",
    EVAL: 3,
    MOVES: "f6d7 g7g8 d7f8 g8f8 h1g2 f8e8 g2g3 e8d7 g3h4 d7d6",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -",
    EVAL: -42,
    MOVES: "h2h4 g5g4 f3e5 d7d6 e5g4 f8e7 d2d4 e7h4 g4f2 h4g3",
    DEPTH: 48,
  },
  {
    FEN: "2Q5/1p4q1/p4k2/6p1/P3b3/6BP/5PP1/6K1 w - -",
    EVAL: 6333,
    MOVES: "g3e5 f6e5 c8c3 e5f5 c3g7 e4c6 g7f7 f5e5 f7e7 e5f5",
    DEPTH: 51,
  },
  {
    FEN: "r1b2rk1/2q1bppp/p2ppn2/1p6/3BPP2/P1N3Q1/1PP1B1PP/R4RK1 b - -",
    EVAL: 29,
    MOVES: "c8b7 a1d1 b7c6 e2f3 f8d8 h2h3 f6e8 g3f2 c7b7 g1h2",
    DEPTH: 27,
  },
  {
    FEN: "rn3rk1/p1pp1ppp/1p2p3/6q1/2PPQ3/6P1/PP2PP1P/R3K2R w KQ -",
    EVAL: 36,
    MOVES: "e4a8 b8c6 a8b7 c6d4 a1d1 c7c5 e2e3 d4c2 e1d2 g5f5",
    DEPTH: 34,
  },
  {
    FEN: "r4rk1/1bq1bppp/p2ppn2/1p6/3BPP2/P1N3Q1/1PP1B1PP/R4RK1 w - -",
    EVAL: 25,
    MOVES: "g1h1 a8d8 a1d1 d8d7 e2f3 f8c8 d1d3 b7c6 f1d1 d7d8",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R b KQkq -",
    EVAL: -87,
    MOVES: "g5g4 e1h1 g4f3 d1f3 d8f6 d2d3 b8c6 c1f4 d7d6 b1c3",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 23,
    MOVES: "g8f6 e2e3 c8f5 b1c3 e7e6 f3h4 f5g6 h4g6 h7g6 c1d2",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pppp1p1p/8/8/2B1Ppp1/5N2/PPPP2PP/RNBQK2R w KQkq -",
    EVAL: -98,
    MOVES: "e1h1 g4f3 d1f3 d8f6 d2d3 b8c6 c1f4 d7d6 b1c3 f6g7",
    DEPTH: 46,
  },
  {
    FEN: "Qn3rk1/p1pp1ppp/1p2p3/6q1/2PP4/6P1/PP2PP1P/R3K2R b KQ -",
    EVAL: 13,
    MOVES: "b8c6 a8b7 c6d4 a1d1 c7c5 d1d2 d7d5 c4d5 e6d5 b7a7",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/8/3PQ3/8/PPP2PPP/RNB1KBNR w KQkq -",
    EVAL: 0,
    MOVES: "e4d3 g7g6 g1f3 f8g7 f1e2 e8h8 e1h1 d8c7 f1e1 f8d8",
    DEPTH: 31,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/RNBQKB1R b KQ -",
    EVAL: -25,
    MOVES: "b8c6 d4d5 c6e5 f1e2 e7e6 f3f4 e5d7 d5e6 f7e6 c1e3",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 87,
    MOVES: "c4d5 c6d5 d1b3 b8c6 e2e4 d5e4 f3e5 c6e5 d4e5 g7g6",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p4/3P1B2/4P3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: 23,
    MOVES: "b8c6 f1d3 c8g4 g1f3 e7e6 e1h1 f8d6 f4d6 d8d6 b1d2",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pppp1p1p/8/8/2B1Pp2/5p2/PPPP2PP/RNBQ1RK1 w kq -",
    EVAL: -113,
    MOVES: "d1f3 d8f6 d2d3 b8c6 c1f4 d7d6 b1c3 f6g7 c3d5 e8d8",
    DEPTH: 41,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p1b2/3P4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 84,
    MOVES: "d1b3 b8c6 e2e4 d5e4 f3e5 c6e5 d4e5 g7g6 f1b5 f5d7",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/8/3P1Q2/8/PPP2PPP/RNB1KBNR b KQkq -",
    EVAL: -17,
    MOVES: "c8g4 h2h3 g4h5 f1d3 e7e6 b1c3 f8b4 a2a3 b4c3 b2c3",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/3P4/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 145,
    MOVES: "c8e6 b1c3 g7g6 f1e2 d8e7 c1e3 e8a8 d1d2 f6g4 e2g4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/2ppP3/8/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 60,
    MOVES: "d2d4 c5d4 f3d4 b8c6 d4c6 b7c6 f1d3 e7e6 e1h1 g8e7",
    DEPTH: 41,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p1b2/3P1B2/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: 33,
    MOVES: "b8c6 b1c3 e7e6 d1b3 f8b4 e2e3 g8f6 f1b5 b4c3 b3c3",
    DEPTH: 26,
  },
  {
    FEN: "rnb1kbnr/pppp1p1p/8/8/2B1Pp1q/5Q2/PPPP2PP/RNB2RK1 w kq -",
    EVAL: 0,
    MOVES: "d2d4 f8h6 g2g3 h4f6 e4e5 f6c6 f3b3 c6b6 c4f7 e8d8",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/5n2/3Pn3/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: -60,
    MOVES: "f2f4 e5g6 e4e5 f8b4 e5f6 d8f6 c1e3 b4c3 b2c3 f6c3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b3B1/4P3/3P4/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: -448,
    MOVES: "f6e4 g5e3 c5e3 f2e3 d8h4 g2g3 e4g3 h2g3 h4h1 b1d2",
    DEPTH: 51,
  },
  {
    FEN: "rnb1kbnr/pppp1p1p/8/8/2BPPp1q/5Q2/PPP3PP/RNB2RK1 b kq -",
    EVAL: 55,
    MOVES: "f8h6 b1c3 e8d8 c3d5 d7d6 d5f4 b8c6 f3d3 f7f6 b2b4",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/5n2/1p1Pp1N1/3n4/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 133,
    MOVES: "c2c3 f6d5 c3d4 d8g5 f1b5 e8d8 e1h1 c8b7 d1f3 a8b8",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/4P3/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: 13,
    MOVES: "b1c3 c8f5 d1b3 c6a5 f1b5 f5d7 b3c2 a8c8 b5d3 d7g4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/7n/2b1n1NQ/2BpP3/8/PPP2PPP/RNB1K2R w KQkq -",
    EVAL: 375,
    MOVES: "g5e6 c5b4 c2c3 d7e6 h5e5 d8h4 e5g7 h4e4 c4e2 h8g8",
    DEPTH: 34,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQR1K1 b - -",
    EVAL: 12,
    MOVES: "a7a5 h2h3 h7h6 c4b5 c5a7 b1d2 c6e7 d3d4 e7g6 d2f1",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2P2/PP4PP/RNBQKB1R b KQ -",
    EVAL: 64,
    MOVES: "a7a6 c1e3 e7e6 a2a4 e6d5 c4d5 b8d7 d1d2 f6e8 f1e2",
    DEPTH: 38,
  },
  {
    FEN: "5rk1/pQpp1ppp/1p2p3/6q1/2Pn4/6P1/PP2PP1P/R3K2R w KQ -",
    EVAL: 25,
    MOVES: "a1d1 c7c5 e2e3 d4c2 e1d2 g5f5 b7g2 c2b4 e3e4 f5g5",
    DEPTH: 31,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/4N2n/2b1n2Q/2BpP3/8/PPP2PPP/RNB1K2R b KQkq -",
    EVAL: 282,
    MOVES: "d7e6 h5e5 c5b4 c2c3 d8h4 c3b4 h4f2 e1f2 h6g4 f2g3",
    DEPTH: 29,
  },
  {
    FEN: "5rk1/pQpp1ppp/1p2p3/6q1/2Pn4/6P1/PP2PP1P/3RK2R b K -",
    EVAL: 13,
    MOVES: "c7c5 d1d2 d7d5 b7a7 g5e5 e1h1 d4e2 g1g2 e2d4 a7b6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/8/1p1np1N1/3n4/2P5/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 62,
    MOVES: "c3d4 d8g5 f1b5 e8d8 e1h1 c8b7 d1f3 a8b8 d4e5 d5e3",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: -19,
    MOVES: "h7h6 g5f6 e7f6 e2e3 c7c6 f1d3 g7g6 h2h3 b8d7 g1f3",
    DEPTH: 52,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/4p2n/2b1Q3/2BpP3/8/PPP2PPP/RNB1K2R b KQkq -",
    EVAL: 280,
    MOVES: "c5b4 c2c3 d8h4 c3b4 h4f2 e1f2 h6g4 f2g3 g4e5 c4e2",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -",
    EVAL: 12,
    MOVES: "e7e6 c2c4 d7d5 b1c3 c7c6 e2e3 b8d7 f1e2 b7b6 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 23,
    MOVES: "e4d5 d8d5 d2d4 g8f6 g1f3 b8c6 d4c5 d5d1 e1d1 c8f5",
    DEPTH: 48,
  },
  {
    FEN: "2Q5/1p4q1/p4k2/4B1p1/P3b3/7P/5PP1/6K1 b - -",
    EVAL: 838,
    MOVES: "f6e5 c8c3 e5d6 c3g7 e4c6 a4a5 g5g4 g7g4 d6c7 g4g3",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 3,
    MOVES: "g8f6 b1c3 f8b4 d4c6 b7c6 f1d3 d7d5 e4d5 c6d5 e1h1",
    DEPTH: 56,
  },
  {
    FEN: "r1bqkbnr/pp1ppp1p/2N3p1/8/4P3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b7c6 f1c4 d7d6 e1h1 f8g7 b1c3 g8f6 e4e5 d6e5 d1d8",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 24,
    MOVES: "b1c3 e7e6 c1e3 c8d7 c4b3 c6d4 d1d4 f8e7 g2g4 e8h8",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR b KQkq -",
    EVAL: 17,
    MOVES: "d8d5 d2d4 g8f6 g1f3 e7e6 b1a3 b8c6 c1e3 c5d4 a3b5",
    DEPTH: 48,
  },
  {
    FEN: "5rk1/pQ1p1ppp/1p2p3/2p3q1/2Pn4/6P1/PP2PP1P/3RK2R w K -",
    EVAL: 28,
    MOVES: "b7e4 d7d5 e4d3 g5h5 c4d5 e6d5 h2h3 f8d8 e1f1 d4c6",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 18,
    MOVES: "c8g4 f2f3 g4d7 g2g4 e7e6 f1d3 f8e7 h2h4 a8c8 a1c1",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p2Q/2B1P3/8/PPPP1PPP/RNB1K1NR b KQkq -",
    EVAL: -716,
    MOVES: "f6h5 g1f3 d7d6 b1c3 g7g6 d2d4 e5d4 f3d4 f8g7 c1e3",
    DEPTH: 32,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2N5/2b5/4P3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "d8f6 d1f3 b7c6 f1d3 f6f3 g2f3 d7d6 b1c3 a7a5 c3a4",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 f8c5 g1f3 d7d6 d2d4 e5d4 f3d4 a7a5 e1h1 b8d7",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 c3d4 c8g4 f1e2 e7e6 e1h1 g8e7 b1d2 a7a5 h2h3",
    DEPTH: 70,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/4p3/3p1b2/3P1B2/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 15,
    MOVES: "d1b3 d8b6 b3b6 a7b6 a2a3 b8c6 b1c3 f8b4 a1c1 b4c3",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 b1c3 f8c5 g1f3 d7d6 e1h1 a7a5 d2d4 e5d4 f3d4",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp1p2pp/2p5/4p3/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 26,
    MOVES: "g8f6 a2a3 f8e7 g1f3 d7d6 f1e2 e8h8 e1h1 c6c5 b2b4",
    DEPTH: 22,
  },
  {
    FEN: "r1bqkb1r/1p2pppp/p1n2n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR w KQkq -",
    EVAL: 25,
    MOVES: "f1e2 c8f5 g1f3 e7e6 d1b3 f6h5 f4g5 d8c7 f3e5 h7h6",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n4n/2b3NQ/2BpP3/8/PPP2PPP/RNB1K2R b KQkq -",
    EVAL: -115,
    MOVES: "d8e7 e1h1 c6e5 g5e6 d7e6 h5e5 f7f6 e5g3 h6f7 c2c3",
    DEPTH: 41,
  },
  {
    FEN: "8/1p4q1/p7/4k1p1/P3b3/2Q4P/5PP1/6K1 b - -",
    EVAL: 844,
    MOVES: "e5e6 c3g7 e4d5 g7g6 e6d7 g6g5 d5c6 g5g4 d7d6 g4b4",
    DEPTH: 38,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1bB1/3P4/2N2P2/PPP1P1PP/R2QKBNR w KQkq -",
    EVAL: 463,
    MOVES: "e2e4 f5g6 e4e5 a7a6 e5f6 g7f6 g5e3 c7c5 d4c5 b8c6",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/4p3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 38,
    MOVES: "c3e4 g8f6 d1e2 f6e4 e2e4 d8a5 b2b4 a5d5 e4e3 c8f5",
    DEPTH: 50,
  },
  {
    FEN: "rn1q1rk1/1p2bppp/p2pbn2/4p3/2B1P3/2N1BN2/PPP1QPPP/R4RK1 b - -",
    EVAL: 0,
    MOVES: "d8c7 c4b3 b7b5 a2a3 b8c6 e3g5 f6d7 g5e3",
    DEPTH: 52,
  },
  {
    FEN: "5rk1/pQ1p1ppp/1p2p3/2p3q1/2Pn4/4P1P1/PP3P1P/3RK2R b K -",
    EVAL: 19,
    MOVES: "d4c2 e1d2 g5f5 b7g2 c2b4 e3e4 f5g5 f2f4 g5d8 a2a3",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR b KQkq -",
    EVAL: -44,
    MOVES: "b8c6 f1c4 g7g6 h5f3 g8f6 g1e2 d7d6 b1c3 c6b4 c4b3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p5/2b5/4P1n1/3P4/PPP1BPPP/RNBQK2R w KQkq -",
    EVAL: 148,
    MOVES: "e2g4 d8h4 e1h1 c8g4 d1e1 e8a8 c1e3 c5d6 f2f4 h4e1",
    DEPTH: 43,
  },
  {
    FEN: "3r1rk1/1bq1bppp/p2ppn2/1p6/3BPP2/P1N3Q1/1PP1B1PP/4RR1K b - -",
    EVAL: 5,
    MOVES: "f6e8 g3f2 d8d7 e2f3 h7h6 d4b6 c7c8 b6e3 c8d8 e1d1",
    DEPTH: 35,
  },
  {
    FEN: "5rk1/pQ1p1ppp/1p2p3/2p3q1/2P5/4P1P1/PPn2P1P/3RK2R w K -",
    EVAL: 36,
    MOVES: "e1d2 g5f5 b7g2 c2b4 e3e4 f5g5 f2f4 g5d8 d2e3 b4c6",
    DEPTH: 33,
  },
  {
    FEN: "5rk1/1bqrbppp/p2ppn2/1p6/3BPP2/P1N3Q1/1PP1B1PP/4RR1K w - -",
    EVAL: 51,
    MOVES: "e2d3 d7d8 e4e5 d6e5 d4e5 c7b6 g3h3 h7h6 e1e3 f6h5",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pB2/8/3PP3/2N5/PPP3PP/R2bKBNR w KQkq -",
    EVAL: 708,
    MOVES: "f6d8 d1c2 d8c7 f8b4 f1b5 b8c6 b5c6 b7c6 e4e5 c6c5",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 47,
    MOVES: "c1g5 e7e6 d1d2 f8e7 e1a1 c6d4 d2d4 e8h8 h2h4 c8d7",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/1p2pppp/p1n2n2/3p4/3P1B2/2N1P3/PP3PPP/2RQKBNR b Kkq -",
    EVAL: 20,
    MOVES: "c8f5 g1f3 e7e6 d1b3 f6h5 f4e5 c6a5 b3a4 a5c6 f1e2",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 31,
    MOVES: "e4e5 c6c5 g1f3 c5d4 f3d4 b8c6 d4c6 b7c6 f1d3 e7e6",
    DEPTH: 54,
  },
  {
    FEN: "5rk1/1bqrbppp/p2ppn2/1p6/3BPP2/P1NB2Q1/1PP3PP/4RR1K b - -",
    EVAL: 0,
    MOVES: "e7d8 d4g1 e6e5 g3h3 b7c6 g1f2 h7h6 f2h4 f6h7 h4g3",
    DEPTH: 29,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/8/3q4/3Q4/5N2/PP3PPP/RNB1KB1R b KQkq -",
    EVAL: 6,
    MOVES: "d5e6 c1e3 b8c6 f1b5 c8d7 d4d3 f8b4 b1c3 g8f6 a2a3",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 19,
    MOVES: "c8f5 g1f3 e7e6 f1e2 c6c5 c2c3 b8c6 e1h1 f5g6 a2a3",
    DEPTH: 55,
  },
  {
    FEN: "r3bk2/ppp1nPp1/3B3p/2b5/2B3nq/2N5/PP4PP/4RR1K w - -",
    EVAL: -10,
    MOVES: "e1e7 h4e7 d6e7 c5e7 f7e8b f8e8 c3b5 e7d6 f1f7 a8d8",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/5b2/4N3/5N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 67,
    MOVES: "e4g3 f5g4 c2c3 g4f3 d1f3 d8d5 f1e2 e7e6 d2d4 b8d7",
    DEPTH: 48,
  },
  {
    FEN: "4r1k1/1bqrbppp/p2ppn2/1p6/3BPP2/P1NB2Q1/1PP3PP/4RR1K w - -",
    EVAL: 39,
    MOVES: "a3a4 b5b4 c3d1 e7d8 b2b3 e6e5 d4b2 c7c8 f4e5 d6e5",
    DEPTH: 33,
  },
  {
    FEN: "8/2k2Q2/3bqP2/R2p4/3P1p2/2p4P/2P3P1/7K b - -",
    EVAL: 1027,
    MOVES: "e6f7 a5a7 c7d8 a7f7 d8e8 f7g7 f4f3 g2f3 e8f8 h1g2",
    DEPTH: 40,
  },
  {
    FEN: "r1bk1b1r/p1p2ppp/8/1B1np1q1/3P4/8/PP1P1PPP/RNBQK2R w KQ -",
    EVAL: 73,
    MOVES: "e1h1 c8b7 d1f3 a8b8 d4e5 d5e3 f3h3 g5g2 h3g2 e3g2",
    DEPTH: 46,
  },
  {
    FEN: "r2qkb1r/ppp2ppp/3p1n2/3Pp3/2P1P1b1/2N2N2/PP2nPPP/R1BQK2R w KQkq -",
    EVAL: 42,
    MOVES: "d1e2 f8e7 h2h3 g4d7 e1h1 h7h6 f3e1 e8h8 f2f4 c7c6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n5/2b1P3/2P3n1/4PN2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 87,
    MOVES: "f1e2 c6e5 f3e5 g4e5 f2f4 e5g6 b1c3 e8h8 d1d3 f8e8",
    DEPTH: 45,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/4q3/8/3Q4/5N2/PP3PPP/RNB1KB1R w KQkq -",
    EVAL: 19,
    MOVES: "c1e3 b8c6 f1b5 c8d7 d4d3 f8b4 b1c3 g8f6 a2a3 b4c3",
    DEPTH: 49,
  },
  {
    FEN: "r2qkb1r/1p2pppp/p1n2n2/3p4/3P1Bb1/2N1P3/PP3PPP/2RQKBNR w Kkq -",
    EVAL: 35,
    MOVES: "g1f3 e7e6 f1e2 f8d6 f4d6 d8d6 e1h1 e8h8 c3a4 a8c8",
    DEPTH: 35,
  },
  {
    FEN: "5rk1/pQ1p1ppp/1p2p3/2p2q2/2P5/4P1P1/PPnK1P1P/3R3R w - -",
    EVAL: 37,
    MOVES: "b7g2 c2b4 e3e4 f5g4 f2f3 g4g5 f3f4 g5d8 d2e3 b4c6",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/ppp1ppp1/2np1n1p/8/3P3B/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 35,
    MOVES: "h4f6 e7f6 c2c4 c6e7 g2g3 g7g6 f1g2 c7c6 b1c3 d6d5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/1B3p2/4p3/1P2P3/PBPP1PPP/RN1QK1NR w KQkq -",
    EVAL: 35,
    MOVES: "d2d3 d8g5 g2g3 a7a6 b5c6 d7c6 h2h4 g5g6 g1h3 g8f6",
    DEPTH: 23,
  },
  {
    FEN: "r1bqkb1r/pp1ppppp/2n2n2/2p5/2B1P3/1P6/P1PP1PPP/RNBQK1NR w KQkq -",
    EVAL: -78,
    MOVES: "c1b2 f6e4 c4f7 e8f7 d1h5 f7g8 h5d5 e7e6 d5e4 d7d5",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2P5/4P3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: -13,
    MOVES: "f8c5 f1d3 d7d6 g1f3 g8f6 a2a3 e8h8 e1h1 b8c6 h2h3",
    DEPTH: 43,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2p3/2p2q2/2P5/4P1P1/PPnK1PQP/3R3R b - -",
    EVAL: 32,
    MOVES: "c2b4 e3e4 f5g5 f2f4 g5d8 a2a3 b4c6 d2c1 c6d4 c1b1",
    DEPTH: 30,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n4n/2b3NQ/2BpP3/8/PPP2PPP/RNB1K2R w KQ -",
    EVAL: -46,
    MOVES: "c4f7 g8h8 e1h1 c6e5 f7b3 a7a5 a2a4 d8f6 b1d2 b7b6",
    DEPTH: 42,
  },
  {
    FEN: "rk3N1r/ppp1n2p/8/6B1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: 469,
    MOVES: "g5e7 b7b6 a1e1 c7c5 f2f4 b8c7 f8e6 c7d7 e7h4 a7a5",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq -",
    EVAL: 12,
    MOVES: "d4b3 f8e7 e1h1 c8e6 f2f4 e5f4 c1f4 d6d5 e4d5 f6d5",
    DEPTH: 45,
  },
  {
    FEN: "8/8/4np2/3Npk1p/R1r4P/P3KP2/1P6/8 b - -",
    EVAL: 1001,
    MOVES: "c4c7 d5c7 e6c7 a4c4 c7d5 e3f2 f5e6 a3a4 e6d7 a4a5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -",
    EVAL: 10,
    MOVES: "g8f6 c2c4 e7e6 g2g3 f8b4 c1d2 b4e7 f1g2 e8h8 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "r1b1kb1r/ppppqppp/2n2n2/8/8/3P1N2/PPP1QPPP/RNB1KB1R w KQkq -",
    EVAL: 10,
    MOVES: "g2g3 e7e2 e1e2 g7g6 f1g2 f8g7 b1c3 a7a6 h1e1 e8h8",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR w KQkq -",
    EVAL: -33,
    MOVES: "f1c4 g7g6 h5e2 f8g7 g1f3 g8e7 d2d3 e8h8 b1c3 c6d4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -",
    EVAL: 18,
    MOVES: "e2e4 d7d5 e4d5 e6d5 d2d4 g8f6 f1d3 c7c5 c2c3 f8d6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 f8d6 c3b5 d6b4 c2c3 b4a5 f1d3 e8h8 e1h1 c7c6",
    DEPTH: 55,
  },
  {
    FEN: "r1k2N1r/ppp1b2p/2n5/5qB1/3p2Q1/8/PPP2PPP/R5K1 w - -",
    EVAL: "M5",
    MOVES: "g4f5 c8b8 f8d7 b8c8 d7b6 c8b8 f5c8 h8c8 b6d7",
    DEPTH: 178,
  },
  {
    FEN: "r1bq1rk1/pppp1Npp/2n4n/2b4Q/2BpP3/8/PPP2PPP/RNB1K2R b KQ -",
    EVAL: -200,
    MOVES: "c5b4 c2c3 h6f7 c3b4 c6b4 c4b3 d7d5 e1h1 d5e4 c1g5",
    DEPTH: 27,
  },
  {
    FEN: "r1bk1b1r/p1p2ppp/8/1B1np1q1/3P4/8/PP1P1PPP/RNBQ1RK1 b - -",
    EVAL: 53,
    MOVES: "c8b7 d1f3 a8b8 d4e5 d5e3 f3h3 g5g2 h3g2 e3g2 d2d4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3p4/2BNn3/8/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -96,
    MOVES: "c4b5 c8d7 d4f3 d7e6 f3d4 d8d7 b1c3 e4c3 b2c3 f8c5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4Q3/4P3/8/PPPP1PPP/RNB1KBNR b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 b1c3 b8c6 e5f4 e8h8 e4e5 f6h5 f4a4 c6e5 g2g4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 94,
    MOVES: "d4d5 g8f6 b1c3 d7d6 d5e6 c8e6 g1e2 b8c6 e2f4 d8d7",
    DEPTH: 49,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 29,
    MOVES: "f1e2 e7e6 g1f3 c6c5 c1e3 c5d4 f3d4 g8e7 e1h1 b8c6",
    DEPTH: 63,
  },
  {
    FEN: "r5kr/1q2Np1p/p4p1B/1pp5/8/1PPP4/1P3PPP/R3R1K1 b - -",
    EVAL: "M5",
    MOVES: "b7e7 e1e7 f6f5 a1e1 f7f6 e1e6 f5f4 e6f6 a6a5 e7g7",
    DEPTH: 102,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 c2c3 g8e7 g1f3 b8c6 b1a3 c8d7 a3c2 d8a5 c1d2",
    DEPTH: 58,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1p4/3Nn3/8/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: -90,
    MOVES: "c8d7 c2c4 f8c5 d4b3 d5c4 b3c5 e4c5 b5c4 d7e6 c4b5",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p2Q/2B1P3/8/PPPP1PPP/RNB1K1NR b KQkq -",
    EVAL: -25,
    MOVES: "g7g6 h5f3 g8f6 g1e2 f8g7 b1c3 d7d6 d2d3 e8h8 a2a3",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 7,
    MOVES: "f8b4 f1d3 e8h8 e1h1 d7d5 e4d5 c6d5 h2h3 c7c6 d1f3",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: -7,
    MOVES: "f6e4 g5e3 e7e6 c2c3 c7c5 d4c5 a7a5 g2g3 b8d7 f1g2",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: -7,
    MOVES: "g5h4 h7h5 h2h3 c7c5 d4c5 b8a6 b1d2 a6c5 d2e4 c5e4",
    DEPTH: 41,
  },
  {
    FEN: "8/8/4np2/3Npk1p/R6r/P3KP2/1P6/8 w - -",
    EVAL: 1069,
    MOVES: "a4h4 e6c5 h4c4 c5b3 c4c6 h5h4 c6f6 f5g5 f6e6 g5f5",
    DEPTH: 43,
  },
  {
    FEN: "r2qkb1r/pppb1ppp/2n5/1B1p4/3Nn3/8/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -93,
    MOVES: "c2c4 f8c5 d4b3 c5f2 f1f2 e4f2 g1f2 c6e7 b5d7 d8d7",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkbnr/pppp1p1p/2n3p1/4p2Q/2B1P3/8/PPPP1PPP/RNB1K1NR w KQkq -",
    EVAL: -34,
    MOVES: "h5f3 g8f6 g1e2 f8g7 d2d3 d7d6 b1c3 e8h8 a2a3 c8e6",
    DEPTH: 46,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1 w - -",
    EVAL: 33,
    MOVES: "e2f3 c8e6 c3d5 a8c8 c2c3 d8d7 a2a4 e7d8 c3c4 h7h6",
    DEPTH: 33,
  },
  {
    FEN: "rnbqk2r/ppppbppp/5n2/4Q3/4P3/8/PPPP1PPP/RNB1KBNR w KQkq -",
    EVAL: 0,
    MOVES: "e5f4 e8h8 g1f3 b8c6 e4e5 f6d5 f4e4 d5b4 d2d4 d7d5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/3p4/3Pn2B/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: -24,
    MOVES: "h7h5 h2h3 c7c5 e2e3 f7f6 f3d2 e4d6 f1d3 d6f5 h4g3",
    DEPTH: 42,
  },
  {
    FEN: "rk3r2/pppQb2p/2n5/6B1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: 662,
    MOVES: "g5e7 c6e7 d7e7 f8h8 e7d7 a7a5 d7d4 h8g8 d4d5 g8c8",
    DEPTH: 31,
  },
  {
    FEN: "r2q1rk1/ppp2ppp/2np1n2/2b1p1B1/2B1P1b1/2PP1N2/PP3PPP/RN1Q1RK1 w - -",
    EVAL: 49,
    MOVES: "h2h3 g4e6 c4e6 f7e6 b2b4 c5b6 a2a4 a7a6 b1d2 h7h6",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pppp1p1p/2n3p1/4p3/2B1P3/5Q2/PPPP1PPP/RNB1K1NR b KQkq -",
    EVAL: -30,
    MOVES: "g8f6 g1e2 f8g7 d2d3 d7d6 b1c3 e8h8 a2a3 h7h6 c1d2",
    DEPTH: 53,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 30,
    MOVES: "e7e6 f1e2 c6c5 c1e3 c5d4 f3d4 g8e7 e1h1 b8c6 e2b5",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 93,
    MOVES: "d4d5 b7b5 c4b5 a7a6 e2e3 d8a5 c1d2 a5b6 b1c3 a6b5",
    DEPTH: 53,
  },
  {
    FEN: "rn1q1rk1/4bppp/p2pbn2/1p2p3/4P3/1BN1BN2/PPP1QPPP/R4RK1 b - -",
    EVAL: 0,
    MOVES: "d8c7 a2a3 b8d7 f1d1 c7b7 e3g5 a8c8 f3h4 g7g6 h4f3",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 32,
    MOVES: "c2c3 d8b6 g1f3 b8c6 a2a3 g8h6 f1d3 c8d7 d3c2 c5d4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n5/2pnp3/2B5/1P1P4/PBP2PPP/RN1QK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 f7f6 b1c3 d5b6 c4b5 c8d7 f3d2 c6d4 a2a4 f8e7",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk2r/ppppbppp/5n2/8/4PQ2/8/PPPP1PPP/RNB1KBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 g1f3 e8h8 e4e5 f6h5 f4g4 g7g6 g4a4 d7d6 d2d4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 9,
    MOVES: "e8h8 g1f3 d7d5 c1d2 b7b6 c4d5 e6d5 a1c1 c8b7 f1d3",
    DEPTH: 52,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 18,
    MOVES: "d2d4 c5d4 c3d4 e7e5 g1f3 e5d4 d1d4 g8f6 b1c3 d5d4",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "d2d4 e7e6 g2g3 d7d5 g1f3 f8e7 f1g2 e8h8 e1h1 d5c4",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/ppppbppp/5n2/8/4PQ2/8/PPPP1PPP/RNB1KBNR w KQ -",
    EVAL: -5,
    MOVES: "g1f3 d7d5 d2d3 b8c6 f1e2 d5e4 d3e4 f8e8 b1c3 e7b4",
    DEPTH: 44,
  },
  {
    FEN: "5k2/pp1b4/3N1pp1/3P4/2p5/q1P1QP2/5KP1/8 w - -",
    EVAL: 1143,
    MOVES: "e3h6 f8e7 h6g7 e7d6 g7f8 d6d5 f8a3 d5e6 a3a7 d7c6",
    DEPTH: 56,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 g8e7 f1d3 c5d4 c3d4 e7f5 c1e3 f5e3 f2e3 f8e7",
    DEPTH: 60,
  },
  {
    FEN: "r1bqkb1r/pppp1p1p/2n2np1/4p3/2B1P3/5Q2/PPPP1PPP/RNB1K1NR w KQkq -",
    EVAL: -40,
    MOVES: "g1e2 f8g7 a2a3 d7d6 b1c3 c8g4 f3e3 g4e6 c4e6 f7e6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/3pP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 3,
    MOVES: "e7e5 c2c3 b8c6 c3d4 e5d4 f3d4 g8f6 b1c3 f8b4 d4c6",
    DEPTH: 47,
  },
  {
    FEN: "r3R2r/5k1p/p4p1B/1pp2p2/8/1PPP4/1P3PPP/4R1K1 w - -",
    EVAL: 905,
    MOVES: "e8e7 f7g6 h6f4 h7h5 h2h4 h8h7 e7e6 h7f7 e1e3 b5b4",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pppp1p1p/2n2np1/4p3/2B1P1P1/5Q2/PPPP1P1P/RNB1K1NR b KQkq -",
    EVAL: -452,
    MOVES: "c6d4 f3d1 d7d5 c4e2 h7h5 c2c3 d4e2 g1e2 c8g4 d2d4",
    DEPTH: 39,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2p3/2p2q2/1nP5/4P1P1/PP1K1PQP/3R3R w - -",
    EVAL: 29,
    MOVES: "e3e4 f5g5 f2f4 g5d8 a2a3 b4c6 d2c1 c6d4 c1b1 e6e5",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/8/4p3/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 21,
    MOVES: "c2c3 b8c6 c3d4 e5d4 f3d4 g8f6 b1c3 f8b4 d4c6 b7c6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 27,
    MOVES: "g8f6 b1c3 a7a6 c1e3 e7e5 d4f3 f8e7 f1c4 e8h8 c4b3",
    DEPTH: 51,
  },
  {
    FEN: "rn1qkbnr/pp2ppp1/2p3bp/8/7P/5NN1/PPPP1PP1/R1BQKB1R w KQkq -",
    EVAL: 139,
    MOVES: "f3e5 d8d6 d2d4 b8d7 e5g6 d6g6 c2c3 g6d6 g3f5 d6c7",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pppp1p1p/5np1/4p3/2BnP1P1/5Q2/PPPP1P1P/RNB1K1NR w KQkq -",
    EVAL: -646,
    MOVES: "f3d1 d7d5 c4e2 d5e4 b1c3 h7h6 d2d3 c8g4 e2g4 f6g4",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pppp2pp/2n2n2/4ppN1/2B1P3/3P4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 50,
    MOVES: "d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5a4 f8d6 e1h1",
    DEPTH: 33,
  },
  {
    FEN: "r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2PB1N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 c3d4 d8b6 d3e2 g8e7 b1c3 e7f5 c3a4 b6a5 a4c3",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 80,
    MOVES: "a7a6 b5a6 e7e6 b1c3 e6d5 c3d5 f8e7 d5e7 d8e7 c1f4",
    DEPTH: 52,
  },
  {
    FEN: "r3R2r/4R2p/p4pkB/1pp2p2/8/1PPP4/1P3PPP/6K1 w - -",
    EVAL: 871,
    MOVES: "e8a8 h8a8 h6e3 a8d8 g1f1 d8d3 f1e2 d3d5 e7a7 c5c4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/ppp1ppp1/8/3p3p/3Pn2B/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "h2h3 c7c6 c2c3 d8b6 d1c1 c8f5 b1d2 e4d2 c1d2 e7e6",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 25,
    MOVES: "f1e2 c6c5 c1e3 c5d4 f3d4 g8e7 b1d2 b8c6 d2f3 f5g4",
    DEPTH: 51,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/1QN5/PP2PPPP/R3KBNR b KQkq -",
    EVAL: -44,
    MOVES: "b6d4 e2e4 e7e6 f4b8 a8b8 f1b5 c8d7 b5d7 e8d7 g1f3",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/8/4N3/3pP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -394,
    MOVES: "d8a5 b1d2 a5e5 f1e2 b8c6 e1h1 f8e7 f1e1 e5c7 d2c4",
    DEPTH: 38,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: 11,
    MOVES: "b8c6 g1f3 e7e6 b1a3 g8f6 f1c4 d5d8 e1h1 c5d4 a3b5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "d7d5 c2c4 g8f6 b1c3 f8e7 c1f4 e8h8 a2a3 b7b6 e2e3",
    DEPTH: 48,
  },
  {
    FEN: "r3qrk1/pppnppbp/2np2pB/8/3PP3/2N1QP2/PPP2P1P/2KR1B1R b - -",
    EVAL: 132,
    MOVES: "e7e5 h6g7 g8g7 d4e5 d6e5 h2h4 h7h5 c3b5 e8e7 b5c7",
    DEPTH: 29,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p4/3P2b1/3B4/PPP1NPPP/RNBQK2R w KQkq -",
    EVAL: 13,
    MOVES: "c2c3 d8d7 e1h1 g8f6 c1g5 h7h6 g5f4 f6h5 f4e3 e7e6",
    DEPTH: 50,
  },
  {
    FEN: "rnb1kbnr/pp1p1ppp/8/q3N3/3pP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: -427,
    MOVES: "c2c3 a5e5 f1d3 b8c6 f2f4 e5f6 e1h1 g8h6 a2a4 f8e7",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2np4/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 53,
    MOVES: "c2c4 g8f6 b1c3 c6d4 d1d4 g7g6 f2f3 f8g7 c1e3 a7a6",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/ppp2p1p/5np1/3pp3/3nP1P1/8/PPPPBP1P/RNBQK1NR b KQkq -",
    EVAL: -656,
    MOVES: "d5e4 b1c3 f8g7 g4g5 f6d5 c3e4 d5b4 c2c3 d4e2 c3b4",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 21,
    MOVES: "c2c4 e7e6 d1b3 b8c6 b1d2 f8d6 f4d6 c7d6 c4d5 e6d5",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "b7b6 f1d3 c8b7 f2f3 b8c6 g1e2 c6a5 e3e4 b7a6 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/6P1/PPPPPP1P/RNBQKBNR b KQkq -",
    EVAL: 10,
    MOVES: "c7c5 f1g2 g7g6 c2c4 b8c6 g1f3 f8g7 b1c3 e7e6 d2d4",
    DEPTH: 60,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2pq2/2p5/1nP1P3/6P1/PP1K1PQP/3R3R w - -",
    EVAL: 48,
    MOVES: "d2c1 b6b5 a2a3 b4c6 c4b5 c6d4 c1b1 f8b8 f2f4 f6d8",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/4P3/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: 13,
    MOVES: "a6b5 f1b5 c8b7 b1c3 d8a5 c1d2 a5b6 a2a4 f6d5 g1f3",
    DEPTH: 51,
  },
  {
    FEN: "r3R2r/6Rp/p4p1k/1pp2p2/8/1PPP4/1P3PPP/6K1 w - -",
    EVAL: 300,
    MOVES: "e8h8 a8h8 g7d7 b5b4 c3c4 h6g6 g1f1 f5f4 d3d4 c5d4",
    DEPTH: 28,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P5/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 10,
    MOVES: "g1f3 e7e6 b1a3 b8c6 c1e3 c5d4 a3b5 d5d7 b5d4 f8e7",
    DEPTH: 49,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2pq2/2p5/1nP1P3/6P1/PP3PQP/2KR3R b - -",
    EVAL: 55,
    MOVES: "b6b5 a2a3 b4c6 c4b5 c6d4 f2f4 f8b8 c1b1 f6d8 d1d4",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkbnr/1p1npppp/p2p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 9,
    MOVES: "b5e2 g8f6 d2d3 e7e6 a2a4 b7b6 c1f4 f8e7 b1a3 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "r1b1k2r/1pqp1ppp/p1n1pn2/8/1b1NP3/2N1B3/PPP1BPPP/R2Q1RK1 w kq -",
    EVAL: 70,
    MOVES: "c3a4 b4e7 d4c6 d7c6 e3b6 c7f4 c2c4 e6e5 f1e1 e8h8",
    DEPTH: 41,
  },
  {
    FEN: "rk3N1r/ppp1b2p/2n5/5QB1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: "M4",
    MOVES: "f8d7 b8c8 d7b6 c8b8 f5c8 h8c8 b6d7",
    DEPTH: 245,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbB2/4p3/4P3/1NN5/PPP1BPPP/R2QK2R b KQkq -",
    EVAL: 0,
    MOVES: "e7f6 d1d3 f6e7 c3d5 b8d7 d5e7 e8e7 e1h1 b7b5 d3g3",
    DEPTH: 46,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 f1e2 b8c6 e1h1 c5d4 f3d4 c8d7 d4c6 d7c6 d1d5",
    DEPTH: 53,
  },
  {
    FEN: "rnbqk2r/p2p1ppp/1p2pn2/2P5/2P5/P1P1P3/5PPP/R1BQKBNR b KQkq -",
    EVAL: -84,
    MOVES: "b6c5 g1e2 c8b7 f2f3 d8c7 e3e4 b8c6 c1f4 c6e5 d1d2",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/ppp3pp/2n2n2/3PppN1/2B5/3P4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 64,
    MOVES: "c6a5 c4b5 c7c6 d5c6 b7c6 b5a4 f8d6 e1h1 e8h8 c1d2",
    DEPTH: 49,
  },
  {
    FEN: "5k2/pp1b4/3N1ppQ/3P4/2p5/q1P2P2/5KP1/8 b - -",
    EVAL: 802,
    MOVES: "f8e7 h6g7 e7d6 g7f8 d6d5 f8a3 d5e6 a3a7 d7c6 a7c5",
    DEPTH: 50,
  },
  {
    FEN: "r2qkb1r/pp1n1ppp/2p1pn2/3p4/3P1B2/2PQPN2/PP3PPP/RN2K2R w KQkq -",
    EVAL: 17,
    MOVES: "b1d2 f8e7 e1h1 e8h8 e3e4 d5e4 d2e4 f6d5 e4g5 d7f6",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkb1r/ppp3pp/5n2/n2PppN1/2B5/3P4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 47,
    MOVES: "c4b5 c7c6 d5c6 b7c6 b5a4 f8d6 e1h1 e8h8 c1d2 h7h6",
    DEPTH: 42,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2pq2/2p5/2P1P3/6P1/nP3PQP/2KR3R w - -",
    EVAL: 31,
    MOVES: "c1b1 a2b4 d1d7 b4c6 f2f4 c6d4 g2d2 a7a6 d7d6 f8b8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/ppp1ppp1/8/3p3p/3Pn2B/2N2N2/PPP1PPPP/R2QKB1R b KQkq -",
    EVAL: -95,
    MOVES: "c7c5 e2e3 d8a5 f1e2 e4c3 d1d2 f7f6 b2c3 g7g5 h4g3",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 37,
    MOVES: "b5c6 d7c6 d2d3 f8g7 h2h3 a7a5 a2a4 b7b6 b1a3 g8f6",
    DEPTH: 52,
  },
  {
    FEN: "8/pp1bk3/3N1ppQ/3P4/2p5/q1P2P2/5KP1/8 w - -",
    EVAL: 806,
    MOVES: "h6g7 e7d6 g7f8 d6d5 f8a3 d5e6 a3a7 b7b5 a7b6 e6f7",
    DEPTH: 56,
  },
  {
    FEN: "3r2k1/pp5p/6p1/2Ppq3/4rB2/7b/PP2P2K/R1Q1R2B b - -",
    EVAL: -781,
    MOVES: "e4f4 c1f4 e5f4 h2h3 f4h6 h3g2 d5d4 e1f1 h6g5 g2f2",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1bB1P3/2N2N2/PB3PPP/R2QK2R b KQkq -",
    EVAL: 0,
    MOVES: "b4c3 b2c3 g8f6 e1h1 e8h8 f3g5 h7h6 c4f7 f8f7 g5f7",
    DEPTH: 76,
  },
  {
    FEN: "rnbqkb1r/3ppppp/5n2/1ppP4/8/4P3/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 68,
    MOVES: "f1b5 c8b7 b1c3 d8a5 c1d2 a5b6 g1f3 f6d5 a2a4 e7e6",
    DEPTH: 54,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/2n2n2/2pq4/2PP4/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: -95,
    MOVES: "d5e4 c1e3 c5d4 f3d4 f6g4 d4c6 e4c6 b1c3 g4e3 f2e3",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/3ppppp/5n2/1BpP4/8/4P3/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: 64,
    MOVES: "d8a5 b1c3 c8b7 c1d2 a5b6 a2a4 f6d5 g1f3 e7e6 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "5rk1/p2p1ppp/1p2pq2/2p5/2P1P3/6P1/nP3PQP/1K1R3R b - -",
    EVAL: 24,
    MOVES: "a2b4 d1d7 b4c6 h1c1 e6e5 g2f1 a7a5 c1c3 f8e8 c3d3",
    DEPTH: 31,
  },
  {
    FEN: "r1b1k2r/2qp1ppp/p1p1pn2/8/1b1QP3/2N1B3/PPP1BPPP/R4RK1 b kq -",
    EVAL: 10,
    MOVES: "c6c5 d4c4 e8h8 c3a4 a6a5 c4d3 d7d5 c2c4 c8d7 a2a3",
    DEPTH: 44,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/2n2n2/2p5/2PPq3/5N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: -52,
    MOVES: "c1e3 c5d4 f3d4 f6g4 b1c3 g4e3 c3e4 e3d1 a1d1 c6d4",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/5n2/1p1Pp1N1/3n4/2P5/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 62,
    MOVES: "f6d5 c3d4 d8g5 f1b5 e8d8 e1h1 c8b7 d1f3 a8b8 d4e5",
    DEPTH: 44,
  },
  {
    FEN: "r1b1k2r/2qp1ppp/p3pn2/2p5/1bQ1P3/2N1B3/PPP1BPPP/R4RK1 b kq -",
    EVAL: 27,
    MOVES: "c8b7 a2a3 b4a5 c4c5 c7c5 e3c5 a5c3 b2c3 f6e4 c5d4",
    DEPTH: 40,
  },
  {
    FEN: "rnb1kb1r/3ppppp/5n2/qBpP4/8/4P3/PP3PPP/RNBQK1NR w KQkq -",
    EVAL: 88,
    MOVES: "b1c3 c8b7 c1d2 a5b6 g1f3 b7d5 c3d5 f6d5 a2a4 e7e6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/5n2/4p3/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 6,
    MOVES: "e1h1 d8c7 d1d3 d7d5 c4d5 f8c5 c2c3 e8h8 b2b4 c5e7",
    DEPTH: 54,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/2n2n2/2p5/2PPq3/4BN2/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: -57,
    MOVES: "f6g4 b1d2 g4e3 d2e4 e3d1 a1d1 c5d4 f3d4 c6d4 d1d4",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 40,
    MOVES: "d2d4 d7d5 e4e5 c8f5 c2c3 e7e6 f1e2 f7f6 f2f4 g7g5",
    DEPTH: 55,
  },
  {
    FEN: "r3k2r/1bqp1ppp/p3pn2/2p5/1bQ1P3/2N1B3/PPP1BPPP/R4RK1 w kq -",
    EVAL: 50,
    MOVES: "a2a3 d7d5 e4d5 e6d5 e3f4 c7c8 c4b3 b4c3 b3c3 e8h8",
    DEPTH: 29,
  },
  {
    FEN: "5rk1/p2R1ppp/1p2pq2/2p5/1nP1P3/6P1/1P3PQP/1K5R b - -",
    EVAL: 24,
    MOVES: "b4c6 f2f4 e6e5 g2d2 c6d4 d7d5 e5f4 g3f4 f6g6 f4f5",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/8/2BNP3/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f6e4 d1h5 e7e6 c4b5 b8d7 d4e6 e4f6 b5d7 c8d7 e6d8",
    DEPTH: 47,
  },
  {
    FEN: "rnb1kb1r/3ppppp/5n2/qBpP4/8/2N1P3/PP3PPP/R1BQK1NR b KQkq -",
    EVAL: 64,
    MOVES: "c8b7 c1d2 a5b6 g1f3 f6d5 a2a4 e7e6 e1h1 d5b4 e3e4",
    DEPTH: 44,
  },
  {
    FEN: "r2q1rk1/pp2bppp/2npbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1 w - -",
    EVAL: 16,
    MOVES: "f1e1 d6d5 c3d5 f6d5 e4d5 e6d5 e2b5 f7f5 b3c5 f5f4",
    DEPTH: 31,
  },
  {
    FEN: "r2qkb1r/pp1b1ppp/2n1pn2/3p4/3P4/5N2/PPP1BPPP/RNBQ1RK1 w kq -",
    EVAL: 38,
    MOVES: "f1e1 f8d6 e2d3 c6b4 d3f1 d8c7 c2c3 b4c6 f1d3 e8h8",
    DEPTH: 22,
  },
  {
    FEN: "r1bqkbnr/3npppp/p2p4/1pp5/4P3/3B1N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 70,
    MOVES: "c2c4 b5b4 d3c2 g8f6 a2a3 b4a3 a1a3 c8b7 d2d3 e7e6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b5/4P3/3P4/PPP1BPPP/RNBQK2R b KQkq -",
    EVAL: 189,
    MOVES: "f6g4 e2g4 d8h4 d1f3 h4g4 f3g4 c8g4 f2f3 g4e6 b1c3",
    DEPTH: 57,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/3p1n2/4pP2/2B5/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -48,
    MOVES: "d6d5 c4b3 f8b4 c2c3 b4c5 d1e2 b8d7 e1h1 d8c7 f1d1",
    DEPTH: 25,
  },
  {
    FEN: "5rk1/p2R1ppp/1pn1pq2/2p5/2P1P3/6P1/1P3PQP/1K5R w - -",
    EVAL: 51,
    MOVES: "f2f4 c6d4 g2d2 e6e5 d7a7 h7h6 h1d1 f6d6 d2e3 f8d8",
    DEPTH: 35,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/5n2/qBpP4/8/2N1P3/PP3PPP/R1BQK1NR w KQkq -",
    EVAL: 48,
    MOVES: "c1d2 a5b6 a2a4 f6d5 g1f3 d5b4 e3e4 e7e6 e1h1 f8e7",
    DEPTH: 46,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/5n2/qBpP4/8/2N1P3/PP1B1PPP/R2QK1NR b KQkq -",
    EVAL: 72,
    MOVES: "a5b6 g1f3 f6d5 a2a4 e7e6 e1h1 d5b4 e3e4 b8c6 d2f4",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/4P3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: -28,
    MOVES: "b8c6 g1f3 e7e6 f1e2 d8c7 e1h1 g8f6 d1a4 d7d6 c1f4",
    DEPTH: 49,
  },
  {
    FEN: "5rk1/p2R1ppp/1pn1pq2/2p5/2P1PP2/6P1/1P4QP/1K5R b - -",
    EVAL: 29,
    MOVES: "e6e5 g2d2 c6d4 d7d5 e5f4 g3f4 f6g6 f4f5 g6g4 d2d3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p5/2b5/4P1B1/3P4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 154,
    MOVES: "d8h4 d1f3 h4g4 f3g4 c8g4 c1e3 c5e7 h2h4 g4e6 f2f3",
    DEPTH: 34,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
    EVAL: 49,
    MOVES: "c2c4 e7e6 d1b3 b8c6 a2a3 c6a5 b3a4 c7c6 c4c5 b7b5",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 36,
    MOVES: "f1e1 a7a6 b5f1 d7d5 e4d5 e7d5 d2d4 d5f6 c1e3 c5d4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 21,
    MOVES: "g8f6 d2d3 d7d6 c2c3 e8h8 f1e1 a7a5 h2h3 h7h6 b1d2",
    DEPTH: 50,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q3n2/1BpP4/8/2N1P3/PP1B1PPP/R2QK1NR w KQkq -",
    EVAL: 83,
    MOVES: "g1f3 f6d5 a2a4 e7e6 e1h1 d5b4 e3e4 f8e7 d2f4 b6d8",
    DEPTH: 49,
  },
  {
    FEN: "rn1qk2r/pp2bppp/3p1n2/4pP2/2B5/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 63,
    MOVES: "b1c3 b8c6 c1e3 a8c8 c4b3 c6a5 e3g5 e8h8 e1h1 f6d7",
    DEPTH: 22,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5Q2/PPPP1PPP/RNB1KBNR w KQkq -",
    EVAL: -20,
    MOVES: "f1c4 c7c6 b1c3 b7b5 c4b3 a7a5 a2a3 f8c5 d2d3 e8h8",
    DEPTH: 45,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 54,
    MOVES: "e7e6 b1c3 g8f6 f3h4 f5g6 c4d5 e6d5 d1b3 b8c6 e2e3",
    DEPTH: 41,
  },
  {
    FEN: "r1q2rk1/p1p2n1p/P1Pp2p1/3P1p2/3B1P2/3Q2P1/7P/R4RK1 w - -",
    EVAL: 1120,
    MOVES: "d4c3 f8e8 d3d4 e8e5 f4e5 d6e5 d4c5 h7h5 c5e7 c8d8",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/8/4P3/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: -10,
    MOVES: "d7d6 f1c4 a7a6 e1h1 g8f6 c1f4 e7e6 d1e2 f6h5 f4e3",
    DEPTH: 59,
  },
  {
    FEN: "r1b1k2r/ppp2ppp/2p5/2b5/4P1Bq/3P4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 151,
    MOVES: "d1f3 c8g4 f3g3 h4g3 h2g3 g4e6 b1d2 a7a5 a2a4 h7h5",
    DEPTH: 38,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e5d4 e4e5 d7d5 c4b5 f6e4 c3d4 c5b6 c1e3 e8h8 b1c3",
    DEPTH: 56,
  },
  {
    FEN: "5rk1/p2R1ppp/1pn2q2/2p1p3/2P1PP2/6P1/1P4QP/1K1R4 b - -",
    EVAL: 0,
    MOVES: "c6d4 f4e5 f6e5 d7a7 e5e6 e4e5 e6c4 a7a8 g7g6 a8f8",
    DEPTH: 41,
  },
  {
    FEN: "r5rk/pp1b1n1p/8/3qPpp1/3P4/PP1Q1N1P/1B5K/4RR2 w - -",
    EVAL: 755,
    MOVES: "e5e6 d7e6 e1e6 g8g6 d3c4 d5c4 d4d5 h8g8 b3c4 g6e6",
    DEPTH: 41,
  },
  {
    FEN: "5q2/1b4pk/1p2p1n1/1P1pPp2/P2P1P1p/rB1N1R1P/1Q4PK/8 w - -",
    EVAL: 1014,
    MOVES: "d3c5 a3b3 c5b3 f8b4 b2c1 b4c4 c1a3 b7c8 a3d6 c4a4",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 40,
    MOVES: "b1c3 g8f6 f3h4 f5g6 h4g6 h7g6 d1b3 b8c6 e2e3 f8b4",
    DEPTH: 56,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/2P2N2/PP3PPP/RNBQK2R w KQkq -",
    EVAL: 17,
    MOVES: "e4e5 d7d5 c4b5 f6e4 c3d4 c5b6 c1e3 e8h8 b1c3 c8g4",
    DEPTH: 58,
  },
  {
    FEN: "r1k2N1r/ppp1b2p/2n5/5QB1/3p4/8/PPP2PPP/R5K1 b - -",
    EVAL: "M4",
    MOVES: "c8b8 f8d7 b8c8 d7b6 c8b8 f5c8 h8c8 b6d7",
    DEPTH: 245,
  },
  {
    FEN: "rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP1B2/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: 12,
    MOVES: "d5c4 b1d2 g8f6 d2c4 f8d6 f3e5 e8h8 a2a3 d8e7 a1c1",
    DEPTH: 41,
  },
  {
    FEN: "r2qnrk1/3nbppp/3pb3/p3p1P1/1p2PP2/1N2B3/PPPQN2P/2KR1B1R w - -",
    EVAL: 73,
    MOVES: "f4f5 a5a4 f5e6 a4b3 c2b3 f7e6 f1h3 a8a2 h3e6 g8h8",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 11,
    MOVES: "c7c5 c2c4 c5d4 c4d5 d8a5 d1d2 a5d2 c1d2 g8f6 e3d4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: -87,
    MOVES: "d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 c7c6 f1d3 c8g4 c1e3",
    DEPTH: 53,
  },
  {
    FEN: "8/pp1b2Q1/3k1pp1/3P4/2p5/q1P2P2/5KP1/8 w - -",
    EVAL: 930,
    MOVES: "g7f8 d6d5 f8a3 d5e6 a3a7 d7c6 a7c5 g6g5 f2e3 e6d7",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/2p2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 68,
    MOVES: "d2d4 e5d4 d1d4 f8e7 a2a4 e8h8 g1f3 d7d6 h2h3 b8d7",
    DEPTH: 50,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP1B2/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 55,
    MOVES: "d1b3 b7b6 c4d5 e6d5 e2e3 f8d6 f1d3 f5d3 b3d3 d6f4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: 34,
    MOVES: "d7d5 d2d4 d5e4 c3e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3",
    DEPTH: 48,
  },
  {
    FEN: "8/R1k2q2/3b1P2/3p4/3P1p2/2p4P/2P3P1/7K b - -",
    EVAL: 992,
    MOVES: "c7c8 a7f7 c8d8 f7a7 d8e8 h3h4 f4f3 g2f3 d6g3 h4h5",
    DEPTH: 38,
  },
  {
    FEN: "5rk1/p2R1ppp/1p3q2/2p1p3/2PnPP2/6P1/1P4QP/1K1R4 w - -",
    EVAL: 0,
    MOVES: "f4e5 f6e5 d7a7 e5e6 e4e5 e6c4 g2e4 h7h5 a7a8 f8a8",
    DEPTH: 39,
  },
  {
    FEN: "r5rk/pp1b1n1p/4P3/3q1pp1/3P4/PP1Q1N1P/1B5K/4RR2 b - -",
    EVAL: 713,
    MOVES: "d7e6 e1e6 g8g6 e6e2 h8g8 f3e5 a8e8 e5g6 e8e2 d3e2",
    DEPTH: 28,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p1N1/2B1P3/8/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: -70,
    MOVES: "e8h8 b1c3 c6d4 d2d3 c7c6 c3a4 c5e7 c2c3 d4e6 c4e6",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3P1P2/4P3/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: -28,
    MOVES: "f8e7 g1f3 c7c5 c2c3 g8f6 f1d3 e8h8 e1h1 b7b6 b1d2",
    DEPTH: 39,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: 37,
    MOVES: "f8d6 f4d6 d8d6 d1b3 d6e7 e2e3 g8f6 f1e2 e8h8 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3n4/8/3Q4/5N2/PPP2PPP/RNB1KB1R w KQkq -",
    EVAL: 69,
    MOVES: "d4f4 c8e6 b1c3 b8c6 c1d2 g7g6 e1a1 f8g7 c3b5 d8e7",
    DEPTH: 46,
  },
  {
    FEN: "5q2/1b4pk/1p2p1n1/1PNpPp2/P2P1P1p/rB3R1P/1Q4PK/8 b - -",
    EVAL: 1009,
    MOVES: "b6c5 b2a3 c5d4 a3f8 g6f8 f3d3 g7g5 d3d4 g5f4 a4a5",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/ppp2ppp/2p5/2b5/4P1Bq/3P4/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 190,
    MOVES: "c8g4 d1e1 e8a8 c1e3 c5d6 f2f4 h4e1 f1e1 g4d7 a2a3",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/8/2pp4/3Pn2B/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: -40,
    MOVES: "d8b6 b1c3 b6b4 f1b5 b8c6 b5c6 b7c6 e1h1 e4c3 b2c3",
    DEPTH: 50,
  },
  {
    FEN: "5Q2/pp1b4/3k1pp1/3P4/2p5/q1P2P2/5KP1/8 b - -",
    EVAL: 966,
    MOVES: "d6d5 f8a3 d5e6 a3a7 b7b5 a7a6 e6e7 a6a3 e7e6 a3f8",
    DEPTH: 34,
  },
  {
    FEN: "r2qkbnr/pp1n1ppp/2p1p3/3p1b2/2PP1B2/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 76,
    MOVES: "d1b3 d8b6 c4c5 b6b3 a2b3 f5e4 b3b4 e4f3 g2f3 g7g6",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkb1r/1p1pnppp/p1B1p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "e7c6 d2d4 c5d4 c3d4 d7d5 e4d5 e6d5 c1g5 f7f6 g5f4",
    DEPTH: 32,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q3n2/1BpP4/P7/2N1P3/1P1B1PPP/R2QK1NR b KQkq -",
    EVAL: 69,
    MOVES: "f6d5 g1f3 e7e6 e1h1 d5b4 e3e4 f8e7 d2f4 e8h8 f4b8",
    DEPTH: 53,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R b KQkq -",
    EVAL: 29,
    MOVES: "c6c5 c1e3 c5d4 f3d4 g8e7 e1h1 b8c6 e2b5 a7a6 b5c6",
    DEPTH: 51,
  },
  {
    FEN: "r2qnrk1/3nbppp/3pb3/4pPP1/pp2P3/1N2B3/PPPQN2P/2KR1B1R w - -",
    EVAL: 54,
    MOVES: "f5e6 a4b3 c2b3 f7e6 f1h3 a8a2 h3e6 g8h8 e2g3 e8c7",
    DEPTH: 50,
  },
  {
    FEN: "r2qkbnr/pp1n1ppp/2p1p3/3p1b2/2PP1B2/2N1PN2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 42,
    MOVES: "f8e7 h2h3 g8f6 d1b3 d8c8 a1c1 a7a5 f1e2 d5c4 e2c4",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk1nr/ppp1bppp/4p3/3p4/3P1P2/4P3/PPP3PP/RNBQKBNR w KQkq -",
    EVAL: -28,
    MOVES: "g1f3 g8f6 f1d3 e8h8 e1h1 c7c5 c2c3 b7b6 b2b3 c5d4",
    DEPTH: 33,
  },
  {
    FEN: "r3k2r/ppp2ppp/2p5/2b5/4P1bq/3P4/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 173,
    MOVES: "d1d2 c5d6 f2f4 g7g5 e4e5 d6e7 f4g5 h8g8 d2f4 e8a8",
    DEPTH: 40,
  },
  {
    FEN: "r5rk/pp3n1p/4b3/3q1pp1/3P4/PP1Q1N1P/1B5K/4RR2 w - -",
    EVAL: 731,
    MOVES: "e1e6 a8c8 f3e1 f5f4 e6f6 g8g6 f6f5 d5d7 d4d5 h8g8",
    DEPTH: 28,
  },
  {
    FEN: "5rk1/R4ppp/1p3q2/2p1p3/2PnPP2/6P1/1P4QP/1K1R4 b - -",
    EVAL: -40,
    MOVES: "e5f4 g3f4 f6f4 a7a3 f4e5 h2h4 h7h5 d1e1 e5e6 g2f1",
    DEPTH: 32,
  },
  {
    FEN: "8/q3pp1k/6pP/3Pbb2/1Q6/2N5/P7/K5R1 b - -",
    EVAL: "M-12",
    MOVES: "a7g1 a1b2 g1b1 b2a3 b1d3 a3a4 e5c3 b4c3 d3c3 a4b5",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/1p1p1ppp/p1n1p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "f1e1 d7d5 e4d5 d8d5 d2d4 f8d6 d4c5 d5d1 e1d1 d6c5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/5n2/1B2N3/3nP3/2N5/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: -62,
    MOVES: "d8e7 e5f3 d4b5 c3b5 e7e4 d1e2 e4e2 e1e2 f6d5 d2d3",
    DEPTH: 53,
  },
  {
    FEN: "r2qkb1r/pp1nnppp/2p1p3/3p1b2/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQkq -",
    EVAL: 83,
    MOVES: "d1b3 d8b6 c4c5 b6b3 a2b3 e7g6 f4g3 h7h5 h2h4 a7a6",
    DEPTH: 23,
  },
  {
    FEN: "r5rk/pp3n1p/4R3/3q1pp1/3P4/PP1Q1N1P/1B5K/5R2 b - -",
    EVAL: 699,
    MOVES: "a8c8 f3e1 f5f4 e6f6 f7d6 f6d6 d5d6 d4d5 g8g7 d3f5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 36,
    MOVES: "e4e5 c8f5 c2c3 e7e6 b1d2 d8d7 f2f4 g8h6 f1e2 f5g6",
    DEPTH: 47,
  },
  {
    FEN: "5q2/1b4pk/4p1n1/1PppPp2/P2P1P1p/QB3R1P/6PK/8 b - -",
    EVAL: 567,
    MOVES: "c5d4 a3f8 g6f8 a4a5 f8d7 a5a6 b7a8 b3c2 d7b6 f3b3",
    DEPTH: 29,
  },
  {
    FEN: "r2qnrk1/3nbppp/3pb3/4pPP1/p2NP3/1p2B3/PPPQN2P/2KR1B1R w - -",
    EVAL: 28,
    MOVES: "c1b1 e5d4 e2d4 b3c2 d4c2 e6b3 a2b3 a4b3 c2a3 d7e5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/5n2/1n2N3/4P3/2N5/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: 40,
    MOVES: "c3b5 d7d5 e4d5 a7a6 b5c3 f6d5 d1f3 c8e6 e1h1 d5c3",
    DEPTH: 32,
  },
  {
    FEN: "r3k2r/ppp2ppp/2p5/2b5/4P1bq/3P4/PPP2PPP/RNB1QRK1 b kq -",
    EVAL: 164,
    MOVES: "e8a8 c1e3 c5d6 f2f4 h4e1 f1e1 g4d7 e3d2 f7f6 b1c3",
    DEPTH: 39,
  },
  {
    FEN: "6q1/6p1/2k4p/R6B/p7/8/2P3P1/2K5 w - -",
    EVAL: 1128,
    MOVES: "a5a8 g8a8 h5f3 c6b5 f3a8 b5b4 c1b2 a4a3 b2a2 g7g5",
    DEPTH: 43,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q6/2pp4/3Pn2B/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: -13,
    MOVES: "b1c3 e4c3 b2c3 b6a5 d1d2 b8c6 c3c4 d5c4 f1c4 c5d4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p5/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR b KQkq -",
    EVAL: 37,
    MOVES: "f8c5 d1g4 d8f6 g1f3 g8e7 g4g3 d7d6 d2d4 c5d4 f3d4",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 f8b4 b1d2 b8c6 f1d3 g8f6 e4e5 f6g4 e1h1 e8h8",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/8/3N4/2N5/PPP1PPPP/R1BQKB1R w KQkq -",
    EVAL: 55,
    MOVES: "d4b5 f8b4 e2e4 g8f6 a2a3 b4c3 b5c3 d7d5 e4d5 e6d5",
    DEPTH: 27,
  },
  {
    FEN: "r3kbnr/pp3ppp/2n1p3/3p4/3P1N2/2PB4/PP3PPP/RN1K3R w kq -",
    EVAL: 0,
    MOVES: "d3b5 g8f6 f4d3 a8c8 a2a4 f8d6 a4a5 e8f8 b5c6 c8c6",
    DEPTH: 31,
  },
  {
    FEN: "8/8/8/6K1/8/3N4/6k1/3B4 w - -",
    EVAL: "M8",
    MOVES: "g5h4 g2h2 d1g4 h2g2 g4e2 g2h2 d3f4 h2g1 h4g3 g1h1",
    DEPTH: 97,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q6/2pp4/3Pn2B/2N1PN2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: -37,
    MOVES: "b6b4 f1b5 b8c6 a2a3 b4a5 b5c6 b7c6 e1h1 e4c3 b2c3",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P5/2N1PN1P/PP3PP1/R1BQKB1R b KQ -",
    EVAL: 35,
    MOVES: "e7e6 f1d3 b8a6 e1h1 e6d5 c4d5 a6c7 e3e4 b7b5 a2a3",
    DEPTH: 30,
  },
  {
    FEN: "r1b2b1r/ppk1pppp/2n2n2/4p1N1/2B5/2N5/PP3PPP/R1B1K2R w KQ -",
    EVAL: 406,
    MOVES: "g5f7 c6a5 c4e2 h8g8 f7e5 g7g5 h2h4 h7h6 h4g5 h6g5",
    DEPTH: 28,
  },
  {
    FEN: "r3kbnr/pp2pppp/2n5/3p4/3P1qb1/2PB4/PP2NPPP/RN1QK2R w KQkq -",
    EVAL: -3,
    MOVES: "e2f4 g4d1 e1d1 e7e6 d3b5 a8c8 a2a4 f8d6 f4d3 g8f6",
    DEPTH: 35,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/2ppP3/3P1P2/2PQ4/PP4PP/RNB1K1NR b KQkq -",
    EVAL: -19,
    MOVES: "e7e6 g1f3 g8h6 c1e3 d8b6 b1d2 h6f5 d4c5 f8c5 e3c5",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkb1r/pp2nppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R w KQkq -",
    EVAL: 51,
    MOVES: "c2c3 b8d7 e1h1 h7h6 b1d2 a7a5 a2a4 f5h7 d2b3 e7f5",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 23,
    MOVES: "d5e4 d4d5 c6b8 c3e4 c7c6 f1c4 c6d5 d1d5 d8d5 c4d5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N2P2/PPP3PP/R1BQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "e7e6 c1e3 b7b5 d1d2 b8d7 g2g4 b5b4 c3e2 h7h6 e1a1",
    DEPTH: 51,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 36,
    MOVES: "h2h3 g4f3 d1f3 e7e6 f1e2 f8c5 e1h1 b8d7 e4d5 e6d5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/2p2n2/4p3/2B1P3/5Q2/PPPP1PPP/RNB1K1NR w KQkq -",
    EVAL: -17,
    MOVES: "b1c3 b7b5 c4b3 b5b4 c3a4 d7d6 g1e2 c6c5 a2a3 c8g4",
    DEPTH: 49,
  },
  {
    FEN: "8/8/8/8/6K1/3N4/7k/3B4 w - -",
    EVAL: "M7",
    MOVES: "d1f3 h2g1 g4g3 g1f1 f3d1 f1g1 d1e2 g1h1 d3f2 h1g1",
    DEPTH: 99,
  },
  {
    FEN: "rnbq1rk1/1p2bppp/p2p1n2/4p3/2B1P3/2N1BN2/PPP2PPP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "c8e6 c4b3 b7b5 f3g5 e6b3 c2b3 b8d7 g5f3 b5b4 c3d5",
    DEPTH: 50,
  },
  {
    FEN: "5rk1/R4ppp/1p3q2/2p5/2PnPp2/6P1/1P4QP/1K1R4 w - -",
    EVAL: -30,
    MOVES: "g3f4 f6f4 h2h4 f4h4 e4e5 h7h6 d1f1 d4e6 g2e2 e6g5",
    DEPTH: 26,
  },
  {
    FEN: "3r2k1/pp5p/6p1/2Pp1q2/4N3/4B1K1/PP2Pr2/R1Q1R2B b - -",
    EVAL: 1592,
    MOVES: "f5e5 g3f2 e5h2 f2f3 h2h3 e4g3 d8f8 e3f4 g6g5 h1g2",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 20,
    MOVES: "d4d5 c6b8 c3e4 c7c6 f1c4 c6d5 d1d5 d8d5 c4d5 e7e6",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 12,
    MOVES: "d1c2 e8h8 g1f3 d7d5 a2a3 b4c3 c2c3 b7b6 c1g5 d5c4",
    DEPTH: 54,
  },
  {
    FEN: "2r5/8/4k3/8/2P5/2K5/8/3R4 w - -",
    EVAL: 0,
    MOVES: "d1d4 c8b8 c4c5 e6e7 c3c4 b8d8 d4h4 e7d7 h4h7 d7c6",
    DEPTH: 75,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 g1f3 g8f6 g2g3 f8c5 f1g2 d7d6 e1h1 a7a5 h2h3",
    DEPTH: 57,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/1b6/1P2N3/2BnP3/8/P1PP1PPP/RNBQK2R b KQkq -",
    EVAL: -400,
    MOVES: "d7d5 e1h1 d5c4 e5c4 d4b5 c1b2 g8e7 c4b6 a7b6 f2f4",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkb1r/1pp2ppp/p1p2n2/4N3/4P3/2N5/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 56,
    MOVES: "f6e4 c3e4 d8d5 e1h1 d5e5 d2d4 e5d5 e4g5 c8e6 g5e6",
    DEPTH: 56,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2B2n2/2b1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7c6 f3e5 c5f2 e1f2 d8d4 f2f1 d4e5 d2d3 e5e7 d1f3",
    DEPTH: 49,
  },
  {
    FEN: "5rk1/R4ppp/1p3q2/2p5/2PnPP2/8/1P4QP/1K1R4 b - -",
    EVAL: 0,
    MOVES: "f6f4 a7a6 f8b8 d1f1 f4e5 f1e1 e5e6 e4e5 g7g6 g2d5",
    DEPTH: 52,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b4p/4P1n1/3P3P/PPP1BPP1/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "e4e5 g4e5 d1d2 c8f5 b1c3 d6g6 d2g5 g6g5 c1g5 f7f6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "f2f4 b8c6 f4e5 c6e5 g1f3 e5f3 d1f3 c7c6 b1c3 f8c5",
    DEPTH: 43,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q6/1Bpn4/P7/2N1P3/1P1B1PPP/R2QK1NR w KQkq -",
    EVAL: 106,
    MOVES: "g1f3 e7e6 e1h1 f8e7 e3e4 d5b4 d2f4 e8h8 f4b8 f8b8",
    DEPTH: 34,
  },
  {
    FEN: "rn3rk1/1pq1bppp/p2pbn2/4p3/4P3/1BN1BN2/PPP2PPP/R2Q1RK1 w - -",
    EVAL: 34,
    MOVES: "f3h4 c7d7 d1d3 b8c6 f1d1 a8c8 h2h3 c6b4 d3d2 b4c6",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/ppp3pp/4p3/5pN1/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 65,
    MOVES: "g5e6 c8e6 c4e6 d8f6 e6c8 b8c6 c8b7 a8b8 b7c6 f6c6",
    DEPTH: 47,
  },
  {
    FEN: "4b2r/4k1r1/4pP1n/pp1p3P/2pP1R2/P1P2B1N/2PK1P2/8 b - -",
    EVAL: 60,
    MOVES: "e7f8 f6g7 f8g7 h3g1 e8f7 f4h4 h8f8 h4h2 g7f6 h2h1",
    DEPTH: 41,
  },
  {
    FEN: "r1b1k1nr/pppp1ppp/1b6/1P2N1q1/2BnP3/8/P1PP1PPP/RNBQK2R w KQkq -",
    EVAL: -260,
    MOVES: "c4f7 e8f8 e1h1 g5e5 f7g8 f8g8 c2c3 d4e6 f1e1 d7d6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -15,
    MOVES: "b8c6 g2g3 d7d5 e4d5 f6d5 f1g2 f8d6 e1h1 e8h8 f1e1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/pppp1ppp/8/8/1bB1P3/8/PB3PPP/RN1QK1NR w KQkq -",
    EVAL: -41,
    MOVES: "b1c3 g8f6 d1b3 b4c3 b3c3 e8h8 g1f3 d7d6 e1h1 b8d7",
    DEPTH: 49,
  },
  {
    FEN: "rn1qkbnr/ppp2ppp/4p3/3p1b2/2P5/1Q2PN2/PP1P1PPP/RNB1KB1R b KQkq -",
    EVAL: 73,
    MOVES: "b7b6 c4d5 d8d5 f1c4 d5b7 b1c3 b8d7 d2d4 g8f6 e1h1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3P4/4p3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 18,
    MOVES: "c6b8 c3e4 c7c6 f1c4 c6d5 d1d5 d8d5 c4d5 e7e6 d5c4",
    DEPTH: 40,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b4p/4P1P1/3P4/PPP1BPP1/RNBQ1RK1 b kq -",
    EVAL: "M-5",
    MOVES: "h5g4 e4e5 d6e5 c1h6 h8h6 f1e1 e5h2 g1f1 h2h1",
    DEPTH: 99,
  },
  {
    FEN: "5rk1/R4ppp/1p6/2p5/2PnPq2/8/1P4QP/1K1R4 w - -",
    EVAL: -71,
    MOVES: "h2h4 f4e5 a7a3 h7h5 d1e1 e5e6 a3c3 f8e8 e4e5 e6f5",
    DEPTH: 27,
  },
  {
    FEN: "rnbqk2r/pp1pbppp/2p2n2/8/2B1Pp2/2NP4/PPP3PP/R1BQK1NR w KQkq -",
    EVAL: 26,
    MOVES: "e4e5 f6d5 c4d5 c6d5 c1f4 d7d6 e5d6 e7d6 d1e2 c8e6",
    DEPTH: 25,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b5/4P1p1/3P4/PPP1BPP1/RNBQ1RK1 w kq -",
    EVAL: "M-4",
    MOVES: "e4e5 d6e5 c1h6 h8h6 f1e1 e5h2 g1f1 h2h1",
    DEPTH: 245,
  },
  {
    FEN: "2RK4/3P1k2/8/8/8/8/8/2r5 b - -",
    EVAL: "M20",
    MOVES: "c1a1 c8c6 a1a8 d8c7 f7e7 c6a6 a8a6 d7d8q e7f7 d8d7",
    DEPTH: 53,
  },
  {
    FEN: "8/Q3pp1k/6pP/3Pbb2/8/2N5/P7/K5R1 b - -",
    EVAL: "M-1",
    MOVES: "e5c3",
    DEPTH: 99,
  },
  {
    FEN: "rk5r/ppp1b3/2n3p1/5QB1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: 509,
    MOVES: "f5g6 e7g5 g6g5 b7b6 f2f4 b8b7 f4f5 a8d8 g5f4 c6b4",
    DEPTH: 24,
  },
  {
    FEN: "5rk1/R4ppp/1p6/2p5/2PnPq2/8/1P4QP/1K2R3 b - -",
    EVAL: -44,
    MOVES: "f4e5 a7a6 e5e6 g2f1 f8d8 a6a3 h7h6 f1d3 d4c6 d3e2",
    DEPTH: 26,
  },
  {
    FEN: "2RK4/3P1k2/8/8/8/8/8/3r4 w - -",
    EVAL: "M21",
    MOVES: "d8c7 d1c1 c7b6 c1b1 b6c5 b1c1 c5b4 c1b1 b4c3 b1d1",
    DEPTH: 38,
  },
  {
    FEN: "rn3rk1/1pq1bppp/p2pbn2/4p3/4P2N/1BN1B3/PPP2PPP/R2Q1RK1 b - -",
    EVAL: 36,
    MOVES: "g7g6 e3h6 f8c8 d1d2 f6g4 h6g5 g4f6 g5f6 e7f6 h4f3",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkbnr/ppp3pp/4N3/5p2/2B1p3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 51,
    MOVES: "c8e6 c4e6 f8c5 d2d4 d8d4 d1d4 c5d4 e6f5 g8f6 c2c3",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: 24,
    MOVES: "a7a6 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 f1e2 e7e5 d4b3",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/8/3N1B2/2N5/PPP1PPPP/R2QKB1R b KQkq -",
    EVAL: 55,
    MOVES: "a7a6 e2e4 d7d6 d4c6 b7c6 f1c4 d6d5 c4d3 g8f6 e1h1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk1nr/pp1pppbp/6p1/2p5/2B1P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 e1h1 d7d6 f1e1 a7a6 a2a4 e7e6 d2d3 d8c7 h2h3",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkbnr/ppp3pp/4b3/5p2/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 64,
    MOVES: "c4e6 g7g6 c2c3 b8d7 d2d4 e4d3 e1h1 d7c5 e6c4 g8f6",
    DEPTH: 31,
  },
  {
    FEN: "r1b1k2r/ppp2pp1/2pq4/2b1P3/6p1/3P4/PPP1BPP1/RNBQ1RK1 b kq -",
    EVAL: "M-4",
    MOVES: "d6e5 c1h6 h8h6 f1e1 e5h2 g1f1 h2h1",
    DEPTH: 245,
  },
  {
    FEN: "rn1qkbnr/ppp3pp/4B3/5p2/4p3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 66,
    MOVES: "d8f6 e6c8 b8c6 c8b7 a8b8 b7c6 f6c6 e1h1 f8d6 d2d3",
    DEPTH: 31,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/8/1p1np1q1/3P4/8/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 92,
    MOVES: "f1b5 e8d8 e1h1 c8b7 d1f3 a8b8 d4e5 d5e3 f3h3 g5g2",
    DEPTH: 36,
  },
  {
    FEN: "5rk1/R4ppp/1p6/2p5/2P1Pq2/5n2/1P4QP/1K2R3 w - -",
    EVAL: -78,
    MOVES: "e1d1 f4e4 b1a1 g7g5 h2h3 f8a8 a7a8 e4a8 a1b1 a8e4",
    DEPTH: 27,
  },
  {
    FEN: "4b2r/6k1/4p2n/pp1p3P/2pP1R2/P1P2B1N/2PK1P2/8 w - -",
    EVAL: 30,
    MOVES: "h3g1 h6g8 g1h3 g8h6 d2e1 h6f5 f3d5 g7f6 d5f3 e8h5",
    DEPTH: 59,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2p2n2/2b4p/4P3/3P4/PPP1BPPP/RNBQ1RK1 b kq -",
    EVAL: 60,
    MOVES: "f6g4 e2g4 h5g4 c1f4 c8e6 b1d2 d8d7 d1e2 e8a8 f1b1",
    DEPTH: 50,
  },
  {
    FEN: "rn2kbnr/ppp3pp/4Bq2/5p2/4p3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 61,
    MOVES: "e6c8 b8c6 c8b7 a8b8 b7c6 f6c6 e1h1 g8f6 d2d3 f8c5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq -",
    EVAL: 23,
    MOVES: "d4c5 e8h8 a2a3 b4c5 g1f3 d7d5 c1g5 c5e7 e2e3 b8c6",
    DEPTH: 52,
  },
  {
    FEN: "rnB1kbnr/ppp3pp/5q2/5p2/4p3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 59,
    MOVES: "b8d7 c8b7 a8b8 b7d5 f6g6 e1h1 g8f6 b1c3 f8d6 d2d3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2p5/2b4p/4P1n1/3P4/PPP1BPPP/RNBQ1RK1 w kq -",
    EVAL: 75,
    MOVES: "e2g4 h5g4 c1f4 h8h5 d1d2 c8e6 b1c3 c5d6 a1e1 d6f4",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq -",
    EVAL: -6,
    MOVES: "g7g6 g1e2 f8g7 d2d3 g8f6 f1g2 d7d6 e1h1 e8h8 c3d5",
    DEPTH: 58,
  },
  {
    FEN: "8/1b1Q1ppk/p2bp2p/1p1q4/3Pp3/1P2B1P1/P6P/2R3K1 b - -",
    EVAL: -994,
    MOVES: "d6f4 c1c7 f4e3 g1g2 d5d7 c7d7 b7d5 d7f7 h7g6 f7d7",
    DEPTH: 47,
  },
  {
    FEN: "5rk1/R4ppp/1p6/2p5/2P1Pq2/5n2/1P2R1QP/1K6 b - -",
    EVAL: -89,
    MOVES: "f3e5 e2e1 f4f6 g2e2 g7g6 e1f1 f6e6 f1d1 e5c4 a7d7",
    DEPTH: 27,
  },
  {
    FEN: "r1B1kbnr/ppp3pp/2n2q2/5p2/4p3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 44,
    MOVES: "c8b7 a8b8 b7c6 f6c6 e1h1 g8f6 d2d3 f8c5 b1d2 e8h8",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/4pP2/2B5/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e5e4 f3d4 g8f6 d4c6 b7c6 d2d3 d7d5 c4b3 c8f5 e1h1",
    DEPTH: 57,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/5P2/2B1p3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -2,
    MOVES: "f3d4 g8f6 d4c6 b7c6 c4b3 d7d5 d2d3 c8f5 e1h1 f8c5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR b KQkq -",
    EVAL: 4,
    MOVES: "g8f6 e4e5 f6d5 d2d4 c5d4 g1f3 b8c6 c3d4 d7d6 f1c4",
    DEPTH: 57,
  },
  {
    FEN: "r2qnrk1/3nbppp/3pb3/5PP1/p2NP3/1p2B3/PPPQ3P/1K1R1B1R b - -",
    EVAL: 16,
    MOVES: "b3c2 d4c2 e6b3 a2b3 a4b3 c2a3 d7e5 h2h4 a8a4 d2g2",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 b kq -",
    EVAL: 16,
    MOVES: "e8h8 a2a4 b5b4 a4a5 d7d6 c2c3 a8b8 b3c4 f8e8 d2d4",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3P4/8/2N2p2/PPP3PP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 g8f6 f1c4 e7e5 d1e2 f8d6 c1g5 e8h8 e1a1 a7a6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/5P2/2BNp3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -8,
    MOVES: "g8f6 d4c6 b7c6 c4b3 d7d5 d2d3 c8f5 e1h1 f8c5 c1e3",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkbnr/pp2pp1p/2np2p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR w KQkq -",
    EVAL: -4,
    MOVES: "g1e2 f8g7 e1h1 e7e5 c3d5 g8e7 d5e7 c6e7 d2d3 c8e6",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 38,
    MOVES: "f3d4 g8f6 b1c3 e7e6 d4c6 b7c6 e4e5 f6d5 c3e4 d8c7",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkb1r/pppp2pp/2n2n2/5P2/2BNp3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d4c6 b7c6 c4b3 d7d5 d2d3 c8f5 e1h1 f8c5 c1e3 c5d6",
    DEPTH: 42,
  },
  {
    FEN: "r3k2r/2q1np1p/p3P1p1/1p2p3/8/2PBB3/P1PR2PP/5RK1 w kq -",
    EVAL: "M20",
    MOVES: "e6f7 e8d7 d3f5 d7c6 f5e4 e7d5 e4d5 c6d7 d5a8 d7e6",
    DEPTH: 78,
  },
  {
    FEN: "r1b1k2r/pp1n1pb1/2p1pqpp/8/2BPP3/2N2N2/PP3PPP/R2Q1RK1 b kq -",
    EVAL: 0,
    MOVES: "e8h8 e4e5 f6e7 f1e1 b7b5 c4d3 c8b7 d3e4 f8d8 d1e2",
    DEPTH: 43,
  },
  {
    FEN: "r2k1b1r/pp2pppp/4bn2/nB2p1N1/8/2N5/PP3PPP/R1B1K2R w KQ -",
    EVAL: 76,
    MOVES: "g5e6 f7e6 c1e3 a5c6 b5c6 b7c6 a1c1 d8e8 b2b3 g7g6",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 49,
    MOVES: "c2c4 g8f6 b1c3 d7d6 f1e2 c6d4 d1d4 f8g7 c1e3 c8e6",
    DEPTH: 57,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f1g2 d7d5 c2c4 c7c6 c4d5 c6d5 b1c3 e8h8 f3e5 f6e4",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkbnr/pp2pp1p/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "f8g7 g1e2 e7e6 c1e3 g8e7 d3d4 d8b6 d4c5 d6c5 a1b1",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/1pp2ppp/p4n2/3p4/3P4/2NB1N2/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 37,
    MOVES: "f8d6 e1h1 e8h8 c1g5 c8e6 f1e1 c7c5 d4c5 d6c5 g5h4",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 21,
    MOVES: "c8f5 e4g3 f5g6 g1f3 b8d7 h2h4 h7h6 h4h5 g6h7 f1d3",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/p1p2ppp/4pn2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 33,
    MOVES: "e4e5 f6d7 c3b5 d7b6 b5c3 c8b7 c1e3 b8c6 f1e2 f8e7",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1e2 e7e6 e1h1 g8e7 c1g5 a8b8 d1d2 h7h6 g5e3 b7b5",
    DEPTH: 54,
  },
  {
    FEN: "5rk1/R4ppp/1p6/2p5/2PnPq2/8/1P2R1QP/1K6 w - -",
    EVAL: -70,
    MOVES: "e2f2 f4e3 a7a3 e3e1 b1a2 d4c6 g2f3 c6b4 a2b3 h7h6",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkbnr/p4ppp/2p1p3/2pp4/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 26,
    MOVES: "f1e1 g8e7 b2b3 e7g6 b1c3 f7f6 c1a3 e6e5 c3a4 c5c4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2PP1N2/PP2BPPP/RNBQK2R b KQkq -",
    EVAL: 42,
    MOVES: "d7d6 d3d4 c5b6 d4e5 f6g4 h1f1 g4e5 f3e5 d6e5 d1d8",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/2n5/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 169,
    MOVES: "d2d4 e5d4 e4e5 d7d6 e5d6 c7d6 e1h1 d6d5 c4b3 g8f6",
    DEPTH: 41,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/2n2n2/4p1N1/2B5/2N5/PP3PPP/R1B1K2R w KQ -",
    EVAL: 179,
    MOVES: "c4f7 e8d7 e1h1 e7e6 c1e3 d7c7 a1d1 h7h6 g5e6 c8e6",
    DEPTH: 27,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR b KQkq -",
    EVAL: -21,
    MOVES: "a8b8 a2a4 e7e6 g1f3 g8e7 h2h4 h7h5 e1h1 a7a6 f3g5",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 38,
    MOVES: "e4g5 h7h6 g5e6 d8b6 e6f8 d7f8 c2c3 g8f6 f1d3 f8e6",
    DEPTH: 53,
  },
  {
    FEN: "r1b1k2r/pp3pb1/1np2qpp/3Pp3/4P3/1BN2N2/PP3PPP/R2Q1RK1 b kq -",
    EVAL: 4,
    MOVES: "c8g4 h2h3 g4f3 d1f3 f6f3 g2f3 e8h8 d5c6 b7c6 c3a4",
    DEPTH: 46,
  },
  {
    FEN: "r1k2b1r/pppN3p/2n5/6B1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: -738,
    MOVES: "d7f8 h8f8 a1d1 c8d7 g5e3 a8d8 c2c3 d7c8 c3d4 d8d5",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/4p3/2BnP3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 161,
    MOVES: "f3d4 e5d4 e1h1 c7c6 f1e1 d7d6 c2c3 f8e7 c3d4 d6d5",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 42,
    MOVES: "e8h8 c4b3 f8e8 f2f3 d7d5 e4d5 c6b4 d4e2 e7e6 d5d6",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2n2n2/2bpP3/2Bp4/2P2N2/PP3PPP/RNBQK2R w KQkq d6",
    EVAL: 8,
    MOVES: "c4b5 f6e4 c3d4 c5b6 c1e3 e8h8 b1c3 c8g4 h2h3 g4h5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/4N3/2BnP3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -53,
    MOVES: "d8g5 e1h1 g5e5 c2c3 d4e6 d2d4 e5e4 f1e1 e4h4 g2g3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR w KQkq -",
    EVAL: -16,
    MOVES: "g1f3 g8e7 h2h4 h7h5 e1h1 a8b8 a2a4 a7a6 f3g5 b7b5",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 23,
    MOVES: "g8e7 f1e1 e7g6 c2c3 f8e7 d2d4 c5d4 c3d4 d7d5 e4d5",
    DEPTH: 53,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 35,
    MOVES: "e1h1 e8h8 h2h3 d7d6 c3d5 a5d8 d5f6 g7f6 c2c3 c8d7",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/8/6b1/5NN1/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 40,
    MOVES: "f1c4 e7e6 c2c3 g8f6 h2h3 g4f3 d1f3 f8d6 d2d4 b8d7",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/pp1npppp/2p2n2/8/3PN3/2P5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 58,
    MOVES: "e4f6 e7f6 f1d3 f8d6 g1e2 e8h8 d1c2 h7h6 c1e3 f8e8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 15,
    MOVES: "e4e5 f6d5 g1f3 b8c6 d2d4 c5d4 c3d4 d7d6 f1c4 d5b6",
    DEPTH: 52,
  },
  {
    FEN: "rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR w KQ -",
    EVAL: 27,
    MOVES: "a2a3 b4c5 g1f3 b8c6 e2e3 d7d5 b2b4 c5d6 c1b2 d8e7",
    DEPTH: 47,
  },
  {
    FEN: "r1b1kbnr/pppp1ppp/8/4N1q1/2BnP3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -71,
    MOVES: "c4f7 e8d8 e1h1 g5e5 d2d3 g8h6 f7d5 c7c6 c2c3 d4f3",
    DEPTH: 47,
  },
  {
    FEN: "r5k1/1b2qpbp/3p4/1B2p3/1N6/2P2P2/1P4PP/3QK2R b K -",
    EVAL: -209,
    MOVES: "d6d5 b5e2 e7c5 h2h4 h7h6 e1f1 e5e4 h1h3 c5b6 h3g3",
    DEPTH: 34,
  },
  {
    FEN: "r3k2r/pp3pb1/1np2qpp/3Pp3/4P1b1/1BN2N2/PP3PPP/2RQ1RK1 b kq -",
    EVAL: 7,
    MOVES: "e8h8 h2h3 g4f3 d1f3 f6f3 g2f3 a8c8 d5c6 b7c6 c3a4",
    DEPTH: 52,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/3B4/PP2NPPP/RNBQ1RK1 w - -",
    EVAL: 55,
    MOVES: "b1c3 e7e5 d4d5 c6d4 c1g5 h7h6 g5e3 f6g4 e3d2 g4f6",
    DEPTH: 25,
  },
  {
    FEN: "r1b1kbnr/pppp1ppp/8/4N1q1/2BnP3/2P5/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: -487,
    MOVES: "g5g2 c3d4 g2h1 c4f1 h1e4 d1e2 e4e2 f1e2 d7d6 e5g4",
    DEPTH: 24,
  },
  {
    FEN: "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP2P1/PPP3BP/R1BQK1NR w KQkq -",
    EVAL: 10,
    MOVES: "g1f3 g8e7 e1h1 e5f4 c1f4 e8h8 d1d2 c8g4 h2h3 g4f3",
    DEPTH: 51,
  },
  {
    FEN: "r4rk1/pp3pb1/1np2qpp/3Pp3/4P1b1/1BN2N2/PP3PPP/2RQ1RK1 w - -",
    EVAL: 0,
    MOVES: "h2h3 g4f3 d1f3 f6f3 g2f3 f8c8 d5c6 b7c6 c3a4 g7f8",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2p2n2/8/3P4/2P5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 64,
    MOVES: "g1f3 c8g4 h2h3 g4f3 d1f3 d8d5 f1e2 e7e6 h3h4 h7h5",
    DEPTH: 35,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQ -",
    EVAL: 56,
    MOVES: "c1e3 d7d6 f1e2 b8a6 h2h4 h7h5 g1f3 f6g4 e3g5 c7c6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 g2g3 d7d5 d1e2 f8e7 f1g2 g8f6 a2a4 e8h8 e1h1",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR b KQkq -",
    EVAL: 9,
    MOVES: "f6d5 f1c4 d5b6 c4b3 d7d6 e5d6 d8d6 b1a3 e7e5 g1f3",
    DEPTH: 54,
  },
  {
    FEN: "r1b1kb1r/pp3ppp/2n1pn2/3q4/3P4/5N2/PP2BPPP/RNBQK2R w KQkq -",
    EVAL: 4,
    MOVES: "e1h1 d5d6 b1a3 d6d8 a3c4 f8e7 c4e5 c8d7 d1b3 c6a5",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2p2n2/8/3P4/2P2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 47,
    MOVES: "c8g4 h2h3 g4f3 d1f3 d8d5 f1e2 e7e6 f3g3 e8a8 a2a4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 83,
    MOVES: "f2f4 f8g7 f1b5 f6d7 a2a4 b8a6 g1f3 a6b4 e1h1 a7a6",
    DEPTH: 45,
  },
  {
    FEN: "5k2/3q2pp/8/4N3/8/8/6PP/5RK1 b - -",
    EVAL: "M18",
    MOVES: "f8e7 f1f7 e7e6 f7d7 e6f6 d7f7 f6e5 f7g7 h7h6 g7h7",
    DEPTH: 61,
  },
  {
    FEN: "rnbqk1nr/pp1pppbp/6p1/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -",
    EVAL: 23,
    MOVES: "g2g3 b8c6 f1g2 e7e6 d2d4 c5d4 c3b5 d7d5 c4d5 e6d5",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 63,
    MOVES: "b1c3 e7e5 g1f3 b8d7 f1c4 f8e7 e1h1 e8h8 f1e1 c7c6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/p1p2ppp/1n2p3/1N2P3/2pP4/5N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 41,
    MOVES: "f1e2 b8c6 e1h1 f8e7 d1d2 e8h8 d2f4 c6b4 f1d1 g8h8",
    DEPTH: 44,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2n2n2/1BbpP3/3p4/2P2N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f6e4 c3d4 c5b6 b1c3 e8h8 c1e3 c8g4 h2h3 g4h5 d1c2",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP2PN/PPP3BP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "g8e7 f4f5 g6f5 d1h5 h7h6 e1h1 c6d4 f1f2 c8e6 c1e3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/4P3/2PP1N2/PP2BPPP/RNBQK2R w KQkq -",
    EVAL: 73,
    MOVES: "b2b4 c5b6 b4b5 c6e7 f3e5 d7d5 b1d2 d5e4 d3e4 e7g6",
    DEPTH: 24,
  },
  {
    FEN: "r1b1kbnr/pppp1Bpp/8/4N1q1/3nP3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -88,
    MOVES: "e8d8 e1h1 g5e5 c2c3 d4e6 d2d3 g7g6 f2f4 e5f6 f7e6",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 11,
    MOVES: "d2d4 c5d4 g1f3 d7d6 c3d4 b8c6 f1c4 d5b6 c4b5 d6e5",
    DEPTH: 53,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 63,
    MOVES: "c1e3 d8b6 d4b3 b6d8 d1d2 g8f6 b1c3 d7d6 f1e2 f6g4",
    DEPTH: 42,
  },
  {
    FEN: "r1bk1bnr/pppp1Bpp/8/4N1q1/3nP3/8/PPPP1PPP/RNBQK2R w KQ -",
    EVAL: -76,
    MOVES: "e1h1 g5e5 c2c3 d4e6 d2d3 g7g5 f7e6 d7e6 f2f4 e5g7",
    DEPTH: 53,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2n5/1BbpP3/3pn3/2P2N2/PP3PPP/RNBQK2R w KQkq -",
    EVAL: 15,
    MOVES: "c3d4 c5b6 c1e3 e8h8 b1c3 c8g4 h2h3 g4h5 d1c2 e4c3",
    DEPTH: 56,
  },
  {
    FEN: "r1bk1bnr/pppp1Bpp/8/4N1q1/3nP3/8/PPPP1PPP/RNBQ1RK1 b - -",
    EVAL: -47,
    MOVES: "g5e5 c2c3 d4e6 d2d3 g7g5 f7e6 d7e6 b1d2 e5g7 d2c4",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 53,
    MOVES: "b1c3 d7d6 f1e2 e8h8 e1h1 a7a6 h2h3 c6d4 e3d4 c8d7",
    DEPTH: 51,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n2n2/2b1p1N1/2B1P3/2N5/PPPP1PPP/R1BQK2R w KQ -",
    EVAL: -43,
    MOVES: "d2d3 h7h6 g5f3 d7d6 e1h1 c6a5 c4b3 a5b3 a2b3 a7a5",
    DEPTH: 48,
  },
  {
    FEN: "Nk5r/ppp4p/2n5/5Qb1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: 832,
    MOVES: "a8c7 b8c7 f5g5 h8e8 g5f5 e8e7 h2h4 a7a6 g2g4 c7b6",
    DEPTH: 30,
  },
  {
    FEN: "r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 67,
    MOVES: "f6g4 d1g4 c6d4 g4d1 e7e5 f1d3 d7d6 e1h1 e8h8 d1d2",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/8/2pnP3/8/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d7d6 d2d4 c5d4 c3d4 d6e5 d4e5 b8c6 f1c4 c8e6 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n2n2/2b1p3/4P3/2PP1N2/PP2BPPP/RNBQ1RK1 w - -",
    EVAL: 92,
    MOVES: "b2b4 c5b6 b4b5 c6e7 f3e5 d7d5 e4d5 f6d5 d3d4 f8e8",
    DEPTH: 23,
  },
  {
    FEN: "r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R w KQ -",
    EVAL: 89,
    MOVES: "f1e2 c6d4 e3d4 d7d6 e1h1 c8d7 d1c2 d7c6 b2b4 b7b6",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/P1N5/1PQ1PPPP/R1B1KBNR w KQ -",
    EVAL: 16,
    MOVES: "c1f4 e6e5 f4g3 c5d4 e2e3 d4c3 c2c3 d7d6 a1d1 c8e6",
    DEPTH: 40,
  },
  {
    FEN: "r1bk1bnr/pppp1Bpp/8/4q3/3nP3/2P5/PP1P1PPP/RNBQ1RK1 b - -",
    EVAL: -97,
    MOVES: "d4e6 d2d3 g7g5 b1d2 e6f4 f7b3 e5g7 d2c4 f8e7 c4e3",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p3p3/2pp4/P3P3/2N2N2/1PPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "e4d5 e6d5 d2d4 g8f6 c1e3 c5c4 f1e2 f8b4 e1h1 b4c3",
    DEPTH: 39,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R w KQ -",
    EVAL: 75,
    MOVES: "e1h1 a7a6 d1d2 c8d7 f2f3 c6d4 e3d4 a8c8 a1d1 d8e8",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/3p1n2/4p3/3PP3/3B4/PPP1NPPP/RNBQK2R w KQkq -",
    EVAL: 52,
    MOVES: "c2c4 e8h8 b1c3 b8c6 d4d5 c6b4 d3b1 a7a5 e2g3 b4a6",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR b KQkq -",
    EVAL: 74,
    MOVES: "f6d5 c2c4 d5b6 d2d4 d7d6 f2f4 c8f5 b1c3 e7e6 g1f3",
    DEPTH: 50,
  },
  {
    FEN: "Nk3r2/ppp4p/2n5/6b1/3p4/8/PPP2PPP/R5K1 w - -",
    EVAL: -766,
    MOVES: "a8c7 b8c7 g2g3 f8f5 a2a3 f5c5 c2c3 d4c3 b2c3 c5c3",
    DEPTH: 21,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p7/2pp4/P7/2N2N2/1PPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1e2 b8c6 e1h1 g8f6 d2d4 h7h6 f1e1 c8e6 e2d3 f8d6",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/3P1N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "g2g3 d7d5 d1e2 f8e7 f1g2 g8f6 a2a4 e8h8 e1h1 a7a6",
    DEPTH: 42,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1 b - -",
    EVAL: 76,
    MOVES: "c8d7 f1e1 c6d4 e3d4 d7c6 d1d3 a7a5 b2b3 f6d7 d4g7",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pp1ppppp/2n5/2pnP3/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 f1c4 d5b6 c4b3 d7d5 e5d6 d8d6 e1h1 c8e6 f3d4",
    DEPTH: 52,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/2N5/PP1QPPPP/R3KBNR b KQkq -",
    EVAL: -12,
    MOVES: "c8f5 e2e3 e7e6 g1e2 b8c6 e2g3 f5g6 a1c1 a8c8 a2a3",
    DEPTH: 34,
  },
  {
    FEN: "rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR w KQkq -",
    EVAL: 59,
    MOVES: "h2h3 e8h8 g1e2 b8d7 e2g3 a7a6 a2a4 a8b8 e1h1 h7h5",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 11,
    MOVES: "e4d6 b5c6 d7c6 d4e5 d6f5 d1d8 e8d8 h2h3 h7h5 b1c3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/3P1NP1/PPP2P1P/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 d1e2 g8f6 f1g2 f8e7 e1h1 b7b5 e4d5 e6d5 d3d4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/1p3ppp/p7/2pp4/P2P4/2N2N2/1PP2PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 c1e3 c5c4 f1e2 b8c6 e1h1 d8a5 f3e5 f8b4 e3g5",
    DEPTH: 39,
  },
  {
    FEN: "r1bq1rk1/pp1p1ppp/2n1pn2/2b5/2P5/P1N2N2/1PQ1PPPP/R1B1KB1R w KQ -",
    EVAL: 23,
    MOVES: "e2e3 a7a5 b2b3 d7d5 c1b2 d5d4 e3d4 c6d4 f3d4 c5d4",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/8/2BpP3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 169,
    MOVES: "e1h1 c7c6 f1e1 d7d6 c2c3 f8e7 d2d3 d4c3 d1b3 g8h6",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2pp4/4P3/3P1NP1/PPP2P1P/RNBQKB1R w KQkq -",
    EVAL: 6,
    MOVES: "d1e2 g8f6 f1g2 f8e7 e1h1 b7b5 e4d5 e6d5 d3d4 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 85,
    MOVES: "g1f3 e7e6 f1e2 e6d5 e4d5 e8h8 e1h1 c8g4 c1f4 f6e8",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np4/2pnP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 31,
    MOVES: "e5d6 c5d4 f3d4 d8d6 f1e2 e7e6 d4c6 d6c6 e1h1 c6c7",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p4n2/2pp4/P2P4/2N2N2/1PP2PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1e2 b8c6 e1h1 c8e6 f1e1 h7h6 e2d3 f8d6 d3f5 e8h8",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1rk1/1pp3bp/p2p2p1/2nPpp1n/2P1P3/2N1BP2/PPBQ2PP/1K1R2NR b - -",
    EVAL: 35,
    MOVES: "b7b5 e3c5 d6c5 g1e2 b5c4 e2g3 h5f4 g3f1 f5e4 c3e4",
    DEPTH: 35,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/2n2n2/1p1qp1N1/8/2N5/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 78,
    MOVES: "d5c5 f1b5 f8e7 b5c6 c5c6 d1f3 c8b7 f3c6 b7c6 e1h1",
    DEPTH: 40,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/1qn2n2/3p4/3P1B2/2N1P3/PP1Q1PPP/R3KBNR b KQkq -",
    EVAL: 0,
    MOVES: "c8f5 f2f3 e7e6 a1c1 f8b4 a2a3 b4c3 c1c3 e8h8 g1e2",
    DEPTH: 28,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q3n2/1BpP4/8/2N1PN2/PP1B1PPP/R2QK2R b KQkq -",
    EVAL: 46,
    MOVES: "f6d5 a2a4 e7e6 e1h1 d5b4 e3e4 f8e7 d2f4 e8h8 f4b8",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/8/2BpP3/8/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 116,
    MOVES: "g8f6 d2d3 c7c6 d1e2 f8c5 e4e5 d8e7 f1e1 f6d5 c4d5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2pp4/4P3/3P1NP1/PPP1QP1P/RNB1KB1R b KQkq -",
    EVAL: 11,
    MOVES: "f8e7 f1g2 g8f6 e1h1 e8h8 a2a4 h7h6 c1f4 c8d7 f1e1",
    DEPTH: 37,
  },
  {
    FEN: "r2qkbnr/ppp1pppp/2n5/1B1p1b2/3P1B2/4P3/PPP2PPP/RN1QK1NR b KQkq -",
    EVAL: 25,
    MOVES: "e7e6 c2c4 f8b4 b1c3 b4c3 b2c3 g8f6 g1e2 e8h8 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p4n2/2pp4/P2P4/2N2N2/1PP1BPPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 e1h1 h7h6 f1e1 c8e6 e2d3 c5d4 c3e2 f8c5 e2f4",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 22,
    MOVES: "b5c6 d7c6 d4e5 d6f5 d1d8 e8d8 f1d1 d8e8 b1c3 f5e7",
    DEPTH: 48,
  },
  {
    FEN: "r3kb1r/pp2pppp/1qn2n2/3p1b2/3P1B2/2N1P3/PP1Q1PPP/R3KBNR w KQkq -",
    EVAL: 25,
    MOVES: "f2f3 e7e6 a1c1 a8c8 g2g4 f5g6 h2h4 h7h6 g1e2 f6d7",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/4P3/3P1NP1/PPP1QP1P/RNB1KB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1g2 f8e7 e1h1 e8h8 a2a4 h7h6 c1f4 a7a6 c2c3 f6h5",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 53,
    MOVES: "d7d6 e2e4 g7g6 f1e2 e6d5 e4d5 f8g7 g1f3 c8g4 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -",
    EVAL: 26,
    MOVES: "e2e4 e7e5 d2d4 e5d4 f3d4 b8c6 d4c6 b7c6 b1c3 a8b8",
    DEPTH: 41,
  },
  {
    FEN: "r3k2r/2q2p1p/p1n1P1p1/1B2p3/8/2P1B3/P1PR2PP/5RK1 w kq -",
    EVAL: 0,
    MOVES: "e6f7 e8f8 e3h6 f8e7 h6g5 e7f8 g5h6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/5n2/8/2BpP3/8/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 127,
    MOVES: "f1e1 d7d6 c2c3 f8e7 c3d4 d6d5 e4d5 f6d5 d1b3 c7c6",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np4/1BpnP3/3P4/2P2N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 15,
    MOVES: "c5d4 e1h1 c8g4 c3d4 e7e6 h2h3 g4f3 d1f3 d8b6 b1a3",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/4P3/3P1NP1/PPP1QPBP/RNB1K2R b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 e1h1 e8h8 c2c3 h7h6 a2a4 d8d7 e4e5 f6h7 d3d4",
    DEPTH: 42,
  },
  {
    FEN: "r1bq1rk1/2p3bp/p2p2p1/1pnPpp1n/2P1P3/2N1BP2/PPBQ2PP/1K1R2NR w - -",
    EVAL: 50,
    MOVES: "c4b5 a6b5 c3b5 f5e4 e3c5 d6c5 f3e4 c8a6 d2a5 h5f6",
    DEPTH: 35,
  },
  {
    FEN: "3K4/3P1k2/8/8/8/8/2r5/R7 w - -",
    EVAL: "M21",
    MOVES: "a1f1 f7g6 f1f4 g6g5 f4d4 g5f5 d8e8 c2h2 d7d8q h2h8",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: -4,
    MOVES: "d5c4 e2e4 b7b5 a2a4 c7c6 b1c3 d8b6 f1e2 b8d7 d4d5",
    DEPTH: 51,
  },
  {
    FEN: "R2K4/3P1k2/8/8/8/8/8/2r5 w - -",
    EVAL: "M21",
    MOVES: "a8c8 c1d1 d8c7 d1c1 c7b6 c1b1 b6c5 b1c1 c5b4 c1b1",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/pp2npbp/2np2pB/2p1p3/4P3/2NP2P1/PPPQ1PBP/R3K1NR b KQkq -",
    EVAL: -17,
    MOVES: "g7h6 d2h6 c6d4 h6d2 h7h5 h2h4 c8e6 g1e2 d8b6 a1b1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 17,
    MOVES: "e2e4 f8b4 f1c4 f6e4 e1h1 e4c3 b2c3 b4d6 f3g5 h7h6",
    DEPTH: 54,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/8/1bB1P3/2N5/PB3PPP/R2QK1NR w KQkq -",
    EVAL: -24,
    MOVES: "d1b3 b4c3 b3c3 e8h8 g1f3 d7d6 e1h1 b8d7 e4e5 d7e5",
    DEPTH: 53,
  },
  {
    FEN: "r1bqk2r/pp2bppp/2n1pn2/2pp4/4P3/3P1NP1/PPP1QPBP/RNB1K2R w KQkq -",
    EVAL: 3,
    MOVES: "e1h1 b7b5 e4d5 e6d5 d3d4 b5b4 d4c5 e8h8 c1e3 d8a5",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/6B1/2pP4/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d1a4 b8d7 e2e3 f8e7 f1c4 h7h6 g5f6 e7f6 c4e2 e8h8",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np4/1B1nP3/3p4/2P2N2/PP3PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d1d4 e7e6 e1h1 c8d7 b5c6 d7c6 f1e1 d6e5 f3e5 d8b6",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/6B1/2pPP3/5N2/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b7b5 a2a4 c7c6 b1c3 d8b6 f1e2 b8d7 d4d5 d7c5 d5c6",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/8/2b5/2BpP3/8/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 332,
    MOVES: "c4f7 e8f7 d1h5 g7g6 h5c5 g8f6 c5d4 d7d5 f2f3 d5e4",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1n2P3/8/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -9,
    MOVES: "a2a4 d7d6 a4b5 c6e5 f1e1 f8e7 f3e5 d6e5 d1d8 e8d8",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2p3B1/2pPP3/5N2/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 10,
    MOVES: "b1c3 c5d4 d1d4 d8d4 f3d4 h7h6 g5d2 a7a6 f1c4 f8c5",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2p3B1/2BPP3/5N2/PP3PPP/RN1QK2R b KQkq -",
    EVAL: -19,
    MOVES: "c5d4 b1d2 b8c6 e1h1 h7h6 g5h4 f8d6 c4b5 e6e5 d2c4",
    DEPTH: 29,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q6/1Bpn4/8/2N1PN2/PP1B1PPP/R2QK2R w KQkq -",
    EVAL: 86,
    MOVES: "a2a4 e7e6 e1h1 d5b4 e3e4 f8e7 d2f4 e8h8 f3e5 d7d5",
    DEPTH: 37,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/2P1P3/2N1B3/PPN1BPPP/R2Q1RK1 b - -",
    EVAL: 69,
    MOVES: "a8c8 h2h3 c6e5 b2b3 a7a6 d1e1 b7b5 f2f4 e5c6 a1c1",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk1nr/pppp1Bpp/8/2b5/3pP3/8/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 327,
    MOVES: "e8f8 f7b3 a7a5 a2a4 d8h4 d1f3 h4f6 f3g3 h7h5 g3c7",
    DEPTH: 30,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2n1pn2/2ppP3/8/3P1NP1/PPP1QPBP/RNB2RK1 b - -",
    EVAL: 23,
    MOVES: "f6d7 c2c4 a7a6 f1d1 d5d4 b2b3 b7b5 h2h4 c8b7 c1f4",
    DEPTH: 29,
  },
  {
    FEN: "rn2kb1r/1b1ppppp/1q6/1BpN4/8/4PN2/PP1B1PPP/R2QK2R b KQkq -",
    EVAL: 42,
    MOVES: "b7d5 a2a4 d5c6 e1h1 e7e6 d2c3 c6b5 d1b3 b8c6 b3b5",
    DEPTH: 26,
  },
  {
    FEN: "r1bq2nr/pppp1kpp/8/2b5/3pP3/8/PPPP1PPP/RNBQ1RK1 w - -",
    EVAL: 707,
    MOVES: "d1h5 g7g6 h5c5 d7d5 c5d4 g8e7 f2f3 e7c6 d4f2 h8f8",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 7,
    MOVES: "d2d4 e5d4 f3d4 f8b4 d4c6 b7c6 f1d3 d7d5 e4d5 e8h8",
    DEPTH: 62,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 24,
    MOVES: "d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1h1 e8h8 c2c4",
    DEPTH: 53,
  },
  {
    FEN: "r1bq2nr/pppp1k1p/6p1/2b4Q/3pP3/8/PPPP1PPP/RNB2RK1 w - -",
    EVAL: 541,
    MOVES: "h5c5 d7d5 c5d4 g8f6 f2f3 c7c6 d4f2 h8f8 d2d3 f7g8",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/3B1N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -6,
    MOVES: "g7g6 e1h1 f8g7 f1e1 e7e6 c2c3 g8e7 d3f1 e6e5 d2d3",
    DEPTH: 36,
  },
  {
    FEN: "rn2kb1r/3ppppp/1q6/1Bpb4/8/4PN2/PP1B1PPP/R2QK2R w KQkq -",
    EVAL: 62,
    MOVES: "a2a4 e7e6 e1h1 f8e7 d2c3 e8h8 f3e5 d7d6 e5c4 d5c4",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 e2e3 c7c5 c2c3 d7d5 g1f3 f8d6 f1b5 b8c6 b5c6",
    DEPTH: 55,
  },
  {
    FEN: "r1bqk1nr/pp2bppp/2npp3/8/2B1P3/2N2N2/PP2QPPP/R1B2RK1 b kq -",
    EVAL: -13,
    MOVES: "a7a6 f1d1 d8c7 c1f4 g8f6 a1c1 e8h8 h2h3 f6d7 a2a3",
    DEPTH: 38,
  },
  {
    FEN: "r1bq2nr/pppp1k1p/6p1/2Q5/3pP3/8/PPPP1PPP/RNB2RK1 b - -",
    EVAL: 355,
    MOVES: "d7d5 c5d4 g8f6 f2f3 d5e4 d4d8 h8d8 f3e4 f7g7 d2d3",
    DEPTH: 37,
  },
  {
    FEN: "rn2kb1r/3ppppp/1q6/1Bpb4/P7/4PN2/1P1B1PPP/R2QK2R b KQkq -",
    EVAL: 46,
    MOVES: "e7e6 e1h1 f8e7 d2c3 b6b7 f3e5 d5g2 f1e1 g2c6 d1g4",
    DEPTH: 34,
  },
  {
    FEN: "r3k2r/2q1nP1p/p5p1/1p2p3/8/2PBB3/P1PR2PP/5RK1 b kq -",
    EVAL: "M19",
    MOVES: "e8d7 d3f5 d7c6 f5e4 e7d5 e4d5 c6d7 d5a8 d7e6 a8d5",
    DEPTH: 69,
  },
  {
    FEN: "r1bq1rk1/pp1nbppp/2n1p3/2ppP3/8/3P1NP1/PPP1QPBP/RNB2RK1 w - -",
    EVAL: 0,
    MOVES: "c2c4 a7a6 f1d1 f8e8 b1c3 d5d4 c3e4 d7e5 f3e5 c6e5",
    DEPTH: 34,
  },
  {
    FEN: "r6r/2qknP1p/p5p1/1p2p3/8/2PBB3/P1PR2PP/5RK1 w - -",
    EVAL: "M22",
    MOVES: "d3f5 d7c6 f5e4 e7d5 e4d5 c6d7 d5a8 d7e6 a8d5 e6e7",
    DEPTH: 66,
  },
  {
    FEN: "3R4/8/8/4k3/2K5/8/8/8 w - -",
    EVAL: "M12",
    MOVES: "d8e8 e5f4 c4d5 f4f3 e8e4 f3g2 e4e3 g2f2 d5e4 f2g2",
    DEPTH: 54,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/2n2n2/1pq1p1N1/8/2N5/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 86,
    MOVES: "f1b5 f8e7 b5c6 c5c6 d1f3 c8b7 f3c6 b7c6 g5f3 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/pp2bppp/2nppn2/8/2B1P3/2N2N2/PP2QPPP/R1BR2K1 b kq -",
    EVAL: 0,
    MOVES: "c8d7 c3b5 d8b8 c1f4 c6e5 b5d6 e7d6 d1d6 b8d6 f3e5",
    DEPTH: 52,
  },
  {
    FEN: "r1bq3k/1p1p2r1/p1n1pP1p/3n4/4N3/1N1Bb3/PPP3PP/R4R1K w - -",
    EVAL: -961,
    MOVES: "f6g7 h8g7 c2c4 d5b4 f1f3 e3a7 a1f1 d7d5 c4d5 e6d5",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 45,
    MOVES: "f1d3 f8c5 d4b3 c5a7 d1e2 g8e7 c1e3 e8h8 e3a7 a8a7",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 61,
    MOVES: "d4e5 f6g4 g1f3 b8c6 e2e3 g4e5 f3e5 c6e5 f1e2 d7d6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/4P3/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 18,
    MOVES: "d7d5 g1f3 c7c5 b1d2 d8b6 a1b1 f8d6 d4c5 b6c5 f1e2",
    DEPTH: 47,
  },
  {
    FEN: "8/8/8/3R4/2K1k3/8/8/8 w - -",
    EVAL: "M12",
    MOVES: "d5h5 e4f4 c4d3 f4g4 h5c5 g4f4 c5d5 f4f3 d5d4 f3f2",
    DEPTH: 222,
  },
  {
    FEN: "r1bq1rk1/pp1nbppp/2n1p3/2ppP3/2P5/3P1NP1/PP2QPBP/RNB2RK1 b - -",
    EVAL: 0,
    MOVES: "a7a6 f1d1 f8e8 h2h3 b7b5 c4d5 e6d5 e5e6 d7f6 e6f7",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n5/2p1p3/4P3/3B1N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 4,
    MOVES: "d3c4 h7h6 d2d3 d7d6 f3d2 g8f6 b1c3 g7g6 d2f1 f8g7",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 76,
    MOVES: "e8h8 f1e2 e7e6 h2h3 e6d5 e4d5 c8f5 g2g4 f5e4 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "c5d4 f3d4 g8f6 b1c3 a7a6 f2f3 e7e5 d4b3 f8e7 c1e3",
    DEPTH: 52,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2nppn2/8/2B1P3/2N2N2/PP2QPPP/R1BR2K1 w - -",
    EVAL: 48,
    MOVES: "e4e5 f6e8 e5d6 e7d6 c3b5 d8e7 c1g5 f7f6 g5e3 b7b6",
    DEPTH: 42,
  },
  {
    FEN: "8/8/8/2KR4/8/4k3/8/8 w - -",
    EVAL: "M11",
    MOVES: "d5d4 e3f3 c5c4 f3e2 d4d3 e2f2 c4c3 f2e2 c3c2 e2e1",
    DEPTH: 99,
  },
  {
    FEN: "8/8/8/3R4/2K5/4k3/8/8 b - -",
    EVAL: "M12",
    MOVES: "e3e4 c4c5 e4f4 c5d4 f4f3 d5f5 f3g4 d4e4 g4g3 e4e3",
    DEPTH: 62,
  },
  {
    FEN: "r1b1kb1r/p1p2ppp/2n2n2/1Bq1p1N1/8/2N5/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 106,
    MOVES: "f8d6 d2d3 e8h8 e1h1 c6d4 b5c4 c8g4 d1d2 c5c6 f2f3",
    DEPTH: 39,
  },
  {
    FEN: "rn2kb1r/3p1ppp/1q2p3/1Bpb4/P7/4PN2/1P1B1PPP/R2QK2R w KQkq -",
    EVAL: 56,
    MOVES: "d2c3 f8e7 e1h1 b6b7 d1e2 e8h8 f3e5 e7f6 f1e1 d7d6",
    DEPTH: 32,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2nbpn2/3p3p/3P4/2P1PNB1/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 85,
    MOVES: "d6g3 h2g3 c8d7 f3e5 g7g6 d1f3 h8f8 e5d7 f6d7 e3e4",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/P7/1PP2PPP/RNBQKB1R b KQkq -",
    EVAL: 28,
    MOVES: "g8f6 b1c3 d8c7 f2f4 d7d5 e4e5 f6e4 c3e4 d5e4 c1e3",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 9,
    MOVES: "g1f3 b8c6 b1c3 d8a5 f3d2 c5d4 d2c4 a5d8 c3b5 f6d5",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n5/2p1p3/4P3/2PB1N2/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: -35,
    MOVES: "g8f6 c3c4 g7g6 b1c3 f8g7 d3e2 d7d6 d2d3 a7a6 a2a3",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/2N1P3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d8a5 d4c5 f6d5 f4d6 d5c3 d1d2 b7b6 d6f8 h8f8 c5b6",
    DEPTH: 50,
  },
  {
    FEN: "r1bqnrk1/pp2bppp/2nPp3/8/2B5/2N2N2/PP2QPPP/R1BR2K1 b - -",
    EVAL: 53,
    MOVES: "e7d6 c3b5 d8e7 c1g5 f7f6 g5e3 b7b6 a1c1 g8h8 e2d3",
    DEPTH: 31,
  },
  {
    FEN: "rn2kb1r/3p1ppp/1q2p3/1Bpb4/P7/2B1PN2/1P3PPP/R2QK2R b KQkq -",
    EVAL: 46,
    MOVES: "f8e7 e1h1 b6b7 d1e2 e8h8 f3e5 e7f6 f1e1 d7d6 e3e4",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/8/3p1B2/2N1P3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 20,
    MOVES: "e3d4 a7a6 g1f3 b7b5 f1d3 c8b7 e1h1 f8e7 f1e1 e8h8",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 75,
    MOVES: "f6g4 e2e4 g4e5 f2f4 e5c6 b1c3 f8c5 d1g4 e8h8 f4f5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppp2pp/5n2/8/8/2N5/PPP1PPPP/R1BQKBNR b KQkq -",
    EVAL: 74,
    MOVES: "f8b4 d1d3 c7c6 a2a3 b4a5 b2b4 a5c7 e2e4 a7a5 b4b5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5Q2/PPPP1PPP/RNB1K1NR b KQkq -",
    EVAL: 0,
    MOVES: "d8f6 c2c3 d7d6 d2d3 h7h6 b1d2 a7a5 f3f6 g8f6 a2a4",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n1pn2/8/3P1B2/2N5/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 17,
    MOVES: "g1f3 a7a6 a2a3 d7d5 f1d3 f8d6 f4d6 d8d6 e1h1 e8h8",
    DEPTH: 26,
  },
  {
    FEN: "8/1bRQ1ppk/p3p2p/1p1q4/3Ppb2/1P2B1P1/P6P/6K1 b - -",
    EVAL: -988,
    MOVES: "f4e3 g1g2 d5d7 c7d7 b7d5 d7f7 e3d4 f7d7 a6a5 g2f1",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2nnp3/8/2B5/2N2N2/PP2QPPP/R1BR2K1 w - -",
    EVAL: 595,
    MOVES: "c1f4 d8c7 f4d6 e7d6 c3b5 d6h2 f3h2 c7f4 d1d2 a7a6",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n2n2/2p1p3/4P3/2P2N2/PPBP1PPP/RNBQK2R b KQkq -",
    EVAL: -20,
    MOVES: "d7d5 d2d3 h7h6 e1h1 f8d6 e4d5 f6d5 d3d4 e5d4 c3d4",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -",
    EVAL: 21,
    MOVES: "d2d3 f8c5 g1f3 g8f6 c2c3 a7a5 e1h1 d7d6 f1e1 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "rn2k2r/3pbppp/1q2p3/1Bpb4/P7/2B1PN2/1P3PPP/R2QK2R w KQkq -",
    EVAL: 44,
    MOVES: "e1h1 b6b7 d1e2 e8h8 f3e5 e7f6 f1e1 d7d6 e3e4 d5c6",
    DEPTH: 27,
  },
  {
    FEN: "r1bq1rk1/pp1nbppp/2n1p3/2p1P3/2Pp4/3P1NP1/PP2QPBP/RNB2RK1 w - -",
    EVAL: 47,
    MOVES: "h2h4 d8c7 c1f4 a8b8 b2b3 a7a6 f1e1 b7b5 f3h2 b5c4",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: 85,
    MOVES: "g1f3 f8b4 b1c3 b4c3 b2c3 d8e7 d1d5 f7f6 e5f6 g4f6",
    DEPTH: 45,
  },
  {
    FEN: "rn2kb1r/pp2qppp/2p2n2/4p1B1/2B1P3/1QN5/PPP2PPP/R3K2R b KQkq -",
    EVAL: 212,
    MOVES: "b8a6 c4a6 b7a6 e1a1 e7b4 d1d3 b4b3 a2b3 f8c5 f2f3",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 32,
    MOVES: "b7b5 f1d3 c8b7 e1h1 b8c6 d4c6 b7c6 f1e1 g8e7 d3f1",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: -31,
    MOVES: "d5d4 c3e2 e7e5 e2g3 c8e6 c2c3 d8d7 g1f3 f7f6 c3d4",
    DEPTH: 52,
  },
  {
    FEN: "rn2k2r/3pbppp/1q2p3/1Bpb4/P7/2B1PN2/1P3PPP/R2Q1RK1 b kq -",
    EVAL: 50,
    MOVES: "b6b7 d1e2 e8h8 f3e5 e7f6 f1e1 d7d6 e3e4 d6e5 e4d5",
    DEPTH: 33,
  },
  {
    FEN: "rn3rk1/3pbppp/1q2p3/1Bpb4/P7/2B1PN2/1P3PPP/R2Q1RK1 w - -",
    EVAL: 75,
    MOVES: "f3d2 b6c7 e3e4 d5c6 d1g4 g7g6 e4e5 c7b7 b5c6 b8c6",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/2ppp3/4P3/2PP1N2/PPB2PPP/RNBQK2R b KQkq -",
    EVAL: -35,
    MOVES: "h7h6 b1d2 f8d6 e1h1 e8h8 c2a4 d6c7 f1e1 c8e6 e4d5",
    DEPTH: 28,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/2P1P3/2N1BP2/PPN1B1PP/R2Q1RK1 b - -",
    EVAL: 42,
    MOVES: "c6e5 b2b3 a7a6 a2a4 e5c6 g1h1 d7e6 a1b1 f6d7 c3d5",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/4P3/2P2Bn1/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: 51,
    MOVES: "f8b4 b1c3 b4c3 b2c3 d8e7 d1d5 f7f6 e5f6 g4f6 d5d3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/1pp1bppp/p4n2/3p4/3P4/2NB1N2/PPP2PPP/R1BQ1RK1 b kq -",
    EVAL: 28,
    MOVES: "e8h8 c3e2 c7c5 c2c3 f8e8 h2h3 b8c6 f1e1 h7h6 c1f4",
    DEPTH: 26,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: 42,
    MOVES: "c4d5 e6d5 e2e3 e8h8 f1d3 h7h6 g5h4 b4e7 a1c1 c7c6",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1n2P3/P7/5N2/1PP2PPP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "b5d4 f3d4 d7d5 c2c3 f8e7 f2f4 e8h8 c1e3 f7f6 d4c6",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 87,
    MOVES: "e1h1 g7g6 b5c4 f8g7 d1f3 d8e7 c2c3 d7d6 d2d3 c8e6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 21,
    MOVES: "a7a6 c1e3 e7e5 d4f3 f8e7 f1c4 e8h8 e1h1 b8c6 e3g5",
    DEPTH: 50,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2nnp3/8/2B2B2/2N2N2/PP2QPPP/R2R2K1 b - -",
    EVAL: 505,
    MOVES: "d8c7 f4d6 e7d6 c3b5 d6h2 f3h2 c7e5 e2d2 h7h6 a1c1",
    DEPTH: 47,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/5n2/3p4/3P1B2/2N1P3/Pq3PPP/R2QKBNR w KQkq -",
    EVAL: 89,
    MOVES: "f1b5 c8d7 b5d7 b8d7 g1e2 b2a3 a1b1 b7b6 f2f3 a7a6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/2p5/P3P3/2N2N2/1PPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 e4d5 e6d5 d2d4 g8f6 c1g5 c8e6 f1e2 b8c6 d4c5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 21,
    MOVES: "e2e4 c7c5 f1c4 c5d4 f3d4 d8a5 g5d2 a5c5 c4b5 c8d7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: -77,
    MOVES: "b1c3 g8f6 c1g5 c8f5 g5f6 e7f6 d1e2 b8c6 e1a1 d8d7",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/3p1ppp/pp2p3/2p5/P3P3/2N2N2/1PPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 34,
    MOVES: "g2g3 c8b7 d2d4 c5d4 f3d4 f8e7 f1g2 d7d6 e1h1 d8c7",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 56,
    MOVES: "f2f3 f8g7 c1e3 e8h8 d1d2 b8c6 e1a1 d6d5 e4d5 f6d5",
    DEPTH: 54,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/3p1np1/8/2PnP3/2N1B3/PP2BPPP/R2Q1RK1 w - -",
    EVAL: 77,
    MOVES: "e3d4 c8d7 d1d2 d7c6 f2f3 f6d7 d4e3 d8a5 a1c1 a8e8",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pp1n1ppp/3p1n2/1B2pN2/4P3/2N5/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 42,
    MOVES: "a7a6 b5a4 b7b5 a4b3 d7c5 c1g5 c8f5 e4f5 f8e7 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/3p1np1/8/2PBP3/2N5/PP2BPPP/R2Q1RK1 b - -",
    EVAL: 77,
    MOVES: "c8d7 f1e1 d7c6 d1c2 d8c7 e2f1 f8c8 h2h3 a7a5 b2b3",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk1nr/1p1p1ppp/p3p3/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 58,
    MOVES: "d1d3 d7d6 c1d2 g8f6 e1a1 e6e5 d4f5 c8f5 e4f5 b8c6",
    DEPTH: 43,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/3p1np1/8/2PBP3/2N5/PP2BPPP/R2Q1RK1 w - -",
    EVAL: 73,
    MOVES: "f1e1 d7c6 d1c2 a7a6 e2f1 d8a5 a2a3 a5g5 a1d1 f8c8",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkbnr/3p1ppp/pp2p3/2p5/P3P3/2N2NP1/1PPP1P1P/R1BQKB1R b KQkq -",
    EVAL: 25,
    MOVES: "c8b7 f1g2 d8c7 d2d4 c5d4 f3d4 g8f6 e1h1 d7d6 f2f4",
    DEPTH: 27,
  },
  {
    FEN: "r1b1kb1r/ppqn1ppp/3p1n2/1B2pN2/4P3/2N5/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 286,
    MOVES: "c1g5 a7a6 g5f6 a6b5 f6g7 f8g7 f5g7 e8f8 g7h5 h8g8",
    DEPTH: 30,
  },
  {
    FEN: "r1bqk2r/pppp1Bpp/2n5/2b1P3/3p2n1/2P2N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e8f7 f3g5 f7e8 d1g4 d7d6 g4g3 h7h6 g5f3 d8e7 e1h1",
    DEPTH: 37,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/3p1np1/8/2PBP3/2NQ4/PP2BPPP/R4RK1 b - -",
    EVAL: 56,
    MOVES: "a7a6 f1d1 a8c8 d3e3 f6g4 e3g3 g4f6 h2h3 d7e6 c4c5",
    DEPTH: 40,
  },
  {
    FEN: "rn1qkbnr/1b1p1ppp/pp2p3/2p5/P3P3/2N2NP1/1PPP1PBP/R1BQK2R b KQkq -",
    EVAL: 30,
    MOVES: "g8f6 e4e5 f6d5 e1h1 f8e7 d2d4 d5c3 b2c3 e8h8 d1d3",
    DEPTH: 33,
  },
  {
    FEN: "rnbqk2r/pppp2pp/5n2/2b3B1/8/2N5/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: -10,
    MOVES: "c5f2 e1f2 f6g4 f2g3 d8g5 g1f3 g5e3 d1d4 d7d6 c3d5",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/ppp2pp1/2p2n2/2b4p/4P3/3P4/PPP1BPPP/RNBQK2R w KQkq -",
    EVAL: 266,
    MOVES: "c2c3 f6g4 d3d4 c5d6 b1d2 d6f4 g2g3 f4h6 h2h3 g4f6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 46,
    MOVES: "f8g7 f2f3 b8c6 d1d2 e8h8 e1a1 d6d5 e4d5 f6d5 d4c6",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 46,
    MOVES: "c7c6 b5c4 a7a5 a2a4 g8f6 f1e1 f8c5 e4e5 f6d5 d2d3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "g8f6 f1b5 c6d4 b5c4 f8c5 d2d3 d7d6 c3a4 d4f3 d1f3",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2p5/8/2BpP3/8/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 58,
    MOVES: "d7d5 e4d5 c6d5 c4b5 c8d7 f1e1 g8e7 a2a4 a7a6 b5d7",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 58,
    MOVES: "f2f3 b8c6 d1d2 e8h8 e1a1 d6d5 e4d5 f6d5 d4c6 b7c6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "f8c5 g1f3 d7d6 d2d4 e5d4 f3d4 e8h8 e1h1 b8d7 d4b3",
    DEPTH: 50,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/5n2/1B1p4/3P1B2/2N1P3/Pq3PPP/R2QK1NR b KQkq -",
    EVAL: 76,
    MOVES: "c8d7 b5d7 b8d7 g1e2 b2a3 a1b1 b7b6 f2f3 a7a6 e1h1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk2r/pppp2pp/8/6B1/6n1/2N5/PPP1PKPP/R2Q1BNR w kq -",
    EVAL: 0,
    MOVES: "f2g3 d8g5 g1f3 g5e3 d1d4 d7d6 c3d5 e3d4 f3d4 e8d8",
    DEPTH: 37,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 d7d6 a2a4 c7c6 e1h1 e8h8 d2d4 e5d4 f3d4 b8d7",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3P4/2P5/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 6,
    MOVES: "g8f6 g1f3 c8f5 d1b3 d8c8 c3c4 d5c4 b3c4 e7e6 b1c3",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R b KQkq -",
    EVAL: -61,
    MOVES: "g7g6 f1c4 f8g7 h2h3 e8h8 e1h1 b8c6 c1e3 c6a5 c4e2",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p5/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 14,
    MOVES: "g8f6 b1c3 e7e6 d2d4 c5d4 f3d4 a7a6 a2a4 b8c6 c1e3",
    DEPTH: 47,
  },
  {
    FEN: "r3kb1r/pp1npppp/5n2/3p4/3P1B2/2N1P3/Pq3PPP/R2QK1NR w KQkq -",
    EVAL: 88,
    MOVES: "g1e2 b2a3 a1b1 b7b6 f2f3 a7a6 e3e4 e7e6 b1b3 a3a5",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/4p1N1/4p3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: -96,
    MOVES: "h2h4 f8e7 g5e4 b8c6 f1c4 g8h6 b1c3 c8g4 f2f3 g4d7",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 28,
    MOVES: "d7d5 e4e5 c8f5 g1f3 e7e6 f1e2 c6c5 c1e3 c5d4 f3d4",
    DEPTH: 48,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "c7c6 g1f3 d7d6 h2h3 e8h8 a2a4 f8e8 f3g5 e8f8",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2np4/2p5/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 d2d3 e7e6 b1d2 f8e7 e1h1 e8h8 f1e1 c6a5 c4b5",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk1nr/p2pppbp/2p3p1/8/2P1P3/2N5/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d7d6 f1e2 g8f6 e1h1 e8h8 c1e3 f6d7 a1b1 d7c5 d1c2",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3p1n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR b KQkq -",
    EVAL: -12,
    MOVES: "e8h8 g1f3 a7a6 c4b3 c8e6 f4e5 d6e5 c1g5 e6b3 a2b3",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/3P4/PPP2PPP/RNBQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "c7c6 g1f3 d7d5 c4b3 a7a5 a2a4 f8b4 c2c3 b4d6 e1h1",
    DEPTH: 50,
  },
  {
    FEN: "r3k3/ppp2pp1/8/3pb2P/8/1B1p2PQ/PPPP3K/RNB1q3 b q -",
    EVAL: -116,
    MOVES: "e1c1 c2c3 c7c6 h3f5 e8f8 h5h6 c1e1 f5g5 e1f2 h2h3",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/4p1N1/3Pp3/8/PPP2PPP/RNBQKB1R b KQkq d3",
    EVAL: -168,
    MOVES: "d8d4 d1d4 e5d4 g5e4 b8c6 b1d2 c8f5 f1d3 c6b4 d2f3",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/2pp1ppp/p1n5/1p2p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 42,
    MOVES: "a4b3 g8f6 e1h1 d7d6 a2a4 c8d7 b1c3 c6a5 b3a2 f8e7",
    DEPTH: 52,
  },
  {
    FEN: "rnb1k2r/pppp2pp/8/6q1/6n1/2N5/PPP1P1PP/R2QKBNR w kq -",
    EVAL: -27,
    MOVES: "g1f3 g5c5 d1d4 c5d4 f3d4 b8c6 d4f3 h8f8 c3d5 e8d8",
    DEPTH: 32,
  },
  {
    FEN: "rn1qkbnr/pp1b1ppp/3p4/1B2p3/3NP3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 84,
    MOVES: "b5d7 d8d7 d4e2 b8c6 f2f3 f7f5 c2c4 f5e4 f3e4 f8e7",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/4p1N1/8/3p4/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: -83,
    MOVES: "f1d3 b8c6 e1h1 f8e7 g5f3 e7d6 a2a3 c8g4 h2h3 g4h5",
    DEPTH: 29,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2np4/2p5/2B1P1b1/2P2N1P/PP1P1PP1/RNBQK2R b KQkq -",
    EVAL: 18,
    MOVES: "g4f3 d1f3 g8f6 d2d3 g7g6 e1h1 f8g7 f3e2 e8h8 a2a4",
    DEPTH: 29,
  },
  {
    FEN: "rn3rk1/3pb1pp/1q2p3/1Bpb1p2/P7/2B1P3/1P1N1PPP/R2Q1RK1 w - -",
    EVAL: 83,
    MOVES: "d1e2 d5c6 e3e4 f5e4 d2e4 b6b7 e2g4 f8f7 e4g5 e7g5",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/4p1N1/8/3B4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -92,
    MOVES: "f8e7 g5f3 b8c6 e1h1 e7d6 b1c3 h7h6 a2a3 a7a6 b2b4",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3P4/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 67,
    MOVES: "d8d5 b1c3 d5a5 d2d4 c8f5 f3e5 c7c6 f1c4 e7e6 g2g4",
    DEPTH: 45,
  },
  {
    FEN: "r3kb1r/pp1npppp/5n2/3p4/3P1B2/2N1P3/Pq2NPPP/R2QK2R b KQkq -",
    EVAL: 76,
    MOVES: "b2a3 a1b1 b7b6 f2f3 a7a6 e3e4 e7e6 b1b3 a3a5 e4e5",
    DEPTH: 32,
  },
  {
    FEN: "r6r/2q1nP1p/p1k3p1/1p2pB2/8/2P1B3/P1PR2PP/5RK1 w - -",
    EVAL: "M18",
    MOVES: "f5e4 e7d5 e4d5 c6d7 d5a8 d7e6 a8d5 e6e7 e3g5 e7d7",
    DEPTH: 69,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n5/4p1N1/8/3B4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -87,
    MOVES: "e1h1 f8e7 g5f3 e7d6 b1c3 h7h6 b2b3 g8e7 c1b2 c8e6",
    DEPTH: 29,
  },
  {
    FEN: "r3kb1r/pp1npppp/5n2/3p4/3P1B2/q1N1P3/P3NPPP/R2QK2R w KQkq -",
    EVAL: 81,
    MOVES: "a1b1 b7b6 f2f3 a7a6 e3e4 e7e6 b1b3 a3a5 e4e5 f6g8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/5ppp/p2p1n2/1p2p3/4P3/1NN2P2/PPP3PP/R1BQKB1R w KQkq -",
    EVAL: 84,
    MOVES: "a2a4 b5a4 a1a4 c8d7 a4a1 b8c6 f1a6 f8e7 c1e3 c6b4",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 10,
    MOVES: "f3d4 f8b4 d4c6 b7c6 f1d3 d7d5 e4d5 e8h8 e1h1 c6d5",
    DEPTH: 50,
  },
  {
    FEN: "rnb1kb1r/ppp1pppp/5n2/3q4/8/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 70,
    MOVES: "b1c3 d5a5 d2d4 c7c6 f1c4 c8f5 f3e5 e7e6 g2g4 f5g6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R b KQkq -",
    EVAL: 46,
    MOVES: "e8h8 d1d2 b8c6 e1a1 d6d5 e4d5 f6d5 d4c6 b7c6 e3d4",
    DEPTH: 49,
  },
  {
    FEN: "rn1qk2r/pp2ppbp/3p1np1/2pP4/2P1P1b1/2N2N1P/PP3PP1/R1BQKB1R b KQkq -",
    EVAL: 54,
    MOVES: "g4f3 d1f3 e8h8 f1e2 e7e6 c1g5 e6d5 c3d5 b8d7 e1h1",
    DEPTH: 31,
  },
  {
    FEN: "rnb1kb1r/ppp1pppp/5n2/3q4/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 57,
    MOVES: "c8g4 f1e2 b8c6 c2c4 d5f5 b1d2 e7e6 h2h3 g4f3 d2f3",
    DEPTH: 50,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/5b2/3Pp3/8/PPP1QPPP/RNB1KBNR w KQkq -",
    EVAL: -94,
    MOVES: "g2g4 f5e6 b1c3 b8c6 c1e3 c6d4 e3d4 d8d4 e2b5 c7c6",
    DEPTH: 23,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e5d4 f3d4 f8b4 d4c6 b7c6 f1d3 d7d5 e4d5 c6d5 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "r6r/2q1nP1p/p1k3p1/1p2p3/4B3/2P1B3/P1PR2PP/5RK1 b - -",
    EVAL: "M25",
    MOVES: "e7d5 e4d5 c6d7 d5a8 d7e6 a8d5 e6d7 d5b3 c7d6 d2d6",
    DEPTH: 51,
  },
  {
    FEN: "r1bq3r/pppp1kpp/2n5/2b1P3/3p2n1/2P2N2/PP3PPP/RNBQK2R w KQ -",
    EVAL: 5,
    MOVES: "f3g5 f7f8 d1g4 c6e5 g4g3 e5c6 e1h1 h7h6 g5f3 d8f6",
    DEPTH: 43,
  },
  {
    FEN: "r6r/2q2P1p/p1k3p1/1p1np3/4B3/2P1B3/P1PR2PP/5RK1 w - -",
    EVAL: "M19",
    MOVES: "e4d5 c6d7 d5a8 d7e6 a8d5 e6e7 e3g5 e7d7 d5e4 c7d6",
    DEPTH: 67,
  },
  {
    FEN: "r3kb1r/pp1npppp/5n2/3p4/3P1B2/q1N1P3/P3NPPP/1R1QK2R b Kkq -",
    EVAL: 54,
    MOVES: "b7b6 f2f3 a7a6 e3e4 e7e6 b1b3 a3a5 e4e5 f6g8 f4d2",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 68,
    MOVES: "b1c3 d7d6 f2f3 c8e6 c1e3 f8g7 a1c1 f6d7 d4e6 f7e6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 60,
    MOVES: "g1f3 g7g6 b1c3 e6d5 c4d5 f8g7 e2e4 d7d6 f3d2 b8d7",
    DEPTH: 46,
  },
  {
    FEN: "r3kb1r/p2npppp/1p3n2/3p4/3P1B2/q1N1P3/P3NPPP/1R1QK2R w Kkq -",
    EVAL: 68,
    MOVES: "f2f3 a7a6 e3e4 e7e6 b1b3 a3a5 e4e5 f6g8 f4d2 b6b5",
    DEPTH: 37,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R w KQ -",
    EVAL: 51,
    MOVES: "d1d2 b8c6 g2g4 h7h5 h2h3 d6d5 g4g5 f6h7 c3d5 e7e6",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/pp1ppp1p/5np1/8/2PnP3/2N5/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 68,
    MOVES: "d1d4 d7d6 c1e3 f8g7 f2f3 e8h8 d4d2 d8a5 f1e2 a7a6",
    DEPTH: 45,
  },
  {
    FEN: "rn1qkbnr/1b1p1ppp/p3p3/1p6/3NP3/2NB4/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 58,
    MOVES: "e1h1 b8c6 d4c6 b7c6 f1e1 f8c5 d1h5 d8b6 c1e3 c5e3",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/8/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 65,
    MOVES: "f1e2 h7h6 c2c4 c8f5 d1b3 d8c8 d4d5 e7e6 f3d4 f5b1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR b KQkq -",
    EVAL: 80,
    MOVES: "g8f6 b1c3 d7d6 e2e4 e7e6 f1c4 f8e7 g1e2 e8h8 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "r3k3/ppp2pp1/8/3pP2P/8/1B1p2Q1/PPPP2PK/RNB1q3 b q -",
    EVAL: 1317,
    MOVES: "e1c1 h5h6 c1d1 g3g7 e8a8 c2d3 d1h5 h2g3 h5h1 h6h7",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 21,
    MOVES: "d2d3 f8c5 c2c3 e8g8 e1g1 d7d6 h2h3 h7h6 f1e1 a7a6",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/pp1ppp1p/5np1/8/2PQP3/2N5/PP3PPP/R1B1KB1R b KQkq -",
    EVAL: 54,
    MOVES: "d7d6 c1e3 f8g7 f1e2 e8h8 d4d3 a7a6 e1h1 c8e6 b2b3",
    DEPTH: 38,
  },
  {
    FEN: "rn2kb1r/p3qppp/2p2n2/1N2p1B1/2B1P3/1Q6/PPP2PPP/R3K2R b KQkq -",
    EVAL: 540,
    MOVES: "e7b4 b3b4 f8b4 c2c3 c6b5 c4b5 b8d7 c3b4 a8b8 b5c6",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 24,
    MOVES: "f1d3 g8f6 d1e2 f8d6 c1e3 b8c6 e1a1 d6b4 d4c6 b7c6",
    DEPTH: 49,
  },
  {
    FEN: "rn1qkb1r/pp2nppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQ1RK1 b kq -",
    EVAL: 36,
    MOVES: "b8d7 c2c3 h7h6 a2a4 a7a6 b1d2 c6c5 d4c5 d7c5 b2b4",
    DEPTH: 44,
  },
  {
    FEN: "r6r/2q2P1p/p1k3p1/1p1Bp3/8/2P1B3/P1PR2PP/5RK1 b - -",
    EVAL: "M29",
    MOVES: "c6d7 d5a8 d7e6 a8d5 e6d7 d5b3 d7c6 f1f6 c6b7 b3d5",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP3PPP/R1B1KB1R w KQkq -",
    EVAL: 51,
    MOVES: "f1e2 f8g7 e1h1 e8h8 d4d3 f6d7 d3c2 d7c5 c1e3 g7c3",
    DEPTH: 47,
  },
  {
    FEN: "r2q1rk1/pp2bppp/2npbn2/4p3/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1 b - -",
    EVAL: -35,
    MOVES: "d6d5 e4d5 f6d5 c3d5 e6d5 c2c4 d5e4 f4e5 c6e5 d1d4",
    DEPTH: 48,
  },
  {
    FEN: "r6r/2qk1P1p/p5p1/1p1Bp3/8/2P1B3/P1PR2PP/5RK1 w - -",
    EVAL: "M19",
    MOVES: "d5a8 d7e6 a8d5 e6e7 e3g5 e7d7 d5e4 c7d6 f7f8n h8f8",
    DEPTH: 63,
  },
  {
    FEN: "7k/pbp1r2p/1p3q2/4nP2/1PP1p2p/P3P3/4B1R1/B5QK b - -",
    EVAL: 789,
    MOVES: "e7g7 g2g7 f6g7 g1g7 h8g7 a1e5 g7g8 f5f6 b7c8 c4c5",
    DEPTH: 25,
  },
  {
    FEN: "r1bq1rk1/1ppp1ppp/2n2n2/p1b1p3/4P3/2N3P1/PPPPNPBP/R1BQ1RK1 w - -",
    EVAL: 6,
    MOVES: "d2d3 f8e8 h2h3 c6d4 e2d4 c5d4 c3b5 d4b6 c2c4 c7c6",
    DEPTH: 38,
  },
  {
    FEN: "4r2k/pbp4p/1p3q2/4nP2/1PP1p2p/P3P3/4B1R1/B5QK w - -",
    EVAL: "M12",
    MOVES: "e2h5 h4h3 g2g5 h7h6 g5g6 f6f8 g1g3 h8h7 a1e5 e8e5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR b KQkq -",
    EVAL: 48,
    MOVES: "f8g7 d1c2 a7a6 a2a4 b8d7 g1f3 f6g4 c1g5 f7f6 g5h4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR b KQkq -",
    EVAL: 59,
    MOVES: "g8f6 g1f3 f6d5 d2d4 d5f6 b1c3 g7g6 c1g5 f8g7 d1d2",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/4p1N1/1bB1P3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 700,
    MOVES: "g5f7 d8e7 f7h8 d7d6 c2c3 b4a5 d2d3 c8g4 f2f3 g4h5",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB3P/PP3PP1/R1BQK1NR b KQkq -",
    EVAL: 48,
    MOVES: "e8h8 a2a4 f8e8 g1e2 b8a6 e1h1 a6b4 d3b1 f6d7 f2f4",
    DEPTH: 43,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R b KQ -",
    EVAL: 58,
    MOVES: "b8c6 e1a1 d6d5 e4d5 f6d5 d4c6 b7c6 e3d4 g7d4 d2d4",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/8/2PP4/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 47,
    MOVES: "c7c5 b1c3 c5d4 d1d4 d8d4 f3d4 a7a6 c3a4 b8d7 c1e3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "f8b4 d4c6 b7c6 f1d3 d7d5 e4d5 c6d5 e1h1 e8h8 h2h3",
    DEPTH: 56,
  },
  {
    FEN: "3k4/4N3/3K2p1/6N1/8/8/8/8 w - -",
    EVAL: "M16",
    MOVES: "e7c6 d8c8 c6a5 c8d8 a5b7 d8e8 d6e6 e8f8 b7d6 f8g7",
    DEPTH: 77,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/3p4/4p3/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 56,
    MOVES: "f1b5 b8d7 d4f5 a7a6 b5a4 b7b5 a4b3 d7c5 b1c3 c8f5",
    DEPTH: 53,
  },
  {
    FEN: "4r2k/pbp4p/1p3q2/4nP1B/1PP1p2p/P3P3/6R1/B5QK b - -",
    EVAL: "M10",
    MOVES: "h4h3 g2g3 h3h2 g1g2 e8d8 g2b2 h7h6 b2e5 d8d1 h5d1",
    DEPTH: 79,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 63,
    MOVES: "d2d4 g8f6 g1f3 c8f5 c1d2 e7e6 f3e5 a5b6 d1f3 c7c6",
    DEPTH: 49,
  },
  {
    FEN: "rnb1k1nr/1pqp1ppp/p3p3/8/1b1NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 19,
    MOVES: "e3d2 g8f6 f1d3 e8h8 e1h1 b4d6 g1h1 d6f4 d2f4 c7f4",
    DEPTH: 46,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R w KQ -",
    EVAL: 61,
    MOVES: "e1a1 d6d5 e4d5 f6d5 d4c6 b7c6 e3d4 g7d4 d2d4 d8b6",
    DEPTH: 54,
  },
  {
    FEN: "rnb1k2r/1pqp1ppp/p3pn2/8/1b1NP3/2N1B3/PPPQ1PPP/R3KB1R w KQkq -",
    EVAL: -61,
    MOVES: "f1d3 d7d5 e4d5 f6d5 d4e2 d5e3 d2e3 b4c5 e3g3 c7g3",
    DEPTH: 48,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/q7/8/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 58,
    MOVES: "g8f6 d2d4 c8f5 c1d2 e7e6 f3e5 a5b6 d1f3 c7c6 e1a1",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/8/5P2/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: 23,
    MOVES: "d2d4 e7e6 b1c3 f8b4 g1h3 g8f6 e2e4 f5e4 f3e4 d7d6",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 d1d2 f8e7 e1a1 b7b5 f2f3 h7h6 g5e3 b5b4 c3a4",
    DEPTH: 58,
  },
  {
    FEN: "2b2rk1/p3q2p/1p1p2p1/2pP4/2B5/6QP/PPP2RP1/6K1 b - -",
    EVAL: -56,
    MOVES: "f8f2 g3f2 e7e5 c2c3 g8g7 f2f3 c8d7 c4d3 h7h5 g1h1",
    DEPTH: 34,
  },
  {
    FEN: "3Qr3/4Rk1p/1p4p1/1Pp5/2P5/6P1/6KP/8 b - -",
    EVAL: "M19",
    MOVES: "e8e7 d8b6 e7d7 b6c6 d7d2 g2f3 d2d3 f3e2 d3a3 b5b6",
    DEPTH: 49,
  },
  {
    FEN: "B6r/3k1P1p/p2q2p1/1p2p3/8/2P1B3/P1PR2PP/5RK1 w - -",
    EVAL: "M16",
    MOVES: "d2d6 d7d6 f7f8q h8f8 f1f8 d6e7 f8h8 e5e4 h8h7 e7e6",
    DEPTH: 60,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c7c6 d5c6 b7c6 b5d3 f6d5 g5f3 f8d6 b1c3 d5f4 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 8,
    MOVES: "f2f4 d8b6 d1d2 b6b2 a1b1 b2a3 f4f5 b8c6 f5e6 f7e6",
    DEPTH: 51,
  },
  {
    FEN: "2b2rk1/p6p/1p1p2p1/2pP4/2B5/6QP/PPP2RP1/4q1K1 w - -",
    EVAL: -64,
    MOVES: "c4f1 f8f2 g3f2 e1e5 f2h4 c8f5 c2c3 f5e4 h4d8 g8f7",
    DEPTH: 34,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R b KQ -",
    EVAL: 21,
    MOVES: "d8a5 e1a1 c6d4 e3d4 c8e6 c4e6 f7e6 h2h4 a8c8 c1b1",
    DEPTH: 48,
  },
  {
    FEN: "rn2kbnr/ppp1pppp/8/q7/6b1/2N2N2/PPPPBPPP/R1BQK2R b KQkq -",
    EVAL: 49,
    MOVES: "e7e6 d2d4 g8f6 h2h3 g4h5 e1h1 b8d7 a2a3 c7c6 c1f4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 6,
    MOVES: "b7c6 b5d3 f6d5 g5f3 f8d6 e1h1 d5f4 f1e1 f4d3 c2d3",
    DEPTH: 55,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 9,
    MOVES: "d4c6 b7c6 f1d3 d7d5 e4d5 e8h8 e1h1 c6d5 h2h3 c7c6",
    DEPTH: 53,
  },
  {
    FEN: "r3kbnr/ppp1pppp/2n5/q7/6b1/2N2N2/PPPPBPPP/R1BQK2R w KQkq -",
    EVAL: 92,
    MOVES: "h2h3 g4f3 e2f3 e7e6 e1h1 g8f6 d2d4 e8a8 f3c6 b7c6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R b KQkq -",
    EVAL: 14,
    MOVES: "d8b6 d1d2 b6b2 a1b1 b2a3 f4f5 b8c6 f5e6 f7e6 d4c6",
    DEPTH: 57,
  },
  {
    FEN: "2b2rk1/p6p/1p1p2p1/2pP4/8/6QP/PPP2RP1/4qBK1 b - -",
    EVAL: -110,
    MOVES: "e1f2 g3f2 f8f2 g1f2 g8g7 f2e3 g7f6 h3h4 f6e5 c2c4",
    DEPTH: 42,
  },
  {
    FEN: "rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d1d2 b6b2 a1b1 b2a3 f4f5 b8c6 f5e6 f7e6 d4c6 b7c6",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 14,
    MOVES: "e2e3 d7d5 g1f3 f8d6 f4d6 d8d6 c2c4 e8h8 b1c3 d5c4",
    DEPTH: 48,
  },
  {
    FEN: "r3kbnr/ppp1pppp/2n5/q7/6b1/2N2N2/PPPPBPPP/R1BQ1RK1 b kq -",
    EVAL: 52,
    MOVES: "g8f6 e2b5 e7e6 h2h3 g4h5 d2d3 e8a8 g2g4 h5g6 b5c6",
    DEPTH: 36,
  },
  {
    FEN: "2b3k1/p6p/1p1p2p1/2pP4/8/6QP/PPP2rP1/4qBK1 w - -",
    EVAL: -46,
    MOVES: "g3f2 e1e5 f2h4 c8f5 c2c3 e5e3 g1h1 g6g5 h4a4 e3e1",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 41,
    MOVES: "d7d6 c2c3 a7a6 b5c6 b7c6 d2d4 e5d4 c3d4 c5b6 f1e1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/4P3/5P2/PPPP2PP/RNBQKBNR b KQkq -",
    EVAL: -25,
    MOVES: "e7e5 d2d4 e5d4 d1d4 b8c6 d4c4 f5e4 b1c3 f8b4 c4e4",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/3B4/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f6d5 g5f3 f8d6 e1h1 d5f4 f1e1 f4d3 c2d3 e8h8 b1c3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqnrk1/pp3ppp/2nbp3/1N6/2B5/5N2/PP2QPPP/R1BR2K1 b - -",
    EVAL: 84,
    MOVES: "d8e7 c1g5 f7f6 g5e3 d6c7 f3d4 g8h8 a1c1 c8d7 d4c6",
    DEPTH: 35,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 7,
    MOVES: "f1d3 e8h8 e1h1 d7d5 e4d5 c6d5 h2h3 f8e8 d1f3 c7c6",
    DEPTH: 55,
  },
  {
    FEN: "2b3k1/p6p/1p1p2p1/2pP4/8/7P/PPP2QP1/4qBK1 b - -",
    EVAL: -77,
    MOVES: "e1e5 c2c4 c8f5 a2a3 g6g5 h3h4 h7h6 h4g5 h6g5 g2g3",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/p4ppp/2p5/n2np1N1/8/3B4/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 12,
    MOVES: "g5f3 f8d6 b1c3 d5f4 e1h1 f4d3 c2d3 e8h8 f1e1 c6c5",
    DEPTH: 53,
  },
  {
    FEN: "2kr1bnr/ppp1pppp/2n5/q7/6b1/2N2N2/PPPPBPPP/R1BQ1RK1 w - -",
    EVAL: 45,
    MOVES: "h2h3 g4f3 e2f3 c6d4 b2b4 a5e5 c1b2 g8f6 f1e1 e5f4",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/pppp2pp/8/4pp2/4P3/5P2/PPPP2PP/RNBQKBNR w KQkq -",
    EVAL: -17,
    MOVES: "d2d4 e5d4 d1d4 b8c6 d4c4 f5e4 f3e4 f8b4 c1d2 d8h4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 59,
    MOVES: "c2c3 g8f6 d2d4 e5d4 e4e5 f6d5 e1h1 c5e7 c3d4 d7d6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -46,
    MOVES: "f6e4 f3e5 d7d5 c4e2 f8d6 e5f3 e8h8 e1h1 f8e8 d2d4",
    DEPTH: 49,
  },
  {
    FEN: "r1b1nrk1/pp2qppp/2nbp3/1N6/2B5/5N2/PP2QPPP/R1BR2K1 w - -",
    EVAL: 61,
    MOVES: "c1g5 f7f6 g5e3 d6c7 f3d4 c6d4 e3d4 a7a6 b5c3 f8f7",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2NB4/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 3,
    MOVES: "d7d5 e4d5 c6d5 h2h3 c7c6 e1h1 e8h8 c1f4 f8e8 d1f3",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkbnr/pppp2pp/8/4pp2/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: -24,
    MOVES: "e5d4 d1d4 b8c6 d4c4 f5e4 f3e4 f8b4 c1d2 d8h4 g2g3",
    DEPTH: 38,
  },
  {
    FEN: "2kr1bnr/ppp1pppp/2n5/q7/6b1/2N2N1P/PPPPBPP1/R1BQ1RK1 b - -",
    EVAL: 21,
    MOVES: "g4f3 e2f3 c6d4 d2d3 e7e6 c1e3 d4f3 d1f3 g8f6 f1e1",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 e2e3 c7c5 c2c3 b8c6 b1d2 c5d4 e3d4 h7h6 g5f4",
    DEPTH: 50,
  },
  {
    FEN: "2b3k1/p6p/1p1p2p1/2pPq3/8/7P/PPP2QP1/5BK1 w - -",
    EVAL: -80,
    MOVES: "f2h4 c8f5 c2c3 e5e3 g1h1 g8g7 h4d8 e3e1 h1h2 g7f7",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/p4ppp/2p5/n2np3/8/3B1N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f8d6 e1h1 d5f4 f1e1 f4d3 c2d3 e8h8 b1c3 c6c5 b2b3",
    DEPTH: 52,
  },
  {
    FEN: "2kr1bnr/ppp1pppp/2n5/q6b/8/2N2N1P/PPPPBPP1/R1BQ1RK1 w - -",
    EVAL: 51,
    MOVES: "d2d3 g8f6 a1b1 e7e6 f3g5 h5g6 c1d2 a5f5 g5f3 c6d4",
    DEPTH: 32,
  },
  {
    FEN: "rnb1kbnr/pp1ppppp/8/2q5/8/2N5/PPPBPPPP/R2QKBNR b KQkq -",
    EVAL: 45,
    MOVES: "g8f6 e2e4 d7d6 g1f3 b8d7 a2a4 g7g6 d2e3 c5b4 d1d4",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 7,
    MOVES: "e5d4 f3d4 g8f6 d4c6 b7c6 b1c3 f8b4 f1d3 d7d5 e4d5",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk2r/p4ppp/2pb4/n2np3/8/3B1N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 5,
    MOVES: "e1h1 d5f4 f1e1 f4d3 c2d3 e8h8 b1c3 c6c5 b2b3 f7f6",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/4P3/3N4/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -190,
    MOVES: "d8a5 b1c3 a5e5 f1e2 a7a6 e1h1 e5c7 f1e1 f8e7 c1g5",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/p4ppp/2pb4/n2np3/8/3B1N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "d5f4 f1e1 f4d3 c2d3 e8h8 f3e5 c6c5 b1c3 c8b7 b2b3",
    DEPTH: 60,
  },
  {
    FEN: "r1bq1rk1/p4ppp/2pb4/n2np3/8/3B1N2/PPPP1PPP/RNBQ1RK1 w - -",
    EVAL: 39,
    MOVES: "f1e1 f7f5 f3e5 d8f6 e5f3 g7g5 c2c4 d5f4 d3f1 g5g4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 14,
    MOVES: "e1h1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 b1d2 e4c5",
    DEPTH: 56,
  },
  {
    FEN: "r1bq1rk1/p4ppp/2pb4/n2np3/8/3B1N2/PPPP1PPP/RNBQR1K1 b - -",
    EVAL: 28,
    MOVES: "f7f5 f3e5 d8f6 e5f3 g7g5 c2c4 d5f4 d3f1 g5g4 d2d4",
    DEPTH: 40,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/1qp5/3p4/3PP3/5P2/PPP3PP/RNBQKBNR w KQkq -",
    EVAL: 19,
    MOVES: "b1c3 e7e6 a2a3 f8e7 b2b3 b6c7 c1b2 a7a6 f1d3 g8f6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqr1k1/p4ppp/2pb4/n2np3/8/3B1N2/PPPP1PPP/RNBQR1K1 w - -",
    EVAL: 71,
    MOVES: "b1c3 f7f5 d3f1 e5e4 c3d5 c6d5 f3d4 a5c4 d2d3 c4e5",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pp2pp1p/3p1np1/6B1/2PQP3/2N5/PP3PPP/R3KB1R b KQkq -",
    EVAL: 36,
    MOVES: "f8g7 a1c1 h7h6 g5d2 e8h8 f1e2 d8b6 d4b6 a7b6 e2d3",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 15,
    MOVES: "e2e3 d7d5 b1d2 b8c6 c2c3 f6h5 f4g5 f7f6 g5h4 g7g6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/3p4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 39,
    MOVES: "f8e7 d2d4 e5d4 f3d4 g8f6 b1c3 e8h8 e1h1 f6e4 c3e4",
    DEPTH: 45,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/1qp5/3p4/3PP3/2N2P2/PPP3PP/R1BQKBNR b KQkq -",
    EVAL: 14,
    MOVES: "d5e4 f1c4 g8f6 f3e4 e7e5 d4e5 f6g4 d1e2 g4e5 c4b3",
    DEPTH: 43,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/1qp5/3pp3/3PP3/2N2P2/PPP3PP/R1BQKBNR w KQkq -",
    EVAL: 53,
    MOVES: "d4e5 d5e4 f1c4 b8d7 e5e6 f7e6 c3e4 b6b4 e4d2 d7b6",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR b KQkq -",
    EVAL: 7,
    MOVES: "d8d5 g1f3 c5d4 f1c4 d5d6 e1h1 g8f6 d2b3 b8c6 b3d4",
    DEPTH: 52,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/4p3/2pp4/1b1P1B2/2N1P3/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 39,
    MOVES: "g1e2 b8c6 a2a3 b4c3 e2c3 c5d4 e3d4 g8e7 d1d2 e8h8",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/3p1np1/6B1/2PQP3/2N5/PP3PPP/R3KB1R w KQkq -",
    EVAL: 39,
    MOVES: "f1e2 e8h8 e1h1 h7h6 g5d2 d8b6 d4b6 a7b6 a2a4 c8d7",
    DEPTH: 33,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/4p3/2pq4/3P4/5N2/PPPN1PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 f1c4 d5d6 e1h1 g8f6 d2b3 b8c6 b3d4 a7a6 c4b3",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/3p1np1/6B1/2PQP3/2N5/PP2BPPP/R3K2R b KQkq -",
    EVAL: 31,
    MOVES: "e8h8 d4d2 a7a6 a1c1 c8e6 b2b3 d8a5 e2f3 f8c8 c3d5",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 35,
    MOVES: "g8f6 c2c4 e7e6 b1c3 f8e7 a2a3 e8h8 b2b4 d5c4 f1c4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R w KQkq -",
    EVAL: 21,
    MOVES: "e1h1 a7a6 d1e2 g8f6 f1d1 b7b5 c4d3 c8b7 a2a4 b5b4",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/2P5/5B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: -16,
    MOVES: "f8c5 e2e3 d8b6 d1c1 b8c6 a2a3 f6h5 f4g3 c5e7 c2c4",
    DEPTH: 31,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/4p3/3q4/2Bp4/5N2/PPPN1PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d5d6 e1h1 g8f6 d2b3 d6c7 d1d4 b8c6 d4h4 f8e7 f1e1",
    DEPTH: 43,
  },
  {
    FEN: "rn1qkb1r/pbpp1pp1/1p2pn1p/8/3P1B2/4PN2/PPPN1PPP/R2QKB1R w KQkq -",
    EVAL: 21,
    MOVES: "h2h3 f8d6 f3e5 d6e7 c2c3 d7d6 e5f3 e8h8 f1d3 c7c5",
    DEPTH: 32,
  },
  {
    FEN: "rnb1k1nr/1pqp1ppp/p3p3/8/1b1NP3/2NB4/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 70,
    MOVES: "e1h1 g8f6 c1d2 b4c5 d4b3 c5e7 a2a4 e8h8 a4a5 d7d5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/2b5/5B2/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "e2e3 d8b6 d1c1 b8c6 a2a3 f6h5 f4g3 d7d5 c2c4 c5e7",
    DEPTH: 34,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n2np1/q7/3NP3/1BN1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: -176,
    MOVES: "f6e4 d1f3 f7f5 e1a1 e4c3 b2c3 a5c3 d4b5 c3a1 c1d2",
    DEPTH: 40,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R w KQ -",
    EVAL: 77,
    MOVES: "e1a1 a8c8 d4c6 d7c6 c4b3 b7b5 e3a7 b5b4 c3d5 f6d5",
    DEPTH: 45,
  },
  {
    FEN: "rn1q1rk1/pp2ppbp/3p1np1/2pP4/2P1P1b1/2N2N2/PP2BPPP/R1BQ1RK1 b - -",
    EVAL: 61,
    MOVES: "e7e6 d1c2 e6d5 e4d5 b8d7 h2h3 g4f3 e2f3 f6e8 c1d2",
    DEPTH: 30,
  },
  {
    FEN: "rnb1k1nr/1p1p1ppp/p3p3/8/3NP3/2qB4/P1P2PPP/R1BQ1RK1 w kq -",
    EVAL: 329,
    MOVES: "a1b1 g8e7 c1b2 c3c5 d4b3 c5c7 b2g7 h8g8 g7a1 d7d6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq -",
    EVAL: 11,
    MOVES: "f8b4 c1d2 b4e7 g1f3 e8h8 e1h1 c7c6 d1c2 b8d7 f1c1",
    DEPTH: 58,
  },
  {
    FEN: "3R4/8/4k3/8/2K5/8/8/8 b - -",
    EVAL: "M13",
    MOVES: "e6e5 d8e8 e5f4 c4d3 f4g5 d3e4 g5f6 e8e5 f6g6 e5e6",
    DEPTH: 62,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 67,
    MOVES: "d2d4 d7d6 f1d3 g8f6 h2h3 e8h8 e1h1 b8d7 c2c4 e7e5",
    DEPTH: 44,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2N5/PPPB1PPP/R2QKB1R b KQkq -",
    EVAL: -18,
    MOVES: "d8e7 f1d3 b4c3 b2c3 d7d5 e1h1 d5e4 f1e1 e8h8 d2g5",
    DEPTH: 52,
  },
  {
    FEN: "r2qkb1r/pbpn1pp1/1p2pn1p/3p4/3P1B2/2PBPN2/PP1N1PPP/R2QK2R w KQkq -",
    EVAL: 76,
    MOVES: "f3e5 d7e5 f4e5 f6d7 e5f4 f8d6 f4d6 c7d6 d1e2 e6e5",
    DEPTH: 22,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 b - -",
    EVAL: 0,
    MOVES: "d5c4 d3c4 c5d4 e3d4 b7b6 d1e2 h7h6 a2a3 b4c3 b2c3",
    DEPTH: 54,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/3p1np1/6B1/2P1P3/2N5/PP1QBPPP/R3K2R b KQ -",
    EVAL: 26,
    MOVES: "c8e6 e1h1 a7a6 a1c1 d8a5 b2b3 b7b5 c3d5 a5d2 d5e7",
    DEPTH: 29,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R b - -",
    EVAL: 45,
    MOVES: "a8c8 c4b3 c6d4 e3d4 b7b5 c3d5 f6d5 d4g7 g8g7 e4d5",
    DEPTH: 44,
  },
  {
    FEN: "rnb1k1nr/1p1p1ppp/p3p3/8/3NP3/2qB4/P1P2PPP/1RBQ1RK1 b kq -",
    EVAL: 203,
    MOVES: "g8e7 c1b2 c3c7 d1g4 e7g6 d4f5 e6f5 e4f5 e8h8 f5g6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR w KQkq -",
    EVAL: 23,
    MOVES: "g1f3 a7a6 f3e5 f8b4 b1c3 f6d5 e1h1 e8h8 c3e4 b7b5",
    DEPTH: 46,
  },
  {
    FEN: "r2q1rk1/pp2ppbp/3pbnp1/6B1/2P1P3/2N5/PP1QBPPP/R3K2R w KQ -",
    EVAL: 44,
    MOVES: "e1h1 a7a6 a1c1 d8a5 b2b3 f8c8 e2f3 a8b8 f1e1 b7b5",
    DEPTH: 33,
  },
  {
    FEN: "r2q1rk1/pp2ppbp/3pbnp1/6B1/2P1P3/2N5/PP1QBPPP/R4RK1 b - -",
    EVAL: 39,
    MOVES: "a7a6 f1c1 a8c8 b2b3 b7b5 e4e5 d6e5 c4b5 d8d2 g5d2",
    DEPTH: 29,
  },
  {
    FEN: "r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -",
    EVAL: 53,
    MOVES: "c4d5 e6d5 d4c5 b4c5 h2h3 f8e8 b2b3 d5d4 c3a4 c5f8",
    DEPTH: 49,
  },
  {
    FEN: "3R4/8/8/2K5/4k3/8/8/8 w - -",
    EVAL: "M12",
    MOVES: "d8e8 e4f4 c5d5 f4f3 e8e4 f3f2 d5e5 f2g3 e4f4 g3h3",
    DEPTH: 81,
  },
  {
    FEN: "8/8/8/2KR4/4k3/8/8/8 b - -",
    EVAL: "M11",
    MOVES: "e4f4 c5d4 f4f3 d5f5 f3g4 d4e4 g4g3 e4e3 g3g4 f5a5",
    DEPTH: 78,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 50,
    MOVES: "d7d6 c1e3 g8f6 b1c3 e8h8 d1d2 b8d7 h2h3 c7c6 a2a4",
    DEPTH: 50,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R w - -",
    EVAL: 54,
    MOVES: "d4c6 d7c6 c4b3 a7a5 a2a4 d8e8 d2e2 f6d7 h2h4 d7c5",
    DEPTH: 46,
  },
  {
    FEN: "r3kb1r/p2nqppp/5n2/1B2p1B1/4P3/1Q6/PPP2PPP/2KR3R b kq -",
    EVAL: 804,
    MOVES: "a8b8 g5f6 b8b5 b3b5 g7f6 d1d3 f6f5 h1d1 f8h6 c1b1",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "e4f6 e7f6 c2c3 f8d6 f1d3 e8h8 d1c2 f8e8 g1e2 h7h6",
    DEPTH: 46,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/1n1p2p1/8/2PP4/1PN1B3/P4PPP/2RQKBNR b K -",
    EVAL: 63,
    MOVES: "e7e5 g1f3 b8c6 f1e2 d6d5 c4c5 b6d7 c3d5 e5d4 f3d4",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/p1p2ppp/4pn2/1p6/2pP4/5NP1/PP2PPBP/RNBQK2R w KQkq -",
    EVAL: 85,
    MOVES: "a2a4 c8b7 a4b5 f8e7 e1h1 e8h8 b1c3 f6d5 f3e5 d5c3",
    DEPTH: 48,
  },
  {
    FEN: "r3k2r/ppp2pp1/2np4/2B1p3/2B1PnNq/3P1P2/PPP3P1/RN1Q1RK1 b kq -",
    EVAL: -1307,
    MOVES: "f4h3 g1h2 h3f2 h2g1 f2d1 f1d1 h4h1 g1f2 h1d1 c5e3",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2NB4/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 48,
    MOVES: "g8f6 d1e2 f8b4 c1d2 d7d6 e1h1 b4c3 d2c3 e6e5 d4f3",
    DEPTH: 45,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3b1n2/3p4/3P4/3B1N2/PPP2PPP/RNBQR1K1 b - -",
    EVAL: 0,
    MOVES: "c8g4 c1g5 h7h6 g5h4 b8d7 b1d2 c7c6 h2h3 g4f3 d1f3",
    DEPTH: 59,
  },
  {
    FEN: "r3k2r/ppp2pp1/2np4/2B1p3/2B1PnNq/3P4/PPP2PP1/RN1Q1RK1 w kq -",
    EVAL: -1161,
    MOVES: "f2f3 f4h3 g1h2 h3f2 h2g1 f2d1 f1d1 h4h1 g1f2 h1d1",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: 23,
    MOVES: "g8f6 d2d4 f6e4 d4e5 d7d6 d1e2 d6d5 e1h1 e4c3 b1c3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/1p2pppp/p1p5/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "d5e4 c3e4 g8f6 e4f6 e7f6 f1c4 f8d6 d1e2 d8e7 e1h1",
    DEPTH: 43,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2NB4/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 13,
    MOVES: "d1e2 f8d6 h2h3 e8h8 e1h1 b8c6 d4c6 d7c6 f2f4 e6e5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R b KQkq -",
    EVAL: 23,
    MOVES: "b8c6 d1a4 f8b4 c1d2 f6d5 d2b4 d5b4 e1h1 a8b8 b1a3",
    DEPTH: 53,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/1nnp2p1/3P4/2P5/1PN1B3/P4PPP/2RQKBNR b K -",
    EVAL: 139,
    MOVES: "c6e5 f1e2 f8e8 g1f3 e7e6 d5e6 c8e6 f3d4 d6d5 d4e6",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 7,
    MOVES: "b1c3 f8b4 f1d3 d7d5 e4d5 c6d5 e1h1 e8h8 h2h3 c7c6",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: 14,
    MOVES: "h7h6 g5f6 d8f6 e2e3 b8d7 f1e2 f8b4 d1b3 f6e7 a2a3",
    DEPTH: 50,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2NB4/PPP2PPP/R1BQ1RK1 b kq -",
    EVAL: 36,
    MOVES: "f8e7 a2a4 b8c6 d4c6 b7c6 a4a5 e8h8 d1f3 c8b7 c1f4",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkbnr/1p2pppp/p1p5/3p4/3PP1b1/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 59,
    MOVES: "h2h3 g4f3 d1f3 e7e6 f1d3 b8d7 c1e3 d5e4 c3e4 g8f6",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "a7a6 d2d4 c5d4 f3d4 b8c6 c1e3 g8f6 a2a3 f8e7 g2g4",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 75,
    MOVES: "d2d4 g8f6 b1c3 e7e5 g1f3 b8d7 f1c4 f8e7 e1h1 e8h8",
    DEPTH: 51,
  },
  {
    FEN: "rnbq1rk1/pp3pbp/1n1p2p1/4p3/2PP4/1PN1B3/P4PPP/2RQKBNR w K -",
    EVAL: 43,
    MOVES: "g1f3 b8c6 f1e2 c8g4 d4e5 d6e5 e1h1 h7h6 h2h3 g4e6",
    DEPTH: 50,
  },
  {
    FEN: "rn1qkbnr/1p2pppp/p1p5/3p4/3PP1b1/2NB1N2/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 63,
    MOVES: "e7e6 h2h3 g4h5 e1h1 g8f6 f1e1 f8e7 c1f4 e8h8 a2a3",
    DEPTH: 26,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/2b5/5B2/2N2N2/PPP1PPPP/R2QKB1R b KQkq -",
    EVAL: -30,
    MOVES: "d7d5 e2e3 e8h8 f1d3 b8d7 e1h1 f8e8 f4g3 a7a6 e3e4",
    DEPTH: 41,
  },
  {
    FEN: "r4rk1/pp2ppbp/3pbnp1/q5B1/2P1P3/2N5/PP1QBPPP/R4RK1 w - -",
    EVAL: 34,
    MOVES: "f1c1 f8c8 b2b3 b7b5 e4e5 f6d7 b3b4 a5b6 c4b5 d7e5",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkbnr/1p3ppp/p1p1p3/3p4/3PP1b1/2NB1N2/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 21,
    MOVES: "h2h3 g4h5 e1h1 g8f6 c1e3 f8e7 d1e2 e8h8 f1d1 b8d7",
    DEPTH: 28,
  },
  {
    FEN: "rnb1k2r/pp1p1ppp/1q2pn2/2b5/5B2/2N2N2/PPP1PPPP/R2QKB1R w KQkq -",
    EVAL: -20,
    MOVES: "e2e3 b6b2 c3b5 c5b4 f3d2 b4d2 e1d2 e8h8 a1b1 b2a2",
    DEPTH: 29,
  },
  {
    FEN: "rnb1k2r/1pqp1ppp/p3pn2/2b5/3NP3/2NB4/PPP2PPP/R1BQ1RK1 w kq -",
    EVAL: 43,
    MOVES: "d4b3 c5a7 g1h1 d7d6 f2f4 b8c6 d1f3 h7h5 c1e3 a7e3",
    DEPTH: 52,
  },
  {
    FEN: "rn1qkbnr/1p3ppp/p1p1p3/3p4/3PP1b1/2NB1N1P/PPP2PP1/R1BQK2R b KQkq -",
    EVAL: 65,
    MOVES: "g4h5 e1h1 b8d7 f1e1 f8b4 a2a3 d5e4 d3e4 b4e7 c3e2",
    DEPTH: 25,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 49,
    MOVES: "g1f3 e7e6 g2g3 g8f6 c2c4 f6e4 f1g2 d7d5 b1c3 c7c6",
    DEPTH: 57,
  },
  {
    FEN: "b3r3/p4k2/1bn1p1N1/1Rp1Pp1P/P1Pp2p1/3P2P1/3B1PB1/6K1 b - -",
    EVAL: 572,
    MOVES: "e8c8 f2f3 g4f3 g2f3 b6d8 b5c5 d8b6 c5b5 b6c7 g1g2",
    DEPTH: 28,
  },
  {
    FEN: "2br2k1/5pp1/1p3q1p/2pBpP2/2P1P3/P6P/2Q3P1/5RK1 w - -",
    EVAL: 173,
    MOVES: "c2b3 d8d6 a3a4 f6e7 b3b5 c8b7 a4a5 b7d5 e4d5 b6a5",
    DEPTH: 46,
  },
  {
    FEN: "rnb1k2r/1pqpbppp/p3pn2/8/4P3/1NNB4/PPP2PPP/R1BQ1RK1 w kq -",
    EVAL: 34,
    MOVES: "a2a4 b8c6 a4a5 b7b6 a5b6 c7b6 d1e2 c6e5 c1e3 b6c7",
    DEPTH: 63,
  },
  {
    FEN: "rn1qkbnr/1p3ppp/p1p1p3/3p3b/3PP3/2NB1N1P/PPP2PP1/R1BQK2R w KQkq -",
    EVAL: 43,
    MOVES: "a2a3 g8f6 e1h1 f8e7 c1e3 e8h8 d1e2 h5g6 e3d2 f6e4",
    DEPTH: 27,
  },
  {
    FEN: "rnb1k2r/1pqpbppp/p3pn2/8/4PP2/1NNB4/PPP3PP/R1BQ1RK1 b kq -",
    EVAL: 30,
    MOVES: "d7d6 d1f3 b8d7 a2a4 b7b6 c1e3 c8b7 f3h3 a8d8 g1h1",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/8/PPP2PPP/RNBQK1NR w KQkq -",
    EVAL: -16,
    MOVES: "g1f3 f8b4 c2c3 d4c3 b2c3 d7d5 e4d5 b4e7 e1h1 e8h8",
    DEPTH: 59,
  },
  {
    FEN: "r1b1nrk1/pp2qppp/2nbp3/1N4B1/2B5/5N2/PP2QPPP/R2R2K1 b - -",
    EVAL: 93,
    MOVES: "f7f6 g5e3 d6c7 f3d4 g8h8 a1c1 c8d7 d4c6 b7c6 b5c7",
    DEPTH: 34,
  },
  {
    FEN: "rnb1k2r/1pq1bppp/p2ppn2/8/4PP2/1NNB4/PPP3PP/R1BQ1RK1 w kq -",
    EVAL: 69,
    MOVES: "a2a4 b8c6 d1f3 c6b4 c1e3 e8h8 a4a5 f6d7 f3f2 d7f6",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pp3pp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "d5c4 e2e4 g7g5 h4g3 b7b5 f3e5 c8b7 h2h4 g5g4 e5g4",
    DEPTH: 45,
  },
  {
    FEN: "r1b1nrk1/pp2q1pp/2nbpp2/1N6/2B5/4BN2/PP2QPPP/R2R2K1 b - -",
    EVAL: 55,
    MOVES: "b7b6 a1c1 c6a5 c4d3 c8b7 a2a3 b7d5 b2b4 a5c6 b5c3",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/8/8/3QP3/8/PPP2PPP/RNB1KB1R b KQkq -",
    EVAL: 47,
    MOVES: "d8f6 d4c4 f8d6 b1c3 g8e7 c3b5 e7c6 f1e2 d6b4 c2c3",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/pp3pp1/2p1pn1p/8/2pPP2B/2N2N2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "g7g5 h4g3 b7b5 f1e2 c8b7 e1h1 b8d7 f3e5 f8g7 e5d7",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pppppppp/2n2n2/8/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 41,
    MOVES: "e2e3 d7d6 g1f3 f6h5 b1c3 a7a6 f1c4 h5f4 e3f4 c6a5",
    DEPTH: 36,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/5n2/2pp4/1b1P4/2NBP3/PP2NPPP/R1BQK2R w KQ -",
    EVAL: 32,
    MOVES: "a2a3 b4c3 b2c3 b7b6 e1h1 c8a6 d3a6 b8a6 d1d3 a6c7",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pppppppp/2n2n2/8/3P1B2/4P3/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 23,
    MOVES: "d7d6 g1f3 f6h5 b1c3 a7a6 f1c4 h5f4 e3f4 c6a5 c4e2",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3pP3/3P4/3P4/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 b1c3 g8e7 h2h4 e7f5 g1f3 h7h5 c1g5 d8b6 c3e2",
    DEPTH: 59,
  },
  {
    FEN: "rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQ1RK1 b - -",
    EVAL: 78,
    MOVES: "b8d7 f1e1 d7e5 f3e5 e8e5 a2a4 e5e8 d3f1 f6h5 c1e3",
    DEPTH: 34,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R b - -",
    EVAL: 33,
    MOVES: "c6d4 e3d4 b7b5 c3d5 f6d5 d4g7 g8g7 e4d5 a7a5 a2a3",
    DEPTH: 53,
  },
  {
    FEN: "rn2kbnr/pp1q1ppp/3p4/4p3/4P3/8/PPP1NPPP/RNBQK2R b KQkq -",
    EVAL: 79,
    MOVES: "d7c6 b1c3 b8d7 e2g3 g7g6 h2h4 h7h5 c1g5 g8f6 d1e2",
    DEPTH: 40,
  },
  {
    FEN: "r3qrk1/pppnppbp/2np2pB/8/3PP3/2N1Qb2/PPP2PPP/2KR1B1R w - -",
    EVAL: 145,
    MOVES: "g2f3 e7e5 d4e5 e8e5 h6g7 g8g7 c3d5 d7f6 d5c7 a8c8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 h2h3 b8c6 f1d3 e7e5 d3c2 d6d5 d2d3 h7h6 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/3p1np1/6B1/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 49,
    MOVES: "f8g7 g1f3 e8h8 d1d2 c8g4 h2h4 c7c5 d4d5 d8b6 f3h2",
    DEPTH: 42,
  },
  {
    FEN: "r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3PB2/2NB1N1P/1P3PP1/R2Q1RK1 b - -",
    EVAL: 162,
    MOVES: "c5c4 d3c2 d7e5 f3e5 d6e5 f4e3 b7b6 b2b3 c4b3 c2b3",
    DEPTH: 35,
  },
  {
    FEN: "rn1qk1nr/pbppbppp/1p2p3/6B1/3P4/2P2N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: -3,
    MOVES: "g5e7 g8e7 b1d2 e8h8 e2e3 d7d6 f1d3 b8d7 e1h1 c7c5",
    DEPTH: 23,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/4p3/4N3/8/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -112,
    MOVES: "b8c6 f1c4 f7f5 e4c3 g8f6 e1h1 a7a6 c3e2 f8d6 a2a4",
    DEPTH: 40,
  },
  {
    FEN: "8/8/2B3N1/5p2/6p1/6pk/4K2b/7r w - -",
    EVAL: "M4",
    MOVES: "c6g2 h3g2 g6f4 g2g1 e2e1 g3g2 f4e2",
    DEPTH: 245,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/1B1pN3/8/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c8d7 e5d7 d8d7 d2d4 e7e6 c1f4 f8d6 f4d6 d7d6 c2c3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f3d4 g8f6 d4c6 b7c6 f1d3 d7d5 b1c3 f8b4 e4d5 e8h8",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/5n2/3p4/3p4/2N3P1/PP2PPBP/R1BQK1NR w KQkq -",
    EVAL: -24,
    MOVES: "d1d4 b8c6 d4a4 d5d4 c3e4 f8e7 e4f6 e7f6 g1h3 e8h8",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p2n2/4p3/3pP3/3N4/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "d3e5 f8d6 g1f3 e8h8 f1c4 f6e4 e1h1 b8d7 e5d7 d8d7",
    DEPTH: 25,
  },
  {
    FEN: "rnbqk1nr/1p1pbppp/p3p3/8/4P3/1N1B4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 84,
    MOVES: "b1c3 b8c6 c1e3 d7d5 f2f4 d5e4 d3e4 d8c7 d1f3 g8f6",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/3P4/3Q4/PPP1PPPP/RNB1KBNR b KQkq -",
    EVAL: 25,
    MOVES: "b8c6 g1f3 e7e6 g2g3 g8f6 a2a3 d7d6 f1g2 d8e7 b1c3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/2BpP3/8/PPP2PPP/RNBQK1NR b KQkq -",
    EVAL: -25,
    MOVES: "g8f6 g1f3 f8b4 c2c3 d4c3 b2c3 b4c5 e1h1 d7d5 e4d5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/5n2/2pp4/3P4/2N3P1/PP2PPBP/R1BQK1NR b KQkq -",
    EVAL: -45,
    MOVES: "c5d4 d1d4 b8c6 d4d1 d5d4 c3e4 f6e4 g2e4 f8b4 c1d2",
    DEPTH: 38,
  },
  {
    FEN: "rn1qkb1r/pp2nppp/2p1p3/3pP3/3P4/2NP1N2/PP3PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e7f5 c3e2 c6c5 d4c5 f8c5 d3d4 c5e7 e1h1 b8c6 a2a3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk1nr/1p1nbp1p/p2pp1p1/8/4P3/1N1B4/PPP1QPPP/RNB2RK1 w kq -",
    EVAL: 84,
    MOVES: "c2c4 b7b6 b1c3 d7e5 f1d1 g8f6 c1g5 d8c7 f2f4 e5d7",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkb1r/pppp2p1/4pn1p/5pB1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: 47,
    MOVES: "g5d2 b7b6 g1f3 f8b4 g2g3 c8b7 f1g2 e8h8 e1h1 b4c3",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/8/2BQn3/2P5/PP3PPP/RNB1K1NR b KQkq -",
    EVAL: -197,
    MOVES: "d8e7 c4e2 b8c6 d4d1 c6e5 c1e3 e4f6 d1d4 d7d5 g1f3",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/5n2/3pP3/2Bp4/8/PPP2PPP/RNBQK1NR w KQkq d6",
    EVAL: -111,
    MOVES: "c4b3 f6e4 g1e2 f8c5 e1h1 c8g4 d1e1 c7c6 c2c4 d4c3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -",
    EVAL: 22,
    MOVES: "b1c3 d7d5 d2d4 f8b4 c4d5 e6d5 c1g5 h7h6 g5h4 c7c5",
    DEPTH: 48,
  },
  {
    FEN: "rnb1kb1r/pppp2p1/4pq1p/5p2/2PPP3/2N5/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "f5e4 a2a3 b8c6 d4d5 e4e3 f2e3 c6e5 d1d4 b7b6 c3e4",
    DEPTH: 34,
  },
  {
    FEN: "r1bq1rk1/pppp1pp1/2n2n1p/2b1p3/2B1P3/2NP1N2/PPPB1PPP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "a7a6 a2a4 d7d6 d2e3 c5e3 f2e3 c8e6 b2b3 c6a5 c4e6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 28,
    MOVES: "e2e3 b8d7 d1c2 f8d6 f1d3 d5c4 d3c4 b7b5 c4e2 e8h8",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppp3pp/8/4pp2/4N3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: -98,
    MOVES: "e4c3 b8c6 f1c4 g8f6 e1h1 a7a6 a2a4 d8d6 b1a3 f5f4",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/3P4/2P5/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e5e4 c3c4 c7c6 c1f4 d7d5 e2e3 f8b4 b1c3 g8e7 a1c1",
    DEPTH: 51,
  },
  {
    FEN: "r3k1nr/1bqnbp1p/pp1pp1p1/8/4PP2/1NNB4/PPPBQ1PP/4RRK1 b kq -",
    EVAL: 36,
    MOVES: "b6b5 g1h1 g8f6 e4e5 f6h5 e5d6 e7d6 c3e4 e8h8 e4d6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/3P4/2P5/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 34,
    MOVES: "d4d5 c6e7 e2e4 g8f6 f2f3 e7g6 c1e3 b7b6 d1d2 f8c5",
    DEPTH: 34,
  },
  {
    FEN: "rnb1kb1r/pppp2p1/4pq1p/5p2/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: 13,
    MOVES: "g2g3 b8c6 g1f3 f8b4 f1g2 e8h8 e1h1 b4c3 b2c3 d7d6",
    DEPTH: 32,
  },
  {
    FEN: "r3k2r/1bqnbp1p/pp1ppnp1/8/4PP2/1NNB4/PPPBQ1PP/4RRK1 w kq -",
    EVAL: 44,
    MOVES: "e4e5 f6h5 e5d6 e7d6 c3e4 e8h8 e4d6 c7d6 d2e3 h5g7",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/2n5/1B1np1N1/8/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 101,
    MOVES: "d8f6 b1c3 c8b7 c3d5 f6g5 d1f3 e8a8 b5c6 b7c6 d5e7",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/2P5/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: -186,
    MOVES: "f6e4 d1d4 e4f6 c1g5 f8e7 g1f3 e8h8 e1h1 c7c6 c4d3",
    DEPTH: 39,
  },
  {
    FEN: "r1b1Rq1k/ppp3pp/2np1r2/5P2/2B2P2/2Q4P/PB4P1/5RK1 b - -",
    EVAL: 914,
    MOVES: "f8e8 c3f6 c6e5 f6g5 h7h6 g5h4 c8f5 f4e5 d6d5 c4d5",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/3P4/PPP1PPPP/RNBQKBNR b KQkq -",
    EVAL: -9,
    MOVES: "d7d5 g1f3 g8f6 g2g3 g7g6 f1g2 f8g7 c2c4 e8h8 c4d5",
    DEPTH: 53,
  },
  {
    FEN: "r3k2r/1bqnbp1p/pp2p1p1/3nP3/4N3/1N1B4/PPPBQ1PP/4RRK1 b kq -",
    EVAL: 130,
    MOVES: "e8h8 e4f2 h7h5 d2h6 a8c8 c2c3 b6b5 a2a3 f8e8 f2h3",
    DEPTH: 29,
  },
  {
    FEN: "6k1/5pp1/4b1n1/8/8/3BR3/5PPP/6K1 w - -",
    EVAL: "M21",
    MOVES: "d3g6 e6d7 e3e7 d7b5 e7f7 b5e8 f7g7 g8g7 g6e8 g7f6",
    DEPTH: 80,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/3q4/8/8/2N2N2/PP1PPPPP/R1BQKB1R b KQkq -",
    EVAL: 59,
    MOVES: "e7e5 e2e4 b8c6 d2d4 c6d4 f3d4 d6d4 d1d4 e5d4 c3b5",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 37,
    MOVES: "f8c5 d4b3 c5a7 d1e2 g8e7 c1e3 e8h8 e3a7 a8a7 e2e3",
    DEPTH: 49,
  },
  {
    FEN: "r1b1q2k/ppp3pp/2np1r2/5P2/2B2P2/2Q4P/PB4P1/5RK1 w - -",
    EVAL: 933,
    MOVES: "c3f6 c6e5 f6g5 h7h6 g5h4 c8f5 f4e5 d6d5 c4d5 e8g6",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 33,
    MOVES: "a4b3 e8h8 h2h3 c8b7 d2d3 d7d6 a2a3 c6a5 b3a2 c7c5",
    DEPTH: 50,
  },
  {
    FEN: "R7/8/8/7p/8/4n1k1/3r4/4K3 b - -",
    EVAL: "M-19",
    MOVES: "d2b2 a8e8 e3g4 e8e3 g3g2 e3e4 g2f3 e4e7 h5h4 e7h7",
    DEPTH: 84,
  },
  {
    FEN: "r1b1q2k/ppp3pp/2np1Q2/5P2/2B2P2/7P/PB4P1/5RK1 b - -",
    EVAL: 836,
    MOVES: "c6e5 f6g5 h7h6 g5h4 c8f5 f4e5 d6d5 c4d5 e8g6 h4e7",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "b8d7 d1c2 f8d6 b2b3 e8h8 c1b2 e6e5 d4e5 d7e5 c4d5",
    DEPTH: 46,
  },
  {
    FEN: "r2qkb1r/pbp2ppp/2n5/1B1np1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 113,
    MOVES: "d2d4 a7a6 b5c6 b7c6 e1h1 e5d4 g5f3 f8c5 f3d4 c5d4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 58,
    MOVES: "b5c6 d7c6 d1e2 c8f5 f1e1 h7h6 f3e5 f8e7 b1d2 e4d6",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/8/3P4/PPPNPPPP/R1BQKBNR b KQkq -",
    EVAL: -18,
    MOVES: "d7d5 g1f3 b8c6 e2e4 g8f6 a2a3 a7a5 e4d5 f6d5 g2g3",
    DEPTH: 35,
  },
  {
    FEN: "r1b1q2k/ppp4p/2np1p2/5P2/2B2P2/7P/PB4P1/5RK1 w - -",
    EVAL: "M1",
    MOVES: "b2f6",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 11,
    MOVES: "f1e2 f8d6 e1h1 e8h8 d1c2 f8e8 a2a4 d8e7 b2b3 a7a5",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/5n2/2pp4/3P4/6P1/PP2PPBP/RNBQK1NR w KQkq -",
    EVAL: 44,
    MOVES: "g1f3 b8c6 e1h1 f8e7 d4c5 e7c5 a2a3 f6e4 f3d2 f7f5",
    DEPTH: 29,
  },
  {
    FEN: "r1bq1rk1/pppnppbp/3p1np1/8/3PPB2/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 115,
    MOVES: "e4e5 f6h5 f4h6 d7b6 d2e3 f7f6 f1e2 f6e5 h6g7 h5g7",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3P4/8/8/PP1PPPPP/RNBQKBNR b KQkq -",
    EVAL: 66,
    MOVES: "d8d5 b1c3 d5d6 d2d4 e7e5 d4e5 d6d1 c3d1 b8c6 g1f3",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 36,
    MOVES: "e1h1 d8c7 d1e2 f8d6 h2h3 e8h8 d4f3 b8c6 f1e1 c6e5",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppppp1pp/5n2/5p2/3P4/3Q4/PPP1PPPP/RNB1KBNR w KQkq -",
    EVAL: 93,
    MOVES: "d3f5 d7d5 f5d3 c7c5 e2e3 b8c6 g1f3 c8g4 f1e2 e7e6",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 58,
    MOVES: "d8c7 d1e2 d7d6 c2c4 f8e7 f2f4 e8h8 b1c3 b8c6 d4c6",
    DEPTH: 39,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/8/3n1b2/3P4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 93,
    MOVES: "b1d2 d5b6 e2e4 f5g6 h2h4 h7h6 f1d3 b8d7 h4h5 g6h7",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/2pp1npB/8/3PP3/2N5/PPPQ1PPP/2KR1BNR b - -",
    EVAL: 107,
    MOVES: "b7b5 f2f3 d8a5 h2h4 b5b4 c3b1 c8a6 f1a6 b8a6 h6g7",
    DEPTH: 45,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 67,
    MOVES: "d1e2 d7d6 c2c4 f8e7 b1c3 e8h8 c1e3 b8c6 d4c6 b7c6",
    DEPTH: 45,
  },
  {
    FEN: "8/8/8/8/6np/R5k1/3r4/5K2 b - -",
    EVAL: "M-18",
    MOVES: "g3h2 a3a4 d2g2 f1e1 h4h3 e1f1 h2g3 a4a3 g3h4 a3a7",
    DEPTH: 85,
  },
  {
    FEN: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R b KQkq -",
    EVAL: 19,
    MOVES: "d5c4 d3c4 b7b5 c4d3 c8b7 e3e4 b5b4 c3a4 c6c5 e4e5",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/ppp3pp/8/4pp2/8/2N5/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -98,
    MOVES: "b8c6 f1c4 a7a6 e1h1 g8f6 a2a4 f8c5 d2d3 d8d6 c1g5",
    DEPTH: 38,
  },
  {
    FEN: "2r1rbk1/5pp1/3P1n2/8/8/3Q3P/2B2PPK/8 w - -",
    EVAL: 338,
    MOVES: "d6d7 c8d8 d7e8q d8d3 e8e2 d3d6 c2b3 f6h7 e2e3 d6f6",
    DEPTH: 59,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q4/8/8/PP1PPPPP/RNBQKBNR w KQkq -",
    EVAL: 75,
    MOVES: "b1c3 d5d6 g1f3 e7e5 e2e3 g8f6 d1c2 b8c6 a2a3 d6e6",
    DEPTH: 46,
  },
  {
    FEN: "rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/3B4/PPP1QPPP/RNB2RK1 w kq -",
    EVAL: 61,
    MOVES: "c2c4 f8e7 b1c3 e8h8 f2f4 b8c6 d4c2 f6d7 c1e3 d7c5",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c8b7 e1h1 f8e7 e3e4 b5b4 e4e5 b4c3 e5f6 e7f6 b2c3",
    DEPTH: 48,
  },
  {
    FEN: "r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R w KQkq -",
    EVAL: 0,
    MOVES: "e1h1 f8e7 e3e4 b5b4 e4e5 b4c3 e5f6 e7f6 b2c3 c6c5",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pppp2pp/4p3/5p2/2PPP3/8/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: -61,
    MOVES: "f5e4 b1c3 g8f6 g2g4 f8b4 g4g5 f6g8 g1e2 d7d5 f1g2",
    DEPTH: 40,
  },
  {
    FEN: "rnb1kb1r/1pq2p1p/p2ppnp1/8/2PNP3/3B4/PP2QPPP/RNB2RK1 w kq -",
    EVAL: 75,
    MOVES: "c1e3 f8g7 b1c3 e8h8 a1c1 b8c6 f1d1 f8d8 h2h3 c8d7",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/8/4p3/4B3/8/PPPP1PPP/R1BQK1NR b KQkq -",
    EVAL: -58,
    MOVES: "f7f5 e4f3 f8d6 f3d5 b8c6 d1h5 g7g6 d5c6 b7c6 h5f3",
    DEPTH: 39,
  },
  {
    FEN: "rn1q1rk1/1p2bppp/p2pbn2/4p3/2B1P3/2N1BN2/PPP2PPP/R2Q1RK1 w - -",
    EVAL: 29,
    MOVES: "c4e6 f7e6 c3a4 f6g4 d1d3 g4e3 d3e3 b7b5 a4b6 a8a7",
    DEPTH: 41,
  },
  {
    FEN: "r2qkb1r/1b1n1ppp/p1p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "e3e4 c6c5 e4e5 f6d5 a2a4 c5c4 f3g5 f8e7 e2f3 d7b6",
    DEPTH: 34,
  },
  {
    FEN: "r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "f8e7 e3e4 b5b4 e4e5 b4c3 e5f6 e7f6 b2c3 c6c5 d4c5",
    DEPTH: 47,
  },
  {
    FEN: "rnb1k2r/1pq2pbp/p2ppnp1/8/2PNP3/2NB4/PP2QPPP/R1BR2K1 b kq -",
    EVAL: 93,
    MOVES: "c8d7 h2h3 b8c6 c1e3 e8h8 a1c1 f8d8 e2d2 c6e5 d3e2",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2Bn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 14,
    MOVES: "d7c6 d4e5 d6f5 d1d8 e8d8 b1c3 c8d7 h2h3 h7h6 b2b3",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pppp2pp/4pn2/5p2/3P4/3Q1N2/PPP1PPPP/RNB1KB1R w KQkq -",
    EVAL: 32,
    MOVES: "a2a3 c7c5 e2e3 b7b6 c2c4 c8b7 b1c3 g7g6 f1e2 f8g7",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp2N1/2B1P3/3P4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -36,
    MOVES: "e4d5 e6d5 c4b5 b8d7 e1h1 f8e7 d3d4 c5c4 g5f3 e8h8",
    DEPTH: 40,
  },
  {
    FEN: "8/7p/p7/4kpPP/3p4/3K4/P7/8 w - -",
    EVAL: "M15",
    MOVES: "h5h6 f5f4 g5g6 h7g6 h6h7 f4f3 h7h8q e5e6 d3d4 f3f2",
    DEPTH: 70,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 24,
    MOVES: "f8b4 c4d5 e6d5 c1f4 c7c5 d4c5 g8f6 e2e3 e8h8 f1d3",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2pn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 143,
    MOVES: "d4e5 d6f5 c1g5 f8e7 g5e7 f5e7 b1c3 d7d5 d1d4 e8h8",
    DEPTH: 32,
  },
  {
    FEN: "rn2kb1r/1bqp1ppp/p3pn2/1p6/3NP3/P1N1BP2/1PP3PP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d1d2 d7d5 e4d5 b7d5 e3f4 c7b7 e1a1 b8c6 c3d5 f6d5",
    DEPTH: 34,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPB2/2N5/PPPQ1PPP/2KR1BNR b - -",
    EVAL: 71,
    MOVES: "c7c6 f2f3 h7h5 f4h6 b8d7 h2h4 e7e5 d4e5 g7h6 d2h6",
    DEPTH: 48,
  },
  {
    FEN: "r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1 b - -",
    EVAL: 32,
    MOVES: "c7c6 d2d3 e7d6 e5e1 c8f5 d1f3 d8h4 g2g3 h4h3 b1d2",
    DEPTH: 46,
  },
  {
    FEN: "rn1q1rk1/1p2b1pp/p2ppn2/4p3/N3P3/4BN2/PPP2PPP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "f6g4 d1d3 g4e3 d3e3 b7b5 a4b6 a8a7 b6d5 a7b7 e3d2",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2pn4/4P3/8/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 77,
    MOVES: "d6f5 c1g5 f8e7 g5e7 f5e7 b1c3 e8h8 c3e4 e7f5 d1d3",
    DEPTH: 29,
  },
  {
    FEN: "r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1 w - -",
    EVAL: 7,
    MOVES: "d2d3 e7d6 e5e1 c8f5 d1f3 d8h4 g2g3 h4h3 c1e3 f5d3",
    DEPTH: 63,
  },
  {
    FEN: "2b5/7p/3k2pP/1p1p1pP1/1P1P1K2/8/5P2/3B4 w - -",
    EVAL: "M17",
    MOVES: "d1h5 c8e6 h5g6 e6g8 g6f5 d6e7 f4e5 e7e8 e5f6 e8f8",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 32,
    MOVES: "d2d4 d7d5 e4d5 d8d5 g1f3 c5d4 c3d4 c8g4 f1e2 e7e6",
    DEPTH: 49,
  },
  {
    FEN: "r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1 b - -",
    EVAL: 25,
    MOVES: "e7d6 e5e1 d8h4 g2g3 h4h3 e1e4 g7g5 d1f1 h3f1 g1f1",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 87,
    MOVES: "f1e1 f8e7 d1d3 d7d5 e5d6 d8d6 d3e4 e8h8 c1f4 d6b4",
    DEPTH: 36,
  },
  {
    FEN: "r1b4k/ppp1q1pp/2np1Q2/5P2/2B2P2/7P/PB4P1/5RK1 w - -",
    EVAL: 2139,
    MOVES: "f6e7 c6e7 f1e1 c8e6 e1e6 e7f5 g2g4 b7b5 c4d5 c7c6",
    DEPTH: 39,
  },
  {
    FEN: "r1bqr1k1/p4ppp/2pb4/n2np3/8/2NB1N2/PPPP1PPP/R1BQR1K1 b - -",
    EVAL: 71,
    MOVES: "d5b4 d3f1 c8g4 d2d3 g4f3 g2f3 b4d5 f3f4 d5f4 c1f4",
    DEPTH: 45,
  },
  {
    FEN: "r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1 w - -",
    EVAL: 52,
    MOVES: "e5e2 c8g4 f2f3 g4f5 b3d5 c6d5 b1d2 f5d3 e2f2 d3g6",
    DEPTH: 44,
  },
  {
    FEN: "q3kbnr/p1pn1ppp/8/4p3/2Pp2b1/3P1P2/PP2P2P/RNBQK1NR b KQk -",
    EVAL: -84,
    MOVES: "f8e7 h2h4 g8f6 e2e4 g4e6 h4h5 a7a5 b1d2 h7h6 g1e2",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/3p2p1/8/3NP1n1/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 623,
    MOVES: "f1b5 b8c6 d4c6 b7c6 b5c6 c8d7 c6a8 g4e3 f2e3 d8a8",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 17,
    MOVES: "d1h5 e4d6 h5e5 d8e7 e5e7 f8e7 c4e2 b8c6 g1f3 d6f5",
    DEPTH: 56,
  },
  {
    FEN: "8/8/5p2/4k1pp/2P4P/4K1P1/8/8 w - -",
    EVAL: "M21",
    MOVES: "g3g4 h5g4 h4h5 f6f5 h5h6 f5f4 e3f2 e5f6 c4c5 g4g3",
    DEPTH: 56,
  },
  {
    FEN: "r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP3PPP/RNBQR1K1 b - -",
    EVAL: 2,
    MOVES: "d8h4 g2g3 h4h3 e1e4 g7g5 d1f1 h3f1 g1f1 c8f5 e4e1",
    DEPTH: 43,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/4pn2/3p1b2/2PP4/1QN2N2/PP3PPP/R1B1KB1R b KQkq -",
    EVAL: 56,
    MOVES: "d5c4 b3b7 b8d7 f1c4 f8d6 b7a6 d8b6 e1h1 e8h8 f1e1",
    DEPTH: 33,
  },
  {
    FEN: "r1bqr1k1/p5pp/2pb4/n2npp2/8/2NB1N2/PPPP1PPP/R1BQR1K1 w - -",
    EVAL: 76,
    MOVES: "d3f1 e5e4 c3d5 c6d5 f3d4 d8f6 c2c3 a5c4 d2d3 c4e5",
    DEPTH: 37,
  },
  {
    FEN: "r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP5/PP3PPP/RNBQR1K1 w - -",
    EVAL: 0,
    MOVES: "g2g3 h4h3 d1e2 c8g4 e2f1 h3h5 b1d2 a8e8 e1e8 f8e8",
    DEPTH: 47,
  },
  {
    FEN: "r1bqr1k1/p5pp/2pb4/n2Npp2/8/3B1N2/PPPP1PPP/R1BQR1K1 b - -",
    EVAL: 62,
    MOVES: "c6d5 d3f1 a5c6 f1b5 c8b7 d2d3 d8c7 c1e3 h7h6 c2c4",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P4/2N5/PPP1PPPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 c1f4 e7e6 c3b5 b8a6 e2e3 f8e7 g1f3 e8h8 f1e2",
    DEPTH: 60,
  },
  {
    FEN: "8/8/1p1k4/7p/PP1Kpp2/8/5PP1/8 b - -",
    EVAL: -5674,
    MOVES: "e4e3 f2e3 f4f3 g2f3 h5h4 a4a5 h4h3 a5b6 h3h2 b6b7",
    DEPTH: 36,
  },
  {
    FEN: "4kb1r/p2B1ppp/4qn2/4p1B1/4P3/1Q6/PPP2PPP/2KR4 b k -",
    EVAL: 7588,
    MOVES: "e6d7 b3b8 e8e7 b8e5 e7d8 g5f6 g7f6 e5f6 d8c8 d1d7",
    DEPTH: 55,
  },
  {
    FEN: "r1bqr1k1/p5pp/3b4/n2ppp2/8/3B1N2/PPPP1PPP/R1BQR1K1 w - -",
    EVAL: 78,
    MOVES: "d3f1 a8b8 d2d3 d6c7 a2a3 a5c6 c2c4 d5c4 d3c4 d8d1",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/2p5/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -",
    EVAL: -15,
    MOVES: "f6e4 d1a4 b8d7 c4f7 e8f7 a4e4 d7f6 e4c4 e7e6 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -",
    EVAL: 34,
    MOVES: "e2e4 d7d5 e4e5 f6d7 f2f4 c7c5 g1f3 b8c6 c1e3 a7a6",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/5bN1/8/2Np4/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: -90,
    MOVES: "d1f3 d8c8 f1d3 f5d3 f3d3 c8d7 d3c4 e7e6 c1f4 f8d6",
    DEPTH: 36,
  },
  {
    FEN: "r1bqr1k1/p5pp/3b4/nB1ppp2/8/5N2/PPPP1PPP/R1BQR1K1 b - -",
    EVAL: 16,
    MOVES: "c8d7 b5d7 d8d7 d2d3 d6c7 c1d2 e5e4 f3d4 a5c6 d4c6",
    DEPTH: 24,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 13,
    MOVES: "d2d4 g8f6 c1g5 d5e4 c3e4 f8e7 g5f6 e7f6 g1f3 b8d7",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/2p5/2B1P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq -",
    EVAL: 18,
    MOVES: "b7b5 c4d3 b8c6 d3c2 c8g4 a2a4 e7e6 d2d4 c5d4 c3d4",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/3P4/2N1B3/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: -44,
    MOVES: "d7d5 g1f3 b8d7 g2g3 b7b6 f1g2 c8b7 e1h1 f8e7 a2a4",
    DEPTH: 28,
  },
  {
    FEN: "rn1qk1nr/pp3ppp/2p1p1b1/3p4/1b1P1BPP/2N1PP2/PPP5/R2QKBNR b KQkq -",
    EVAL: -48,
    MOVES: "h7h5 g4h5 h8h5 f4g5 f7f6 g5f4 b8d7 f1d3 g8e7 g1e2",
    DEPTH: 28,
  },
  {
    FEN: "r2qr1k1/p2b2pp/3b4/nB1ppp2/8/5N2/PPPP1PPP/R1BQR1K1 w - -",
    EVAL: 37,
    MOVES: "b5d7 d8d7 d2d3 h7h6 a2a3 a8d8 b2b4 e5e4 b4a5 e4f3",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2NP1N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: -25,
    MOVES: "f8b4 g2g3 d7d5 e4d5 f6d5 c1d2 d5c3 b2c3 b4d6 f1g2",
    DEPTH: 50,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq -",
    EVAL: 17,
    MOVES: "e8h8 e1h1 d5c4 d1c2 a7a6 a2a4 c8d7 c2c4 d7c6 c1g5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/2N1B3/PPP1PPPP/R2QKBNR w KQkq -",
    EVAL: -42,
    MOVES: "g1f3 b8d7 g2g3 b7b6 f1g2 c8b7 e1h1 f8e7 e3f4 a8c8",
    DEPTH: 32,
  },
  {
    FEN: "r3r1k1/p2q2pp/3b4/n2ppp2/8/5N2/PPPP1PPP/R1BQR1K1 w - -",
    EVAL: 14,
    MOVES: "d2d3 h7h6 a2a3 a5c6 b2b4 d7f7 b4b5 e5e4 d3e4 f5e4",
    DEPTH: 29,
  },
  {
    FEN: "1n1q1rk1/1r2b1pp/p2pp3/1p1Np3/4P3/5N2/PPPQ1PPP/R4RK1 b - -",
    EVAL: 19,
    MOVES: "d8e8 d5e7 e8e7 f1d1 b7b6 a2a4 b5a4 a1a4 b6b2 a4b4",
    DEPTH: 40,
  },
  {
    FEN: "rn1qkbnr/ppp1pppp/8/8/2Pp2b1/8/PP1PPPBP/RNBQK1NR w KQkq -",
    EVAL: -94,
    MOVES: "g2b7 b8d7 h2h3 g4h5 g1f3 a8b8 b7c6 h5f3 c6f3 e7e5",
    DEPTH: 37,
  },
  {
    FEN: "3r1k2/pp1n2pb/q1p1Qp1N/2P2r2/3Pp2p/P1P1B2P/6P1/1R1R2K1 b - -",
    EVAL: 1478,
    MOVES: "a6c4 e6c4 f5d5 b1b7 h7g8 h6g4 g8e6 g4f2 f8g8 c4a4",
    DEPTH: 49,
  },
  {
    FEN: "3r1k2/pp1n2pb/2p1Qp1N/2P2r2/2qPp2p/P1P1B2P/6P1/1R1R2K1 w - -",
    EVAL: "M21",
    MOVES: "d4d5 f5f1 g1h2 d7e5 d1f1 c4d5 f1f6 g7f6 e6f6 f8e8",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3pP3/8/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: -70,
    MOVES: "d5d4 c3e4 d8d5 d2d3 d5e5 g1f3 e5d5 g2g3 f8e7 f1g2",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: 23,
    MOVES: "e7e5 g1f3 f8b4 b1c3 e5d4 f3d4 b8d7 f1c4 g8f6 f2f3",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 34,
    MOVES: "c4d5 e6d5 e2e3 h7h6 g5h4 g7g5 h4g3 f6h5 f1d3 h5g3",
    DEPTH: 52,
  },
  {
    FEN: "r2qkbnr/pBpnpppp/8/8/2Pp2b1/8/PP1PPP1P/RNBQK1NR w KQkq -",
    EVAL: -21,
    MOVES: "b7a8 d8a8 f2f3 e7e5 d2d3 g4e6 b1d2 g8e7 d2e4 f7f5",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/6N1/8/2Nb1Q2/PPP2PPP/R1B1K2R w KQkq -",
    EVAL: -30,
    MOVES: "f3b7 b8d7 c2d3 a8b8 b7a6 b8b6 a6a4 f8e7 g5f3 e8h8",
    DEPTH: 26,
  },
  {
    FEN: "8/1r3k2/2q1ppp1/8/5PB1/4P3/4QK2/5R2 w - -",
    EVAL: "M21",
    MOVES: "g4f3 c6c7 f3b7 c7b7 f1d1 b7c7 e2a2 g6g5 a2a4 f7g6",
    DEPTH: 80,
  },
  {
    FEN: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 32,
    MOVES: "d8a5 c4d5 f6d5 d1d2 f8b4 a1c1 c6c5 a2a3 b4c3 b2c3",
    DEPTH: 42,
  },
  {
    FEN: "4K3/4P1k1/8/8/8/8/r7/5R2 w - -",
    EVAL: "M18",
    MOVES: "f1g1 g7f6 e8f8 a2a8 e7e8q a8e8 f8e8 f6e5 e8e7 e5e4",
    DEPTH: 99,
  },
  {
    FEN: "r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQR1K1 b - -",
    EVAL: 16,
    MOVES: "d7d6 a2a4 c8d7 c1d2 b5b4 a4a5 a8b8 c2c3 d7g4 b3c4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2p1P3/3pN3/8/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 17,
    MOVES: "g1f3 b8c6 f1c4 d8c7 d2d3 c6e5 f3e5 c7e5 e1h1 f8e7",
    DEPTH: 27,
  },
  {
    FEN: "r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQkq -",
    EVAL: 22,
    MOVES: "c4d5 f6d5 d1d2 c6c5 e3e4 d5c3 d2c3 a5c3 b2c3 c5d4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/1B1p4/8/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: -15,
    MOVES: "c8g4 h2h3 g4f3 d1f3 e7e6 e1h1 g8f6 c3e2 a7a6 b5c6",
    DEPTH: 38,
  },
  {
    FEN: "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQR1K1 w - -",
    EVAL: 16,
    MOVES: "a2a4 c6a5 a4b5 a6b5 c1d2 c7c6 b3a2 c8d7 b2b4 a5b7",
    DEPTH: 42,
  },
  {
    FEN: "r1b1kb1r/pp1n1ppp/2p1pn2/q2P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 17,
    MOVES: "f6d5 a1c1 d5c3 b2c3 f8a3 c1c2 b7b6 f1d3 c8a6 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1 b - -",
    EVAL: 2,
    MOVES: "c6a5 b3c2 c7c5 h2h3 f8e8 b1d2 a5c6 a2a4 c8d7 d2f1",
    DEPTH: 40,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/1B1p4/6b1/2N2N2/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: -10,
    MOVES: "h2h3 g4f3 d1f3 e7e6 c3e2 g8f6 e1h1 a7a6 b5c6 b7c6",
    DEPTH: 38,
  },
  {
    FEN: "8/5pk1/8/4p3/pp1qPn2/5P2/PP2B3/2Q2K2 b - -",
    EVAL: "M-20",
    MOVES: "d4g1 f1g1 f4e2 g1f1 e2c1 f1f2 c1a2 f2e1 g7g6 e1e2",
    DEPTH: 75,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: -18,
    MOVES: "e5d4 d1d4 b8c6 d4d3 f8c5 c1f4 d7d6 d3d2 c8e6 f1d3",
    DEPTH: 50,
  },
  {
    FEN: "r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1 w - -",
    EVAL: 0,
    MOVES: "b1d2 a5c6 h2h3 f8e8 a2a4 b5b4 d3d4 e5d4 c3d4 c5d4",
    DEPTH: 40,
  },
  {
    FEN: "8/8/2P5/3K1k2/2R3p1/3q4/8/8 w - -",
    EVAL: -6070,
    MOVES: "d5c5 d3d8 c4c3 d8c7 c3c2 g4g3 c2a2 c7e5 c5b6 e5b8",
    DEPTH: 37,
  },
  {
    FEN: "r1b1kb1r/pp1n1ppp/2p1p3/q2n2B1/3P4/2N1PN2/PP3PPP/R2QKB1R w KQkq -",
    EVAL: 27,
    MOVES: "d1d2 f7f6 g5h4 d7b6 a2a3 d5c3 f1d3 c6c5 b2c3 c5d4",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 98,
    MOVES: "d1d8 e8d8 b1c3 f8d6 f2f3 h8e8 e1f2 a7a5 a2a4 b7b6",
    DEPTH: 39,
  },
  {
    FEN: "rn1qkb1r/pQp2ppp/4pn2/5bN1/8/2Np4/PPP2PPP/R1B1KB1R b KQkq -",
    EVAL: -44,
    MOVES: "b8d7 f1d3 f5d3 c2d3 a8b8 b7a6 b8b6 a6a4 f8d6 e1h1",
    DEPTH: 31,
  },
  {
    FEN: "R2K4/3P1k2/8/8/8/8/2r5/8 b - -",
    EVAL: "M21",
    MOVES: "c2c1 a8c8 c1d1 d8c7 d1c1 c7b6 c1b1 b6c5 b1c1 c5b4",
    DEPTH: 50,
  },
  {
    FEN: "8/8/2P5/3K1k2/3R2p1/3q4/8/8 b - -",
    EVAL: "M-9",
    MOVES: "d3b5 d5d6 b5e5 d6d7 e5d4 d7e7 f5e5 c6c7 d4d6 e7f7",
    DEPTH: 116,
  },
  {
    FEN: "r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 b kq -",
    EVAL: 0,
    MOVES: "b7b5 a4b3 d7d6 c2c3 e8h8 d2d4 c5a7 h2h3 c8b7 a2a4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/2B1P3/8/Pp3PPP/RNBQK1NR w KQkq -",
    EVAL: -43,
    MOVES: "c1b2 g8f6 e4e5 f8b4 b2c3 d8e7 d1e2 f6g8 g1f3 g8h6",
    DEPTH: 53,
  },
  {
    FEN: "r2qkb1r/pQpn1ppp/4pn2/5bN1/8/2Np4/PPP2PPP/R1B1KB1R w KQkq -",
    EVAL: -44,
    MOVES: "f1d3 a8b8 b7a6 b8b6 a6c4 h7h6 g5f3 f5g4 d3e2 f8d6",
    DEPTH: 44,
  },
  {
    FEN: "8/3qk3/8/3b4/5B2/3K1Q2/8/8 b - -",
    EVAL: "M-10",
    MOVES: "d5f3 d3e3 d7d5 f4c7 d5e4 e3d2 e4d4 d2c2 f3d1 c2c1",
    DEPTH: 99,
  },
  {
    FEN: "r1bq1rk1/pppnppbp/3p1npB/8/3PP3/2N5/PPPQ1PPP/2KR1BNR b - -",
    EVAL: 105,
    MOVES: "e7e5 h6g7 g8g7 f2f3 c7c6 g2g4 h7h5 g4g5 f6h7 h2h4",
    DEPTH: 40,
  },
  {
    FEN: "r1b1kb1r/pp1n1ppp/2p1p3/q2n2B1/3P4/2N1PN2/PP1Q1PPP/R3KB1R b KQkq -",
    EVAL: 14,
    MOVES: "f8b4 a1c1 h7h6 g5h4 d5c3 b2c3 b4a3 c1b1 b7b6 d2d3",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/1pp2ppp/p1np1n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 26,
    MOVES: "c2c3 b7b5 a4c2 e8h8 h2h3 c5b6 d2d3 c6e7 a2a4 c8b7",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/3p2p1/1B6/3NP1n1/2N1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 625,
    MOVES: "b8c6 d4c6 b7c6 b5c6 c8d7 c6a8 g4e3 f2e3 d8a8 d1d4",
    DEPTH: 58,
  },
  {
    FEN: "r1b1k2r/pp1n1ppp/2p1p3/q2n2B1/1b1P4/2N1PN2/PP1Q1PPP/R3KB1R w KQkq -",
    EVAL: 18,
    MOVES: "a1c1 d5c3 b2c3 b4a3 c1b1 b7b6 d2d3 c8b7 f1e2 h7h6",
    DEPTH: 32,
  },
  {
    FEN: "8/4K1k1/4P3/8/8/8/r7/3R4 w - -",
    EVAL: "M25",
    MOVES: "e7e8 a2a8 d1d8 a8a6 e6e7 a6a7 d8d3 a7a8 e8d7 a8a7",
    DEPTH: 68,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 33,
    MOVES: "c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/2PP4/5P2/PP2P1PP/RNBQKBNR b KQkq -",
    EVAL: 24,
    MOVES: "e7e6 e2e4 c7c5 d4d5 d7d6 b1c3 f8g7 g1e2 e6d5 c4d5",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: 11,
    MOVES: "f8g7 b1d2 e8h8 c2c3 h7h6 g5h4 d7d6 e2e3 d8e8 f1c4",
    DEPTH: 44,
  },
  {
    FEN: "r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1 b - -",
    EVAL: 0,
    MOVES: "f8e8 h2h3 a5c6 a2a4 c8d7 d2f1 h7h6 f1e3 b5b4 c2b3",
    DEPTH: 37,
  },
  {
    FEN: "8/5pk1/8/4p3/pp2Pn2/5P2/PP2B3/2Q2Kq1 w - -",
    EVAL: "M-21",
    MOVES: "f1g1 f4e2 g1f2 e2c1 f2e1 c1a2 e1d2 g7g6 d2d3 b4b3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e5d4 e1h1 a7a6 b5a4 f8e7 e4e5 f6e4 f3d4 e8h8 d4f5",
    DEPTH: 50,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/2N5/PP2PPPP/2RQKBNR b Kkq -",
    EVAL: 17,
    MOVES: "c8d7 e2e3 b6b2 f1d3 e7e6 g1e2 d7c6 e1h1 a7a6 c1b1",
    DEPTH: 31,
  },
  {
    FEN: "r1b1k2r/pp1n1ppp/2p1p3/q2n2B1/1b1P4/2N1PN2/PP1Q1PPP/2R1KB1R b Kkq -",
    EVAL: 8,
    MOVES: "h7h6 g5h4 d5c3 b2c3 b4a3 c1b1 b7b6 b1b3 c8a6 c3c4",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/8/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR b KQkq -",
    EVAL: 24,
    MOVES: "g8f6 a2a3 b4c3 b2c3 e8h8 g1e2 c8g4 e1h1 g4h5 f2f3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4P3/2B5/8/PB3PPP/RN1QK1NR w KQkq -",
    EVAL: 119,
    MOVES: "g1f3 g8h6 e1h1 f8e7 b1c3 b8c6 c3d5 e8h8 d1d2 d7d6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/5np1/3p4/3P1B2/2N5/PPP1PPPP/R2QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "c3b5 b8a6 e2e3 f8g7 b5c3 a6b8 g1f3 e8h8 f1e2 c7c5",
    DEPTH: 55,
  },
  {
    FEN: "r1bq1rk1/1pp2ppp/p1np1n2/2b1p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1 w - -",
    EVAL: 50,
    MOVES: "d2d4 c5a7 h2h3 b7b5 a4c2 c8b7 c1e3 e5d4 c3d4 c6b4",
    DEPTH: 31,
  },
  {
    FEN: "8/5pk1/8/4p3/pp2P3/5P2/PP2n3/2Q3K1 w - -",
    EVAL: -11764,
    MOVES: "g1f1 e2c1 f1e1 c1a2 e1d2 g7g6 d2e2 g6g5 e2e3 a2c1",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/8/2NP1N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: -16,
    MOVES: "g2g3 d5c3 b2c3 f8d6 f1g2 e8h8 e1h1 c8f5 d3d4 a8b8",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/pp2p1pp/2n2p2/1B1p4/8/2N2N2/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: 88,
    MOVES: "f3d4 c8d7 d1h5 g7g6 h5d5 e7e6 d5e4 c6d4 e4d4 d7b5",
    DEPTH: 28,
  },
  {
    FEN: "8/1b1R1ppk/p3p2p/1p6/3Pp3/1P2b1P1/P5KP/8 b - -",
    EVAL: -918,
    MOVES: "b7d5 g2f1 h7g6 d7d6 e3d4 f1e2 a6a5 d6d8 a5a4 b3a4",
    DEPTH: 34,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/5N2/PPPNPPPP/R2QKB1R w KQkq -",
    EVAL: 12,
    MOVES: "e2e3 c7c5 c2c3 e8h8 f1e2 h7h6 g5f4 b8c6 e1h1 c5d4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/2pPP3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 18,
    MOVES: "b7b5 a2a4 b5b4 c3b1 c8a6 d1c2 d8d4 g1f3 d4b6 c1e3",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 36,
    MOVES: "b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 c3d5 f8e7",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/pppn1ppp/4N3/nB1Pp3/8/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 144,
    MOVES: "f7e6 d5e6 h7h5 d1f3 d8e7 f3f5 c7c6 f5g6 e8d8 e6d7",
    DEPTH: 32,
  },
  {
    FEN: "8/5pk1/8/4p3/pp2P3/5P2/PP2nK2/2Q5 b - -",
    EVAL: -7977,
    MOVES: "e2c1 f2e3 c1a2 e3d3 g7f6 d3c4 f6g5 c4d3 g5f4 d3d2",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/pppp1pp1/2n2n1p/2b1p3/2B1P3/2PP1N2/PP1N1PPP/R1BQK2R b KQkq -",
    EVAL: 14,
    MOVES: "d7d6 e1h1 e8h8 f1e1 a7a6 h2h3 c6a5 b2b4 a5c4 b4c5",
    DEPTH: 63,
  },
  {
    FEN: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/1P6/P3PPPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 d5e6 f7e6 b1d2 b5c4 b3c4 d8a5 e2e3 b8c6 a2a3",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: -7,
    MOVES: "d4c3 b1c3 f8c5 f1c4 d7d6 e1h1 g8e7 c3a4 e7g6 a4c5",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/p3pp1p/2pp2p1/1B6/4P1n1/2N1B3/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 496,
    MOVES: "b5c6 c8d7 c6a8 g4e3 f2e3 d8a8 c3d5 a8b7 d1d4 h8g8",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/p2ppppp/5n2/2pP4/1pP5/1P6/P3PPPP/RNBQKBNR w KQkq -",
    EVAL: 71,
    MOVES: "g2g3 g7g6 c1b2 d7d6 b1d2 f8g7 f1g2 e8h8 g1h3 a7a5",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/ppp1ppb1/5npp/3p4/3P3B/4PN2/PPPN1PPP/R2QKB1R w KQ -",
    EVAL: 21,
    MOVES: "c2c3 b8d7 h2h3 c7c5 f1e2 f8e8 a2a4 e7e5 e1h1 e5e4",
    DEPTH: 27,
  },
  {
    FEN: "8/8/3kb3/8/8/3KQ3/8/8 w - -",
    EVAL: "M10",
    MOVES: "d3d4 e6f7 e3h6 d6d7 h6b6 f7h5 b6b5 d7e6 b5h5 e6d7",
    DEPTH: 154,
  },
  {
    FEN: "1Q2kb1r/p2n1ppp/4q3/4p1B1/4P3/8/PPP2PPP/2KR4 b k -",
    EVAL: "M1",
    MOVES: "d7b8 d1d8",
    DEPTH: 127,
  },
  {
    FEN: "3K4/3P4/6k1/8/5R2/8/2r5/8 w - -",
    EVAL: "M17",
    MOVES: "d8e7 c2e2 e7d6 e2a2 d7d8q a2d2 d6e7 d2d8 e7d8 g6g5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/ppp2ppp/2n5/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR w KQkq -",
    EVAL: 50,
    MOVES: "g1f3 g8e7 e1h1 c8g4 h2h3 g4h5 a2a3 b4c3 b2c3 d8d7",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R b KQkq -",
    EVAL: 8,
    MOVES: "h7h6 g5h4 d7d6 c2c3 c7c5 d4c5 d6c5 d1c2 b8c6 e2e3",
    DEPTH: 43,
  },
  {
    FEN: "r1bq1rk1/4bppp/p1np1n2/1pp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1 w - -",
    EVAL: 6,
    MOVES: "h2h3 f8e8 a2a4 c8d7 d2f1 h7h6 d3d4 c5d4 a4b5 a6b5",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1rk1/pppnppbp/3p1np1/8/3PPB2/2N2N2/PPPQ1PPP/2KR1B1R b - -",
    EVAL: 82,
    MOVES: "d7b6 h2h4 c8g4 f4h6 g4f3 g2f3 e7e5 h6g7 g8g7 a2a4",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pp2pp1p/2np2p1/1B6/3NP1n1/2N1B3/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 343,
    MOVES: "d4c6 b7c6 b5c6 c8d7 c6a8 g4e3 f2e3 d8a8 c3d5 a8b7",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 12,
    MOVES: "e1h1 f6e4 f1e1 e4d6 f3e5 f8e7 b5f1 c6e5 e1e5 e8h8",
    DEPTH: 59,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2N2n2/8/4P3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "b7c6 b1c3 f8b4 f1d3 d7d5 e4d5 c6d5 e1h1 e8h8 h2h3",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4P3/2B5/5Q2/PB3PPP/RN2K1NR b KQkq -",
    EVAL: -20,
    MOVES: "g8h6 b1c3 d7d5 c4d5 c7c6 d5b3 b8d7 a1d1 d8a5 e5e6",
    DEPTH: 26,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R w KQ -",
    EVAL: 28,
    MOVES: "h2h3 c7c5 c2c3 b8d7 g5f4 b7b6 a2a4 c8b7 f1e2 a7a6",
    DEPTH: 34,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/3BPN2/PPPN1PPP/R2QK2R b KQ -",
    EVAL: 0,
    MOVES: "h7h6 g5h4 c7c5 c2c3 d8b6 a1b1 c5d4 e3d4 b6e6 d3e2",
    DEPTH: 36,
  },
  {
    FEN: "r1bq1rk1/4bppp/p1np1n2/1pp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1 b - -",
    EVAL: 1,
    MOVES: "c8e6 h2h3 h7h6 f1g3 f8e8 d3d4 c5d4 c3d4 e5d4 g3e2",
    DEPTH: 34,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2pp2B1/3P4/3BPN2/PPPN1PPP/R2QK2R w KQ -",
    EVAL: -2,
    MOVES: "c2c3 h7h6 g5f4 b8c6 e1h1 d8b6 a1b1 f6h5 f4e5 c5d4",
    DEPTH: 40,
  },
  {
    FEN: "4k3/R4br1/8/p3P3/4N3/5K2/8/8 w - -",
    EVAL: "M25",
    MOVES: "e4d6 e8f8 a7a8 f8e7 d6f5 e7e6 f5g7 e6e5 a8a5 e5d4",
    DEPTH: 97,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/1q3n2/3p4/3P1B2/2N5/PPQ1PPPP/R3KBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 g1f3 a7a6 a1c1 c6d4 f3d4 b6d4 e2e4 e7e6 f4e3",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/pp2pp1p/2Np2p1/1B6/4P1n1/2N1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: 574,
    MOVES: "b7c6 b5c6 c8d7 c6a8 g4e3 f2e3 d8a8 c3d5 a8b7 d1d4",
    DEPTH: 36,
  },
  {
    FEN: "3K4/3P2k1/8/8/5R2/8/2r5/8 b - -",
    EVAL: "M17",
    MOVES: "g7g6 d8e7 c2e2 e7d6 e2b2 f4c4 g6f7 d7d8q b2d2 d6c7",
    DEPTH: 79,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2pp2B1/3P4/2PBPN2/PP1N1PPP/R2QK2R b KQ -",
    EVAL: 5,
    MOVES: "h7h6 g5h4 d8b6 a1b1 c5d4 e3d4 b6e6 f3e5 f6h5 e1h1",
    DEPTH: 35,
  },
  {
    FEN: "r1b2rk1/2q1bppp/p1np1n2/1pp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1 w - -",
    EVAL: 14,
    MOVES: "f1e3 c8e6 f3g5 e6d7 a2a4 b5b4 e3d5 c7d8 g5f3 d7e6",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/p1p2ppp/2p2n2/3p4/3QP3/5N2/PPP2PPP/RNB1K2R w KQkq -",
    EVAL: 0,
    MOVES: "e1h1 f8e7 b1c3 e8h8 d4a4 f6e4 c3e4 d5e4 a4e4 a8b8",
    DEPTH: 28,
  },
  {
    FEN: "r1b1kb1r/pp2pppp/1qn2n2/3p4/3P1B2/2N5/PPQ1PPPP/R3KBNR w KQkq -",
    EVAL: 19,
    MOVES: "g1f3 a7a6 c3a4 b6a5 f4d2 a5d8 a1c1 f6e4 e2e3 c8f5",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: -24,
    MOVES: "f1c4 b4c3 b2c3 d7d6 e1h1 g8f6 e4e5 c6e5 f3e5 d6e5",
    DEPTH: 50,
  },
  {
    FEN: "rnb1k2r/1pqp1ppp/p3pn2/8/1b1NP3/2N1BP2/PPPQ2PP/R3KB1R b KQkq -",
    EVAL: -67,
    MOVES: "d7d5 a2a3 b4c3 d2c3 c7c3 b2c3 d5e4 c3c4 e8h8 f3e4",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2n2np1/2pp2B1/3P4/2PBPN2/PP1N1PPP/R2QK2R w KQ -",
    EVAL: 36,
    MOVES: "d4c5 h7h6 g5f6 g7f6 a2a4 c6e5 f3e5 f6e5 d3e2 e5g7",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/ppppp1pp/5n2/5p2/3P4/5NP1/PPP1PP1P/RNBQKB1R b KQkq -",
    EVAL: 65,
    MOVES: "e7e6 c2c4 f8b4 c1d2 b4e7 b1c3 e8h8 f1g2 d7d5 e1h1",
    DEPTH: 35,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2np1n2/2b1p1N1/2B1P3/2NP4/PPP2PPP/R1BQK2R w KQ -",
    EVAL: -52,
    MOVES: "c3a4 c5b6 a2a3 h7h6 g5f3 d6d5 e4d5 f6d5 h2h3 c8e6",
    DEPTH: 50,
  },
  {
    FEN: "r2qk1nr/ppp2ppp/2n5/3p4/1b1P2b1/2NB4/PPP1NPPP/R1BQK2R w KQkq -",
    EVAL: 33,
    MOVES: "f2f3 g4e6 c1e3 g8e7 a2a3 b4c3 e2c3 e7f5 d3f5 e6f5",
    DEPTH: 26,
  },
  {
    FEN: "rn1qkb1r/pp2ppp1/2p2n1p/3p4/3P1Bb1/3BPN2/PPP2PPP/RN1QK2R w KQkq -",
    EVAL: 71,
    MOVES: "c2c3 e7e6 d1b3 d8b6 f3e5 b8d7 e5g4 f6g4 b1d2 f8e7",
    DEPTH: 26,
  },
  {
    FEN: "rnb1k2r/1pq2ppp/p3pn2/3p4/1b1NP3/2NBBP2/PPPQ2PP/R3K2R b KQkq -",
    EVAL: -400,
    MOVES: "e6e5 e4d5 e5d4 e3d4 c7e7 e1f1 e8h8 a1e1 e7d8 h2h4",
    DEPTH: 41,
  },
  {
    FEN: "8/4K1k1/4P3/8/8/8/r7/3R4 b - -",
    EVAL: 0,
    MOVES: "a2a7 d1d7 a7a5 d7d6 a5a8 d6d3 a8a7",
    DEPTH: 74,
  },
  {
    FEN: "r2qk1nr/ppp2ppp/2n5/3p4/1b1P2b1/2NB1P2/PPP1N1PP/R1BQK2R b KQkq -",
    EVAL: 34,
    MOVES: "g4e6 c1e3 g8e7 a2a3 b4c3 e2c3 e7f5 d3f5 e6f5 d1e2",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkb1r/p2p1ppp/4pn2/2pP4/2P5/8/P3PPPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 e6d5 c4d5 g7g6 e2e4 f8g7 a1b1 e8h8 g1f3 f8e8",
    DEPTH: 51,
  },
  {
    FEN: "rn1qkb1r/pp2ppp1/2p2n1p/3p4/3P1Bb1/3BPN2/PPPN1PPP/R2QK2R b KQkq -",
    EVAL: 40,
    MOVES: "e7e6 h2h3 g4f3 d1f3 c6c5 c2c3 b8c6 f4g3 f8d6 g3h4",
    DEPTH: 25,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4p2Q/2B1n3/2N5/PPPP1PPP/R1B1K1NR b KQkq -",
    EVAL: 16,
    MOVES: "e4d6 h5e5 d8e7 e5e7 f8e7 c4e2 d6f5 g1f3 c7c6 g2g4",
    DEPTH: 53,
  },
  {
    FEN: "8/r2RK1k1/4P3/8/8/8/8/8 b - -",
    EVAL: 0,
    MOVES: "a7a8 d7c7 g7g6 e7d7 g6f6 c7b7 f6g6 d7d6 g6f6 b7f7",
    DEPTH: 70,
  },
  {
    FEN: "r7/3RK1k1/4P3/8/8/8/8/8 w - -",
    EVAL: 0,
    MOVES: "d7d1 a8a7 e7d6 a7a6 d6e5 a6a5 d1d5 a5a8 d5d7 g7g6",
    DEPTH: 71,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R b KQkq -",
    EVAL: -48,
    MOVES: "c5b4 c2c3 b4a5 d2d4 d7d6 d1b3 d8d7 e1h1 a5b6 c4b5",
    DEPTH: 56,
  },
  {
    FEN: "r3kb1r/pp2pppp/1qn2n2/3p1b2/3P1B2/2N1P3/PPQ2PPP/R3KBNR w KQkq -",
    EVAL: -26,
    MOVES: "c2b3 b6b3 a2b3 a7a6 f1b5 a8c8 b5c6 c8c6 g1f3 f6e4",
    DEPTH: 40,
  },
  {
    FEN: "3R4/6k1/r3P3/4K3/8/8/8/8 b - -",
    EVAL: 0,
    MOVES: "a6a5 e5d6 a5a6 d6e7 g7g6 d8d7 a6a8 d7b7 a8a6",
    DEPTH: 61,
  },
  {
    FEN: "rn2k2r/1pq2ppp/p4n2/4pP2/1b1p4/2NBBP2/PPPQ2PP/R3K2R w KQkq -",
    EVAL: -753,
    MOVES: "c3e4 b4d2 e3d2 b8d7 e4f6 g7f6 b2b4 d7b6 e1h1 b6c4",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/1QN2N2/PP3PPP/R1B1KB1R b KQkq -",
    EVAL: -29,
    MOVES: "g8f6 f1d3 e8h8 e1h1 b4c3 b3c3 d7d5 e4d5 f6d5 c3c4",
    DEPTH: 26,
  },
  {
    FEN: "8/8/2P5/5k2/2RKq1p1/8/8/8 w - -",
    EVAL: -899,
    MOVES: "d4c5 e4e5 c5b4 e5c7 c4c5 f5e6 c5c2 g4g3 c2e2 e6d5",
    DEPTH: 18,
  },
  {
    FEN: "r1bqkb1r/pp1npp1p/3p2p1/1B6/3NP1n1/2N1B3/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 619,
    MOVES: "d1g4 a7a6 b5d7 c8d7 g4g3 f8g7 f2f4 a8c8 e1h1 c8c3",
    DEPTH: 29,
  },
  {
    FEN: "rn1qk2r/1p2bpp1/p2p4/3Ppb1p/8/1N2BP2/PPPQB1PP/R3K2R b KQkq -",
    EVAL: 23,
    MOVES: "e8h8 e1h1 a6a5 a2a4 b8d7 f3f4 e5f4 e3f4 d8c7 b3d4",
    DEPTH: 37,
  },
  {
    FEN: "6k1/6p1/8/4K3/4NN2/8/8/8 w - -",
    EVAL: "M15",
    MOVES: "e5e6 g7g5 f4h5 g8f8 e4d6 g5g4 h5g3 f8g8 e6e7 g8g7",
    DEPTH: 86,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/3Pp3/2P5/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: -25,
    MOVES: "f8b4 c1d2 a7a5 e2e3 b8a6 f1d3 a6c5 d3c2 f6e4 a2a3",
    DEPTH: 51,
  },
  {
    FEN: "8/8/2P5/5k2/2R1q1p1/2K5/8/8 b - -",
    EVAL: -1864,
    MOVES: "e4e5 c3b4 e5c7 c4c2 g4g3 b4c5 c7a5 c5d6 a5e5 d6d7",
    DEPTH: 25,
  },
  {
    FEN: "r7/6k1/4P3/3RK3/8/8/8/8 w - -",
    EVAL: 0,
    MOVES: "e6e7 g7f7 e7e8q a8e8 e5d4 e8e7 d5e5 e7a7 e5e3 a7d7",
    DEPTH: 61,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/1qn2np1/8/2BNP3/2N1BP2/PPP3PP/R2QK2R w KQ -",
    EVAL: -15,
    MOVES: "c4b3 f6e4 c3d5 b6a5 c2c3 e4f6 d4c6 d7c6 d5e7 g8h8",
    DEPTH: 44,
  },
  {
    FEN: "3K4/3P2k1/8/8/5R2/8/8/2r5 w - -",
    EVAL: "M16",
    MOVES: "d8e7 c1e1 e7d6 e1d1 d6e6 g7g6 f4f6 g6g5 f6f5 g5g4",
    DEPTH: 61,
  },
  {
    FEN: "r1bq2nr/pppp1kpp/8/2b4Q/3pP3/8/PPPP1PPP/RNB2RK1 b - -",
    EVAL: 382,
    MOVES: "f7e7 h5c5 d7d6 c5d4 g8f6 b1c3 h8f8 b2b3 e7f7 c1b2",
    DEPTH: 32,
  },
  {
    FEN: "r7/3R2k1/4P3/4K3/8/8/8/8 b - -",
    EVAL: 0,
    MOVES: "g7g6 d7d4 a8a5 e5d6 g6f6 d4f4 f6g7 e6e7 a5a6 d6e5",
    DEPTH: 70,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "a7a6 b5a3 a8b8 e3g5 b7b5 c3d5 f8e7 g5f6 e7f6 c2c3",
    DEPTH: 38,
  },
  {
    FEN: "r3r1k1/1b2qppp/p7/1p1Pb3/1P6/P2B4/1B2Q1PP/3R1RK1 w - -",
    EVAL: 465,
    MOVES: "d3h7 g8h7 e2h5 h7g8 b2e5 e7e5 h5f7 g8h8 f7b7 a8d8",
    DEPTH: 60,
  },
  {
    FEN: "3q3k/pp2b1pp/8/2P5/1P2RBQ1/2P5/P4rPp/7K w - -",
    EVAL: "M17",
    MOVES: "e4e7 f2f1 h1h2 d8f8 g4g7 f8g7 e7e8 g7f8 e8f8 h8g7",
    DEPTH: 80,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/4P3/2Q2N2/PP3PPP/R1B1KB1R b KQkq -",
    EVAL: -23,
    MOVES: "g8f6 f1d3 d7d5 c1g5 e8h8 e1a1 d5d4 c3a3 f8e8 h1e1",
    DEPTH: 26,
  },
  {
    FEN: "5k2/6p1/8/4K1N1/5N2/8/8/8 w - -",
    EVAL: "M19",
    MOVES: "f4g6 f8e8 e5d6 e8d8 g6e7 g7g6 e7c6 d8c8 c6a5 c8d8",
    DEPTH: 82,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2np1n2/2b1p1N1/2B1P3/2NP3P/PPP2PP1/R1BQK2R b KQ -",
    EVAL: -40,
    MOVES: "c6e7 e1h1 h7h6 g5f3 c7c6 c4b3 e7g6 a2a4 f8e8 c1d2",
    DEPTH: 41,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/1qn2np1/8/3NP3/1BN1BP2/PPP3PP/R2QK2R b KQ -",
    EVAL: -12,
    MOVES: "f6e4 c3d5 b6a5 c2c3 e4c5 d4c6 d7c6 d5e7 g8h8 e7c8",
    DEPTH: 51,
  },
  {
    FEN: "8/5kpN/8/4K3/5N2/8/8/8 w - -",
    EVAL: 9605,
    MOVES: "h7g5 f7e7 f4g6 e7d7 e5d5 d7c7 g5e4 c7b6 e4c3 b6c7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/1P6/P3PPPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "d5e6 f7e6 b1d2 b5c4 b3c4 b8c6 g2g3 a8b8 f1g2 d8c7",
    DEPTH: 46,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/1qn3p1/8/3Nn3/1BN1BP2/PPP3PP/R2QK2R w KQ -",
    EVAL: -13,
    MOVES: "c3d5 b6a5 c2c3 e4c5 d4c6 d7c6 d5e7 g8h8 e7c8 f8c8",
    DEPTH: 46,
  },
  {
    FEN: "r1b2r2/pp2npkp/3p1pp1/q2P4/3QPP2/8/PP2B1PP/R1B2R1K w - -",
    EVAL: 631,
    MOVES: "b2b4 e7f5 e4f5 a5b6 d4b6 a7b6 f5g6 h7g6 e2b5 c8f5",
    DEPTH: 44,
  },
  {
    FEN: "8/1Q1rkpp1/p3p3/3nB1r1/8/3q3P/PP3R2/K1R5 w - -",
    EVAL: 804,
    MOVES: "f2f7 e7f7 b7d7 f7g6 d7g7 g6f5 g7h7 g5g6 c1g1 f5e5",
    DEPTH: 58,
  },
  {
    FEN: "r1b1kbnr/pppp1ppp/5q2/8/2BpP3/8/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 171,
    MOVES: "d2d3 g8e7 a2a4 d7d6 b2b4 h7h5 b1d2 c8g4 d1e1 e8a8",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 92,
    MOVES: "b1d2 g8f6 e4e5 f6d7 f1d3 c6c5 c2c3 f8e7 e1h1 b8c6",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/1B2p1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 66,
    MOVES: "b5c6 b7c6 d2d3 h7h6 g5f3 f8d6 b1d2 c8g4 e1h1 e8h8",
    DEPTH: 42,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2np4/2b1p1N1/2B1P1n1/2NP4/PPP2PPP/R1BQ1RK1 w - -",
    EVAL: 39,
    MOVES: "c3a4 c5b6 h2h3 g4f6 a4b6 a7b6 f2f4 h7h6 g5f3 c6a5",
    DEPTH: 33,
  },
  {
    FEN: "8/3PK1k1/8/8/5R2/8/8/2r5 b - -",
    EVAL: "M15",
    MOVES: "c1e1 e7d6 e1d1 d6e6 g7g6 f4f6 g6g5 f6f5 g5g4 f5d5",
    DEPTH: 69,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 80,
    MOVES: "f8b4 e4e5 c6c5 a2a3 b4c3 b2c3 h7h6 a3a4 g8e7 h2h4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/pppn2pp/4p3/nB1Pp3/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 140,
    MOVES: "d5e6 f8d6 b1c3 e8h8 e6d7 c8d7 b5d7 d8d7 e1h1 a5c6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 31,
    MOVES: "g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 c3d5",
    DEPTH: 58,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq -",
    EVAL: 32,
    MOVES: "a2a4 a7a6 d2d3 g8f6 b1d2 d7d6 c2c3 e8h8 h2h3 c6e7",
    DEPTH: 45,
  },
  {
    FEN: "r1b1kbnr/pppp1ppp/5q2/8/2BpP3/3P4/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 204,
    MOVES: "g8e7 a2a4 d7d6 c2c3 c8e6 c4e6 f7e6 c3d4 e8a8 b1d2",
    DEPTH: 25,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2n3p1/q2N4/3Nn3/1B2BP2/PPP3PP/R2QK2R w KQ -",
    EVAL: -11,
    MOVES: "c2c3 e4f6 d4c6 d7c6 d5e7 g8h8 e7c8 a8c8 e1h1 c6c5",
    DEPTH: 41,
  },
  {
    FEN: "rn1qkb1r/pp1bpp1p/3p2p1/1B6/3NP1n1/2N1B3/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 615,
    MOVES: "d1g4 f8g7 b5d7 b8d7 g4h3 e8h8 e1h1 d7e5 a1d1 d8d7",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkbnr/pp1n1ppp/2p1p3/3p4/3PPB2/2N2N2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 93,
    MOVES: "f8b4 f1d3 d5e4 d3e4 g8f6 e4d3 f6d5 f4d2 d5c3 b2c3",
    DEPTH: 27,
  },
  {
    FEN: "8/3PK1k1/8/8/5R2/8/8/4r3 w - -",
    EVAL: "M15",
    MOVES: "e7d6 e1d1 d6e6 g7g6 f4f6 g6g5 f6f5 g5g4 f5d5 d1e1",
    DEPTH: 71,
  },
  {
    FEN: "r1bqk1nr/ppp2ppp/2np4/2b1p3/2B1P3/5N1P/PPPP1PP1/RNBQ1RK1 b kq -",
    EVAL: -5,
    MOVES: "g8f6 d2d3 h7h6 c2c3 a7a6 d3d4 c5a7 d4e5 c6e5 f3e5",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 23,
    MOVES: "e4e5 c8g4 f1e2 c6c5 e1h1 e7e6 c2c4 d5d4 d2d3 b8c6",
    DEPTH: 50,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP1BPP1P/RN1QKB1R w KQkq -",
    EVAL: 18,
    MOVES: "d1c2 e8h8 f1g2 c7c6 e1h1 b7b6 f1d1 b8d7 b2b3 f8e8",
    DEPTH: 51,
  },
  {
    FEN: "rn1qkb1r/pp1bpp1p/3p2p1/1B6/3NP1Q1/2N1B3/PPP2PPP/R3K2R b KQkq -",
    EVAL: 776,
    MOVES: "b8c6 g4d1 f8g7 d4e2 e8h8 e1h1 a8c8 f2f4 a7a6 b5c6",
    DEPTH: 34,
  },
  {
    FEN: "8/3P2k1/3K4/8/5R2/8/8/4r3 b - -",
    EVAL: "M14",
    MOVES: "e1d1 d6e6 g7g6 f4f6 g6g5 f6f5 g5g4 f5d5 d1e1 e6d6",
    DEPTH: 75,
  },
  {
    FEN: "r1b1kb1r/ppppnppp/5q2/8/2BpP3/3P4/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 183,
    MOVES: "a2a4 d7d6 c2c3 e7c6 b1a3 f8e7 c1d2 e8h8 a3b5 e7d8",
    DEPTH: 29,
  },
  {
    FEN: "r2qkb1r/pppb1ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 70,
    MOVES: "d1e2 f8e7 g5f3 e8h8 b5d7 d8d7 f3e5 d7f5 d2d4 f6d5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B6/3pP3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -1,
    MOVES: "e4e5 f6e4 e1h1 a7a6 b5c4 d7d5 e5d6 e4d6 c4b3 c8g4",
    DEPTH: 50,
  },
  {
    FEN: "8/3P2k1/8/3K4/3R4/8/8/3r4 b - -",
    EVAL: "M8",
    MOVES: "d1d4 d5d4 g7f6 d7d8q f6f5 d8d6 f5g4 d4e4 g4g5 d6e7",
    DEPTH: 106,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/8/5P2/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: -84,
    MOVES: "b1c3 b8c6 e2e3 g8f6 f1b5 d7d5 g1e2 c8d7 d2d3 f8c5",
    DEPTH: 48,
  },
  {
    FEN: "r2qkbnr/ppp1pppp/2n5/3p4/3P1Bb1/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 37,
    MOVES: "e7e6 c2c4 g8f6 b1d2 f6e4 a2a3 e4d2 d1d2 f8d6 f4g3",
    DEPTH: 47,
  },
  {
    FEN: "8/3P2k1/8/3K4/3r4/8/8/8 w - -",
    EVAL: "M8",
    MOVES: "d5d4 g7f6 d7d8q f6f5 d8e7 f5f4 e7e4 f4g3 d4e3 g3h2",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 21,
    MOVES: "c3d5 f6d5 e4d5 c6b8 a2a4 f8e7 f1e2 e8h8 e1h1 b8d7",
    DEPTH: 64,
  },
  {
    FEN: "3r3k/1p1b1Qbp/1n2B1p1/p5N1/Pq6/8/1P4PP/R6K w - -",
    EVAL: "M2",
    MOVES: "f7g8 d8g8 g5f7",
    DEPTH: 122,
  },
  {
    FEN: "r2qkb1r/pppb1ppp/5n2/nB1Pp1N1/8/8/PPPPQPPP/RNB1K2R b KQkq -",
    EVAL: 23,
    MOVES: "f8e7 g5f3 f6d5 f3e5 c7c6 e5d7 c6b5 d7e5 e8h8 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "3Q4/8/5k2/8/3K4/8/8/8 b - -",
    EVAL: "M6",
    MOVES: "f6f5 d8e7 f5f4 e7e4 f4g3 d4e3 g3h2 e3f3 h2g1 e4g6",
    DEPTH: 165,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2BPP3/2P2N2/P4PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e5d4 c3d4 c5b6 b1c3 d7d6 h2h3 g8e7 f3g5 b6d4 g5f7",
    DEPTH: 48,
  },
  {
    FEN: "r2qk1nr/ppp3pp/2np4/2b1pb2/2B5/2P2N1P/PP1P1PP1/RNBQ1RK1 b kq -",
    EVAL: 9,
    MOVES: "d6d5 c4b5 e5e4 d2d4 c5d6 f3e5 d6e5 b5c6 b7c6 d4e5",
    DEPTH: 32,
  },
  {
    FEN: "r2qk1nr/ppp2ppp/2nbp3/3p4/3P1Bb1/3BPN2/PPP2PPP/RN1QK2R w KQkq -",
    EVAL: 32,
    MOVES: "f4g3 g8f6 d3e2 e8h8 b1d2 g4f3 d2f3 a8c8 e1h1 c6e7",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B2P3/3p4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -23,
    MOVES: "f6e4 d1e2 e4c5 c2c3 d4d3 e2e3 a7a6 b5c4 f8e7 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "8/3P2k1/4K3/8/5R2/8/8/4r3 w - -",
    EVAL: "M10",
    MOVES: "e6d5 e1d1 f4d4 d1d4 d5d4 g7f6 d7d8q f6f5 d4e3 f5e6",
    DEPTH: 88,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2n3p1/q2N4/3Nn3/1BP1BP2/PP4PP/R2QK2R b KQ -",
    EVAL: -10,
    MOVES: "e4f6 d4c6 d7c6 d5e7 g8h8 e7c8 a8c8 e1h1 c6c5 a2a4",
    DEPTH: 54,
  },
  {
    FEN: "8/3P2k1/8/3K4/5R2/8/8/3r4 w - -",
    EVAL: "M9",
    MOVES: "f4d4 d1d4 d5d4 g7f6 d7d8q f6f5 d8e7 f5f4 e7f6 f4g3",
    DEPTH: 99,
  },
  {
    FEN: "rnbqk1nr/ppp1bppp/3p4/8/3NPB2/8/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 87,
    MOVES: "g8f6 b1c3 b8c6 d1d2 c6d4 d2d4 c8e6 e1a1 e8h8 f1e2",
    DEPTH: 37,
  },
  {
    FEN: "r1bq1r2/pppn1pkp/3p1np1/4p3/3PP3/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 133,
    MOVES: "f1e2 d8e7 g2g4 e5d4 d2d4 e7e5 d4e3 e5c5 e3d2 d7b6",
    DEPTH: 44,
  },
  {
    FEN: "8/3P2k1/8/3K4/5R2/8/8/4r3 b - -",
    EVAL: "M9",
    MOVES: "e1d1 f4d4 d1d4 d5d4 g7f6 d7d8q f6f5 d8e7 f5f4 e7f6",
    DEPTH: 95,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P6/PBPPPPPP/RN1QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "e2e3 g8f6 g1f3 e5e4 f3d4 h7h5 c2c4 h8h6 h2h3 a7a6",
    DEPTH: 65,
  },
  {
    FEN: "2r1r1k1/5ppp/8/8/Q7/8/5PPP/4R1K1 w - -",
    EVAL: "M2",
    MOVES: "e1e8 c8e8 a4e8",
    DEPTH: 99,
  },
  {
    FEN: "rnbq1rk1/pppp1ppp/4pn2/6B1/1bPP4/2N5/PPQ1PPPP/R3KBNR b KQ -",
    EVAL: -16,
    MOVES: "c7c5 d4c5 h7h6 g5f4 b8a6 f4d6 f8e8 a2a3 d8a5 a1c1",
    DEPTH: 50,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2N2np1/q2N4/8/1BP1BP2/PP4PP/R2QK2R b KQ -",
    EVAL: 0,
    MOVES: "d7c6 d5e7 g8h8 e7c8 a8c8 e1h1 c6c5 a2a4 c5c4 b3c2",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p5/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 61,
    MOVES: "g1f3 g8f6 b1c3 d8a5 f1d3 d7d6 e1h1 f8e7 d3c2 c8e6",
    DEPTH: 45,
  },
  {
    FEN: "8/1Q1r1kp1/p3p3/3nB1r1/8/3q3P/PP6/K1R5 w - -",
    EVAL: 875,
    MOVES: "b7d7 f7g6 d7g7 g6f5 g7h7 g5g6 e5d6 d5f6 c1c5 f6d5",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b5/2BpP3/2P2N2/P4PPP/RNBQ1RK1 b kq -",
    EVAL: -36,
    MOVES: "d7d6 c3d4 c5b6 b1c3 c6a5 c4d3 g8e7 h2h3 e8h8 f1e1",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/2P5/1P6/PB1PPPPP/RN1QKBNR b KQkq -",
    EVAL: -9,
    MOVES: "g8e7 g1f3 d7d5 c4d5 e7d5 f3e5 c6e5 b2e5 d8e7 e5b2",
    DEPTH: 45,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2n2np1/q2N4/3N4/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: 0,
    MOVES: "d4c6 d7c6 d5e7 g8h8 e7c8 a8c8 e1h1 c6c5 a2a4 c5c4",
    DEPTH: 37,
  },
  {
    FEN: "8/3Q2p1/p3p1k1/3nB1r1/8/3q3P/PP6/K1R5 w - -",
    EVAL: 812,
    MOVES: "d7g7 g6f5 g7h7 g5g6 c1g1 f5e5 h7g6 d3g6 g1g6 e5f5",
    DEPTH: 56,
  },
  {
    FEN: "1r3b1r/1p1k1ppp/4p3/q2pP1B1/p2Q4/P2B4/1P3PPP/2R3K1 w - -",
    EVAL: "M10",
    MOVES: "d4a7 f8c5 a7a5 h8c8 d3b5 c8c6 b5c6 b7c6 a5c5 f7f6",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/1P6/PB1PPPPP/RN1QKBNR w KQkq -",
    EVAL: 24,
    MOVES: "g1f3 e5e4 f3d4 f8e7 d4f5 d7d5 c4d5 c8f5 d5c6 b7c6",
    DEPTH: 48,
  },
  {
    FEN: "rnb1k1nr/pp1p1ppp/4p3/1Nb5/4P3/2N1B3/PqP2PPP/R2QKB1R b KQkq -",
    EVAL: 412,
    MOVES: "c5e3 a1b1 b2c3 b5c3 e3b6 c3b5 b6a5 c2c3 b8c6 d1b3",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 b1c3 b8c6 d2d4 d7d5 e4d5 e6d5 c4b5 c8g4 c1g5",
    DEPTH: 47,
  },
  {
    FEN: "8/3PK3/6k1/8/5R2/8/4r3/8 w - -",
    EVAL: "M16",
    MOVES: "e7d6 e2a2 d7d8q a2d2 d6c7 d2d8 c7d8 g6g5 f4f1 g5g4",
    DEPTH: 57,
  },
  {
    FEN: "4rk2/ppp2Pp1/2np3p/2b5/2B2Bnq/2N5/PP4PP/5R1K w - -",
    EVAL: -616,
    MOVES: "f7e8r f8e8 c4f7 e8f7 f4g3 h4f6 f1f3 f7e8 f3f6 g4f6",
    DEPTH: 29,
  },
  {
    FEN: "8/8/2P5/5k2/2RK2p1/5q2/8/8 b - -",
    EVAL: -5921,
    MOVES: "f3f2 d4c3 f2g3 c3d2 g3c7 d2d1 g4g3 c4c2 f5f6 d1c1",
    DEPTH: 28,
  },
  {
    FEN: "8/2K5/4P1k1/8/8/8/1r6/R7 w - -",
    EVAL: 5153,
    MOVES: "a1e1 b2c2 c7d8 c2d2 d8e8 g6f6 e6e7 f6g7 e1a1 g7g6",
    DEPTH: 40,
  },
  {
    FEN: "r1b2rk1/pp2Npbp/2p2np1/q7/8/1BP1BP2/PP4PP/R2QK2R b KQ -",
    EVAL: -8,
    MOVES: "g8h8 e7c8 a8c8 e1h1 c6c5 a2a4 c5c4 b3c2 f8d8 d1c1",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1B2P3/3pn3/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: -9,
    MOVES: "a7a6 b5c4 d7d5 e5d6 e4d6 c4b3 c8g4 h2h3 g4f3 d1f3",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/8/1P2P3/PB1P1PPP/RN1QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "a2a3 c8e6 b3b4 f8d6 d1c2 f7f5 g1f3 d8f6 b1c3 e8a8",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pp4/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 17,
    MOVES: "e4d5 e6d5 c4b5 b8c6 e1h1 g8f6 b1c3 f8e7 d2d4 e8h8",
    DEPTH: 54,
  },
  {
    FEN: "8/3P4/3K2k1/8/5R2/8/4r3/8 b - -",
    EVAL: "M16",
    MOVES: "e2b2 f4d4 b2b8 d6e6 g6g5 d7d8q b8d8 d4d8 g5f4 d8d4",
    DEPTH: 55,
  },
  {
    FEN: "3r2k1/pb1r1pp1/1pn2q1p/3B4/6Q1/P4NP1/1P3PP1/3RR1K1 w - -",
    EVAL: 1409,
    MOVES: "g4d7 d8d7 e1e8 g8h7 d5e4 g7g6 d1d7 b7a6 e4c6 a6c4",
    DEPTH: 55,
  },
  {
    FEN: "8/3P4/3K2k1/8/5R2/8/r7/8 w - -",
    EVAL: "M15",
    MOVES: "d7d8q a2d2 d6c7 d2d8 c7d8 g6g5 f4f8 g5g4 d8d7 g4g3",
    DEPTH: 57,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b5/2B1P3/2p2N2/P4PPP/RNBQ1RK1 w kq -",
    EVAL: 128,
    MOVES: "c4f7 e8f7 d1d5 f7f8 d5c5 d7d6 c5c3 h7h6 c1a3 f8f7",
    DEPTH: 45,
  },
  {
    FEN: "6k1/3qb1pp/4p3/ppp1P3/8/2PP1Q2/PP4PP/5RK1 w - -",
    EVAL: "M3",
    MOVES: "f3f7 g8h8 f7f8 e7f8 f1f8",
    DEPTH: 245,
  },
  {
    FEN: "8/3P4/6k1/r3K3/5R2/8/8/8 w - -",
    EVAL: "M17",
    MOVES: "e5d6 a5a8 d6e7 g6g5 f4f8 a8a7 e7e8 a7a8 d7d8q a8d8",
    DEPTH: 57,
  },
  {
    FEN: "4k3/7R/8/4PK2/8/8/8/3r4 w - -",
    EVAL: 2430,
    MOVES: "f5e6 e8f8 h7f7 f8g8 f7d7 d1e1 e6f6 e1f1 f6e7 f1a1",
    DEPTH: 45,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 31,
    MOVES: "d1c2 a7a6 a2a4 c8d7 c2c4 d7c6 c1g5 c6d5 c4c2 d5e4",
    DEPTH: 50,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n2n2/2b1p1N1/2B1P3/2NP4/PPP2PPP/R1BQK2R b KQ -",
    EVAL: -29,
    MOVES: "d7d6 h2h3 c6a5 c4b3 a5b3 a2b3 a7a5 g5f3 f8e8 e1h1",
    DEPTH: 56,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1B5/4P3/3pn3/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: -12,
    MOVES: "d7c6 f3d4 e4c5 b1c3 c5e6 d4e6 c8e6 d1h5 d8d4 h2h3",
    DEPTH: 50,
  },
  {
    FEN: "2br2k1/5pp1/1p3q1p/2pBpP2/Q1P1P3/P6P/6P1/5RK1 b - -",
    EVAL: 141,
    MOVES: "f6e7 f1b1 d8d6 a4a8 e7c7 a3a4 g8h7 b1b5 d6d7 a4a5",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4PB2/8/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 57,
    MOVES: "f4g5 f8c5 b1c3 d7d6 g5f6 d8f6 b5c7 e8d8 f2f3 a8b8",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: 55,
    MOVES: "d5e4 c3e4 f8b4 c1d2 d8d4 d2b4 d4e4 f1e2 b8a6 b4a5",
    DEPTH: 52,
  },
  {
    FEN: "r1bqkb1r/1pp2ppp/p1p5/4P3/3pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -25,
    MOVES: "f3d4 e4c5 b1c3 c5e6 d4e6 c8e6 d1h5 d8d4 h2h3 f8e7",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk1nr/pppp1Bpp/2n5/2b5/4P3/2p2N2/P4PPP/RNBQ1RK1 b kq -",
    EVAL: 122,
    MOVES: "e8f7 d1d5 f7f8 d5c5 d7d6 c5c3 d8f6 c3a3 g8e7 c1b2",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/3p4/4P3/5B2/8/PPP1PPPP/RN1QKBNR b KQkq -",
    EVAL: 106,
    MOVES: "b8c6 g1f3 c8g4 e5d6 d8f6 e2e3 f8d6 f4d6 e8a8 b1d2",
    DEPTH: 43,
  },
  {
    FEN: "2r2r1k/pp3pbp/2p2np1/q7/8/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: -15,
    MOVES: "e1h1 c6c5 a2a4 c5c4 b3c2 f8d8 d1e2 h8g8 a1d1 b7b5",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/3n4/4p2Q/2B5/2N5/PPPP1PPP/R1B1K1NR w KQkq -",
    EVAL: 18,
    MOVES: "h5e5 d8e7 e5e7 f8e7 c4e2 d6f5 g1f3 b8c6 c3d5 e7d8",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 108,
    MOVES: "c4d5 c6d5 e4e5 f6e4 f1d3 e4c3 b2c3 d8c7 c1d2 b8c6",
    DEPTH: 39,
  },
  {
    FEN: "7k/3qbQpp/4p3/ppp1P3/8/2PP4/PP4PP/5RK1 w - -",
    EVAL: "M2",
    MOVES: "f7f8 e7f8 f1f8",
    DEPTH: 127,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n2n2/1N2p1B1/4P3/8/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 35,
    MOVES: "f8c5 b1c3 d7d6 g5f6 d8f6 b5c7 e8d8 f2f3 a8b8 d1d2",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p5/4p3/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 67,
    MOVES: "g8f6 b1c3 d8a5 f1e2 f8b4 e1h1 d7d6 d1c2 b8d7 d2d4",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n1pn2/1N6/4PB2/8/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 37,
    MOVES: "e6e5 f4g5 f8c5 b1c3 d7d6 g5f6 d8f6 b5c7 e8d8 f2f3",
    DEPTH: 44,
  },
  {
    FEN: "r1bq2nr/pppp1kpp/2n5/2b5/4P3/2p2N2/P4PPP/RNBQ1RK1 w - -",
    EVAL: 91,
    MOVES: "d1d5 f7f8 d5c5 d7d6 c5c3 d8f6 c3a3 g8e7 c1b2 f6f7",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P2P3/PBPP1PPP/RN1QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 g1f3 e5e4 f3d4 f8c5 d2d3 c6d4 e3d4 c5b4 c2c3",
    DEPTH: 50,
  },
  {
    FEN: "4rk2/ppp3p1/2np3p/2b3B1/2B4q/2N5/PP3nPP/4RR1K w - -",
    EVAL: "M-11",
    MOVES: "h1g1 h6g5 e1c1 f8e7 f1f2 c5f2 g1f1 e7d8 c4e2 f2c5",
    DEPTH: 49,
  },
  {
    FEN: "8/3PK1k1/8/8/8/8/2r5/5R2 b - -",
    EVAL: "M20",
    MOVES: "c2e2 e7d8 e2d2 f1f4 g7g6 d8e7 d2e2 e7d6 e2a2 d7d8q",
    DEPTH: 48,
  },
  {
    FEN: "r1bq1rk1/1ppp1ppp/p1n2n2/2b1p3/B3P3/2P2N2/PP1P1PPP/RNBQ1RK1 w - -",
    EVAL: 62,
    MOVES: "d2d4 c5a7 c1g5 h7h6 g5h4 d7d6 a4c6 b7c6 d4e5 d6e5",
    DEPTH: 41,
  },
  {
    FEN: "r1bq2nr/pppp1kpp/2n5/2bQ4/4P3/2p2N2/P4PPP/RNB2RK1 b - -",
    EVAL: 87,
    MOVES: "f7f8 d5c5 d7d6 c5c3 d8f6 c3a3 g8e7 f1e1 h7h6 e4e5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n5/3pp3/8/1P2P3/PBPP1PPP/RN1QKBNR w KQkq -",
    EVAL: -11,
    MOVES: "f1b5 f8d6 f2f4 f7f6 b1c3 g8e7 f4e5 f6e5 d1h5 g7g6",
    DEPTH: 47,
  },
  {
    FEN: "2r2r1k/pp3pbp/2p2np1/q7/8/1BP1BP2/PP4PP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "c6c5 a2a4 c5c4 b3c2 f6d5 e3f2 f8d8 d1c1 g7f8 g1h1",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/1Bpp4/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c8d7 b5d7 d8d7 e4d5 d7d5 b1c3 d5d7 d2d4 b8c6 d4c5",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 32,
    MOVES: "d7d6 c2c4 g8f6 b1c3 a7a6 b5d4 f8e7 f1e2 e8h8 e1h1",
    DEPTH: 43,
  },
  {
    FEN: "8/1q3k2/p3p3/2P3Qp/P3P2P/2P5/4r1PK/8 w - -",
    EVAL: 1275,
    MOVES: "g5h5 f7g7 h5e2 b7c7 e4e5 c7c5 e2g4 g7f7 g4h5 f7e7",
    DEPTH: 44,
  },
  {
    FEN: "3r2k1/Q2b1pp1/1p3q1p/2pBpP2/2P1P3/P6P/6P1/5RK1 b - -",
    EVAL: 291,
    MOVES: "d7e8 f1b1 d8d6 a3a4 g8h7 a7c7 e8a4 d5f7 h6h5 f7d5",
    DEPTH: 46,
  },
  {
    FEN: "rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R w KQ -",
    EVAL: 69,
    MOVES: "b1c3 d7d6 e1h1 c7c6 d1b3 b8a6 c1f4 d8b6 b3c2 c8e6",
    DEPTH: 38,
  },
  {
    FEN: "3rb1k1/Q4pp1/1p3q1p/2pBpP2/2P1P3/P6P/6P1/5RK1 w - -",
    EVAL: 301,
    MOVES: "f1b1 d8d6 a3a4 g8h7 a7c7 e8a4 d5f7 h6h5 f7d5 h5h4",
    DEPTH: 38,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2bPp3/2P5/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "e2e3 a7a5 b1c3 d7d6 f1d3 e8h8 a2a3 c7c6 g1e2 c6d5",
    DEPTH: 43,
  },
  {
    FEN: "3rb1k1/Q4pp1/1p3q1p/2pBpP2/2P1P3/P6P/6P1/1R4K1 b - -",
    EVAL: 315,
    MOVES: "d8d6 a3a4 g8h7 a7c7 e8a4 d5f7 h6h5 f7d5 h5h4 c7c8",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/6N1/4p3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: -49,
    MOVES: "f1c4 e7e6 b1c3 b7b6 e1h1 c8b7 f1e1 h7h6 g5e4 b8c6",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk1nr/ppp2ppp/2np4/2b1p1N1/2B1P3/3P4/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 12,
    MOVES: "g8h6 g5f3 d8f6 b1c3 f6g6 d1e2 c8g4 c1e3 e8a8 h2h3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk1nr/ppp2ppp/2nb4/1B1pp3/5P2/1P2P3/PBPP2PP/RN1QK1NR b KQkq -",
    EVAL: 0,
    MOVES: "f7f6 b1c3 g8e7 d1h5 g7g6 h5f3 c8e6 b5c6 e7c6 c3d5",
    DEPTH: 51,
  },
  {
    FEN: "1r2r1k1/p1p1qppp/Q2b1n2/1b1p4/P2P4/2P5/1P2BPPP/R1B1KN1R w KQ -",
    EVAL: -694,
    MOVES: "a6b5 b8b5 f1e3 b5b6 e1h1 a7a5 e2f3 e7e6 f1e1 f6e4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2np4/4p3/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -",
    EVAL: 99,
    MOVES: "f3g5 g8h6 d1f3 f7f6 g5e6 c8e6 c4e6 c6d4 f3h3 h6f7",
    DEPTH: 41,
  },
  {
    FEN: "4b1k1/Q4pp1/1p1r1q1p/2pBpP2/2P1P3/P6P/6P1/1R4K1 w - -",
    EVAL: 282,
    MOVES: "a3a4 g8h7 a4a5 b6a5 a7a5 d6a6 a5c5 e8c6 b1d1 f6g5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/2pp4/2B5/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 18,
    MOVES: "c4b5 b8c6 d2d4 f8d6 d4c5 d6c5 e1h1 g8f6 b1c3 e8h8",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/6N1/2B1p3/8/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -48,
    MOVES: "e7e6 b1c3 b8c6 e1h1 c6a5 c4e2 b7b6 g5e4 f6e4 c3e4",
    DEPTH: 45,
  },
  {
    FEN: "8/3pp2p/8/1kPP4/5P2/8/1K6/8 w - -",
    EVAL: "M19",
    MOVES: "c5c6 d7c6 d5d6 e7d6 f4f5 h7h5 f5f6 b5c5 f6f7 c5d5",
    DEPTH: 55,
  },
  {
    FEN: "5r1b/2R1R3/P4r2/2p2Nkp/2b3pN/6P1/4PP2/6K1 w - -",
    EVAL: "M3",
    MOVES: "e7g7 h8g7 c7g7 f6g6 g7g6",
    DEPTH: 245,
  },
  {
    FEN: "6k1/2q5/p3p3/2P5/P3P2P/2P5/4Q1PK/8 w - -",
    EVAL: 1184,
    MOVES: "e4e5 c7c5 e2g4 g8f7 g4g5 c5c3 g5f6 f7e8 f6e6 e8d8",
    DEPTH: 40,
  },
  {
    FEN: "r1b1k1nr/ppp1qppp/2nb4/1B1pp3/5P2/1P2P1P1/PBPP3P/RN1QK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 f7f6 b1c3 e7f7 f4e5 f6e5 d2d4 e5d4 e3d4 f7e6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/6N1/2B1p3/2N5/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: -46,
    MOVES: "b8c6 e1h1 c6a5 c4e2 b7b6 c3e4 c8b7 e2f3 f6e4 g5e4",
    DEPTH: 42,
  },
  {
    FEN: "3Q4/8/3K2k1/8/5R2/8/3r4/8 w - -",
    EVAL: "M15",
    MOVES: "d6e7 d2d8 e7d8 g6g5 f4c4 g5f5 d8c7 f5e5 c7c6 e5f5",
    DEPTH: 53,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 50,
    MOVES: "b1c3 e7e5 d4d5 c6b8 e2e4 a7a5 a1b1 b8a6 f1e1 a6c5",
    DEPTH: 55,
  },
  {
    FEN: "4b1k1/Q4pp1/1p1r1q1p/2pBpP2/P1P1P3/7P/6P1/1R4K1 b - -",
    EVAL: 277,
    MOVES: "g8h7 a4a5 b6a5 a7a5 e8c6 a5c5 c6d5 e4d5 f6f5 b1f1",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/1Bpp4/8/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 13,
    MOVES: "b8c6 d2d4 f8e7 d4c5 e7c5 e1h1 g8f6 b1c3 e8h8 c1g5",
    DEPTH: 51,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3p1n2/4p1B1/2B1P3/2P2N2/PPP2PPP/R2QK2R w KQ -",
    EVAL: 24,
    MOVES: "f3d2 h7h6 g5h4 c7c6 c4b3 b8a6 f2f3 a6c5 d2c4 d6d5",
    DEPTH: 29,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/2np1np1/3P4/2P5/5NP1/PP2PPBP/RNBQ1RK1 b - -",
    EVAL: 55,
    MOVES: "c6a5 b1a3 c7c5 f1e1 e7e6 e2e4 f8e8 c1f4 e6d5 e4d5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk1nr/ppp3pp/2np1p2/2b1p1N1/2B1P3/3P4/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 578,
    MOVES: "g5f7 d8e7 f7h8 c8e6 c4e6 e7e6 d1h5 e8d7 c2c3 g8e7",
    DEPTH: 26,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/3p4/8/2B1n3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 16,
    MOVES: "b1c3 e4c3 d2c3 e8h8 e1h1 e7f6 f1e1 b8c6 c4d3 f8e8",
    DEPTH: 28,
  },
  {
    FEN: "4b3/Q4ppk/1p1r1q1p/P1pBpP2/2P1P3/7P/6P1/1R4K1 b - -",
    EVAL: 284,
    MOVES: "b6a5 a7a5 e8c6 a5c5 c6d5 e4d5 f6f5 b1f1 f5g6 c5c7",
    DEPTH: 40,
  },
  {
    FEN: "3K4/8/8/6k1/5R2/8/8/8 w - -",
    EVAL: "M12",
    MOVES: "f4f1 g5g4 d8d7 g4g3 d7e6 g3g4 e6f6 g4g3 f6g5 g3g2",
    DEPTH: 117,
  },
  {
    FEN: "3Q4/8/3K2k1/8/5R2/8/r7/8 b - -",
    EVAL: "M15",
    MOVES: "a2d2 d6e7 d2d8 e7d8 g6g5 f4f8 g5g6 d8e7 g6g5 e7e6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: -7,
    MOVES: "a7a6 a2a4 b8c6 e2e3 c6a5 f3d2 c7c5 d2c4 g8f6 d4c5",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/8/3PP3/B7/2P2PPP/RN1QKBNR b KQkq -",
    EVAL: -69,
    MOVES: "g7g6 g1f3 f8g7 f1d3 c8g4 c2c3 g8f6 e1h1 e8h8 h2h3",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/1p3ppp/p1np1n2/1N2p3/4P3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b5a3 a8b8 e3g5 b7b5 c3d5 f8e7 g5f6 e7f6 c2c3 f6g5",
    DEPTH: 38,
  },
  {
    FEN: "r1b1k1nr/ppp1q1pp/2nb1p2/1B1pp3/5P2/1P2PNP1/PBPP3P/RN1QK2R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 e7e6 e1h1 g8e7 f4e5 f6e5 e3e4 d5d4 c3d5 c8d7",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/3p1np1/n2P4/2P5/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 45,
    MOVES: "b1a3 c7c5 f1e1 e7e6 e2e4 e6d5 e4d5 d8b6 g2f1 c8d7",
    DEPTH: 43,
  },
  {
    FEN: "4b3/Q4ppk/1p1r1q1p/2pBpP2/P1P1P3/7P/6P1/1R4K1 w - -",
    EVAL: 318,
    MOVES: "a4a5 b6a5 a7a5 e8c6 a5c5 c6d5 e4d5 f6f5 b1f1 f5g6",
    DEPTH: 34,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/3p4/8/2B1n3/3P1N2/PPP2PPP/RNBQ1RK1 b - -",
    EVAL: 0,
    MOVES: "e4f6 d3d4 d6d5 c4d3 c8g4 h2h3 g4h5 f1e1 c7c5 d4c5",
    DEPTH: 30,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2n5/1B2P3/3pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 40,
    MOVES: "f1e1 e4c5 f3d4 c6d4 d1d4 e8h8 b1c3 c7c6 b5c4 b7b5",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/4P3/B7/2PP1PPP/RN1QKBNR b KQkq -",
    EVAL: -98,
    MOVES: "b8c6 d2d4 g7g6 d4d5 f8g7 b1d2 c6b8 d5d6 e7e6 g1f3",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1B6/3pn3/5N2/PPP2PPP/RNBQR1K1 b kq -",
    EVAL: 70,
    MOVES: "f7f5 f3d4 c6d4 d1d4 c7c6 b1c3 d8f6 d4f6 g7f6 c3e4",
    DEPTH: 47,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1 b - -",
    EVAL: 32,
    MOVES: "b7b5 a2a4 b5b4 b1d2 c8b7 d2c4 c7c5 d4c5 b7e4 c2d1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -",
    EVAL: -46,
    MOVES: "c5b4 g1f3 g8f6 e4e5 f6d5 a2a3 e7e6 a3b4 d5b4 c2c3",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 f1g2 c6d4 d1d4 f8g7 e1h1 e8h8 a2a4 c8d7 d4b4",
    DEPTH: 49,
  },
  {
    FEN: "r1bq1knr/ppp3pp/2np4/8/4P3/2Q2N2/P4PPP/RNB2RK1 b - -",
    EVAL: 150,
    MOVES: "g8f6 c1b2 h7h6 b1d2 f8f7 c3b3 c8e6 b2f6 d8d7 b3b2",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -",
    EVAL: -47,
    MOVES: "f1d3 c7c5 c1e3 c5d4 f3d4 b8c6 d4c6 b7c6 d1f3 f8e7",
    DEPTH: 40,
  },
  {
    FEN: "2b1Q3/1kp5/p1Nb4/3P4/1P5p/p6P/K3R1P1/5q2 w - -",
    EVAL: "M3",
    MOVES: "e8c8 b7c8 e2e8 c8b7 e8b8",
    DEPTH: 245,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/1P2P3/8/P1PP1PPP/RNBQKBNR w KQkq -",
    EVAL: 64,
    MOVES: "b4c5 e6e5 c2c3 f8c5 g1f3 g8f6 d2d4 c5e7 f3e5 f6e4",
    DEPTH: 38,
  },
  {
    FEN: "r2q2k1/pQ2bppp/4p3/8/3r1B2/6P1/P3PP1P/1R3RK1 w - -",
    EVAL: 491,
    MOVES: "f4b8 a8b8 b7b8 d8b8 b1b8 d4d8 f1b1 g8f8 b8d8 e7d8",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkbnr/1pp2ppp/p3p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "e2e3 b7b5 a2a4 b5b4 c3b1 c8b7 f1c4 c7c5 e1h1 c5d4",
    DEPTH: 44,
  },
  {
    FEN: "r1b1kb1r/1pqp1pp1/p1n1pn2/7p/3NP3/2NBB3/PPP2PPP/R2Q1RK1 w kq -",
    EVAL: 69,
    MOVES: "h2h3 b7b5 d4b3 c8b7 f2f4 c6a5 b3a5 c7a5 a2a3 a5c7",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2n5/1B2P3/3pn3/5N2/PPP2PPP/RNBQR1K1 b kq -",
    EVAL: 33,
    MOVES: "e4c5 f3d4 c6d4 d1d4 e8h8 b1c3 c7c6 b5f1 d7d5 e5d6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkbnr/p4ppp/2p5/2pp4/8/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -20,
    MOVES: "e1h1 f8d6 d2d4 g8e7 d4c5 d6c5 f1e1 e8h8 c1e3 c5e3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk2r/ppppbppp/2n5/1Bn1P3/3p4/5N2/PPP2PPP/RNBQR1K1 w kq -",
    EVAL: 31,
    MOVES: "f3d4 c6d4 d1d4 e8h8 b1c3 c7c6 b5c4 b7b5 c4f1 c5e6",
    DEPTH: 30,
  },
  {
    FEN: "4b3/Q4ppk/3r1q1p/p1pBpP2/2P1P3/7P/6P1/1R4K1 w - -",
    EVAL: 327,
    MOVES: "a7a5 e8c6 a5c5 c6d5 e4d5 f6f5 b1f1 f5g6 c5c7 d6f6",
    DEPTH: 38,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 18,
    MOVES: "b1d2 a7a6 c2c3 e8h8 e1h1 d7d6 h2h3 c5a7 c4b3 h7h6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/pp2pp1p/3p2p1/8/3PP3/B1P5/5PPP/RN1QKBNR b KQkq -",
    EVAL: -41,
    MOVES: "b8c6 g1f3 a7a6 f1d3 g8f6 e1h1 f8g7 b1d2 e8h8 f1e1",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/pp2pp1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 74,
    MOVES: "b1c3 g8f6 f2f3 b8c6 c1e3 f8g7 d1d2 e8h8 e1a1 d6d5",
    DEPTH: 52,
  },
  {
    FEN: "1r1q2k1/pQ2bppp/4p3/8/3r4/6P1/P3PP1P/1R3RK1 w - -",
    EVAL: 682,
    MOVES: "b7b8 h7h5 b8a7 e7f8 b1b7 f7f5 e2e3 d4c4 b7d7 d8c8",
    DEPTH: 47,
  },
  {
    FEN: "4b3/5ppk/3r1q1p/Q1pBpP2/2P1P3/7P/6P1/1R4K1 b - -",
    EVAL: 122,
    MOVES: "e8c6 a5c5 c6d5 c4d5 d6a6 c5c8 a6b6 c8c2 b6a6 g1h1",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/p1p2ppp/1p2pn2/6N1/2B1p3/2N5/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: -44,
    MOVES: "e1h1 c8b7 f1e1 f8d6 d2d3 e4d3 d1d3 e8h8 c3e4 b7e4",
    DEPTH: 31,
  },
  {
    FEN: "rn1qkbnr/pp1bpp1p/3p2p1/1B6/3NP3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 34,
    MOVES: "b5e2 g8f6 b1c3 b8c6 c1e3 a8c8 f2f3 f8g7 d1d2 a7a6",
    DEPTH: 29,
  },
  {
    FEN: "1Q1q2k1/p3bppp/4p3/8/3r4/6P1/P3PP1P/1R3RK1 b - -",
    EVAL: 442,
    MOVES: "h7h5 e2e3 d4d7 b8d8 d7d8 f1d1 d8d1 b1d1 a7a5 d1d7",
    DEPTH: 43,
  },
  {
    FEN: "2k5/2p5/p1Nb4/3P4/1P5p/p6P/K3R1P1/5q2 w - -",
    EVAL: "M2",
    MOVES: "e2e8 c8d7 e8d8",
    DEPTH: 127,
  },
  {
    FEN: "r1bqkbnr/p4ppp/2p5/2pp4/3P4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -40,
    MOVES: "f8d6 d4c5 d6c5 c1e3 c5b6 e1h1 g8e7 f1e1 e8h8 c2c3",
    DEPTH: 32,
  },
  {
    FEN: "rnb1k1nr/pp1p1ppp/4p3/1N6/1b2P3/2N1B3/PqP2PPP/R2QKB1R w KQkq -",
    EVAL: 740,
    MOVES: "e3d2 g8f6 a1b1 b4c3 d2c3 b2c3 b5c3 e8h8 e4e5 f6e8",
    DEPTH: 34,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R w - -",
    EVAL: 110,
    MOVES: "c1b1 a7a6 h2h4 h7h5 g2g4 h5g4 h4h5 f6h5 d1g1 d8a5",
    DEPTH: 43,
  },
  {
    FEN: "2rqr1k1/pp1Q1pbp/2n3p1/8/4P3/4BP2/PP2B1PP/2RR2K1 b - -",
    EVAL: -219,
    MOVES: "g7d4 d1d4 c6d4 d7d8 c8c1 e3c1 d4e2 g1f2 e8d8 f2e2",
    DEPTH: 77,
  },
  {
    FEN: "rn1qkb1r/pbp2ppp/1p2pn2/6N1/2B1p3/2N5/PPPP1PPP/R1BQ1RK1 w kq -",
    EVAL: -42,
    MOVES: "f1e1 f8d6 h2h3 e4e3 e1e3 e8h8 d2d4 b8c6 g5f3 a7a6",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1p4/3Nn3/8/PPP2PPP/RNBQR1K1 b kq -",
    EVAL: 233,
    MOVES: "f8c5 d4c6 c5f2 g1f1 d8h4 e1e4 d5e4 d1d8 h4d8 c6d8",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 13,
    MOVES: "c2c4 f8g7 b1c3 e8h8 e2e4 d7d6 f1e2 b8c6 g1f3 e7e5",
    DEPTH: 51,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR b KQkq -",
    EVAL: 60,
    MOVES: "d5d6 d2d4 g8f6 g1f3 g7g6 c3b5 d6b6 a2a4 a7a6 b5a3",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/p2p1ppp/4pn2/1PpP4/8/1P6/P3PPPP/RNBQKBNR b KQkq -",
    EVAL: -62,
    MOVES: "f6d5 c1b2 d8a5 b1d2 d5c3 b2c3 a5c3 e2e4 f8e7 g1f3",
    DEPTH: 30,
  },
  {
    FEN: "rnbq1rk1/pppn1ppp/4p3/3pP3/1b1P4/2NB1N2/PPP2PPP/R1BQK2R w KQq -",
    EVAL: 708,
    MOVES: "d3h7 g8h7 f3g5 d8g5 c1g5 f8h8 d1f3 h7g8 e1a1 a7a6",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/pp2pp1p/3p2p1/1B6/3NP3/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 17,
    MOVES: "c8d7 b5e2 g8f6 b1c3 f8g7 c1e3 b8c6 e1h1 e8h8 a2a4",
    DEPTH: 30,
  },
  {
    FEN: "2rQr1k1/pp3p1p/6p1/8/3nP3/4BP2/PP2B1PP/2R3K1 b - -",
    EVAL: -222,
    MOVES: "c8c1 e3c1 d4e2 g1f2 e8d8 f2e2 d8c8 e2d1 f7f6 c1d2",
    DEPTH: 62,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 18,
    MOVES: "d7d6 e1h1 e8h8 f1e1 a7a6 a2a4 f6g4 e1e2 c5a7 h2h3",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 41,
    MOVES: "d7d5 e4e5 c8f5 c2c3 e7e6 f1d3 g8e7 g1e2 f7f6 f2f4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2P5/4P3/8/P1PP1PPP/RNBQKBNR b KQkq -",
    EVAL: 38,
    MOVES: "f8c5 d2d4 c5e7 f1d3 d7d6 g1e2 g8f6 e1h1 e8h8 c1e3",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -",
    EVAL: -43,
    MOVES: "e5f4 g1f3 g8f6 b1c3 d7d5 e4d5 f6d5 f1b5 c7c6 d1e2",
    DEPTH: 59,
  },
  {
    FEN: "r1bq1rk1/ppp1ppbp/2np1np1/8/3PPB2/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 125,
    MOVES: "f2f3 h7h5 f4g5 e7e5 d4d5 c6e7 h2h3 a7a6 g2g4 f6h7",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/3n4/4Q3/2B5/2N5/PPPP1PPP/R1B1K1NR b KQkq -",
    EVAL: 25,
    MOVES: "d8e7 e5e7 f8e7 c4e2 e8h8 g1f3 b8c6 c3d5 e7d8 e1h1",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/3P1B2/2P1P3/PP3PPP/RN1QKBNR w KQ -",
    EVAL: 0,
    MOVES: "f1e2 d7d6 f4g3 f6d7 g3h4 h7h6 f2f4 c7c5 g1f3 d8b6",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/2N2n2/8/8/8/PPPPQPPP/RNB1KB1R b KQkq -",
    EVAL: 838,
    MOVES: "d8e7 c6e7 f8e7 b1c3 b8c6 c3b5 e8d8 d2d4 a7a6 b5c3",
    DEPTH: 44,
  },
  {
    FEN: "2rQr1k1/pp3p1p/6p1/8/4P3/4BP2/PP2nKPP/2R5 b - -",
    EVAL: -334,
    MOVES: "e8d8 f2e2 c8c1 e3c1 d8c8 e2d1 a7a5 b2b3 b7b5 c1e3",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 18,
    MOVES: "g1f3 e7e5 c2c3 b8c6 c3d4 e5d4 f3d4 g8f6 b1c3 f8b4",
    DEPTH: 55,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/6p1/2pn4/8/2N2NP1/PP1PPPBP/R1BQ1RK1 w - -",
    EVAL: 13,
    MOVES: "d2d4 c5d4 f3d4 d5c3 b2c3 d8c7 a1b1 b8d7 c1f4 c7c3",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/4p3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: -95,
    MOVES: "f3g5 c8f5 b1c3 g8f6 d2d3 e4d3 d1f3 d8c8 f1d3 f5d3",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: -17,
    MOVES: "d4c3 b1c3 e7e6 g1f3 b8c6 f1e2 g8f6 e1h1 d8c7 c3b5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 93,
    MOVES: "g2g3 e8h8 g1f3 f8e8 f1g2 d6f8 e1h1 d7d6 f3d2 g7g6",
    DEPTH: 50,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R b - -",
    EVAL: 0,
    MOVES: "h7h5 e3g5 f8e8 h1f1 e5c4 d2d3 c4e5 d3d2",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/pppn1ppB/4p3/3pP3/1b1P4/2N2N2/PPP2PPP/R1BQK2R b KQq -",
    EVAL: 671,
    MOVES: "g8h7 f3g5 h7g8 d1d3 f7f5 d3h3 d8g5 c1g5 c7c5 e1a1",
    DEPTH: 46,
  },
  {
    FEN: "1rb2rk1/ppq2ppp/4pn2/2p5/2PP4/5N2/PPBRQPPP/3R2K1 w - -",
    EVAL: 268,
    MOVES: "e2e5 c7e5 d4e5 f6d7 d2d7 c8d7 d1d7 f8d8 d7c7 d8c8",
    DEPTH: 45,
  },
  {
    FEN: "3rr1k1/pp3p1p/6p1/8/4P3/4BP2/PP2K1PP/2R5 b - -",
    EVAL: -162,
    MOVES: "d8c8 c1c8 e8c8 e2d3 b7b6 g2g4 f7f6 h2h4 g8f7 g4g5",
    DEPTH: 36,
  },
  {
    FEN: "5r1k/1b2Nppp/8/2R5/4Q3/8/5PPP/6K1 w - -",
    EVAL: "M2",
    MOVES: "e4h7 h8h7 c5h5",
    DEPTH: 120,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R w KQ -",
    EVAL: 15,
    MOVES: "c1g5 c5e7 e1h1 d7d5 e4d5 f6d5 g5e7 d5e7 f1e1 e7g6",
    DEPTH: 56,
  },
  {
    FEN: "r1bqkbnr/p4ppp/2p1p3/2pP4/8/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: -55,
    MOVES: "c6d5 c2c4 g8f6 d2d3 f8e7 f3e5 e7d6 c1f4 e8h8 f4g3",
    DEPTH: 25,
  },
  {
    FEN: "3r1bnr/1p2pkpp/3p4/1p6/4P3/8/PPP2PPP/RNBK3R b - -",
    EVAL: 162,
    MOVES: "g8f6 h1e1 e7e6 a2a3 d6d5 e4e5 f6d7 e1e2 d5d4 e2e4",
    DEPTH: 31,
  },
  {
    FEN: "3rr1k1/pp3p1p/6p1/8/4P3/4BP2/PP2nKPP/2R5 w - -",
    EVAL: -228,
    MOVES: "f2e2 d8c8 c1c8 e8c8 e2d3 a7a6 a2a4 f7f5 e4f5 g6f5",
    DEPTH: 30,
  },
  {
    FEN: "4k3/R4br1/4P3/p7/4N3/5K2/8/8 b - -",
    EVAL: -5724,
    MOVES: "f7h5 f3e3 g7a7 e4d6 e8d8 d6b5 a7b7 b5c3 h5e8 c3e4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/ppp1pp1p/6p1/4N3/4p3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: -90,
    MOVES: "d2d4 e4d3 f1d3 b8d7 c1f4 f8g7 e5d7 c8d7 b1c3 c7c6",
    DEPTH: 26,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/3p1np1/8/2nNP2P/1BN1BP2/PPPQ2P1/2KR3R w - -",
    EVAL: 109,
    MOVES: "b3c4 c8c4 h4h5 f6h5 g2g4 h5f6 c1b1 f8e8 b2b3 c4c8",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 71,
    MOVES: "c4d5 c7c6 d5c6 b8c6 g1f3 e7e5 d4e5 d8d1 e1d1 f6g4",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/3p2pp/p3pn2/1Pp5/8/1P6/P3PPPP/RNBQKBNR w KQkq -",
    EVAL: -5,
    MOVES: "c1b2 a6b5 e2e3 d8b6 f1e2 d7d5 e2h5 f6h5 d1h5 g7g6",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3p1n2/2bPp3/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq -",
    EVAL: -39,
    MOVES: "f6g4 g1h3 e8h8 f1d3 d8h4 d1f3 b8a6 e1h1 h7h6 g1h1",
    DEPTH: 40,
  },
  {
    FEN: "rnbq1r2/pppn1ppk/4p3/3pP3/1b1P4/2N2N2/PPP2PPP/R1BQK2R w KQ -",
    EVAL: 712,
    MOVES: "f3g5 d8g5 c1g5 f8h8 d1d3 h7g8 e1a1 c7c5 c3b5 b8c6",
    DEPTH: 52,
  },
  {
    FEN: "2rr2k1/pp3p1p/6p1/8/4P3/4BP2/PP2K1PP/2R5 b - -",
    EVAL: -337,
    MOVES: "c8c1 e3c1 d8c8 e2d1 f7f6 c1e3 a7a5 d1d2 c8d8 d2e2",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk1nr/pp1p1ppp/1b2p3/8/3PP3/8/P1P2PPP/RNBQKBNR w KQkq -",
    EVAL: 60,
    MOVES: "c2c3 b8c6 b1d2 g8f6 f1d3 e8h8 e4e5 f6d5 d1c2 d7d6",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3p4/8/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 17,
    MOVES: "d2d4 g8f6 f1d3 c7c5 e1h1 c5c4 f1e1 f8e7 d3f1 e8h8",
    DEPTH: 49,
  },
  {
    FEN: "4k3/R4br1/3N4/p3P3/8/5K2/8/8 b - -",
    EVAL: "M28",
    MOVES: "e8f8 a7a8 f8e7 d6f5 e7e6 f5g7 e6e5 a8a5 e5d4 g7f5",
    DEPTH: 64,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/8/8/3P1N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 13,
    MOVES: "d3d4 d6d5 f1d3 f8d6 e1h1 e8h8 h2h3 c7c6 f1e1 f8e8",
    DEPTH: 49,
  },
  {
    FEN: "rnbqk1nr/pp1p1ppp/1b2p3/8/3PP3/5N2/P1P2PPP/RNBQKB1R b KQkq -",
    EVAL: 63,
    MOVES: "d7d5 e4e5 g8e7 f1d3 b8c6 c2c3 f7f6 e5f6 g7f6 f3h4",
    DEPTH: 34,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/P1N5/1PP2PPP/R1BQKB1R w KQkq -",
    EVAL: 40,
    MOVES: "f2f4 d7d5 e4e5 f6e4 c3e4 d5e4 c1e3 b8c6 d4c6 c7c6",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/4P3/2p5/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: -21,
    MOVES: "b1c3 e7e6 g1f3 b8c6 f1e2 d8c7 c1g5 c7b8 e1h1 f8e7",
    DEPTH: 50,
  },
  {
    FEN: "5k2/R4br1/3N4/p3P3/8/5K2/8/8 w - -",
    EVAL: "M28",
    MOVES: "a7a8 f8e7 d6f5 e7e6 f5g7 e6e5 f3e3 a5a4 a8a5 e5f6",
    DEPTH: 77,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/1b2p3/3p4/3PP3/5N2/P1P2PPP/RNBQKB1R w KQkq -",
    EVAL: 62,
    MOVES: "b1d2 g8f6 f1d3 f6e4 d2e4 d5e4 d3e4 b6a5 e1f1 f7f5",
    DEPTH: 39,
  },
  {
    FEN: "r1bq1rk1/pppp1ppp/1bn2n2/4p3/PPB1P3/2PP1N2/5PPP/RNBQK2R b KQ -",
    EVAL: 44,
    MOVES: "a7a5 b4b5 c6e7 f3e5 c7c6 e1h1 b6c7 f2f4 d7d5 e4d5",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2nb4/1B1p4/3Nn3/8/PPP2PPP/RNBQR1K1 w kq -",
    EVAL: 279,
    MOVES: "d4c6 d6h2 g1h1 d8h4 e1e4 d5e4 d1d8 h4d8 c6d8 e8d8",
    DEPTH: 34,
  },
  {
    FEN: "rnbq1r2/pppn1ppk/4p3/3pP1N1/1b1P4/2N5/PPP2PPP/R1BQK2R b KQ -",
    EVAL: 724,
    MOVES: "d8g5 c1g5 f8h8 d1g4 h7g8 e1a1 b8c6 h2h4 b4c3 b2c3",
    DEPTH: 49,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/6B1/3NP3/P1N5/1PP2PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f8e7 d1d3 e8h8 d3g3 e7d6 g3h4 d6e7",
    DEPTH: 24,
  },
  {
    FEN: "3q1rk1/pp1bppbp/3p1np1/7P/2rNP3/2N1BP2/PPPQ2P1/2KR3R b - -",
    EVAL: 86,
    MOVES: "f6h5 g2g4 h5f6 c1b1 d8c8 d4e2 f8e8 e4e5 f6g4 f3g4",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/8/8/3P1N2/PPP1BPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d6d5 e1h1 f8d6 h2h3 b8c6 c2c4 d5d4 f1e1 e8h8 b1a3",
    DEPTH: 37,
  },
  {
    FEN: "rnbqk1nr/pp3ppp/1b2p3/3pP3/3P4/5N2/P1P2PPP/RNBQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "b8c6 f1d3 g8e7 c2c3 f7f6 e1h1 f6e5 d4e5 e8h8 b1d2",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "g2g3 g8f6 f1g2 f8c5 d2d3 e8h8 b1c3 d7d6 g1f3 a7a5",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/8/4P3/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: -14,
    MOVES: "d7d6 f1c4 b8c6 e1h1 a7a6 d1e2 f8e7 f1d1 d8c7 c1f4",
    DEPTH: 54,
  },
  {
    FEN: "rnbq1rk1/pppn1pp1/4p3/3pP1N1/1b1P4/2N5/PPP2PPP/R1BQK2R w KQ -",
    EVAL: 807,
    MOVES: "d1d3 f7f5 d3h3 d8g5 c1g5 c7c5 e1a1 b8c6 c3b5 c5d4",
    DEPTH: 47,
  },
  {
    FEN: "rnb1kb1r/ppppqppp/3n4/4Q3/2B5/2N5/PPPP1PPP/R1B1K1NR w KQkq -",
    EVAL: 12,
    MOVES: "e5e7 f8e7 c4e2 d6f5 g1f3 b8c6 c3d5 e7d8 c2c3 c6e7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/3p4/4pN2/4P3/8/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 1,
    MOVES: "c8f5 e4f5 d6d5 b1c3 f8b4 f1b5 b8c6 e1h1 a7a6 b5a4",
    DEPTH: 31,
  },
  {
    FEN: "r1bq1rk1/1ppp1ppp/pbn2n2/4p3/PPB1P3/2PP1N2/5PPP/RNBQK2R w KQ -",
    EVAL: 57,
    MOVES: "e1h1 d7d6 b1d2 h7h6 h2h3 c8e6 f1e1 d8d7 c4e6 d7e6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p1p3/4P3/2PPp3/8/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 73,
    MOVES: "f2f4 f8e7 g1e2 f7f5 e2c3 b8a6 c1e3 a6c7 a2a4 e8h8",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk2r/pp2nppp/1b2p3/3pP3/3P4/5N2/P1P2PPP/RNBQKB1R w KQkq -",
    EVAL: 66,
    MOVES: "c2c3 b8c6 f1d3 f7f6 e1h1 e8h8 b1d2 c8d7 f1e1 h7h6",
    DEPTH: 25,
  },
  {
    FEN: "r4rk1/pp2ppbp/4q1p1/2p5/8/3PB1P1/PP2PP1P/2RQ1RK1 w - -",
    EVAL: 13,
    MOVES: "c1c5 e6a2 c5b5 b7b6 d1b3 a2b3 b5b3 f8b8 f1a1 a7a6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq -",
    EVAL: 8,
    MOVES: "g8f6 g1f3 b8c6 e2e4 f8b4 d2d3 d7d6 a2a3 b4c5 b2b4",
    DEPTH: 53,
  },
  {
    FEN: "rn1qk2r/pp3ppp/4pn2/2bp1b2/5B2/2P1PN2/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: -17,
    MOVES: "f1e2 e8h8 b1d2 h7h6 e1h1 d8e7 f4g3 f8d8 b2b4 c5d6",
    DEPTH: 28,
  },
  {
    FEN: "rnbq1rk1/pppn1pp1/4p3/3pP1N1/1b1P4/2NQ4/PPP2PPP/R1B1K2R b KQ -",
    EVAL: 628,
    MOVES: "d8g5 c1g5 c7c5 g5f6 d7f6 e5f6 g7g6 h2h4 b8d7 h4h5",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk2r/pp2nppp/1b2p3/3pP3/3P4/3B1N2/P1P2PPP/RNBQK2R b KQkq -",
    EVAL: 77,
    MOVES: "b8c6 c2c3 f7f6 e5f6 g7f6 f3h4 e6e5 d1h5 e8d7 e1h1",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n5/1B2P3/3pn3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -12,
    MOVES: "e1h1 a7a6 b5c6 d7c6 f3d4 f8c5 c2c3 e8h8 f1e1 f7f5",
    DEPTH: 34,
  },
  {
    FEN: "rn1qk2r/pp3ppp/4pn2/2bp1b2/5B2/2P1PN2/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: -21,
    MOVES: "b8d7 f1e2 e8h8 h2h3 c5e7 e1h1 a8c8 a1c1 h7h6 b2b4",
    DEPTH: 26,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/1n6/8/3PP1b1/5N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 80,
    MOVES: "b1c3 e7e5 d4d5 b8d7 h2h3 g4h5 c1e3 f8b4 a2a3 b4c3",
    DEPTH: 40,
  },
  {
    FEN: "rnb2rk1/pppn1pp1/4p3/3pP1q1/1b1P4/2NQ4/PPP2PPP/R1B1K2R w KQ -",
    EVAL: 642,
    MOVES: "c1g5 c7c5 a2a3 b4a5 d3g3 f7f6 e5f6 d7f6 g5f6 f8f6",
    DEPTH: 31,
  },
  {
    FEN: "8/8/1K6/8/5pk1/8/8/R7 b - -",
    EVAL: 27,
    MOVES: "g4f3 b6c5 f3e3 c5d5 f4f3 a1a3 e3e2 d5e4 f3f2 a3a2",
    DEPTH: 86,
  },
  {
    FEN: "4R3/8/pp6/k1r5/P3p3/K3PbB1/5P2/8 w - -",
    EVAL: 5041,
    MOVES: "e8e5 b6b5 e5c5 a5b6 c5c1 b5b4 a3b3 a6a5 g3c7 b6a6",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -",
    EVAL: 7,
    MOVES: "g1f3 b8c6 e2e4 f8b4 d2d3 d7d6 a2a3 b4c3 b2c3 b7b6",
    DEPTH: 58,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/4B3/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 74,
    MOVES: "c2c4 d8b6 d4b3 b6d8 d1d2 g8f6 b1c3 f6g4 e3f4 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 e2e3 f8b4 d1c2 e8h8 c3d5 f8e8 f3g5 g7g6 d5f6",
    DEPTH: 53,
  },
  {
    FEN: "3q1rk1/pp1bppbp/3p2p1/7n/2rNP1P1/2N1BP2/PPPQ4/2KR3R b - -",
    EVAL: 82,
    MOVES: "h5f6 c1b1 f8e8 b2b3 c4c8 c3d5 f6d5 e4d5 e7e5 d5e6",
    DEPTH: 30,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/1n6/8/3PP1b1/2N2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 51,
    MOVES: "e7e5 d4d5 b8d7 h2h3 g4f3 d1f3 f8c5 f1d3 e8h8 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "5rk1/1b3ppp/8/2RN4/8/8/2Q2PPP/6K1 w - -",
    EVAL: "M3",
    MOVES: "d5e7 g8h8 c2h7 h8h7 c5h5",
    DEPTH: 245,
  },
  {
    FEN: "3B2k1/6p1/3b4/1p1p3q/3P1p2/2PQ1NPb/1P2rP1P/R5K1 b - -",
    EVAL: -801,
    MOVES: "e2e3 d3e3 f4e3 f3g5 h3d7 f2e3 h5e2 a1a8 e2e3 g1g2",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -",
    EVAL: -5,
    MOVES: "c8f5 f3h4 f5g4 h2h3 g4d7 f1g2 e7e6 e1h1 h7h6 e2e4",
    DEPTH: 40,
  },
  {
    FEN: "3q1rk1/pp1bppbp/3p1np1/8/2rNP1P1/2N1BP2/PPPQ4/2KR3R w - -",
    EVAL: 94,
    MOVES: "c1b1 d8c8 g4g5 f6h5 c3d5 f8e8 b2b3 c4c5 d4e2 b7b6",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -",
    EVAL: 11,
    MOVES: "e2e4 f8c5 f3e5 c6e5 d2d4 c5b4 d4e5 f6e4 d1d4 e4c3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 68,
    MOVES: "f1d3 e7e5 d4e5 d6e5 e1h1 b8c6 a2a4 g8f6 c2c3 e8h8",
    DEPTH: 49,
  },
  {
    FEN: "r1b1kbnr/ppp1pppp/8/4q3/3n4/2N5/PPP1BPPP/R1BQK1NR w KQkq -",
    EVAL: 11,
    MOVES: "c1e3 d4e6 e2c4 g7g6 g1f3 e5a5 f3d4 f8g7 e1h1 g8f6",
    DEPTH: 24,
  },
  {
    FEN: "3B2k1/6p1/3b4/1p1p3q/3P1p2/2PQrNPb/1P3P1P/R5K1 w - -",
    EVAL: -896,
    MOVES: "d3e3 f4e3 f3g5 e3f2 g1f2 h3d7 f2g1 h5e8 a1a8 d6f8",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkbnr/pp1B1ppp/3p4/4p3/3NP3/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 88,
    MOVES: "d8d7 d4e2 b8c6 b1c3 g8e7 e1h1 a8d8 c3d5 e7d5 d1d5",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 22,
    MOVES: "c1f4 c8f5 e2e3 e7e6 d1b3 f8b4 f1b5 b4c3 b3c3 e8h8",
    DEPTH: 40,
  },
  {
    FEN: "5r1k/1b2Nppp/8/2R5/8/8/2Q2PPP/6K1 w - -",
    EVAL: "M2",
    MOVES: "c2h7 h8h7 c5h5",
    DEPTH: 127,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 f2f4 b8c6 f1e2 d7d6 g1f3 g7g6 e1h1 f8g7 a2a4",
    DEPTH: 44,
  },
  {
    FEN: "3q1rk1/pp1bppbp/3p1np1/8/2r1P1P1/2N1BP2/PPPQN3/2KR3R b - -",
    EVAL: 0,
    MOVES: "f8e8 e4e5 f6g4 f3g4 d7g4 e5e6 g4e6 e3d4 f7f6 b2b3",
    DEPTH: 34,
  },
  {
    FEN: "3B2k1/6p1/3b4/1p1p2Nq/3P4/2P1p1Pb/1P3P1P/R5K1 b - -",
    EVAL: -859,
    MOVES: "e3f2 g1f2 h3d7 f2g1 h5e8 a1a6 d6f8 h2h4 e8e1 g1g2",
    DEPTH: 39,
  },
  {
    FEN: "rn2kbnr/pp1q1ppp/3p4/4p3/3NP3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 98,
    MOVES: "d4e2 b8c6 b1c3 g8e7 e1h1 a8d8 c3d5 e7d5 d1d5 d7e6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1n5/1B2P3/3pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -16,
    MOVES: "b5c4 f8e7 c4d5 e4c5 f1e1 e8h8 f3d4 c6d4 d1d4 c7c6",
    DEPTH: 36,
  },
  {
    FEN: "rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b - -",
    EVAL: 58,
    MOVES: "d7d6 b1c3 c7c6 d1b3 b8a6 c1f4 d8b6 b3c2 c8e6 d4d5",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R b KQkq -",
    EVAL: 9,
    MOVES: "d7d5 c4d5 f6d5 f1g2 f8c5 e1h1 e8h8 d2d3 a7a5 d1a4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/1P6/P1PPPPPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e7e5 c1b2 b8c6 e2e3 g8f6 g1f3 e5e4 f3d4 h7h5 c2c4",
    DEPTH: 56,
  },
  {
    FEN: "r1bq1knr/pppp2pp/2n5/2Q5/4P3/2p2N2/P4PPP/RNB2RK1 b - -",
    EVAL: 126,
    MOVES: "d7d6 c5c3 h7h6 b1d2 g8f6 c1b2 f8g8 c3b3 g8h7 b3c2",
    DEPTH: 34,
  },
  {
    FEN: "rnbqr1k1/pp1p1ppp/3b1n2/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R w KQ -",
    EVAL: 144,
    MOVES: "f1e2 f6e4 c3e4 e8e4 e1h1 e4e8 e2d3 g7g6 c1g5 d6e7",
    DEPTH: 36,
  },
  {
    FEN: "1r5k/6pp/2pr4/P1Q3bq/1P2Bn2/2P5/5PPP/R3NRK1 b - -",
    EVAL: "M-3",
    MOVES: "f4e2 g1h1 h5h2 h1h2 d6h6",
    DEPTH: 245,
  },
  {
    FEN: "rnbqkbnr/pp3p1p/3p2p1/4pN2/4P3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 87,
    MOVES: "f5e3 g8f6 b1c3 f8g7 e3d5 f6d5 c3d5 e8h8 h2h4 h7h6",
    DEPTH: 25,
  },
  {
    FEN: "3B2k1/6p1/3b4/1p1p3q/3P1p2/2P1QNPb/1P3P1P/R5K1 b - -",
    EVAL: -917,
    MOVES: "f4e3 f3g5 e3f2 g1f2 h3c8 f2g1 h5e8 a1a8 e8d8 g5h3",
    DEPTH: 47,
  },
  {
    FEN: "r1bq1rk1/pppn1pbp/3p1npB/4p3/3PP3/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 108,
    MOVES: "h6g7 g8g7 f2f3 c7c6 g2g4 b7b5 c3e2 h7h5 g4g5 f6h7",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp3p1p/3p2p1/4p3/4P3/4N3/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 78,
    MOVES: "g8f6 b1c3 f8g7 e3d5 f6d5 c3d5 e8h8 h2h4 h7h6 h4h5",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n2n2/3pp3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R w KQkq -",
    EVAL: 19,
    MOVES: "c4d5 f6d5 f1g2 f8c5 c3d5 d8d5 d2d3 e8h8 e1h1 h7h6",
    DEPTH: 44,
  },
  {
    FEN: "r2qkb1r/ppp2ppp/3p1n2/3Pp3/2PnP1b1/2N1BN2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 20,
    MOVES: "f8e7 f1e2 d4e2 d1e2 f6d7 h2h3 g4f3 e2f3 e7g5 e3g5",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 25,
    MOVES: "d8b6 a2a3 g8h6 f1d3 c5d4 e1h1 c8d7 b2b4 a8c8 c1b2",
    DEPTH: 53,
  },
  {
    FEN: "1r5k/6pp/2pr4/P1Q3bq/1P2B3/2P5/4nPPP/R3NR1K b - -",
    EVAL: "M-2",
    MOVES: "h5h2 h1h2 d6h6",
    DEPTH: 122,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPP1P/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1g2 f8c5 f3e5 d5c3 g2c6 b7c6 d2c3 d8e7 c1f4 e8h8",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/3p4/4p3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 70,
    MOVES: "b8c6 f1d3 g8f6 c2c4 g7g6 e1h1 f8g7 b1c3 e8h8 f1e1",
    DEPTH: 43,
  },
  {
    FEN: "rnb1k2r/ppq1nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR w KQkq -",
    EVAL: 67,
    MOVES: "g4g7 h8g8 g7h7 c5d4 g1e2 d4c3 f2f4 c8d7 h7d3 b8a6",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f8c5 f3e5 d5c3 g2c6 b7c6 b2c3 d8d5 e5f3 c8h3 d1b3",
    DEPTH: 48,
  },
  {
    FEN: "rnb1kb1r/ppppQppp/3n4/8/2B5/2N5/PPPP1PPP/R1B1K1NR b KQkq -",
    EVAL: 29,
    MOVES: "f8e7 c4e2 b8c6 c3d5 e7d8 c2c3 c6e7 d5e7 d8e7 d2d4",
    DEPTH: 41,
  },
  {
    FEN: "8/8/8/2K5/5p2/5k2/8/R7 b - -",
    EVAL: 12,
    MOVES: "f3e3 a1a3 e3e4 a3a4 e4e3 c5d5 f4f3 a4a3 e3e2 d5e4",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 26,
    MOVES: "f8e7 f1d3 b8d7 g1f3 e8h8 d1c2 h7h6 g5h4 f8e8 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/4P3/6P1/PPPP1P1P/RNBQKBNR b KQkq -",
    EVAL: -7,
    MOVES: "d7d5 d1e2 d5e4 b1c3 g8f6 c3e4 f6e4 e2e4 d8a5 f1g2",
    DEPTH: 40,
  },
  {
    FEN: "8/8/8/3K4/5p2/4k3/8/R7 b - -",
    EVAL: 6,
    MOVES: "f4f3 a1a3 e3e2 d5e4 f3f2 a3a2 e2e1 e4e3 f2f1n e3d3",
    DEPTH: 53,
  },
  {
    FEN: "8/8/8/3K4/8/4kp2/8/R7 w - -",
    EVAL: 7,
    MOVES: "a1a3 e3e2 d5e4 f3f2 a3a2 e2e1 e4e3 f2f1n e3e4 f1d2",
    DEPTH: 62,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 26,
    MOVES: "d4b3 c5b6 d1e2 d7d6 b1c3 a7a5 c1e3 a5a4 e3b6 a4b3",
    DEPTH: 55,
  },
  {
    FEN: "r1bq1r2/pppn1pkp/5np1/4p3/4P3/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 11,
    MOVES: "g1e2 c7c6 e2g3 f6e8 a2a4 d8h4 d2e3 e8c7 b2b3 c7e6",
    DEPTH: 28,
  },
  {
    FEN: "rnb1kb1r/ppp1pppp/1q3n2/8/3P4/2N5/PPPB1PPP/R2QKBNR w KQkq -",
    EVAL: 80,
    MOVES: "g1f3 c8f5 f1c4 e7e6 d4d5 b8d7 d5e6 f5e6 d1e2 e8a8",
    DEPTH: 41,
  },
  {
    FEN: "8/8/8/3K4/8/R3kp2/8/8 b - -",
    EVAL: 27,
    MOVES: "e3e2 d5e4 f3f2 a3a2 e2e1 e4e3 f2f1n e3d3 f1g3 a2g2",
    DEPTH: 52,
  },
  {
    FEN: "5rk1/1R2R1pp/8/8/8/8/8/1K6 w - -",
    EVAL: "M3",
    MOVES: "e7g7 g8h8 g7h7 h8g8 b7g7",
    DEPTH: 245,
  },
  {
    FEN: "r1bqkbnr/p4ppp/2p1p3/2pp4/4P3/3P1N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 28,
    MOVES: "g8e7 h2h4 h7h5 d1e2 e7g6 g2g3 f7f6 e4e5 a7a5 b2b3",
    DEPTH: 43,
  },
  {
    FEN: "r1bq1r2/pppn1pkp/5np1/4p3/4P3/2N2P2/PPPQ2PP/2KR1BNR b - -",
    EVAL: 0,
    MOVES: "c7c6 g2g4 h7h5 g4g5 f6h7 h2h4 d8e7 g1e2 f8e8 d2d6",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: 29,
    MOVES: "e2e3 h7h6 g5h4 e8h8 g1f3 b7b6 f1e2 d5c4 e2c4 b8d7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 19,
    MOVES: "c8e6 f1e2 f8e7 e1h1 e8h8 c3d5 b8d7 d1d3 e6d5 e4d5",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -",
    EVAL: -14,
    MOVES: "d7d5 e4d5 c6d5 c1b2 a7a6 g1f3 b8c6 d2d4 c8g4 f1e2",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/4p3/2PP4/P1P2N2/4PPPP/R1BQKB1R b KQkq -",
    EVAL: -74,
    MOVES: "e5e4 f3d2 e8h8 d2b3 d7d6 a3a4 f8e8 a4a5 h7h6 e2e3",
    DEPTH: 23,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 29,
    MOVES: "a2a3 c5c4 b1d2 c6a5 f1e2 c8d7 a1b1 h7h6 h2h4 e8a8",
    DEPTH: 48,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR b KQkq -",
    EVAL: 14,
    MOVES: "h7h6 g5h4 d5c4 f1c4 c7c5 d4c5 d8d1 a1d1 b8d7 g1f3",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk2r/ppp1bppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1 w kq -",
    EVAL: 9,
    MOVES: "b2b3 e8h8 c1b2 c8f5 a1c1 e5e4 f3e1 e7f6 b2a1 d8e7",
    DEPTH: 45,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1 w - -",
    EVAL: -2,
    MOVES: "d4e2 a8b8 a2a4 a7a6 c3d5 b7b5 a4b5 a6b5 c1g5 f6g4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p5/4p3/4P3/1P6/P1PP1PPP/RNBQKBNR w KQkq -",
    EVAL: 5,
    MOVES: "c1b2 g8f6 b2e5 f6e4 d1e2 d8e7 e5b2 d7d5 b1c3 e4d6",
    DEPTH: 29,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1BP2/PPPQ2PP/2KR1B1R w - -",
    EVAL: 40,
    MOVES: "d4c6 b7c6 h2h4 c8e6 e4d5 f6d5 e3d4 d5f6 d2g5 h7h6",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n2n2/3Pp3/8/2N2NP1/PP1PPP1P/R1BQKB1R b KQkq -",
    EVAL: 30,
    MOVES: "f6d5 f1g2 f8c5 e1h1 e8h8 d2d3 f8e8 c3d5 d8d5 c1d2",
    DEPTH: 33,
  },
  {
    FEN: "r1bqkbnr/pp1npppp/2p5/8/3PN3/5N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 56,
    MOVES: "g8f6 e4f6 e7f6 f1d3 f8d6 d1e2 d8e7 e2e7 d6e7 c1e3",
    DEPTH: 51,
  },
  {
    FEN: "2r4k/5pR1/7p/4pN1q/7P/2n1P3/2Q2P1K/8 b - -",
    EVAL: -446,
    MOVES: "e5e4 c2b3 h5f5 g7f7 f5g6 f7f4 c8g8 b3c3 h8h7 c3c7",
    DEPTH: 62,
  },
  {
    FEN: "rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f2f3 c8e6 d1d2 e8h8 e1a1 b8d7 g2g4 b7b5 g4g5 f6h5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/5np1/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 32,
    MOVES: "e2e3 f8g7 c2c3 e8h8 h2h3 c7c5 f1e2 b7b6 b1d2 b8c6",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR w KQ -",
    EVAL: 14,
    MOVES: "c4d5 f6d5 g5e7 d8e7 a1c1 d5c3 c1c3 b8c6 f1b5 c6b4",
    DEPTH: 44,
  },
  {
    FEN: "r1bq1rk1/pppnpp1p/3p1npQ/8/3PP3/2N5/PPP2PPP/2KR1BNR b - -",
    EVAL: 108,
    MOVES: "c7c5 g1f3 c5d4 f3d4 a7a6 f2f3 e7e5 d4b3 d7b6 h6d2",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/1P6/P1PP1PPP/RNBQKBNR w KQkq -",
    EVAL: -27,
    MOVES: "e4e5 c8f5 d2d4 e7e6 c2c3 g8e7 g1f3 b8d7 c1e3 d8c7",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R b KQkq -",
    EVAL: -23,
    MOVES: "d7d6 d1b3 d8d7 d4e5 a5b6 a2a4 c6a5 b3a2 a5c4 a2c4",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 79,
    MOVES: "d2d4 e7e6 g1f3 g8f6 f1c4 a7a6 a2a4 b7b6 e1h1 c8b7",
    DEPTH: 48,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R b KQkq -",
    EVAL: 24,
    MOVES: "c8d7 b2b4 c5d4 c3d4 a8c8 c1b2 g8e7 b1c3 c6a5 c3a4",
    DEPTH: 50,
  },
  {
    FEN: "rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R b KQ -",
    EVAL: 0,
    MOVES: "b7b6 f1e2 c8b7 a1c1 d5c4 e2c4 a7a6 e1h1 b8d7 a2a4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -",
    EVAL: 46,
    MOVES: "g7g6 c2c4 g8f6 b1c3 f8g7 e2e4 e8h8 f1e2 b8a6 e1h1",
    DEPTH: 54,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/4P3/2N3P1/PPP1NPBP/R1BQ1RK1 b - -",
    EVAL: 13,
    MOVES: "e7e6 c1f4 d6d5 e4d5 e6d5 f1e1 c8f5 f4g5 d8d6 c3d5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R b KQkq -",
    EVAL: 12,
    MOVES: "f8b4 d1c2 b4c3 c2c3 d8e7 a2a3 d7d5 d2d4 e5d4 f3d4",
    DEPTH: 52,
  },
  {
    FEN: "2r4k/5R2/7p/5q2/4p2P/1Qn1P3/5P1K/8 b - -",
    EVAL: -472,
    MOVES: "f5g6 f7f4 c8g8 b3c3 h8h7 c3c7 g8g7 c7g7 h7g7 h2h3",
    DEPTH: 47,
  },
  {
    FEN: "rnb1k2r/ppppbppp/3n4/8/2B5/2N5/PPPP1PPP/R1B1K1NR w KQkq -",
    EVAL: 11,
    MOVES: "c4e2 d6f5 g1f3 b8c6 c3d5 e7d6 d5e3 f5e3 d2e3 d6b4",
    DEPTH: 60,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/5n2/3p4/1b1P4/2N3P1/PP2PPBP/R1BQK1NR w KQ -",
    EVAL: -29,
    MOVES: "c1g5 c7c6 g5f6 d8f6 g1f3 f6d8 a2a3 b4e7 e1h1 f8e8",
    DEPTH: 31,
  },
  {
    FEN: "rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -",
    EVAL: 16,
    MOVES: "d1c2 f8e7 c4d5 f6d5 e2e4 d5c3 b2c3 e8h8 f1d3 c7c5",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b1N3/4P3/2N5/PPPP1PPP/R1BQKB1R b KQkq -",
    EVAL: 69,
    MOVES: "c6e5 d2d4 c5d6 d4e5 d6e5 f1d3 g8f6 e1h1 e8h8 c1g5",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d1b3 d8f6 e1h1 a5b6 e4e5 f6g6 c3d4 c6d4 f3d4 b6d4",
    DEPTH: 65,
  },
  {
    FEN: "r1bq1rk1/pppn1p1p/3p1npQ/4p3/3PP3/2N5/PPP2PPP/2KR1BNR w - -",
    EVAL: 107,
    MOVES: "g1f3 f6g4 h6d2 d8e7 d4e5 d6e5 c3d5 e7d8 h2h3 g4f6",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 31,
    MOVES: "b1c3 e7e5 f1c4 f8e7 d2d3 d7d6 f3d2 g8f6 d2f1 c8g4",
    DEPTH: 52,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/3pP3/2pP4/P1P2N2/1P3PPP/RNBQKB1R w KQkq -",
    EVAL: 23,
    MOVES: "b1d2 c6a5 a1b1 c8d7 f1e2 e8a8 d1c2 c8b8 f3g5 g8h6",
    DEPTH: 58,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PP1P1PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d1c2 b4c3 c2c3 d8e7 a2a3 a7a5 b2b3 d7d5 d2d4 c6d4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/8/2N5/PPPPBPPP/R1BQK1NR b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 g1f3 b8c6 e1h1 e7e6 f1e1 g8f6 e2b5 c8d7 b5c6",
    DEPTH: 41,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/3pP3/2pP4/P1P2NP1/1P3P1P/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "h7h6 f1e2 c6a5 e1h1 c8d7 b1d2 g8e7 a1b1 e8a8 f3h4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R b KQkq -",
    EVAL: 9,
    MOVES: "c8e6 d1d2 h7h5 c3d5 f6d5 e4d5 e6f5 f1e2 a6a5 a2a4",
    DEPTH: 53,
  },
  {
    FEN: "rn2kb1r/pp1bpppp/1q3n2/3p4/3P1B2/2N5/PP2PPPP/2RQKBNR w Kkq -",
    EVAL: 32,
    MOVES: "d1d2 e7e6 e2e3 b8c6 f1d3 f8e7 g1f3 f6h5 f4g3 b6d8",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2np4/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
    EVAL: 67,
    MOVES: "c2c4 h7h6 d4d5 c6e5 e2e4 e5f3 d1f3 g8f6 b1c3 g7g6",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/p2ppp1p/2p3p1/8/3QP3/8/PPP2PPP/RNB1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 e4e5 f6d5 b1c3 f8g7 c3d5 c6d5 d4d5 a8b8 d5c5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/6P1/PPPP1P1P/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 f8c5 f1g2 e8h8 g1e2 b8c6 c3a4 c5e7 a4c3",
    DEPTH: 44,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQ1RK1 b kq -",
    EVAL: -6,
    MOVES: "g8f6 c1a3 d7d6 e4e5 f6e4 e5d6 c7d6 d1e2 d6d5 f3d4",
    DEPTH: 60,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PPQP1PPP/R1B1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "b4c3 c2c3 d8e7 a2a3 a7a5 b2b3 d7d5 d2d4 c6d4 f3d4",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppp2p1p/3p4/8/3PPppP/8/PPP3P1/RNBQKBNR b KQkq -",
    EVAL: -62,
    MOVES: "f8h6 b1c3 c8e6 d4d5 e6c8 g1e2 d8f6 d1d4 f6d4 e2d4",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/8/8/2N5/PPPPBPPP/R1BQK1NR w KQkq -",
    EVAL: 62,
    MOVES: "d2d4 e7e6 g1f3 f8e7 c1f4 e8h8 f3e5 b8d7 e1h1 d7e5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkb1r/p2ppp1p/2p2np1/4P3/3Q4/8/PPP2PPP/RNB1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "f6d5 b1c3 f8g7 c3d5 c6d5 d4d5 a8b8 f1c4 e8h8 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/6P1/PPPP1PBP/RNBQK1NR b KQkq -",
    EVAL: -5,
    MOVES: "f8c5 d2d3 d7d6 g1f3 e8h8 e1h1 b8c6 c2c3 a7a5 f1e1",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkbnr/p4ppp/4p3/2pp4/8/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: -80,
    MOVES: "c2c4 g8f6 f1e1 d5c4 f3e5 f8e7 b1a3 d8d5 d1a4 f6d7",
    DEPTH: 29,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/4p3/2P5/2b1PN2/PPQP1PPP/R1B1KB1R w KQkq -",
    EVAL: 0,
    MOVES: "c2c3 d8e7 a2a3 a7a5 b2b3 d7d5 d2d4 e5d4 f3d4 c6d4",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 9,
    MOVES: "c5d4 g1f3 e7e5 c2c3 b8c6 c3d4 e5d4 f3d4 f8b4 b1c3",
    DEPTH: 56,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/3p1np1/8/2BnP3/2N1BP2/PPPQ2PP/R3K2R w KQ -",
    EVAL: 68,
    MOVES: "e3d4 d8a5 e1a1 c8e6 c4e6 f7e6 c1b1 a8c8 f3f4 e6e5",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 79,
    MOVES: "g8f6 b1c3 g7g6 c1e3 f8g7 d1d2 f6g4 e3g5 h7h6 g5h4",
    DEPTH: 46,
  },
  {
    FEN: "5r1k/1R4Rp/8/8/8/8/8/1K6 w - -",
    EVAL: "M2",
    MOVES: "g7h7 h8g8 b7g7",
    DEPTH: 127,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/3QP3/8/PPP2PPP/RNB1KBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 d4d3 g8f6 b1c3 a7a6 c3d5 e7e6 d5f6 d8f6 c2c3",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/2pp2B1/3P4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 90,
    MOVES: "g5f6 g7f6 e2e3 c5c4 f3h4 c8e6 g2g3 d8d7 f1g2 c6e7",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b7/2B1P3/2p2N2/P4PPP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "d1b3 d8f6 c1g5 f6g6 b1c3 a5c3 b3c3 g8e7 g5e7 e8e7",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkb1r/pppp1p1p/4pnp1/8/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 67,
    MOVES: "c2c4 f6h5 f4e5 f8b4 b1d2 e8h8 h2h3 d7d6 e5h2 e6e5",
    DEPTH: 27,
  },
  {
    FEN: "rnbq1rk1/1p2bppp/p1p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R w KQ -",
    EVAL: 71,
    MOVES: "c4d5 e6d5 e1h1 b8d7 d1c2 g7g6 g5h6 f8e8 h2h3 f6h5",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/8/3PP3/2P5/P4PPP/RNBQKBNR b KQkq -",
    EVAL: 88,
    MOVES: "e7e6 f1d3 c7c5 g1f3 g8f6 e1h1 b8c6 d1e2 f8e7 f1d1",
    DEPTH: 36,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/4P3/2N3P1/PPP1NPBP/R1BQ1RK1 w - -",
    EVAL: 39,
    MOVES: "h2h3 a8b8 a2a4 a7a6 c3d5 f6e8 a4a5 b7b5 a5b6 e7e6",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p1b2/3P4/1Q3N2/PP2PPPP/RNB1KB1R b KQkq -",
    EVAL: 74,
    MOVES: "b8c6 e2e4 d5e4 f3e5 c6e5 d4e5 g7g6 f1b5 f5d7 b1c3",
    DEPTH: 45,
  },
  {
    FEN: "r1b1k2r/ppppqppp/2n2n2/4p3/2P5/2Q1PN2/PP1P1PPP/R1B1KB1R w KQkq -",
    EVAL: 0,
    MOVES: "a2a3 a7a5 b2b3 d7d5 c4d5 f6d5 c3c4 d5b6 c4c2 e5e4",
    DEPTH: 52,
  },
  {
    FEN: "rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP1B2/P1N2N2/1P2PPPP/R2QKB1R b KQkq -",
    EVAL: 11,
    MOVES: "d5c4 e2e3 f6d5 f1c4 d5c3 b2c3 f8d6 f4g3 e8h8 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/8/3QP3/8/PPP2PPP/RNB1KBNR w KQkq -",
    EVAL: 0,
    MOVES: "d4d3 g8f6 b1c3 a7a6 c1f4 d7d6 g1f3 g7g6 f1e2 f8g7",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/8/3np3/8/6P1/PPPP1PBP/RNBQK1NR w KQkq -",
    EVAL: 34,
    MOVES: "g1f3 f8d6 e1h1 e8h8 d2d4 e5d4 f3d4 c7c6 f1e1 d6c5",
    DEPTH: 30,
  },
  {
    FEN: "r1bq1rk1/1p2ppbp/2np1np1/p7/2BNP3/2N1BP2/PPPQ2PP/R3K2R w KQ -",
    EVAL: 96,
    MOVES: "a2a4 c8d7 e1a1 a8c8 d4b5 c6b4 c4b3 d7e6 b3e6 f7e6",
    DEPTH: 38,
  },
  {
    FEN: "r1b1k2r/pppp1Npp/2n5/4p3/2B1n2q/8/PPPP2PP/RNBQ2KR w kq -",
    EVAL: 0,
    MOVES: "g2g3 e4g3 f7h8 c6d4 h2g3 h4g3 g1f1 g3f4 f1g2 f4g5",
    DEPTH: 60,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/3p4/8/1P6/PBPP1PPP/RN1QKBNR b KQkq -",
    EVAL: -25,
    MOVES: "b8c6 f1b5 g8f6 g1f3 c8d7 e1h1 d7g4 h2h3 g4h5 c2c4",
    DEPTH: 35,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b7/2B1P3/1Qp2N2/P4PPP/RNB2RK1 b kq -",
    EVAL: 0,
    MOVES: "d8f6 c1g5 f6g6 b1c3 a5c3 b3c3 g8e7 g5e7 e8e7 e4e5",
    DEPTH: 57,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR w KQkq -",
    EVAL: 14,
    MOVES: "a2a3 b4c3 b2c3 e8h8 g1e2 f8e8 e1h1 c7c5 d4c5 b8d7",
    DEPTH: 54,
  },
  {
    FEN: "r1b1k2r/ppppqppp/2n2n2/4p3/2PP4/2Q1PN2/PP3PPP/R1B1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "e5e4 f3g1 d7d5 c1d2 e8h8 g1e2 e7d8 e2g3 c6e7 f1e2",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4N3/4n3/8/PPPPQPPP/RNB1KB1R b KQkq -",
    EVAL: 75,
    MOVES: "d8e7 e2e4 d7d6 d2d4 d6e5 d4e5 b8c6 b1c3 e7e5 e4e5",
    DEPTH: 60,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/8/3np3/8/5NP1/PPPP1PBP/RNBQK2R b KQkq -",
    EVAL: 32,
    MOVES: "f8d6 e1h1 e8h8 d2d4 e5d4 f3d4 c7c6 b1d2 f8e8 d2e4",
    DEPTH: 31,
  },
  {
    FEN: "r1bq1rk1/pppn1p1p/3p1npQ/8/3NP3/2N5/PPP2PPP/2KR1B1R b - -",
    EVAL: 180,
    MOVES: "a7a6 f2f3 d7e5 h2h4 c7c5 d4e2 f6h5 c1b1 b7b5 g2g4",
    DEPTH: 24,
  },
  {
    FEN: "r2q1rk1/pp1bppbp/2np1np1/8/4P3/2N3PP/PPP1NPB1/R1BQ1RK1 b - -",
    EVAL: 18,
    MOVES: "f6e8 a1b1 a8b8 c3d5 e7e6 d5e3 d8a5 a2a3 b7b5 c1d2",
    DEPTH: 38,
  },
  {
    FEN: "rnb1kb1r/ppppqppp/8/4N3/4n3/8/PPPPQPPP/RNB1KB1R w KQkq -",
    EVAL: 49,
    MOVES: "e2e4 d7d6 d2d4 d6e5 d4e5 b8c6 b1c3 e7e5 e4e5 c6e5",
    DEPTH: 56,
  },
  {
    FEN: "rn1qk2r/pbp2ppp/1p1Bpn2/3p4/2PP4/P1N2N2/1P2PPPP/R2QKB1R b KQkq -",
    EVAL: 19,
    MOVES: "c7d6 e2e3 e8h8 c4d5 f6d5 c3d5 b7d5 f1d3 b8d7 e1h1",
    DEPTH: 37,
  },
  {
    FEN: "r4rk1/2R5/1n2N1pp/2Rp4/p2P4/P3P2P/qP3PPK/8 w - -",
    EVAL: "M6",
    MOVES: "c7g7 g8h8 c5c7 b6d7 c7d7 a8a7 d7a7 f8f7 a7a8 f7f8",
    DEPTH: 245,
  },
  {
    FEN: "r1b1k2r/ppppqppp/2n5/4p3/2PPn3/2Q1PN2/PP3PPP/R1B1KB1R w KQkq -",
    EVAL: 0,
    MOVES: "c3d3 e5d4 f3d4 e4c5 d3d1 c6d4 d1d4 e8h8 b2b3 b7b6",
    DEPTH: 35,
  },
  {
    FEN: "r2qk1nr/ppp2ppp/2n1p3/3p1b2/1bPP4/4PN2/PP1B1PPP/RN1QKB1R w KQkq -",
    EVAL: 94,
    MOVES: "d2b4 c6b4 d1a4 b4c6 c4d5 e6d5 f1a6 d8b8 e1h1 g8e7",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkbnr/pppp2pp/8/3Ppn2/2P5/3B4/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: 66,
    MOVES: "f8b4 b1d2 d8g5 g2g3 e5e4 d3e4 g5e7 d1h5 g7g6 h5e2",
    DEPTH: 22,
  },
  {
    FEN: "r6k/pp4rn/2p1PN1R/3p1P2/8/8/PPPK4/8 w - -",
    EVAL: 0,
    MOVES: "d2d3 g7g3 d3d4 h8g7 h6h7 g7f6 h7f7 f6g5 f7g7 g5f5",
    DEPTH: 58,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R w KQkq -",
    EVAL: 33,
    MOVES: "d1d2 h7h5 c3d5 f6d5 e4d5 e6f5 f1e2 a6a5 a2a4 b8d7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/pppp1p1p/4pnp1/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 74,
    MOVES: "b1c3 d7d5 c1f4 f8g7 e2e3 e8h8 f1e2 d5c4 e2c4 b7b6",
    DEPTH: 40,
  },
  {
    FEN: "r4r1k/6R1/1n2N1pp/2Rp4/p2P4/P3P2P/qP3PPK/8 w - -",
    EVAL: "M5",
    MOVES: "c5c7 b6d7 c7d7 f8f7 d7f7 h6h5 g7h7 h8g8 f7g7",
    DEPTH: 224,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 12,
    MOVES: "g2g3 f8b4 c1d2 b4e7 f1g2 c7c6 e1h1 e8h8 d1c2 b7b6",
    DEPTH: 52,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R b KQkq -",
    EVAL: 22,
    MOVES: "e8h8 e1a1 b8d7 g2g4 b7b5 g4g5 f6h5 a2a3 d8c7 c1b1",
    DEPTH: 46,
  },
  {
    FEN: "r4rk1/2pqb1pp/p1n1n3/1p1pP1p1/8/1NP1BN1P/PP1Q1PP1/R4RK1 b - -",
    EVAL: -475,
    MOVES: "f8f3 g2f3 c6e5 g1g2 a8f8 b3d4 e6d4 d2d4 e5f3 d4g4",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p1Q/4P3/8/PPPP1PPP/RNB1KBNR b KQkq -",
    EVAL: -45,
    MOVES: "g7g6 h5h4 f5e4 d2d3 e4d3 f1d3 f8g7 g1f3 b8c6 b1c3",
    DEPTH: 54,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPP2PPP/R2Q1RK1 w - -",
    EVAL: 48,
    MOVES: "h2h3 d7d6 c3d5 a5d8 d5f6 g7f6 c2c3 c8d7 f1e1 c6d4",
    DEPTH: 40,
  },
  {
    FEN: "rn1qk1nr/pp1bppbp/3p2p1/1B6/3NP3/2N1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: -17,
    MOVES: "d7b5 d4b5 a7a6 b5d4 b8d7 f2f4 a8c8 d1f3 g8f6 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 18,
    MOVES: "d1a4 b8c6 a2a3 b4c3 b2c3 f6e4 e2e3 e4c3 a4c2 c3e4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppppp2p/6p1/8/4Q3/8/PPPP1PPP/RNB1KBNR b KQkq -",
    EVAL: -75,
    MOVES: "d7d5 e4a4 c8d7 f1b5 e7e5 b5d7 d8d7 a4d7 b8d7 d2d3",
    DEPTH: 26,
  },
  {
    FEN: "8/8/3kb3/8/3K4/4Q3/8/8 b - -",
    EVAL: "M9",
    MOVES: "e6f7 e3h6 d6d7 d4e5 d7c7 h6g7 c7c6 g7f7 c6c5 f7d5",
    DEPTH: 99,
  },
  {
    FEN: "r1bq1rk1/1p2ppbp/2np1np1/p7/2BNP1P1/2N1BP2/PPPQ3P/R3K2R b KQ -",
    EVAL: 50,
    MOVES: "a5a4 a2a3 f6d7 e1a1 d7e5 c4e2 c6a5 h2h4 c8d7 c1b1",
    DEPTH: 34,
  },
  {
    FEN: "r2qkbnr/pb1p1ppp/1pn1p3/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R w KQkq -",
    EVAL: 54,
    MOVES: "e1h1 f8e7 d2d4 c6d4 f3d4 b7g2 g1g2 c5d4 d1d4 g8f6",
    DEPTH: 26,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "e7e6 f1d3 f5d3 d1d3 b8d7 b1d2 g8f6 e1h1 f8e7 f1d1",
    DEPTH: 42,
  },
  {
    FEN: "rnbqk1nr/pp1p1ppp/8/4p3/1b1PP3/8/PP1B1PPP/RN1QKBNR b KQkq -",
    EVAL: 84,
    MOVES: "b4d2 d1d2 e5d4 d2d4 g8f6 b1c3 b8c6 d4d6 d8e7 e1a1",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/ppppp2p/5np1/8/4Q3/8/PPPP1PPP/RNB1KBNR w KQkq -",
    EVAL: -49,
    MOVES: "e4a4 e7e5 d2d4 e5d4 g1f3 b8c6 f1e2 f8g7 f3d4 c6d4",
    DEPTH: 27,
  },
  {
    FEN: "8/1q3k2/p3p3/2P4Q/P3P2P/2P5/4r1PK/8 b - -",
    EVAL: 1118,
    MOVES: "f7f6 h5e2 b7h7 e2f2 f6e7 e4e5 e7d7 f2d4 d7c7 d4d6",
    DEPTH: 33,
  },
  {
    FEN: "1k6/pp5p/3bBp2/2p2r2/P6P/2BnP3/1P6/5RKR b - -",
    EVAL: 762,
    MOVES: "f5e5 f1f6 e5e3 h1h3 e3h3 e6h3 d6e5 c3e5 d3e5 h3f1",
    DEPTH: 24,
  },
  {
    FEN: "6k1/1q6/p3p3/2P4Q/P3P2P/2P5/4r1PK/8 w - -",
    EVAL: 1036,
    MOVES: "h5e2 b7c7 e4e5 c7c5 e2g4 g8f7 g4f3 f7e8 f3h5 e8d8",
    DEPTH: 34,
  },
  {
    FEN: "1k4B1/pp5p/3bqp2/2p2r2/P6P/2BnP3/1P6/5RKR w - -",
    EVAL: 743,
    MOVES: "g8e6 f5f1 g1f1 c5c4 e6f5 d3c5 f5c2 b7b5 a4b5 b8c7",
    DEPTH: 23,
  },
  {
    FEN: "2rq1rk1/pp1bppbp/2np1np1/8/4P3/2N3PP/PPP1NPB1/R1BQ1RK1 w - -",
    EVAL: 47,
    MOVES: "c1e3 f6e8 a1b1 e7e6 d1d2 c6a5 b2b3 d8c7 e3d4 g7d4",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 45,
    MOVES: "g1f3 b8c6 d2d4 c5d4 f3d4 d8c7 g2g3 a7a6 f1g2 g8f6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN1B2P/PPP2PP1/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c8e6 g2g4 d6d5 e4d5 f6d5 f1g2 d5e3 d1d8 e7d8 f2e3",
    DEPTH: 42,
  },
  {
    FEN: "r3kbnr/pp1q1ppp/2np4/4p3/4P3/8/PPP1NPPP/RNBQK2R w KQkq -",
    EVAL: 90,
    MOVES: "f2f3 f7f5 c1e3 g8f6 b1c3 a8d8 c3d5 f8e7 e2c3 e8h8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p4/8/1P6/PBPP1PPP/RN1QKBNR w KQkq -",
    EVAL: -18,
    MOVES: "g1f3 c8f5 f1b5 b8c6 b1c3 a8c8 c3e2 e7e6 e2d4 f5g4",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pb2/8/3PN3/8/PPP2PPP/R2QKBNR w KQkq -",
    EVAL: 13,
    MOVES: "e4f6 d8f6 g1f3 e8h8 d1d3 f8d8 e1a1 b8c6 d3e4 e6e5",
    DEPTH: 42,
  },
  {
    FEN: "5r1k/r1R3R1/1n2N1pp/3p4/p2P4/P3P2P/qP3PPK/8 w - -",
    EVAL: "M4",
    MOVES: "c7a7 b6d7 a7d7 f8f7 d7d8 f7f8 d8f8",
    DEPTH: 245,
  },
  {
    FEN: "r1bq1rk1/ppppbppp/2n2n2/4p3/2BPP3/2P2N2/P4PPP/RNBQ1RK1 w - -",
    EVAL: -76,
    MOVES: "d4e5 f6e4 f1e1 e4c5 c1e3 c6a5 c4f1 d7d5 b1d2 c5e6",
    DEPTH: 25,
  },
  {
    FEN: "rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BQ1P/PPP2PP1/R3KB1R b KQkq -",
    EVAL: 0,
    MOVES: "e8h8 g2g4 a6a5 a2a4 b8a6 e1a1 a8c8 f1b5 c8c3 b2c3",
    DEPTH: 41,
  },
  {
    FEN: "2kr1bnr/pp1b1ppp/1qn1p3/3pP3/2pP4/P1P2NP1/1P3PBP/RNBQK2R w KQ -",
    EVAL: 33,
    MOVES: "b1d2 c6a5 h2h4 h7h6 a1b1 g8e7 h4h5 c8b8 e1f1 a5b3",
    DEPTH: 28,
  },
  {
    FEN: "r1r3k1/p2qpp1p/np1p2p1/3P4/PP1QP3/4BP2/6PP/2RR2K1 w - -",
    EVAL: 303,
    MOVES: "c1c6 a6b8 c6c8 d7c8 e4e5 c8f8 e5e6 f7e6 d4g4 f8f5",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: 69,
    MOVES: "f4e5 d7d6 e5d6 f8d6 g1f3 g8f6 g2g3 c7c5 f1g2 e8h8",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk1nr/pp1p1ppp/8/4p3/1b1PP3/8/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 83,
    MOVES: "c1d2 b4d2 d1d2 e5d4 d2d4 g8f6 b1c3 b8c6 d4d6 d8e7",
    DEPTH: 34,
  },
  {
    FEN: "2kr1bnr/pp1b1ppp/1qn1p3/3pP3/2pP4/P1P2NP1/1P3PBP/RNBQ1RK1 b - -",
    EVAL: 0,
    MOVES: "c8b8 f1e1 c6a5 b1d2 h7h6 a1b1 g8e7 h2h4 e7f5 d2f1",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/ppp3pp/3p1n2/3Ppp2/4P3/5P2/PPP3PP/RNBQKBNR w KQkq -",
    EVAL: 12,
    MOVES: "e4f5 c8f5 g1e2 f5g6 c2c4 b8a6 e2g3 c7c6 a2a3 f8e7",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p4/2p5/2B1n3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d1a4 b8d7 c4f7 e8f7 a4e4 d7f6 e4e2 h7h6 d2d4 c5d4",
    DEPTH: 35,
  },
  {
    FEN: "r1bqr1k1/p5pp/2pb4/n2npp2/8/2N2N2/PPPP1PPP/R1BQRBK1 b - -",
    EVAL: 77,
    MOVES: "e5e4 c3d5 c6d5 f3d4 a5c4 d2d3 c4e5 h2h3 g8h8 c1e3",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk1nr/ppp2ppp/3b4/8/8/5N2/PPPPP1PP/RNBQKB1R b KQkq -",
    EVAL: 51,
    MOVES: "g8f6 g2g3 f6g4 b1c3 h7h5 d2d3 h5h4 g3h4 g4h2 c1g5",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p4/2p5/Q1B1n3/2P2N2/PP1P1PPP/RNB1K2R b KQkq -",
    EVAL: -9,
    MOVES: "b8d7 c4f7 e8f7 a4e4 d7f6 e4c4 e7e6 d2d4 c5d4 c3d4",
    DEPTH: 46,
  },
  {
    FEN: "2kr1bnr/pp1b1ppp/1q2p3/n2pP3/2pP4/P1P2NP1/1P3PBP/RNBQ1RK1 w - -",
    EVAL: 0,
    MOVES: "f3g5 g8h6 b1d2 d7e8 a1b1 h6f5 g5h3 c8b8 f1e1 g7g6",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/4pP2/3P4/PPP3PP/RNBQKBNR w KQkq -",
    EVAL: -11,
    MOVES: "d3e4 d8d1 e1d1 g8f6 e4e5 f6d5 g1f3 c8f5 f3d4 f5g6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/p2npppp/3p1n2/2pP4/Pp2PB2/5P2/1PP3PP/RN1QKBNR w KQkq -",
    EVAL: -3,
    MOVES: "b1d2 g7g6 a4a5 f8g7 d2c4 e8h8 g1h3 a8b8 d1d2 d8c7",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/4PP2/8/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d8d1 e1d1 g8f6 e4e5 f6d5 g1f3 g7g6 c2c4 d5c7 c1e3",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkb1r/pp1bpppp/3p4/2p5/Q1B1n3/2P2N2/PP1P1PPP/RNB1K2R w KQkq -",
    EVAL: 75,
    MOVES: "c4f7 e8f7 a4e4 e7e6 d2d4 c5d4 e1h1 b8c6 c3d4 d6d5",
    DEPTH: 30,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/2p5/8/4PP2/8/PPP3PP/RNBqKBNR w KQkq -",
    EVAL: 13,
    MOVES: "e1d1 g8f6 e4e5 f6g4 d1e1 h7h5 g1f3 c8f5 f3d4 g4h6",
    DEPTH: 36,
  },
  {
    FEN: "rn1q1b1r/pp1bpkpp/3p4/2p5/Q3n3/2P2N2/PP1P1PPP/RNB1K2R w KQ -",
    EVAL: 59,
    MOVES: "a4e4 b8c6 e1h1 e7e6 d2d4 c5d4 c3d4 d6d5 e4f4 f7g8",
    DEPTH: 30,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/5n2/3p2B1/1b1P4/2NB4/PPP2PPP/R2QK1NR b KQkq -",
    EVAL: -13,
    MOVES: "e8h8 g1e2 h7h6 g5e3 f8e8 e1h1 b8c6 e2g3 c6a5 c3e2",
    DEPTH: 38,
  },
  {
    FEN: "rnb1kbnr/pp2pppp/2p5/8/4PP2/8/PPP3PP/RNBK1BNR b kq -",
    EVAL: -11,
    MOVES: "g8f6 e4e5 f6d5 g1f3 c8f5 f1d3 f5d3 c2d3 b8a6 d1e2",
    DEPTH: 39,
  },
  {
    FEN: "r1bk3r/ppp2ppp/2p5/2b5/4Q3/3P4/PPP1BPPP/RNq1K2R w KQ -",
    EVAL: -1223,
    MOVES: "e2d1 h8e8 b1d2 c1a1 e1h1 e8e4 d2e4 c5b6 e4d2 c8e6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np4/2p5/Q1B1n3/2P2N2/PP1P1PPP/RNB1K2R w KQkq -",
    EVAL: 0,
    MOVES: "c4f7 e8f7 a4e4 d6d5 f3g5 f7g8 e4f3 c6e5 f3g3 e5c6",
    DEPTH: 33,
  },
  {
    FEN: "rnbqk2r/ppp2pp1/3b4/7p/6n1/5NP1/PPPPP1BP/RNBQK2R w KQkq -",
    EVAL: 82,
    MOVES: "e1h1 h5h4 h2h3 g4f6 d2d4 h4g3 f3g5 c7c5 e2e4 c5d4",
    DEPTH: 27,
  },
  {
    FEN: "r1bqk1nr/ppp2ppp/2np4/b3P3/2B1P3/2P2N2/P4PPP/RNBQK2R b KQkq -",
    EVAL: -66,
    MOVES: "c8g4 c1g5 d8g5 f3g5 g4d1 c4f7 e8f8 f7g8 f8g8 e1d1",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/pppnbppp/3p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 96,
    MOVES: "g2g4 d7b6 d4f5 c8f5 g4f5 c7c6 h1g1 d8c7 e3d4 h8g8",
    DEPTH: 26,
  },
  {
    FEN: "8/8/2k5/8/3BK3/8/8/5N2 w - -",
    EVAL: "M26",
    MOVES: "e4e5 c6d7 e5d5 d7c7 f1e3 c7b7 d5d6 b7a8 d6c6 a8b8",
    DEPTH: 78,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/1Bpp4/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 75,
    MOVES: "e4d5 e6d5 d2d4 g8f6 f1e1 c8e6 c1g5 f8e7 d4c5 e7c5",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/1p2pppp/p2p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 a2a4 f8e7 e1h1 e8h8 c1e3 b8c6 f1e1 c8d7 d4c6",
    DEPTH: 49,
  },
  {
    FEN: "r4k1r/1q3p1p/p1N2p2/1pp5/8/1PPP4/1P3PPP/R1B1R1K1 w - -",
    EVAL: "M4",
    MOVES: "c1h6 f8g8 e1e3 b7c7 c6e7 c7e7 e3g3",
    DEPTH: 245,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/3p4/8/1P6/PBPP1PPP/RN1QKBNR w KQkq -",
    EVAL: -13,
    MOVES: "g1f3 c8g4 h2h3 g4h5 f1e2 e7e6 e1h1 g8f6 d2d4 f8e7",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/2Pp4/3P4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: -63,
    MOVES: "b7b6 c5b6 a7b6 e2e3 g8f6 g1f3 f8b4 c1d2 b4d6 b1c3",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2np4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 76,
    MOVES: "d4d5 c6e7 c2c4 g7g6 b1c3 f8g7 h2h4 g8f6 f3h2 a7a5",
    DEPTH: 54,
  },
  {
    FEN: "2kr1bnr/pp1b1ppp/1q2p3/n2pP3/2pP4/P1P2NP1/1P1N1PBP/R1BQ1RK1 b - -",
    EVAL: 9,
    MOVES: "c8b8 f1e1 g8e7 a1b1 h7h6 d2f1 a5b3 f3d2 d7a4 d1e2",
    DEPTH: 31,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/2np1np1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1e2 a8b8 h2h3 c8d7 a2a4 a7a6 g3g4 e8h8 e1h1 b7b5",
    DEPTH: 46,
  },
  {
    FEN: "r2qkb1r/ppp1pppp/2n2n2/3p4/3P1P2/3PP3/PP4PP/RNBQK1NR w KQkq -",
    EVAL: 75,
    MOVES: "g1f3 e7e6 e1h1 f8e7 b1c3 e8h8 e3e4 d5e4 d3e4 a7a6",
    DEPTH: 28,
  },
  {
    FEN: "2kr1bnr/pp1b1pp1/1q2p2p/n2pP3/2pP4/P1P2NP1/1P1N1PBP/R1BQ1RK1 w - -",
    EVAL: 21,
    MOVES: "f1e1 g8e7 d2f1 e7f5 d1e2 a5b3 a1b1 c8b8 h2h4 b3c1",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk2r/pp3ppp/2p2n2/3p2B1/1b1P4/2NB4/PPP1NPPP/R2QK2R b KQkq -",
    EVAL: 0,
    MOVES: "e8h8 a2a3 b4d6 d1d2 f8e8 e1h1 b8d7 h2h3 d7f8 a1e1",
    DEPTH: 26,
  },
  {
    FEN: "r5kr/1q3p1p/p1N2p1B/1pp5/8/1PPP4/1P3PPP/R3R1K1 w - -",
    EVAL: "M3",
    MOVES: "e1e3 b7c7 e3g3 c7g3 c6e7",
    DEPTH: 245,
  },
  {
    FEN: "r1bqr1k1/p5pp/3b4/n2p1p2/3Np3/8/PPPP1PPP/R1BQRBK1 b - -",
    EVAL: 55,
    MOVES: "d8f6 c2c3 a5c4 d4b5 d6b8 d2d3 c4e5 d3e4 d5e4 d1h5",
    DEPTH: 33,
  },
  {
    FEN: "r1b2bnr/ppppkBpp/8/4q3/3nP3/8/PPPP1PPP/RNBQ1RK1 w - -",
    EVAL: 49,
    MOVES: "f7g8 h8g8 c2c3 d4e6 f2f4 e5e4 f4f5 e7d8 f5e6 e4e6",
    DEPTH: 36,
  },
  {
    FEN: "2kr1b1r/pp1bnpp1/1q2p2p/n2pP3/2pP4/P1P2NP1/1P3PBP/R1BQRNK1 b - -",
    EVAL: 0,
    MOVES: "e7f5 a1b1 b6b3 d1e2 d7a4 h2h4 b3c2 c1d2 c8b8 e1c1",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5B2/2pp4/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq -",
    EVAL: -13,
    MOVES: "g7f6 e2e4 d5e4 d4c5 f6f5 d1d8 e8d8 e1a1 c8d7 g2g4",
    DEPTH: 41,
  },
  {
    FEN: "r1b2bnr/ppppk1pp/8/4q3/2BnP3/8/PPPP1PPP/RNBQ1RK1 b - -",
    EVAL: 63,
    MOVES: "e7d8 c2c3 d4e6 f1e1 g7g5 d2d4 e5g7 b1d2 g8h6 a2a4",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 77,
    MOVES: "e5d4 f3d4 c8d7 b1c3 g7g6 b5c6 b7c6 c1f4 f8g7 d1d2",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/8/4P3/PPPP1PPP/RNBQKBNR b KQkq -",
    EVAL: 7,
    MOVES: "e7e6 g1f3 g8f6 d2d4 f8e7 f1d3 d7d5 e1h1 e8h8 b2b3",
    DEPTH: 57,
  },
  {
    FEN: "rn1qk2r/pbp2ppp/1p2p3/3pP3/1b1Pn3/2NB1N2/PPP1QPPP/R1B1K2R w KQkq -",
    EVAL: 0,
    MOVES: "c1d2 b4c3 d2c3 e4c3 b2c3 c7c5 e2e3 h7h6 e1h1 e8h8",
    DEPTH: 43,
  },
  {
    FEN: "2kr1b1r/pp1b1pp1/1q2p2p/n2pP3/2pP4/P1P1nNP1/1P3PBP/R1BQR1K1 w - -",
    EVAL: -8,
    MOVES: "e1e3 b6b3 d1f1 b3c2 e3e2 c2f5 c1e3 f8e7 f3e1 g7g5",
    DEPTH: 26,
  },
  {
    FEN: "r1bqk2r/1pp2ppp/2np1n2/p1b1p3/P1B1P3/2PP1N2/1P3PPP/RNBQK2R w KQkq -",
    EVAL: 6,
    MOVES: "c4b3 h7h6 e1h1 e8h8 h2h3 c8e6 b3e6 f7e6 c1e3 c5e3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
    EVAL: 72,
    MOVES: "c2c4 e7e6 e2e3 g8f6 b1c3 f8d6 c4c5 d6e7 a2a3 f6e4",
    DEPTH: 50,
  },
  {
    FEN: "rnbqk1nr/ppp2p1p/3p3b/8/3PPppP/8/PPP3P1/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 c7c6 g1e2 d8f6 e4e5 d6e5 c3e4 f6e6 d4e5 b8d7",
    DEPTH: 41,
  },
  {
    FEN: "2kr3r/pp1bbpp1/1q2p2p/n2pP3/2pP4/P1P1RNP1/1P3PBP/R1BQ2K1 w - -",
    EVAL: -13,
    MOVES: "e3e1 a5b3 a1b1 b3c1 d1c1 d7a4 b2b3 c4b3 c3c4 d5c4",
    DEPTH: 46,
  },
  {
    FEN: "8/2k5/8/4K3/3B4/8/8/5N2 w - -",
    EVAL: "M25",
    MOVES: "e5d5 c7b7 d4e5 b7a7 d5c6 a7a8 f1d2 a8a7 d2c4 a7a8",
    DEPTH: 73,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 62,
    MOVES: "g2g4 h7h6 h2h4 b8c6 h1g1 d6d5 e4d5 f6d5 c3d5 e6d5",
    DEPTH: 46,
  },
  {
    FEN: "r2qkbnr/pppb1ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 75,
    MOVES: "b5c6 d7c6 b1c3 e5d4 f3d4 g7g6 d4c6 b7c6 d1d4 g8f6",
    DEPTH: 61,
  },
  {
    FEN: "2kr3r/pp1bbpp1/1q2p2p/n2pP3/2pP4/P1P2NP1/1P3PBP/R1BQR1K1 b - -",
    EVAL: -63,
    MOVES: "b6b3 d1e2 d7a4 h2h3 b3c2 c1e3 c8b8 f3h2 c2g6 a1c1",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/2np1np1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R b KQkq -",
    EVAL: -12,
    MOVES: "a8b8 e1h1 e8h8 h2h3 b7b5 g3g4 e7e6 c3e2 b5b4 f4f5",
    DEPTH: 43,
  },
  {
    FEN: "2b5/8/2k5/4K3/5Q2/8/8/8 b - -",
    EVAL: "M9",
    MOVES: "c6b5 e5d4 c8h3 f4f3 h3c8 f3c3 c8e6 c3c5 b5a6 c5c6",
    DEPTH: 48,
  },
  {
    FEN: "2kr3r/pp1bbpp1/4p2p/n2pP3/2pP4/PqP2NP1/1P3PBP/R1BQR1K1 w - -",
    EVAL: -16,
    MOVES: "d1e2 d7a4 c1e3 b3c2 a1c1 c2e2 e1e2 d8g8 f3d2 a5c6",
    DEPTH: 28,
  },
  {
    FEN: "2kr3r/pp1bbpp1/4p2p/n2pP3/2pP4/PqP2NP1/1P2QPBP/R1B1R1K1 b - -",
    EVAL: 0,
    MOVES: "d7a4 c1e3 b3c2 a1c1 c2e2 e1e2 c8b8 h2h3 a5c6 f3d2",
    DEPTH: 32,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/3p1np1/8/2BBP3/2N2P2/PPPQ2PP/R3K2R b KQ -",
    EVAL: 43,
    MOVES: "d8a5 e1a1 c8e6 c4e6 f7e6 h1e1 f6d7 d4g7 g8g7 e4e5",
    DEPTH: 46,
  },
  {
    FEN: "8/1Q1rkRp1/p3p3/3nB1r1/8/3q3P/PP6/K1R5 b - -",
    EVAL: 748,
    MOVES: "e7f7 b7d7 f7g6 d7g7 g6f5 g7h7 g5g6 c1g1 f5e5 h7g6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "a7a6 a2a4 b8c6 c1e3 f8e7 e1h1 e8h8 d1e2 c8d7 d4c6",
    DEPTH: 44,
  },
  {
    FEN: "r2q1rk1/pp2ppbp/3pbnp1/8/2BBP3/2N2P2/PPPQ2PP/R3K2R w KQ -",
    EVAL: 45,
    MOVES: "c4e6 f7e6 e1a1 d8a5 h1e1 f6d7 d4g7 g8g7 e4e5 d6d5",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R w KQ -",
    EVAL: -28,
    MOVES: "a2a4 a7a6 e1h1 a8b8 h2h3 b7b5 c3e2 c8b7 c1e3 f6d7",
    DEPTH: 32,
  },
  {
    FEN: "2kr3r/pp2bpp1/4p2p/n2pP3/b1pP4/PqP2NP1/1P2QPBP/R1B1R1K1 w - -",
    EVAL: 0,
    MOVES: "c1e3 b3c2 a1c1 c2e2 e1e2 d8g8 h2h4 a5b3 c1e1 e7d8",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pb2/8/3PN3/5N2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 15,
    MOVES: "e8h8 f1c4 b8d7 d1e2 a7a6 e1h1 c7c5 f1d1 c5d4 e4f6",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq -",
    EVAL: 3,
    MOVES: "c7c5 e2e3 c5d4 e3d4 b8c6 g1f3 c8f5 c2c4 e7e6 b1c3",
    DEPTH: 55,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/1p2P3/8/P1PP1PPP/RNBQKBNR w KQkq -",
    EVAL: -35,
    MOVES: "g1f3 d7d5 e4d5 d8d5 c2c4 d5d8 d2d4 e7e6 g2g3 b7b5",
    DEPTH: 52,
  },
  {
    FEN: "1k1r3r/pp2bpp1/4p2p/n2pP3/b1pP4/PqP1BNP1/1P2QPBP/R3R1K1 w - -",
    EVAL: 0,
    MOVES: "a1c1 a5c6 h2h4 d8f8 f3h2 e7d8 h2g4 c6e7 h4h5 b3b5",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkbnr/pppppppp/2n5/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 40,
    MOVES: "e7e5 d4d5 f8b4 b1d2 c6e7 g1f3 b4d2 d1d2 d7d6 d2g5",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/pp4pp/2p1p3/2Pp1p2/3P4/4P3/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 51,
    MOVES: "b2b4 a7a5 b4b5 b7b6 c1a3 a5a4 b1c3 b6c5 d4c5 d8a5",
    DEPTH: 23,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4p3/8/3PN3/5N2/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 65,
    MOVES: "f1d3 b8d7 d1e2 c7c5 e4c5 d8a5 c2c3 d7c5 d4c5 a5c5",
    DEPTH: 39,
  },
  {
    FEN: "rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1BQ1P/PPP2PP1/R3KB1R w KQ -",
    EVAL: 0,
    MOVES: "e1a1 b7b5 g2g4 b5b4 c3d5 f6d5 e4d5 e6c8 b3d2 b8d7",
    DEPTH: 38,
  },
  {
    FEN: "4rk2/p1r2pp1/1p2pq2/nP1bN2Q/3P4/P6R/5PPP/R5K1 w - -",
    EVAL: -500,
    MOVES: "h3c3 a5c4 a1c1 e8c8 a3a4 f6h6 h5h6 g7h6 h2h4 f8e7",
    DEPTH: 37,
  },
  {
    FEN: "1k1r3r/pp2bpp1/4p2p/n2pP3/b1pP4/PqP1BNP1/1P2QPBP/3RR1K1 b - -",
    EVAL: -32,
    MOVES: "b3c2 d1c1 c2f5 h2h3 g7g5 f3h2 h6h5 h2f1 f5g6 f2f4",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 43,
    MOVES: "g1f3 e7e6 e2e3 g8f6 a2a3 d5c4 f1c4 f8d6 e1h1 e8h8",
    DEPTH: 51,
  },
  {
    FEN: "1k1r3r/pp2bpp1/4p2p/n2pP3/b1pP4/P1P1BNP1/1Pq1QPBP/3RR1K1 w - -",
    EVAL: -56,
    MOVES: "d1c1 c2f5 h2h3 g7g5 f3h2 h6h5 h2f1 a5b3 c1d1 f5g6",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/2ppP3/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -48,
    MOVES: "c8g4 c2c3 b8c6 f1b5 g4f3 d1f3 e7e6 b5c6 b7c6 e1h1",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3P4/3P4/8/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 62,
    MOVES: "d8d5 e2e3 e7e5 b1c3 f8b4 a2a3 b4c3 b2c3 g8f6 c3c4",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/8/1p2P3/P7/2PP1PPP/RNBQKBNR b KQkq -",
    EVAL: -62,
    MOVES: "e7e5 g1f3 b8c6 c1b2 d8b6 f1d3 d7d6 c2c3 b4a3 b1a3",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/1BppP3/8/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -50,
    MOVES: "c8d7 b5d7 d8d7 c2c3 b8c6 d2d4 g8e7 e1h1 e7f5 a2a3",
    DEPTH: 44,
  },
  {
    FEN: "r1b2r1k/pp2Npbp/2p2np1/q7/8/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: -7,
    MOVES: "e7c8 a8c8 e1h1 c6c5 a2a4 c5c4 b3c2 f8d8 d1c1 h8g8",
    DEPTH: 31,
  },
  {
    FEN: "1k1r3r/pp2bp2/4p2p/n2pPqp1/b1pP4/P1P1BNPP/1P1RQPB1/5RK1 b - -",
    EVAL: -33,
    MOVES: "d8f8 f3e1 h6h5 f2f4 g5g4 h3h4 f7f6 e3f2 e7d8 e1c2",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/b7/2BpP3/2P2N2/P4PPP/RNBQ1RK1 w kq -",
    EVAL: -17,
    MOVES: "c1a3 d7d6 e4e5 f6e4 e5d6 c7d6 d1e2 d6d5 f3d4 c6d4",
    DEPTH: 50,
  },
  {
    FEN: "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 59,
    MOVES: "e2e3 e7e5 b1c3 f8b4 c1d2 b4c3 b2c3 g8f6 c3c4 d5d6",
    DEPTH: 52,
  },
  {
    FEN: "3qrk2/p2r1pp1/1p2pb2/nP1b3Q/3PN3/P6R/5PPP/R5K1 w - -",
    EVAL: -1020,
    MOVES: "e4f6 d8f6 h5d1 d5c4 a1b1 f6d4 d1e1 d4d2 a3a4 d2e1",
    DEPTH: 27,
  },
  {
    FEN: "1k1r3r/pp2bp2/4p2p/n2pPqp1/b1pP4/P1P1BNP1/1P1RQPBP/5RK1 w - -",
    EVAL: -35,
    MOVES: "f3e1 h6h5 h2h3 d8f8 f2f4 g5g4 h3h4 a5b3 d2d1 f7f6",
    DEPTH: 36,
  },
  {
    FEN: "r2q1rk1/pp2ppbp/3pbnp1/8/3BP3/1BN2P2/PPPQ2PP/R3K2R b KQ -",
    EVAL: 30,
    MOVES: "d8a5 e1a1 b7b5 c1b1 b5b4 b3e6 f7e6 c3e2 f8c8 h2h4",
    DEPTH: 35,
  },
  {
    FEN: "1k1r3r/pp2bpp1/4p2p/n2pPq2/b1pP4/P1P1BNP1/1P1RQPBP/5RK1 b - -",
    EVAL: -46,
    MOVES: "g7g5 h2h3 d8g8 f3e1 a5c6 f2f4 g5g4 h3h4 e7d8 e1c2",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/8/4P3/B7/2PP1PPP/RN1QKBNR w KQkq -",
    EVAL: -51,
    MOVES: "g1f3 g7g6 f1b5 c8d7 b5d7 b8d7 d2d4 f8g7 d1e2 g8f6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/4P3/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 8,
    MOVES: "e7e6 g1f3 f8d6 b1d2 e8h8 f1d3 d6f4 e3f4 b7b6 h2h4",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/1BppP3/8/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: -36,
    MOVES: "g8e7 c2c3 c8d7 b1a3 a7a6 b5e2 d5d4 a3c2 e7g6 c3d4",
    DEPTH: 32,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/4p3/1bP5/P1N2N2/1P1PPPPP/R1BQKB1R b KQkq -",
    EVAL: -44,
    MOVES: "b4c3 d2c3 g8f6 d1c2 d8e7 e2e4 d7d6 f1e2 a7a5 e1h1",
    DEPTH: 26,
  },
  {
    FEN: "r4rk1/pp2ppbp/3pbnp1/q7/3BP3/1BN2P2/PPPQ2PP/R3K2R w KQ -",
    EVAL: 36,
    MOVES: "e1a1 b7b5 c1b1 b5b4 c3d5 e6d5 e4d5 a5b5 d2d3 b5d3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p4/4N3/4Q3/8/PPPP1PPP/RNB1KB1R b KQkq -",
    EVAL: 96,
    MOVES: "d8e7 d2d4 d6e5 d4e5 b8c6 b1c3 e7e5 e4e5 c6e5 c3b5",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/ppp1pppp/2n2n2/3p4/3P1B2/2N5/PPPQPPPP/R3KBNR b KQkq -",
    EVAL: 3,
    MOVES: "c8f5 f2f3 e7e6 g2g4 f5g6 a2a3 h7h5 g4g5 f6d7 e1a1",
    DEPTH: 29,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/1qn3p1/3N4/3Nn3/1B2BP2/PPP3PP/R2QK2R b KQ -",
    EVAL: 0,
    MOVES: "b6a5 c2c3 e4c5 d4c6 d7c6 d5e7 g8h8 e7c8 f8c8 e1h1",
    DEPTH: 38,
  },
  {
    FEN: "rn2kbnr/pp1q1ppp/3p4/4p3/4P3/1N6/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 52,
    MOVES: "b8c6 b1c3 f8e7 d1e2 a7a6 h2h4 f7f5 e4f5 d7f5 c1e3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3p4/3P1B2/2N5/PPPQPPPP/2KR1BNR b kq -",
    EVAL: -63,
    MOVES: "c7c6 h2h4 b8d7 f2f3 b7b5 g2g4 h7h6 e2e3 d7b6 d2h2",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 12,
    MOVES: "c7c5 b1d2 b8c6 c2c3 f8d6 f4g3 e8h8 f1d3 b7b6 e1h1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/1BppP3/5P2/5N2/PPPP2PP/RNBQK2R b KQkq -",
    EVAL: -67,
    MOVES: "g8e7 c2c3 c8d7 b5a4 d8b6 e1h1 h7h6 a4c2 g7g5 d2d4",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "c4d5 e6d5 e2e3 e8h8 f1d3 e7d6 c3b5 d6e7",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pP4/8/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 21,
    MOVES: "d8d5 f1e2 g8f6 e1h1 b8c6 d2d4 c5d4 f3d4 c8d7 c1e3",
    DEPTH: 45,
  },
  {
    FEN: "1k1r3r/pp2bp2/4p3/n2pPqpp/b1pP4/P1P1BNPP/1P1RQPB1/5RK1 w - -",
    EVAL: -57,
    MOVES: "f3e1 d8f8 g1h2 e7d8 f2f4 g5g4 h3h4 a5b3 d2d1 f7f6",
    DEPTH: 32,
  },
  {
    FEN: "1k1r3r/pp2bp2/4p3/n2pPqpp/b1pP4/P1P1B1PP/1P1RQPBN/5RK1 b - -",
    EVAL: -81,
    MOVES: "d8f8 f2f4 g5g4 h3h4 e7d8 f1e1 a5c6 h2f1 c6e7 e3f2",
    DEPTH: 29,
  },
  {
    FEN: "1k4rr/pp2bp2/4p3/n2pPqpp/b1pP4/P1P1B1PP/1P1RQPBN/5RK1 w - -",
    EVAL: -52,
    MOVES: "f2f4 g5g4 h3h4 a5c6 f1e1 e7d8 h2f1 c6e7 e3f2 d8c7",
    DEPTH: 33,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/4p3/2pq4/8/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 19,
    MOVES: "f1e2 g8f6 e1h1 b8c6 d2d4 c5d4 f3d4 c8d7 c1e3 f8e7",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/3p4/8/1P3N2/PBPP1PPP/RN1QKB1R b KQkq -",
    EVAL: -18,
    MOVES: "c8g4 h2h3 g4h5 f1e2 g8f6 f3e5 h5e2 d1e2 c6e5 b2e5",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/4p3/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 26,
    MOVES: "c5d4 c3d4 g8f6 b1c3 d5d6 f1c4 f8e7 e1h1 e8h8 c1e3",
    DEPTH: 42,
  },
  {
    FEN: "R7/4kbr1/8/p3PN2/8/5K2/8/8 b - -",
    EVAL: 675,
    MOVES: "e7e6 f5g7 e6e5 a8a5 e5d4 a5a3 d4c4 f3f4 c4b4 a3e3",
    DEPTH: 45,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2n3p1/q1nN4/3N4/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: -13,
    MOVES: "d4c6 d7c6 d5e7 g8h8 e7c8 a8c8 e1h1 f8e8 e3f2 c8d8",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7c6 d2d3 f8d6 h2h3 c8e6 f3g5 e6d7 g5f3",
    DEPTH: 53,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 31,
    MOVES: "g8f6 b1c3 e8h8 c1e3 f6g4 e3g5 h7h6 g5h4 g4f6 h2h3",
    DEPTH: 47,
  },
  {
    FEN: "rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 17,
    MOVES: "b1a3 b8c6 c1e3 c5d4 a3b5 d5d7 b5d4 f8e7 d4c6 d7c6",
    DEPTH: 49,
  },
  {
    FEN: "R7/5br1/4k3/p3PN2/8/5K2/8/8 w - -",
    EVAL: 5580,
    MOVES: "f5g7 e6e5 f3e3 e5f6 g7e8 f6e7 e8c7 f7b3 e3d4 e7d7",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/p1pp1ppp/2p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 40,
    MOVES: "e1h1 d7d6 f1e1 f8e7 d2d4 e5d4 f3d4 c8d7 c2c4 c6c5",
    DEPTH: 48,
  },
  {
    FEN: "3qrk2/p5p1/1p2pr2/nP1bN3/3P4/P6R/5PPP/R5K1 w - -",
    EVAL: -1431,
    MOVES: "e5g4 f6f4 g4e5 f8g8 a1e1 f4f6 h3d3 f6f8 h2h3 d8c7",
    DEPTH: 44,
  },
  {
    FEN: "rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/N1P2N2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "b8c6 c1e3 c5d4 a3b5 d5d7 b5d4 f8e7 d4c6 d7c6 f1d3",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 22,
    MOVES: "g1f3 g8f6 b1c3 f8b4 d1a4 b8c6 e2e3 e8h8 a4c2 b7b6",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4N3/4n3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 75,
    MOVES: "d1e2 d8e7 e2e4 d7d6 d2d4 d6e5 d4e5 b8c6 b1c3 e7e5",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2p5/3P4/N1P2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 24,
    MOVES: "c1f4 c5d4 f3d4 f8c5 a3c4 e8h8 d4b3 c5e7 f1e2 b8c6",
    DEPTH: 41,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 w kq -",
    EVAL: -81,
    MOVES: "d2d4 e8h8 d4e5 f6e4 c4d5 e4c5 c1g5 c6e7 b1d2 h7h6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/1BppP3/8/P4N2/1PPP1PPP/RNBQK2R b KQkq -",
    EVAL: -54,
    MOVES: "g8e7 e1h1 a7a6 b5c6 e7c6 c2c3 d5d4 d2d3 f8e7 f1e1",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/6n1/8/4PP2/4B3/PPP3PP/RN1QKBNR b KQkq -",
    EVAL: 27,
    MOVES: "g8f6 e4e5 d8e7 d1f3 g6h4 f3e2 f6d5 e3d2 d7d6 b1c3",
    DEPTH: 38,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -",
    EVAL: 14,
    MOVES: "f1e1 f6g4 e1f1 g4f6 b1c3 e8h8 d2d3 h7h6 c1e3 c5e3",
    DEPTH: 53,
  },
  {
    FEN: "r4rk1/pp2ppbp/3pbnp1/q7/3BP3/1BN2P2/PPPQ2PP/2KR3R b - -",
    EVAL: 25,
    MOVES: "b7b5 c1b1 b5b4 c3d5 e6d5 e4d5 a5b5 d2d3 b5d3 d1d3",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2p5/3P4/2P2N2/PPN2PPP/R1BQKB1R w KQkq -",
    EVAL: 15,
    MOVES: "f1d3 c5d4 c2d4 c6d4 f3d4 f8c5 d4b3 c5b6 d1e2 c8d7",
    DEPTH: 34,
  },
  {
    FEN: "r1bq1rk1/ppp1bppp/2n1pn2/3p4/2PP1B2/P1N1PN2/1P3PPP/R2QKB1R b KQ -",
    EVAL: 79,
    MOVES: "e7d6 f4g3 b7b6 g3h4 d6e7 d1c2 c8b7 a1d1 f6e4 c4d5",
    DEPTH: 42,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p4/6b1/1P3N2/PBPP1PPP/RN1QKB1R w KQkq -",
    EVAL: -23,
    MOVES: "h2h3 g4h5 f1e2 e7e6 e1h1 g8f6 d2d4 f8e7 f3e5 h5e2",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/8/3pP2n/3P1p2/5N2/PPP3PP/RNBQKB1R w KQkq d6",
    EVAL: -44,
    MOVES: "c2c4 g7g5 g2g4 d5c4 f1c4 h5g7 b1c3 c8g4 h2h3 g4h5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/p3ppbp/2p2np1/1p1p4/3P1B2/2N2P2/PPPQP1PP/2KR1BNR w kq -",
    EVAL: 0,
    MOVES: "c1b1 b8d7 f4h6 g7h6 d2h6 b5b4 c3a4 d8a5 b2b3 c8a6",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp1p1p1p/4p1p1/2p5/2P5/6P1/PP1PPPBP/RNBQK1NR w KQkq -",
    EVAL: 83,
    MOVES: "d2d4 c5d4 d1d4 g8f6 c1g5 f8g7 b1c3 b8c6 d4d6 d8e7",
    DEPTH: 42,
  },
  {
    FEN: "rnb1kb1r/ppppqppp/8/4N3/4Q3/8/PPPP1PPP/RNB1KB1R b KQkq -",
    EVAL: 73,
    MOVES: "d7d6 d2d4 d6e5 d4e5 b8c6 b1c3 e7e5 e4e5 c6e5 c3b5",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/3p1n2/3Pp3/4P1b1/3B1N2/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 55,
    MOVES: "b8d7 h2h3 g4h5 c1e3 f8e7 b1c3 a8c8 a1c1 d7c5 d3b1",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 32,
    MOVES: "e2e4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4 h7h6",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7c6 e1h1 d8f6 d2d4 e5d4 c1g5 f6d6 f3d4 f8e7 g5e3",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/3pP3/8/8/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 47,
    MOVES: "d2d4 g8e7 g1f3 b8c6 f1b5 c8d7 b1c3 d8b6 e1h1 a8c8",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p2B1/2PP4/8/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: -19,
    MOVES: "g7g6 b1c3 f8g7 g1f3 e8h8 g5f6 e7f6 c3d5 b8c6 f1e2",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/2p5/8/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq -",
    EVAL: 8,
    MOVES: "d8b6 b2b3 g8f6 a2a4 g7g6 g1f3 f8g7 a4a5 b6d8 g2g3",
    DEPTH: 49,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR b KQ -",
    EVAL: 49,
    MOVES: "a7a5 a2a3 e7e5 g1f3 e5d4 f3d4 b8a6 f2f3 c7c6 d1d2",
    DEPTH: 53,
  },
  {
    FEN: "r1b1kb1r/ppp1qppp/2n5/4P3/4Q3/8/PPP2PPP/RNB1KB1R w KQkq -",
    EVAL: 74,
    MOVES: "b1c3 e7e5 e4e5 c6e5 c1f4 f7f6 e1a1 f8d6 f4g3 h7h5",
    DEPTH: 53,
  },
  {
    FEN: "r1b2rk1/pp1pppbp/2N3p1/q1nN4/8/1BP1BP2/PP4PP/R2QK2R b KQ -",
    EVAL: -13,
    MOVES: "d7c6 d5e7 g8h8 e7c8 f8c8 e1h1 c8e8 e3f2 a8d8 d1c2",
    DEPTH: 42,
  },
  {
    FEN: "R4k2/5br1/3N4/p3P3/8/5K2/8/8 b - -",
    EVAL: 391,
    MOVES: "f8e7 d6f5 e7e6 f5g7 e6e5 a8a5 e5d4 a5a3 d4c5 f3f4",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pppnpppp/8/8/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 58,
    MOVES: "e7e6 c2c4 b7b6 b1c3 c8b7 f1d3 g8f6 e1h1 f8d6 c3b5",
    DEPTH: 27,
  },
  {
    FEN: "rnb1kb1r/ppp1qppp/8/4P3/4Q3/8/PPP2PPP/RNB1KB1R b KQkq -",
    EVAL: 58,
    MOVES: "b8c6 b1c3 e7e5 e4e5 c6e5 c3b5 f8b4 c1d2 b4d2 e1d2",
    DEPTH: 48,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3bpn2/3p4/3P4/4PNB1/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 8,
    MOVES: "e8h8 c2c4 c7c5 d4c5 d6c5 d1c2 b8c6 a2a3 c5e7 b1c3",
    DEPTH: 56,
  },
  {
    FEN: "r4rk1/p3ppbp/3pbnp1/qp6/3BP3/1BN2P2/PPPQ2PP/2KR3R w - -",
    EVAL: 26,
    MOVES: "c1b1 b5b4 b3e6 f7e6 c3e2 a5b5 d4e3 a7a5 d2d3 b5d7",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -",
    EVAL: -33,
    MOVES: "d7d5 e4d5 e6d5 c4e2 g8f6 d2d4 f8d6 g1f3 e8h8 c1g5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 20,
    MOVES: "b1d2 f8e7 c2c3 b8c6 f3e5 f6d7 e5d7 c8d7 f1e2 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "r1b2rk1/pp2ppbp/2p3p1/q1nN4/8/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: 0,
    MOVES: "d5e7 g8h8 e7c8 a8c8 e1h1 f8e8 e3f2 c8d8 d1c2 a5b5",
    DEPTH: 51,
  },
  {
    FEN: "8/8/3kn3/8/8/3KQ3/8/8 w - -",
    EVAL: "M12",
    MOVES: "d3c4 d6d7 e3d2 d7e7 c4d5 e6f8 d2f4 f8g6 f4f2 g6f8",
    DEPTH: 116,
  },
  {
    FEN: "r1b2rk1/pp2Npbp/2p3p1/q1n5/8/1BP1BP2/PP4PP/R2QK2R b KQ -",
    EVAL: -13,
    MOVES: "g8h8 e7c8 f8c8 e1h1 c8e8 e3f2 a8d8 d1c2 a5b5 b3f7",
    DEPTH: 34,
  },
  {
    FEN: "r1bq1rk1/ppp1bpp1/2n1pn1p/3p4/2PP1B2/P1N1PN2/1P3PPP/R2QKB1R w KQ -",
    EVAL: 84,
    MOVES: "a1c1 e7d6 c4d5 e6d5 f4d6 c7d6 f1d3 c8e6 e1h1 a8c8",
    DEPTH: 24,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p4/6b1/1P3N2/PBPPBPPP/RN1QK2R b KQkq -",
    EVAL: -32,
    MOVES: "g8f6 e1h1 e7e6 h2h3 g4f5 e2b5 d8c7 b2e5 f8d6 b5c6",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/8/3p2B1/2PPn3/8/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: -16,
    MOVES: "g5e3 e7e5 b1c3 d8a5 g1f3 f8b4 a1c1 e4c3 b2c3 b4c3",
    DEPTH: 35,
  },
  {
    FEN: "r1bq1rk1/pppnpp1p/3p1npb/8/3PP3/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 96,
    MOVES: "d2h6 e7e5 d4e5 d6e5 h2h4 f6g4 h6d2 d8e7 h4h5 d7b6",
    DEPTH: 25,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/2p2np1/3p4/3P1B2/2N5/PPPQPPPP/2KR1BNR w kq -",
    EVAL: -38,
    MOVES: "h2h4 b7b5 f2f3 h7h5 g1h3 c8h3 h1h3 b8d7 e2e4 b5b4",
    DEPTH: 32,
  },
  {
    FEN: "r2qkbnr/pp2p1pp/2n2p2/2pp4/3P1Bb1/2P1PN2/PP3PPP/RN1QKB1R w KQkq -",
    EVAL: 65,
    MOVES: "f1e2 c5d4 c3d4 e7e6 h2h3 g4h5 e1h1 f8d6 f4d6 d8d6",
    DEPTH: 28,
  },
  {
    FEN: "r2qkb1r/pp2pppp/2n2n2/3p4/6b1/1P3N2/PBPPBPPP/RN1QK2R w KQkq -",
    EVAL: -24,
    MOVES: "e1h1 e7e6 h2h3 g4f5 e2b5 f5g6 f3e5 a8c8 b5c6 b7c6",
    DEPTH: 25,
  },
  {
    FEN: "2b5/8/3k4/8/3K4/Q7/8/8 b - -",
    EVAL: "M9",
    MOVES: "d6d7 a3c5 c8b7 d4e5 b7g2 c5d6 d7c8 d6e7 c8b8 e5d6",
    DEPTH: 66,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/8/3p4/2PPn3/4B3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: -5,
    MOVES: "e7e5 b1c3 d8a5 e3d2 e4c3 d2c3 f8b4 c3b4 a5b4 d1d2",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P1B2/4PN2/PPPN1PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 c2c3 f8d6 f4d6 d8d6 f1e2 b7b6 e1h1 e8h8 d1c2",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/2NP1N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: -20,
    MOVES: "f8e7 f1e2 e8h8 e1h1 a7a6 c1f4 c7c5 f3e5 e7d6 f4g3",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 33,
    MOVES: "c3e4 c8f5 e4g3 f5g6 g1f3 b8d7 h2h4 h7h6 f1d3 g6d3",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/8/3pp3/2PPn3/4B3/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: -4,
    MOVES: "b1c3 d8a5 e3d2 e4c3 d2c3 f8b4 c3b4 a5b4 d1d2 b8c6",
    DEPTH: 36,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/3bpn2/2pp4/3P4/2P1PNB1/PP1N1PPP/R2QKB1R b KQ -",
    EVAL: 3,
    MOVES: "d8c7 d4c5 c7c5 c3c4 b8c6 c4d5 d6g3 h2g3 e6d5 a2a3",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk2r/pp1p2pp/4pn2/2p2p2/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R w KQkq -",
    EVAL: 36,
    MOVES: "a2a3 b4a5 c1d2 b7b6 e2e3 e8h8 f1e2 d7d6 e1h1 c8b7",
    DEPTH: 36,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 18,
    MOVES: "e4g3 f5g6 g1f3 b8d7 c1f4 e7e6 h2h4 h7h6 f1d3 g6d3",
    DEPTH: 53,
  },
  {
    FEN: "r2qkb1r/pp2pppp/2n2n2/3p4/6b1/1P1P1N2/PBP1BPPP/RN1QK2R b KQkq -",
    EVAL: -62,
    MOVES: "e7e6 h2h3 g4h5 e1h1 f8d6 d3d4 e8h8 f3e5 h5e2 d1e2",
    DEPTH: 23,
  },
  {
    FEN: "rnb1kb1r/ppQp1ppp/5n2/8/4q3/8/PPPPBPPP/RNB1K1NR b KQkq -",
    EVAL: 54,
    MOVES: "b8c6 d2d3 e4g2 e2f3 g2g6 b1c3 f6h5 c1f4 f8e7 g1e2",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2B4n/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 49,
    MOVES: "d7c6 d2d3 d8f6 h2h3 h6g8 e1h1 g8e7 b1c3 h7h6 c1e3",
    DEPTH: 23,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 35,
    MOVES: "f5g6 g1f3 b8d7 h2h4 h7h6 f1d3 g6d3 d1d3 e7e6 c1f4",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/pp1npppp/2p2n2/8/3P4/5NN1/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 20,
    MOVES: "e7e6 a2a4 f8d6 f1d3 e8h8 e1h1 b7b6 a4a5 b6a5 g3e4",
    DEPTH: 40,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 26,
    MOVES: "g1e2 g8f6 c2c3 e7e5 f2f4 e5f4 e2f4 b8d7 f1d3 f8d6",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 c1e3 c7c5 a1c1 d8a5",
    DEPTH: 52,
  },
  {
    FEN: "r4rk1/pp2ppbp/3p1np1/q7/3BP3/1PN2P2/PP1Q2PP/2KR3R b - -",
    EVAL: 140,
    MOVES: "h7h5 c1b1 f8c8 h2h3 c8c6 g2g4 a8c8 a2a3 a7a6 d2d3",
    DEPTH: 35,
  },
  {
    FEN: "r2qkb1r/pp3ppp/2n1pn2/3p4/6b1/1P1P1N2/PBP1BPPP/RN1QK2R w KQkq -",
    EVAL: -40,
    MOVES: "e1h1 f8d6 b1d2 e8h8 a2a3 a7a5 h2h3 g4h5 c2c4 a8c8",
    DEPTH: 23,
  },
  {
    FEN: "r2qkbnr/pp3ppp/2b1p3/2ppP3/5P2/5N2/PPPP2PP/RNBQK2R w KQkq -",
    EVAL: -62,
    MOVES: "d2d4 g8h6 b1c3 h6f5 c3e2 c6a4 d1d3 d8b6 c2c3 f8e7",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 19,
    MOVES: "f8g7 d1b3 d5c4 b3c4 e8h8 e2e4 a7a6 c4b3 b7b5 f1e2",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P1B2/4PN2/PPPN1PPP/R2QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "c2c3 f8d6 f4d6 d8d6 f1e2 e8h8 e1h1 f8d8 a1c1 b7b6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2npp3/8/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 45,
    MOVES: "g1f3 g8f6 d2d4 f8e7 a2a3 e8h8 c4a2 a7a6 e1h1 e6e5",
    DEPTH: 25,
  },
  {
    FEN: "rn1qkbnr/pbpppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 75,
    MOVES: "f1d3 e7e6 g1f3 c7c5 c2c3 g8f6 d1e2 d7d5 e4e5 f6d7",
    DEPTH: 50,
  },
  {
    FEN: "rk3N1r/ppp1bq1p/2n5/6B1/3p2Q1/8/PPP2PPP/R5K1 w - -",
    EVAL: "M4",
    MOVES: "f8d7 b8c8 d7b6 c8b8 g4c8 h8c8 b6d7",
    DEPTH: 121,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR w KQkq -",
    EVAL: 36,
    MOVES: "g1f3 f8e7 f4e5 d6e5 f1e2 b8d7 e1h1 c7c6 d2d3 d7c5",
    DEPTH: 50,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/2p1pnb1/8/3P4/3B1NN1/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 19,
    MOVES: "e1h1 f8e7 d3g6 h7g6 d1e2 b8d7 c1f4 e8h8 f1d1 f8e8",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/2b5/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 21,
    MOVES: "f1d3 e8h8 c3a4 c5b6 e1h1 f8e8 d1f3 d7d5 a4b6 c7b6",
    DEPTH: 39,
  },
  {
    FEN: "r1b2rk1/1pq1bppp/p2ppn2/8/3BPP2/2N3Q1/PPP1B1PP/R4RK1 b - -",
    EVAL: 21,
    MOVES: "b7b5 a2a3 c8b7 g1h1 b7c6 a1d1 a8c8 e2f3 f8d8 h2h3",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 27,
    MOVES: "g8f6 b1c3 f8b4 d1d3 d7d5 e4d5 e6d5 f1e2 b8c6 d4c6",
    DEPTH: 47,
  },
  {
    FEN: "r2qkbnr/pp3ppp/2b1p3/2ppP3/3P1P2/5N2/PPP3PP/RNBQK2R b KQkq -",
    EVAL: -70,
    MOVES: "g8e7 b1c3 e7f5 c3e2 c6b5 c2c3 a8c8 a2a4 b5c4 e1f2",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/1P1p4/8/8/PBPPPPPP/RN1QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 a7a6 a2a4 c7c6 e2e3 c8d7 b1a3 f8e7 f1e2 e8h8",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 22,
    MOVES: "d1b3 d5c4 b3c4 e8h8 e2e4 a7a6 c4b3 b7b5 f1e2 c7c5",
    DEPTH: 48,
  },
  {
    FEN: "r1bq1rk1/pp1nbppp/2n1p3/2ppP3/3P1P2/2N1BN2/PPPQ2PP/2KR1B1R b - -",
    EVAL: -200,
    MOVES: "c5c4 f4f5 b7b5 f5f6 g7f6 e3h6 f6e5 d4e5 d7e5 c1b1",
    DEPTH: 34,
  },
  {
    FEN: "rnbqk2r/ppppnpb1/4p1pp/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 98,
    MOVES: "c1e3 d7d5 f1d3 c7c5 c4d5 e6d5 d4c5 e8h8 e3f4 g7c3",
    DEPTH: 26,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/1P1p4/P7/8/1BPPPPPP/RN1QKBNR w KQkq -",
    EVAL: 1,
    MOVES: "e2e3 e8h8 g1f3 a7a6 c2c4 c7c6 b1c3 d8c7 d1c2 a6b5",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/6P1/PPP2P1P/RNBQKB1R b KQkq -",
    EVAL: -12,
    MOVES: "f8g7 d4e2 b7b5 f1g2 a8b8 e1h1 h7h5 h2h3 d7d6 a2a3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/2b1P3/8/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: -26,
    MOVES: "d8e7 d1e2 f6d5 c3e4 c5d4 f2f4 f7f5 e5f6 d5f6 e4f6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3P4/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 10,
    MOVES: "c6d5 d2d4 b8c6 c2c3 a7a6 f1d3 c8g4 c1f4 e7e6 b1d2",
    DEPTH: 55,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP1B2/4PN2/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: -59,
    MOVES: "c5d4 e3d4 d5c4 f4e3 f8d6 d2c4 e8h8 a2a3 b7b6 f1d3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 5,
    MOVES: "e8h8 c4d5 f6d5 f1c4 d5b6 c4b3 c7c5 e1h1 c5d4 e3d4",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "c5d4 f3d4 e7e6 e2e3 b8c6 f1e2 b7b6 e1h1 c8b7 b1c3",
    DEPTH: 44,
  },
  {
    FEN: "r2qkb1r/pp2pppp/2n2n2/3p4/6b1/1P3N2/PBPPBPPP/RN1Q1RK1 b kq -",
    EVAL: -22,
    MOVES: "e7e6 h2h3 g4h5 d2d4 a8c8 f3e5 h5e2 d1e2 d8b6 e5c6",
    DEPTH: 23,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/3p4/4p3/2B1n3/2P2N2/PP1P1PPP/RNBQR1K1 b kq -",
    EVAL: -96,
    MOVES: "e4f6 c4d3 c8g4 d3c2 e8h8 h2h3 g4h5 d2d4 b8c6 g2g4",
    DEPTH: 24,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/3q4/8/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 64,
    MOVES: "g1f3 g8f6 d2d4 e7e6 f1c4 f8e7 e1h1 a7a6 a2a4 b8d7",
    DEPTH: 51,
  },
  {
    FEN: "2rq1r1k/pp3R1p/1b1p4/3p4/4P3/3B1P2/Pn2NP1P/R6K w - -",
    EVAL: -2232,
    MOVES: "f7g7 h8g7 d3c4 c8c4 h1g2 d8h4 a1g1 h4f2 g2h3 f2g1",
    DEPTH: 29,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p4/3P2b1/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 56,
    MOVES: "h2h3 g4f3 d1f3 b8c6 c2c3 e7e6 f1d3 f8d6 f3e2 d8c7",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/4p3/4PP2/2N2N2/PPPP2PP/R1BQKB1R b KQkq -",
    EVAL: 34,
    MOVES: "b8c6 d2d4 e5d4 f3d4 g7g6 f1b5 c8d7 d4c6 b7c6 b5d3",
    DEPTH: 47,
  },
  {
    FEN: "5rk1/3Q1p2/6p1/P5r1/R1q5/7B/5R1P/7K b - -",
    EVAL: 627,
    MOVES: "c4c5 f2f1 g5d5 d7b7 c5b5 b7b5 d5b5 a5a6 g8g7 h3g2",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/3p4/2Pp1B2/4PN2/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: -39,
    MOVES: "e3d4 d5c4 d2c4 f8b4 f4d2 b4d2 d1d2 e8h8 a1c1 b7b6",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk2r/ppppnpb1/4p1pp/8/2PPPB2/2N2N2/PP3PPP/R2QKB1R b KQkq -",
    EVAL: 63,
    MOVES: "d7d5 e4e5 b7b6 a1c1 c8b7 c4d5 e7d5 c3d5 b7d5 f1b5",
    DEPTH: 25,
  },
  {
    FEN: "8/8/3k4/8/8/4K3/8/4R3 w - -",
    EVAL: "M11",
    MOVES: "e3d4 d6c6 e1e6 c6d7 d4d5 d7c7 d5c5 c7d7 e6e1 d7c7",
    DEPTH: 245,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/3p4/2PN1B2/4P3/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: -77,
    MOVES: "c6d4 e3d4 d5c4 d2c4 f8b4 f4d2 b4e7 f1e2 e8h8 d2e3",
    DEPTH: 25,
  },
  {
    FEN: "rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 65,
    MOVES: "b1c3 d5a5 g1f3 g8f6 d2d4 c7c6 c1d2 c8f5 f1c4 b8d7",
    DEPTH: 46,
  },
  {
    FEN: "rnb1kbnr/1pqp1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -",
    EVAL: 68,
    MOVES: "a2a3 g8f6 b1c3 b8c6 f1e2 f8c5 c1e3 d7d6 e1h1 e8h8",
    DEPTH: 41,
  },
  {
    FEN: "r4rk1/pp1n1ppp/2pqpn2/3p4/2PP4/2NQPN2/PP3PPP/3R1RK1 b - -",
    EVAL: 0,
    MOVES: "d6c7 f3d2 f8d8 e3e4 d5e4 c3e4 a8b8 h2h3 h7h6 f1e1",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 29,
    MOVES: "d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 c7c5 a1b1 e8h8 f1e2",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk2r/ppppnppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQ1RK1 w kq -",
    EVAL: 5,
    MOVES: "c3d4 d7d5 e4d5 e7d5 c1a3 c8e6 c4b5 a5b4 b5c6 b7c6",
    DEPTH: 52,
  },
  {
    FEN: "8/8/8/2k5/8/4K3/8/3R4 w - -",
    EVAL: "M11",
    MOVES: "e3e4 c5c6 d1d5 c6c7 e4e5 c7c6 e5e6 c6c7 d5d6 c7c8",
    DEPTH: 99,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2np1n2/4p3/4PP2/2N2N2/PPPP2PP/R1BQKB1R w KQkq -",
    EVAL: 29,
    MOVES: "f1b5 e5f4 d2d4 a7a6 b5c6 b7c6 c1f4 a6a5 d1d3 f8e7",
    DEPTH: 47,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: 50,
    MOVES: "a2a3 b8c6 f1e2 f8c5 c1e3 d7d6 e1h1 e8h8 d1d2 c5d4",
    DEPTH: 49,
  },
  {
    FEN: "r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -",
    EVAL: 104,
    MOVES: "d4d5 c6e5 f3e5 d6e5 d1b3 e7e6 c1e3 d8e7 a1d1 f6g4",
    DEPTH: 39,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3pn2/8/2PNP3/P1N5/1P3PPP/R1BQKB1R b KQkq -",
    EVAL: 40,
    MOVES: "f8d6 c1e3 b8c6 f1e2 e8h8 h2h3 f8d8 e1h1 d6h2 g1h1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqk2r/p4ppp/2pb1n2/n3p1N1/8/3B4/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 47,
    MOVES: "e1h1 f6d5 g5e4 d6c7 f1e1 f7f5 e4c3 e8h8 d3f1 c8e6",
    DEPTH: 36,
  },
  {
    FEN: "8/8/2k5/8/3K4/8/8/4R3 w - -",
    EVAL: "M10",
    MOVES: "e1e6 c6d7 d4d5 d7c7 d5c5 c7d7 e6e2 d7c7 e2e7 c7d8",
    DEPTH: 241,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p4/3P2b1/2N2N2/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: -20,
    MOVES: "e7e6 h2h3 g4f3 d1f3 b8c6 c1e3 d8b6 e1a1 g8f6 f3g3",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/6P1/PPP2P1P/RNBQKB1R w KQkq -",
    EVAL: -15,
    MOVES: "d4e2 b7b5 f1g2 g8f6 e1h1 e8h8 c2c3 c8b7 f1e1 c6e5",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/ppp2p1p/3p4/8/3PP1pN/5pP1/PPP4P/RNBQKB1R w KQkq -",
    EVAL: -70,
    MOVES: "c1f4 f8g7 b1c3 b8c6 f1b5 g8e7 d1d2 a7a6 b5c6 b7c6",
    DEPTH: 39,
  },
  {
    FEN: "rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR b KQkq -",
    EVAL: 81,
    MOVES: "e7e6 e2e4 e6d5 f1a6 b8a6 e4e5 f6e4 d1d5 e4c3 b2c3",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/3p1n2/4p3/2B5/2P2N2/PP1P1PPP/RNBQR1K1 w kq -",
    EVAL: -82,
    MOVES: "c4d3 b8c6 d3c2 e8h8 d2d4 c8g4 d4d5 c6b8 c3c4 g4h5",
    DEPTH: 23,
  },
  {
    FEN: "8/3k4/4R3/8/3K4/8/8/8 w - -",
    EVAL: "M9",
    MOVES: "d4d5 d7c7 d5c5 c7d7 e6e4 d7c7 e4e7 c7d8 c5d6 d8c8",
    DEPTH: 99,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2B5/2b1p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -16,
    MOVES: "d7c6 d2d3 g8e7 c1e3 d8d6 b1c3 e7g6 h2h3 c5e3 f2e3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk2r/p1pp1ppp/2p2n2/2b5/2B1P3/2N5/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 16,
    MOVES: "d7d6 e1h1 e8h8 d1d3 c5b6 c1g5 d8e7 g1h1 e7e5 f2f4",
    DEPTH: 37,
  },
  {
    FEN: "rnb1kb1r/1pqp1ppp/p3p3/8/2PNn3/P1N5/1P3PPP/R1BQKB1R w KQkq -",
    EVAL: 89,
    MOVES: "c3e4 c7e5 f1d3 e5d4 e1h1 f7f5 c1e3 d4b2 e4g3 b2f6",
    DEPTH: 59,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP1B1PPP/R2QKB1R b KQ -",
    EVAL: 0,
    MOVES: "c7c5 d4c5 b8a6 c4d5 a6c5 f1c4 b7b6 a1c1 c8b7 b2b4",
    DEPTH: 43,
  },
  {
    FEN: "r5k1/ppp2p1p/6pB/4n2n/3bPp1q/2NB3P/PPP3PK/R2Q1R2 b - -",
    EVAL: -506,
    MOVES: "f4f3 f1f3 e5g4 h2h1 h5g3 f3g3 g4f2 h1h2 f2d1 c3e2",
    DEPTH: 61,
  },
  {
    FEN: "rnb1kb1r/ppp1pppp/3q1n2/8/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 60,
    MOVES: "g1f3 c7c6 f3e5 b8d7 f2f4 a7a6 f1e2 e7e6 e2f3 f8e7",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/4P3/6P1/PPP1NP1P/RNBQKB1R b KQkq -",
    EVAL: -21,
    MOVES: "b7b5 f1g2 a7a5 b1c3 b5b4 c3d5 c8a6 e1h1 e7e6 d5e3",
    DEPTH: 41,
  },
  {
    FEN: "8/8/1k6/8/3K4/8/4R3/8 w - -",
    EVAL: "M9",
    MOVES: "d4d5 b6c7 d5c5 c7d7 e2e3 d7c7 e3e7 c7d8 c5d6 d8c8",
    DEPTH: 99,
  },
  {
    FEN: "R7/6k1/P7/8/8/5K2/8/r7 b - -",
    EVAL: 0,
    MOVES: "a1f1 f3e4 f1f6 e4d4 f6d6 d4c4 d6f6 a8a7 g7g6 c4b4",
    DEPTH: 78,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2pp4/2PP4/2N1PN2/PP1B1PPP/R2QKB1R w KQ -",
    EVAL: 0,
    MOVES: "d4c5 b8a6 c4d5 a6c5 f1c4 b7b6 b2b4 c5e4 a1c1 c8b7",
    DEPTH: 51,
  },
  {
    FEN: "rnb1kb1r/1pp1pppp/p2q1n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 79,
    MOVES: "g2g3 e7e6 f1g2 f8e7 c1f4 d6d8 e1h1 e8h8 a2a3 f6d5",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 32,
    MOVES: "b8c6 d4c6 b7c6 e4e5 f6d5 c3e4 d8c7 f2f4 c7b6 c2c4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4PP2/2N2N2/PPPP2PP/R1BQK2R b KQkq -",
    EVAL: 32,
    MOVES: "e5f4 d2d4 a7a6 b5e2 c6e7 c1f4 e7g6 d1d2 f8e7 e2d3",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/p1pp1ppp/1p2p3/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 101,
    MOVES: "g1f3 c8b7 f1d3 d7d5 e4e5 b7a6 d3a6 b8a6 c2c4 a6b8",
    DEPTH: 49,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/5np1/2Pp4/2P5/2N1PN2/PP1B1PPP/R2QKB1R b KQ -",
    EVAL: 0,
    MOVES: "b8a6 c4d5 a6c5 f1c4 b7b6 a1c1 c8b7 b2b4 c5e4 e1h1",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk2r/pp1p1ppp/4pn2/4P3/1b1N4/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 40,
    MOVES: "f6d5 c1d2 d5c3 b2c3 b4e7 d1g4 e8f8 f1d3 d7d6 e5d6",
    DEPTH: 45,
  },
  {
    FEN: "R7/6k1/P7/8/8/5K2/8/5r2 w - -",
    EVAL: 97,
    MOVES: "f3e4 f1f6 e4d4 f6c6 a8a7 g7g6 d4d5 c6f6 a7a8 g6g7",
    DEPTH: 64,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p4/3p4/1P2PN2/P1P2PPP/RNBQKB1R w KQkq -",
    EVAL: -1,
    MOVES: "f1b5 c8d7 b5d7 d8d7 e3d4 e7e6 e1h1 b8c6 c1b2 f8e7",
    DEPTH: 36,
  },
  {
    FEN: "rn1qkbnr/pbpp1ppp/1p2p3/8/3PP3/3B1N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 98,
    MOVES: "d7d5 e4d5 d8d5 e1h1 f8d6 b1c3 d5h5 c3e4 b7e4 d3e4",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/pp1pppbp/2n2np1/8/4P3/6P1/PPP1NP1P/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1g2 b7b5 e1h1 e8h8 c2c3 c8b7 f1e1 a7a6 e2d4 e7e6",
    DEPTH: 25,
  },
  {
    FEN: "r1bq1rk1/p2nbppp/2n1pP2/1p1pP3/2pP4/2N1BN2/PPPQ2PP/2KR1B1R b - -",
    EVAL: -191,
    MOVES: "g7f6 c1b1 f6e5 d4e5 b5b4 c3e2 a7a5 e3h6 c6e5 e2f4",
    DEPTH: 32,
  },
  {
    FEN: "r4rk1/p3ppbp/3pbnp1/qp6/3BP3/1BN2P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 28,
    MOVES: "b5b4 c3d5 e6d5 e4d5 a5b5 d2d3 b5d3 d1d3 f8c8 h1e1",
    DEPTH: 39,
  },
  {
    FEN: "r3k1nr/ppp2ppp/2p5/2b5/4P1Q1/8/PPPP1qPP/RNB1K2R w KQkq -",
    EVAL: -95,
    MOVES: "e1d1 g8f6 g4f3 f2h4 d2d3 f6g4 h1e1 g4h2 f3f5 h4g4",
    DEPTH: 27,
  },
  {
    FEN: "2rq1r1k/pp4Rp/1b1p1p2/3p4/2B1P3/5P2/PB2NP1P/R6K w - -",
    EVAL: -743,
    MOVES: "c4d5 h8g7 a1g1 g7h8 e2d4 b6d4 b2d4 d8d7 g1g3 d7a4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/8/4P3/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 21,
    MOVES: "d2d4 e5d4 e3d4 d7d5 g1f3 b8c6 f1e2 g8f6 e1h1 f8e7",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/2np1np1/8/4P3/6P1/PPP1NPBP/RNBQK2R w KQkq -",
    EVAL: 7,
    MOVES: "a2a4 a8b8 b1c3 e8h8 e1h1 a7a6 c3d5 f6g4 h2h3 g4e5",
    DEPTH: 27,
  },
  {
    FEN: "r1bq1rk1/pp1nppbp/2pp1npB/8/3PP3/2N5/PPPQ1PPP/2KR1BNR w - -",
    EVAL: 150,
    MOVES: "f2f3 e7e5 h2h4 g7h6 d2h6 e5d4 d1d4 f6h5 g1h3 d8f6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/3p4/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 22,
    MOVES: "b8c6 c2c4 g8f6 b1c3 c8g4 c1e3 e7e6 c4c5 f8e7 f1b5",
    DEPTH: 49,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n2np1/q7/2B1P3/1NN1B3/PPP2PPP/R2QK2R b KQkq -",
    EVAL: -135,
    MOVES: "a5b4 c4d3 f6e4 d3e4 g7c3 b2c3 b4e4 e1h1 e8h8 f1e1",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/8/PPPN1PPP/RNBQKB1R b KQkq -",
    EVAL: -241,
    MOVES: "d8d4 b1c3 g8f6 d2e4 d4d1 e1d1 c8f5 f1d3 f5g6 a2a4",
    DEPTH: 28,
  },
  {
    FEN: "1rbq1rk1/p4ppp/2nPpn2/3p1P2/2pP4/4BN2/PPPQ2PP/2KR1B1R w - -",
    EVAL: -416,
    MOVES: "d2c3 d8d6 f3d2 e6f5 g2g3 b8b7 e3f4 d6d8 f1g2 f6e4",
    DEPTH: 33,
  },
  {
    FEN: "rnb1kb1r/2p1pppp/p2q1n2/1p6/2BP4/2N2N2/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: 17,
    MOVES: "c4d3 c8b7 d1e2 e7e6 e1h1 f8e7 c3e4 b7e4 d3e4 f6e4",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e5d4 d1d4 b8c6 d4e3 f8e7 f1g2 g8f6 g1f3 e8h8 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "3rR1k1/pb1r1pp1/1pn2q1p/3B4/6Q1/P4NP1/1P3PP1/3R2K1 b - -",
    EVAL: -63,
    MOVES: "d8e8 g4d7 e8e7 d7g4 f6b2 g4a4 b2f6 d1c1 c6d4 a4d4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/ppp3pp/5p2/3pP3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 66,
    MOVES: "d5e4 f3d4 f8c5 c1e3 c5d4 e3d4 b8c6 f1b5 c8d7 e5e6",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3pp3/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -62,
    MOVES: "e5e4 f3d2 c7c6 c2c4 f7f5 d1a4 g8f6 c4d5 f6d5 a4b3",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/8/4P3/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 10,
    MOVES: "g1f3 g8f6 d2d4 c7c5 f1e2 b8c6 e1h1 c5d4 e3d4 c8g4",
    DEPTH: 46,
  },
  {
    FEN: "r4rk1/p3ppbp/3pbnp1/q7/1p1BP3/1BN2P2/PPPQ2PP/1K1R3R w - -",
    EVAL: 28,
    MOVES: "c3d5 e6d5 e4d5 a5b5 d2d3 b5d3 d1d3 f8c8 h1e1 c8c7",
    DEPTH: 43,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2BPP3/2N2N2/PPP2PPP/R1BQK2R w KQ -",
    EVAL: 30,
    MOVES: "c4b3 b8c6 h2h3 c6a5 e1h1 a5b3 a2b3 b7b6 e4e5 f6d7",
    DEPTH: 44,
  },
  {
    FEN: "r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R b KQ -",
    EVAL: 72,
    MOVES: "a7a6 e1h1 d7d6 h2h3 c6d4 e3d4 c8d7 d1c2 a8c8 b2b3",
    DEPTH: 33,
  },
  {
    FEN: "rn1qkb1r/pb1n1ppp/1p2p3/2ppP3/3P4/2PB1N2/PP2QPPP/RNB1K2R w KQkq -",
    EVAL: 101,
    MOVES: "c1e3 b8c6 h2h4 f8e7 b1a3 a7a6 f3g5 b6b5 f2f4 c5d4",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/4pn2/3p4/2Pn1B2/4P3/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: -60,
    MOVES: "e3d4 d5c4 d2c4 f8b4 f4d2 b4e7 d2c3 e8h8 f1e2 b7b6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: -8,
    MOVES: "e2e4 e7e6 g1f3 d7d5 f1b5 b8d7 e4d5 e6d5 d1e2 f8e7",
    DEPTH: 43,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/5n2/2b1n3/2PPP3/2N5/PP3PPP/R1BQKB1R b KQkq -",
    EVAL: 11,
    MOVES: "c5b4 d4e5 f6e4 d1d4 e4c3 b2c3 b4e7 f1e2 e8h8 e1h1",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 12,
    MOVES: "c6d5 c1f4 d8b6 b1d2 b8c6 d2b3 f6h5 f4e3 e7e6 f1d3",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/8/2BQP3/8/PPP2PPP/RNB1K1NR b KQkq -",
    EVAL: -67,
    MOVES: "f8b4 c2c3 b8c6 d4d3 c6e5 d3e2 b4c5 g1f3 e5c4 e2c4",
    DEPTH: 45,
  },
  {
    FEN: "r1bq1bk1/p4p1p/2n1p3/1p1pn3/2p5/2N2N2/PPPQ2PP/2KR1B1R w - -",
    EVAL: -355,
    MOVES: "f3e5 c6e5 c1b1 f8g7 f1e2 c8d7 e2f3 a8c8 h1f1 d8c7",
    DEPTH: 34,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -",
    EVAL: -25,
    MOVES: "b8c6 g1f3 g8f6 b1c3 f8b4 c3d5 b4c5 f1c4 d7d6 d1e2",
    DEPTH: 45,
  },
  {
    FEN: "2rq1rk1/1p1bppbp/p1np1np1/8/2P1P3/2N1BP2/PPN1B1PP/R2Q1RK1 w - -",
    EVAL: 53,
    MOVES: "a1c1 c6e5 c4c5 d7e6 c5d6 e7d6 c2d4 b7b5 d4e6 f7e6",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/pp1ppppp/8/2p5/5P2/5N2/PPPPP1PP/RNBQKB1R b KQkq -",
    EVAL: -24,
    MOVES: "d7d5 e2e3 g8f6 f1b5 b8d7 e1h1 e7e6 b2b3 f8e7 c1b2",
    DEPTH: 38,
  },
  {
    FEN: "rnbqk1nr/pp2bppp/2pp4/8/3NPB2/2N5/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 63,
    MOVES: "g8f6 h2h3 d8b6 d4b3 b6c7 g2g4 e8h8 g4g5 f6d7 d1d2",
    DEPTH: 27,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/8/3p4/3P2b1/5N1P/PPP2PP1/RNBQKB1R b KQkq -",
    EVAL: 63,
    MOVES: "g4f3 d1f3 e7e6 f1d3 g8f6 c2c3 h7h6 b1d2 f8d6 e1h1",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/2pp4/5P2/2P2N2/PP1PP1PP/RNBQKB1R w KQkq -",
    EVAL: -41,
    MOVES: "g2g3 e7e6 d2d3 g8f6 f1g2 f8e7 a2a4 e8h8 e1h1 b7b6",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 39,
    MOVES: "e2e3 b7b5 a2a4 e7e6 a4b5 c6b5 b2b3 g8f6 b3c4 b5c4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/3p2n1/4P3/2BP4/2N5/PPP2PPP/R1BQK2R w KQkq -",
    EVAL: -189,
    MOVES: "d1f3 c8e6 f3b7 g8e7 c3b5 a8c8 d4d5 g6e5 d5e6 e5c4",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/2pPP3/5N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: -13,
    MOVES: "b7b5 a2a4 e7e6 a4b5 c6b5 b2b3 g8f6 b3c4 f6e4 c4c5",
    DEPTH: 51,
  },
  {
    FEN: "r1b2r1k/pp2Npbp/2p3p1/q1n5/8/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: -23,
    MOVES: "e7c8 f8c8 e1h1 c8e8 e3f2 a8d8 d1c2 c5b3 c2b3 d8d7",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkbnr/p3pppp/2p5/1p6/P1pPP3/5N2/1P3PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 a4b5 c6b5 b2b3 g8f6 b3c4 b5c4 f1c4 f6e4 e1h1",
    DEPTH: 43,
  },
  {
    FEN: "r4rk1/p3ppbp/3pbnp1/q7/1p1BP3/1B3P2/PPPQN1PP/1K1R3R b - -",
    EVAL: 0,
    MOVES: "e6b3 c2b3 e7e5 d4f2 f8d8 f2h4 d6d5 h4f6 g7f6 e4d5",
    DEPTH: 34,
  },
  {
    FEN: "2r2r1k/pp3pbp/2p3p1/q1n5/8/1BP1BP2/PP4PP/R2QK2R w KQ -",
    EVAL: -13,
    MOVES: "e1h1 f8e8 e3f2 c8d8 d1c2 a5b5 f1d1 h8g8 d1d8 e8d8",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/4P3/3P1NP1/PPPN1P1P/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1g2 f8e7 e1h1 e8h8 c2c3 b7b5 e4d5 e6d5 d3d4 c5d4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R w KQkq -",
    EVAL: 16,
    MOVES: "f1g2 e8h8 h2h3 b8c6 c1e3 c6d4 e3d4 e7e5 d4e3 b7b5",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: -21,
    MOVES: "d2d3 d7d5 e4d5 f6d5 g1f3 b8c6 f1e2 f8c5 e1h1 c8f5",
    DEPTH: 33,
  },
  {
    FEN: "r1bq1rk1/ppppbppp/2n2n2/4p3/2B1P3/2N2N2/PPPP1PPP/R1BQ1RK1 w - -",
    EVAL: 34,
    MOVES: "d2d3 d7d6 a2a3 h7h6 h2h3 f8e8 c1e3 e7f8 f3h2 c8e6",
    DEPTH: 42,
  },
  {
    FEN: "rnb2rk1/ppq2ppp/3bpn2/2pp4/3P4/2P1PNB1/PP1N1PPP/R2QKB1R w KQ -",
    EVAL: 0,
    MOVES: "a1c1 d6g3 h2g3 b8d7 d1c2 h7h6 c3c4 c7a5 c4d5 c5d4",
    DEPTH: 46,
  },
  {
    FEN: "2r2rk1/6Bp/pp3p2/2q5/2P2P2/3pP1RP/P5P1/2R3K1 w - -",
    EVAL: -1177,
    MOVES: "g1h2 g8f7 g7f8 f7f8 c1d1 c5c4 e3e4 c8d8 g3f3 c4c2",
    DEPTH: 27,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/q7/1p1BP3/1b3P2/PPPQN1PP/1K1R3R w - -",
    EVAL: 0,
    MOVES: "c2b3 e7e5 d4f2 f8d8 a2a3 a5a6 a3a4 d6d5 f2h4 d5e4",
    DEPTH: 35,
  },
  {
    FEN: "rnb1kb1r/ppppqppp/5n2/1B2P3/3Q4/8/PPP2PPP/RNB1K1NR b KQkq -",
    EVAL: -37,
    MOVES: "b8c6 b5c6 d7c6 b1c3 e7d7 d4d7 f6d7 c1f4 f8c5 g1e2",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "c1b2 f8b4 b2e5 g8f6 g1f3 d7d5 e2e3 c8e6 c2c4 d5c4",
    DEPTH: 52,
  },
  {
    FEN: "2rq1rk1/pp4Bp/1b1p4/3p1p2/2n1P3/3B1P2/P3NP1P/R5RK w - -",
    EVAL: -3,
    MOVES: "g7f6 g8f7 f6d8 f8d8 e4d5 f7f6 f3f4 c4b2 d3b1 d8g8",
    DEPTH: 57,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n1p3/8/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 14,
    MOVES: "d7d5 e4d5 e6d5 c4d5 d8d5 b1c3 d5h5 d2d4 f8d6 f1e2",
    DEPTH: 29,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 b kq -",
    EVAL: -44,
    MOVES: "d7d6 d2d4 c8g4 c4d5 g4f3 d1f3 d8d7 b1d2 g8f6 d2c4",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/5N2/PPPNPPPP/R1BQKB1R w KQkq -",
    EVAL: 1,
    MOVES: "b2b3 b7b6 c1b2 c8b7 g2g3 c7c5 f1g2 b8c6 e2e3 a8c8",
    DEPTH: 39,
  },
  {
    FEN: "rnbqk2r/pp2bppp/2pp1n2/8/3NPB2/2N5/PPPQ1PPP/R3KB1R b KQkq -",
    EVAL: 54,
    MOVES: "e8h8 e1a1 d6d5 e4d5 f6d5 c3d5 d8d5 d4b3 d5d2 b3d2",
    DEPTH: 31,
  },
  {
    FEN: "2k1rb1r/ppp3pp/2n2q2/3B1b2/5P2/2P1BQ2/PP1N1P1P/2KR3R b - -",
    EVAL: "M-2",
    MOVES: "f6c3 b2c3 f8a3",
    DEPTH: 120,
  },
  {
    FEN: "r1bqkbnr/pppp1ppp/2n1p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -",
    EVAL: 74,
    MOVES: "d2d4 g8f6 e2e3 g7g6 f1e2 f8g7 e1h1 e8h8 a2a3 f8e8",
    DEPTH: 39,
  },
  {
    FEN: "r1bq1rk1/p2nbppp/1pn1p3/2ppP3/3P1P2/2N1BN2/PPPQB1PP/R4RK1 b - -",
    EVAL: 17,
    MOVES: "c8b7 c3d1 c5d4 f3d4 d7c5 d1c3 a8c8 c3b5 c5e4 d2e1",
    DEPTH: 36,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 28,
    MOVES: "e2e3 c8f5 b1c3 e7e6 f3h4 f5g6 d1b3 d8b6 h4g6 h7g6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -",
    EVAL: 24,
    MOVES: "g8f6 d2d4 d7d5 b1c3 f8e7 c1f4 e8h8 e2e3 b8d7 a2a3",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkb1r/pp2pp1p/2n2np1/3p4/3P4/1P3N2/PBP2PPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "f1b5 f8g7 b5c6 b7c6 e1h1 c8f5 f1e1 e8h8 b1c3 f5g4",
    DEPTH: 36,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -",
    EVAL: -32,
    MOVES: "d2d4 d7d6 d1b3 d8d7 e1h1 a5b6 c4b5 a7a6 b5a4 b6a7",
    DEPTH: 54,
  },
  {
    FEN: "2r2r1k/7p/pp3p2/2q3R1/2P2P2/3pP2P/P5P1/B1R3K1 w - -",
    EVAL: -90,
    MOVES: "g5c5 c8c5 a1d4 c5a5 c1d1 a5a2 d1d3 h8g7 d4b6 h7h5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 b kq -",
    EVAL: 16,
    MOVES: "e8h8 d2d4 d5c4 d1c2 a7a6 a2a4 c8d7 c2c4 d7c6 c1g5",
    DEPTH: 42,
  },
  {
    FEN: "2r2r1k/pp3pbp/2p3p1/q1n5/8/1BP1BP2/PP4PP/R2Q1RK1 b - -",
    EVAL: -7,
    MOVES: "f8e8 e3f2 c8d8 d1c2 a5b5 f1d1 h8g8 d1d8 e8d8 a1d1",
    DEPTH: 45,
  },
  {
    FEN: "4k3/8/8/8/8/8/8/4KBN1 w - -",
    EVAL: "M29",
    MOVES: "e1d2 e8d7 f1c4 d7d6 d2e3 d6e5 g1e2 e5f5 e2f4 f5e5",
    DEPTH: 101,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/8/1b2Pp2/2N2N2/PPPP2PP/R1BQKB1R w KQkq -",
    EVAL: 19,
    MOVES: "c3d5 g8e7 c2c3 b4d6 f1c4 c6a5 c4e2 e8h8 e1h1 a5c6",
    DEPTH: 32,
  },
  {
    FEN: "2r1r2k/pp3pbp/2p3p1/q1n5/8/1BP1BP2/PP4PP/R2Q1RK1 w - -",
    EVAL: 0,
    MOVES: "e3f2 c8d8 d1c2 c5b3 c2b3 d8d7 a1d1 e8d8 d1d7 d8d7",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPPN1PPP/R1BQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 b2b3 c5d4 e3d4 f8b4 c1b2 f6e4 f1d3 b8c6 e1h1",
    DEPTH: 38,
  },
  {
    FEN: "rnbq1rk1/pp2bppp/2pp1n2/8/3NPB2/2N5/PPPQ1PPP/R3KB1R w KQ -",
    EVAL: 100,
    MOVES: "e1a1 d6d5 e4d5 f6d5 c3d5 d8d5 c1b1 e7f6 f1e2 f8d8",
    DEPTH: 42,
  },
  {
    FEN: "8/4k3/8/1B6/8/8/8/4K1N1 w - -",
    EVAL: 6450,
    MOVES: "e1e2 e7d8 e2e3 d8c7 b5c4 c7d6 e3e4 d6c5 c4b3 c5b4",
    DEPTH: 71,
  },
  {
    FEN: "5r1k/7p/pp3p2/2r5/2P2P2/3pP2P/P5P1/B1R3K1 w - -",
    EVAL: -94,
    MOVES: "a1d4 c5a5 c1d1 a5a2 d1d3 h8g7 d4b6 h7h5 f4f5 f8g8",
    DEPTH: 43,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/7P/PPP2PP1/RNBQKBNR b KQkq -",
    EVAL: -39,
    MOVES: "c5d4 g1f3 f8c5 c2c3 d4c3 b1c3 b8c6 a2a3 c5e7 c1f4",
    DEPTH: 25,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "g7g6 e2e3 f8g7 f1e2 e8h8 e1h1 d5c4 e2c4 c6c5 d4c5",
    DEPTH: 47,
  },
  {
    FEN: "2r1r2k/pp3pbp/2p3p1/q1n5/8/1BP2P2/PP3BPP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "c8d8 d1c2 c5b3 c2b3 d8d7 a1d1 e8d8 d1d7 d8d7 f1d1",
    DEPTH: 59,
  },
  {
    FEN: "r1bqk1nr/p2pppbp/2p3p1/8/2B1P3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 5,
    MOVES: "b1c3 d7d6 h2h3 g8f6 e1h1 e8h8 c1g5 c8b7 d1c1 d8b6",
    DEPTH: 41,
  },
  {
    FEN: "8/8/3k4/1B6/8/5N2/8/4K3 w - -",
    EVAL: 6625,
    MOVES: "b5a6 d6c5 f3h4 c5d5 a6b7 d5c5 h4g6 c5d4 e1f2 d4c5",
    DEPTH: 68,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR b KQkq -",
    EVAL: -6,
    MOVES: "c7c5 g1f3 h7h6 g5f6 d8f6 a1c1 d7d6 e2e3 e6e5 d4c5",
    DEPTH: 57,
  },
  {
    FEN: "r1bq4/pppp1kpp/2n2n2/2b1p3/4P3/8/PPPP1PPP/RNBQ1RK1 w - -",
    EVAL: -245,
    MOVES: "d2d3 d7d5 c1e3 c5e3 f2e3 d5e4 b1d2 e4d3 d2e4 f7g8",
    DEPTH: 36,
  },
  {
    FEN: "3rr2k/pp3pbp/2p3p1/q1n5/8/1BP2P2/PP3BPP/R2Q1RK1 w - -",
    EVAL: -12,
    MOVES: "d1c2 a5b5 f1d1 h8g8 d1d8 e8d8 a1d1 g7f6 f2c5 b5c5",
    DEPTH: 41,
  },
  {
    FEN: "r4rk1/p3ppbp/3pbnp1/q2N4/1p1BP3/1B3P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 50,
    MOVES: "e6d5 e4d5 a5b5 d2d3 b5d3 d1d3 f8c8 h1e1 c8c7 c2c3",
    DEPTH: 44,
  },
  {
    FEN: "r1bq1rk1/p2nb1pp/1pn1p3/2ppPp2/3P1P2/4BN2/PPPQB1PP/R2N1RK1 b - -",
    EVAL: -8,
    MOVES: "c5d4 f3d4 c6d4 e3d4 d7c5 d1f2 a7a5 g1h1 c8a6 e2a6",
    DEPTH: 29,
  },
  {
    FEN: "6RK/8/3k4/8/2p5/8/8/8 w - -",
    EVAL: "M20",
    MOVES: "g8g5 c4c3 h8g7 c3c2 g5g1 d6c5 g1c1 c5d4 c1c2 d4e5",
    DEPTH: 74,
  },
  {
    FEN: "8/8/8/1Bk5/8/5N2/3K4/8 w - -",
    EVAL: 8038,
    MOVES: "b5a6 c5d5 d2e3 d5e6 e3e4 e6e7 a6f1 e7f6 f1c4 f6g6",
    DEPTH: 61,
  },
  {
    FEN: "rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R w KQkq -",
    EVAL: 27,
    MOVES: "e2e3 c8f5 c2b3 d8b6 c4d5 b6b3 a2b3 f6d5 c1d2 e7e6",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/pppq1ppp/8/8/2B1p3/1Q6/PP1P1PPP/RNB1K1NR w KQkq -",
    EVAL: -68,
    MOVES: "d2d3 b8c6 d3e4 g8f6 b1c3 a7a6 c4d5 c6e5 g1f3 e5f3",
    DEPTH: 50,
  },
  {
    FEN: "8/8/8/3k4/8/3B1N2/3K4/8 w - -",
    EVAL: 8048,
    MOVES: "d2e3 d5c5 f3d2 c5d6 d3c4 d6e7 d2f3 e7e8 e3f4 e8f8",
    DEPTH: 61,
  },
  {
    FEN: "3rr2k/pp3pbp/2p3p1/q1n5/8/1BP2P2/PPQ2BPP/R4RK1 b - -",
    EVAL: 0,
    MOVES: "c5b3 c2b3 d8d7 a1d1 e8d8 d1d7 d8d7 f1d1 d7d1 b3d1",
    DEPTH: 48,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p5/2b3B1/4n3/3P4/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: -436,
    MOVES: "g5e3 c5e3 f2e3 d8h4 g2g3 e4g3 h2g3 h4h1 b1d2 h7h5",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppppp1pp/8/5p2/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 224,
    MOVES: "e4f5 g8f6 d2d4 d7d5 f1d3 c7c5 c2c3 c5c4 d3c2 e7e6",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -",
    EVAL: -3,
    MOVES: "c1f4 e7e6 c3b5 b8a6 e2e3 f8e7 g1f3 e8h8 f1e2 c7c6",
    DEPTH: 57,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1c3 c7c6 d1c2 g7g6 c1g5 f8e7 e2e3 c8f5 f1d3 f5d3",
    DEPTH: 58,
  },
  {
    FEN: "8/8/3k4/8/8/2KB1N2/8/8 w - -",
    EVAL: "M31",
    MOVES: "d3c4 d6e7 c3d3 e7f6 d3e4 f6g6 e4e5 g6g7 e5f5 g7h8",
    DEPTH: 74,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: -24,
    MOVES: "c5d4 d1d4 b8c6 d4f4 f7f6 f1b5 c8d7 b5c6 b7c6 e1h1",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/5n2/3p4/3P1B2/5N2/PP2PPPP/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f6e4 b1c3 f8b4 a1c1 b8c6 f3d2 g7g5 f4e3 e4c3 b2c3",
    DEPTH: 46,
  },
  {
    FEN: "r1bq1rk1/pp2ppbp/2np1np1/8/4P3/2N3P1/PPP1NPBP/R1BQK2R w KQ -",
    EVAL: 0,
    MOVES: "a2a4 a8b8 e1h1 a7a6 c3d5 b7b5 a4b5 a6b5 c1e3 b5b4",
    DEPTH: 53,
  },
  {
    FEN: "r2qrbk1/5ppp/pn1p4/np2P1P1/3p4/5N2/PPB2PP1/R1BQR1K1 w - -",
    EVAL: 597,
    MOVES: "c2h7 g8h7 g5g6 f7g6 f3g5 h7g8 d1f3 d8g5 c1g5 d6e5",
    DEPTH: 60,
  },
  {
    FEN: "rnb1k2r/pppp1pp1/4pq1p/8/1bPP4/2N5/PP2PPPP/R2QKBNR w KQkq -",
    EVAL: -13,
    MOVES: "a1c1 c7c5 e2e3 c5d4 d1d4 f6d4 e3d4 e8h8 g1f3 f8d8",
    DEPTH: 49,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3b1n2/3p4/3P1B2/5N2/PP2PPPP/RN1QKB1R w KQkq -",
    EVAL: 28,
    MOVES: "f4d6 d8d6 e2e3 c8f5 f1e2 e8h8 e1h1 c7c6 f3h4 f5e4",
    DEPTH: 37,
  },
  {
    FEN: "8/8/4k3/8/3K4/3B1N2/8/8 w - -",
    EVAL: "M27",
    MOVES: "d3e4 e6f6 d4d5 f6e7 d5e5 e7f7 e4c2 f7g7 f3g5 g7g8",
    DEPTH: 83,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/5n2/3pB3/1b6/4PN2/P1PPBPPP/RN1QK2R b KQ -",
    EVAL: -90,
    MOVES: "c7c5 e1h1 b8c6 e5b2 b4a5 b2a3 f6e4 a3b2 d5d4 d2d3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n2n2/1B1pP3/3p4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f6e4 f3d4 c8d7 b5c6 b7c6 e1h1 f8c5 f2f3 e4g5 f3f4",
    DEPTH: 52,
  },
  {
    FEN: "8/8/5k2/8/3KB3/5N2/8/8 w - -",
    EVAL: "M26",
    MOVES: "d4d5 f6e7 f3e5 e7f6 e5g6 f6g5 d5e5 g5h5 g6f4 h5h6",
    DEPTH: 67,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1 b - -",
    EVAL: 0,
    MOVES: "d5d4 e2e3 c7c5 f3e5 d8c7 f2f4 b8d7 e5d3 e6e5 c1b2",
    DEPTH: 50,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/3B4/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 99,
    MOVES: "g5e4 f6e4 d3e4 f8d6 d2d3 e8h8 g2g4 a8b8 b1c3 a5b7",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/4P3/3p4/5N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "g2g3 c8d7 d2d3 b8c6 b1d2 d8a5 d1e2 c6b4 f3d4 b4c6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 3,
    MOVES: "e2e3 g8f6 g1f3 f8d6 f4g3 c7c5 d4c5 d6c5 b1d2 c5e7",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1pP3/3pn3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "f3d4 c8d7 b5c6 b7c6 e1h1 f8c5 f2f3 e4g5 f3f4 g5e4",
    DEPTH: 47,
  },
  {
    FEN: "2r2rk1/6pp/pp3p2/2q2P2/2P5/3pP1RP/P5P1/B1R3K1 w - -",
    EVAL: -721,
    MOVES: "g1h2 c5f5 c1d1 h7h5 g3f3 f5e4 a1c3 c8c4 c3d2 c4c2",
    DEPTH: 26,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/q2b4/1p1BP3/1B3P2/PPPQ2PP/1K1R3R w - -",
    EVAL: 25,
    MOVES: "e4d5 a5b5 d2d3 b5d3 d1d3 f6d7 b3a4 d7c5 d4c5 d6c5",
    DEPTH: 44,
  },
  {
    FEN: "8/8/4k3/8/4B3/4KN2/8/8 w - -",
    EVAL: 7380,
    MOVES: "e4g6 e6d5 g6f7 d5d6 f7c4 d6e7 f3g1 e7f6 e3f4 f6g6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2p1P3/3P4/5N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 f1c4 g8e7 d2d3 a7a6 e1h1 b8d7 c4b3 e7g6 f1e1",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/ppp2ppp/2n1p3/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 80,
    MOVES: "g1f3 g8f6 f1d3 c6b4 e4f6 g7f6 d3e2 b7b6 c2c3 b4d5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/4p3/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: 12,
    MOVES: "g8f6 e2e3 c7c5 b1d2 b8c6 c2c3 f8d6 f4g3 e8h8 f1d3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/1B1pP3/3Nn3/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c8d7 b5c6 b7c6 e1h1 f8c5 f2f3 e4g5 f3f4 g5e4 c1e3",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/ppppnpb1/2n3pp/1B1N2B1/3pP3/5N2/PPP2PPP/R2QK2R w KQkq -",
    EVAL: 80,
    MOVES: "g5f6 g7f6 d5f6 e8f8 d1d2 d7d5 e4e5 a7a6 b5c6 e7c6",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2n5/1BbpP3/3Nn3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 13,
    MOVES: "c1e3 c8d7 b5c6 d7c6 e1h1 d8e7 c2c3 e8h8 f1e1 c5b6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 3,
    MOVES: "d7d5 g1f3 c7c5 f1e2 b8c6 c2c4 d5c4 e2c4 e7e6 e1h1",
    DEPTH: 51,
  },
  {
    FEN: "rnbq1rk1/pp3ppp/5n2/b1ppB3/8/2P1PN2/P2PBPPP/RN1QK2R w KQ -",
    EVAL: -66,
    MOVES: "d1c2 b8c6 e5f4 f8e8 e1h1 h7h6 h2h3 a5c7 f4c7 d8c7",
    DEPTH: 25,
  },
  {
    FEN: "rnbqk2r/ppp2pbp/3p1np1/4p3/2B1P3/5Q1P/PPPP1PP1/RNB1K1NR w KQkq -",
    EVAL: -76,
    MOVES: "d2d3 c7c6 a2a3 e8h8 c1g5 h7h6 g5h4 d8b6 c4b3 b8d7",
    DEPTH: 26,
  },
  {
    FEN: "rnbqkbnr/ppp2pp1/4p2p/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 25,
    MOVES: "g8f6 c2c4 f8d6 f4d6 c7d6 b1c3 d5c4 f1c4 a7a6 e1h1",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk1nr/ppp2pp1/3bp2p/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 37,
    MOVES: "f4d6 c7d6 c2c4 d5c4 f1c4 a7a6 b1c3 g8f6 e1h1 b7b5",
    DEPTH: 28,
  },
  {
    FEN: "8/8/8/4k1N1/4B3/4K3/8/8 w - -",
    EVAL: 20000,
    MOVES: "g5f7 e5e6 f7g5 e6e5 g5h7 e5e6 e3d4 e6e7 d4e5 e7f7",
    DEPTH: 43,
  },
  {
    FEN: "r1b3nr/ppp2kqp/1bnp4/4p1B1/3PP3/2P5/PP3PPP/RN3RK1 w - -",
    EVAL: -1962,
    MOVES: "h2h4 e5d4 c3d4 b6d4 b1c3 d4c3 b2c3 g7e5 f2f3 h7h6",
    DEPTH: 27,
  },
  {
    FEN: "rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/2N2N2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: -7,
    MOVES: "g7g6 e4e5 f6e7 f1d3 f8g7 d1e2 d6e5 d4e5 b8d7 e1h1",
    DEPTH: 45,
  },
  {
    FEN: "rnbqk1nr/ppp2pp1/3bp2p/3p4/3P4/4PNB1/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 13,
    MOVES: "g8f6 b1d2 c7c5 d4c5 d6c5 f1d3 e8h8 e1h1 b7b6 e3e4",
    DEPTH: 26,
  },
  {
    FEN: "rn2kb1r/ppp1pppp/5n2/q4b2/2BP4/2N5/PPP2PPP/R1BQK1NR w KQkq -",
    EVAL: 69,
    MOVES: "g1f3 b8d7 c1d2 c7c6 d1e2 a5c7 e1a1 b7b5 f3h4 f5g6",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p2n1p/n3p3/4N3/3B4/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 97,
    MOVES: "f6e4 d3e4 f8d6 d2d3 e8h8 e1h1 f7f5 e4f3 d8c7 g2g3",
    DEPTH: 32,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/3bpn2/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 13,
    MOVES: "f4d6 d8d6 c2c4 c7c5 d4c5 d6c5 b1d2 d5c4 f1c4 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "rnbqk1nr/ppp2pp1/4p2p/3p4/3P4/4PNb1/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 28,
    MOVES: "h2g3 g8f6 c2c4 e8h8 b1c3 b8d7 a1c1 c7c6 f1d3 d5c4",
    DEPTH: 26,
  },
  {
    FEN: "r4rk1/p3ppbp/3pb1p1/q2n4/1p1BP3/1B3P2/PPPQ2PP/1K1R3R w - -",
    EVAL: 158,
    MOVES: "d4g7 d5c3 b2c3 g8g7 c3b4 a5a3 d2d4 g7g8 b3e6 f7e6",
    DEPTH: 33,
  },
  {
    FEN: "8/8/2N1k3/8/3KB3/8/8/8 w - -",
    EVAL: "M27",
    MOVES: "d4c5 e6f6 c5d5 f6f7 d5e5 f7g7 e5e6 g7h8 e6f6 h8g8",
    DEPTH: 69,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/8/8/5NP1/PPPPPPBP/RNBQK2R b KQkq -",
    EVAL: 37,
    MOVES: "d7d5 d2d4 f8e7 e1h1 e8h8 c2c4 d5c4 d1c2 a7a6 a2a4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqk2r/ppp2pp1/4pn1p/3p4/3P4/3BPNP1/PPP2PP1/RN1QK2R b KQkq -",
    EVAL: 0,
    MOVES: "b8d7 c2c4 d5c4 d3c4 c7c5 b1c3 e8h8 e3e4 d8b6 e1h1",
    DEPTH: 31,
  },
  {
    FEN: "3N4/3k4/8/8/3KB3/8/8/8 w - -",
    EVAL: "M28",
    MOVES: "d8f7 d7e6 e4d5 e6f5 d4e3 f5g6 e3e4 g6f6 e4f4 f6g6",
    DEPTH: 74,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p4p/n2np3/4N3/3B4/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 127,
    MOVES: "e1h1 f8e7 e4g3 e8h8 d3f5 e7g5 b1c3 c8f5 g3f5 d8c8",
    DEPTH: 44,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3bpn2/3pN3/3P4/4P1B1/PPP2PPP/RN1QKB1R b KQ -",
    EVAL: -26,
    MOVES: "c7c5 c2c3 g7g6 b1d2 f6h5 f1d3 a7a5 a2a3 b8c6 e5c6",
    DEPTH: 26,
  },
  {
    FEN: "8/5N2/4k3/8/3KB3/8/8/8 w - -",
    EVAL: 7868,
    MOVES: "f7e5 e6f6 e5d3 f6g7 e4f3 g7g8 d4e5 g8g7 e5f5 g7f7",
    DEPTH: 58,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 48,
    MOVES: "b8c6 f1e2 d7d5 e1h1 d5e4 d3e4 f8d6 b1d2 g8f6 c2c3",
    DEPTH: 27,
  },
  {
    FEN: "8/8/5k2/6N1/3KB3/8/8/8 w - -",
    EVAL: "M30",
    MOVES: "g5f3 f6e6 e4d3 e6f6 d4d5 f6e7 d5e5 e7f7 d3c4 f7g7",
    DEPTH: 63,
  },
  {
    FEN: "rnb2rk1/pp2ppbp/1q3np1/8/2PN4/6P1/PB2PPBP/RN1Q1RK1 w - -",
    EVAL: 29,
    MOVES: "d1c1 f8d8 e2e3 b8a6 b2a3 e7e5 c4c5 b6a5 d4b3 a5c7",
    DEPTH: 39,
  },
  {
    FEN: "2rq1r1k/pp4Rp/1b1p4/3P1p2/2n5/3B1P2/PB2NP1P/R6K w - -",
    EVAL: 0,
    MOVES: "d3c4 c8c4 a1g1 b6d4 b2d4 c4d4 e2d4 d8e8 d4e6 f8f7",
    DEPTH: 48,
  },
  {
    FEN: "8/8/4k3/8/3KB3/5N2/8/8 w - -",
    EVAL: "M28",
    MOVES: "f3d2 e6f6 d4d5 f6f7 d5e5 f7g7 e5f5 g7h7 d2f3 h7h8",
    DEPTH: 71,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p4/3P2b1/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "h2h3 g4f3 d1f3 e7e6 c1e3 d8b6 e1a1 g8f6 g2g4 f8b4",
    DEPTH: 47,
  },
  {
    FEN: "3rr2k/pp3pbp/2p3p1/1qn5/8/1BP2P2/PPQ2BPP/R4RK1 w - -",
    EVAL: -9,
    MOVES: "f1e1 h8g8 f2c5 b5c5 c2f2 c5f2 g1f2 d8d2 f2g3 e8e1",
    DEPTH: 40,
  },
  {
    FEN: "r1bq1rk1/pp1nppbp/2pp1npB/8/3PP3/2N2P2/PPPQ2PP/2KR1BNR b - -",
    EVAL: 109,
    MOVES: "e7e5 h2h4 g7h6 d2h6 e5d4 d1d4 d7e5 g2g4 f6e8 f1e2",
    DEPTH: 43,
  },
  {
    FEN: "6KR/8/pk6/8/8/8/8/8 b - -",
    EVAL: 0,
    MOVES: "b6c5 h8h4 a6a5 g8g7 c5b5 g7h6 a5a4 h6h5 a4a3 h5g4",
    DEPTH: 79,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/4p3/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -",
    EVAL: -12,
    MOVES: "d7d5 f1b5 c8d7 b5d7 d8d7 d2d3 b8c6 g1f3 f8e7 e1h1",
    DEPTH: 46,
  },
  {
    FEN: "8/8/8/3B1k2/3K4/5N2/8/8 w - -",
    EVAL: "M29",
    MOVES: "d4e3 f5g6 e3e4 g6f6 e4f4 f6g6 f4e5 g6g7 e5f5 g7h7",
    DEPTH: 74,
  },
  {
    FEN: "r4rk1/pp1bppbp/1qn2np1/8/2PN4/6P1/PB1NPPBP/R1Q2RK1 w - -",
    EVAL: 0,
    MOVES: "d4c2 a8c8 a1b1 b6a6 b2c3 f8d8 c1b2 d7e6 c2e3 a6b6",
    DEPTH: 38,
  },
  {
    FEN: "8/8/5k2/3B4/8/4KN2/8/8 w - -",
    EVAL: "M27",
    MOVES: "e3f4 f6g6 f4e5 g6g7 e5f5 g7h6 f5f6 h6h7 f3h4 h7h8",
    DEPTH: 67,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/2B1PP2/5N2/PPPP2PP/RNBQ1RK1 w kq -",
    EVAL: 3,
    MOVES: "d2d3 e7e6 c2c3 g8e7 a2a4 e8h8 d1e2 d6d5 c4a2 b7b6",
    DEPTH: 43,
  },
  {
    FEN: "rnbq1rk1/pppp1ppp/5n2/4B3/1b6/4PN2/P1PP1PPP/RN1QKB1R b KQ -",
    EVAL: -35,
    MOVES: "d7d5 c2c4 c7c5 c4d5 f6d5 a2a3 b4a5 d1c2 b8c6 e5b2",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -",
    EVAL: 13,
    MOVES: "g8f6 e4e5 f6d5 f1c4 e7e6 e1h1 d7d6 d2d4 f8e7 c4d3",
    DEPTH: 60,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/4p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 18,
    MOVES: "d7d5 e4d5 e6d5 d2d4 g8f6 f1d3 f8d6 d1e2 d6e7 e1h1",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN2P2/PPP3PP/R1BQKB1R b KQkq -",
    EVAL: 17,
    MOVES: "c8e6 c1e3 h7h5 d1d2 b8d7 c3d5 e6d5 e4d5 g7g6 e1a1",
    DEPTH: 53,
  },
  {
    FEN: "r4rk1/p3ppBp/3pb1p1/q2n4/1p2P3/1B3P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 134,
    MOVES: "d5c3 b2c3 g8g7 c3b4 a5a3 d2c3 g7g8 d1d3 f8c8 c3d2",
    DEPTH: 34,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p4/3P2b1/2N2N1P/PPP2PP1/R1BQKB1R b KQkq -",
    EVAL: -24,
    MOVES: "g4f3 d1f3 e7e6 c1e3 d8b6 e1a1 g8f6 f1d3 a8c8 h3h4",
    DEPTH: 47,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n5/3p3b/3P4/2N2N1P/PPP2PP1/R1BQKB1R w KQkq -",
    EVAL: 121,
    MOVES: "g2g4 h5g6 h3h4 h7h5 f3e5 c6e5 d4e5 a7a6 d1d5 d8d5",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p4p/n2np3/4N3/3B4/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 118,
    MOVES: "g7g6 f1e1 f7f5 e4c3 f8g7 d3f1 e8h8 d2d3 d8c7 b2b3",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/2B1PP2/2N2N2/PPPP2PP/R1BQ1RK1 b kq -",
    EVAL: -36,
    MOVES: "e7e6 d2d4 c5d4 c3b5 a7a6 b5d4 b7b5 c4b3 c6a5 e4e5",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/4p3/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 39,
    MOVES: "g1f3 b8c6 f1e2 d7d5 e4d5 d8d5 b1c3 d5d8 c1e3 f8b4",
    DEPTH: 29,
  },
  {
    FEN: "2r2rk1/6pp/pp3p2/2q5/2P2P2/3pP1RP/P4KP1/B1R5 w - -",
    EVAL: -826,
    MOVES: "g3f3 c5a3 c1d1 c8c4 f2g3 a3a2 f3f1 c4c2 f1g1 a2c4",
    DEPTH: 23,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/2B1P3/8/PB3PPP/RN1QK1NR b KQkq -",
    EVAL: -41,
    MOVES: "g8f6 e4e5 f8b4 b2c3 d8e7 d1e2 f6g8 g1f3 g8h6 e1h1",
    DEPTH: 60,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/8/2b1p3/2P1P1n1/2N2N2/PP1P1PPP/R1BQKB1R w KQkq -",
    EVAL: -21,
    MOVES: "d2d4 e5d4 c3a4 c5b4 c1d2 d8e7 f1e2 b4d2 d1d2 c7c5",
    DEPTH: 35,
  },
  {
    FEN: "rn1qkbnr/pp1Bpppp/8/3p4/8/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: -25,
    MOVES: "b8d7 d2d3 e7e6 c2c4 f8b4 b1d2 g8f6 a2a3 b4e7 b2b4",
    DEPTH: 47,
  },
  {
    FEN: "r4r2/p3ppkp/3pb1p1/q2n4/1p2P3/1B3P2/PPPQ2PP/1K1R3R w - -",
    EVAL: 202,
    MOVES: "e4d5 e6d7 h2h4 f7f6 h4h5 g6g5 h1e1 f8f7 e1e4 a8b8",
    DEPTH: 27,
  },
  {
    FEN: "rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPP1NPPP/R1BQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "c7c5 f2f4 f8e7 g1f3 b8c6 c2c3 e8h8 c1e3 f7f6 g2g3",
    DEPTH: 57,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P1B2/2P1PN2/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 21,
    MOVES: "c5d4 e3d4 f6h5 f4e3 f8d6 f1d3 h5f4 e3f4 d6f4 g2g3",
    DEPTH: 43,
  },
  {
    FEN: "r4r2/p3ppkp/3pb1p1/q2P4/1p6/1B3P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 321,
    MOVES: "e6d7 h2h4 h7h5 g2g4 f8h8 d1e1 a8e8 e1e4 f7f5 e4b4",
    DEPTH: 34,
  },
  {
    FEN: "r1bqk2r/p3bpp1/2p4p/n2np3/8/3B2N1/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: 137,
    MOVES: "e8h8 d3f5 e7g5 b1c3 c8f5 g3f5 d8f6 f5g3 f6e6 d2d3",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3P4/7n/3P1p2/5N2/PPP3PP/RNBQKB1R b KQkq -",
    EVAL: -89,
    MOVES: "f8d6 d1e2 d8e7 b1c3 b8c6 c1d2 e8h8 e1a1 h5f6 d1e1",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk1nr/pppp1ppp/4p3/1Bb5/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -",
    EVAL: 82,
    MOVES: "a7a6 b5e2 d7d5 d2d4 c5b6 b1d2 g8e7 e1h1 e8h8 b2b4",
    DEPTH: 22,
  },
  {
    FEN: "rn2kbnr/pp2pppp/4q3/3pN3/3P4/8/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: -80,
    MOVES: "f7f6 d1h5 g7g6 h5g4 e6g4 e5g4 e7e6 a2a4 h7h5 g4e3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk1nr/pp2bppp/2np4/4p3/2BPP3/5N2/PP3PPP/RNBQ1RK1 b kq -",
    EVAL: 82,
    MOVES: "e5d4 f3d4 g8f6 b1c3 e8h8 c1e3 h7h6 f2f3 a7a6 a1c1",
    DEPTH: 24,
  },
  {
    FEN: "r1bqk2r/pp3ppp/2nbpn2/2pp4/3P1B2/2P1PN2/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: 20,
    MOVES: "f4g3 e8h8 f1d3 a7a5 e1h1 b7b6 d1e2 c8b7 f1e1 c6e7",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/8/2b5/2PpP1n1/2N2N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: -30,
    MOVES: "c3a4 c5b4 c1d2 d8e7 f1e2 b4d2 d1d2 c7c5 f3d4 c5d4",
    DEPTH: 40,
  },
  {
    FEN: "8/3k4/8/8/8/5N2/8/4KB2 w - -",
    EVAL: 6177,
    MOVES: "e1d2 d7e6 d2e3 e6f6 f1d3 f6g7 e3f4 g7g8 d3b1 g8h8",
    DEPTH: 64,
  },
  {
    FEN: "r1bq1rk1/pp1nppbp/2pp1npB/8/3PP2P/2N5/PPPQ1PP1/2KR1BNR b - -",
    EVAL: 97,
    MOVES: "e7e5 f2f3 g7h6 d2h6 e5d4 d1d4 d7e5 g1h3 c8h3 h1h3",
    DEPTH: 31,
  },
  {
    FEN: "r1bqk1nr/pp2bppp/2np4/8/2BpP3/5N2/PP3PPP/RNBQ1RK1 w kq -",
    EVAL: 127,
    MOVES: "f3d4 g8f6 b1c3 e8h8 c1e3 a7a6 a1c1 c8d7 f2f3 f8e8",
    DEPTH: 28,
  },
  {
    FEN: "r1bqk2r/pp3ppp/2nbpn2/2pp4/3P4/2P1PNB1/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 10,
    MOVES: "e8h8 f1d3 g7g6 e1h1 b7b6 g3h4 c8b7 h2h3 d6e7 a2a4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkbnr/pppppppp/2n5/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -",
    EVAL: 50,
    MOVES: "d2d4 d7d5 e2e3 c8g4 f1b5 d8d7 b1d2 a7a6 b5e2 e7e6",
    DEPTH: 50,
  },
  {
    FEN: "2r2rk1/5ppp/pp6/2q5/2P2P2/3pP1RP/P5P1/B3R1K1 b - -",
    EVAL: -964,
    MOVES: "f7f6 e1d1 c5c4 e3e4 d3d2 a1c3 c4e4 d1d2 c8c3 g3c3",
    DEPTH: 23,
  },
  {
    FEN: "r2qkb1r/pp3ppp/2n1pn2/3p4/3P2b1/2N2N2/PPP1BPPP/R1BQ1RK1 w kq -",
    EVAL: -15,
    MOVES: "h2h3 g4f3 e2f3 f8d6 c3b5 d6e7 a2a4 a7a6 b5a3 h7h6",
    DEPTH: 41,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 67,
    MOVES: "e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 d6e5 f4e5 b8c6",
    DEPTH: 51,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/2B1PP2/3P1N2/PPP3PP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "e7e6 a2a4 g8e7 c2c3 e8h8 d1e2 d6d5 c4a2 b7b6 b1a3",
    DEPTH: 42,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -",
    EVAL: 11,
    MOVES: "b2b3 d5c4 b3c4 c6c5 c1b2 c5d4 f3d4 d8b6 d1c2 b8c6",
    DEPTH: 45,
  },
  {
    FEN: "8/4k3/8/8/2B5/5N2/8/4K3 w - -",
    EVAL: 8038,
    MOVES: "e1d2 e7f6 d2d3 f6f5 d3e3 f5g6 e3f4 g6f6 c4d5 f6g7",
    DEPTH: 65,
  },
  {
    FEN: "r2q1rk1/pbp3pp/1p1b4/3N1p2/2B5/P3PPn1/1P3P1P/2RQK2R w K -",
    EVAL: "M4",
    MOVES: "d5e7 g8h8 e7g6 h7g6 h2g3 d8h4 h1h4",
    DEPTH: 245,
  },
  {
    FEN: "r1bqk2r/pp3pp1/2nbpn1p/2pp4/3P4/2P1PNB1/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: 32,
    MOVES: "f3e5 d8c7 f2f4 c5c4 a2a4 b7b6 f1e2 c8b7 e1h1 e8h8",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 76,
    MOVES: "e4d5 e6d5 f1b5 b8c6 e1h1 g8f6 f1e1 c8e6 c1g5 f8e7",
    DEPTH: 59,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1 b - -",
    EVAL: 0,
    MOVES: "c8f5 b2b3 a7a5 c1b2 a5a4 f3h4 f5e6 h4f3",
    DEPTH: 42,
  },
  {
    FEN: "8/8/8/5k2/2B5/4KN2/8/8 w - -",
    EVAL: "M27",
    MOVES: "c4b3 f5f6 e3e4 f6g6 e4e5 g6g7 e5f5 g7h8 f5f6 h8h7",
    DEPTH: 80,
  },
  {
    FEN: "rn2kbnr/pp2p1pp/4qp2/3pN3/3P4/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -84,
    MOVES: "d1g4 e6g4 e5g4 e7e6 g4e3 b8c6 c2c3 h7h5 b1d2 h5h4",
    DEPTH: 29,
  },
  {
    FEN: "r1b1k2r/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPPQ1PPP/R3K2R b KQkq -",
    EVAL: -80,
    MOVES: "f6e4 d4c6 a5c3 d2c3 g7c3 b2c3 d7c6 f2f3 e4d6 c4b3",
    DEPTH: 41,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/8/8/NbPpP1n1/5N2/PP3PPP/R1BQKB1R w KQkq -",
    EVAL: -33,
    MOVES: "c1d2 d8e7 f1e2 b8c6 e1h1 g4e5 f3e5 e7e5 d2b4 c6b4",
    DEPTH: 32,
  },
  {
    FEN: "8/8/4k3/8/8/3BKN2/8/8 w - -",
    EVAL: "M27",
    MOVES: "e3e4 e6f6 d3c4 f6g6 e4e5 g6g7 e5f5 g7h8 f5f6 h8h7",
    DEPTH: 65,
  },
  {
    FEN: "r1bqk1nr/pp2ppbp/2np2p1/2p5/2B1PP2/2P2N2/PP1P2PP/RNBQ1RK1 b kq -",
    EVAL: -20,
    MOVES: "g8f6 e4e5 d6e5 f3e5 c6e5 f4e5 f6d5 d1f3 c8e6 d2d3",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P1B2/2P1PN2/PP3PPP/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f8d6 f1b5 b8c6 b5c6 b7c6 f4d6 d8d6 e1h1 e8h8 d1a4",
    DEPTH: 50,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 w - -",
    EVAL: 29,
    MOVES: "d2d4 d5c4 d1c2 a7a6 a2a4 c8d7 c2c4 d7c6 c1g5 b8d7",
    DEPTH: 47,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R w KQkq -",
    EVAL: 33,
    MOVES: "f1e2 f8c5 f3g3 c5d4 d2d3 g8e7 h3h4 b8d7 h4h5 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "r1bqk2r/pp2ppbp/2np1np1/2p5/2B1PP2/2P2N2/PP1P2PP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "e4e5 d6e5 f4e5 f6d5 d2d4 c5d4 c3d4 e8h8 b1c3 d5b6",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/3Pp1N1/2Bp4/2P5/PP3PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c6a5 c4b5 c8d7 d1e2 f8d6 b2b4 h7h6 g5f3 e8h8 b4a5",
    DEPTH: 38,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/3p1n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -",
    EVAL: 72,
    MOVES: "d2d4 e7e5 g1f3 b8d7 a2a4 f8e7 f1c4 c7c6 e1h1 h7h6",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/2pp4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 11,
    MOVES: "e2e3 c5d4 e3d4 b8c6 g1f3 c8f5 c2c3 e7e6 b1d2 h7h6",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2P2N2/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 17,
    MOVES: "f6h5 f4e3 f8d6 f3e5 h5f6 d2f3 d8c7 f1d3 d6e5 d4e5",
    DEPTH: 47,
  },
  {
    FEN: "r2qrbk1/5ppp/pn1pP3/np4P1/3p4/5N2/PPB2PP1/R1BQR1K1 b - -",
    EVAL: 281,
    MOVES: "g7g6 e6f7 g8f7 e1e8 d8e8 d1d4 a5c4 c2b3 e8c6 c1e3",
    DEPTH: 25,
  },
  {
    FEN: "r1bqk2r/pp3ppp/2nbpn2/3p4/3P1B2/2P2N2/PP1N1PPP/R2QKB1R w KQkq -",
    EVAL: 79,
    MOVES: "f4d6 d8d6 f1d3 e8h8 e1h1 e6e5 f3e5 c6e5 d4e5 d6e5",
    DEPTH: 52,
  },
  {
    FEN: "rnb1k2r/ppppqppp/8/8/NbPpP1n1/5N2/PP1B1PPP/R2QKB1R w KQkq -",
    EVAL: -69,
    MOVES: "f1e2 b8c6 e1h1 g4e5 f3e5 e7e5 d2b4 c6b4 a2a3 b4c6",
    DEPTH: 34,
  },
  {
    FEN: "r2qrbk1/5p1p/pn1pP1p1/np4P1/3p4/5N2/PPB2PP1/R1BQR1K1 w - -",
    EVAL: 181,
    MOVES: "e6f7 g8f7 e1e8 d8e8 d1d4 a5c4 c2b3 e8c6 d4f6 f7g8",
    DEPTH: 28,
  },
  {
    FEN: "r1bqk2r/pp3ppp/2nbpn2/3p4/3P4/2P2NB1/PP1N1PPP/R2QKB1R b KQkq -",
    EVAL: 33,
    MOVES: "e8h8 f1d3 f6h5 g3d6 d8d6 g2g3 f7f6 e1h1 e6e5 c3c4",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/5n2/3pp3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -",
    EVAL: 95,
    MOVES: "e4d5 f8d6 f1b5 c7c6 d5c6 b8c6 b5c6 b7c6 e1h1 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "8/8/5k2/8/4K3/3B1N2/8/8 w - -",
    EVAL: "M26",
    MOVES: "e4d5 f6e7 d5e5 e7f7 d3e4 f7g7 e5e6 g7h8 e6f6 h8g8",
    DEPTH: 73,
  },
  {
    FEN: "r2qrb2/5k1p/pn1p2p1/np4P1/3p4/5N2/PPB2PP1/R1BQR1K1 w - -",
    EVAL: 290,
    MOVES: "e1e8 d8e8 d1d4 a5c4 c2b3 d6d5 c1e3 f8g7 d4f4 f7g8",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pppppp1p/5np1/8/3P1B2/4P3/PPP2PPP/RN1QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "f8g7 g1f3 e8h8 f1e2 c7c5 e1h1 d8b6 b1c3 b6b2 c3b5",
    DEPTH: 49,
  },
  {
    FEN: "8/4k3/8/3K4/8/3B1N2/8/8 w - -",
    EVAL: "M26",
    MOVES: "d5e5 e7f7 f3g5 f7g7 d3e4 g7h6 g5f7 h6g7 e5e6 g7f8",
    DEPTH: 68,
  },
  {
    FEN: "8/5k2/8/4K3/8/3B1N2/8/8 w - -",
    EVAL: "M24",
    MOVES: "f3g5 f7g7 d3f5 g7g8 e5f6 g8h8 f5e4 h8g8 g5f7 g8f8",
    DEPTH: 70,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -",
    EVAL: 59,
    MOVES: "e2e3 g8f6 b1d2 c8f5 c2c4 e7e6 a2a3 f8d6 f4d6 c7d6",
    DEPTH: 48,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 13,
    MOVES: "d7d6 h2h3 c7c5 f1e2 b8c6 c2c3 d8b6 d1c1 e8h8 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "r3qb2/5k1p/pn1p2p1/np4P1/3p4/5N2/PPB2PP1/R1BQ2K1 w - -",
    EVAL: 240,
    MOVES: "d1d4 a5c4 c2b3 d6d5 b3c4 b6c4 a2a4 e8d7 a4b5 a6a5",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 c2c4 b8c6 d4e3 g7g6 f1e2 f8g7 b1c3 e8h8 e1h1",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/ppp1pppp/2n5/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -",
    EVAL: 40,
    MOVES: "c8f5 e2e3 e7e6 a2a3 g8f6 c2c4 f8d6 f4g3 e8h8 b1c3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/8/3QP3/5N2/PPP2PPP/RNB1KB1R w KQkq -",
    EVAL: 9,
    MOVES: "b1c3 b8c6 f1b5 c8d7 b5c6 d7c6 c1g5 e7e6 d4d3 f8e7",
    DEPTH: 54,
  },
  {
    FEN: "8/8/8/8/1k6/1pp5/8/1KR5 w - -",
    EVAL: "M23",
    MOVES: "c1g1 b4c4 g1g8 c4d3 g8b8 d3d2 b8d8 d2e2 b1c1 e2e3",
    DEPTH: 63,
  },
  {
    FEN: "8/6k1/8/4K1N1/8/3B4/8/8 w - -",
    EVAL: "M25",
    MOVES: "e5e6 g7h6 g5f7 h6g7 e6e7 g7g8 e7f6 g8f8 d3h7 f8e8",
    DEPTH: 69,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq -",
    EVAL: 48,
    MOVES: "d2d4 c5d4 c3d4 d6d5 e4d5 g8f6 f1b5 b8d7 g1f3 a7a6",
    DEPTH: 48,
  },
  {
    FEN: "r1bqkb1r/ppp1pppp/2n2n2/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R w KQkq -",
    EVAL: 55,
    MOVES: "e2e3 c8g4 c2c4 e7e6 a2a3 f8d6 f4g5 h7h6 g5h4 g7g5",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkbnr/pppppp1p/6p1/8/8/5P2/PPPPP1PP/RNBQKBNR w KQkq -",
    EVAL: -8,
    MOVES: "e2e4 f8g7 d2d4 d7d5 c2c3 d5e4 f3e4 g8f6 e4e5 f6d5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/ppp1pppp/2n2n2/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R b KQkq -",
    EVAL: 39,
    MOVES: "c8g4 c2c4 e7e6 a2a3 f8d6 f4g5 h7h6 g5h4 d6e7 h2h3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/2pp4/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 0,
    MOVES: "c2c3 g8f6 b1d2 e7e6 f1d3 f8e7 a2a4 e8h8 g1f3 f6h5",
    DEPTH: 50,
  },
  {
    FEN: "8/4n3/1p2p1k1/p1p1Pppp/P1P1p3/1PB1PbPP/3K1P2/5B2 b - -",
    EVAL: -532,
    MOVES: "h5h4 c3a1 g5g4 a1b2 h4g3 f2g3 f5f4 e3f4 g6h5 d2e1",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/8/3QP3/2N2N2/PPP2PPP/R1B1KB1R b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 d4e3 g7g6 f1e2 c6b4 e3d2 f8g7 a2a3 b4c6 h2h3",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN2P2/PPP3PP/R1BQKB1R w KQkq -",
    EVAL: 25,
    MOVES: "c1e3 h7h5 f1e2 b8d7 f3f4 d8c7 h2h3 f8e7 e1h1 b7b5",
    DEPTH: 48,
  },
  {
    FEN: "r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "g8f6 f1e2 a7a6 f2f4 d7d6 e1h1 e8h8 d4b3 e7e5 d1d2",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/3p1np1/8/2B1P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 37,
    MOVES: "f8g7 d2d4 e8h8 c4b3 c8g4 h2h3 g4f3 d1f3 b8c6 c1e3",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -",
    EVAL: 0,
    MOVES: "h2h3 b8c6 f1d3 d6d5 e4e5 f6d7 d3b5 d8b6 b5c6 b6c6",
    DEPTH: 49,
  },
  {
    FEN: "rnbqkbnr/ppp1pppp/8/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: -150,
    MOVES: "d5e4 f3g5 c8f5 g2g4 f5g6 b1c3 h7h5 g5e4 h5g4 d1g4",
    DEPTH: 47,
  },
  {
    FEN: "6k1/8/8/5KN1/8/3B4/8/8 w - -",
    EVAL: "M22",
    MOVES: "f5f6 g8f8 g5f7 f8g8 d3b1 g8f8 b1h7 f8e8 f7e5 e8d8",
    DEPTH: 62,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -",
    EVAL: -35,
    MOVES: "g1f3 e5f4 e4d5 g8f6 c2c4 c7c6 d2d4 f8b4 b1c3 c6d5",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n1pn2/3p4/3P1B2/4PN1P/PPP2PP1/RN1QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f8d6 f1e2 d6f4 e3f4 d8d6 d1c1 e8h8 e1h1 b7b6 c2c3",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkbnr/1p2pppp/p1n5/2pP4/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -",
    EVAL: 37,
    MOVES: "d8d5 c1e3 c5d4 c3d4 d5a5 b1c3 e7e6 a2a3 g8f6 f1d3",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/8/3QP3/2N2N2/PPP2PPP/R1B1KB1R w KQkq -",
    EVAL: 0,
    MOVES: "d4d3 g7g6 c1g5 f8g7 c3d5 e8h8 c2c3 d8a5 f1e2 c8e6",
    DEPTH: 41,
  },
  {
    FEN: "5k2/8/6K1/6N1/8/3B4/8/8 w - -",
    EVAL: "M23",
    MOVES: "g6f6 f8e8 d3f5 e8d8 f6e6 d8c7 e6d5 c7d8 g5h7 d8e7",
    DEPTH: 63,
  },
  {
    FEN: "r4r2/p2bppkp/3p2p1/q2P4/1p6/1B3P2/PPPQ2PP/1K2R2R b - -",
    EVAL: 157,
    MOVES: "a5c5 h2h4 h7h5 g2g4 a7a5 e1e7 a5a4 e7d7 a4b3 c2b3",
    DEPTH: 38,
  },
  {
    FEN: "rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R b KQkq -",
    EVAL: 24,
    MOVES: "h7h5 d1d2 b8d7 c3d5 e6d5 e4d5 g7g6 f1e2 f8g7 e1h1",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/pppp1pp1/2n2n1p/2b1p3/N1B1P3/3P1N2/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: -14,
    MOVES: "c5b4 c1d2 b4d2 d1d2 d7d6 a4c3 c8g4 f3g1 e8h8 g1e2",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n1pn2/3p4/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 47,
    MOVES: "c2c4 f6e4 f3d2 e4d2 d1d2 f8d6 f4d6 d8d6 b1c3 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3ppn2/2p5/4P3/2P2N2/PP1PBPPP/RNBQ1RK1 b kq -",
    EVAL: 25,
    MOVES: "c8d7 d2d3 f8e7 f1e1 e8h8 e2f1 b7b5 c1f4 d7c6 b1d2",
    DEPTH: 37,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: -21,
    MOVES: "c5d4 f3d4 c6d4 c1e3 d4c6 f2f4 d8e7 c4d5 e5f4 e3f4",
    DEPTH: 55,
  },
  {
    FEN: "1k4rr/pp2bp2/4p1q1/n2pP1pp/b1pP2P1/P1P1BB1P/1P1RQP1N/5RK1 b - -",
    EVAL: -251,
    MOVES: "h5g4 f3g4 e7d8 g1g2 a5c6 h2f3 a7a6 f1g1 b8a8 g2f1",
    DEPTH: 28,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2np1n2/1B6/3QP3/2N2N2/PPP2PPP/R1B1K2R b KQkq -",
    EVAL: 0,
    MOVES: "c8d7 b5c6 d7c6 c1g5 e7e6 e1a1 f8e7 d4d3 d8a5 g5h4",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2nppn2/2p5/4P3/2P2N2/PP1PBPPP/RNBQ1RK1 w kq -",
    EVAL: 40,
    MOVES: "d1c2 c8d7 d2d4 c5d4 c3d4 a8c8 b1c3 d6d5 c1g5 d5e4",
    DEPTH: 40,
  },
  {
    FEN: "rn2k1nr/pp1bqppp/8/1BbQ4/8/5N2/PPP2PPP/RNB1K2R w KQkq -",
    EVAL: 74,
    MOVES: "c1e3 c5e3 e1h1 g8f6 d5b7 e8h8 f2e3 e7e3 g1h1 d7b5",
    DEPTH: 25,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 4,
    MOVES: "c6d4 f3d4 e5d4 e4e5 d4c3 e5f6 d8f6 d2c3 f8c5 d1e2",
    DEPTH: 56,
  },
  {
    FEN: "rnbq1r1k/pppnbppp/4p3/3pP1B1/3P2QP/2N5/PPP2PP1/R3KBNR w KQ -",
    EVAL: 127,
    MOVES: "g4h5 c7c5 f1d3 g7g6 h5h6 c5d4 g1f3 f8g8 h4h5 g8g7",
    DEPTH: 39,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2nppn2/2p5/4P3/2PP1N2/PP2BPPP/RNBQ1RK1 b kq -",
    EVAL: -3,
    MOVES: "f8e7 a2a3 e8h8 f1e1 a7a6 e2f1 e6e5 b2b4 c8g4 h2h3",
    DEPTH: 28,
  },
  {
    FEN: "r2qkb1r/pp1bpppp/2np1n2/1B6/3QP3/2N2N2/PPP2PPP/R1B1K2R w KQkq -",
    EVAL: 0,
    MOVES: "d4d3 a7a6 b5c6 d7c6 e1h1 e7e6 c1f4 d8c7 f1d1 a8d8",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pppp1ppp/8/8/2B1P3/2p5/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: -56,
    MOVES: "c3b2 c1b2 b8c6 g1f3 f8b4 b1c3 g8f6 e1h1 b4c3 b2c3",
    DEPTH: 50,
  },
  {
    FEN: "r1bqk2r/pp2bppp/2nppn2/2p5/4P3/2PP1N2/PP2BPPP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "h2h3 e8h8 c1f4 e6e5 f4g3 h7h6 f1e1 c8e6 b1d2 a7a6",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3p4/2B1P3/2p5/PP3PPP/RNBQK1NR w KQkq -",
    EVAL: -21,
    MOVES: "c4d5 c3b2 c1b2 g8f6 d5f7 e8f7 d1d8 f8b4 d8d2 b4d2",
    DEPTH: 42,
  },
  {
    FEN: "r2qkb1r/pp1bpppp/2Bp1n2/8/3QP3/2N2N2/PPP2PPP/R1B1K2R b KQkq -",
    EVAL: 0,
    MOVES: "d7c6 c1g5 e7e6 e1a1 f8e7 d4d3 d8a5 h2h4 h7h6 g5d2",
    DEPTH: 33,
  },
  {
    FEN: "r1bqk2r/pp2bppp/2nppn2/2p5/4P3/2PPBN2/PP2BPPP/RN1Q1RK1 b kq -",
    EVAL: -16,
    MOVES: "d8c7 b1d2 d6d5 e3g5 e8h8 f1e1 f8d8 e2f1 e6e5 e4d5",
    DEPTH: 25,
  },
  {
    FEN: "r1bqk2r/pppp1pp1/2nb1n1p/4p3/N1B1P3/2PP1N2/PP3PPP/R1BQK2R w KQkq -",
    EVAL: 0,
    MOVES: "e1h1 e8h8 b2b4 a7a6 c4b3 b7b5 a4b2 a6a5 b4a5 c6a5",
    DEPTH: 27,
  },
  {
    FEN: "rnbqk1nr/ppp1bppp/3pp3/8/8/5NP1/PPPPPPBP/RNBQ1RK1 b kq -",
    EVAL: 76,
    MOVES: "g8f6 c2c4 e8h8 d2d4 d6d5 d1c2 a7a5 f1d1 b8d7 b1c3",
    DEPTH: 27,
  },
  {
    FEN: "r2qk2r/pppb1ppp/2nbpn2/3p4/3P1B2/2P1PN1P/PP3PP1/RN1QKB1R w KQkq -",
    EVAL: 38,
    MOVES: "b1d2 e8h8 f1d3 d7e8 d1e2 d8e7 f4g5 e6e5 e3e4 e5d4",
    DEPTH: 27,
  },
  {
    FEN: "r1bqkb1r/pp2pppp/2n2n2/2pp4/3P1B2/4PN2/PPP2PPP/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "d4c5 e7e6 c2c4 f8c5 c4d5 d8d5 b1c3 d5d1 a1d1 c5b4",
    DEPTH: 49,
  },
  {
    FEN: "r2qkb1r/pp2pppp/2bp1n2/8/3QP3/2N2N2/PPP2PPP/R1B1K2R w KQkq -",
    EVAL: -13,
    MOVES: "e1h1 e7e6 f1d1 f8e7 e4e5 c6f3 g2f3 d6e5 d4e5 d8c8",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3P4/2B5/2p5/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: -92,
    MOVES: "c3b2 c1b2 g8f6 g1f3 f8e7 e1h1 e8h8 b1d2 c8g4 d1c2",
    DEPTH: 46,
  },
  {
    FEN: "8/8/3k4/8/8/5N1B/8/4K3 w - -",
    EVAL: "M30",
    MOVES: "e1d2 d6e7 d2e3 e7f7 e3f4 f7g7 f4e5 g7g6 h3e6 g6g7",
    DEPTH: 85,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/8/4P2n/3P1p1b/5N2/PPP1Q1PP/RNB1KB1R w KQkq -",
    EVAL: 46,
    MOVES: "f3h4 d8h4 e2f2 h4f2 e1f2 d7d5 b1c3 c7c6 f1e2 g7g6",
    DEPTH: 49,
  },
  {
    FEN: "r2qk2r/pp1b1ppp/2nppn2/3p4/3P4/2P1PN1P/PP3PP1/RN1QKB1R w KQkq -",
    EVAL: -10,
    MOVES: "f1e2 a8c8 d1b3 e8h8 e1h1 e6e5 a2a4 c8c7 a4a5 a7a6",
    DEPTH: 25,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp2B1/3PP3/2N2N2/PPP2PPP/R2QKB1R b KQkq -",
    EVAL: 31,
    MOVES: "d5e4 c3e4 c5d4 f1b5 c8d7 d1d4 f8e7 e1a1 f6e4 b5d7",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pppppppp/8/8/2PP1B2/P1NBPN1P/1PQ2PP1/2R2RK1 w Qkq -",
    EVAL: 709,
    MOVES: "d3h7 g8f6 h7g6 d7d6 f1d1 e7e6 f3g5 f7g6 c2g6 e8d7",
    DEPTH: 44,
  },
  {
    FEN: "rn1q1rk1/pp2ppbp/2p2np1/3p1b2/2PP4/1P3NP1/P2NPPBP/R1BQ1RK1 b - -",
    EVAL: 4,
    MOVES: "f8e8 c1b2 h7h5 e2e3 a7a5 d1e2 a5a4 f3h4 f5g4 e2d3",
    DEPTH: 30,
  },
  {
    FEN: "8/1K6/P7/8/3q4/8/8/7k w - -",
    EVAL: 0,
    MOVES: "a6a7 d4d5 b7b8 d5b3 b8a8 b3a3 a8b8 a3f8 b8b7 f8f3",
    DEPTH: 94,
  },
  {
    FEN: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq -",
    EVAL: 53,
    MOVES: "c4d5 f6d5 e2e4 d5b6 c1e3 f8g7 b1c3 e8h8 d1d2 b8c6",
    DEPTH: 43,
  },
  {
    FEN: "5k2/5N2/6K1/3B4/8/8/8/8 w - -",
    EVAL: "M21",
    MOVES: "f7d6 f8e7 d6c4 e7f8 g6f6 f8e8 c4e5 e8f8 e5g6 f8e8",
    DEPTH: 69,
  },
  {
    FEN: "rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -",
    EVAL: 50,
    MOVES: "e2e4 f8g7 c2c4 g8f6 b1c3 e8h8 g1f3 d7d6 f1e2 e7e5",
    DEPTH: 51,
  },
  {
    FEN: "rn1qk1nr/pppbbppp/3pp3/8/4P3/5NP1/PPPP1PBP/RNBQ1RK1 b kq -",
    EVAL: 96,
    MOVES: "c7c5 d2d4 c5d4 f3d4 b8c6 c2c4 g8f6 b1c3 c6d4 d1d4",
    DEPTH: 21,
  },
  {
    FEN: "rnb1k2r/pppp1ppp/8/4P2n/3P1p1q/8/PPP1Q1PP/RNB1KB1R w KQkq -",
    EVAL: 55,
    MOVES: "e2f2 h4f2 e1f2 d7d5 f1e2 g7g6 b1c3 c7c6 e2h5 g6h5",
    DEPTH: 30,
  },
  {
    FEN: "8/5k2/8/8/5K2/5N1B/8/8 w - -",
    EVAL: 15414,
    MOVES: "f4e5 f7f8 h3f5 f8g7 f5e6 g7h6 f3e1 h6h7 e6c4 h7h8",
    DEPTH: 60,
  },
  {
    FEN: "1k4rr/pp2bp2/4p1q1/n2pP1p1/b1pP2B1/P1P1B2P/1P1RQP1N/5RK1 b - -",
    EVAL: -224,
    MOVES: "e7d8 g1h1 a5c6 f2f4 g5f4 e3f4 c6e7 e2g2 e7f5 g2e2",
    DEPTH: 43,
  },
  {
    FEN: "r2qkb1r/pppb1ppp/2n1pn2/3p4/3P1B2/4PN1P/PPP2PP1/RN1QKB1R w KQkq -",
    EVAL: 40,
    MOVES: "c2c3 f8d6 b1d2 e8h8 f1d3 d7e8 d1e2 d8e7 d3c2 e6e5",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3P4/5p2/8/PPPP2PP/RNBQKBNR w KQkq -",
    EVAL: -28,
    MOVES: "g1f3 g8f6 c2c4 c7c6 d2d4 f8b4 b1c3 c6d5 f1e2 b8c6",
    DEPTH: 52,
  },
  {
    FEN: "rn1qk1nr/pppbbppp/3pp3/8/8/5NP1/PPPPPPBP/RNBQ1RK1 w kq -",
    EVAL: 89,
    MOVES: "d2d4 g8f6 c2c4 c7c5 d4d5 e8h8 f1e1 f6e4 b1d2 e4d2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3pp3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 123,
    MOVES: "e4d5 e5e4 d1e2 g8f6 d2d3 d8d5 b1d2 b8c6 d3e4 d5e6",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/ppp2ppp/8/3Pp3/5P2/8/PPPP2PP/RNBQKBNR b KQkq -",
    EVAL: -15,
    MOVES: "e5f4 g1f3 g8f6 c2c4 c7c6 d2d4 c6d5 c4c5 b7b6 b2b4",
    DEPTH: 56,
  },
  {
    FEN: "8/8/5k2/4N3/5K2/7B/8/8 w - -",
    EVAL: 5395,
    MOVES: "e5c6 f6g6 h3f5 g6f6 f4g4 f6g7 g4g5 g7f7 f5c8 f7g8",
    DEPTH: 55,
  },
  {
    FEN: "1k4rr/pp2bp2/2n1p1q1/3pP1p1/b1pP2B1/P1P1BP1P/1P1RQ2N/5RK1 b - -",
    EVAL: -218,
    MOVES: "e7d8 e2g2 c6e7 f3f4 g5f4 e3f4 g6h7 h2f3 e7f5 g2h2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pppp1p1p/5np1/4p3/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq -",
    EVAL: 80,
    MOVES: "d4e5 f6h5 g1h3 b8c6 b1c3 c6e5 h3f2 f7f5 f3f4 e5c6",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 4,
    MOVES: "b7b5 a4b3 f8c5 b1c3 d7d6 c3d5 h7h6 a2a4 c6a5 d5f6",
    DEPTH: 57,
  },
  {
    FEN: "rnbq1rk1/p3bppp/1p2pn2/2pp4/2P5/1P2PNP1/PB1P1PBP/RN1Q1RK1 b - -",
    EVAL: 0,
    MOVES: "c8b7 b1c3 d5c4 b3c4 f6e4 h2h4 e4c3 b2c3 b8d7 a2a4",
    DEPTH: 35,
  },
  {
    FEN: "rnb1k2r/pp2qppp/4p3/2ppP3/3Pn3/2N2N2/PPP2PPP/R2QKB1R w KQkq -",
    EVAL: 51,
    MOVES: "c3e4 d5e4 f3d2 c5d4 d2e4 e7b4 d1d2 b4d2 e1d2 c8d7",
    DEPTH: 23,
  },
  {
    FEN: "8/8/8/4N1k1/4K3/7B/8/8 w - -",
    EVAL: 8038,
    MOVES: "h3f1 g5h5 e4f4 h5h6 f1d3 h6g7 f4f5 g7g8 f5f6 g8h8",
    DEPTH: 59,
  },
  {
    FEN: "r2qkb1r/pppb1ppp/2n1pn2/3p4/3P1B2/2P1PN1P/PP3PP1/RN1QKB1R b KQkq -",
    EVAL: 39,
    MOVES: "f8d6 b1d2 e8h8 f1d3 a7a5 e1h1 c6e7 f4e5 e7g6 c3c4",
    DEPTH: 30,
  },
  {
    FEN: "1k1b2rr/pp3p2/2n1p1q1/3pP1p1/b1pP2B1/P1P1BP1P/1P1RQ2N/5RK1 w - -",
    EVAL: -217,
    MOVES: "e2g2 c6e7 f3f4 g5f4 e3f4 g6h7 d2e2 e7g6 f4g3 g6h4",
    DEPTH: 33,
  },
  {
    FEN: "8/8/3kn3/8/4K3/4Q3/8/8 b - -",
    EVAL: "M13",
    MOVES: "e6c5 e4d4 c5e6 d4c4 d6d7 e3b6 d7e7 c4d5 e6f8 b6f2",
    DEPTH: 104,
  },
  {
    FEN: "rnbqkb1r/pppp1p1p/6p1/4P2n/2P5/5P2/PP2P1PP/RNBQKBNR w KQkq -",
    EVAL: 98,
    MOVES: "g1h3 b8c6 b1c3 c6e5 h3f2 f7f5 f3f4 e5c6 g2g3 f8g7",
    DEPTH: 43,
  },
  {
    FEN: "8/8/5k2/8/4K3/5N1B/8/8 w - -",
    EVAL: "M34",
    MOVES: "h3f1 f6g6 e4e5 g6g7 f1b5 g7f7 b5c4 f7g6 c4d5 g6g7",
    DEPTH: 60,
  },
  {
    FEN: "rn1qk2r/1p2bpp1/p2pbn2/4p2p/4P3/1NN1BP2/PPPQ2PP/R3KB1R w KQkq -",
    EVAL: 31,
    MOVES: "f1e2 e8h8 c3d5 f6d5 e4d5 e6f5 e1h1 a6a5 f3f4 e5f4",
    DEPTH: 45,
  },
  {
    FEN: "r5kr/4qp1p/p4p1B/1pp5/8/1PPP4/1P3PPP/R3R1K1 w - -",
    EVAL: "M5",
    MOVES: "e1e7 f6f5 a1e1 f7f6 e1e6 f5f4 e6f6 a6a5 e7g7",
    DEPTH: 161,
  },
  {
    FEN: "r2qk2r/ppp2ppp/2p2n2/2b1p3/4P1b1/3P1N2/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: 0,
    MOVES: "h2h3 g4h5 g2g4 f6g4 h3g4 h5g4 c1e3 c5e7 g1g2 f7f5",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n1p3/2pp4/4P3/2NP1N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: -1,
    MOVES: "e4d5 e6d5 f1e2 f8e7 d3d4 c5c4 e1h1 g8f6 b2b3 e7b4",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/4P3/8/8/PPPP1PPP/RNBQKB1R b KQkq -",
    EVAL: 77,
    MOVES: "f6d5 d2d4 c6c5 f1e2 c8f5 c2c3 c7c6 e1h1 f8e7 e2g4",
    DEPTH: 50,
  },
  {
    FEN: "r2qk2r/ppp2ppp/2p2n2/2b1p3/4P1b1/3P1N2/PPPN1PPP/R1BQ1RK1 b kq -",
    EVAL: 60,
    MOVES: "f6d7 h2h3 g4e6 d2b3 c5b6 f3g5 d8e7 g5e6 e7e6 a2a4",
    DEPTH: 27,
  },
  {
    FEN: "r5kr/4Rp1p/p6B/1pp2p2/8/1PPP4/1P3PPP/R5K1 w - -",
    EVAL: "M4",
    MOVES: "a1e1 f7f6 e1e6 f5f4 e6f6 b5b4 e7g7",
    DEPTH: 245,
  },
  {
    FEN: "8/8/8/2nkQK2/8/8/8/8 b - -",
    EVAL: "M17",
    MOVES: "d5c4 e5e3 c4d5 e3f4 d5c6 f4d4 c5d7 d4c4 c6d6 c4e6",
    DEPTH: 63,
  },
  {
    FEN: "rnbq1rk1/p3bppp/1p2pn2/2pp4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1 w - -",
    EVAL: 24,
    MOVES: "c4d5 f6d5 d2d4 c8b7 d4c5 e7c5 b1d2 d5f6 f3e5 b7g2",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p4/4P2n/3P1p2/2N2N2/PPP3PP/R1BQKB1R b KQkq -",
    EVAL: -58,
    MOVES: "d6e5 f1b5 c7c6 b5c4 b8d7 c3e4 h5f6 d1e2 f8e7 e1h1",
    DEPTH: 30,
  },
  {
    FEN: "r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1B1P1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 13,
    MOVES: "b1c3 h7h6 c3d5 d7d6 d5f6 d8f6 b3d5 c8d7 c1e3 e8h8",
    DEPTH: 43,
  },
  {
    FEN: "rnbq1rk1/p1p1bppp/1p2pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1 w - -",
    EVAL: 37,
    MOVES: "c4d5 f6d5 d2d4 c8b7 c1b2 c7c5 d4c5 e7c5 b1d2 b8d7",
    DEPTH: 43,
  },
  {
    FEN: "r1b1kb1r/ppqn1ppp/2pp1n2/4p3/3PP3/2N1BP2/PPPQN1PP/R3KB1R b KQkq -",
    EVAL: 40,
    MOVES: "b7b5 a2a3 h7h5 d4d5 a7a6 d5c6 c7c6 e2c1 d7b6 c1a2",
    DEPTH: 24,
  },
  {
    FEN: "rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/3P1NP1/PP2PPBP/RNBQ1RK1 b - -",
    EVAL: 0,
    MOVES: "h7h6 b2b3 c7c5 c4d5 e6d5 d3d4 f6e4 d4c5 b8c6 c1b2",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq -",
    EVAL: 35,
    MOVES: "c6d5 f1d3 g8f6 g1f3 c8g4 h2h3 g4h5 c1f4 b8c6 c2c3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1BNP1N2/PPP2PPP/R1BQK2R b KQkq -",
    EVAL: 0,
    MOVES: "d7d6 c3d5 h7h6 c2c3 c8e6 d5f6 d8f6 e1h1 c6e7 c1e3",
    DEPTH: 47,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/8/1Bp5/3nP3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 a7a6 b5d3 g7g6 f3d4 c5d4 c3e2 d7d5 c2c3 d5e4",
    DEPTH: 45,
  },
  {
    FEN: "rn3rk1/p5pp/2p5/3Ppb2/4q3/1Q6/PPPBN1PP/R3K2R b KQ -",
    EVAL: -9,
    MOVES: "f8f7 d5c6 f5g4 b3e3 e4g2 h1g1 g2f3 c6c7 b8c6 e3f3",
    DEPTH: 36,
  },
  {
    FEN: "2kr1bnr/ppp1pppp/2n5/5q2/2PP2b1/5N2/PP2BPPP/RNBQK2R w KQ -",
    EVAL: 0,
    MOVES: "e1h1 c6d4 f3d4 g4e2 d1e2 d8d4 c1e3 d4d7 c4c5 f5d3",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/4pn2/6B1/2PP4/8/PP2PPPP/RN1QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "h7h6 g5f6 d8f6 b1c3 f8b4 a1c1 c7c5 g1f3 c5d4 f3d4",
    DEPTH: 57,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP1B2/1Q2P3/PP1N1PPP/R3KBNR b KQkq -",
    EVAL: 30,
    MOVES: "d8c8 f1d3 f5d3 b3d3 b8d7 g1f3 f8e7 a1c1 a7a5 h2h3",
    DEPTH: 29,
  },
  {
    FEN: "r2qkb1r/pp2pppp/2n2n2/3p4/3P2b1/3B1N2/PPPN1PPP/R1BQK2R w KQkq -",
    EVAL: -9,
    MOVES: "c2c3 e7e6 d2f1 d8a5 d1b3 e8a8 f1d2 a5c7 d3b5 f8d6",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/4p3/2p5/2BnP3/2N5/PPPP1PPP/R1BQK1NR w KQkq -",
    EVAL: 0,
    MOVES: "g1e2 g8f6 e1h1 a7a6 a2a4 d7d5 e4d5 e6d5 c4a2 f8d6",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkb1r/1p2pppp/p1np1n2/1B6/4P3/2N5/PPP2PPP/R1BQK1NR w KQkq -",
    EVAL: -11,
    MOVES: "b5c6 b7c6 g1f3 e7e5 e1h1 f8e7 f1e1 e8h8 b2b3 f6d7",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: 12,
    MOVES: "d4c6 b7c6 b1c3 f8b4 f1d3 e8h8 e1h1 d7d5 e4d5 c6d5",
    DEPTH: 51,
  },
  {
    FEN: "r1b1k2r/ppqnbppp/2pp1n2/4p3/3PP3/2N1BP2/PPPQN1PP/R3KB1R w KQkq -",
    EVAL: 95,
    MOVES: "g2g4 e8h8 g4g5 f6e8 d4d5 f7f6 h2h4 f6g5 h4g5 f8f3",
    DEPTH: 41,
  },
  {
    FEN: "rn1qk2r/1p2bpp1/p2pbn2/3Np2p/4P3/1N2BP2/PPPQ2PP/R3KB1R b KQkq -",
    EVAL: 21,
    MOVES: "f6d5 e4d5 e6f5 f1e2 a6a5 a2a4 e8h8 e1h1 b8d7 f3f4",
    DEPTH: 39,
  },
  {
    FEN: "rnbq1rk1/p1p1bppp/1p2pn2/3p4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1 b - -",
    EVAL: 11,
    MOVES: "c8b7 c4d5 f6d5 d2d4 b8d7 f1e1 c7c5 e2e4 d5f6 b1c3",
    DEPTH: 38,
  },
  {
    FEN: "r1bqkbnr/pp1p1ppp/4p3/2p5/2BnP3/2N5/PPPPNPPP/R1BQK2R b KQkq -",
    EVAL: 2,
    MOVES: "g8f6 e1h1 f8e7 e4e5 f6g4 f2f4 d4c6 d2d3 e8h8 e2g3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 43,
    MOVES: "d4c6 b7c6 e4e5 f6d5 c3e4 d8c7 f2f4 c7b6 c2c4 f8b4",
    DEPTH: 53,
  },
  {
    FEN: "r3r1k1/1b2qppB/p7/1p1Pb3/1P6/P7/1B2Q1PP/3R1RK1 b - -",
    EVAL: 433,
    MOVES: "g8h7 e2h5 h7g8 b2e5 e7e5 h5f7 g8h8 f7b7 a8d8 b7f7",
    DEPTH: 47,
  },
  {
    FEN: "8/8/3Q4/8/2k1K3/3n4/8/8 b - -",
    EVAL: "M13",
    MOVES: "d3c5 e4e3 c5b3 d6c7 c4b4 e3e4 b3c5 e4d5 c5b3 c7c4",
    DEPTH: 60,
  },
  {
    FEN: "1k1b2rr/pp3p2/2n1p1q1/3pP1p1/b1pP2B1/P1P2P1P/1P1RQB1N/5RK1 b - -",
    EVAL: -303,
    MOVES: "c6e7 f1e1 g6h7 f2g3 e7g6 h2f1 g6f4 e2e3 f4h3 g4h3",
    DEPTH: 26,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p2n2/2b1p3/4P3/3P1N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 3,
    MOVES: "f6d7 c2c3 c5d6 d3d4 a7a5 c1e3 e8h8 b1d2 e5d4 f3d4",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/4pn2/2p5/2BnP3/2N5/PPPPNPPP/R1BQK2R w KQkq -",
    EVAL: 0,
    MOVES: "e1h1 a7a6 a2a4 d8c7 d2d3 b7b6 e2d4 c5d4 c3e2 f6e4",
    DEPTH: 39,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR w KQkq -",
    EVAL: 13,
    MOVES: "f1d3 b8c6 c2c3 g8f6 c1f4 c8g4 g1f3 e7e6 e1h1 f6h5",
    DEPTH: 60,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR b KQkq -",
    EVAL: 32,
    MOVES: "b8c6 c2c3 g8f6 c1f4 c8g4 d1b3 d8d7 h2h3 g4h5 b1d2",
    DEPTH: 56,
  },
  {
    FEN: "1k1b2rr/pp2np2/4p1q1/3pP1p1/b1pP2B1/P1P2P1P/1P1RQB1N/4R1K1 b - -",
    EVAL: -332,
    MOVES: "d8c7 h2f1 h8h7 f2g3 e7f5 e2g2 f5h6 g3f2 a4c2 f1g3",
    DEPTH: 31,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/q2B4/1p1BP3/5P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 15,
    MOVES: "f6d5 e4d5 a5d5 d2b4 f8b8 b4d2 g7d4 d2d4 d5a5 b2b3",
    DEPTH: 37,
  },
  {
    FEN: "rnbq1rk1/pp2ppbp/6p1/4N3/3Pp3/6P1/PP2PPBP/R1BQ1RK1 b - -",
    EVAL: 0,
    MOVES: "b8d7 e5d7 c8d7 g2e4 d7h3 e4b7 a8b8 b7g2 h3g2 g1g2",
    DEPTH: 43,
  },
  {
    FEN: "r3r1k1/1b2qpp1/p7/1p1Pb2Q/1P6/P7/1B4PP/3R1RK1 w - -",
    EVAL: 390,
    MOVES: "b2e5 e7e5 h5f7 g8h8 f7b7 a8d8 b7f7 e8e7 f7g6 e5e3",
    DEPTH: 58,
  },
  {
    FEN: "r1bqkb1r/1p1p1ppp/p3pn2/2p5/P1BnP3/2N5/1PPPNPPP/R1BQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "f8e7 e4e5 f6g4 f2f4 d4c6 e2g3 f7f5 h2h3 d7d5 e5d6",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2n5/3p4/3P4/2PB4/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: 12,
    MOVES: "g8f6 c1f4 c8g4 d1b3 d8d7 b1d2 e7e6 g1f3 f8d6 f4d6",
    DEPTH: 51,
  },
  {
    FEN: "1k1b2r1/pp2np2/4p1qr/3pP1p1/b1pP2B1/P1P2P1P/1P1RQB1N/4R1K1 w - -",
    EVAL: -290,
    MOVES: "f2g3 b8a8 e1c1 g8h8 h2f1 e7f5 g3h2 a7a6 c1e1 h6h7",
    DEPTH: 32,
  },
  {
    FEN: "r2q1rk1/ppp2ppp/2n2n2/3pp3/1b4b1/2NPPN2/PPPBBPPP/R2Q1RK1 w - -",
    EVAL: -37,
    MOVES: "a2a3 b4e7 b2b4 e5e4 f3d4 c6d4 e3d4 g4e2 d1e2 f8e8",
    DEPTH: 26,
  },
  {
    FEN: "r1bqk2r/ppp2ppp/2p5/2b1P3/4n3/3P4/PPP2PPP/RNBQKB1R w KQkq -",
    EVAL: -355,
    MOVES: "c1e3 c5e3 f2e3 d8h4 g2g3 e4g3 h2g3 h4h1 b1d2 e8h8",
    DEPTH: 46,
  },
  {
    FEN: "1k1b2r1/pp2np2/4p1qr/3pP1p1/b1pP2B1/P1P2P1P/1P1RQB2/4RNK1 b - -",
    EVAL: -302,
    MOVES: "g8h8 f2g3 h6h7 g3h2 e7f5 f1g3 d8c7 e2g2 f5h4 g2e2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 14,
    MOVES: "c4d5 c5d4 d1d4 e6d5 c1g5 f8e7 e2e3 b8c6 d4d3 e8h8",
    DEPTH: 53,
  },
  {
    FEN: "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -",
    EVAL: 79,
    MOVES: "d4d5 d7d6 g1f3 g8f6 f1c4 h7h6 d1e2 e8h8 e1h1 b8d7",
    DEPTH: 44,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/q2P4/1p1B4/1B3P2/PPPQ2PP/1K1R3R b - -",
    EVAL: 29,
    MOVES: "a5b5 d2d3 b5d3 d1d3 f6d7 d4g7 g8g7 d3e3 g7f6 h1e1",
    DEPTH: 37,
  },
  {
    FEN: "r3r1k1/1b3pp1/p7/1p1Pq2Q/1P6/P7/6PP/3R1RK1 w - -",
    EVAL: 430,
    MOVES: "h5f7 g8h8 f7b7 a8d8 b7f7 e8e7 f7g6 e5e4 g6h5 e4h7",
    DEPTH: 55,
  },
  {
    FEN: "1k1b3r/pp2np2/4p1qr/3pP1p1/b1pP2B1/P1P2P1P/1P1RQB2/4RNK1 w - -",
    EVAL: -293,
    MOVES: "f2g3 e7f5 g3h2 g6g8 e2f2 h6h4 f2g2 h4h7 g2f2 f5h6",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p1p3/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -",
    EVAL: 53,
    MOVES: "e2e3 d7d5 g1f3 g8f6 b2b3 c6c5 c1b2 c5d4 f3d4 e6e5",
    DEPTH: 45,
  },
  {
    FEN: "7r/6Rp/p4p1k/1pp2p2/8/1PPP4/1P3PPP/6K1 w - -",
    EVAL: 434,
    MOVES: "g7d7 h6g5 g1f1 h7h5 d7d5 h8c8 h2h4 g5g4 d3d4 c5c4",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkb1r/pp1p1ppp/2n1pn2/1N6/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq -",
    EVAL: 20,
    MOVES: "f8b4 a2a3 b4c3 b5c3 d7d5 e4d5 e6d5 f1d3 e8h8 e1h1",
    DEPTH: 66,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/3P4/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "e5f4 c1f4 b8c6 f1e2 f8c5 b1c3 e8h8 e4e5 f6e8 g1f3",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk1nr/pp1pppbp/6p1/2P5/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq -",
    EVAL: 66,
    MOVES: "g7c3 b2c3 d8a5 d1d4 g8f6 d4b4 a5c7 f2f3 b7b6 c5b6",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/3P4/8/4n3/8/PPPPQPPP/RNB1KBNR b KQkq -",
    EVAL: -44,
    MOVES: "e4d6 d2d4 g7g6 c1f4 f8g7 f4e5 f7f6 e5d6 c7d6 b1c3",
    DEPTH: 24,
  },
  {
    FEN: "r3r2k/1b3Qp1/p7/1p1Pq3/1P6/P7/6PP/3R1RK1 w - -",
    EVAL: 409,
    MOVES: "f7b7 a8d8 b7f7 e8e7 f7g6 e5e3 g1h1 e3e2 g6b6 d8d7",
    DEPTH: 57,
  },
  {
    FEN: "r6r/2q1nP1p/p3k1p1/1B2p3/8/2P1B3/P1PR2PP/5RK1 w - -",
    EVAL: -438,
    MOVES: "b5d7 c7d7 d2d7 e6d7 e3h6 h8f8 f1f6 a6a5 g2g4 a8c8",
    DEPTH: 41,
  },
  {
    FEN: "r1bq1rk1/ppp2ppp/2n2n2/3pp3/1b6/2NPPN2/PPPBBPPP/R2Q1RK1 b - -",
    EVAL: -64,
    MOVES: "f8e8 a2a3 b4f8 e3e4 d5d4 c3a2 a7a5 c2c3 b7b6 a2c1",
    DEPTH: 23,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/1q1P4/1p1B4/1B3P2/PPPQ2PP/1K1R3R w - -",
    EVAL: 35,
    MOVES: "d2d3 b5d3 d1d3 f6d7 b3a4 d7c5 d4c5 d6c5 h1e1 g7f6",
    DEPTH: 45,
  },
  {
    FEN: "8/8/3kn2Q/8/8/3K4/8/8 b - -",
    EVAL: "M14",
    MOVES: "d6d5 h6h5 d5d6 d3c4 d6e7 h5h6 e7f7 c4d5 e6f8 d5d6",
    DEPTH: 64,
  },
  {
    FEN: "8/8/8/2k5/4K3/8/8/4R3 w - -",
    EVAL: "M11",
    MOVES: "e1d1 c5c6 d1d5 c6c7 e4e5 c7c6 e5e6 c6c7 d5c5 c7b6",
    DEPTH: 99,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/3n4/8/8/8/PPPPQPPP/RNB1KBNR w KQkq -",
    EVAL: -29,
    MOVES: "g1f3 g7g6 d2d4 f8g7 b1c3 d6f5 c1g5 h7h6 g5e3 c7c5",
    DEPTH: 26,
  },
  {
    FEN: "8/8/8/4K3/2k5/8/8/4R3 w - -",
    EVAL: "M11",
    MOVES: "e1d1 c4c3 d1d4 c3c2 e5e4 c2c3 e4e3 c3c2 d4d3 c2c1",
    DEPTH: 99,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/5NP1/PP1BPP1P/RN1QKB1R b KQkq -",
    EVAL: 9,
    MOVES: "b4e7 f1g2 e8h8 e1h1 c7c6 d1c2 b7b6 c4d5 c6d5 f3e5",
    DEPTH: 57,
  },
  {
    FEN: "1k1b4/pp2np1q/4p3/3pP1p1/b1pP4/P1P1NPBr/1P1R2Q1/4R1K1 b - -",
    EVAL: -310,
    MOVES: "e7g6 e3f1 g6f4 g3f4 g5f4 e1a1 a7a6 a1e1 b8a7 e1c1",
    DEPTH: 43,
  },
  {
    FEN: "rnbqk1nr/pp1ppp1p/6p1/2P5/4P3/2P5/P1P2PPP/R1BQKBNR b KQkq -",
    EVAL: 71,
    MOVES: "d8a5 d1d4 g8f6 d4b4 a5c7 f1d3 e8h8 g1e2 b8a6 d3a6",
    DEPTH: 42,
  },
  {
    FEN: "8/8/8/4K3/8/2k5/8/3R4 w - -",
    EVAL: "M10",
    MOVES: "d1d4 c3c2 e5e4 c2c3 e4e3 c3c2 d4d3 c2c1 d3d2 c1b1",
    DEPTH: 201,
  },
  {
    FEN: "r4rk1/p3ppbp/3p1np1/1q1P4/1p1B4/1B3P2/PPPQ2PP/1K1RR3 b - -",
    EVAL: 0,
    MOVES: "a7a5 d2e2 b5e2 e1e2 a5a4 b3c4 f8c8 b2b3 c8c7 c4b5",
    DEPTH: 40,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R b KQkq -",
    EVAL: 27,
    MOVES: "g4f3 d1f3 e7e6 g2g3 b8d7 d2d4 d8b6 f3d3 f8b4 e4d5",
    DEPTH: 50,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/5n2/3p4/3P4/2PB4/PP3PPP/RNBQK1NR b KQkq -",
    EVAL: 21,
    MOVES: "c8g4 g1e2 e7e6 h2h3 g4h5 d1b3 h5e2 e1e2 d8c7 h1e1",
    DEPTH: 50,
  },
  {
    FEN: "1k1b4/pp2np1q/4p3/3pP1p1/b1pP4/P1P2PBr/1P1R2Q1/4RNK1 w - -",
    EVAL: -312,
    MOVES: "d2f2 e7g6 f3f4 g5f4 g3f4 h3h5 e1e2 a4d1 e2d2 g6f4",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk2r/1pp2ppp/4pn2/p2p4/1bPP4/5NP1/PP1BPP1P/RN1QKB1R w KQkq -",
    EVAL: 30,
    MOVES: "d1c2 d5c4 c2c4 b7b6 d2b4 c8a6 c4b3 a5b4 b3b4 b8c6",
    DEPTH: 51,
  },
  {
    FEN: "8/8/8/8/1k2K3/8/8/3R4 w - -",
    EVAL: "M10",
    MOVES: "e4d4 b4b5 d1c1 b5b6 d4d5 b6b5 c1b1 b5a4 d5d4 a4a3",
    DEPTH: 107,
  },
  {
    FEN: "r3r2k/1Q4p1/p7/1p1Pq3/1P6/P7/6PP/3R1RK1 b - -",
    EVAL: 366,
    MOVES: "a8d8 b7f7 e8e7 f7g6 e5e3 g1h1 e3e2 f1g1 h8g8 g6a6",
    DEPTH: 35,
  },
  {
    FEN: "rnbqk2r/ppp2ppp/4pn2/3p4/3P4/2PBP1P1/PP3PP1/RN1QK1NR b KQkq -",
    EVAL: 23,
    MOVES: "b8d7 g1f3 d8e7 b1d2 e6e5 d4e5 d7e5 f3e5 e7e5 d1a4",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p3b/4P3/2N2N1P/PPPP1PP1/R1BQKB1R w KQkq -",
    EVAL: 74,
    MOVES: "e4d5 c6d5 g2g4 h5g6 h3h4 e7e6 d2d4 h7h6 f3e5 g8e7",
    DEPTH: 44,
  },
  {
    FEN: "8/8/8/3K4/1k6/8/8/2R5 w - -",
    EVAL: "M9",
    MOVES: "c1c5 b4b3 c5c4 b3b2 d5c5 b2b3 c5b5 b3a2 b5a4 a2b2",
    DEPTH: 99,
  },
  {
    FEN: "3rr2k/1Q4p1/p7/1p1Pq3/1P6/P7/6PP/3R1RK1 w - -",
    EVAL: 417,
    MOVES: "b7f7 e8e7 f7g6 e5e3 g1h1 e3e2 f1g1 h8g8 g6a6 e7e3",
    DEPTH: 53,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/3pP3/4n3/8/PPPPQPPP/RNB1KBNR w KQkq d6",
    EVAL: 99,
    MOVES: "c2c3 c8f5 d2d4 e7e6 c1f4 g7g5 f4e3 g5g4 b1d2 e4d2",
    DEPTH: 25,
  },
  {
    FEN: "r4rk1/4ppbp/3p1np1/pq1P4/1p1B4/1B3P2/PPPQ2PP/1K1RR3 w - -",
    EVAL: 0,
    MOVES: "d2e2 b5e2 e1e2 a5a4 b3c4 f8c8 b2b3 c8c7 c4b5 a4b3",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/5n2/3p4/3P2b1/2PB4/PP3PPP/RNBQK1NR w KQkq -",
    EVAL: 20,
    MOVES: "g1f3 e7e6 h2h3 g4h5 c1f4 f8d6 f4d6 d8d6 b1d2 b8c6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqk2r/1pp2ppp/4pn2/3p4/1pPP4/5NP1/PP2PP1P/RN1QKB1R w KQkq -",
    EVAL: 0,
    MOVES: "b1d2 b7b6 f1g2 e8h8 e1h1 c8b7 d1c2 b8d7 c4c5 c7c6",
    DEPTH: 36,
  },
  {
    FEN: "8/8/8/1k6/3K4/8/8/2R5 w - -",
    EVAL: "M9",
    MOVES: "c1c2 b5b6 d4d5 b6b7 d5c5 b7c7 c2d2 c7b7 d2d7 b7c8",
    DEPTH: 99,
  },
  {
    FEN: "8/8/4n2Q/4k3/8/4K3/8/8 b - -",
    EVAL: "M18",
    MOVES: "e5d5 h6h5 d5c4 h5f7 c4d5 f7f5 d5d6 e3e4 e6c5 e4d4",
    DEPTH: 44,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/5n2/3p4/3P2b1/1QPB4/PP3PPP/RNB1K1NR b KQkq -",
    EVAL: 13,
    MOVES: "d8c7 g1e2 g4e2 d3e2 e7e6 c1e3 f8d6 b1d2 b8c6 a1c1",
    DEPTH: 56,
  },
  {
    FEN: "8/8/7Q/2n1k3/8/4K3/8/8 w - -",
    EVAL: "M14",
    MOVES: "h6h8 e5d6 h8d4 d6c6 d4e5 c5a6 e5f6 c6b7 e3d4 a6c7",
    DEPTH: 99,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -29,
    MOVES: "f3e5 d7d5 c4e2 f8d6 e5f3 c7c6 d2d3 e4f6 e1h1 e8h8",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p3b/4P1P1/2N2N1P/PPPP1P2/R1BQKB1R b KQkq -",
    EVAL: 71,
    MOVES: "d5e4 c3e4 h5g6 d1e2 d8c7 d2d4 e7e6 f1g2 b8d7 e1h1",
    DEPTH: 39,
  },
  {
    FEN: "rnb1k2r/pppp1pp1/4pq1p/8/1bPP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: -24,
    MOVES: "b4c3 b2c3 d7d6 g2g3 e8h8 f1g2 e6e5 e1h1 b8c6 a1b1",
    DEPTH: 36,
  },
  {
    FEN: "8/8/8/1k6/3K4/8/8/1R6 b - -",
    EVAL: "M10",
    MOVES: "b5c6 b1e1 c6d6 d4c4 d6c6 e1d1 c6b6 d1d6 b6b7 c4b5",
    DEPTH: 69,
  },
  {
    FEN: "rnbqkbnr/pp1p1ppp/2p1p3/8/2PP1B2/8/PP2PPPP/RN1QKBNR b KQkq -",
    EVAL: 33,
    MOVES: "g8f6 e2e3 d7d5 b1c3 f8d6 f4g3 e8h8 g1f3 b7b6 a1c1",
    DEPTH: 30,
  },
  {
    FEN: "rnbqk2r/1pp2ppp/4pn2/8/1ppP4/5NP1/PP2PPBP/RN1QK2R w KQkq -",
    EVAL: 0,
    MOVES: "f3e5 e8h8 e5c4 b8c6 e2e3 e6e5 d4e5 d8d1 e1d1 f6g4",
    DEPTH: 35,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR w KQkq -",
    EVAL: 57,
    MOVES: "g1f3 f6d5 d2d4 c8f5 f1d3 f5d3 d1d3 c7c6 c2c4 d5f6",
    DEPTH: 56,
  },
  {
    FEN: "rnbqr1k1/ppp1ppbp/3p1np1/8/3P1B2/2NBPN2/PPP2PPP/R2QK2R w KQ -",
    EVAL: 0,
    MOVES: "e1h1 c8g4 h2h3 g4f3 d1f3 c7c6 a2a4 b8d7 d3e2 e7e5",
    DEPTH: 27,
  },
  {
    FEN: "1k1b4/pp3p1q/4p1n1/3pP1p1/b1pP4/P1P1NPBr/1P1R2Q1/4R1K1 w - -",
    EVAL: -325,
    MOVES: "e3f1 g6f4 g3f4 g5f4 e1c1 h7h8 d2e2 a4b3 c1a1 d8e7",
    DEPTH: 47,
  },
  {
    FEN: "8/8/2k5/8/3K4/8/8/1R6 w - -",
    EVAL: "M10",
    MOVES: "b1e1 c6d6 d4c4 d6c6 e1d1 c6b6 d1d6 b6c7 c4c5 c7b7",
    DEPTH: 80,
  },
  {
    FEN: "r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/3P1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 21,
    MOVES: "a4b3 f8c5 c2c3 d7d6 c1g5 h7h6 g5h4 g7g5 h4g3 c8e6",
    DEPTH: 46,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p5/3p4/2PP4/8/PP3PPP/RNBQKBNR w KQkq -",
    EVAL: 10,
    MOVES: "b1c3 g8f6 f1d3 f8b4 c4d5 f6d5 g1e2 b8d7 a2a3 b4a5",
    DEPTH: 47,
  },
  {
    FEN: "1k1b4/pp3p1q/4p1n1/3pP1p1/b1pP2N1/P1P2PBr/1P1R2Q1/4R1K1 b - -",
    EVAL: -531,
    MOVES: "g6f4 g3f4 g5f4 g2h3 h7h3 e1f1 h3h5 g1g2 h5h4 g2g1",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/5N2/PPPP1PPP/RNBQ1RK1 b kq -",
    EVAL: -88,
    MOVES: "d7d5 c4b3 b8c6 d1e1 f8e7 d2d3 e4f6 f3e5 c6e5 e1e5",
    DEPTH: 43,
  },
  {
    FEN: "1k1b4/pp3p1q/4p3/3pP3/b1pP1pN1/P1P2P1r/1P1R2Q1/4R1K1 w - -",
    EVAL: -537,
    MOVES: "e1c1 h3g3 g1f2 g3g2 f2g2 h7h4 c1f1 h4g3 g2h1 g3h3",
    DEPTH: 54,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 53,
    MOVES: "g1f3 c5d4 f3d4 g8f6 c1e3 b8c6 g2g3 f6g4 d1b3 g4e3",
    DEPTH: 46,
  },
  {
    FEN: "1k1b4/pp3p1q/4p3/3pP3/b1pP1pN1/P1P2P1r/1P1R2Q1/4RK2 b - -",
    EVAL: -557,
    MOVES: "h3g3 e1c1 h7h4 f1g1 h4h3 c1f1 a4b3 g4h2 g3g2 d2g2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqk2r/pp1ppp1p/5np1/2P5/4P3/2P5/P1P2PPP/R1BQKBNR w KQkq -",
    EVAL: 123,
    MOVES: "e4e5 f6e4 f1c4 e4c5 g1f3 b8c6 e1h1 d7d6 c1h6 d6e5",
    DEPTH: 40,
  },
  {
    FEN: "r1bqkb1r/pppnnppp/3pp3/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -",
    EVAL: 94,
    MOVES: "f1c4 a7a6 a2a4 g7g6 c1e3 d7f6 d1d2 b7b6 e3g5 f8g7",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: 25,
    MOVES: "d7c6 f3e5 f6e4 c3e4 d8d5 e1h1 d5e5 d2d4 e5d5 f1e1",
    DEPTH: 52,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "g1f3 f8d6 f1d3 d5c4 d3c4 e8h8 e1h1 c8f5 h2h3 b8d7",
    DEPTH: 43,
  },
  {
    FEN: "8/8/8/2k1K3/8/8/8/1R6 w - -",
    EVAL: "M10",
    MOVES: "b1c1 c5b5 e5d4 b5b6 d4d5 b6b7 d5c5 b7c7 c1d1 c7b7",
    DEPTH: 109,
  },
  {
    FEN: "1k1b4/pp3p1q/4p3/3pP3/b1pP1pN1/P1P2Pr1/1P1R1Q2/4RK2 b - -",
    EVAL: -803,
    MOVES: "h7h3 f1e2 d8h4 e1g1 g3g4 g1g4 h4f2 e2f2 h3h2 g4g2",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 36,
    MOVES: "g8f6 c1g5 c8e6 e2e3 c5c4 f3e5 f8e7 g2g3 e8h8 f1g2",
    DEPTH: 50,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/6b1/3p4/6P1/2N2N1P/PPPP1P2/R1BQKB1R w KQkq -",
    EVAL: 76,
    MOVES: "d2d4 e7e6 h3h4 h7h6 f3e5 g8e7 e5g6 e7g6 h4h5 g6e7",
    DEPTH: 40,
  },
  {
    FEN: "r3R1kr/7p/p4p1B/1pp2p2/8/1PPP4/1P3PPP/4R1K1 b - -",
    EVAL: 596,
    MOVES: "g8f7 e8e7 f7g6 h6f4 h7h5 h2h4 h8h7 e7h7 g6h7 f4e3",
    DEPTH: 29,
  },
  {
    FEN: "1k1b4/pp3p2/4p3/3pP3/b1pP1pN1/P1P2Prq/1P1R1Q2/4RK2 w - -",
    EVAL: -614,
    MOVES: "f1e2 g3g2 e1g1 g2f2 g4f2 h3h2 e2f1 b8c7 g1g7 h2h5",
    DEPTH: 37,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/2p5/3n4/3P4/2N5/PP3PPP/R1BQKBNR w KQkq -",
    EVAL: 18,
    MOVES: "f1d3 f8b4 g1e2 e8h8 e1h1 f8e8 h2h3 b4d6 f1e1 c8e6",
    DEPTH: 28,
  },
  {
    FEN: "8/8/8/2k1K3/3R4/8/8/8 w - -",
    EVAL: "M11",
    MOVES: "d4e4 c5c6 e4c4 c6b5 e5d5 b5b6 d5d6 b6b5 c4g4 b5b6",
    DEPTH: 110,
  },
  {
    FEN: "rn1qk2r/1p2bpp1/p2p4/3Ppb1p/8/1N2BP2/PPPQ2PP/R3KB1R w KQkq -",
    EVAL: 43,
    MOVES: "f1e2 e7h4 g2g3 h4e7 e1a1 b8d7 c1b1 a8c8 e2d3 f5d3",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/1pp2ppp/p1p2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -",
    EVAL: 69,
    MOVES: "f3e5 f6e4 c3e4 d8d4 e1h1 d4e5 d2d4 e5d5 e4g5 c8e6",
    DEPTH: 46,
  },
  {
    FEN: "1k1b4/pp3p2/4p3/3pP3/b1pP1pN1/P1P2P1q/1P1RKQr1/6R1 b - -",
    EVAL: -539,
    MOVES: "g2f2 g4f2 h3h2 e2f1 b8c7 g1h1 h2g3 h1h3 g3g6 h3h1",
    DEPTH: 27,
  },
  {
    FEN: "rn2kb1r/ppq1pppp/5n2/3p4/3P2b1/1QPB4/PP3PPP/RNB1K1NR w KQkq -",
    EVAL: 17,
    MOVES: "h2h3 g4d7 g1f3 e7e6 e1h1 d7c6 f3e5 f8d6 e5c6 b8c6",
    DEPTH: 48,
  },
  {
    FEN: "8/8/8/4K3/2k5/3R4/8/8 w - -",
    EVAL: "M11",
    MOVES: "d3d5 c4c3 d5d4 c3b2 d4d3 b2c2 e5d4 c2b2 d4c4 b2c2",
    DEPTH: 228,
  },
  {
    FEN: "r2qkbnr/pp2pppp/2n3b1/1B1p4/6P1/2N2N1P/PPPP1P2/R1BQK2R w KQkq -",
    EVAL: 47,
    MOVES: "f3e5 a8c8 d2d4 e7e6 d1e2 f8d6 e5g6 h7g6 c3d5 a7a6",
    DEPTH: 41,
  },
  {
    FEN: "1k1b4/pp3p2/4p3/3pP3/b1pP1p2/P1P2P1q/1P1RKN2/6R1 b - -",
    EVAL: -607,
    MOVES: "h3h5 g1h1 h5g6 f2g4 a4c2 h1h6 g6f5 e2f2 c2e4 h6h2",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP1B2/8/PP2PPPP/RN1QKBNR w KQkq -",
    EVAL: 47,
    MOVES: "e2e3 g8f6 g1f3 f8d6 f4d6 d8d6 b1c3 e8h8 a1c1 f8d8",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/8/8/5N2/PPPPnPPP/RNBQ1RK1 w kq -",
    EVAL: 18,
    MOVES: "d1e2 b8c6 c2c3 e7e6 d2d4 f8e7 f1d1 e8h8 c3c4 b7b6",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/3p4/3p4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 48,
    MOVES: "f3d4 g8f6 c1g5 f8e7 e2e3 b8c6 f1e2 e8h8 e1h1 h7h6",
    DEPTH: 49,
  },
  {
    FEN: "1k1b4/pp3p1q/4p3/3pP3/b1pP1p2/P1P2P2/1P1RKN2/7R b - -",
    EVAL: -613,
    MOVES: "h7g6 f2g4 a4c2 h1h6 g6f5 h6h2 c2e4 d2d1 e4b1 h2h1",
    DEPTH: 46,
  },
  {
    FEN: "rnbq1rk1/pp2bppp/2pp1n2/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1 w - -",
    EVAL: 32,
    MOVES: "a2a4 d6d5 e4d5 c6d5 c4b3 b8c6 h2h3 h7h6 d1d3 a7a6",
    DEPTH: 45,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/8/8/5N2/PPPPQPPP/RNB2RK1 b kq -",
    EVAL: 14,
    MOVES: "b8c6 c2c3 e7e6 f1d1 f8d6 d2d4 d8e7 e2e4 e8h8 c1f4",
    DEPTH: 50,
  },
  {
    FEN: "rn2kb1r/ppq1pppp/5n2/3p4/3P2b1/1QPB3P/PP3PP1/RNB1K1NR b KQkq -",
    EVAL: 37,
    MOVES: "g4d7 g1f3 e7e6 e1h1 d7c6 f3e5 b8d7 f2f4 f8d6 b3c2",
    DEPTH: 43,
  },
  {
    FEN: "1k1b4/pp3p2/4p1q1/3pP3/b1pP1p2/P1P2P2/1P1RKN2/7R w - -",
    EVAL: -592,
    MOVES: "e2f1 b8c7 f2g4 a7a6 f1g1 d8g5 g1g2 a4c2 h1h3 c2f5",
    DEPTH: 51,
  },
  {
    FEN: "2r1nrk1/pp3pBp/1b1p4/3p2q1/2n1P3/3B1P2/P3NP1P/R5RK w - -",
    EVAL: -494,
    MOVES: "g1g5 e8g7 a1g1 g8h8 g5g7 c4e5 d3b1 b6f2 e4d5 f2g1",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkb1r/ppp1pppp/8/3pP3/4n3/3P4/PPP1QPPP/RNB1KBNR b KQkq -",
    EVAL: 58,
    MOVES: "e4c5 d3d4 c5a6 b1c3 c7c5 d4c5 e7e6 f2f4 a6c5 c1e3",
    DEPTH: 30,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/3p4/3N4/2N5/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 39,
    MOVES: "g8f6 c1g5 f8e7 e2e3 e8h8 f1e2 b8c6 e1h1 h7h6 g5f4",
    DEPTH: 36,
  },
  {
    FEN: "rn2kb1r/ppq1pppp/5n2/3p3b/3P4/1QPB3P/PP3PP1/RNB1K1NR w KQkq -",
    EVAL: 33,
    MOVES: "g2g4 h5g6 d3g6 h7g6 g4g5 f6d7 b3d5 b8c6 g1e2 e7e5",
    DEPTH: 41,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n5/3p4/3N4/2N5/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 89,
    MOVES: "c1f4 a7a6 e2e3 g8f6 f1e2 f8d6 f4d6 d8d6 e1h1 e8h8",
    DEPTH: 37,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/n4n2/3P4/8/P1N2N2/1P2PPPP/R1BQKB1R b KQkq -",
    EVAL: 123,
    MOVES: "f8c5 b2b4 c5b6 e2e4 e8h8 d5d6 c7d6 f1d3 a6c7 e1h1",
    DEPTH: 25,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R w KQkq -",
    EVAL: 32,
    MOVES: "d1f3 e7e6 g2g3 b8d7 d2d4 d8b6 f3d3 f8b4 f1g2 d5e4",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP1B2/4P3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: 50,
    MOVES: "g8f6 g1f3 f8d6 f4d6 d8d6 b1c3 e8h8 a1c1 b7b6 c4d5",
    DEPTH: 32,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n5/3p4/3N1B2/2N5/PP2PPPP/R2QKB1R b KQkq -",
    EVAL: 56,
    MOVES: "a7a6 e2e3 g8f6 f1e2 f8d6 f4d6 d8d6 e1h1 e8h8 a1c1",
    DEPTH: 33,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5Q2/PPPP1PPP/RNB1K1NR b KQkq -",
    EVAL: -31,
    MOVES: "c7c6 b1c3 b7b5 c4b3 a7a5 a2a3 f8c5 f3g3 e8h8 g1f3",
    DEPTH: 49,
  },
  {
    FEN: "r1bqkb1r/ppp1pppp/2n5/8/8/5N2/PPPPQPPP/RNB2RK1 w kq -",
    EVAL: 26,
    MOVES: "c2c3 e7e6 d2d4 f8d6 c3c4 e8h8 b1c3 f8e8 f1d1 f7f6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4p3/2B5/5N2/PPPP1PPP/RNBQR1K1 b kq -",
    EVAL: 25,
    MOVES: "d7d5 f3e5 c8e6 c4f1 f8e7 d2d4 e8h8 b1d2 b8d7 c2c3",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R b KQkq -",
    EVAL: 21,
    MOVES: "e7e6 d2d4 g8f6 a2a3 d5e4 f3e3 d8a5 c1d2 f8b4 c3e4",
    DEPTH: 42,
  },
  {
    FEN: "r3k3/ppp2pp1/8/2bpP2P/4q3/1B1p1Q2/PPPP2P1/RNB4K b q -",
    EVAL: "M-5",
    MOVES: "e4h4 f3h3 h4e1 h1h2 c5g1 h2h1 g1f2 h1h2 e1g1",
    DEPTH: 245,
  },
  {
    FEN: "3rnrk1/pp3ppp/1b1p4/3p4/2n1P3/3B1P2/PB2NP1P/R5RK w - -",
    EVAL: -224,
    MOVES: "d3c4 d5c4 e2d4 b6d4 b2d4 a7a6 g1b1 b7b5 a2a4 d8b8",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/4p3/2B5/5N2/PPPP1PPP/RNBQR1K1 w kq -",
    EVAL: 54,
    MOVES: "d2d4 e5e4 c1g5 f8e7 g5f6 g7f6 e1e4 d6d5 c4d5 d8d5",
    DEPTH: 28,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/8/1Bpp4/3P4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 79,
    MOVES: "b8c6 e1g1 g8f6 f1e1 c8e6 c1g5 f8e7 d4c5 e7c5 g5f6",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: -13,
    MOVES: "e4e5 d7d5 c4b5 f6e4 f3d4 c8d7 b5c6 b7c6 e1h1 f8e7",
    DEPTH: 57,
  },
  {
    FEN: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5NP1/PPPP1P1P/RNBQKB1R b KQkq -",
    EVAL: -8,
    MOVES: "g7g6 f1g2 f8g7 e1h1 d7d6 d2d3 g8f6 b1c3 e8h8 c3d5",
    DEPTH: 48,
  },
  {
    FEN: "1k1b4/pp3p2/4p3/3pP3/b1pP1pN1/P1P2Prq/1P1RKQ2/4R3 b - -",
    EVAL: -743,
    MOVES: "d8h4 e1g1 b8c7 g1g3 f4g3 f2g1 g3g2 g4f2 h3g3 f2g4",
    DEPTH: 29,
  },
  {
    FEN: "r2qrbk1/5P1p/pn1p2p1/np4P1/3p4/5N2/PPB2PP1/R1BQR1K1 b - -",
    EVAL: 276,
    MOVES: "g8f7 e1e8 d8e8 d1d4 a5c4 c2b3 d6d5 b3c4 b6c4 d4d5",
    DEPTH: 22,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 21,
    MOVES: "f8c5 e1h1 e8h8 b1c3 h7h6 h2h3 a7a6 c1e3 d7d6 e3c5",
    DEPTH: 54,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/2p2n2/3pP3/8/2N2Q1P/PPPP1PP1/R1B1KB1R b KQkq -",
    EVAL: -26,
    MOVES: "f6d7 f3g3 e7e6 b2b4 g7g6 a1b1 a7a6 f1e2 c6c5 b4b5",
    DEPTH: 41,
  },
  {
    FEN: "r2qkb1r/ppp1pppp/2n5/8/6b1/3P1N2/PPP1QPPP/RNB2RK1 w kq -",
    EVAL: 0,
    MOVES: "b1d2 d8d5 c2c4 d5d7 h2h3 g4h5 d2b3 e8a8 d3d4 d7f5",
    DEPTH: 23,
  },
  {
    FEN: "rnbqk2r/pp1ppp1p/6p1/2P1P3/2B1n3/2P5/P1P2PPP/R1BQK1NR b KQkq -",
    EVAL: 95,
    MOVES: "e4c5 g1f3 b8c6 e1h1 d7d6 c1h6 d6e5 d1e2 d8c7 c4f7",
    DEPTH: 42,
  },
  {
    FEN: "r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: 24,
    MOVES: "d2d4 c5d4 f3d4 g8f6 b5d3 d7c5 b1c3 g7g6 d4b3 c5d3",
    DEPTH: 48,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/2p2n2/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R w KQkq -",
    EVAL: 24,
    MOVES: "g2g3 e7e6 f1g2 d5e4 c3e4 f6e4 f3e4 d8d5 b2b3 d5e4",
    DEPTH: 45,
  },
  {
    FEN: "r1bqkbnr/1ppp2pp/p1B2p2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: -8,
    MOVES: "d7c6 d2d4 e5d4 d1d4 d8d4 f3d4 c8d7 f2f3 e8a8 c1e3",
    DEPTH: 31,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/2B5/3P1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 36,
    MOVES: "e1h1 f8e7 f1e1 f7f6 c2c3 c8g4 d3d4 d8d7 d4e5 e8a8",
    DEPTH: 40,
  },
  {
    FEN: "r1bqk2r/pp1pbppp/2n1pn2/2p5/4P3/3P1NP1/PPP2PBP/RNBQ1RK1 b kq -",
    EVAL: 8,
    MOVES: "d7d5 c1f4 e8h8 f3e5 c6e5 f4e5 f6d7 e5f4 e7f6 c2c3",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2n5/3np3/2B5/3P1N2/PPPB1PPP/RN1QK2R b KQkq -",
    EVAL: -21,
    MOVES: "d5b6 c4b3 c8g4 h2h3 g4h5 b1c3 d8e7 c3d5 b6d5 b3d5",
    DEPTH: 36,
  },
  {
    FEN: "2rqnrk1/pp3p1p/1b1p2pQ/3p4/2n1P3/3B1P2/PB2NP1P/R5RK w - -",
    EVAL: -36,
    MOVES: "d3c4 c8c4 e2f4 b6d4 b2d4 c4d4 f4d5 e8c7 d5e3 d8f6",
    DEPTH: 35,
  },
  {
    FEN: "r1bqkb1r/ppp2ppp/2p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -15,
    MOVES: "b1c3 f8d6 h2h3 d8e7 d2d4 f6d7 e1h1 b7b6 d4d5 e8h8",
    DEPTH: 47,
  },
  {
    FEN: "rn1qk2r/1p3pp1/p2p4/3Ppb1p/7b/1N2BP2/PPPQB1PP/R3K2R w KQkq -",
    EVAL: 52,
    MOVES: "g2g3 h4e7 e1a1 b8d7 c1b1 a8c8 d1c1 e8h8 h2h3 f5g6",
    DEPTH: 46,
  },
  {
    FEN: "r2qRb2/5k1p/pn1p2p1/np4P1/3p4/5N2/PPB2PP1/R1BQ2K1 b - -",
    EVAL: 169,
    MOVES: "d8e8 d1d4 a5c4 c2b3 d6d5 b3c4 b6c4 d4d5 f7g7 d5b7",
    DEPTH: 21,
  },
  {
    FEN: "r5kr/1q3p1p/p1N4B/1pp2p2/4R3/1PPP4/1P3PPP/R5K1 w - -",
    EVAL: "M7",
    MOVES: "c6e7 b7e7 e4e7 f7f6 a1e1 a8d8 e1e6 d8d3 e7g7 g8f8",
    DEPTH: 99,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/5n2/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 37,
    MOVES: "c1g5 c8e6 g5f6 d8f6 e2e4 d5e4 c3e4 f6f4 f1b5 b8c6",
    DEPTH: 52,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2p2n2/4N3/3qP3/8/PPPP1PPP/RNBQK2R w KQkq -",
    EVAL: -64,
    MOVES: "e5f3 d4e4 d1e2 e4e2 e1e2 f6d5 h1e1 f7f6 d2d3 e8f7",
    DEPTH: 53,
  },
  {
    FEN: "r4rk1/4ppbp/3p1np1/pq1P4/1p1B4/1B3P2/PPP1Q1PP/1K1RR3 b - -",
    EVAL: 0,
    MOVES: "b5e2 e1e2 a5a4 b3c4 f8c8 b2b3 c8c7 c4b5 a4b3 c2b3",
    DEPTH: 44,
  },
  {
    FEN: "r1bqk1nr/pppp1ppp/2n5/2b5/2B1P3/2N2N2/PP3PPP/R1BQK2R b KQkq -",
    EVAL: -7,
    MOVES: "d7d6 e1h1 g8f6 c1g5 h7h6 g5f6 d8f6 c3d5 f6d8 b2b4",
    DEPTH: 50,
  },
  {
    FEN: "r3k2r/pp3pp1/2b1p3/3pP2n/1P1P3P/3B1N2/P2K1P2/2R3R1 b kq -",
    EVAL: 92,
    MOVES: "a7a6 c1c5 e8d7 f3g5 d7e7 d2e3 g7g6 a2a4 h5g7 g1h1",
    DEPTH: 29,
  },
  {
    FEN: "rnbqk2r/pp2bppp/5n2/2pp2B1/3P4/2N2N2/PP2PPPP/R2QKB1R w KQkq -",
    EVAL: 72,
    MOVES: "d4c5 e8h8 e2e3 h7h6 g5h4 c8e6 f1b5 f6e4 h4e7 d8e7",
    DEPTH: 43,
  },
  {
    FEN: "r1b1kb1r/ppp2ppp/2p2n2/8/8/5N2/PPPPKPPP/RNB4R b kq -",
    EVAL: -64,
    MOVES: "f6d5 h1e1 f7f6 d2d3 c8d7 c1d2 e8f7 b1c3 b7b6 e2f1",
    DEPTH: 44,
  },
  {
    FEN: "rn1qk2r/1p3pp1/p2p4/3Ppb1p/7b/1N2BPP1/PPPQB2P/R3K2R b KQkq -",
    EVAL: 35,
    MOVES: "h4e7 e1a1 a6a5 e2d3 f5d7 c1b1 a5a4 b3c1 d8a5 d2a5",
    DEPTH: 29,
  },
  {
    FEN: "rnbqkb1r/pppppppp/5n2/8/3P4/2P5/PP2PPPP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 g1f3 e7e6 c1f4 f8d6 e2e3 e8h8 f1d3 c7c5 f3e5",
    DEPTH: 50,
  },
  {
    FEN: "r3k2r/1p3pp1/p1b1p3/3pP2n/PP1P3P/3B1N2/3K1P2/2R3R1 b kq -",
    EVAL: 60,
    MOVES: "g7g6 d2e3 e8e7 c1c3 h5g7 g1c1 h8d8 c1a1 d8b8 c3c2",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/3p4/4PP2/3P4/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d5e4 d3e4 d8d1 e1d1 g8f6 e4e5 f6d5 g1f3 c8f5 f3d4",
    DEPTH: 48,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N1B3/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: -98,
    MOVES: "g8f6 f2f3 c8f5 f3e4 f6e4 g1f3 b8d7 c3e4 f5e4 f1d3",
    DEPTH: 35,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "c5d4 c3d4 g8h6 b1c3 h6f5 c3a4 b6a5 a4c3",
    DEPTH: 64,
  },
  {
    FEN: "r2k3r/1p3pp1/p1b1p3/3pP2n/PP1P3P/3B1N2/3K1P2/2R3R1 w - -",
    EVAL: 165,
    MOVES: "b4b5 a6b5 a4b5 c6d7 b5b6 d7c6 c1a1 d8e7 d2e3 h8c8",
    DEPTH: 36,
  },
  {
    FEN: "r5kr/4Rp1p/p1q4B/1pp2p2/8/1PPP4/1P3PPP/R5K1 w - -",
    EVAL: -638,
    MOVES: "h6g5 h7h6 g5f4 h6h5 h2h4 h8h7 e7e3 h7g7 g2g3 g7g4",
    DEPTH: 24,
  },
  {
    FEN: "r7/5K1k/4RP2/8/8/8/8/8 b - -",
    EVAL: 0,
    MOVES: "h7h6 e6d6 h6h7 d6d3 a8a7 f7e6 a7a6 d3d6 a6a2 e6e7",
    DEPTH: 71,
  },
  {
    FEN: "rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR w KQkq -",
    EVAL: 25,
    MOVES: "e2e4 b7b5 a2a4 b5b4 c3b1 c8a6 d1c2 d8d4 g1f3 d4d7",
    DEPTH: 49,
  },
  {
    FEN: "r2qk2r/ppp2ppp/2pb1n2/4p3/4P1b1/3P1N2/PPPN1PPP/R1BQK2R w KQkq -",
    EVAL: 29,
    MOVES: "h2h3 g4e6 f3g5 e6c8 d2c4 e8h8 e1h1 h7h6 g5f3 f8e8",
    DEPTH: 28,
  },
  {
    FEN: "rnbqk1nr/ppppppbp/6p1/8/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq -",
    EVAL: 0,
    MOVES: "d7d5 c2c3 e7e5 d4e5 g7e5 d1d5 d8d5 e4d5 g8e7 d5d6",
    DEPTH: 30,
  },
  {
    FEN: "r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -",
    EVAL: 37,
    MOVES: "d4c5 d5d4 c3a4 f8c5 a4c5 d8a5 c1d2 a5c5 a1c1 c5b6",
    DEPTH: 52,
  },
  {
    FEN: "rnbq1rk1/ppp1ppbp/3p1np1/8/2B1P3/2NP1N2/PPPB1PPP/R2QK2R b KQ -",
    EVAL: -32,
    MOVES: "c7c5 e1h1 b8c6 h2h3 a7a6 c4b3 b7b5 d1c1 e7e6 d2g5",
    DEPTH: 29,
  },
  {
    FEN: "r1bqkb1r/pp1npppp/3p1n2/1Bp5/3PP3/5N2/PPP2PPP/RNBQ1RK1 b kq -",
    EVAL: 0,
    MOVES: "c5d4 d1d4 e7e6 b1c3 a7a6 b5d7 c8d7 c1g5 f8e7 g5f6",
    DEPTH: 36,
  },
  {
    FEN: "rn1qk2r/1p2bpp1/p2p4/3Ppb1p/8/1N2BPP1/PPPQB2P/R3K2R w KQkq -",
    EVAL: 42,
    MOVES: "e1a1 b8d7 c1b1 e8h8 h2h3 a8c8 d1c1 h5h4 g3h4 e7h4",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p4/8/2BNn3/8/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "d1h5 e7e6 c4b5 b8d7 d4e6 e4f6 b5d7 c8d7 e6d8 f6h5",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR b KQkq -",
    EVAL: 17,
    MOVES: "d8b6 a2a3 b8c6 g1f3 g8h6 f1d3 c5d4 e1h1 c8d7 b2b4",
    DEPTH: 52,
  },
  {
    FEN: "r1bk1bnr/ppp2ppp/2n5/4p3/4P3/5N2/PPP2PPP/RNB1KB1R w KQ -",
    EVAL: 62,
    MOVES: "b1c3 a7a6 c1e3 g8f6 e3g5 h7h6 e1a1 f8d6 g5f6 g7f6",
    DEPTH: 44,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/3pP3/3p4/2P2N2/PP2BPPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "c3d4 g8h6 e2d3 c8d7 d3c2 g7g5 c1g5 b6b2 b1d2 c6b4",
    DEPTH: 56,
  },
  {
    FEN: "r1b1kbnr/pp1npppp/2p5/8/4PP2/8/PPP3PP/RNBK1BNR w kq -",
    EVAL: 43,
    MOVES: "g1f3 g7g6 b1c3 f8g7 f1d3 e7e5 h2h3 g8e7 a2a4 a7a5",
    DEPTH: 24,
  },
  {
    FEN: "rn1qkb1r/pp2pppp/2p2n2/3p4/3PP3/2N2Q1P/PPP2PP1/R1B1KB1R b KQkq -",
    EVAL: 22,
    MOVES: "e7e6 e4d5 c6d5 f1d3 b8c6 c3e2 g7g6 c1g5 f8g7 c2c3",
    DEPTH: 41,
  },
  {
    FEN: "r1b1kbnr/pp3ppp/1qn1p3/3pP3/3P4/5N2/PP2BPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "g8h6 e2d3 c8d7 d3c2 g7g5 c1g5 b6b2 b1d2 c6b4 c2a4",
    DEPTH: 53,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R w KQkq -",
    EVAL: 50,
    MOVES: "f1g2 f8e7 d4c5 e7c5 e1h1 e8h8 c3a4 c5e7 c1e3 c8g4",
    DEPTH: 42,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/3p4/7Q/2BNn3/8/PPP2PPP/RNB1K2R b KQkq -",
    EVAL: 0,
    MOVES: "e7e6 c4b5 b8d7 d4e6 e4f6 e6d8 f6h5 b5d7 c8d7 d8b7",
    DEPTH: 45,
  },
  {
    FEN: "4r1kr/1q3p1p/p1N2p1B/1pp5/8/1PPP4/1P3PPP/R5K1 w - -",
    EVAL: -853,
    MOVES: "c6a5 b7d5 h2h3 d5d3 b3b4 c5b4 c3b4 f6f5 h6e3 d3d5",
    DEPTH: 36,
  },
  {
    FEN: "r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R b KQkq -",
    EVAL: 41,
    MOVES: "c5d4 f3d4 f8c5 d4b3 c5b4 c1g5 b4c3 b2c3 e8h8 e1h1",
    DEPTH: 47,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3pp3/7Q/2BNn3/8/PPP2PPP/RNB1K2R w KQkq -",
    EVAL: 0,
    MOVES: "c4b5 b8d7 d4e6 e4f6 b5d7 c8d7 e6d8 f6h5 d8b7 d7c6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/1N1p4/3pPB2/8/PPP2PPP/R2QKBNR b KQkq -",
    EVAL: 0,
    MOVES: "f8b4 c2c3 d4c3 b2c3 b4a5 d1a4 b8c6 a4a3 d8e7 f4d6",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkb1r/pp3ppp/3pp3/1B5Q/3Nn3/8/PPP2PPP/RNB1K2R b KQkq -",
    EVAL: 0,
    MOVES: "b8d7 d4e6 e4f6 e6d8 f6h5 b5d7 c8d7 d8b7 d7c6 b7a5",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pppppppp/n4n2/3P4/2P5/2N2N2/PP2PPPP/R1BQKB1R b KQkq -",
    EVAL: 79,
    MOVES: "e7e6 a2a3 e6d5 c4d5 f8d6 e2e4 e8h8 f1a6 b7a6 e1h1",
    DEPTH: 24,
  },
  {
    FEN: "rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R b KQ -",
    EVAL: 13,
    MOVES: "a7a5 c1e3 b8a6 e1h1 a6c5 d1c2 f6d7 f3d2 f7f5 f2f3",
    DEPTH: 45,
  },
  {
    FEN: "rn1qkb1r/pp3ppp/2p1pn2/3p4/3PP3/2N2Q1P/PPP2PP1/R1B1KB1R w KQkq -",
    EVAL: 28,
    MOVES: "e4d5 c6d5 f1d3 b8c6 c3e2 g7g6 c2c3 f8g7 c1g5 e8h8",
    DEPTH: 43,
  },
  {
    FEN: "r1bqkb1r/pp1npppp/3p4/1Bp5/3Pn3/5N2/PPP2PPP/RNBQR1K1 b kq -",
    EVAL: 146,
    MOVES: "e4f6 d4c5 d6c5 f3g5 e7e6 b1c3 f8e7 d1e2 d8b6 c1f4",
    DEPTH: 30,
  },
  {
    FEN: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/3P1Q2/PPP2PPP/RNB1K1NR b KQkq -",
    EVAL: -17,
    MOVES: "c7c6 b1c3 b7b5 c4b3 a7a5 a2a4 b5b4 c3d1 d7d5 c1g5",
    DEPTH: 34,
  },
  {
    FEN: "rnq1kb1r/pp2pppp/5n2/3p4/3P2b1/1QPB4/PP3PPP/RNB1K1NR w KQkq -",
    EVAL: 92,
    MOVES: "h2h3 g4h5 g2g4 h5g6 d3g6 h7g6 g4g5 f6d7 b3d5 b8c6",
    DEPTH: 47,
  },
  {
    FEN: "r1bk1bnr/ppp2ppp/2n5/1B2p3/4P3/5N2/PPP2PPP/RNB1K2R b KQ -",
    EVAL: 50,
    MOVES: "f8d6 b5c6 b7c6 b1c3 d8e8 f3d2 g8f6 d2c4 c8e6 c4d6",
    DEPTH: 39,
  },
  {
    FEN: "rnb1kb1r/pp2pppp/2p2n2/8/4PP2/8/PPP3PP/RNBK1BNR w kq -",
    EVAL: 0,
    MOVES: "e4e5 f6d5 g1f3 c8f5 f1d3 f5d3 c2d3 b8a6 d1e2 e7e6",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R w KQ -",
    EVAL: 34,
    MOVES: "c1g5 h7h6 g5h4 b8a6 f3d2 c8d7 f2f3 a6c5 d1c2 d8b8",
    DEPTH: 56,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/2p5/4p3/P1pP4/2N5/1P2PPPP/R1BQKBNR w KQkq -",
    EVAL: 0,
    MOVES: "d4e5 d8d1 e1d1 c8e6 e2e4 f7f6 f2f4 f6e5 f4f5 e6f7",
    DEPTH: 41,
  },
  {
    FEN: "rnq1kb1r/pp2pppp/5n2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR b KQkq -",
    EVAL: 26,
    MOVES: "b8c6 h2h3 g4h5 b1d2 e7e6 g1f3 f8e7 e1h1 h5g6 d3e2",
    DEPTH: 29,
  },
  {
    FEN: "rnbq1rk1/1pp2pbp/3p1np1/p2Pp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R b KQ -",
    EVAL: 31,
    MOVES: "h7h6 g5h4 g6g5 h4g3 f6h5 f3d2 h5f4 e1h1 b8a6 h2h3",
    DEPTH: 40,
  },
  {
    FEN: "rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R b KQkq -",
    EVAL: 19,
    MOVES: "c6d5 b1c3 b8c6 c1f4 c8f5 e2e3 e7e6 d1b3 f8b4 f1b5",
    DEPTH: 48,
  },
  {
    FEN: "r5kr/1q5p/p1N1pp1B/1pp5/8/1PPP4/1P3PPP/R5K1 w - -",
    EVAL: -567,
    MOVES: "c6a5 b7c8 h6e3 g8f7 a1e1 h8g8 g2g3 g8d8 e1d1 e6e5",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/3p1b2/3P1B2/4P3/PPP2PPP/RN1QKBNR w KQkq -",
    EVAL: 35,
    MOVES: "f1d3 f5d3 c2d3 e7e6 d1b3 b7b6 b1c3 f8d6 f4d6 d8d6",
    DEPTH: 46,
  },
  {
    FEN: "r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N2N2/PP2BPPP/R2Q1RK1 w - -",
    EVAL: -3,
    MOVES: "f3d2 c8d7 c3b5 e8c8 a2a3 a6c5 h4f6 g7f6 b2b4 d7b5",
    DEPTH: 34,
  },
  {
    FEN: "r1bqkb1r/pp1npppp/5n2/1Bp5/8/5N2/PPP2PPP/RNBQR1K1 w kq -",
    EVAL: 182,
    MOVES: "f3g5 e7e6 b1c3 f8e7 d1e2 d8b6 a2a4 e8h8 a4a5 b6d6",
    DEPTH: 38,
  },
  {
    FEN: "rn1qkb1r/ppp1pppp/5n2/3p1b2/2PP1B2/4P3/PP3PPP/RN1QKBNR b KQkq -",
    EVAL: 7,
    MOVES: "e7e6 g1f3 f8d6 f4d6 c7d6 d1b3 f5b1 a1b1 d8a5 f3d2",
    DEPTH: 46,
  },
  {
    FEN: "r1bq1rk1/pp2bppp/2nppn2/2p5/4P3/3PBNP1/PPP2PBP/RN1Q1RK1 w - -",
    EVAL: -2,
    MOVES: "a2a4 a8b8 b1a3 a7a6 c2c3 h7h6 a3c2 b7b5 a4b5 a6b5",
    DEPTH: 22,
  },
  {
    FEN: "r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "h7h6 g5f3 e5e4 f3e5 f8d6 d2d4 e4d3 e5d3 e8h8 e1h1",
    DEPTH: 54,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2np4/2p5/4P3/5N1P/PPPP1PP1/RNBQKB1R w KQkq -",
    EVAL: 12,
    MOVES: "d2d4 c5d4 f3d4 g8f6 d4c6 b7c6 f1d3 g7g6 b1c3 f8g7",
    DEPTH: 37,
  },
  {
    FEN: "r2k1bnr/ppp3pp/2n1p3/4p3/4P3/5N2/PPP2PPP/RNB1K2R w KQ -",
    EVAL: 135,
    MOVES: "c1e3 g8f6 b1d2 f6d7 f3g5 d8e7 a2a4 e7f6 g5f3 f8c5",
    DEPTH: 49,
  },
  {
    FEN: "rnbq1rk1/pppp1ppp/5n2/2b1p3/2B1P3/3P2Q1/PPP2PPP/RNB1K1NR b KQ -",
    EVAL: -55,
    MOVES: "b7b5 c4b3 a7a5 a2a4 b5a4 b3a4 d7d5 e4d5 f6g4 g1h3",
    DEPTH: 28,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1b2/2PP1B2/4P3/PP3PPP/RN1QKBNR w KQkq -",
    EVAL: 33,
    MOVES: "g1f3 f8d6 f4g3 d6g3 h2g3 c7c6 d1b3 d8c7 b1c3 e8h8",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/n3pn2/3P4/2P5/P1N2N2/1P2PPPP/R1BQKB1R b KQkq -",
    EVAL: 98,
    MOVES: "d7d6 g2g3 e6d5 c4d5 g7g6 f1g2 f8g7 b2b4 e8h8 e1h1",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N1P/PPPP1PP1/RNBQK2R b KQkq -",
    EVAL: 4,
    MOVES: "c8d7 c2c3 g8f6 d2d3 e7e5 e1h1 f8e7 f1e1 e8h8 b1d2",
    DEPTH: 34,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1b3/8/P1pP4/2N5/1P3PPP/R1BQKBNR w KQkq -",
    EVAL: -85,
    MOVES: "g1f3 f8b4 f3e5 d8e7 c1e3 b8d7 f1c4 d7e5 c4e6 e7e6",
    DEPTH: 38,
  },
  {
    FEN: "r1b1kb1r/pp2nppp/1qn1p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R w KQkq -",
    EVAL: 47,
    MOVES: "d4c5 b6c5 b2b4 c5b6 b4b5 c6a5 e1h1 e7g6 e2d3 f8c5",
    DEPTH: 48,
  },
  {
    FEN: "rn1qk1nr/pp3ppp/2p1b3/8/PbpP4/2N2N2/1P3PPP/R1BQKB1R w KQkq -",
    EVAL: -86,
    MOVES: "f3e5 d8e7 c1e3 b8d7 f1c4 d7e5 c4e6 e7e6 d4e5 g8e7",
    DEPTH: 34,
  },
  {
    FEN: "rn1qkb1r/ppp2ppp/4pn2/3p1b2/2PP1B2/1Q2P3/PP3PPP/RN2KBNR b KQkq -",
    EVAL: 0,
    MOVES: "b8c6 b1c3 f8b4 f4g5 a7a5 b3a4 e8h8 a2a3 h7h6 g5f6",
    DEPTH: 44,
  },
  {
    FEN: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/3B1N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 46,
    MOVES: "e7e5 d4e5 d6e5 e1h1 b8c6 d1e2 g8f6 d3b5 e8h8 b5c6",
    DEPTH: 48,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3p1n2/2b1p3/2B1P3/3P2Q1/PPP2PPP/RNB1K1NR w KQ -",
    EVAL: -6,
    MOVES: "c1g5 b8d7 g1f3 c7c6 a2a4 f8e8 e1h1 d7f8 b1d2 f8g6",
    DEPTH: 25,
  },
  {
    FEN: "r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2Q1RK1 b - -",
    EVAL: 0,
    MOVES: "a6c5 h2h3 f6h7 c3b5 e8d7 f2f3 b7b6 b2b3 h6h5 a2a3",
    DEPTH: 44,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p2n1p/n3p3/8/7N/PPPPBPPP/RNBQK2R b KQkq -",
    EVAL: -10,
    MOVES: "a5b7 d2d3 f8c5 b1c3 e8h8 e1h1 c8h3 g2h3 b7d6 e2f3",
    DEPTH: 41,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/2p5/4p3/4PP2/8/PPP3PP/RNBK1BNR w kq -",
    EVAL: 54,
    MOVES: "f4e5 b8d7 g1f3 f7f6 e5f6 g8f6 b1c3 d7c5 e4e5 f6g4",
    DEPTH: 37,
  },
  {
    FEN: "rnq1kb1r/pp3ppp/4pn2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR w KQkq -",
    EVAL: 24,
    MOVES: "a2a4 b8c6 h2h3 g4h5 b1d2 c8d7 g1f3 f8d6 f4d6 d7d6",
    DEPTH: 35,
  },
  {
    FEN: "r1b1kbnr/pp2pppp/2qp4/2p5/4P3/3P1N1P/PPP2PP1/RNBQK2R b KQkq -",
    EVAL: 13,
    MOVES: "e7e6 e1h1 b7b6 c2c4 g8f6 a2a4 f8e7 a4a5 e8h8 c1f4",
    DEPTH: 24,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R w KQkq -",
    EVAL: 0,
    MOVES: "g5f3 e5e4 f3e5 f8d6 d2d4 e4d3 e5d3 e8h8 e1h1 d8c7",
    DEPTH: 51,
  },
  {
    FEN: "r1bqkb1r/p4pp1/2p2n1p/n3p3/8/5N2/PPPPBPPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "e5e4 f3e5 f8c5 e1h1 d8d4 e5g4 c8g4 e2g4 e4e3 g4f3",
    DEPTH: 42,
  },
  {
    FEN: "r1bqk1nr/pppnppbp/3p2p1/8/3PP3/3B1N2/PPP2PPP/RNBQK2R w KQkq -",
    EVAL: 72,
    MOVES: "b1c3 c7c6 d1e2 e7e5 d4e5 d7e5 f3e5 d6e5 c1e3 g8f6",
    DEPTH: 46,
  },
  {
    FEN: "r1b1kb1r/pp2nppp/1qn1p3/2ppP3/3P4/N1P2N2/PP2BPPP/R1BQK2R b KQkq -",
    EVAL: 26,
    MOVES: "c5d4 c3d4 e7f5 a3c2 c8d7 e1h1 a7a5 g1h1 a8c8 a2a4",
    DEPTH: 41,
  },
  {
    FEN: "rnbq1rk1/1p3ppp/2pp1n2/p1b1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1 w - -",
    EVAL: 22,
    MOVES: "d3d4 e5d4 f3d4 a5a4 e2e3 a4a3 c3a4 c5d4 e3d4 a3b2",
    DEPTH: 51,
  },
  {
    FEN: "rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -",
    EVAL: 49,
    MOVES: "e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1h1 e8h8 c2c4 c7c6",
    DEPTH: 54,
  },
  {
    FEN: "r2qrb2/6pk/pn1p2p1/np2P3/3p4/5N2/PP3PP1/R1BQR1K1 w - -",
    EVAL: 653,
    MOVES: "f3g5 h7g8 d1f3 d8g5 c1g5 d6e5 a1c1 f8d6 b2b3 e8e6",
    DEPTH: 54,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3p3B/2b1p2n/2B1P3/3P2Q1/PPP2PPP/RN2K1NR w KQ -",
    EVAL: -41,
    MOVES: "g3g5 d8g5 h6g5 b8c6 g5e3 c6d4 e1d2 b7b5 c4d5 a8b8",
    DEPTH: 23,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/5n2/4p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -",
    EVAL: -21,
    MOVES: "c7c6 d2d3 a7a5 a2a3 f8c5 h2h3 d7d6 f3d4 c5d4 a4b3",
    DEPTH: 50,
  },
  {
    FEN: "r4k1r/1q3p2/p1N2p2/1pp4p/4R3/1PPP4/1P3PPP/R1B3K1 w - -",
    EVAL: -237,
    MOVES: "c6e7 a8d8 f2f3 d8d3 c1e3 b5b4 e3c5 b4c3 b2c3 b7b3",
    DEPTH: 26,
  },
  {
    FEN: "r1bqkb1r/pppp1ppp/2n2n2/4P3/2Bp4/5N2/PPP2PPP/RNBQK2R b KQkq -",
    EVAL: 0,
    MOVES: "f6e4 d1e2 e4c5 c2c3 d4d3 e2e3 f8e7 b2b4 c5e6 e1h1",
    DEPTH: 58,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPPBPP1/R1B1K2R b KQkq -",
    EVAL: 14,
    MOVES: "f8c5 e1h1 b8d7 e4d5 c6d5 f1d1 g8f6 d2d4 c5e7 e2d3",
    DEPTH: 54,
  },
  {
    FEN: "r1bqk2r/p4pp1/2p2n1p/n1b1p3/8/7N/PPPPBPPP/RNBQ1RK1 b kq -",
    EVAL: -7,
    MOVES: "e8h8 d2d3 a5b7 g1h1 c8h3 g2h3 b7d6 b1d2 f8e8 e2f3",
    DEPTH: 46,
  },
  {
    FEN: "rnb1kbnr/pp3ppp/2p5/4P3/4P3/8/PPP3PP/RNBK1BNR b kq -",
    EVAL: 36,
    MOVES: "f8c5 c1f4 g8e7 b1d2 e7g6 f4g3 c8g4 f1e2 h7h5 h2h3",
    DEPTH: 40,
  },
  {
    FEN: "rn1qkbnr/pp3ppp/2p1p3/8/3pP3/2N2Q1P/PPPPBPP1/R1B1K2R w KQkq -",
    EVAL: 55,
    MOVES: "c3b1 f8d6 d2d3 b8d7 h3h4 g8f6 b1d2 d7e5 f3h3 h7h5",
    DEPTH: 46,
  },
  {
    FEN: "r2qkb1r/pQp2ppp/2n1pn2/3p1b2/2PP1B2/4P3/PP3PPP/RN2KBNR b KQkq -",
    EVAL: -81,
    MOVES: "c6b4 b1a3 d5c4 f1c4 f5e4 d4d5 e4d5 c4d5 d8d5 b7d5",
    DEPTH: 32,
  },
  {
    FEN: "rnbqkbnr/pp3ppp/4p3/2pp4/3P1B2/2N5/PPP1PPPP/R2QKBNR w KQkq -",
    EVAL: 5,
    MOVES: "e2e4 c5d4 d1d4 b8c6 d4a4 f8b4 e4d5 e6d5 f1b5 b4c3",
    DEPTH: 58,
  },
  {
    FEN: "r2qrb2/6pk/pn1p2p1/np2P1N1/3p4/8/PP3PP1/R1BQR1K1 b - -",
    EVAL: 638,
    MOVES: "h7g8 d1f3 d8g5 c1g5 d6e5 a1c1 a5c4 f3c6 a8c8 c6g6",
    DEPTH: 44,
  },
  {
    FEN: "rnbq1rk1/ppp2ppp/3p3B/2b1p2n/2B1P3/3P1Q2/PPP2PPP/RN2K1NR b KQ -",
    EVAL: -83,
    MOVES: "h5f6 h6g5 c8g4 f3g4 f6g4 g5d8 f8d8 g1h3 c7c6 c4b3",
    DEPTH: 24,
  },
  {
    FEN: "r2qkb1r/pQp2ppp/4pn2/3p1b2/1nPP1B2/4P3/PP3PPP/RN2KBNR w KQkq -",
    EVAL: -86,
    MOVES: "b1a3 d5c4 f1c4 f5e4 d4d5 e4d5 c4d5 d8d5 b7d5 f6d5",
    DEPTH: 30,
  },
  {
    FEN: "r2qrbk1/6p1/pn1p2p1/np2P1N1/3p4/8/PP3PP1/R1BQR1K1 w - -",
    EVAL: 638,
    MOVES: "d1f3 d8g5 c1g5 d6e5 a1c1 a5c4 f3c6 f8e7 g5e7 e8e7",
    DEPTH: 45,
  },
  {
    FEN: "r1bqk2r/pppp1ppp/5n2/4p3/2BnP3/8/PPP2PPP/RNBQ1RK1 w kq -",
    EVAL: -19,
    MOVES: "c1e3 d4c6 f2f4 d8e7 c4d5 e5f4 e3f4 d7d6 f4g5 c6e5",
    DEPTH: 47,
  },
  {
    FEN: "rnbqk2r/ppppppbp/5np1/8/3P1B1P/4P3/PPP2PP1/RN1QKBNR b KQkq -",
    EVAL: -12,
    MOVES: "c7c5 c2c3 c5d4 c3d4 d7d6 b1c3 e8h8 g1f3 a7a6 f3d2",
    DEPTH: 43,
  },
  {
    FEN: "rnb1k1nr/pp3ppp/2p5/2b1P3/4P3/8/PPP3PP/RNBK1BNR w kq -",
    EVAL: 39,
    MOVES: "g1f3 g8e7 b1d2 e8h8 d2c4 c8e6 c4d6 b7b6 c2c3 e7g6",
    DEPTH: 33,
  },
  {
    FEN: "r1b1kbnr/pppp1ppp/2n5/3NP3/5q2/5N2/PPP1PPPP/R2QKB1R b KQkq -",
    EVAL: 0,
    MOVES: "f4e4 d5c7 e8d8 c7a8 c6e5 d1d2 b7b6 e1a1 e5f3 e2f3",
    DEPTH: 47,
  },
  {
    FEN: "r2qkb1r/pQp2ppp/4pn2/3p1b2/1nPP1B2/N3P3/PP3PPP/R3KBNR b KQkq -",
    EVAL: -96,
    MOVES: "d5c4 f1c4 f5e4 d4d5 d8b8 b7b8 a8b8 f2f3 e4d5 e1f2",
    DEPTH: 31,
  },
  {
    FEN: "rnbqkb1r/pppp1ppp/5n2/4P3/3p4/5N2/PPP2PPP/RNBQKB1R b KQkq -",
    EVAL: 63,
    MOVES: "f6e4 d1d4 d7d5 e5d6 e4d6 d4f4 b8c6 b1c3 d6f5 c1d2",
    DEPTH: 50,
  },
  {
    FEN: "rnbq1rk1/ppppppbp/5np1/8/3P1B1P/4P3/PPP2PP1/RN1QKBNR w KQ -",
    EVAL: 22,
    MOVES: "h4h5 c7c5 b1c3 d7d5 h5g6 h7g6 d1d2 b8c6 e1a1 d8a5",
    DEPTH: 27,
  },
  {
    FEN: "r2qkb1r/pQp2ppp/4pn2/5b2/1npP1B2/N3P3/PP3PPP/R3KBNR w KQkq -",
    EVAL: -57,
    MOVES: "f1c4 f5e4 d4d5 e4d5 c4d5 d8d5 b7d5 f6d5 f4g3 b4d3",
    DEPTH: 30,
  },
];
