import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";

import { Helmet } from "react-helmet";
import { chessterms } from "../../../data/chessterms/chessterms";
import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";
import RotatingImagePuzzleHelp from "../../../help/RotatingImagePuzzleHelp";

const RotatingImagePuzzle = () => {
  const gridSize = 5; // 5x5 grid
  const defaultMaxWidth = 500; // Fixed max width for larger screens
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rotation, setRotation] = useState(Array(gridSize * gridSize).fill(0));
  const [isSolved, setIsSolved] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [imageName, setImageName] = useState("");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const [lockedOnCorrect, setLockedOnCorrect] = useState(false);
  const [playerTerm, setPlayerTerm] = useState(""); // New state for term
  const [playerDescription, setPlayerDescription] = useState("");
  const { t } = useTranslation("Games");

  // Responsive puzzle size based on screen width
  const [puzzleWidth, setPuzzleWidth] = useState(defaultMaxWidth);

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/rotatingimagepuzzle",
      title: "Chessboard Magic - Rotating Image Puzzle",
      description:
        "Rotate chess images in a 5x5 puzzle! Click squares to align pieces back to their correct positions and solve the puzzle",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  // Calculate the puzzle size based on window width
  useEffect(() => {
    const handleResize = () => {
      const newWidth =
        window.innerWidth < 600 ? window.innerWidth - 100 : defaultMaxWidth;
      setPuzzleWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Set initial width
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [defaultMaxWidth]);

  // Dynamically calculate the tile size based on the current puzzle width
  const tileSize = puzzleWidth / gridSize;

  const checkIfSolved = useCallback(() => {
    let allSolved = true;

    rotation.forEach((angle, index) => {
      const isPieceSolved = angle % 360 === 0;

      if (!isPieceSolved) {
        allSolved = false;
      }
    });

    return allSolved;
  }, [rotation]);

  const handleSolved = useCallback(() => {
    trackEvent("Games", "RotatingImagePuzzle-Success", "Rotate Image Puzzle");

    if (userData) {
      userData.Puzzles.RotatingImagePuzzle.Completed =
        (userData.Puzzles.RotatingImagePuzzle.Completed || 0) + 1;
      updateUserData(userRef, userData);
    }

    setCompleted(true);
    setPlayerTerm(selectedPlayer.Term); // Set term when game is completed
    setPlayerDescription(selectedPlayer.Description); // Set description when game is completed
  }, [userData, userRef, selectedPlayer.Term, selectedPlayer.Description]);

  useEffect(() => {
    if (checkIfSolved()) {
      setIsSolved(true);
      handleSolved();
    }
  }, [rotation, checkIfSolved, handleSolved]);

  const handleNewGame = useCallback(() => {
    setCompleted(false); // Reset completion
    setPlayerTerm(""); // Clear term
    setPlayerDescription(""); // Clear description
    setIsSolved(false); // Reset puzzle solved state

    const chessPlayers = chessterms.filter(
      (term) => term.Type === "P" || term.Type === "W"
    );
    const randomNumber = Math.floor(Math.random() * chessPlayers.length);
    const newSelectedChessPlayer = chessPlayers[randomNumber];

    setImageName(
      `${process.env.PUBLIC_URL}/img/players/${newSelectedChessPlayer.Key}.png`
    );
    setSelectedPlayer(newSelectedChessPlayer);

    const randomized = Array(gridSize * gridSize)
      .fill(0)
      .map(() => Math.floor(Math.random() * 4) * 90);
    setRotation(randomized);

    trackEvent("Games", "RotatingImagePuzzle-Play", "RotatingImage Puzzle");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.RotatingImagePuzzle) {
        userData.Puzzles.RotatingImagePuzzle.Played =
          (userData.Puzzles.RotatingImagePuzzle.Played || 0) + 1;
      } else {
        userData.Puzzles.RotatingImagePuzzle = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  }, [userData, userRef]);

  // Rotate a single piece by 90 degrees clockwise
  const rotatePiece = (index) => {
    if (lockedOnCorrect && rotation[index] % 360 === 0) {
      return;
    }
    const newRotation = [...rotation];
    newRotation[index] = newRotation[index] + 90;
    setRotation(newRotation);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("RotatingImagePuzzle.header.title")}
        subtitle={t("RotatingImagePuzzle.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Rotating Image Puzzle</title>
        <meta
          name="description"
          content="Rotate chess images in a 5x5 puzzle! Click squares to align pieces back to their correct positions and solve the puzzle"
        />
        <meta property="og:title" content="Rotating Image Puzzle" />
        <meta
          property="og:description"
          content="Rotate chess images in a 5x5 puzzle! Click squares to align pieces back to their correct positions and solve the puzzle"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/rotatingimagepuzzle.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/rotatingimagepuzzle`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Rotating Image Puzzle" />
        <meta
          name="twitter:description"
          content="Rotate chess images in a 5x5 puzzle! Click squares to align pieces back to their correct positions and solve the puzzle"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/rotatingimagepuzzle.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("RotatingImagePuzzle.buttons.play")}
        </Button>
        <FormControl variant="outlined" sx={{ marginRight: 2 }}>
          <InputLabel id="lock-on-correct-label">
            {t("RotatingImagePuzzle.labels.lock")}
          </InputLabel>
          <Select
            labelId="lock-on-correct-label"
            id="lock-on-correct"
            value={lockedOnCorrect}
            onChange={(e) => setLockedOnCorrect(e.target.value === "true")}
            label="Lock on Correct"
          >
            <MenuItem value="false">
              {t("RotatingImagePuzzle.labels.no")}
            </MenuItem>
            <MenuItem value="true">
              {t("RotatingImagePuzzle.labels.yes")}
            </MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("RotatingImagePuzzle.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("RotatingImagePuzzle.helpDetails.title")}
          content={<RotatingImagePuzzleHelp />}
        ></HelpModal>
      </Box>

      <Box sx={{ pt: 2 }}>
        {imageName && (
          <div
            style={{
              display: isSolved ? "block" : "grid", // Change layout to block when solved
              gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
              gap: isSolved ? "0px" : "2px", // Remove gaps between pieces when solved
              maxWidth: isSolved ? "500px" : `${puzzleWidth}px`, // 500px maxWidth for solved image
              width: window.innerWidth < 600 ? "90%" : "100%", // Make it 90% for smaller screens
              margin: "0", // Remove centering and align to the left
              textAlign: "left", // Ensure text or content aligns left
              justifyContent: "start", // Align the grid to the left
            }}
          >
            {isSolved ? (
              <img
                src={imageName}
                alt="Rotated puzzle solution"
                style={{
                  width: "100%", // Stretch to 100% width of its container
                  maxWidth: "500px", // Cap it at 500px on larger screens
                  height: "auto", // Maintain aspect ratio
                  display: "block", // Ensure the image is left-aligned
                  margin: "0", // Remove horizontal centering
                }}
              />
            ) : (
              Array.from({ length: gridSize * gridSize }).map((_, index) => {
                const x = (index % gridSize) * tileSize;
                const y = Math.floor(index / gridSize) * tileSize;

                return (
                  <div
                    key={index}
                    className="puzzle-piece"
                    onClick={() => rotatePiece(index)}
                    style={{
                      width: `${tileSize}px`,
                      height: `${tileSize}px`,
                      backgroundImage: `url(${imageName})`,
                      backgroundSize: `${tileSize * gridSize}px ${
                        tileSize * gridSize
                      }px`,
                      backgroundPosition: `-${x}px -${y}px`,
                      transform: `rotate(${rotation[index]}deg)`, // Keeps rotating continuously
                      transition: "transform 0.3s ease",
                      cursor:
                        rotation[index] % 360 === 0 && lockedOnCorrect
                          ? "default"
                          : "pointer", // Disable pointer if solved and lockedOnCorrect is true
                      border: isSolved ? "none" : "1px solid transparent", // Remove border when solved
                    }}
                  />
                );
              })
            )}
          </div>
        )}
      </Box>

      {completed && (
        <Box
          id="results"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "flex-start",
            maxWidth: "600px",
            paddingBottom: "20px",
            mt: 2,
          }}
        >
          <Typography variant="h5">{playerTerm}</Typography>
          <Typography>{playerDescription}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RotatingImagePuzzle;
