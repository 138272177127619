import { db } from "../config/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  //runTransaction,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";

// Function to create or update user data from auth
export const updateUserFromAuth = async (user, setUserData, setUserRef) => {
  if (user) {
    const { email, uid } = user;

    try {
      const userRef = doc(db, "Users", uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, {
          email: email,
          type: "Free",
          username: "NewUser",
          uid: uid,
          visibility: "Private",
          mode: "light",
          theme: "Modern Minimal",
          pieceset: "Maestro",
        });

        setUserRef(uid); // Set the userRef to UID
        setUserData({
          email: email,
          type: "Free",
          username: "NewUser",
          uid: uid,
          mode: "light",
          visibility: "Private",
          theme: "Modern Minimal",
          pieceset: "Maestro",
        });
      } else {
        setUserData(docSnap.data());
        setUserRef(uid); // Set the userRef to UID
      }
    } catch (error) {}
  } else {
    setUserData(null);
  }
};

// Function to update user data
export const updateUserData = async (uid, updatedData) => {
  try {
    if (typeof uid !== "string") {
      throw new TypeError("uid must be a string");
    }

    const userRef = doc(db, "Users", uid);
    await updateDoc(userRef, updatedData);
  } catch (error) {}
};

/* Function to increment game property in GameAudit document
export async function incrementGameProperty(gameName) {
  try {
    await runTransaction(db, async (transaction) => {
      const gameAuditRef = doc(db, "Audit", "GameAudit");
      const sfDoc = await transaction.get(gameAuditRef);

      const currentPropertyValue = sfDoc.data()[gameName] || 0;
      const newPropertyValue = currentPropertyValue + 1;

      transaction.update(gameAuditRef, { [gameName]: newPropertyValue });
    });
  } catch (e) {}
}*/

export const checkUsernameExists = async (username) => {
  const q = query(collection(db, "Users"), where("username", "==", username));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};
