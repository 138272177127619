import { ColorModeContext, useMode } from "../styles/theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";

import { Outlet } from "react-router-dom";

const BlankLayout = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <Outlet />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default BlankLayout;
