export const chessresources = [
  {
    Type: "Website",
    Title: "Chess.com",
    Description:
      "Chess.com is the premier online chess platform for players of all levels. Play live and daily games, solve engaging puzzles, and take interactive lessons from top coaches. Explore comprehensive analysis tools, join clubs and forums, participate in tournaments, and connect with millions of chess enthusiasts worldwide.",
    URL: "https://www.chess.com",
  },
  {
    Type: "Website",
    Title: "Lichess",
    Description:
      "Lichess is a top-tier, open-source online chess platform offering free access to players of all levels. Enjoy live and correspondence games, tactical puzzles, and in-depth analysis tools. Participate in tournaments, join communities, and improve your skills with interactive lessons, all without ads or fees.",
    URL: "https://www.lichess.org",
  },
  {
    Type: "Website",
    Title: "Playchess",
    Description:
      "Playchess, hosted by ChessBase, is a premier online chess platform offering a rich experience for players of all levels. Engage in live games and tournaments, access extensive training tools, and explore a vast database of historical games. With professional commentary and analysis, Playchess provides an immersive environment for improving your skills. Connect with a global community, participate in exciting events, and enjoy comprehensive features designed to enhance your chess journey.",
    URL: "https://play.chessbase.com",
  },
  {
    Type: "Website",
    Title: "Internet Chess Club (ICC)",
    Description:
      "The Internet Chess Club (ICC) is one of the oldest and most respected online chess platforms. It offers live games, lessons from top coaches, and a variety of tournaments. With a large database of historical games and advanced analysis tools, ICC provides a comprehensive environment for improving your chess skills. Join a global community of players and participate in exciting events and competitions, all designed to enhance your chess experience.",
    URL: "https://www.chessclub.com",
  },
  {
    Type: "Website",
    Title: "FIDE Online Arena",
    Description:
      "FIDE Online Arena is the official online platform of the World Chess Federation (FIDE). It offers rated games and the opportunity to earn official FIDE titles. Participate in a variety of tournaments and events with global rankings, and improve your skills with comprehensive training tools and analysis. Connect with a worldwide community of chess enthusiasts and enjoy an authentic, competitive chess experience designed to enhance your journey.",
    URL: "https://chessarena.com",
  },
  {
    Type: "Website",
    Title: "Chessable",
    Description:
      "Chessable is a leading online chess platform focused on learning and improvement through interactive courses and the science of spaced repetition. Explore a wide range of courses created by top grandmasters and chess educators, designed to enhance your skills and understanding of the game. Engage with a global community, track your progress, and enjoy a unique, immersive learning experience that makes studying chess fun and effective.",
    URL: "https://www.chessable.com",
  },
  {
    Type: "Website",
    Title: "Aimchess",
    Description:
      "Aimchess is a cutting-edge online chess platform that uses advanced analytics to help players improve their game. It offers personalized insights and recommendations based on your playing history, highlighting strengths and weaknesses. With tailored training plans, puzzles, and performance tracking, Aimchess provides a data-driven approach to elevate your skills. Join a community of chess enthusiasts and take your game to the next level with Aimchess's innovative tools and features.",
    URL: "https://www.aimchess.com",
  },
  {
    Type: "Website",
    Title: "Chess Tempo",
    Description:
      "Chess Tempo is a powerful online chess training platform focusing on tactical training and endgame practice. It features an extensive collection of puzzles, detailed game analysis, and online play. With tools designed to help players improve their tactics and strategic understanding, Chess Tempo provides a comprehensive environment for chess training. Join a global community of chess enthusiasts and enhance your skills with this versatile platform.",
    URL: "https://www.chesstempo.com",
  },
  {
    Type: "Website",
    Title: "DecodeChess",
    Description:
      "DecodeChess is an innovative online chess analysis platform that uses AI to provide detailed explanations in natural language. It aims to make complex positions and strategic decisions easier to understand for players of all levels. With its user-friendly interface and insightful analysis, DecodeChess helps players improve their game by offering clear, understandable feedback on their moves. Join a community of chess enthusiasts and elevate your chess understanding with DecodeChess.",
    URL: "https://www.decodechess.com",
  },
  {
    Type: "Website",
    Title: "ChessMood",
    Description:
      "ChessMood is an online chess learning platform designed to help players improve their skills through high-quality courses and training materials. Created by grandmasters, it offers structured lessons, opening repertoires, and a supportive community. With a focus on practical improvement and personalized guidance, ChessMood provides valuable resources for players looking to elevate their game and achieve their chess goals.",
    URL: "https://www.chessmood.com",
  },
  {
    Type: "Software",
    Title: "Scid vs. PC",
    Description:
      "Scid vs. PC is a free, open-source chess database software designed for managing and analyzing chess games. It supports a wide range of features including game collections, opening trees, and advanced search functions. With compatibility for various chess engines, it provides robust analysis tools to help players improve their skills. Ideal for both casual players and serious enthusiasts, Scid vs. PC offers a comprehensive and versatile environment for chess study and training.",
    URL: "https://scidvspc.sourceforge.io",
  },
  {
    Type: "Software",
    Title: "SwissManager",
    Description:
      "SwissManager is a professional tournament management software widely used for organizing chess tournaments. It supports the Swiss-system, round-robin, and other pairing systems, and offers features like automated pairing, result tracking, and detailed reporting. With its user-friendly interface and comprehensive tools, SwissManager simplifies the complex task of tournament administration, making it an essential tool for organizers and arbiters in the chess community.",
    URL: "http://swiss-manager.at",
  },
  {
    Type: "Software",
    Title: "ChessBase",
    Description:
      "ChessBase is a leading chess database software used by professionals and enthusiasts for game analysis and preparation. It offers extensive features including a vast library of historical games, advanced search capabilities, and powerful analysis tools. ChessBase enables players to study openings, improve tactics, and learn from grandmaster games. With its comprehensive resources and user-friendly interface, ChessBase is an essential tool for anyone serious about improving their chess skills.",
    URL: "https://www.chessbase.com",
  },
  {
    Type: "Software",
    Title: "Stockfish",
    Description:
      "Stockfish is an open-source chess engine renowned for its strength and accuracy. It offers advanced evaluation capabilities and deep position analysis, making it a valuable tool for players of all levels. Compatible with various interfaces, Stockfish is widely used for game analysis, training, and preparation. Its continuous development by a community of contributors ensures it remains at the forefront of chess engine technology.",
    URL: "https://stockfishchess.org",
  },
  {
    Type: "Software",
    Title: "Komodo",
    Description:
      "Komodo is a highly-regarded chess engine known for its strategic depth and nuanced understanding of positions. It excels in complex and unbalanced positions, providing insightful analysis and move suggestions. Suitable for both casual players and professionals, Komodo is a powerful tool for game preparation and training, helping users to refine their strategies and improve their chess skills.",
    URL: "https://komodochess.com",
  },
  {
    Type: "Software",
    Title: "Houdini",
    Description:
      "Houdini is a powerful chess engine famous for its creative and tactical play. It provides exceptional analysis capabilities, making it a popular choice for players looking to enhance their understanding of the game. With its ability to find unexpected and sharp moves, Houdini is an excellent tool for exploring new ideas and improving tactical skills. It is compatible with various chess interfaces, offering flexibility for users.",
    URL: "https://www.cruxis.com/chess/houdini.htm",
  },
  {
    Type: "Software",
    Title: "Leela Chess Zero",
    Description:
      "Leela Chess Zero (Lc0) is an open-source chess engine that uses neural networks and machine learning to evaluate positions. Inspired by DeepMind's AlphaZero, Lc0 plays in a human-like style and provides unique insights into complex positions. It continuously learns from its own games, offering innovative strategies and deep analysis. Ideal for those interested in cutting-edge AI in chess, Lc0 is a valuable tool for advanced analysis and study.",
    URL: "https://lczero.org",
  },
  {
    Type: "Software",
    Title: "Shredder",
    Description:
      "Shredder is a versatile chess engine known for its strong play and user-friendly interface. It offers robust analysis tools, puzzles, and training features suitable for players of all levels. Shredder is recognized for its ability to adjust playing strength to match any opponent, making it an excellent choice for practice and improvement. Its comprehensive suite of tools helps users enhance their tactical and strategic skills.",
    URL: "https://www.shredderchess.com",
  },
  {
    Type: "Software",
    Title: "Rybka",
    Description:
      "Rybka is a highly acclaimed chess engine noted for its strategic depth and superior positional understanding. Once considered the strongest engine in the world, Rybka offers detailed analysis and insights into game positions. It is a valuable tool for players looking to deepen their strategic understanding and explore advanced concepts in chess. Rybka's sophisticated evaluation algorithms make it a preferred choice for thorough game analysis.",
    URL: "http://www.rybkachess.com",
  },
  {
    Type: "Software",
    Title: "Fritz",
    Description:
      "Fritz is a well-known chess engine and training tool that has been popular for decades. It offers extensive features including game analysis, training modules, and online play. Fritz is designed to help players improve their skills with personalized training programs and interactive lessons. Its user-friendly interface and powerful analysis capabilities make it suitable for both beginners and advanced players.",
    URL: "https://www.chessbase.com/fritz",
  },
  {
    Type: "Website",
    Title: "ChessManager",
    Description:
      "ChessManager is a modern tournament management software designed to streamline the organization and administration of chess tournaments. It supports a variety of pairing systems, automated pairings, result tracking, and comprehensive reporting. With an intuitive interface and powerful features, ChessManager simplifies the complex tasks involved in tournament management, making it an essential tool for organizers and arbiters.",
    URL: "https://www.chessmanager.com",
  },
  {
    Type: "Website",
    Title: "ChessBook",
    Description:
      "ChessBook is an innovative online platform designed to help chess players improve their opening repertoire. It offers a comprehensive database of openings, interactive training tools, and personalized learning plans. With detailed analysis and practical exercises, ChessBook enables players to master their favorite openings and enhance their overall game strategy. Ideal for players of all levels, ChessBook provides a structured approach to opening preparation and study.",
    URL: "https://chessbook.com",
  },
  {
    Type: "Website",
    Title: "ChessMonitor",
    Description:
      "ChessMonitor is an advanced online platform that provides comprehensive tools for tracking and analyzing chess performance. It offers detailed game statistics, progress tracking, and personalized insights to help players understand their strengths and weaknesses. With features like performance metrics, historical data analysis, and improvement recommendations, ChessMonitor is an essential resource for players aiming to enhance their skills and achieve their chess goals.",
    URL: "https://www.chessmonitor.com",
  },
  {
    Type: "Website",
    Title: "2700chess",
    Description:
      "2700chess is a premier online platform dedicated to tracking the live ratings and performances of the world's top chess players. It provides real-time updates, detailed statistics, and historical data on players with ratings of 2700 and above. With comprehensive coverage of top tournaments and insightful analysis, 2700chess is an invaluable resource for fans, players, and enthusiasts looking to stay informed about the elite chess world.",
    URL: "https://2700chess.com",
  },
  {
    Type: "Website",
    Title: "The Week in Chess",
    Description:
      "The Week in Chess (TWIC) is a premier online resource providing weekly updates on the latest chess news. It includes detailed tournament reports, game collections, and top player performances. With comprehensive coverage of chess events worldwide, TWIC is an invaluable resource for chess enthusiasts looking to stay informed about the current chess scene.",
    URL: "https://theweekinchess.com",
  },
  {
    Type: "Website",
    Title: "FIDE",
    Description:
      "FIDE is the official website of the World Chess Federation, offering comprehensive information on chess regulations, official rankings, and tournament calendars. It provides news updates, resources for players and organizers, and detailed information about FIDE events and initiatives. FIDE is an essential resource for anyone interested in the global governance and competitive aspects of chess.",
    URL: "https://fide.com",
  },
  {
    Type: "Website",
    Title: "Chessdom",
    Description:
      "Chessdom is a comprehensive online resource offering chess news, live commentary, and updates on major tournaments and events. It provides in-depth coverage of top chess competitions, insightful analysis, and interviews with leading players. Chessdom is an essential destination for chess enthusiasts looking to stay updated with the latest happenings in the chess world.",
    URL: "https://www.chessdom.com",
  },
  {
    Type: "Website",
    Title: "Chessgames.com",
    Description:
      "Chessgames.com is an extensive online chess database offering a vast collection of chess games, player profiles, and a platform for chess discussion and analysis. Users can search and explore historical and recent games, participate in forums, and access tools for game review and study. Chessgames.com is a valuable resource for chess enthusiasts and players looking to enhance their knowledge and skills.",
    URL: "https://www.chessgames.com",
  },
  {
    Type: "Website",
    Title: "Chess-Results",
    Description:
      "Chess-Results.com is a premier online platform for accessing comprehensive chess tournament results and player information. It offers detailed results, standings, and pairings for tournaments worldwide, with searchable databases and up-to-date information. Chess-Results.com is an essential resource for players, coaches, and chess enthusiasts looking to stay informed about tournament outcomes and player performances.",
    URL: "https://chess-results.com",
  },
  {
    Type: "Website",
    Title: "RedHotPawn",
    Description:
      "RedHotPawn is an online chess platform that focuses on correspondence chess. It allows players to engage in long-term strategic play with no time pressure. The platform offers a variety of features including tournaments, forums, and player blogs, providing a community-focused environment for chess enthusiasts.",
    URL: "https://www.redhotpawn.com",
  },
  {
    Type: "Website",
    Title: "OpeningTree",
    Description:
      "OpeningTree is a useful tool for exploring and analyzing chess openings. It allows players to upload their games or use PGN files to generate a detailed analysis of opening repertoires. With features like statistics on popular moves and success rates, OpeningTree helps players refine their opening strategies and prepare for their opponents.",
    URL: "https://openingtree.com",
  },
  {
    Type: "YouTube Channel",
    Title: "GothamChess",
    Description:
      "Levy Rozman, also known as GothamChess, offers a mix of educational content, game analysis, and entertaining commentary. His videos are suitable for players of all levels.",
    URL: "https://www.youtube.com/c/GothamChess",
  },
  {
    Type: "YouTube Channel",
    Title: "Agadmator's Chess Channel",
    Description:
      "Antonio Radić, known as Agadmator, provides in-depth analysis of famous games, historical matches, and current tournament games. His clear explanations make complex strategies accessible to all viewers.",
    URL: "https://www.youtube.com/c/AGADMATOR",
  },
  {
    Type: "YouTube Channel",
    Title: "Hikaru Nakamura",
    Description:
      "Grandmaster Hikaru Nakamura shares his expertise through live game commentary, speed runs, and instructional videos. His channel also features highlights from his Twitch streams.",
    URL: "https://www.youtube.com/c/GMHikaru",
  },
  {
    Type: "YouTube Channel",
    Title: "The Chessbrahs",
    Description:
      "Led by Grandmasters Eric Hansen and Aman Hambleton, The Chessbrahs offer a variety of content including live blitz games, tournament coverage, and instructional videos.",
    URL: "https://www.youtube.com/c/chessbrah",
  },
  {
    Type: "YouTube Channel",
    Title: "ChessNetwork",
    Description:
      "Jerry from ChessNetwork provides educational content, live game analysis, and puzzles. His calm and clear teaching style is appreciated by many chess learners.",
    URL: "https://www.youtube.com/c/ChessNetwork",
  },
  {
    Type: "YouTube Channel",
    Title: "Saint Louis Chess Club",
    Description:
      "The Saint Louis Chess Club channel features lectures, game analysis, and tournament coverage from one of the premier chess clubs in the world. It includes content from top players and instructors.",
    URL: "https://www.youtube.com/channel/UCM-ONC2bCHytG2mYtKDmIeA",
  },
  {
    Type: "YouTube Channel",
    Title: "Chess.com",
    Description:
      "Chess.com's official YouTube channel offers a wide range of content including lessons, game analysis, and highlights from major tournaments and events.",
    URL: "https://www.youtube.com/@chess",
  },
  {
    Type: "YouTube Channel",
    Title: "IM Eric Rosen",
    Description:
      "International Master Eric Rosen shares instructive game analysis, live commentary, and unique content such as blindfold chess. His videos are both educational and entertaining.",
    URL: "https://www.youtube.com/c/EricRosen",
  },
  {
    Type: "YouTube Channel",
    Title: "BotezLive",
    Description:
      "Sisters Alexandra and Andrea Botez provide a mix of educational content, game analysis, and entertaining chess challenges. Their channel is known for its engaging and lively style.",
    URL: "https://www.youtube.com/c/BotezLive",
  },
  {
    Type: "YouTube Channel",
    Title: "Hanging Pawns",
    Description:
      "This channel offers educational content focused on improving chess skills, including opening theory, endgame techniques, and game analysis.",
    URL: "https://www.youtube.com/c/HangingPawns",
  },
  {
    Type: "YouTube Channel",
    Title: "Alex Banzea",
    Description:
      "Alex Banzea offers insightful chess tutorials, game analysis, and practical tips to help players improve their skills. His content is suitable for all levels and provides valuable strategies for advancing in the game.",
    URL: "https://www.youtube.com/@AlexBanzea",
  },
  {
    Type: "YouTube Channel",
    Title: "Anna Cramling",
    Description:
      "Anna Cramling offers a variety of chess content including game analysis, tutorials, and live commentary. As a titled player and popular streamer, her channel provides both educational and entertaining videos, making chess accessible and enjoyable for players of all levels.",
    URL: "https://www.youtube.com/c/AnnaCramling",
  },
  {
    Type: "YouTube Channel",
    Title: "John Bartholomew",
    Description:
      "John Bartholomew, an International Master, offers a wide range of chess content including game analysis, tutorials, and educational series like 'Climbing the Rating Ladder.' His clear and instructive videos are designed to help players of all levels improve their chess skills and understanding.",
    URL: "https://www.youtube.com/c/JohnBartholomew",
  },
  {
    Type: "YouTube Channel",
    Title: "Daniel Naroditsky",
    Description:
      "Grandmaster Daniel Naroditsky provides in-depth chess content including game analysis, tutorials, and live commentary. His channel features educational series and practical advice aimed at helping players of all levels enhance their understanding and skills in chess.",
    URL: "https://www.youtube.com/@DanielNaroditskyGM",
  },
  {
    Type: "YouTube Channel",
    Title: "GingerGM",
    Description:
      "GingerGM, run by Grandmaster Simon Williams, offers entertaining and educational chess content including game analysis, tutorials, and commentary. Known for his dynamic and engaging style, Simon Williams' channel is a favorite among players of all levels looking to improve their game and enjoy the rich history and strategies of chess.",
    URL: "https://www.youtube.com/c/GingerGM",
  },
  {
    Type: "YouTube Channel",
    Title: "The Chess Giant",
    Description:
      "The Chess Giant, hosted by Solomon Ruddell, provides a wide range of chess content including opening theory, game analysis, and tutorials. With a focus on practical improvement and strategic understanding, his channel offers valuable insights and tips for players of all levels.",
    URL: "https://www.youtube.com/@TheChessGiant",
  },
  {
    Type: "YouTube Channel",
    Title: "Epic Chess",
    Description:
      "Epic Chess offers a variety of chess content including game analysis, tutorials, and highlights from top tournaments. The channel focuses on providing clear and insightful commentary to help players of all levels improve their skills and enjoy the game.",
    URL: "https://www.youtube.com/@epicchess2021",
  },
  {
    Type: "YouTube Channel",
    Title: "Hans Niemann Chess",
    Description:
      "Hans Niemann Chess features content from Grandmaster Hans Niemann, including game analysis, tutorials, and insights into his own games and tournament experiences. His channel offers valuable lessons and strategies for players looking to improve their chess skills and understanding.",
    URL: "https://www.youtube.com/@HansNiemannChess",
  },
  {
    Type: "YouTube Channel",
    Title: "Miodrag Perunovic Official",
    Description:
      "Miodrag Perunovic Official provides a wide range of chess content including game analysis, tutorials, and strategic insights. As a seasoned chess player and coach, Miodrag Perunovic offers valuable lessons and practical tips to help players of all levels enhance their skills and understanding of the game.",
    URL: "https://www.youtube.com/@MiodragPerunovicOfficial",
  },
  {
    Type: "YouTube Channel",
    Title: "Dina Belenkaya",
    Description:
      "Dina Belenkaya's channel features a variety of chess content including game analysis, tutorials, and live streams. As a Woman Grandmaster, she provides insightful commentary and practical advice to help players of all levels improve their chess skills and enjoy the game.",
    URL: "https://www.youtube.com/@DinaBelenkaya",
  },
  {
    Type: "YouTube Channel",
    Title: "Chessable",
    Description:
      "Chessable's YouTube channel offers a wide range of educational chess content, including tutorials, game analysis, and courses from top grandmasters and coaches. It focuses on improving players' skills using the science of spaced repetition and provides valuable insights for players of all levels.",
    URL: "https://www.youtube.com/@Chessable",
  },
  {
    Type: "YouTube Playlist",
    Title: "London System Series",
    Description:
      "GM Aman Hambleton discusses and demonstrates typical ideas and plans in the London Opening as he takes an account from 800 to 2500.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTiljFCufDXDSjEQH1cIdbSx&si=R5_xBxMlHf12uaUl",
  },
  {
    Type: "YouTube Playlist",
    Title: "Kings Indian Series",
    Description:
      "GM Aman Hambleton discusses and demonstrates typical ideas and plans in the King's Indian opening as he takes an account from 800 to 2000.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTinraGDsjTkIh_NMnaIOr4K&si=ixTQ3OJAl5Gpnspu",
  },
  {
    Type: "YouTube Playlist",
    Title: "Hippo Series",
    Description:
      "GM Eric Hansen demonstrates the power of the Hippo Opening as he takes an account from 800 to 2400.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTjPPDAZGCcpmpSmw6QFLxJU&si=J2iLZyWTYj8sRePT",
  },

  {
    Type: "YouTube Playlist",
    Title: "English Series",
    Description:
      "GM Aman Hambleton plays and explains the English opening (1.c4) through a series of blitz games with commentary.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTgFhixLDC8ZbTyLaqZZ-oyu&si=a6wr9C_lG0LlCE_A",
  },
  {
    Type: "YouTube Playlist",
    Title: "Stonewall Attack Series",
    Description:
      "GM Aman Hambleton discusses and demonstrates typical ideas and plans in the Stonewall Attack opening as he takes an account from 400 to 2200.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTgUMR77Bdd3NGtvFXApHZkS&si=9hbLJyDucEOVbnL0",
  },
  {
    Type: "YouTube Playlist",
    Title: "Bird Opening Series",
    Description:
      "GM Eric Hansen demonstrates the power of the Bird Opening (1.f4) as he takes an account from 400 to 2600.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTiZCQN7rLQ6k61_Cttt783P&si=UMr0EoKTxzouSc_-",
  },
  {
    Type: "YouTube Playlist",
    Title: "Caro-Kann Series",
    Description:
      "GM Eric Hansen introduces a flexible approach by playing the Caro-Kann (1.. c6) against all of White's main opening moves as he takes an account from 400 to 2600.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTjVEZ_1I8E28uav9t_ltDKX&si=dEDUvOhQ2Z3NZYnQ",
  },
  {
    Type: "YouTube Playlist",
    Title: "Taimanov Series",
    Description:
      "GM Aman Hambleton discusses and demonstrates typical ideas and plans in the Taimanov Silician (with Black and White?) as he takes an account from 400 to 2400.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTjZAD99gBAKVm_ZipXTtNYn&si=_RAzasx0KmmFgdDk",
  },
  {
    Type: "YouTube Playlist",
    Title: "French Defense Series",
    Description:
      "GM Aman Hambleton discusses and demonstrates typical ideas and plans in the French Defense as he takes an account from 900 to 2300.",
    URL: "https://youtube.com/playlist?list=PLUjxDD7HNNTgWqHZpTn_6s7_47bRMOo2c&si=BY2IjnZ4Xabov9pn",
  },
  {
    Type: "YouTube Playlist",
    Title: "Beginner to Master Speedrun",
    Description:
      "This is an educational series where Eric Rosen starts at a 400 Elo on a brand new chess.com account and try to reach 2200+. With every game, Eric provide important lessons that will hopefully make you a better chess player!",
    URL: "https://youtube.com/playlist?list=PLdT3OotRiHJkaRjrG0GSfaddcf0cDyqhn&si=fhRs1eqJPy51ptvv",
  },
  {
    Type: "YouTube Channel",
    Title: "GM Benjamin Finegold",
    Description:
      "GM Benjamin Finegold's channel features a mix of educational chess content, including game analysis, tutorials, and entertaining commentary. Known for his humorous and engaging style, GM Finegold offers valuable insights and practical advice to help players of all levels improve their chess skills.",
    URL: "https://www.youtube.com/@GMBenjaminFinegold",
  },

  {
    Type: "YouTube Playlist",
    Title: "Back to 3000 Elo Speedrun",
    Description:
      "GM Daniel Naroditsky creates a brand new account and takes you step by step up the rating ladder from 400 to 2400, while discussing key ideas and tactics in the position.",
    URL: "https://youtube.com/playlist?list=PLT1F2nOxLHOcmi_qi1BbY6axf5xLFEcit&si=z5yfPB0Q4459yDo-",
  },
  {
    Type: "Website",
    Title: "PGN Mentor",
    Description:
      "PGN Mentor offers a comprehensive collection of PGN (Portable Game Notation) files for chess enthusiasts. The site provides a vast database of chess games, including famous matches and tournament archives. PGN Mentor is an excellent resource for players looking to study and analyze historical games to improve their understanding and skills in chess.",
    URL: "https://www.pgnmentor.com",
  },
  {
    Type: "Software",
    Title: "Caissabase",
    Description:
      "Caissabase provides an extensive collection of chess game databases available for download. It includes games from various tournaments and notable matches, making it a valuable resource for players and enthusiasts looking to study and analyze chess games in depth.",
    URL: "http://caissabase.co.uk",
  },
  {
    Type: "Website",
    Title: "365Chess",
    Description:
      "365Chess is a comprehensive online chess database offering access to millions of chess games. The site provides powerful search tools, game analysis features, and a vast library of historical and modern games. It is an essential resource for players looking to study openings, endgames, and famous matches to improve their chess skills.",
    URL: "https://www.365chess.com",
  },
  {
    Type: "Website",
    Title: "ChessPuzzle.net",
    Description:
      "ChessPuzzle.net is a free platform offering a vast collection of chess puzzles for players of all levels. It provides puzzles categorized by themes such as checkmates, tactics, and endgames, helping players improve their problem-solving skills and tactical awareness. Users can track their progress and compete with others on leaderboards.",
    URL: "https://chesspuzzle.net",
  },
  {
    Type: "Website",
    Title: "Chessly",
    Description:
      "Chessly is an online platform dedicated to providing chess lessons and training materials for players of all levels. Featuring courses by top grandmasters and coaches, Chessly offers a structured approach to learning chess, including opening theory, middlegame strategies, and endgame techniques. The site aims to help players improve their skills and achieve their chess goals.",
    URL: "https://chessly.com",
  },
];
