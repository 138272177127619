import React from "react";
import { SvgIcon } from "@mui/material";

function WhackIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.8) translate(100, 100)">
        <path d="m480.79 440.02h-146.64c-26.305 0-47.664-21.312-47.664-47.676 0-26.293 21.348-47.629 47.664-47.629h146.64c26.316 0 47.664 21.336 47.664 47.629 0.011719 26.363-21.348 47.676-47.664 47.676z" />
        <path d="m480.79 557.16h-168.67c-26.328 0-47.664-21.324-47.664-47.699 0-26.258 21.336-47.617 47.664-47.617h168.67c26.316 0 47.664 21.359 47.664 47.617 0.011719 26.363-21.348 47.699-47.664 47.699z" />
        <path d="m480.79 674.27h-102.61c-26.316 0-47.664-21.324-47.664-47.652 0-26.305 21.348-47.664 47.664-47.664h102.61c26.316 0 47.664 21.359 47.664 47.664 0.011719 26.328-21.348 47.652-47.664 47.652z" />
        <path d="m801.45 334.3-102.56-115.68c-4.9062-5.5312-11.051-9.8047-17.941-12.516-148.8-58.379-148.77-58.332-168.19-45.047l-122.79 79.234c-21.742 14.039-27.996 43.043-13.98 64.789 14.039 21.742 43.043 27.996 64.777 13.934l73.969-47.746c13.586 73.512 46.441 190.69 121.08 230.88 92.531 49.789 150.25 26.004 150.25 26.004v26.594s-60.289 12-115.44-7.0664c-68.734-23.746-119.43-77.219-119.43-77.219v204.54h134.69s39.383-2.5312 68.762-18.66c26.293-14.461 41.594-46.488 41.594-46.488h398.21v-275.54z" />
        <path d="m101.06 906.54-27.059-47.484 276.61-156.75 27.059 47.484z" />
        <path d="m395.76 963.1-48.207-25.754 95.391-178.57 48.215 25.762z" />
        <path d="m650.86 1136.7-60.551-378.98 53.977-8.6289 60.551 378.98z" />
        <path d="m956.14 923.09-262.38-145.75 26.531-47.785 262.39 145.77z" />
        <path d="m246.5 675.09h-233.63v-54.66h233.63z" />
      </g>
    </SvgIcon>
  );
}

export default WhackIcon;
