// Processed from https://github.com/lichess-org/chess-openings
export const openings = [
  {
    eco: "A00",
    name: "Amar Opening",
    pgn: "1. Nh3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/7N/PPPPPPPP/RNBQKB1R b KQkq - 1 1",
  },
  {
    eco: "A00",
    name: "Anderssen's Opening",
    pgn: "1. a3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/P7/1PPPPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Barnes Opening",
    pgn: "1. f3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/5P2/PPPPP1PP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Clemenz Opening",
    pgn: "1. h3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/7P/PPPPPPP1/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Grob Opening",
    pgn: "1. g4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/6P1/8/PPPPPP1P/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Hungarian Opening",
    pgn: "1. g3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/6P1/PPPPPP1P/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Kádas Opening",
    pgn: "1. h4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/7P/8/PPPPPPP1/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Mieses Opening",
    pgn: "1. d3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/3P4/PPP1PPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Polish Opening",
    pgn: "1. b4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/1P6/8/P1PPPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Saragossa Opening",
    pgn: "1. c3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/2P5/PP1PPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Sodium Attack",
    pgn: "1. Na3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/N7/PPPPPPPP/R1BQKBNR b KQkq - 1 1",
  },
  {
    eco: "A00",
    name: "Van Geet Opening",
    pgn: "1. Nc3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/2N5/PPPPPPPP/R1BQKBNR b KQkq - 1 1",
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening",
    pgn: "1. e3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/4P3/PPPP1PPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Ware Opening",
    pgn: "1. a4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/P7/8/1PPPPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack",
    pgn: "1. b3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/1P6/P1PPPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A02",
    name: "Bird Opening",
    pgn: "1. f4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R b KQkq - 1 1",
  },
  {
    eco: "A10",
    name: "English Opening",
    pgn: "1. c4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game",
    pgn: "1. d4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "B00",
    name: "King's Pawn Game",
    pgn: "1. e4",
    length: 1,
    fen: "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq - 0 1",
  },
  {
    eco: "A00",
    name: "Grob Opening: Alessi Gambit",
    pgn: "1. g4 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/6P1/8/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Grob Opening: Double Grob",
    pgn: "1. g4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/6P1/8/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Dutch Defense",
    pgn: "1. g3 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Indian Defense",
    pgn: "1. g3 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Myers Defense",
    pgn: "1. g3 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Sicilian Invitation",
    pgn: "1. g3 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Symmetrical Variation",
    pgn: "1. g3 g6",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/6p1/8/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Schneider Gambit",
    pgn: "1. h4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/7P/8/PPPPPPP1/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Lasker Simul Special",
    pgn: "1. g3 h5",
    length: 2,
    fen: "rnbqkbnr/ppppppp1/8/7p/8/6P1/PPPPPP1P/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Mieses Opening: Reversed Rat",
    pgn: "1. d3 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/3P4/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Birmingham Gambit",
    pgn: "1. b4 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Dutch Defense",
    pgn: "1. b4 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Grigorian Variation",
    pgn: "1. b4 Nc6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/2n5/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Karniewski Variation",
    pgn: "1. b4 Nh6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/7n/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Outflank Variation",
    pgn: "1. b4 c6",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Symmetrical Variation",
    pgn: "1. b4 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/1P6/8/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Laroche Gambit",
    pgn: "1. Nc3 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/8/2N5/PPPPPPPP/R1BQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Reversed Nimzowitsch",
    pgn: "1. Nc3 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/2N5/PPPPPPPP/R1BQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Classical Variation",
    pgn: "1. b3 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Dutch Variation",
    pgn: "1. b3 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: English Variation",
    pgn: "1. b3 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Indian Variation",
    pgn: "1. b3 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Polish Variation",
    pgn: "1. b3 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Symmetrical Variation",
    pgn: "1. b3 b6",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/1p6/8/8/1P6/P1PPPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A02",
    name: "Bird Opening",
    pgn: "1. f4 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit",
    pgn: "1. f4 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: Hobbs Gambit",
    pgn: "1. f4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: Horsefly Defense",
    pgn: "1. f4 Nh6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/7n/8/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: Myers Defense",
    pgn: "1. f4 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A03",
    name: "Bird Opening: Dutch Variation",
    pgn: "1. f4 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Arctic Defense",
    pgn: "1. Nf3 f6",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/5p2/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Basman Defense",
    pgn: "1. Nf3 h6",
    length: 2,
    fen: "rnbqkbnr/ppppppp1/7p/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Black Mustang Defense",
    pgn: "1. Nf3 Nc6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/2n5/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 2 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Dutch Variation",
    pgn: "1. Nf3 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Herrstrom Gambit",
    pgn: "1. Nf3 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Kingside Fianchetto",
    pgn: "1. Nf3 g6",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/6p1/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Pirc Invitation",
    pgn: "1. Nf3 d6",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Polish Defense",
    pgn: "1. Nf3 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Queen's Gambit Invitation",
    pgn: "1. Nf3 e6",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Queenside Fianchetto Variation",
    pgn: "1. Nf3 b6",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/1p6/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Ross Gambit",
    pgn: "1. Nf3 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Sicilian Invitation",
    pgn: "1. Nf3 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Slav Invitation",
    pgn: "1. Nf3 c6",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: St. George Defense",
    pgn: "1. Nf3 a6",
    length: 2,
    fen: "rnbqkbnr/1ppppppp/p7/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Ware Defense",
    pgn: "1. Nf3 a5",
    length: 2,
    fen: "rnbqkbnr/1ppppppp/8/p7/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 2 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R w KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Defense",
    pgn: "1. c4 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Lithuanian Variation",
    pgn: "1. c4 Nc6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/2n5/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense",
    pgn: "1. c4 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Great Snake Variation",
    pgn: "1. c4 g6",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/6p1/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Jaenisch Gambit",
    pgn: "1. c4 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Myers Defense",
    pgn: "1. c4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A11",
    name: "English Opening: Caro-Kann Defensive System",
    pgn: "1. c4 c6",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense",
    pgn: "1. c4 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation",
    pgn: "1. c4 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation",
    pgn: "1. c4 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Australian Defense",
    pgn: "1. d4 Na6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/n7/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A40",
    name: "Borg Defense: Borg Gambit",
    pgn: "1. d4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "English Defense",
    pgn: "1. d4 b6",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/1p6/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Englund Gambit",
    pgn: "1. d4 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Horwitz Defense",
    pgn: "1. d4 e6",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Mikenas Defense",
    pgn: "1. d4 Nc6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/2n5/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A40",
    name: "Polish Defense",
    pgn: "1. d4 b5",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/8/1p6/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game: Modern Defense",
    pgn: "1. d4 g6",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A41",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d6",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Indian Defense",
    pgn: "1. d4 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense",
    pgn: "1. d4 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Barnes Defense",
    pgn: "1. e4 f6",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/5p2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Borg Defense",
    pgn: "1. e4 g5",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/8/6p1/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Carr Defense",
    pgn: "1. e4 h6",
    length: 2,
    fen: "rnbqkbnr/ppppppp1/7p/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Duras Gambit",
    pgn: "1. e4 f5",
    length: 2,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Goldsmith Defense",
    pgn: "1. e4 h5",
    length: 2,
    fen: "rnbqkbnr/ppppppp1/8/7p/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Hippopotamus Defense",
    pgn: "1. e4 Nh6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/7n/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "B00",
    name: "Lemming Defense",
    pgn: "1. e4 Na6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/n7/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense",
    pgn: "1. e4 Nc6",
    length: 2,
    fen: "r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "B00",
    name: "Owen Defense",
    pgn: "1. e4 b6",
    length: 2,
    fen: "rnbqkbnr/p1pppppp/1p6/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "St. George Defense",
    pgn: "1. e4 a6",
    length: 2,
    fen: "rnbqkbnr/1ppppppp/p7/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Ware Defense",
    pgn: "1. e4 a5",
    length: 2,
    fen: "rnbqkbnr/1ppppppp/8/p7/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense",
    pgn: "1. e4 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B02",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6",
    length: 2,
    fen: "rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 1 2",
  },
  {
    eco: "B06",
    name: "Modern Defense",
    pgn: "1. e4 g6",
    length: 2,
    fen: "rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense",
    pgn: "1. e4 c5",
    length: 2,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense",
    pgn: "1. e4 e6",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game",
    pgn: "1. e4 e5",
    length: 2,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5",
    length: 2,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Anderssen's Opening: Polish Gambit",
    pgn: "1. a3 a5 2. b4",
    length: 3,
    fen: "rnbqkbnr/1ppppppp/8/p7/1P6/P7/2PPPPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Barnes Opening: Hammerschlag",
    pgn: "1. f3 e5 2. Kf2",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/5P2/PPPPPKPP/RNBQ1BNR b kq - 1 2",
  },
  {
    eco: "A00",
    name: "Clemenz Opening: Spike Lee Gambit",
    pgn: "1. h3 h5 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppppppp1/8/7p/6P1/7P/PPPPPP2/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Crab Opening",
    pgn: "1. a4 e5 2. h4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/P6P/8/1PPPPPP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Global Opening",
    pgn: "1. h3 e5 2. a3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/P6P/1PPPPPP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Grob Opening: Double Grob, Coca-Cola Gambit",
    pgn: "1. g4 g5 2. f4",
    length: 3,
    fen: "rnbqkbnr/pppppp1p/8/6p1/5PP1/8/PPPPP2P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit",
    pgn: "1. g4 d5 2. Bg2",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/6P1/8/PPPPPPBP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Alekhine",
    pgn: "1. g3 e5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/5NP1/PPPPPP1P/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Beginner's Trap",
    pgn: "1. h4 d5 2. Rh3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/7P/7R/PPPPPPP1/RNBQKBN1 b Qkq - 1 2",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 c5 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1P5P/8/P1PPPPP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Mieses Opening: Myers Spike Attack",
    pgn: "1. d3 g6 2. g4",
    length: 3,
    fen: "rnbqkbnr/pppppp1p/6p1/8/6P1/3P4/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Polish Opening: Bugayev Attack",
    pgn: "1. b4 e5 2. a3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/1P6/P7/2PPPPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Sodium Attack: Chenoboskion Variation",
    pgn: "1. Na3 g6 2. g4",
    length: 3,
    fen: "rnbqkbnr/pppppp1p/6p1/8/6P1/N7/PPPPPP1P/R1BQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Valencia Opening",
    pgn: "1. d3 e5 2. Nd2",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/3P4/PPPNPPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Battambang Variation",
    pgn: "1. a3 e5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/8/P1N5/1PPPPPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Düsseldorf Gambit",
    pgn: "1. Nc3 c5 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1P6/2N5/P1PPPPPP/R1BQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Myers Attack",
    pgn: "1. Nc3 g6 2. h4",
    length: 3,
    fen: "rnbqkbnr/pppppp1p/6p1/8/7P/2N5/PPPPPPP1/R1BQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Twyble Attack",
    pgn: "1. Nc3 c5 2. Rb1",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/8/2N5/PPPPPPPP/1RBQKBNR b Kkq - 1 2",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Tübingen Gambit",
    pgn: "1. Nc3 Nf6 2. g4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/6P1/2N5/PPPPPP1P/R1BQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Ware Opening: Meadow Hay Trap",
    pgn: "1. a4 e5 2. Ra3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/P7/R7/1PPPPPPP/1NBQKBNR b Kkq - 1 2",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Graz Attack",
    pgn: "1. b3 d5 2. Ba3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/BP6/P1PPPPPP/RN1QKBNR b KQkq - 1 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Bahr Gambit",
    pgn: "1. f4 e5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/5P2/2N5/PPPPP1PP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: Mujannah",
    pgn: "1. f4 Nf6 2. c4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2P2P2/8/PP1PP1PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A02",
    name: "Bird Opening: Wagner-Zwitersch Gambit",
    pgn: "1. f4 f5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A03",
    name: "Bird Opening: Dutch Variation, Dudweiler Gambit",
    pgn: "1. f4 d5 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/5PP1/8/PPPPP2P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A03",
    name: "Bird Opening: Sturm Gambit",
    pgn: "1. f4 d5 2. c4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/2P2P2/8/PP1PP1PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams Gambit",
    pgn: "1. f4 d5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Lisitsyn Gambit",
    pgn: "1. Nf3 f5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A05",
    name: "Polish Opening: Zukertort System",
    pgn: "1. Nf3 Nf6 2. b4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/1P6/5N2/P1PPPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Lemberger Gambit",
    pgn: "1. Nf3 Nf6 2. e4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Nimzo-Larsen Variation",
    pgn: "1. Nf3 Nf6 2. b3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/8/1P3N2/P1PPPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Quiet System",
    pgn: "1. Nf3 Nf6 2. e3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/8/4PN2/PPPP1PPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Classical Variation",
    pgn: "1. Nf3 d5 2. b3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/1P3N2/P1PPPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Ampel Variation",
    pgn: "1. Nf3 d5 2. Rg1",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKBR1 b Qkq - 1 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Old Indian Attack",
    pgn: "1. Nf3 d5 2. d3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/3P1N2/PPP1PPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Reversed Mexican Defense",
    pgn: "1. Nf3 d5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/2N2N2/PPPPPPPP/R1BQKB1R b KQkq - 1 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Santasiere's Folly",
    pgn: "1. b4 d5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/1P6/5N2/P1PPPPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Tennison Gambit",
    pgn: "1. e4 d5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: The Potato",
    pgn: "1. Nf3 d5 2. a4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/P7/5N2/1PPPPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A07",
    name: "King's Indian Attack",
    pgn: "1. Nf3 d5 2. g3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 d5 2. c4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Achilles-Omega Gambit",
    pgn: "1. c4 Nf6 2. e4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Defense, Hickmann Gambit",
    pgn: "1. c4 f5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A10",
    name: "English Opening: Wade Gambit",
    pgn: "1. c4 f5 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/2P3P1/8/PP1PPP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Knight Variation",
    pgn: "1. Nf3 Nf6 2. c4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq - 0 2",
  },
  {
    eco: "A15",
    name: "English Orangutan",
    pgn: "1. c4 Nf6 2. b4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/1PP5/8/P2PPPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Queen's Knight Variation",
    pgn: "1. c4 Nf6 2. Nc3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq - 2 2",
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Nimzowitsch Variation",
    pgn: "1. c4 e5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Reversed Sicilian",
    pgn: "1. c4 e5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation",
    pgn: "1. c4 c5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A30",
    name: "English Opening: Wing Gambit",
    pgn: "1. c4 c5 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1PP5/8/P2PPPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Normal Variation",
    pgn: "1. c4 c5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex Declined",
    pgn: "1. d4 e5 2. d5",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/3Pp3/8/8/PPP1PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Alekhine",
    pgn: "1. d4 e5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed French",
    pgn: "1. d4 e5 2. e3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A41",
    name: "Old Indian Defense",
    pgn: "1. d4 d6 2. c4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni Gambit Accepted",
    pgn: "1. d4 c5 2. dxc5",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2P5/8/8/PPP1PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit",
    pgn: "1. d4 c5 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1P1P4/8/P1P1PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Canard Opening",
    pgn: "1. d4 Nf6 2. f4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P1P2/8/PPP1P1PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit",
    pgn: "1. d4 Nf6 2. g4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Indian Defense: Omega Gambit",
    pgn: "1. d4 Nf6 2. e4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Indian Defense: Pawn Push Variation",
    pgn: "1. d4 Nf6 2. d5",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/3P4/8/8/PPP1PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Indian Defense: Tartakower Attack",
    pgn: "1. d4 Nf6 2. g3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P4/6P1/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Paleface Attack",
    pgn: "1. d4 Nf6 2. f3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P4/5P2/PPP1P1PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack",
    pgn: "1. d4 Nf6 2. Bg5",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq - 2 2",
  },
  {
    eco: "A46",
    name: "Indian Defense: Knights Variation",
    pgn: "1. d4 Nf6 2. Nf3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq - 2 2",
  },
  {
    eco: "A50",
    name: "Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Alapin Variation",
    pgn: "1. d4 f5 2. Qd3",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P4/3Q4/PPP1PPPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Hopton Attack",
    pgn: "1. d4 f5 2. Bg5",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5pB1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq - 1 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Korchnoi Attack",
    pgn: "1. d4 f5 2. h3",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P4/7P/PPP1PPP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Krejcik Gambit",
    pgn: "1. d4 f5 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Raphael Variation",
    pgn: "1. d4 f5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "A81",
    name: "Dutch Defense: Fianchetto Attack",
    pgn: "1. d4 f5 2. g3",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit",
    pgn: "1. d4 f5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A84",
    name: "Dutch Defense",
    pgn: "1. d4 f5 2. c4",
    length: 3,
    fen: "rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense",
    pgn: "1. e4 Nc6 2. d4",
    length: 3,
    fen: "r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Declined Variation",
    pgn: "1. e4 Nc6 2. Nf3",
    length: 3,
    fen: "r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 2 2",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Pseudo-Spanish Variation",
    pgn: "1. e4 Nc6 2. Bb5",
    length: 3,
    fen: "r1bqkbnr/pppppppp/2n5/1B6/4P3/8/PPPP1PPP/RNBQK1NR b KQkq - 2 2",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Wheeler Gambit",
    pgn: "1. e4 Nc6 2. b4",
    length: 3,
    fen: "r1bqkbnr/pppppppp/2n5/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Rat Defense: Harmonist",
    pgn: "1. e4 d6 2. f4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Rat Defense: Petruccioli Attack",
    pgn: "1. e4 d6 2. h4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/4P2P/8/PPPP1PP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B00",
    name: "Rat Defense: Spike Attack",
    pgn: "1. e4 d6 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/3p4/8/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Zilbermints Gambit",
    pgn: "1. e4 d5 2. b4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Krejcik Variation",
    pgn: "1. e4 Nf6 2. Bc4",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq - 2 2",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Maróczy Variation",
    pgn: "1. e4 Nf6 2. d3",
    length: 3,
    fen: "rnbqkb1r/pppppppp/5n2/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack",
    pgn: "1. e4 c6 2. c4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Breyer Variation",
    pgn: "1. e4 c6 2. d3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Euwe Attack",
    pgn: "1. e4 c6 2. b3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hillbilly Attack",
    pgn: "1. e4 c6 2. Bc4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack",
    pgn: "1. e4 c6 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Spike Variation",
    pgn: "1. e4 c6 2. g4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Amazon Attack",
    pgn: "1. e4 c5 2. Qg4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P1Q1/8/PPPP1PPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Bowdler Attack",
    pgn: "1. e4 c5 2. Bc4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Brick Variation",
    pgn: "1. e4 c5 2. Nh3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/7N/PPPP1PPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Grob Variation",
    pgn: "1. e4 c5 2. g4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Keres Variation",
    pgn: "1. e4 c5 2. Ne2",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPPNPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: King David's Opening",
    pgn: "1. e4 c5 2. Ke2",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPPKPPP/RNBQ1BNR b kq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Kronberger Variation",
    pgn: "1. e4 c5 2. Na3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/N7/PPPP1PPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Lasker-Dunne Attack",
    pgn: "1. e4 c5 2. g3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/6P1/PPPP1P1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Mengarini Variation",
    pgn: "1. e4 c5 2. a3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/P7/1PPP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Myers Attack, with a4",
    pgn: "1. e4 c5 2. a4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/P3P3/8/1PPP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Myers Attack, with h4",
    pgn: "1. e4 c5 2. h4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P2P/8/PPPP1PP1/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Snyder Variation",
    pgn: "1. e4 c5 2. b3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Staunton-Cochrane Variation",
    pgn: "1. e4 c5 2. c4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit",
    pgn: "1. e4 c5 2. b4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: McDonnell Attack",
    pgn: "1. e4 c5 2. f4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit",
    pgn: "1. e4 c5 2. d4",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation",
    pgn: "1. e4 c5 2. c3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "B27",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "C00",
    name: "French Defense: Banzai-Leong Gambit",
    pgn: "1. e4 e6 2. b4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Bird Invitation",
    pgn: "1. e4 e6 2. Bb5",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/1B6/4P3/8/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "C00",
    name: "French Defense: Chigorin Variation",
    pgn: "1. e4 e6 2. Qe2",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPPQPPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "C00",
    name: "French Defense: Horwitz Attack",
    pgn: "1. e4 e6 2. b3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: King's Indian Attack",
    pgn: "1. e4 e6 2. d3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Knight Variation",
    pgn: "1. e4 e6 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "C00",
    name: "French Defense: La Bourdonnais Variation",
    pgn: "1. e4 e6 2. f4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Normal Variation",
    pgn: "1. e4 e6 2. d4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Queen's Knight",
    pgn: "1. e4 e6 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "C00",
    name: "French Defense: Réti-Spielmann Attack",
    pgn: "1. e4 e6 2. g3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/4P3/6P1/PPPP1P1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Steiner Variation",
    pgn: "1. e4 e6 2. c4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C00",
    name: "French Defense: Steinitz Attack",
    pgn: "1. e4 e6 2. e5",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/4p3/4P3/8/8/PPPP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "Bongcloud Attack",
    pgn: "1. e4 e5 2. Ke2",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPPKPPP/RNBQ1BNR b kq - 1 2",
  },
  {
    eco: "C20",
    name: "Center Game",
    pgn: "1. e4 e5 2. d4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "English Opening: The Whale",
    pgn: "1. e4 e5 2. c4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Alapin Opening",
    pgn: "1. e4 e5 2. Ne2",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPPNPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: King's Head Opening",
    pgn: "1. e4 e5 2. f3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/5P2/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Leonardis Variation",
    pgn: "1. e4 e5 2. d3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack",
    pgn: "1. e4 e5 2. c3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/2P5/PP1P1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Mengarini's Opening",
    pgn: "1. e4 e5 2. a3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/P7/1PPP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Napoleon Attack",
    pgn: "1. e4 e5 2. Qf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/5Q2/PPPP1PPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Tortoise Opening",
    pgn: "1. e4 e5 2. Bd3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/3B4/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack",
    pgn: "1. e4 e5 2. Qh5",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "C20",
    name: "King's Pawn Opening",
    pgn: "1. e4 e5 2. b3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C20",
    name: "Portuguese Opening",
    pgn: "1. e4 e5 2. Bb5",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/1B2p3/4P3/8/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "C23",
    name: "Bishop's Opening",
    pgn: "1. e4 e5 2. Bc4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq - 1 2",
  },
  {
    eco: "C25",
    name: "Vienna Game",
    pgn: "1. e4 e5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "C30",
    name: "King's Gambit",
    pgn: "1. e4 e5 2. f4",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "C40",
    name: "King's Knight Opening",
    pgn: "1. e4 e5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "D00",
    name: "Amazon Attack",
    pgn: "1. d4 d5 2. Qd3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P4/3Q4/PPP1PPPP/RNB1KBNR b KQkq - 1 2",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit",
    pgn: "1. d4 d5 2. e4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5 2. e3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System",
    pgn: "1. d4 d5 2. Bf4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq - 1 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 d5 2. Nc3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR b KQkq - 1 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Levitsky Attack",
    pgn: "1. d4 d5 2. Bg5",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq - 1 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Mason Attack",
    pgn: "1. d4 d5 2. f4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P1P2/8/PPP1P1PP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Zurich Gambit",
    pgn: "1. d4 d5 2. g4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Zukertort Variation",
    pgn: "1. d4 d5 2. Nf3",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq - 1 2",
  },
  {
    eco: "D06",
    name: "Queen's Gambit",
    pgn: "1. d4 d5 2. c4",
    length: 3,
    fen: "rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR b KQkq - 0 2",
  },
  {
    eco: "A00",
    name: "Barnes Opening: Fool's Mate",
    pgn: "1. f3 e5 2. g4 Qh4#",
    length: 4,
    fen: "rnb1kbnr/pppp1ppp/8/4p3/6Pq/5P2/PPPPP2P/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Creepy Crawly Formation: Classical Defense",
    pgn: "1. h3 d5 2. a3 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/8/P6P/1PPPPPP1/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit Declined",
    pgn: "1. g4 d5 2. Bg2 c6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/6P1/8/PPPPPPBP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: London Defense",
    pgn: "1. g4 e5 2. h3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/6P1/7P/PPPPPP2/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Catalan Formation",
    pgn: "1. g3 d5 2. Bg2 e6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Modern Defense",
    pgn: "1. g3 d5 2. Bg2 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Slav Formation",
    pgn: "1. g3 d5 2. Bg2 c6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Van Kuijk Gambit",
    pgn: "1. g3 h5 2. Nf3 h4",
    length: 4,
    fen: "rnbqkbnr/ppppppp1/8/8/7p/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Baltic Defense",
    pgn: "1. b4 d5 2. Bb2 Bf5",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p1b2/1P6/8/PBPPPPPP/RN1QKBNR w KQkq - 2 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Czech Defense",
    pgn: "1. b4 e5 2. Bb2 d6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/1P6/8/PBPPPPPP/RN1QKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: German Defense",
    pgn: "1. b4 d5 2. Bb2 Qd6",
    length: 4,
    fen: "rnb1kbnr/ppp1pppp/3q4/3p4/1P6/8/PBPPPPPP/RN1QKBNR w KQkq - 2 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation",
    pgn: "1. b4 Nf6 2. Bb2 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/1P6/8/PBPPPPPP/RN1QKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Wolferts Gambit",
    pgn: "1. b4 e5 2. Bb2 c5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/8/2p1p3/1P6/8/PBPPPPPP/RN1QKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Billockus-Johansen Gambit",
    pgn: "1. Nc3 e5 2. Nf3 Bc5",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/8/2N2N2/PPPPPPPP/R1BQKB1R w KQkq - 2 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Damhaug Gambit",
    pgn: "1. Nc3 d5 2. f4 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/5P2/2N5/PPPPP1PP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Warsteiner Gambit",
    pgn: "1. Nc3 d5 2. f4 g5",
    length: 4,
    fen: "rnbqkbnr/ppp1pp1p/8/3p2p1/5P2/2N5/PPPPP1PP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Ware Opening: Wing Gambit",
    pgn: "1. a4 b5 2. axb5 Bb7",
    length: 4,
    fen: "rn1qkbnr/pbpppppp/8/1P6/8/8/1PPPPPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5 2. Bb2 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P6/PBPPPPPP/RN1QKBNR w KQkq - 2 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Double Duck Formation",
    pgn: "1. f4 f5 2. d4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp1p1pp/8/3p1p2/3P1P2/8/PPP1P1PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Hobbs-Zilbermints Gambit",
    pgn: "1. f4 h6 2. Nf3 g5",
    length: 4,
    fen: "rnbqkbnr/pppppp2/7p/6p1/5P2/5N2/PPPPP1PP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Lasker Gambit",
    pgn: "1. f4 e5 2. fxe5 f6",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/5p2/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Platz Gambit",
    pgn: "1. f4 e5 2. fxe5 Ne7",
    length: 4,
    fen: "rnbqkb1r/ppppnppp/8/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Schlechter Gambit",
    pgn: "1. f4 e5 2. fxe5 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A04",
    name: "Zukertort Defense: Kingside Variation",
    pgn: "1. Nf3 Nh6 2. d4 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/6pn/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A04",
    name: "Zukertort Defense: Sicilian Knight Variation",
    pgn: "1. Nf3 Na6 2. e4 c5",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/n7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Drunken Cavalry Variation",
    pgn: "1. Nf3 Na6 2. e4 Nh6",
    length: 4,
    fen: "r1bqkb1r/pppppppp/n6n/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 1 3",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Vos Gambit",
    pgn: "1. Nf3 d6 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Wade Defense",
    pgn: "1. Nf3 d6 2. e4 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/3p4/8/4P1b1/5N2/PPPP1PPP/RNBQKB1R w KQkq - 1 3",
  },
  {
    eco: "A05",
    name: "King's Indian Attack",
    pgn: "1. Nf3 Nf6 2. g3 d5",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Spassky Variation",
    pgn: "1. Nf3 Nf6 2. g3 b5",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/5n2/1p6/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Symmetrical Defense",
    pgn: "1. Nf3 Nf6 2. g3 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening",
    pgn: "1. Nf3 Nf6 2. Nc3 Nc6",
    length: 4,
    fen: "r1bqkb1r/pppppppp/2n2n2/8/8/2N2N2/PPPPPPPP/R1BQKB1R w KQkq - 4 3",
  },
  {
    eco: "A07",
    name: "Hungarian Opening: Wiedenhagen-Beta Gambit",
    pgn: "1. g3 d5 2. Nf3 g5",
    length: 4,
    fen: "rnbqkbnr/ppp1pp1p/8/3p2p1/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Double Fianchetto",
    pgn: "1. Nf3 d5 2. g3 g6",
    length: 4,
    fen: "rnbqkbnr/ppp1pp1p/6p1/3p4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Keres Variation",
    pgn: "1. Nf3 d5 2. g3 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p4/6b1/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 1 3",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Omega-Delta Gambit",
    pgn: "1. Nf3 d5 2. g3 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Sicilian Variation",
    pgn: "1. Nf3 d5 2. g3 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Advance Variation",
    pgn: "1. Nf3 d5 2. c4 d4",
    length: 4,
    fen: "rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Réti Accepted",
    pgn: "1. Nf3 d5 2. c4 dxc4",
    length: 4,
    fen: "rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Zilbermints Gambit",
    pgn: "1. Nf3 d5 2. c4 b5",
    length: 4,
    fen: "rnbqkbnr/p1p1pppp/8/1p1p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A10",
    name: "English Opening: Adorjan Defense",
    pgn: "1. c4 g6 2. e4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp1p1p/6p1/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Löhn Gambit",
    pgn: "1. c4 d5 2. cxd5 e6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/4p3/3P4/8/8/PP1PPPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Schulz Gambit",
    pgn: "1. c4 d5 2. cxd5 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PP1PPPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A10",
    name: "English Opening: Myers Gambit",
    pgn: "1. c4 g5 2. d4 Bg7",
    length: 4,
    fen: "rnbqk1nr/ppppppbp/8/6p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A10",
    name: "English Opening: Zilbermints Gambit",
    pgn: "1. c4 g5 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp1p1p/8/4p1p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense",
    pgn: "1. c4 e6 2. Nf3 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Old Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 d6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 b6",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/1p3n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Scandinavian Defense",
    pgn: "1. Nf3 Nf6 2. c4 d5",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Grünfeld Defense",
    pgn: "1. c4 Nf6 2. Nc3 d5",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Hedgehog System",
    pgn: "1. c4 Nf6 2. Nc3 e6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A20",
    name: "English Opening: Drill Variation",
    pgn: "1. c4 e5 2. g3 h5",
    length: 4,
    fen: "rnbqkbnr/pppp1pp1/8/4p2p/2P5/6P1/PP1PPP1P/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Nimzowitsch-Flohr Variation",
    pgn: "1. c4 e5 2. Nf3 e4",
    length: 4,
    fen: "rnbqkbnr/pppp1ppp/8/8/2P1p3/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Kramnik-Shirov Counterattack",
    pgn: "1. c4 e5 2. Nc3 Bb4",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Reversed Closed Sicilian",
    pgn: "1. c4 e5 2. Nc3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "A35",
    name: "English Opening: Symmetrical Variation, Two Knights Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "A40",
    name: "English Defense",
    pgn: "1. d4 e6 2. c4 b6",
    length: 4,
    fen: "rnbqkbnr/p1pp1ppp/1p2p3/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Hartlaub-Charlick Gambit",
    pgn: "1. d4 e5 2. dxe5 d6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4P3/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Mosquito Gambit",
    pgn: "1. d4 e5 2. dxe5 Qh4",
    length: 4,
    fen: "rnb1kbnr/pppp1ppp/8/4P3/7q/8/PPP1PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Soller Gambit",
    pgn: "1. d4 e5 2. dxe5 f6",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/5p2/4P3/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Krebs",
    pgn: "1. d4 e5 2. Nf3 e4",
    length: 4,
    fen: "rnbqkbnr/pppp1ppp/8/8/3Pp3/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Horwitz Defense: Zilbermints Gambit",
    pgn: "1. d4 e6 2. c4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp1ppp/8/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Kangaroo Defense",
    pgn: "1. d4 e6 2. c4 Bb4+",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A40",
    name: "Montevideo Defense",
    pgn: "1. d4 Nc6 2. d5 Nb8",
    length: 4,
    fen: "rnbqkbnr/pppppppp/8/3P4/8/8/PPP1PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A40",
    name: "Queen's Pawn Game: Anglo-Slav Opening",
    pgn: "1. d4 c6 2. c4 d6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2pp4/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat",
    pgn: "1. d4 d6 2. c4 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A41",
    name: "Wade Defense",
    pgn: "1. d4 d6 2. Nf3 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni Gambit, Schlenker Defense",
    pgn: "1. d4 c5 2. dxc5 Na6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/n7/2P5/8/8/PPP1PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Indian Defense",
    pgn: "1. d4 c5 2. d5 Nf6",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Cormorant Gambit",
    pgn: "1. d4 c5 2. dxc5 b6",
    length: 4,
    fen: "rnbqkbnr/p2ppppp/1p6/2P5/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5 d6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni, Mujannah Formation",
    pgn: "1. d4 c5 2. d5 f5",
    length: 4,
    fen: "rnbqkbnr/pp1pp1pp/8/2pP1p2/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Snail Variation",
    pgn: "1. d4 c5 2. d5 Na6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/n7/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A44",
    name: "Benoni Defense: Old Benoni",
    pgn: "1. d4 c5 2. d5 e5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR w KQkq e6 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit Accepted",
    pgn: "1. d4 Nf6 2. g4 Nxg4",
    length: 4,
    fen: "rnbqkb1r/pppppppp/8/8/3P2n1/8/PPP1PP1P/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Oshima Defense",
    pgn: "1. d4 Nf6 2. g4 e5",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/3P2P1/8/PPP1PP1P/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Lazard Gambit",
    pgn: "1. d4 Nf6 2. Nd2 e5",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/3P4/8/PPPNPPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Maddigan Gambit",
    pgn: "1. d4 Nf6 2. Nc3 e5",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Reversed Chigorin Defense",
    pgn: "1. d4 Nf6 2. Nc3 c5",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Classical Defense",
    pgn: "1. d4 Nf6 2. Bg5 e6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/8/PPP1PPPP/RN1QKBNR w KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Döry Defense",
    pgn: "1. d4 Nf6 2. Nf3 Ne4",
    length: 4,
    fen: "rnbqkb1r/pppppppp/8/8/3Pn3/5N2/PPP1PPPP/RNBQKB1R w KQkq - 3 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: Czech-Indian",
    pgn: "1. d4 Nf6 2. Nf3 c6",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/2p2n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: Knights Variation, Alburt-Miles Variation",
    pgn: "1. d4 Nf6 2. Nf3 a6",
    length: 4,
    fen: "rnbqkb1r/1ppppppp/p4n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: Polish Variation",
    pgn: "1. d4 Nf6 2. Nf3 b5",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/5n2/1p6/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: Spielmann-Indian",
    pgn: "1. d4 Nf6 2. Nf3 c5",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: Wade-Tartakower Defense",
    pgn: "1. d4 Nf6 2. Nf3 d6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A47",
    name: "Pseudo Queen's Indian Defense",
    pgn: "1. d4 Nf6 2. Nf3 b6",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A48",
    name: "East Indian Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A50",
    name: "Indian Defense: Medusa Gambit",
    pgn: "1. d4 Nf6 2. c4 g5",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5n2/6p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A50",
    name: "Indian Defense: Pyrenees Gambit",
    pgn: "1. d4 Nf6 2. c4 b5",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/5n2/1p6/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A50",
    name: "Mexican Defense",
    pgn: "1. d4 Nf6 2. c4 Nc6",
    length: 4,
    fen: "r1bqkb1r/pppppppp/2n2n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "A50",
    name: "Queen's Indian Accelerated",
    pgn: "1. d4 Nf6 2. c4 b6",
    length: 4,
    fen: "rnbqkb1r/p1pppppp/1p3n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A50",
    name: "Slav Indian",
    pgn: "1. d4 Nf6 2. c4 c6",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/2p2n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense",
    pgn: "1. d4 Nf6 2. c4 e5",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A53",
    name: "Old Indian Defense",
    pgn: "1. d4 Nf6 2. c4 d6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A56",
    name: "Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 c5",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Hevendehl Gambit",
    pgn: "1. d4 f5 2. g4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/3P2P1/8/PPP1PP1P/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Omega-Isis Gambit",
    pgn: "1. d4 f5 2. Nf3 e5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Queen's Pawn Game: Veresov Attack, Dutch System",
    pgn: "1. d4 f5 2. Nc3 d5",
    length: 4,
    fen: "rnbqkbnr/ppp1p1pp/8/3p1p2/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit Accepted",
    pgn: "1. d4 f5 2. e4 fxe4",
    length: 4,
    fen: "rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A82",
    name: "Rat Defense: Balogh Defense",
    pgn: "1. e4 d6 2. d4 f5",
    length: 4,
    fen: "rnbqkbnr/ppp1p1pp/3p4/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 e6",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Normal Variation",
    pgn: "1. d4 f5 2. c4 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Borg Defense: Borg Gambit",
    pgn: "1. e4 g5 2. d4 Bg7",
    length: 4,
    fen: "rnbqk1nr/ppppppbp/8/6p1/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Borg Defense: Zilbermints Gambit",
    pgn: "1. e4 g5 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp1p1p/8/4p1p1/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Carr Defense: Zilbermints Gambit",
    pgn: "1. e4 h6 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/pppp1pp1/7p/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Fried Fox Defense",
    pgn: "1. e4 f6 2. d4 Kf7",
    length: 4,
    fen: "rnbq1bnr/pppppkpp/5p2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQ - 1 3",
  },
  {
    eco: "B00",
    name: "Goldsmith Defense: Picklepuss Defense",
    pgn: "1. e4 h5 2. d4 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppppppp1/5n2/7p/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Guatemala Defense",
    pgn: "1. e4 b6 2. d4 Ba6",
    length: 4,
    fen: "rn1qkbnr/p1pppppp/bp6/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Colorado Countergambit",
    pgn: "1. e4 Nc6 2. Nf3 f5",
    length: 4,
    fen: "r1bqkbnr/ppppp1pp/2n5/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Franco-Nimzowitsch Variation",
    pgn: "1. e4 Nc6 2. Nf3 e6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n1p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: French Connection",
    pgn: "1. e4 Nc6 2. Nc3 e6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n1p3/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation",
    pgn: "1. e4 Nc6 2. d4 e5",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Mikenas Variation",
    pgn: "1. e4 Nc6 2. d4 d6",
    length: 4,
    fen: "r1bqkbnr/ppp1pppp/2np4/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Neo-Mongoloid Defense",
    pgn: "1. e4 Nc6 2. d4 f6",
    length: 4,
    fen: "r1bqkbnr/ppppp1pp/2n2p2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Pirc Connection",
    pgn: "1. e4 Nc6 2. Nc3 g6",
    length: 4,
    fen: "r1bqkbnr/pppppp1p/2n3p1/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation",
    pgn: "1. e4 Nc6 2. d4 d5",
    length: 4,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Williams Variation",
    pgn: "1. e4 Nc6 2. Nf3 d6",
    length: 4,
    fen: "r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Woodchuck Variation",
    pgn: "1. e4 Nc6 2. d4 a6",
    length: 4,
    fen: "r1bqkbnr/1ppppppp/p1n5/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Rat Defense: Antal Defense",
    pgn: "1. e4 d6 2. d4 Nd7",
    length: 4,
    fen: "r1bqkbnr/pppnpppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "St. George Defense: Zilbermints Gambit",
    pgn: "1. e4 a6 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/1ppp1ppp/p7/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Ware Defense: Snagglepuss Defense",
    pgn: "1. e4 a5 2. d4 Nc6",
    length: 4,
    fen: "r1bqkbnr/1ppppppp/2n5/p7/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Blackburne-Kloosterboer Gambit",
    pgn: "1. e4 d5 2. exd5 c6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3P4/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Mieses-Kotroc Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5",
    length: 4,
    fen: "rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation",
    pgn: "1. e4 d5 2. exd5 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Brooklyn Variation",
    pgn: "1. e4 Nf6 2. e5 Ng8",
    length: 4,
    fen: "rnbqkbnr/pppppppp/8/4P3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe",
    pgn: "1. e4 Nf6 2. e5 Ne4",
    length: 4,
    fen: "rnbqkb1r/pppppppp/8/4P3/4n3/8/PPPP1PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Normal Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5",
    length: 4,
    fen: "rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation",
    pgn: "1. e4 Nf6 2. Nc3 d5",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "B06",
    name: "Modern Defense",
    pgn: "1. e4 g6 2. d4 Bg7",
    length: 4,
    fen: "rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Fianchetto Gambit",
    pgn: "1. e4 g6 2. d4 f5",
    length: 4,
    fen: "rnbqkbnr/ppppp2p/6p1/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Norwegian Defense",
    pgn: "1. e4 g6 2. d4 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B07",
    name: "King's Pawn Game: Maróczy Defense",
    pgn: "1. e4 d6 2. d4 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. Nc3 d5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack",
    pgn: "1. e4 c6 2. c4 d5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Open Variation",
    pgn: "1. e4 c6 2. c4 e5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/2p5/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: De Bruycker Defense",
    pgn: "1. e4 c6 2. d4 Na6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/n1p5/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Masi Variation",
    pgn: "1. e4 c6 2. d4 Nf6",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/2p2n2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Massachusetts Defense",
    pgn: "1. e4 c6 2. d4 f5",
    length: 4,
    fen: "rnbqkbnr/pp1pp1pp/2p5/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Snyder Variation, Queen Fianchetto Variation",
    pgn: "1. e4 c5 2. b3 b6",
    length: 4,
    fen: "rnbqkbnr/p2ppppp/1p6/2p5/4P3/1P6/P1PP1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 e6",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Traditional",
    pgn: "1. e4 c5 2. Nc3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Brussels Gambit",
    pgn: "1. e4 c5 2. Nf3 f5",
    length: 4,
    fen: "rnbqkbnr/pp1pp1pp/8/2p2p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Bücker Variation",
    pgn: "1. e4 c5 2. Nf3 h6",
    length: 4,
    fen: "rnbqkbnr/pp1pppp1/7p/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 g6",
    length: 4,
    fen: "rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Jalalabad Variation",
    pgn: "1. e4 c5 2. Nf3 e5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/8/2p1p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Katalimov Variation",
    pgn: "1. e4 c5 2. Nf3 b6",
    length: 4,
    fen: "rnbqkbnr/p2ppppp/1p6/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Mongoose Variation",
    pgn: "1. e4 c5 2. Nf3 Qa5",
    length: 4,
    fen: "rnb1kbnr/pp1ppppp/8/q1p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Polish Gambit",
    pgn: "1. e4 c5 2. Nf3 b5",
    length: 4,
    fen: "rnbqkbnr/p2ppppp/8/1pp5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Quinteros Variation",
    pgn: "1. e4 c5 2. Nf3 Qc7",
    length: 4,
    fen: "rnb1kbnr/ppqppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation",
    pgn: "1. e4 c5 2. Nf3 a6",
    length: 4,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6",
    length: 4,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Old Sicilian",
    pgn: "1. e4 c5 2. Nf3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation",
    pgn: "1. e4 c5 2. Nf3 e6",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations",
    pgn: "1. e4 c5 2. Nf3 d6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense",
    pgn: "1. e4 e6 2. d4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Baeuerle Gambit",
    pgn: "1. e4 e6 2. d4 b5",
    length: 4,
    fen: "rnbqkbnr/p1pp1ppp/4p3/1p6/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. d4 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Sicilian Defense",
    pgn: "1. e4 e6 2. d4 c5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: King's Indian Attack, Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. d3 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Knight Variation, Franco-Hiva Gambit",
    pgn: "1. e4 e6 2. Nf3 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Mediterranean Defense",
    pgn: "1. e4 e6 2. d4 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense",
    pgn: "1. e4 e6 2. d4 a6",
    length: 4,
    fen: "rnbqkbnr/1ppp1ppp/p3p3/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "Rat Defense: Small Center Defense",
    pgn: "1. d4 e6 2. e4 d6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3pp3/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Bavarian Gambit",
    pgn: "1. e4 e5 2. c4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Beyer Gambit",
    pgn: "1. e4 e5 2. d4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Clam Variation, King's Gambit Reversed",
    pgn: "1. e4 e5 2. d3 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack, Lasa Gambit",
    pgn: "1. e4 e5 2. c3 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack, Kiddie Countergambit",
    pgn: "1. e4 e5 2. Qh5 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR w KQkq - 2 3",
  },
  {
    eco: "C21",
    name: "Center Game Accepted",
    pgn: "1. e4 e5 2. d4 exd4",
    length: 4,
    fen: "rnbqkbnr/pppp1ppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Boi Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq - 2 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Calabrese Countergambit",
    pgn: "1. e4 e5 2. Bc4 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Khan Gambit",
    pgn: "1. e4 e5 2. Bc4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Philidor Counterattack",
    pgn: "1. e4 e5 2. Bc4 c6",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/2p5/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq - 0 3",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Berlin Defense",
    pgn: "1. e4 e5 2. Bc4 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq - 2 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Anderssen Defense",
    pgn: "1. e4 e5 2. Nc3 Bc5",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Max Lange Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "C26",
    name: "Vienna Game: Falkbeer Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation",
    pgn: "1. e4 e5 2. f4 Bc5",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene's Defense",
    pgn: "1. e4 e5 2. f4 Qh4+",
    length: 4,
    fen: "rnb1kbnr/pppp1ppp/8/4p3/4PP1q/8/PPPP2PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Mafia Defense",
    pgn: "1. e4 c5 2. f4 e5",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/8/2p1p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation",
    pgn: "1. e4 e5 2. f4 Qf6",
    length: 4,
    fen: "rnb1kbnr/pppp1ppp/5q2/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit",
    pgn: "1. e4 e5 2. f4 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Petrov's Defense",
    pgn: "1. e4 e5 2. f4 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Queen's Knight Defense",
    pgn: "1. e4 e5 2. f4 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Zilbermints Double Countergambit",
    pgn: "1. e4 e5 2. f4 g5",
    length: 4,
    fen: "rnbqkbnr/pppp1p1p/8/4p1p1/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit",
    pgn: "1. e4 e5 2. f4 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted",
    pgn: "1. e4 e5 2. f4 exf4",
    length: 4,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Elephant Gambit",
    pgn: "1. e4 e5 2. Nf3 d5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Gunderam Defense",
    pgn: "1. e4 e5 2. Nf3 Qe7",
    length: 4,
    fen: "rnb1kbnr/ppppqppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Busch-Gass Gambit",
    pgn: "1. e4 e5 2. Nf3 Bc5",
    length: 4,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense",
    pgn: "1. e4 e5 2. Nf3 f6",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/5p2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Gunderam Gambit",
    pgn: "1. e4 e5 2. Nf3 c6",
    length: 4,
    fen: "rnbqkbnr/pp1p1ppp/2p5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: McConnell Defense",
    pgn: "1. e4 e5 2. Nf3 Qf6",
    length: 4,
    fen: "rnb1kbnr/pppp1ppp/5q2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit",
    pgn: "1. e4 e5 2. Nf3 f5",
    length: 4,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6",
    length: 4,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "C44",
    name: "King's Knight Opening: Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6",
    length: 4,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game",
    pgn: "1. d4 d5 2. e3 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4P3/PPP2PPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit",
    pgn: "1. d4 d5 2. Bf4 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Alburt Defense",
    pgn: "1. d4 d5 2. Nc3 Bf5",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p1b2/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Anti-Veresov",
    pgn: "1. d4 d5 2. Nc3 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p4/3P2b1/2N5/PPP1PPPP/R1BQKBNR w KQkq - 2 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Irish Gambit",
    pgn: "1. d4 d5 2. Nc3 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Shaviliuk Gambit",
    pgn: "1. d4 d5 2. Nc3 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Shropshire Defense",
    pgn: "1. d4 d5 2. Nc3 h5",
    length: 4,
    fen: "rnbqkbnr/ppp1ppp1/8/3p3p/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Levitsky Attack, Welling Variation",
    pgn: "1. d4 d5 2. Bg5 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p2B1/3P2b1/8/PPP1PPPP/RN1QKBNR w KQkq - 2 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Anti-Torre",
    pgn: "1. Nf3 d5 2. d4 Bg4",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p4/3P2b1/5N2/PPP1PPPP/RNBQKB1R w KQkq - 1 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Chigorin Variation",
    pgn: "1. d4 d5 2. Nf3 Nc6",
    length: 4,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Krause Variation",
    pgn: "1. d4 d5 2. Nf3 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Symmetrical Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq - 2 3",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense",
    pgn: "1. d4 d5 2. c4 c5",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense",
    pgn: "1. d4 d5 2. c4 Bf5",
    length: 4,
    fen: "rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Marshall Defense",
    pgn: "1. d4 d5 2. c4 Nf6",
    length: 4,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Zilbermints Gambit",
    pgn: "1. d4 d5 2. c4 b5",
    length: 4,
    fen: "rnbqkbnr/p1p1pppp/8/1p1p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6",
    length: 4,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 1 3",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit",
    pgn: "1. d4 d5 2. c4 e5",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6",
    length: 4,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted",
    pgn: "1. d4 d5 2. c4 dxc4",
    length: 4,
    fen: "rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 e6",
    length: 4,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "Indian Defense: West Indian Defense",
    pgn: "1. d4 Nf6 2. c4 g6",
    length: 4,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Amar Opening: Paris Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/5P2/6PN/PPPPP2P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Barnes Opening: Gedult Gambit",
    pgn: "1. f3 f5 2. e4 fxe4 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppppp1pp/8/8/4p3/2N2P2/PPPP2PP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Basman Gambit",
    pgn: "1. g4 d5 2. Bg2 h5 3. gxh5",
    length: 5,
    fen: "rnbqkbnr/ppp1ppp1/8/3p3P/8/8/PPPPPPBP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Fritz Gambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4",
    length: 5,
    fen: "rn1qkbnr/ppp1pppp/8/3p4/2P3b1/8/PP1PPPBP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Spike Attack",
    pgn: "1. g4 d5 2. Bg2 c6 3. g5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p2P1/8/8/PPPPPPBP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Spike, Hurst Attack",
    pgn: "1. g4 e5 2. Bg2 d5 3. c4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/2P3P1/8/PP1PPPBP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/4p1P1/2N5/PPPP1P1P/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Bücker Gambit",
    pgn: "1. g3 d5 2. Bg2 e5 3. b4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/1P6/6P1/P1PPPPBP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Norwegian Defense",
    pgn: "1. g3 e5 2. Nf3 e4 3. Nh4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4p2N/6P1/PPPPPP1P/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 e5 2. d4 exd4 3. c3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3p3P/2P5/PP2PPP1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Myers Variation",
    pgn: "1. h4 d5 2. d4 c5 3. e4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/3PP2P/8/PPP2PP1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Steinbok Gambit",
    pgn: "1. h4 f5 2. e4 fxe4 3. d3",
    length: 5,
    fen: "rnbqkbnr/ppppp1pp/8/8/4p2P/3P4/PPP2PP1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Bugayev Advance Variation",
    pgn: "1. b4 e5 2. Bb2 f6 3. b5",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/5p2/1P2p3/8/8/PBPPPPPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation, Schiffler Attack",
    pgn: "1. b4 Nf6 2. Bb2 g6 3. e4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Myers Variation",
    pgn: "1. b4 d5 2. Bb2 c6 3. a4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/PP6/8/1BPPPPPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Polish Opening: Tartakower Gambit",
    pgn: "1. b4 e5 2. Bb2 f6 3. e4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/5p2/4p3/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Caro-Kann Variation, St. Patrick's Attack",
    pgn: "1. Nc3 d5 2. e4 c6 3. h3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N4P/PPPP1PP1/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Dougherty Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. f3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/4p3/2N2P2/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Dunst-Perrenet Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. d3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/4p3/2NP4/PPP2PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hector Gambit",
    pgn: "1. Nc3 d5 2. e4 dxe4 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2B1p3/2N5/PPPP1PPP/R1BQK1NR b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Kluever Gambit",
    pgn: "1. Nc3 f5 2. e4 fxe4 3. d3",
    length: 5,
    fen: "rnbqkbnr/ppppp1pp/8/8/4p3/2NP4/PPP2PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Napoleon Attack",
    pgn: "1. Nc3 e5 2. Nf3 Nc6 3. d4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/3P4/2N2N2/PPP1PPPP/R1BQKB1R b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Nowokunski Gambit",
    pgn: "1. Nc3 e5 2. f4 exf4 3. e4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/2N5/PPPP2PP/R1BQKBNR b KQkq e3 0 3",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Venezolana Variation",
    pgn: "1. Nc3 d5 2. d3 Nf6 3. g3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/8/2NP2P1/PPP1PP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Bouncing Bishop Variation",
    pgn: "1. e3 e5 2. Bc4 b5 3. Bb3",
    length: 5,
    fen: "rnbqkbnr/p1pp1ppp/8/1p2p3/8/1B2P3/PPPP1PPP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Venezolana Opening",
    pgn: "1. d3 c5 2. Nc3 Nc6 3. g3",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/8/2NP2P1/PPP1PP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Modern Variation",
    pgn: "1. b3 e5 2. Bb2 Nc6 3. e3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P2P3/PBPP1PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Pachman Gambit",
    pgn: "1. b3 e5 2. Bb2 Nc6 3. f4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/5P2/1P6/PBPPP1PP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Ringelbach Gambit",
    pgn: "1. b3 f5 2. Bb2 e6 3. e4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A01",
    name: "Nimzo-Larsen Attack: Spike Variation",
    pgn: "1. b3 Nf6 2. Bb2 g6 3. g4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/6P1/1P6/PBPPPP1P/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A02",
    name: "Bird Opening: Batavo-Polish Attack",
    pgn: "1. f4 Nf6 2. Nf3 g6 3. b4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/1P3P2/5N2/P1PPP1PP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Lisitsyn Gambit Deferred",
    pgn: "1. Nf3 f5 2. d3 Nf6 3. e4",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Speelsmet Gambit",
    pgn: "1. Nf3 c5 2. d4 cxd4 3. e3",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/3p4/4PN2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Smyslov Variation",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. b4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/1P6/5NP1/P1PPPP1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Myers Polish Attack",
    pgn: "1. Nf3 Nf6 2. a4 g6 3. b4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/PP6/5N2/2PPPPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Norfolk Gambit",
    pgn: "1. Nf3 d5 2. b3 c5 3. e4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A08",
    name: "King's Indian Attack: Sicilian Variation",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Penguin Variation",
    pgn: "1. Nf3 d5 2. c4 d4 3. Rg1",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKBR1 b Qkq - 1 3",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Variation, Chabanon Gambit",
    pgn: "1. c4 f5 2. Nf3 d6 3. e4",
    length: 5,
    fen: "rnbqkbnr/ppp1p1pp/3p4/5p2/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Dutch Variation, Ferenc Gambit",
    pgn: "1. c4 f5 2. Nc3 Nf6 3. e4",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/2P1P3/2N5/PP1P1PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 d5 2. c4 c6 3. b3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation",
    pgn: "1. c4 e5 2. Nc3 d6 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq - 1 3",
  },
  {
    eco: "A22",
    name: "English Opening: Carls-Bremen System",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A22",
    name: "English Opening: King's English, Mazedonisch",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. f4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2P2P2/2N5/PP1PP1PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A27",
    name: "English Opening: King's English Variation, Three Knights System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. Nf3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation, Napolitano Gambit",
    pgn: "1. c4 c5 2. Nf3 Nf6 3. b4",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/1PP5/5N2/P2PPPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A31",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. Nf3",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Fianchetto Variation",
    pgn: "1. c4 Nf6 2. Nc3 c5 3. g3",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Three Knights Variation",
    pgn: "1. c4 c5 2. Nc3 Nf6 3. Nf3",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Two Knights, Fianchetto Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Brooklyn",
    pgn: "1. d4 e5 2. Nf3 e4 3. Ng1",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3Pp3/8/PPP1PPPP/RNBQKBNR b KQkq - 1 3",
  },
  {
    eco: "A40",
    name: "Englund Gambit Declined: Reversed Mokele Mbembe",
    pgn: "1. d4 e5 2. Nf3 e4 3. Ne5",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/4N3/3Pp3/8/PPP1PPPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "A40",
    name: "Kangaroo Defense: Keres Defense, Transpositional Variation",
    pgn: "1. d4 e6 2. c4 Bb4+ 3. Nc3",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/4p3/8/1bPP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "A40",
    name: "Modern Defense: Lizard Defense, Pirc-Diemer Gambit",
    pgn: "1. d4 g6 2. h4 Nf6 3. h5",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/7P/3P4/8/PPP1PPP1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A40",
    name: "Polish Defense: Spassky Gambit Accepted",
    pgn: "1. d4 b5 2. e4 Bb7 3. Bxb5",
    length: 5,
    fen: "rn1qkbnr/pbpppppp/8/1B6/3PP3/8/PPP2PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Indian Defense, Kingside Move Order",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Benoni-Staunton Gambit",
    pgn: "1. d4 c5 2. d5 f5 3. e4",
    length: 5,
    fen: "rnbqkbnr/pp1pp1pp/8/2pP1p2/4P3/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: French Benoni",
    pgn: "1. e4 e6 2. d4 c5 3. d5",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2pP4/4P3/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit",
    pgn: "1. d4 c5 2. Nf3 cxd4 3. b4",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/1P1p4/5N2/P1P1PPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A43",
    name: "Queen's Pawn Game: Liedmann Gambit",
    pgn: "1. d4 c5 2. c4 cxd4 3. e3",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/2Pp4/4P3/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Amazon Attack: Siberian Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Qd3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2NQ4/PPP1PPPP/R1B1KBNR b KQkq - 1 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gedult Attack, Gedult Attack",
    pgn: "1. d4 Nf6 2. f3 d5 3. g4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P2P1/5P2/PPP1P2P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Indian Defense: Paleface Attack, Blackmar-Diemer Gambit Deferred",
    pgn: "1. d4 Nf6 2. f3 d5 3. e4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Queen's Pawn Game: Veresov, Richter Attack",
    pgn: "1. d4 Nf6 2. f3 d5 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N2P2/PPP1P1PP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Classical Defense, Big Center Variation",
    pgn: "1. d4 Nf6 2. Bg5 e6 3. e4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/6B1/3PP3/8/PPP2PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Edge Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bh4",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/8/3Pn2B/8/PPP1PPPP/RN1QKBNR b KQkq - 4 3",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Raptor Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. h4",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/6B1/3Pn2P/8/PPP1PPP1/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A46",
    name: "Indian Defense: London System",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bf4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq - 1 3",
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq - 1 3",
  },
  {
    eco: "A46",
    name: "Yusupov-Rubinstein System",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A48",
    name: "London System",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bf4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq - 1 3",
  },
  {
    eco: "A48",
    name: "Torre Attack: Fianchetto Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq - 1 3",
  },
  {
    eco: "A49",
    name: "Indian Defense: Przepiorka Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Aged Gibbon Gambit",
    pgn: "1. d4 Nf6 2. c4 d6 3. g4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/2PP2P1/8/PP2PP1P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Janzen-Korchnoi Gambit",
    pgn: "1. d4 f5 2. h3 Nf6 3. g4",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/3P2P1/7P/PPP1PP2/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Kingfisher Gambit",
    pgn: "1. d4 f5 2. Nc3 d5 3. e4",
    length: 5,
    fen: "rnbqkbnr/ppp1p1pp/8/3p1p2/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Classical Line",
    pgn: "1. d4 f5 2. Qd3 e6 3. g4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Leningrad",
    pgn: "1. d4 f5 2. Qd3 g6 3. g4",
    length: 5,
    fen: "rnbqkbnr/ppppp2p/6p1/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Modern",
    pgn: "1. d4 f5 2. Qd3 d6 3. g4",
    length: 5,
    fen: "rnbqkbnr/ppp1p1pp/3p4/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Manhattan Gambit, Anti-Stonewall",
    pgn: "1. d4 f5 2. Qd3 d5 3. g4",
    length: 5,
    fen: "rnbqkbnr/ppp1p1pp/8/3p1p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Senechaud Gambit",
    pgn: "1. d4 f5 2. Bf4 e6 3. g4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/3P1BP1/8/PPP1PP1P/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Spielmann Gambit",
    pgn: "1. d4 f5 2. Nc3 Nf6 3. g4",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/3P2P1/2N5/PPP1PP1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit, American Attack",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nd2",
    length: 5,
    fen: "rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPPN1PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Bellon Gambit",
    pgn: "1. d4 f5 2. c4 e6 3. e4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/2PPP3/8/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Rubinstein Variation",
    pgn: "1. d4 f5 2. c4 e6 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A85",
    name: "Dutch Defense: Queen's Knight Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "A86",
    name: "Dutch Defense: Fianchetto Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3",
    length: 5,
    fen: "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Lion Defense: Lion's Jaw",
    pgn: "1. e4 d6 2. d4 Nf6 3. f3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Colorado Countergambit Accepted",
    pgn: "1. e4 Nc6 2. Nf3 f5 3. exf5",
    length: 5,
    fen: "r1bqkbnr/ppppp1pp/2n5/5P2/8/5N2/PPPP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Hornung Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Be3",
    length: 5,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Linksspringer Variation",
    pgn: "1. e4 Nc6 2. d4 e5 3. d5",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/3Pp3/4P3/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Advance Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. e5",
    length: 5,
    fen: "r1bqkbnr/ppp1pppp/2n5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3",
    length: 5,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "B00",
    name: "Owen Defense: Naselwaus Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Bg5",
    length: 5,
    fen: "rn1qkbnr/pbpppppp/1p6/6B1/3PP3/8/PPP2PPP/RN1QKBNR b KQkq - 2 3",
  },
  {
    eco: "B00",
    name: "Owen Defense: Smith Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Nf3",
    length: 5,
    fen: "rn1qkbnr/pbpppppp/1p6/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 3",
  },
  {
    eco: "B00",
    name: "Pirc Defense: Roscher Gambit",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nf3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 3",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Buckley Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Na3",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/3nP3/8/N7/PPPP1PPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Krejcik Variation, Krejcik Gambit",
    pgn: "1. e4 Nf6 2. Bc4 Nxe4 3. Bxf7+",
    length: 5,
    fen: "rnbqkb1r/pppppBpp/8/8/4n3/8/PPPP1PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Sämisch Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/3nP3/8/2N5/PPPP1PPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/3nP3/2P5/8/PP1P1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Welling Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. b3",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/3nP3/8/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4",
    length: 5,
    fen: "rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bc4",
    length: 5,
    fen: "rnbqk1nr/ppppppbp/6p1/8/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq - 2 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Standard Line",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3",
    length: 5,
    fen: "rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Three Pawns Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4",
    length: 5,
    fen: "rnbqk1nr/ppppppbp/6p1/8/3PPP2/8/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Westermann Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bd2",
    length: 5,
    fen: "rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPPB1PPP/RN1QKBNR b KQkq - 2 3",
  },
  {
    eco: "B06",
    name: "Modern Defense: Wind Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bd3",
    length: 5,
    fen: "rnbqk1nr/ppppppbp/6p1/8/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq - 2 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Goldman Variation",
    pgn: "1. e4 c6 2. Nc3 d5 3. Qf3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2Q2/PPPP1PPP/R1B1KBNR b KQkq - 1 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack, Double Gambit",
    pgn: "1. e4 c6 2. b4 d5 3. b5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/1P1p4/4P3/8/P1PP1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Labahn Attack, Polish Variation",
    pgn: "1. e4 c6 2. b4 e5 3. Bb2",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/2p5/4p3/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Toikkanen Gambit",
    pgn: "1. e4 c6 2. c4 d5 3. e5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3pP3/2P5/8/PP1P1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Two Knights Attack",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 1 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Maróczy Variation",
    pgn: "1. e4 c6 2. d4 d5 3. f3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Mieses Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Be3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Modern Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation",
    pgn: "1. e4 c6 2. d4 d5 3. exd5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Euwe Attack, Prins Gambit",
    pgn: "1. e4 c5 2. b3 d5 3. Bb2",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Abrahams Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. Bb2",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/1p2P3/8/PBPP1PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Marshall Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/1p2P3/P7/2PP1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Santasiere Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. c4",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/1pP1P3/8/P2P1PPP/RNBQKBNR b KQkq c3 0 3",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Halasz Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. f4",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/3pPP2/8/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Morphy Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/3pP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3",
    length: 5,
    fen: "rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 e6 3. g3",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Chameleon Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. Nge2",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPPNPPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Grob Attack",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g4",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4P1P1/2N5/PPPP1P1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Grand Prix Attack",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. f4",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Fianchetto",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4",
    length: 5,
    fen: "rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Aronin System",
    pgn: "1. e4 c5 2. Nf3 a6 3. Be2",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPPBPPP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Kieseritzky System",
    pgn: "1. e4 c5 2. Nf3 a6 3. b3",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Quiet System",
    pgn: "1. e4 c5 2. Nf3 a6 3. d3",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Réti System",
    pgn: "1. e4 c5 2. Nf3 a6 3. g3",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5NP1/PPPP1P1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Wing Gambit",
    pgn: "1. e4 c5 2. Nf3 a6 3. b4",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Yerevan System",
    pgn: "1. e4 c5 2. Nf3 a6 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/1p1ppppp/p7/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 1 3",
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Advance Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Closed Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 3 3",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Portsmouth Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. b4",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4",
    length: 5,
    fen: "r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Delayed Alapin Variation, with e6",
    pgn: "1. e4 c5 2. Nf3 e6 3. c3",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Westerinen Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. b3",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kramnik Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. c4",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Wing Gambit Deferred",
    pgn: "1. e4 c5 2. Nf3 e6 3. b4",
    length: 5,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B50",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Delayed Alapin Variation, with d6",
    pgn: "1. e4 c5 2. Nf3 d6 3. c3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Kopec System",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bd3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/3p4/2p5/4P3/3B1N2/PPPP1PPP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Wing Gambit, Deferred Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. b4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/3p4/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "B51",
    name: "Sicilian Defense: Moscow Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: Alapin Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Be3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: Banzai-Leong Gambit, Pinova Gambit",
    pgn: "1. e4 e6 2. b4 Bxb4 3. e5",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/4p3/4P3/1b6/8/P1PP1PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Diemer-Duhm Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. c4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/2PPP3/8/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Franco-Hiva Gambit Accepted",
    pgn: "1. e4 e6 2. d4 f5 3. exf5",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/4p3/5P2/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Horwitz Attack, Papa-Ticulat Gambit",
    pgn: "1. e4 e6 2. b3 d5 3. Bb2",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: Morphy Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nh3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/7N/PPP2PPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: Pelikan Variation",
    pgn: "1. e4 e6 2. Nc3 d5 3. f4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Perseus Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: Schlechter Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Bd3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Three Pawn Attack",
    pgn: "1. e4 e6 2. d4 a6 3. c4",
    length: 5,
    fen: "rnbqkbnr/1ppp1ppp/p3p3/8/2PPP3/8/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C00",
    name: "French Defense: Two Knights Variation",
    pgn: "1. e4 e6 2. Nf3 d5 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 1 3",
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "C10",
    name: "French Defense: Paulsen Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "C20",
    name: "Barnes Opening: Walkerling",
    pgn: "1. f3 e5 2. e4 Nf6 3. Bc4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5P2/PPPP2PP/RNBQK1NR b KQkq - 2 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: King's Head Opening",
    pgn: "1. e4 e5 2. f3 Nf6 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2P2/PPPP2PP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "C20",
    name: "King's Pawn Opening: Speers",
    pgn: "1. e4 e5 2. Qg4 Nf6 3. Qf5",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4pQ2/4P3/8/PPPP1PPP/RNB1KBNR b KQkq - 3 3",
  },
  {
    eco: "C20",
    name: "Portuguese Opening: Miguel Gambit",
    pgn: "1. e4 e5 2. Bb5 Bc5 3. b4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/1Bb1p3/1P2P3/8/P1PP1PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C20",
    name: "Portuguese Opening: Portuguese Gambit",
    pgn: "1. e4 e5 2. Bb5 Nf6 3. d4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/1B2p3/3PP3/8/PPP2PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C21",
    name: "Center Game",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3QP3/8/PPP2PPP/RNB1KBNR b KQkq - 0 3",
  },
  {
    eco: "C21",
    name: "Center Game: Halasz-McDonnell Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. f4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3pPP2/8/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3pP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C21",
    name: "Center Game: Ross Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Bd3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3pP3/3B4/PPP2PPP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C21",
    name: "Center Game: von der Lasa Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/2BpP3/8/PPP2PPP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C21",
    name: "Danish Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. c3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Calabrese Countergambit, Jaenisch Variation",
    pgn: "1. e4 e5 2. Bc4 f5 3. d3",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/3P4/PPP2PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. d4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/8/PPPPQPPP/RNB1K1NR b KQkq - 3 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/1PB1P3/8/P1PP1PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Philidor Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/2P5/PP1P1PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Stein Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. f4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/2B1PP2/8/PPPP2PP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Berlin Defense, Greco Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. f4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1PP2/8/PPPP2PP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Ponziani Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq - 0 3",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Fyfe Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. d4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Giraffe Attack",
    pgn: "1. e4 e5 2. Nc3 Bc5 3. Qg4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/4P1Q1/2N5/PPPP1PPP/R1B1KBNR b KQkq - 3 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Hamppe-Meitner Variation",
    pgn: "1. e4 e5 2. Nc3 Bc5 3. Na4",
    length: 5,
    fen: "rnbqk1nr/pppp1ppp/8/2b1p3/N3P3/8/PPPP1PPP/R1BQKBNR b KQkq - 3 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Omaha Gambit",
    pgn: "1. e4 e5 2. Nc3 d6 3. f4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C26",
    name: "Vienna Game: Mengarini Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. a3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/P1N5/1PPP1PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C26",
    name: "Vienna Game: Mieses Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR b KQkq - 3 3",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene's Defense",
    pgn: "1. e4 e5 2. f4 Qh4+ 3. g3",
    length: 5,
    fen: "rnb1kbnr/pppp1ppp/8/4p3/4PP1q/6P1/PPPP3P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit Accepted",
    pgn: "1. e4 e5 2. f4 d5 3. exd5",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3Pp3/5P2/8/PPPP2PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Blackburne Attack",
    pgn: "1. e4 e5 2. f4 d5 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/5N2/PPPP2PP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Hinrichsen Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. d4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/3PPP2/8/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Milner-Barry Variation",
    pgn: "1. e4 e5 2. f4 d5 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Basman Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qe2",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPQ1PP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Breyer Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qf3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/5Q2/PPPP2PP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Carrera Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Qh5",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/7Q/4Pp2/8/PPPP2PP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Dodo Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Qg4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4PpQ1/8/PPPP2PP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Eisenberg Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nh3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/7N/PPPP2PP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Gaga Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. g3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/6P1/PPPP3P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Mason-Keres Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/2N5/PPPP2PP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Orsini Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. b3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/1P6/P1PP2PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Paris Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Ne2",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPN1PP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Polerio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. d4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/3PPp2/8/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Schurig Gambit, with Bb5",
    pgn: "1. e4 e5 2. f4 exf4 3. Bb5",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/1B6/4Pp2/8/PPPP2PP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Schurig Gambit, with Bd3",
    pgn: "1. e4 e5 2. f4 exf4 3. Bd3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/3B4/PPPP2PP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Stamma Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. h4",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp1P/8/PPPP2P1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tartakower Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Be2",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPB1PP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tumbleweed",
    pgn: "1. e4 e5 2. f4 exf4 3. Kf2",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP1KPP/RNBQ1BNR b kq - 1 3",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 f5 3. exf5",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pP2/8/5N2/PPPP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Diepstraten Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. c4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Lobster Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. g4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4P1P1/5N2/PPPP1P1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mason Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. d4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mlotkowski Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 1 3",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Senechaud Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. b4",
    length: 5,
    fen: "rnbqkbnr/pppp2pp/8/4pp2/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/3p4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 1 3",
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C42",
    name: "Russian Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 3 3",
  },
  {
    eco: "C42",
    name: "Russian Game: Three Knights Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "Irish Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nxe5",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4N3/4P3/8/PPPP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "King's Knight Opening: Konstantinopolsky",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. g3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5NP1/PPPP1P1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Dresden Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Pachman Wing Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. b4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPPBPPP/RNBQK2R b KQkq - 3 3",
  },
  {
    eco: "C44",
    name: "Ponziani Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C44",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "C46",
    name: "Three Knights Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 3 3",
  },
  {
    eco: "C50",
    name: "Italian Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 3 3",
  },
  {
    eco: "C60",
    name: "Ruy Lopez",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5",
    length: 5,
    fen: "r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 3 3",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Blackmar Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. f3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/3Pp3/5P2/PPP3PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Diemer-Rosenberg Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Be3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/3Pp3/4B3/PPP2PPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Fritz Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Bc4",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2BPp3/8/PPP2PPP/RNBQK1NR b KQkq - 1 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit Accepted",
    pgn: "1. d4 d5 2. Bf4 c5 3. dxc5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2Pp4/5B2/8/PPP1PPPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit, Morris Countergambit",
    pgn: "1. d4 d5 2. Bf4 c5 3. e4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/8/2pp4/3PPB2/8/PPP2PPP/RN1QKBNR b KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Hübsch Gambit",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. e4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 3",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Stonewall Attack",
    pgn: "1. d4 d5 2. e3 Nf6 3. Bd3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/3BP3/PPP2PPP/RNBQK1NR b KQkq - 2 3",
  },
  {
    eco: "D01",
    name: "Rapport-Jobava System",
    pgn: "1. d4 d5 2. Nc3 Nf6 3. Bf4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/2N5/PPP1PPPP/R2QKBNR b KQkq - 3 3",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq - 1 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: London System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bf4",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq - 3 3",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Symmetrical Variation, Pseudo-Catalan",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. g3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq - 3 3",
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3",
    length: 5,
    fen: "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Queen Attack",
    pgn: "1. d4 d5 2. c4 Bf5 3. Qb3",
    length: 5,
    fen: "rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/1Q6/PP2PPPP/RNB1KBNR b KQkq - 2 3",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3",
    length: 5,
    fen: "r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 2 3",
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "D10",
    name: "Slav Defense: Diemer Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. e4",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2PPP3/8/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "D10",
    name: "Slav Defense: Exchange Variation",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PP2PPPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "D11",
    name: "Slav Defense: Modern Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Accelerated Mannheim Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Qa4+",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/Q1pP4/8/PP2PPPP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Saduleto Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Normal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3",
    length: 5,
    fen: "rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Queen's Knight Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3",
    length: 5,
    fen: "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "E00",
    name: "Catalan Opening",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E00",
    name: "Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Qb3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/1Q6/PP2PPPP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "E00",
    name: "Indian Defense: Devin Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. g4",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2PP2P1/8/PP2PP1P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E00",
    name: "Indian Defense: Seirawan Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Bg5",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/6B1/2PP4/8/PP2PPPP/RN1QKBNR b KQkq - 1 3",
  },
  {
    eco: "E10",
    name: "Indian Defense: Anti-Nimzo-Indian",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3",
    length: 5,
    fen: "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 3",
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Advance Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. d5",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/3P4/2P5/8/PP2PPPP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/5P2/PP2P1PP/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Basman-Williams Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. h4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP3P/8/PP2PPP1/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Immediate Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Normal Variation, King's Knight Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq - 0 3",
  },
  {
    eco: "E60",
    name: "Queen's Pawn, Mengarini Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Qc2",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PPQ1PPPP/RNB1KBNR b KQkq - 1 3",
  },
  {
    eco: "E61",
    name: "King's Indian Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3",
    length: 5,
    fen: "rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq - 1 3",
  },
  {
    eco: "A00",
    name: "Grob Opening: Keene Defense",
    pgn: "1. g4 d5 2. h3 e5 3. Bg2 c6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2p5/3pp3/6P1/7P/PPPPPPB1/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Grob Opening: Romford Countergambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4 d4",
    length: 6,
    fen: "rn1qkbnr/ppp1pppp/8/8/2Pp2b1/8/PP1PPPBP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit, Schiller Defense",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3 h5",
    length: 6,
    fen: "rnbqkbnr/ppp1ppp1/8/7p/4p1P1/2N5/PPPP1P1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Pachman Gambit",
    pgn: "1. g3 f5 2. e4 fxe4 3. Qh5+ g6",
    length: 6,
    fen: "rnbqkbnr/ppppp2p/6p1/7Q/4p3/6P1/PPPP1P1P/RNB1KBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Polish Opening: Queen's Indian Variation",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 b6",
    length: 6,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/1P6/8/8/PBPPPPPP/RN1QKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Polish Opening: Queenside Defense",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 a6",
    length: 6,
    fen: "rnbqkb1r/1ppp1ppp/p3pn2/1P6/8/8/PBPPPPPP/RN1QKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Sodium Attack: Durkin Gambit",
    pgn: "1. Na3 e5 2. Nc4 Nc6 3. e4 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/2N1P3/8/PPPP1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hergert Gambit",
    pgn: "1. Nc3 d6 2. f4 e5 3. fxe5 Nc6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2np4/4P3/8/2N5/PPPPP1PP/R1BQKBNR w KQkq - 1 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Hulsemann Gambit",
    pgn: "1. Nc3 e5 2. e3 d5 3. Qh5 Be6",
    length: 6,
    fen: "rn1qkbnr/ppp2ppp/4b3/3pp2Q/8/2N1P3/PPPP1PPP/R1B1KBNR w KQkq - 2 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Liebig Gambit",
    pgn: "1. Nc3 e5 2. e3 d5 3. Qh5 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/5n2/3pp2Q/8/2N1P3/PPPP1PPP/R1B1KBNR w KQkq - 2 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Melleby Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/3pNP2/8/PPPPP1PP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Pfeiffer Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 e5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3pNP2/8/PPPPP1PP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Sleipnir Gambit",
    pgn: "1. Nc3 d5 2. e3 e5 3. d4 Bb4",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/8/3pp3/1b1P4/2N1P3/PPP2PPP/R1BQKBNR w KQkq - 1 4",
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Langheld Gambit",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/3P1n2/8/8/8/PPPPP1PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A03",
    name: "Bird Opening: Lasker Variation",
    pgn: "1. f4 d5 2. Nf3 Nf6 3. e3 c5",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/5n2/2pp4/5P2/4PN2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Arctic Defense, Drunken Knight Variation",
    pgn: "1. Nf3 f6 2. e4 Nh6 3. d4 Nf7",
    length: 6,
    fen: "rnbqkb1r/pppppnpp/5p2/8/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: The Walrus",
    pgn: "1. Nf3 e5 2. Nxe5 Nc6 3. Nxc6 dxc6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2p5/8/8/8/PPPPPPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Keres Variation",
    pgn: "1. Nf3 d5 2. g3 Bg4 3. Bg2 Nd7",
    length: 6,
    fen: "r2qkbnr/pppnpppp/8/3p4/6b1/5NP1/PPPPPPBP/RNBQK2R w KQkq - 3 4",
  },
  {
    eco: "A08",
    name: "King's Indian Attack: French Variation",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6",
    length: 6,
    fen: "r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R w KQkq - 2 4",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Advance Variation, Michel Gambit",
    pgn: "1. Nf3 d5 2. c4 d4 3. b4 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/1PPp4/5N2/P2PPPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Réti Gambit, Keres Variation",
    pgn: "1. Nf3 d5 2. c4 dxc4 3. e3 Be6",
    length: 6,
    fen: "rn1qkbnr/ppp1pppp/4b3/8/2p5/4PN2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "A10",
    name: "English Opening: Anglo-Scandinavian Defense, Malvinas Variation",
    pgn: "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qa5",
    length: 6,
    fen: "rnb1kbnr/ppp1pppp/8/q7/8/2N5/PP1PPPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 d5 2. c4 c6 3. b3 Bg4",
    length: 6,
    fen: "rn1qkbnr/pp2pppp/2p5/3p4/2P3b1/1P3N2/P2PPPPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 c5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Kurajica Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 c6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2p1p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A13",
    name: "English Opening: Neo-Catalan",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Grünfeld Formation",
    pgn: "1. c4 Nf6 2. Nf3 g6 3. g3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Romanishin Variation",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 a6",
    length: 6,
    fen: "rnbqkb1r/1ppp1ppp/p3pn2/8/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Scandinavian Defense, Exchange Variation",
    pgn: "1. Nf3 Nf6 2. c4 d5 3. cxd5 Nxd5",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/8/3n4/8/5N2/PP1PPPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Slav Formation",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. g3 c6",
    length: 6,
    fen: "rnbqkb1r/pp1ppp1p/2p2np1/8/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Nimzo-English",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. Nc3 Bb4",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq - 2 4",
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6",
    length: 6,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq - 0 4",
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 Nc6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n1pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR w KQkq - 1 4",
  },
  {
    eco: "A19",
    name: "English Opening: Mikenas-Carls, Sicilian",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 c5",
    length: 6,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Keres Defense",
    pgn: "1. c4 e5 2. Nc3 d6 3. g3 c6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2pp4/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Smyslov Defense",
    pgn: "1. c4 e5 2. Nc3 d6 3. Nf3 Bg4",
    length: 6,
    fen: "rn1qkbnr/ppp2ppp/3p4/4p3/2P3b1/2N2N2/PP1PPPPP/R1BQKB1R w KQkq - 2 4",
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation, Reversed Dragon",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/5n2/3pp3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Two Knights Variation, Smyslov System",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 Bb4",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq - 1 4",
  },
  {
    eco: "A23",
    name: "English Opening: King's English Variation, Two Knights Variation, Keres Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 c6",
    length: 6,
    fen: "rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A24",
    name: "English Opening: King's English Variation, Two Knights Variation, Fianchetto Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. g3 g6",
    length: 6,
    fen: "rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq - 4 4",
  },
  {
    eco: "A35",
    name: "English Opening: Symmetrical Variation, Four Knights Variation",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6",
    length: 6,
    fen: "r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq - 2 4",
  },
  {
    eco: "A40",
    name: "English Defense: Eastbourne Gambit",
    pgn: "1. d4 b6 2. c4 Bb7 3. Nc3 e5",
    length: 6,
    fen: "rn1qkbnr/pbpp1ppp/1p6/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex Declined: Diemer Counterattack",
    pgn: "1. d4 e5 2. d5 Bc5 3. e4 Qh4",
    length: 6,
    fen: "rnb1k1nr/pppp1ppp/8/2bPp3/4P2q/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Englund Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7",
    length: 6,
    fen: "r1b1kbnr/ppppqppp/2n5/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq - 3 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Felbecker Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Bc5",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq - 3 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Soller Gambit Deferred",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 f6",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n2p2/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Zilbermints Gambit",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Nge7",
    length: 6,
    fen: "r1bqkb1r/ppppnppp/2n5/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq - 3 4",
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Cannstatter Variation",
    pgn: "1. d4 Nc6 2. c4 e5 3. d5 Nd4",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/8/3Pp3/2Pn4/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Lithuanian Variation",
    pgn: "1. d4 Nc6 2. c4 e5 3. d5 Nce7",
    length: 6,
    fen: "r1bqkbnr/ppppnppp/8/3Pp3/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A40",
    name: "Slav Indian: Kudischewitsch Gambit",
    pgn: "1. d4 c6 2. Nf3 Nf6 3. c4 b5",
    length: 6,
    fen: "rnbqkb1r/p2ppppp/2p2n2/1p6/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A41",
    name: "Modern Defense",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6",
    length: 6,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A41",
    name: "Modern Defense: Neo-Modern Defense",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 e5",
    length: 6,
    fen: "rnbqk1nr/pppp1pbp/6p1/4p3/2PPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat, Lisbon Gambit",
    pgn: "1. d4 d6 2. c4 e5 3. dxe5 Nc6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2np4/4P3/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A41",
    name: "Rat Defense: English Rat, Pounds Gambit",
    pgn: "1. d4 d6 2. c4 e5 3. dxe5 Be6",
    length: 6,
    fen: "rn1qkbnr/ppp2ppp/3pb3/4P3/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Hawk Variation",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5 c4",
    length: 6,
    fen: "rnbqkb1r/pp1ppppp/5n2/3P4/2p5/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Old Benoni, Schmid Variation",
    pgn: "1. d4 c5 2. d5 d6 3. Nc3 g6",
    length: 6,
    fen: "rnbqkbnr/pp2pp1p/3p2p1/2pP4/8/2N5/PPP1PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Woozle",
    pgn: "1. d4 c5 2. d5 Nf6 3. Nc3 Qa5",
    length: 6,
    fen: "rnb1kb1r/pp1ppppp/5n2/q1pP4/8/2N5/PPP1PPPP/R1BQKBNR w KQkq - 3 4",
  },
  {
    eco: "A43",
    name: "Benoni Defense: Zilbermints-Benoni Gambit, Tamarkin Countergambit",
    pgn: "1. d4 c5 2. Nf3 cxd4 3. b4 e5",
    length: 6,
    fen: "rnbqkbnr/pp1p1ppp/8/4p3/1P1p4/5N2/P1P1PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A43",
    name: "Indian Defense: Pseudo-Benko",
    pgn: "1. d4 Nf6 2. Nf3 c5 3. d5 b5",
    length: 6,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/8/5N2/PPP1PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A44",
    name: "Benoni Defense: Semi-Benoni",
    pgn: "1. d4 c5 2. d5 e5 3. e4 d6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Borg Variation",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bf4 g5",
    length: 6,
    fen: "rnbqkb1r/pppppp1p/8/6p1/3PnB2/8/PPP1PPPP/RN1QKBNR w KQkq - 0 4",
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense, Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 h6",
    length: 6,
    fen: "rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R w KQkq - 0 4",
  },
  {
    eco: "A47",
    name: "Indian Defense: Schnepper Gambit",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. c3 e5",
    length: 6,
    fen: "rnbqkb1r/p1pp1ppp/1p3n2/4p3/3P4/2P2N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P1n3/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Czech Variation, with Nc3",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Czech Variation, with Nf3",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nf3 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5",
    length: 6,
    fen: "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Tartakower-Indian",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nf3 Bg4",
    length: 6,
    fen: "rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Ukrainian Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "A56",
    name: "Benoni Defense: Czech Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e5",
    length: 6,
    fen: "rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR w KQkq e6 0 4",
  },
  {
    eco: "A56",
    name: "Benoni Defense: Hromádka System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 d6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A56",
    name: "Benoni Defense: Weenink Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. dxc5 e6",
    length: 6,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/2P5/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A56",
    name: "Vulture Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 Ne4",
    length: 6,
    fen: "rnbqkb1r/pp1ppppp/8/2pP4/2P1n3/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5",
    length: 6,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A60",
    name: "Benoni Defense: Modern Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6",
    length: 6,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A81",
    name: "Dutch Defense: Semi-Leningrad Variation",
    pgn: "1. d4 f5 2. g3 Nf6 3. Bg2 g6",
    length: 6,
    fen: "rnbqkb1r/ppppp2p/5np1/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Bladel Variation",
    pgn: "1. d4 f5 2. c4 g6 3. Nc3 Nh6",
    length: 6,
    fen: "rnbqkb1r/ppppp2p/6pn/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "A86",
    name: "Dutch Defense: Leningrad Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 g6",
    length: 6,
    fen: "rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Borg Defense: Troon Gambit",
    pgn: "1. e4 g5 2. d4 h6 3. h4 g4",
    length: 6,
    fen: "rnbqkbnr/pppppp2/7p/8/3PP1pP/8/PPP2PP1/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Hippopotamus Defense",
    pgn: "1. e4 Nh6 2. d4 g6 3. c4 f6",
    length: 6,
    fen: "rnbqkb1r/ppppp2p/5ppn/8/2PPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Breyer Variation",
    pgn: "1. e4 Nc6 2. Nc3 Nf6 3. d4 e5",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4",
    length: 6,
    fen: "r1bqkb1r/pppppppp/2n5/4P3/6n1/5N2/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Bielefelder Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Bc5",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Hammer Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 f6",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n2p2/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Herford Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Qh4",
    length: 6,
    fen: "r1b1kbnr/pppp1ppp/2n5/4P3/4P2q/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, de Smet Gambit",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 d6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2np4/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Aachen Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Nb4",
    length: 6,
    fen: "r1bqkbnr/ppp1pppp/8/3P4/1n1P4/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4",
    length: 6,
    fen: "r1bqkbnr/ppp1pppp/2n5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Brandics Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 a6",
    length: 6,
    fen: "r1bqkbnr/1pp1pppp/p1n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Erben Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 g6",
    length: 6,
    fen: "r1bqkbnr/ppp1pp1p/2n3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Heinola-Deppe Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 e5",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2n5/3pp3/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Vehre Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 Nf6",
    length: 6,
    fen: "r1bqkb1r/ppp1pppp/2n2n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5",
    length: 6,
    fen: "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Owen Defense: Hekili-Loa Gambit",
    pgn: "1. e4 b6 2. d4 c5 3. dxc5 Nc6",
    length: 6,
    fen: "r1bqkbnr/p2ppppp/1pn5/2P5/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Owen Defense: Unicorn Variation",
    pgn: "1. e4 f6 2. d4 b6 3. c4 Bb7",
    length: 6,
    fen: "rn1qkbnr/pbppp1pp/1p3p2/8/2PPP3/8/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Owen Defense: Wind Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. f3 e5",
    length: 6,
    fen: "rn1qkbnr/pbpp1ppp/1p6/4p3/3PP3/5P2/PPP3PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B00",
    name: "Rat Defense: Fuller Gambit",
    pgn: "1. e4 d6 2. f4 d5 3. exd5 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/5n2/3P4/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Blackburne Gambit",
    pgn: "1. e4 d5 2. exd5 c6 3. dxc6 Nxc6",
    length: 6,
    fen: "r1bqkbnr/pp2pppp/2n5/8/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Boehnke Gambit",
    pgn: "1. e4 d5 2. exd5 e5 3. dxe6 Bxe6",
    length: 6,
    fen: "rn1qkbnr/ppp2ppp/4b3/8/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Gubinsky-Melts Defense",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6",
    length: 6,
    fen: "rnb1kbnr/ppp1pppp/3q4/8/8/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Icelandic-Palme Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. c4 e6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3P4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kloosterboer Gambit",
    pgn: "1. e4 d5 2. exd5 c6 3. dxc6 e5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2P5/4p3/8/8/PPPP1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5",
    length: 6,
    fen: "rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Marshall Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Panov Transfer",
    pgn: "1. e4 d5 2. exd5 Nf6 3. c4 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3P4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4",
    length: 6,
    fen: "rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Richter Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 g6",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8",
    length: 6,
    fen: "rnbqkbnr/ppp1pppp/8/8/8/2N5/PPPP1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B01",
    name: "Van Geet Opening: Grünfeld Defense",
    pgn: "1. e4 d5 2. Nc3 dxe4 3. Nxe4 e5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/4N3/8/PPPP1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Brooklyn Variation, Everglades Variation",
    pgn: "1. e4 Nf6 2. e5 Ng8 3. d4 f5",
    length: 6,
    fen: "rnbqkbnr/ppppp1pp/8/4Pp2/3P4/8/PPP2PPP/RNBQKBNR w KQkq f6 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe, Modern Line",
    pgn: "1. e4 Nf6 2. e5 Ne4 3. d4 f6",
    length: 6,
    fen: "rnbqkb1r/ppppp1pp/5p2/4P3/3Pn3/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Mokele Mbembe, Vavra Defense",
    pgn: "1. e4 Nf6 2. e5 Ne4 3. d4 e6",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/4p3/4P3/3Pn3/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation, Geschev Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. exd5 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: The Squirrel",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nf4",
    length: 6,
    fen: "rnbqkb1r/pppppppp/8/4P3/2P2n2/8/PP1P1PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: O'Sullivan Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 b5",
    length: 6,
    fen: "rnbqkb1r/p1pppppp/8/1p1nP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack, Bücker Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Bc4 b5",
    length: 6,
    fen: "rnbqk1nr/p1ppppbp/6p1/1p6/2BPP3/8/PPP2PPP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Lizard Defense, Mittenberger Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d5",
    length: 6,
    fen: "rnbqk1nr/ppp1ppbp/6p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Modern Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5",
    length: 6,
    fen: "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Mongredien Defense, with Nc3",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 b6",
    length: 6,
    fen: "rnbqk1nr/p1ppppbp/1p4p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Mongredien Defense, with Nf3",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 b6",
    length: 6,
    fen: "rnbqk1nr/p1ppppbp/1p4p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Standard Defense",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6",
    length: 6,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Rat Defense: Accelerated Gurgenidze",
    pgn: "1. e4 g6 2. d4 d6 3. Nc3 c6",
    length: 6,
    fen: "rnbqkbnr/pp2pp1p/2pp2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Czech Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2pp1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Pirc Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Pseudo-Scandinavian",
    pgn: "1. e4 c6 2. c4 d5 3. exd5 Qxd5",
    length: 6,
    fen: "rnb1kbnr/pp2pppp/2p5/3q4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4",
    length: 6,
    fen: "rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R w KQkq - 2 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Botvinnik-Carls Defense",
    pgn: "1. e4 c6 2. d4 d5 3. e5 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2ppP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: De Bruycker Defense",
    pgn: "1. e4 c6 2. d4 Na6 3. Nc3 Nc7",
    length: 6,
    fen: "r1bqkbnr/ppnppppp/2p5/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 3 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Edinburgh Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 Qb6",
    length: 6,
    fen: "rnb1kbnr/pp2pppp/1qp5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Campomanes Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 Nf6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Gurgenidze Counterattack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 b5",
    length: 6,
    fen: "rnbqkbnr/p3pppp/2p5/1p1p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Gurgenidze System",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 g6",
    length: 6,
    fen: "rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Carlsbad Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3 bxa3",
    length: 6,
    fen: "rnbqkbnr/pp1ppppp/8/8/4P3/p7/2PP1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Bird Opening: Dutch Variation, Batavo Gambit",
    pgn: "1. e4 c5 2. f4 d5 3. Nf3 dxe4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/4pP2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: McDonnell Attack, Tal Gambit",
    pgn: "1. e4 c5 2. f4 d5 3. exd5 Nf6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/5n2/2pP4/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Alapin Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 Nf6",
    length: 6,
    fen: "rnbqkb1r/pp1ppppp/5n2/8/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Center Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 e5",
    length: 6,
    fen: "rnbqkbnr/pp1p1ppp/8/4p3/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Push Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d3",
    length: 6,
    fen: "rnbqkbnr/pp1ppppp/8/8/4P3/2Pp4/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Scandinavian Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/3p4/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Wing Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 Qa5",
    length: 6,
    fen: "rnb1kbnr/pp1ppppp/8/q7/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5",
    length: 6,
    fen: "rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Closed, Korchnoi Defense",
    pgn: "1. e4 c5 2. Nc3 e6 3. g3 d5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/4P3/2N3P1/PPPP1P1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6",
    length: 6,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Acton Extension",
    pgn: "1. e4 c5 2. Nf3 g6 3. c4 Bh6",
    length: 6,
    fen: "rnbqk1nr/pp1ppp1p/6pb/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Double-Dutch Gambit",
    pgn: "1. e4 c5 2. Nf3 f5 3. exf5 Nh6",
    length: 6,
    fen: "rnbqkb1r/pp1pp1pp/7n/2p2P2/8/5N2/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Frederico Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 f5",
    length: 6,
    fen: "rnbqkbnr/pp1pp2p/6p1/2p2p2/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Pterodactyl",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7",
    length: 6,
    fen: "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Paulsen Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4 e6",
    length: 6,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Robatsch Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c4 d6",
    length: 6,
    fen: "rnbqkbnr/1p2pppp/p2p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Barcza Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 Nf6",
    length: 6,
    fen: "rnbqkb1r/1p1ppppp/p4n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Ljubojevic Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 b5",
    length: 6,
    fen: "rnbqkbnr/3ppppp/p7/1pp5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Steiner Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 d6",
    length: 6,
    fen: "rnbqkbnr/1p2pppp/p2p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Rossolimo Variation, Brooklyn Retreat Defense",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Nb8",
    length: 6,
    fen: "rnbqkbnr/pp1ppppp/8/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6",
    length: 6,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Franco-Sicilian Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 e6",
    length: 6,
    fen: "r1bqkbnr/pp1p1ppp/2n1p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4",
    length: 6,
    fen: "r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Drazic Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 a6",
    length: 6,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Open",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4",
    length: 6,
    fen: "rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Marshall Counterattack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 d5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B50",
    name: "Sicilian Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Kotov Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. g3 b5",
    length: 6,
    fen: "rnbqkbnr/p3pppp/3p4/1pp5/4P3/5NP1/PPPP1P1P/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 Nf6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/3p1n2/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "B52",
    name: "Sicilian Defense: Moscow Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7",
    length: 6,
    fen: "rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 4",
  },
  {
    eco: "C00",
    name: "French Defense: Diemer-Duhm Gambit Accepted",
    pgn: "1. e4 e6 2. d4 d5 3. c4 dxe4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/4p3/8/2PPp3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C00",
    name: "French Defense: La Bourdonnais Variation, Reuter Gambit",
    pgn: "1. e4 e6 2. f4 d5 3. Nf3 dxe4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/4p3/8/4pP2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Sanky-George Gambit",
    pgn: "1. e4 e6 2. d4 a6 3. c4 b5",
    length: 6,
    fen: "rnbqkbnr/2pp1ppp/p3p3/1p6/2PPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Extended Bishop Swap",
    pgn: "1. e4 e6 2. d4 d5 3. e5 Bd7",
    length: 6,
    fen: "rn1qkbnr/pppb1ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Guimard Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Haberditz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 f5",
    length: 6,
    fen: "rnbqkbnr/ppp3pp/4p3/3p1p2/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Modern System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 a6",
    length: 6,
    fen: "rnbqkbnr/1pp2ppp/p3p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C03",
    name: "French Defense: Tarrasch Variation, Morozevich Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Be7",
    length: 6,
    fen: "rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Closed Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C10",
    name: "Sicilian Defense: Marshall Gambit",
    pgn: "1. e4 c5 2. Nc3 e6 3. d4 d5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Clam Variation, Radisch Gambit",
    pgn: "1. e4 e5 2. d3 Nf6 3. f4 Bc5",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/5n2/2b1p3/4PP2/3P4/PPP3PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: MacLeod Attack, Norwalde Gambit",
    pgn: "1. e4 e5 2. c3 d5 3. Qh5 Bd6",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/3b4/3pp2Q/4P3/2P5/PP1P1PPP/RNB1KBNR w KQkq - 2 4",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Philidor Gambit",
    pgn: "1. e4 e5 2. d4 d6 3. dxe5 Bd7",
    length: 6,
    fen: "rn1qkbnr/pppb1ppp/3p4/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5",
    length: 6,
    fen: "rnbqkbnr/pp1p1ppp/8/2p5/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Svenonius Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 Ne7",
    length: 6,
    fen: "rnbqkb1r/ppppnppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C21",
    name: "Danish Gambit Declined: Sorensen Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 d5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C22",
    name: "Center Game: Normal Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/3QP3/8/PPP2PPP/RNB1KBNR w KQkq - 1 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Anderssen Gambit",
    pgn: "1. e4 e5 2. Bc4 b5 3. Bxb5 c6",
    length: 6,
    fen: "rnbqkbnr/p2p1ppp/2p5/1B2p3/4P3/8/PPPP1PPP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/8/2bpp3/2B1P3/2P5/PP1P1PPP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Thorold Gambit",
    pgn: "1. e4 e5 2. Bc4 b5 3. Bxb5 f5",
    length: 6,
    fen: "rnbqkbnr/p1pp2pp/8/1B2pp2/4P3/8/PPPP1PPP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: del Rio Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 Qg5",
    length: 6,
    fen: "rnb1k1nr/pppp1ppp/8/2b1p1q1/2B1P3/2P5/PP1P1PPP/RNBQK1NR w KQkq - 1 4",
  },
  {
    eco: "C25",
    name: "Vienna Game: Philidor Countergambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. d4 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C25",
    name: "Vienna Game: Zhuravlev Countergambit",
    pgn: "1. e4 e5 2. Nc3 Bb4 3. Qg4 Nf6",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/5n2/4p3/1b2P1Q1/2N5/PPPP1PPP/R1B1KBNR w KQkq - 4 4",
  },
  {
    eco: "C26",
    name: "Bishop's Opening: Horwitz Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. Nc3 b5",
    length: 6,
    fen: "rnbqkb1r/p1pp1ppp/5n2/1p2p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq - 0 4",
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation, Reversed Spanish",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bb4",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/5n2/4p3/1bB1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq - 4 4",
  },
  {
    eco: "C27",
    name: "Vienna Game: Frankenstein-Dracula Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR w KQkq - 0 4",
  },
  {
    eco: "C28",
    name: "Vienna Game: Stanley Variation, Three Knights Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq - 4 4",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Main Line",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Keene Defense",
    pgn: "1. e4 e5 2. f4 Qh4+ 3. g3 Qe7",
    length: 6,
    fen: "rnb1kbnr/ppppqppp/8/4p3/4PP2/6P1/PPPP3P/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Miles Defense",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Greco Variation",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 Qh4+",
    length: 6,
    fen: "rnb1kbnr/pppp2pp/8/4pP2/5P1q/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Schiller's Defense",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 Bc5",
    length: 6,
    fen: "rnbqk1nr/pppp2pp/8/2b1pP2/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Senechaud Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 g5",
    length: 6,
    fen: "rnbqk1nr/pppp1p1p/8/2b1p1p1/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Soller-Zilbermints Gambit",
    pgn: "1. e4 e5 2. f4 f6 3. fxe5 Nc6",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n2p2/4P3/4P3/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Zilbermints Double Gambit",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 g5",
    length: 6,
    fen: "r1bqkbnr/pppp1p1p/2n5/4p1p1/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Miles Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 Bc5",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/8/2bPp3/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Modern Transfer",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 exf4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/5p2/8/PPPP2PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Nimzowitsch-Marshall Countergambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 c6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2p5/3Pp3/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Staunton Line",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/4pP2/8/PPPP2PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Anderssen Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 g5",
    length: 6,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bledow Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Cozio Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 2 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Gianutio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 f5",
    length: 6,
    fen: "rnbqkbnr/pppp2pp/8/5p2/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Kieseritzky Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 b5",
    length: 6,
    fen: "rnbqkbnr/p1pp1ppp/8/1p6/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Lopez Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 c6",
    length: 6,
    fen: "rnbqkbnr/pp1p1ppp/2p5/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Maurian Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nc6",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 2 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Steinitz Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Ne7",
    length: 6,
    fen: "rnbqkb1r/ppppnppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 2 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Becker Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 h6",
    length: 6,
    fen: "rnbqkbnr/pppp1pp1/7p/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Bonsch-Osmolovsky Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Ne7",
    length: 6,
    fen: "rnbqkb1r/ppppnppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Gianutio Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 f5",
    length: 6,
    fen: "rnbqkbnr/pppp2pp/8/5p2/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5",
    length: 6,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: MacLeod Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Schallopp Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Wagenbach Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 h5",
    length: 6,
    fen: "rnbqkbnr/pppp1pp1/8/7p/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7",
    length: 6,
    fen: "rnbqk1nr/ppppbppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Modern Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Maróczy Gambit",
    pgn: "1. e4 e5 2. Nf3 d5 3. exd5 Bd6",
    length: 6,
    fen: "rnbqk1nr/ppp2ppp/3b4/3Pp3/8/5N2/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Paulsen Countergambit",
    pgn: "1. e4 e5 2. Nf3 d5 3. exd5 e4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/4p3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Busch-Gass Gambit, Chiodini Gambit",
    pgn: "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Nc6",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Gunderam Defense, Gunderam Gambit",
    pgn: "1. e4 e5 2. Nf3 Qe7 3. Bc4 f5",
    length: 6,
    fen: "rnb1kbnr/ppppq1pp/8/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Fraser Defense",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Nc6",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4Np2/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Greco Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qe7",
    length: 6,
    fen: "rnb1kbnr/ppppq1pp/8/4Np2/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Morgado Defense",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 Nf6",
    length: 6,
    fen: "rnbqkb1r/pppp2pp/5n2/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Strautins Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 b5",
    length: 6,
    fen: "rnbqkbnr/p1pp2pp/8/1p2pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7",
    length: 6,
    fen: "rnbqk1nr/ppp1bppp/3p4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7",
    length: 6,
    fen: "r1bqkbnr/pppn1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lopez Countergambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 f5",
    length: 6,
    fen: "rnbqkbnr/ppp3pp/3p4/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5",
    length: 6,
    fen: "rnbqkbnr/ppp3pp/3p4/4pp2/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Bd7",
    length: 6,
    fen: "rn1qkbnr/pppb1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Damiano Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/8/4N3/4n3/8/PPPP1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Stafford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Symmetrical Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 d5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/5n2/3pp3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Jaenisch Counterattack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Ponziani Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Romanishin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Be7",
    length: 6,
    fen: "r1bqk1nr/ppppbppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Réti Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nge7",
    length: 6,
    fen: "r1bqkb1r/ppppnppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Scotch Game: Lolli Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/8/4p3/3nP3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "C46",
    name: "Three Knights Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq - 4 4",
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6",
    length: 6,
    fen: "r1bqkbnr/pppp1p1p/2n3p1/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq - 0 4",
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Winawer Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq - 0 4",
  },
  {
    eco: "C47",
    name: "Four Knights Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq - 4 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Anti-Fried Liver Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6",
    length: 6,
    fen: "r1bqkbnr/pppp1pp1/2n4p/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Blackburne-KostiÄ‡ Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/8/4p3/2BnP3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Piano",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Hungarian Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7",
    length: 6,
    fen: "r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Paris Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 d6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2np4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Rousseau Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Alapin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/1B2p3/1b2P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Brentano Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g5",
    length: 6,
    fen: "r1bqkbnr/pppp1p1p/2n5/1B2p1p1/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Bulgarian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a5",
    length: 6,
    fen: "r1bqkbnr/1ppp1ppp/2n5/pB2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Cozio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7",
    length: 6,
    fen: "r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Fianchetto Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6",
    length: 6,
    fen: "r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Lucena Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Be7",
    length: 6,
    fen: "r1bqk1nr/ppppbppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Nürnberg Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f6",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n2p2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Pollock Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Na5",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/8/nB2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Retreat Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nb8",
    length: 6,
    fen: "rnbqkbnr/pppp1ppp/8/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Rotary-Albany Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 b6",
    length: 6,
    fen: "r1bqkbnr/p1pp1ppp/1pn5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2n5/1B1pp3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Vinogradov Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Qe7",
    length: 6,
    fen: "r1b1kbnr/ppppqppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C61",
    name: "Ruy Lopez: Bird Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4",
    length: 6,
    fen: "r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5",
    length: 6,
    fen: "r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5",
    length: 6,
    fen: "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6",
    length: 6,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6",
    length: 6,
    fen: "r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Netherlands Variation",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 f5",
    length: 6,
    fen: "rnbqkbnr/ppp1p1pp/8/5p2/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Reversed Albin Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Zeller Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5",
    length: 6,
    fen: "rn1qkbnr/ppp1pppp/8/5b2/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Accelerated London System, Steinitz Countergambit, Morris Countergambit Accepted",
    pgn: "1. d4 d5 2. Bf4 c5 3. e4 dxe4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/3PpB2/8/PPP2PPP/RN1QKBNR w KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Queen's Pawn Game: Chigorin Variation, Fianchetto Defense",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. Nc3 d5",
    length: 6,
    fen: "rnbqk1nr/ppp1ppbp/6p1/3p4/3P4/2N2N2/PPP1PPPP/R1BQKB1R w KQkq - 0 4",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5",
    length: 6,
    fen: "rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N5/PPP1PPPP/R2QKBNR w KQkq - 2 4",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Boyce Defense",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Ne4",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/2N5/PPP1PPPP/R2QKBNR w KQkq - 2 4",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Zilbermints Countergambit",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. c4 b5",
    length: 6,
    fen: "rnbqkb1r/p1p1pppp/5n2/1p1p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack, Gossip Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5 Ne4",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/5N2/PPP1PPPP/RN1QKB1R w KQkq - 4 4",
  },
  {
    eco: "D03",
    name: "Queen's Pawn Game: Torre Attack, Grünfeld Variation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. Bg5 g6",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R w KQkq - 0 4",
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System, Anti-Colle",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 Bf5",
    length: 6,
    fen: "rn1qkb1r/ppp1pppp/5n2/3p1b2/3P4/4PN2/PPP2PPP/RNBQKB1R w KQkq - 1 4",
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 e6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Attack, Salvio Countergambit",
    pgn: "1. d4 d5 2. c4 c5 3. dxc5 d4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2P5/2Pp4/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense, Gusev Countergambit",
    pgn: "1. d4 d5 2. c4 c5 3. cxd5 Nf6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/5n2/2pP4/3P4/8/PP2PPPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Marshall Defense, Tan Gambit",
    pgn: "1. d4 d5 2. c4 Nf6 3. cxd5 c6",
    length: 6,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4",
    length: 6,
    fen: "r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Exchange Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. cxd5 Qxd5",
    length: 6,
    fen: "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Lazard Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 e5",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2n5/3pp3/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Main Line",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4",
    length: 6,
    fen: "r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R w KQkq - 3 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Tartakower Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 e5",
    length: 6,
    fen: "r1bqkbnr/ppp2ppp/2n5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D10",
    name: "Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 dxc4",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D10",
    name: "Slav Defense: Winawer Countergambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 e5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Alekhine System",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Greco Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 b5",
    length: 6,
    fen: "rnbqkbnr/p1p1pppp/8/1p6/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, McDonnell Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 e5",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Modern Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 Nc6",
    length: 6,
    fen: "r1bqkbnr/ppp1pppp/2n5/8/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 1 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Rubinstein Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Schwartz Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 f5",
    length: 6,
    fen: "rnbqkbnr/ppp1p1pp/8/5p2/2pPP3/8/PP3PPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Godes Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nd7",
    length: 6,
    fen: "r1bqkbnr/pppnpppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Gunsberg Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 c5",
    length: 6,
    fen: "rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Rosenthal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 e6",
    length: 6,
    fen: "rnbqkbnr/ppp2ppp/4p3/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Slav Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 b5",
    length: 6,
    fen: "rnbqkbnr/p1p1pppp/8/1p6/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6",
    length: 6,
    fen: "rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D23",
    name: "Queen's Gambit Accepted",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 c5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Alapin Variation",
    pgn: "1. d4 e6 2. c4 b6 3. Nc3 d5",
    length: 6,
    fen: "rnbqkbnr/p1p2ppp/1p2p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Charousek Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7",
    length: 6,
    fen: "rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Janowski Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 a6",
    length: 6,
    fen: "rnbqkbnr/1pp2ppp/p3p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Accelerated Move Order",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5",
    length: 6,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Normal Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: Goglidze Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: with Nf3",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "D70",
    name: "Neo-Grünfeld Defense: with g3",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 4",
  },
  {
    eco: "E00",
    name: "Catalan Opening",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5",
    length: 6,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "E00",
    name: "Catalan Opening: Hungarian Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 e5",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "E10",
    name: "Indian Defense: Dzindzi-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 a6",
    length: 6,
    fen: "rnbqkb1r/1ppp1ppp/p3pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "E10",
    name: "Indian Defense: Döry Indian",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Ne4",
    length: 6,
    fen: "rnbqkb1r/pppp1ppp/4p3/8/2PPn3/5N2/PP2PPPP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 2 4",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6",
    length: 6,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4",
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4",
    length: 6,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR w KQkq - 2 4",
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Adorjan Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. d5 b5",
    length: 6,
    fen: "rnbqkb1r/p1pppp1p/5np1/1p1P4/2P5/8/PP2PPPP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "E60",
    name: "Indian Defense: Anti-Grünfeld, Alekhine Variation, Leko Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. f3 e5",
    length: 6,
    fen: "rnbqkb1r/pppp1p1p/5np1/4p3/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Barnes Opening: Gedult Gambit",
    pgn: "1. f3 d5 2. e4 g6 3. d4 dxe4 4. c3",
    length: 7,
    fen: "rnbqkbnr/ppp1pp1p/6p1/8/3Pp3/2P2P2/PP4PP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Keres Gambit",
    pgn: "1. g4 d5 2. Bg2 e5 3. d4 exd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/3p2P1/2P5/PP2PPBP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Richter-Grob Gambit",
    pgn: "1. g4 d5 2. Bg2 c6 3. c4 dxc4 4. b3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/2p3P1/1P6/P2PPPBP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Grob Opening: Zilbermints Gambit, Zilbermints-Hartlaub Gambit",
    pgn: "1. g4 d5 2. e4 dxe4 3. Nc3 e5 4. d3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/4p1P1/2NP4/PPP2P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Reversed Brooklyn Defense, Brooklyn Benko Gambit",
    pgn: "1. g3 e5 2. Nf3 e4 3. Ng1 Nf6 4. b4",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/1P2p3/6P1/P1PPPP1P/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Winterberg Gambit",
    pgn: "1. g3 d5 2. Bg2 e5 3. c4 dxc4 4. b3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/2p5/1P4P1/P2PPPBP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Kádas Opening: Kádas Gambit",
    pgn: "1. h4 d5 2. d4 c5 3. Nf3 cxd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3p4/3p3P/2P2N2/PP2PPP1/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Polish Opening: Schiffler-Sokolsky Variation",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 d5 4. e3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/1P1p4/8/4P3/PBPP1PPP/RN1QKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Polish Opening: Schuehler Gambit",
    pgn: "1. b4 c6 2. Bb2 a5 3. b5 cxb5 4. e4",
    length: 7,
    fen: "rnbqkbnr/1p1ppppp/8/pp6/4P3/8/PBPP1PPP/RN1QKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Gladbacher Gambit",
    pgn: "1. Nc3 e5 2. b3 d5 3. e4 dxe4 4. d3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/4p3/1PNP4/P1P2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Novosibirsk Variation",
    pgn: "1. Nc3 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qh4",
    length: 7,
    fen: "r1bqkbnr/pp1ppppp/2n5/8/7Q/2N5/PPP1PPPP/R1B1KBNR b KQkq - 2 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Pfeiffer Gambit, Sleipnir Countergambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 e5 4. Nf3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3pNP2/5N2/PPPPP1PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Reversed Scandinavian",
    pgn: "1. Nc3 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qa4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/Q7/2N5/PPP1PPPP/R1B1KBNR b KQkq - 2 4",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Sicilian Two Knights",
    pgn: "1. Nc3 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4",
    length: 7,
    fen: "r1bqkbnr/pp1ppppp/2n5/8/3N4/2N5/PPP1PPPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Akahi Variation",
    pgn: "1. e3 e5 2. Nc3 Nf6 3. f4 exf4 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Alua Variation",
    pgn: "1. e3 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A00",
    name: "Van't Kruijs Opening: Keoni-Hiva Gambit, Ekolu Variation",
    pgn: "1. e3 e5 2. Nc3 d5 3. f4 exf4 4. Nf3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A00",
    name: "Ware Opening: Ware Gambit",
    pgn: "1. a4 e5 2. a5 d5 3. e3 f5 4. a6",
    length: 7,
    fen: "rnbqkbnr/ppp3pp/P7/3ppp2/8/4P3/1PPP1PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A02",
    name: "Bird Opening: Siegener Gambit",
    pgn: "1. f4 e5 2. d4 exd4 3. Nf3 c5 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp1p1ppp/8/2p5/3p1P2/2P2N2/PP2P1PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "A02",
    name: "Bird Opening: Swiss Gambit",
    pgn: "1. f4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4",
    length: 7,
    fen: "rnbqkb1r/ppppp1pp/5n2/8/4pPP1/2N5/PPPP3P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams Gambit",
    pgn: "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Qe2",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/4pP2/2N5/PPPPQ1PP/R1B1KBNR b KQkq - 3 4",
  },
  {
    eco: "A03",
    name: "Bird Opening: Williams-Zilbermints Gambit",
    pgn: "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Nge2",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/4pP2/2N5/PPPPN1PP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "A06",
    name: "Nimzo-Larsen Attack: Norfolk Gambit",
    pgn: "1. Nf3 d5 2. b3 Nf6 3. Bb2 c5 4. e4",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/5n2/2pp4/4P3/1P3N2/PBPP1PPP/RN1QKB1R b KQkq - 0 4",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Pachman Gambit",
    pgn: "1. Nf3 d5 2. e3 c5 3. c4 dxc4 4. b3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/2p5/2p5/1P2PN2/P2P1PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "A06",
    name: "Réti-Zukertort Opening: Regina-Nu Gambit",
    pgn: "1. Nf3 d5 2. b3 c5 3. c4 dxc4 4. Nc3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/2p5/2p5/1PN2N2/P2PPPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A08",
    name: "Réti-Zukertort Opening: Reversed Grünfeld",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. d4",
    length: 7,
    fen: "r1bqkbnr/pp2pppp/2n5/2pp4/3P4/5NP1/PPP1PPBP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "A09",
    name: "Réti-Zukertort Opening: Reversed Blumenfeld Gambit",
    pgn: "1. Nf3 d5 2. c4 d4 3. e3 c5 4. b4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/2p5/1PPp4/4PN2/P2P1PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "A10",
    name: "English Opening: Porcupine Variation",
    pgn: "1. c4 f5 2. Nc3 Nf6 3. e4 fxe4 4. g4",
    length: 7,
    fen: "rnbqkb1r/ppppp1pp/5n2/8/2P1p1P1/2N5/PP1P1P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bogoljubov Variation",
    pgn: "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R b KQkq - 1 4",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Anti-Anti-Grünfeld",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4",
    length: 7,
    fen: "rnbqk2r/ppppppbp/5np1/8/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Zvjaginsev-Krasenkow Attack",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 Bb4 4. g4",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bP3P1/2N2N2/PP1PPP1P/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A18",
    name: "English Opening: Mikenas-Carls Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 d5 4. e5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A20",
    name: "English Opening: King's English Variation, Kahiko-Hula Gambit",
    pgn: "1. c4 e5 2. e3 Nf6 3. f4 exf4 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2P2p2/4PN2/PP1P2PP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "A28",
    name: "English Opening: Four Knights System, Nimzowitsch Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Flexible Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2NP1N2/PP2PPPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Korchnoi Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. a3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/P1N2N2/1P1PPPPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Quiet Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A29",
    name: "English Opening: King's English Variation, Four Knights Variation, Fianchetto Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. g3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "A40",
    name: "Englund Gambit Complex: Stockholm Variation",
    pgn: "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Qd5",
    length: 7,
    fen: "r1b1kbnr/ppppqppp/2n5/3QP3/8/5N2/PPP1PPPP/RNB1KB1R b KQkq - 4 4",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Queen Pterodactyl, Quiet Line",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. e3",
    length: 7,
    fen: "rnbqk1nr/pp1pppbp/6p1/2p5/2PP4/2N1P3/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A42",
    name: "Modern Defense: Averbakh System",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4",
    length: 7,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Maltese Falcon",
    pgn: "1. d4 Nf6 2. g4 Nxg4 3. f3 Nf6 4. e4",
    length: 7,
    fen: "rnbqkb1r/pppppppp/5n2/8/3PP3/5P2/PPP4P/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A45",
    name: "Indian Defense: Omega Gambit, Arafat Gambit",
    pgn: "1. d4 Nf6 2. e4 Nxe4 3. Bd3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/pppppppp/5n2/6B1/3P4/3B4/PPP2PPP/RN1QK1NR b KQkq - 3 4",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Poisoned Pawn Variation",
    pgn: "1. d4 Nf6 2. Bg5 c5 3. d5 Qb6 4. Nc3",
    length: 7,
    fen: "rnb1kb1r/pp1ppppp/1q3n2/2pP2B1/8/2N5/PPP1PPPP/R2QKBNR b KQkq - 2 4",
  },
  {
    eco: "A46",
    name: "Queen's Pawn Game: Veresov Attack, Classical Defense",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Nc3 d5 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R b KQkq - 1 4",
  },
  {
    eco: "A46",
    name: "Torre Attack: Wagner Gambit",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e4",
    length: 7,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3PP3/5N2/PPP2PPP/RN1QKB1R b KQkq - 0 4",
  },
  {
    eco: "A50",
    name: "Mexican Defense: Horsefly Gambit",
    pgn: "1. d4 Nf6 2. c4 Nc6 3. d5 Ne5 4. f4",
    length: 7,
    fen: "r1bqkb1r/pppppppp/5n2/3Pn3/2P2P2/8/PP2P1PP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz-Steiner Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. Qc2",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P1n3/8/PPQ1PPPP/RNB1KBNR b KQkq - 2 4",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Adler Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P3n1/5N2/PP2PPPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P1P1n1/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Rubinstein Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Bf4",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR b KQkq - 2 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. g3",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N3P1/PP2PP1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Grinberg Gambit",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. e4",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A53",
    name: "Old Indian Defense: Janowski Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. f3",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Two Knights Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Bishop Attack",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP2B1/2P5/8/PP2PPPP/RN1QKBNR b KQkq - 1 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Hjørring Countergambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. e4",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P1P3/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Main Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Pseudo-Sämisch",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. f3",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5P2/PP2P1PP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Quiet Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nd2",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP1NPPPP/R1BQKBNR b KQkq - 1 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit Declined: Sosonko Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. a4",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/P1P5/8/1P2PPPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A57",
    name: "Benko Gambit: Mutkin Countergambit",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. g4",
    length: 7,
    fen: "rnbqkb1r/p2ppppp/5n2/1ppP4/2P3P1/8/PP2PP1P/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "A80",
    name: "Dutch Defense: Krejcik Gambit, Tate Gambit",
    pgn: "1. d4 f5 2. g4 fxg4 3. e4 d5 4. Nc3",
    length: 7,
    fen: "rnbqkbnr/ppp1p1pp/8/3p4/3PP1p1/2N5/PPP2P1P/R1BQKBNR b KQkq - 1 4",
  },
  {
    eco: "A81",
    name: "Dutch Defense: Blackburne Variation",
    pgn: "1. d4 f5 2. g3 Nf6 3. Bg2 e6 4. Nh3",
    length: 7,
    fen: "rnbqkb1r/pppp2pp/4pn2/5p2/3P4/6PN/PPP1PPBP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "A81",
    name: "Dutch Defense: Leningrad Variation, Karlsbad Variation",
    pgn: "1. d4 f5 2. g3 g6 3. Bg2 Bg7 4. Nh3",
    length: 7,
    fen: "rnbqk1nr/ppppp1bp/6p1/5p2/3P4/6PN/PPP1PPBP/RNBQK2R b KQkq - 3 4",
  },
  {
    eco: "A82",
    name: "Dutch Defense: Blackmar's Second Gambit",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. f3",
    length: 7,
    fen: "rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A82",
    name: "Dutch Defense: Staunton Gambit, Tartakower Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4",
    length: 7,
    fen: "rnbqkb1r/ppppp1pp/5n2/8/3Pp1P1/2N5/PPP2P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppppp1pp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "A90",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2",
    length: 7,
    fen: "rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Keres Attack",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nc3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/8/4n3/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Paulsen Attack",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nf3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/8/4n3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation, Marshall Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5 4. Nc3",
    length: 7,
    fen: "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 4",
  },
  {
    eco: "B00",
    name: "Van Geet Opening: Berlin Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5",
    length: 7,
    fen: "r1bqkbnr/ppp1pppp/2n5/3P4/4p3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line, Leonhardt Gambit",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. b4",
    length: 7,
    fen: "rnb1kbnr/ppp1pppp/8/q7/1P6/2N5/P1PP1PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Classical Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Nf3",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Wuss Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Be2",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP1BPPP/RNBQK1NR b KQkq - 2 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Scandinavian Variation, Myers Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. d3 dxe4 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/6B1/4p3/2NP4/PPP2PPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Spielmann Gambit",
    pgn: "1. e4 Nf6 2. Nc3 d5 3. e5 Nfd7 4. e6",
    length: 7,
    fen: "rnbqkb1r/pppnpppp/4P3/3p4/8/2N5/PPPP1PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Steiner Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. b3",
    length: 7,
    fen: "rnbqkb1r/pppppppp/1n6/4P3/2P5/1P6/P2P1PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Lasker Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5",
    length: 7,
    fen: "rnbqkb1r/pppppppp/1n6/2P1P3/8/8/PP1P1PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Tate Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. a4",
    length: 7,
    fen: "rnbqkb1r/pppppppp/1n6/4P3/P1P5/8/1P1P1PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B03",
    name: "Alekhine Defense",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Balogh Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Bc4",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/3p4/3nP3/2BP4/8/PPP2PPP/RNBQK1NR b KQkq - 1 4",
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Pseudo-Austrian Attack",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. f4",
    length: 7,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3",
    length: 7,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Be3",
    length: 7,
    fen: "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N1B3/PPP2PPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni",
    pgn: "1. d4 g6 2. e4 Bg7 3. Nc3 c5 4. d5",
    length: 7,
    fen: "rnbqk1nr/pp1pppbp/6p1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Rhamphorhynchus",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5",
    length: 7,
    fen: "rnbqk1nr/pp1pppbp/6p1/2P5/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Lion Defense: Anti-Philidor",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4",
    length: 7,
    fen: "r1bqkb1r/pppnpppp/3p1n2/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Lion Defense: Bayonet Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. g4",
    length: 7,
    fen: "r1bqkb1r/pppnpppp/3p1n2/8/3PP1P1/2N5/PPP2P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Modern Defense: Geller's System",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. c3",
    length: 7,
    fen: "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B07",
    name: "Pirc Defense: Byrne Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/6B1/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "B07",
    name: "Pirc Defense: Kholmov System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bc4",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR b KQkq - 1 4",
  },
  {
    eco: "B07",
    name: "Pirc Defense: Sveshnikov System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. g3",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N3P1/PPP2P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Van Weersel Attack",
    pgn: "1. e4 c6 2. c4 d5 3. cxd5 cxd5 4. Qb3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3p4/4P3/1Q6/PP1P1PPP/RNB1KBNR b KQkq - 1 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Apocalypse Attack",
    pgn: "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Ne5",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3pN3/8/8/PPPP1PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hector Gambit",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Ng5",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/6N1/4p3/2N5/PPPP1PPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Hillbilly Attack, Schaeffer Gambit",
    pgn: "1. e4 c6 2. Bc4 d5 3. Bb3 dxe4 4. Qh5",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/7Q/4p3/1B6/PPPP1PPP/RNB1K1NR b KQkq - 1 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Scorpion-Horus Gambit",
    pgn: "1. e4 c6 2. Nc3 d5 3. d3 dxe4 4. Bg5",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/6B1/4p3/2NP4/PPP2PPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Spike Variation, Scorpion-Grob Gambit",
    pgn: "1. e4 c6 2. g4 d5 3. Nc3 dxe4 4. d3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/4p1P1/2NP4/PPP2P1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Bayonet Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. g4",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P2P1/8/PPP2P1P/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Bronstein Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Ne2",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP1NPPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Prins Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. b4",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/1P1P4/8/P1P2PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Short Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Tal Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. h4",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Van der Wiel Attack",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3",
    length: 7,
    fen: "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 2 4",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Ulysses Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nf3 dxe4 4. Ng5",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/6N1/3Pp3/8/PPP2PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation, Bulla Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. g4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3p4/3P2P1/8/PPP2P1P/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Rasa-Studier Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. f3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: von Hennig Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Bc4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/2BPp3/2N5/PPP2PPP/R1BQK1NR b KQkq - 1 4",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Big Clamp Formation",
    pgn: "1. e4 c5 2. d3 Nc6 3. c3 d6 4. f4",
    length: 7,
    fen: "r1bqkbnr/pp2pppp/2np4/2p5/4PP2/2PP4/PP4PP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Kronberger Variation, Nemeth Gambit",
    pgn: "1. e4 c5 2. Na3 Nc6 3. d4 cxd4 4. Bc4",
    length: 7,
    fen: "r1bqkbnr/pp1ppppp/2n5/8/2BpP3/N7/PPP2PPP/R1BQK1NR b KQkq - 1 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Morphy Gambit, Andreaschek Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Nf3 e5 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp1p1ppp/8/4p3/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Declined, Dubois Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 d3 4. c4",
    length: 7,
    fen: "rnbqkbnr/pp1ppppp/8/8/2P1P3/3p4/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit, Danish Variation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nf3",
    length: 7,
    fen: "rnbqkbnr/pp1ppppp/8/8/4P3/2p2N2/PP3PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Cortlever Gambit",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Bc4",
    length: 7,
    fen: "rnbqkbnr/1p1ppppp/p7/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Smith-Morra Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/1p1ppppp/p7/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Zagorovsky Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Qxd4",
    length: 7,
    fen: "rnbqkbnr/1p1ppppp/p7/8/3QP3/5N2/PPP2PPP/RNB1KB1R b KQkq - 0 4",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, San Francisco Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 Na5 4. b4",
    length: 7,
    fen: "r1bqkbnr/pp1ppppp/8/nBp5/1P2P3/5N2/P1PP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4",
    length: 7,
    fen: "r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Smith-Morra Gambit Deferred",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp1p1ppp/4p3/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Tartakower",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/3p4/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "B53",
    name: "Sicilian Defense: Chekhover Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R b KQkq - 0 4",
  },
  {
    eco: "C00",
    name: "French Defense: Carlson Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nf3 dxe4 4. Ne5",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/4p3/4N3/3Pp3/8/PPP2PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "C00",
    name: "French Defense: Orthoschnapp Gambit",
    pgn: "1. e4 e6 2. c4 d5 3. cxd5 exd5 4. Qb3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/4P3/1Q6/PP1P1PPP/RNB1KBNR b KQkq - 1 4",
  },
  {
    eco: "C00",
    name: "French Defense: Wing Gambit",
    pgn: "1. e4 e6 2. Nf3 d5 3. e5 c5 4. b4",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/1P6/5N2/P1PP1PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Monte Carlo Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. c4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Frenkel Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. b4",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/1P1P4/8/P1P2PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch Attack",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P2Q1/8/PPP2PPP/RNB1KBNR b KQkq - 1 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch System",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. dxc5",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2PpP3/8/8/PPP2PPP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Euwe-Keres Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. Ngf3",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Süchting Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. c3",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2P5/PP1N1PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C10",
    name: "French Defense: Classical Variation, Svenonius Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nc6 4. exd5",
    length: 7,
    fen: "r1bqkbnr/ppp2ppp/2n1p3/3P4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C11",
    name: "French Defense: Burn Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Delayed Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Swiss Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bd3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2NB4/PPP2PPP/R1BQK1NR b KQkq - 3 4",
  },
  {
    eco: "C11",
    name: "French Defense: Henneberger Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Be3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N1B3/PPP2PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine-Maróczy Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Delayed Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/4p3/3P4/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPPB1PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Winckelmann-Riemer Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. a3",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/P1N5/1PP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C16",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4",
    length: 7,
    fen: "rnbqkbnr/pp1p1ppp/8/2p5/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C21",
    name: "Center Game: Lanc-Arnold Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3",
    length: 7,
    fen: "rnbqk1nr/pppp1ppp/8/2b5/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C22",
    name: "Center Game: Hall Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qc4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/2Q1P3/8/PPP2PPP/RNB1KBNR b KQkq - 2 4",
  },
  {
    eco: "C22",
    name: "Center Game: Paulsen Attack Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/4P3/4Q3/PPP2PPP/RNB1KBNR b KQkq - 2 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Variation, Lopez Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nf6 4. f4",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1PP2/8/PPPPQ1PP/RNB1K1NR b KQkq - 0 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit, La Bourdonnais-Denker Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. c3",
    length: 7,
    fen: "rnbqk1nr/pppp1ppp/8/4p3/1bB1P3/2P5/P2P1PPP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: McDonnell Gambit, McDonnell Double Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. f4",
    length: 7,
    fen: "rnbqk1nr/pppp1ppp/8/4p3/1bB1PP2/8/P1PP2PP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Pachman Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. Ne2 Nxe4 4. Nec3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Vienna Hybrid",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. Nc3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR b KQkq - 2 4",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Warsaw Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. c3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/2P5/PP3PPP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Knight Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/4Pp2/2N2N2/PPPP2PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/3PPp2/2N5/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C26",
    name: "Bishop's Opening: Vienna Hybrid, Spielmann Attack",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. d3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR b KQkq - 0 4",
  },
  {
    eco: "C27",
    name: "Bishop's Opening: Boden-Kieseritzky Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 1 4",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Steinitz Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. d3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2NP4/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/3p4/2b1p3/4PP2/2P2N2/PP1P2PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Rotlewi Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. b4",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/3p4/2b1p3/1P2PP2/5N2/P1PP2PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical, Soldatenkov Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. fxe5",
    length: 7,
    fen: "rnbqk1nr/ppp2ppp/3p4/2b1P3/4P3/5N2/PPPP2PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation, Schubert Variation",
    pgn: "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. d4",
    length: 7,
    fen: "rnb1kbnr/pppp1ppp/8/4p3/3PPq2/2N5/PPP3PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Anderssen Attack",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. Bb5+",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/1B1P4/4pP2/8/PPPP2PP/RNBQK1NR b KQkq - 1 4",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/4pP2/3P4/PPP3PP/RNBQKBNR b KQkq - 0 4",
  },
  {
    eco: "C31",
    name: "Van Geet Opening: Grünfeld Defense, Steiner Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. Nc3 dxe4 4. Nxe4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/4NP2/8/PPPP2PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR b KQkq - 3 4",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense, Schulder Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. b4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/1P2Pp2/5N2/P1PP2PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Modern Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/5p2/5N2/PPPP2PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4",
    length: 7,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Quade Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Nc3",
    length: 7,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/4Pp2/2N2N2/PPPP2PP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4",
    length: 7,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/3PPp2/5N2/PPP3PP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: King's Knight's Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4",
    length: 7,
    fen: "rnbqkbnr/pppp1p1p/8/6p1/4Pp1P/5N2/PPPP2P1/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: La Bourdonnais Gambit",
    pgn: "1. e4 e5 2. Nf3 Qf6 3. Bc4 Qg6 4. O-O",
    length: 7,
    fen: "rnb1kbnr/pppp1ppp/6q1/4p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 5 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Foltys-Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4",
    length: 7,
    fen: "rnb1kbnr/pppp2pp/5q2/5p2/2N1P3/8/PPPP1PPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Main Line",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4",
    length: 7,
    fen: "rnb1kbnr/pppp2pp/5q2/4Np2/3PP3/8/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Bird Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. c3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Morphy Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Bc4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/3p4/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Klein Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Bc4",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq - 2 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch, Locock Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Ng5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/4p1N1/3PP3/8/PPP2PPP/RNBQKB1R b KQkq - 2 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit, Zukertort Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Nc3",
    length: 7,
    fen: "rnbqkbnr/ppp3pp/3p4/4pp2/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. c3",
    length: 7,
    fen: "rnbqk1nr/ppp1bppp/3p4/4p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7",
    length: 7,
    fen: "rnbqkb1r/ppp2Npp/3p1n2/8/4P3/8/PPPP1PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Karklins-Martinovsky Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nd3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/4P3/3N4/PPPP1PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Moody Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Qe2 Nc6 4. d4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/5N2/PPP1QPPP/RNB1KB1R b KQkq - 0 4",
  },
  {
    eco: "C42",
    name: "Russian Game: Paulsen Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nc4",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/2N1P3/8/PPPP1PPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "C43",
    name: "Bishop's Opening: Urusov Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Center Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3",
    length: 7,
    fen: "rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Dresden Opening: The Goblin",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c4 Nf6 4. Nxe5",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4N3/2P1P3/8/PP1P1PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Schulze-Müller Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nxe5 Nxe5 4. d4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/8/4n3/3PP3/8/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/5N2/PPP1BPPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Latvian Gambit: Clam Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d3 f5 4. exf5",
    length: 7,
    fen: "r1bqkbnr/pppp2pp/2n5/4pP2/8/3P1N2/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Neumann Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. Bc4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 2 4",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5",
    length: 7,
    fen: "r1bqkbnr/ppp2ppp/2n5/1B1pp3/4P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C44",
    name: "Scotch Game: Relfsson Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bb5",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/1B6/3pP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "C45",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4",
    length: 7,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Gunsberg Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. a3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/P1N2N2/1PPP1PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Halloween Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4N3/4P3/2N5/PPPP1PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 5 4",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 0 4",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 5 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Jerome Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Bxf7+",
    length: 7,
    fen: "r1bqk1nr/pppp1Bpp/2n5/2b1p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C50",
    name: "Italian Game: Rosentreter Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d4",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Modern Bishop's Opening",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Open Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Knight Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq - 5 4",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Dyckhoff Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3",
    length: 7,
    fen: "r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 1 4",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Bxc6",
    length: 7,
    fen: "r1bqkbnr/pppp2pp/2B5/4pp2/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Jaenisch Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. exf5",
    length: 7,
    fen: "r1bqkbnr/pppp2pp/2n5/1B2pP2/8/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Schönemann Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. d4",
    length: 7,
    fen: "r1bqkbnr/pppp2pp/2n5/1B2pp2/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Central Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Spanish Wing Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. b4",
    length: 7,
    fen: "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/1P2P3/5N2/P1PP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 5 4",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Halloween Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nxe5",
    length: 7,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/1B2N3/4P3/8/PPPP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6",
    length: 7,
    fen: "r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Endgame Variation",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. dxe5",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4P3/4p3/2N5/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Lange Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nxe4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3PN3/8/PPP2PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Rasmussen Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nge2",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3Pp3/2N5/PPP1NPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Sneiders Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Qh5",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p2Q/3Pp3/2N5/PPP2PPP/R1B1KBNR b KQkq - 1 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Lemberger Countergambit, Soller Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Be3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3Pp3/2N1B3/PPP2PPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Rasa-Studier Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Be3",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/3Pp3/2N1B3/PPP2PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: von Popiel Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Richter Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. f3",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N2P2/PPP1P1PP/R2QKBNR b KQkq - 0 4",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Two Knights System",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3",
    length: 7,
    fen: "r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R b KQkq - 3 4",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Veresov Variation",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. Bxf6",
    length: 7,
    fen: "rn1qkb1r/ppp1pppp/5B2/3p1b2/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq - 0 4",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Chandler Gambit",
    pgn: "1. d4 d5 2. Nf3 c5 3. g3 cxd4 4. Bg2",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/8/3p4/3p4/5NP1/PPP1PPBP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 e6 4. Bd3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R b KQkq - 1 4",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Queen Attack Deferred",
    pgn: "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Qb3",
    length: 7,
    fen: "rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/1QN5/PP2PPPP/R1B1KBNR b KQkq - 1 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Janowski Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3",
    length: 7,
    fen: "r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Main Line, Alekhine Variation",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4 4. Qa4",
    length: 7,
    fen: "r2qkbnr/ppp1pppp/2n5/3p4/Q1PP2b1/5N2/PP2PPPP/RNB1KB1R b KQkq - 4 4",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Normal Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R b KQkq - 1 4",
  },
  {
    eco: "D10",
    name: "Slav Defense: Slav Gambit, Alekhine Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 dxc4 4. e4",
    length: 7,
    fen: "rnbqkbnr/pp2pppp/2p5/8/2pPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D10",
    name: "Slav Defense: Winawer Countergambit, Anti-Winawer Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 e5 4. e4",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/2p5/3pp3/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D11",
    name: "Slav Defense: Bonet Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq - 3 4",
  },
  {
    eco: "D11",
    name: "Slav Defense: Breyer Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nbd2",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP1NPPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "D11",
    name: "Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "D15",
    name: "Slav Defense: Three Knights Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, McDonnell Defense, Somov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 e5 4. Bxc4",
    length: 7,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/2BPP3/8/PP3PPP/RNBQK1NR b KQkq - 0 4",
  },
  {
    eco: "D21",
    name: "Queen's Gambit Accepted: Alekhine Defense, Borisenko-Furman Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e4",
    length: 7,
    fen: "rnbqkbnr/1pp1pppp/p7/8/2pPP3/5N2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "D23",
    name: "Queen's Gambit Accepted: Mannheim Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Qa4+",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R b KQkq - 3 4",
  },
  {
    eco: "D24",
    name: "Queen's Gambit Accepted: Showalter Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Normal Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3",
    length: 7,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Traditional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq - 1 4",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4",
    length: 7,
    fen: "rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Harrwitz Attack",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bf4",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Modern Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Gibbon Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. g4",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP2P1/2N5/PP2PP1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lutikov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Stockholm Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Zaitsev Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. h4",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP3P/2N5/PP2PPP1/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "D81",
    name: "Grünfeld Defense: Russian Variation, Accelerated Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Qb3",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/1QN5/PP2PPPP/R1B1KBNR b KQkq - 1 4",
  },
  {
    eco: "D82",
    name: "Grünfeld Defense: Brinckmann Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR b KQkq - 1 4",
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3",
    length: 7,
    fen: "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "E01",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2",
    length: 7,
    fen: "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq - 1 4",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Grünfeld Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nbd2",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: New England Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nfd2",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/8/PP1NPPPP/RNBQKB1R b KQkq - 3 4",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3",
    length: 7,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 4",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Miles Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Bf4",
    length: 7,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R b KQkq - 1 4",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3",
    length: 7,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "E14",
    name: "Queen's Indian Defense: Spassky System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. e3",
    length: 7,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3",
    length: 7,
    fen: "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Kmoch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Mikenas Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qd3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2NQ4/PP2PPPP/R1B1KBNR b KQkq - 3 4",
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Romanishin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. g3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 3 4",
  },
  {
    eco: "E22",
    name: "Nimzo-Indian Defense: Spielmann Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR b KQkq - 3 4",
  },
  {
    eco: "E30",
    name: "Nimzo-Indian Defense: Leningrad Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR b KQkq - 3 4",
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR b KQkq - 3 4",
  },
  {
    eco: "E40",
    name: "Nimzo-Indian Defense: Normal Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3",
    length: 7,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "E60",
    name: "Indian Defense: King's Indian Variation, Fianchetto Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2",
    length: 7,
    fen: "rnbqk2r/ppppppbp/5np1/8/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq - 2 4",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Santasiere Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. b4",
    length: 7,
    fen: "rnbqk2r/ppppppbp/5np1/8/1PPP4/5N2/P3PPPP/RNBQKB1R b KQkq - 0 4",
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4",
    length: 7,
    fen: "rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 4",
  },
  {
    eco: "A00",
    name: "Amar Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4",
    length: 8,
    fen: "rn1qkbnr/ppp2ppp/8/3p4/5p2/6PB/PPPPP2P/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A00",
    name: "Amsterdam Attack",
    pgn: "1. e3 e5 2. c4 d6 3. Nc3 Nc6 4. b3 Nf6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2np1n2/4p3/2P5/1PN1P3/P2P1PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Lasker Variation",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 g5",
    length: 8,
    fen: "rnbqk1nr/ppp2p1p/3b4/6p1/8/5N2/PPPPP1PP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "A04",
    name: "Colle System: Rhamphorhynchus Variation",
    pgn: "1. Nf3 c5 2. e3 g6 3. d4 Bg7 4. dxc5 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1P5/8/4PN2/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Polish Variation",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qb6",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/1q4p1/2p5/2PPP3/5N2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/2PPP3/5N2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A04",
    name: "Réti-Zukertort Opening: Shabalov Gambit",
    pgn: "1. Nf3 e6 2. c4 a6 3. Nc3 c5 4. g3 b5",
    length: 8,
    fen: "rnbqkbnr/3p1ppp/p3p3/1pp5/2P5/2N2NP1/PP1PPP1P/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Yugoslav Variation",
    pgn: "1. Nf3 Nf6 2. g3 d5 3. Bg2 c6 4. O-O Bg4",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1 w kq - 2 5",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Bled Variation",
    pgn: "1. Nf3 d5 2. b3 Nf6 3. Bb2 g6 4. c4 c6",
    length: 8,
    fen: "rnbqkb1r/pp2pp1p/2p2np1/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R w KQkq - 0 5",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Capablanca Variation",
    pgn: "1. c4 Nf6 2. Nf3 c6 3. b3 d5 4. Bb2 Bg4",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3N2/PB1PPPPP/RN1QKB1R w KQkq - 2 5",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, London Defensive System",
    pgn: "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bf5",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3NP1/P2PPP1P/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, New York System",
    pgn: "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2 Bf5",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3N2/PB1PPPPP/RN1QKB1R w KQkq - 2 5",
  },
  {
    eco: "A12",
    name: "Réti-Zukertort Opening: Anglo-Slav Variation, Torre System",
    pgn: "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bg4",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3NP1/P2PPP1P/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Bogoljubov Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Bd6",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/3bpn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense Accepted",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2p5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense, Semi-Slav Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 c6",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A13",
    name: "English Opening: Neo-Catalan Declined",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7",
    length: 8,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "A13",
    name: "English Opening: Romanishin Gambit",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 a6 4. Bg2 b5",
    length: 8,
    fen: "rnbqkb1r/2pp1ppp/p3pn2/1p6/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, King's Indian Formation, Double Fianchetto",
    pgn: "1. Nf3 Nf6 2. c4 b6 3. g3 Bb7 4. Bg2 g6",
    length: 8,
    fen: "rn1qkb1r/pbpppp1p/1p3np1/8/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A15",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Formation",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 b6 4. Bg2 Bb7",
    length: 8,
    fen: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "A19",
    name: "English Opening: Anglo-Indian Defense, Flohr-Mikenas-Carls Variation, Nei Gambit",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. e4 c5 4. e5 Ng8",
    length: 8,
    fen: "rnbqkbnr/pp1p1ppp/4p3/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "A21",
    name: "English Opening: King's English Variation, Troger Defense",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 d6 4. Bg2 Be6",
    length: 8,
    fen: "r2qkbnr/ppp2ppp/2npb3/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq - 2 5",
  },
  {
    eco: "A22",
    name: "English Opening: King's English Variation, Bellon Gambit",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 b5",
    length: 8,
    fen: "rnbqkb1r/p1pp1ppp/5n2/1p4N1/2P1p3/2N5/PP1PPPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "A22",
    name: "English Opening: King's English, Erbenheimer Gambit",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 Ng4",
    length: 8,
    fen: "rnbqkb1r/pppp1ppp/8/6N1/2P1p1n1/2N5/PP1PPPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7",
    length: 8,
    fen: "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq - 2 5",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Bradley Beach Variation",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d4 e4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/2PPp3/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "A32",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense",
    pgn: "1. d4 Nf6 2. c4 c5 3. Nf3 cxd4 4. Nxd4 e6",
    length: 8,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Symmetrical Variation",
    pgn: "1. c4 c5 2. g3 g6 3. Bg2 Bg7 4. Nc3 Nc6",
    length: 8,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq - 4 5",
  },
  {
    eco: "A40",
    name: "Colle System: Pterodactyl Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "A40",
    name: "English Defense: Perrin Variation",
    pgn: "1. d4 e6 2. c4 b6 3. e4 Bb7 4. Bd3 Nc6",
    length: 8,
    fen: "r2qkbnr/pbpp1ppp/1pn1p3/8/2PPP3/3B4/PP3PPP/RNBQK1NR w KQkq - 3 5",
  },
  {
    eco: "A40",
    name: "Mikenas Defense: Pozarek Gambit",
    pgn: "1. d4 Nc6 2. c4 e5 3. dxe5 Nxe5 4. Nc3 Nxc4",
    length: 8,
    fen: "r1bqkbnr/pppp1ppp/8/8/2n5/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Central, Benoni Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 c5 4. d5 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1pP4/2P1P3/8/PP3PPP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Benoni Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1pP4/2P5/2N5/PP2PPPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Pterodactyl",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. g3 c5 4. Bg2 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3P4/5NP1/PPP1PPBP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "A40",
    name: "Zaire Defense",
    pgn: "1. d4 Nc6 2. d5 Nb8 3. e4 Nf6 4. e5 Ng8",
    length: 8,
    fen: "rnbqkbnr/pppppppp/8/3PP3/8/8/PPP2PPP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "A41",
    name: "Robatsch Defense",
    pgn: "1. d4 d6 2. Nf3 g6 3. c4 Bg7 4. e4 Bg4",
    length: 8,
    fen: "rn1qk1nr/ppp1ppbp/3p2p1/8/2PPP1b1/5N2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "A41",
    name: "Réti-Zukertort Opening: Wade Defense, Chigorin Plan",
    pgn: "1. d4 d6 2. Nf3 Bg4 3. c4 Nd7 4. Qb3 Rb8",
    length: 8,
    fen: "1r1qkbnr/pppnpppp/3p4/8/2PP2b1/1Q3N2/PP2PPPP/RNB1KB1R w KQk - 3 5",
  },
  {
    eco: "A42",
    name: "Modern Defense: Kotov Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 Nc6",
    length: 8,
    fen: "r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "A42",
    name: "Modern Defense: Randspringer Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 f5",
    length: 8,
    fen: "rnbqk1nr/ppp1p1bp/3p2p1/5p2/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Raptor Variation, Hergert Gambit",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. h4 Nxg5 4. hxg5 e5",
    length: 8,
    fen: "rnbqkb1r/pppp1ppp/8/4p1P1/3P4/8/PPP1PPP1/RN1QKBNR w KQkq - 0 5",
  },
  {
    eco: "A47",
    name: "Marienbad System",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5",
    length: 8,
    fen: "rn1qkb1r/pb1ppppp/1p3n2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A48",
    name: "Indian Defense: Colle System, King's Indian Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. e3 Bg7 4. Bd3 d6",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "A48",
    name: "Torre Attack: Fianchetto Defense, Euwe Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 c5",
    length: 8,
    fen: "rnbqk2r/pp1pppbp/5np1/2p3B1/3P4/5N2/PPPNPPPP/R2QKB1R w KQkq - 0 5",
  },
  {
    eco: "A51",
    name: "Indian Defense: Budapest Defense, Fajarowicz Defense, Bonsdorf Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. a3 b6",
    length: 8,
    fen: "rnbqkb1r/p1pp1ppp/1p6/4P3/2P1n3/P7/1P2PPPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation, Tartakower Defense",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 d6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/3p4/4P3/2P1P1n1/8/PP3PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6",
    length: 8,
    fen: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Chigorin Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 c6",
    length: 8,
    fen: "rnbqkb1r/pp1pp1pp/2p2n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR w KQkq - 0 5",
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Nimzowitsch Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 b6",
    length: 8,
    fen: "rnbqkb1r/p1ppp1pp/1p3n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR w KQkq - 0 5",
  },
  {
    eco: "A84",
    name: "Dutch Defense: Krause Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. Nc3 d6 4. Nf3 Nc6",
    length: 8,
    fen: "r1bqkb1r/ppp1p1pp/2np1n2/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "A90",
    name: "Dutch Defense: Nimzo-Dutch Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Bb4+",
    length: 8,
    fen: "rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP2PPBP/RNBQK1NR w KQkq - 2 5",
  },
  {
    eco: "A91",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7",
    length: 8,
    fen: "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR w KQkq - 2 5",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Main Line",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Ng6",
    length: 8,
    fen: "r1bqkbnr/pppp1ppp/6n1/8/4PP2/8/PPP3PP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Kennedy Variation, Riemann Defense",
    pgn: "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Nc6",
    length: 8,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/4PP2/8/PPP3PP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Nimzowitsch Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Ne5",
    length: 8,
    fen: "r1bqkbnr/ppp1pppp/8/3Pn3/4p3/2N5/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B00",
    name: "St. George Defense: Polish Variation",
    pgn: "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 e6",
    length: 8,
    fen: "rn1qkbnr/1bpp1ppp/p3p3/1p6/3PP3/3B1N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5",
    length: 8,
    fen: "rnb1kbnr/ppp2ppp/8/q3p3/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kiel Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. c4 Nb4",
    length: 8,
    fen: "rnbqkb1r/ppp1pppp/8/8/1nPP4/8/PP3PPP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Kádas Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 c6 4. dxc6 e5",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/2P2n2/4p3/3P4/8/PPP2PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Main Line, Mieses Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6",
    length: 8,
    fen: "rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation, Gipslis Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 Bg4",
    length: 8,
    fen: "rn1qkb1r/ppp1pppp/8/3n4/3P2b1/5N2/PPP2PPP/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Modern Variation, Wing Gambit",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 g6 4. c4 b5",
    length: 8,
    fen: "rnbqkb1r/p1p1pp1p/5np1/1p1P4/2PP4/8/PP3PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Elbow Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Bb5+ c6",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/1B1P4/3P2b1/8/PPP2PPP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Richter Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 g6",
    length: 8,
    fen: "rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Schiller-Pytel Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 c6",
    length: 8,
    fen: "rnb1kbnr/pp2pppp/2pq4/8/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Alburt Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 g6",
    length: 8,
    fen: "rnbqkb1r/ppp1pp1p/3p2p1/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Larsen Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 dxe5",
    length: 8,
    fen: "rnbqkb1r/ppp1pppp/8/3np3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Larsen-Haakert Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nc6",
    length: 8,
    fen: "r1bqkb1r/ppp1pppp/2np4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B04",
    name: "Alekhine Defense: Modern Variation, Schmid Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nb6",
    length: 8,
    fen: "rnbqkb1r/ppp1pppp/1n1p4/4P3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Main Line",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4",
    length: 8,
    fen: "rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B06",
    name: "Modern Defense: Bishop Attack, Monkey's Bum",
    pgn: "1. e4 g6 2. Bc4 Bg7 3. Qf3 e6 4. d4 Bxd4",
    length: 8,
    fen: "rnbqk1nr/pppp1p1p/4p1p1/8/2BbP3/5Q2/PPP2PPP/RNB1K1NR w KQkq - 0 5",
  },
  {
    eco: "B06",
    name: "Modern Defense: Norwegian Defense, Norwegian Gambit",
    pgn: "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. Be2 d6",
    length: 8,
    fen: "rnbqkb1r/ppp1pp1p/3p2p1/4P2n/3P4/8/PPP1BPPP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation, Suttles Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Nf3 d6",
    length: 8,
    fen: "rnbqk1nr/pp2ppbp/2pp2p1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "B06",
    name: "Norwegian Defense",
    pgn: "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. g4 Ng7",
    length: 8,
    fen: "rnbqkb1r/ppppppnp/6p1/4P3/3P2P1/8/PPP2P1P/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Austriadactylus Western",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4 c5 4. Nf3 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PPP2/5N2/PPP3PP/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Grand Prix Pterodactyl",
    pgn: "1. e4 g6 2. Nc3 Bg7 3. f4 c5 4. Nf3 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Austrian, Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. f4 c5 4. c3 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PPP2/2P5/PP4PP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni Pterodactyl",
    pgn: "1. d4 g6 2. Nc3 Bg7 3. e4 c5 4. d5 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1pP4/4P3/2N5/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2N5/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Fianchetto, King Pterodactyl",
    pgn: "1. e4 g6 2. d4 Bg7 3. g3 c5 4. Nf3 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/5NP1/PPP2P1P/RNBQKB1R w KQkq - 2 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Fianchetto, Rhamphorhynchus",
    pgn: "1. e4 g6 2. d4 Bg7 3. g3 c5 4. dxc5 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/6P1/PPP2P1P/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Western, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nf3 c5 4. Be3 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/4BN2/PPP2PPP/RN1QKB1R w KQkq - 2 5",
  },
  {
    eco: "B07",
    name: "Lion Defense: Anti-Philidor, Lion's Cave",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4 e5",
    length: 8,
    fen: "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PPP2/2N5/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "B10",
    name: "Caro-Kann Defense: Accelerated Panov Attack, Modern Variation",
    pgn: "1. e4 c6 2. c4 d5 3. exd5 cxd5 4. cxd5 Nf6",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/5n2/3P4/8/8/PP1P1PPP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Exchange Line",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bxf3",
    length: 8,
    fen: "rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "B11",
    name: "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Retreat Line",
    pgn: "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bh5",
    length: 8,
    fen: "rn1qkbnr/pp2pppp/2p5/3p3b/4P3/2N2N1P/PPPP1PP1/R1BQKB1R w KQkq - 1 5",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance Variation, Van der Wiel Attack, Dreyev Defense",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3 Qb6",
    length: 8,
    fen: "rn2kbnr/pp2pppp/1qp5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 3 5",
  },
  {
    eco: "B16",
    name: "Caro-Kann Defense: Finnish Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 h6",
    length: 8,
    fen: "rnbqkbnr/pp2ppp1/2p4p/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7",
    length: 8,
    fen: "r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5",
    length: 8,
    fen: "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Gloria Variation",
    pgn: "1. e4 c5 2. c4 d6 3. Nc3 Nc6 4. g3 h5",
    length: 8,
    fen: "r1bqkbnr/pp2ppp1/2np4/2p4p/2P1P3/2N3P1/PP1P1P1P/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Smith-Morra Declined",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. d4 cxd4",
    length: 8,
    fen: "rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "B24",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7",
    length: 8,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR w KQkq - 2 5",
  },
  {
    eco: "B27",
    name: "Modern Defense: Pterodactyl Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 3 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Western, Pterodactyl",
    pgn: "1. e4 c5 2. Nf3 g6 3. c3 Bg7 4. d4 Qa5",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2P2N2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Western, Rhamphorhynchus",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+",
    length: 8,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Normal System, Taimanov Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Nxd4 e5",
    length: 8,
    fen: "rnbqkbnr/1p1p1ppp/p7/4p3/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B28",
    name: "Sicilian Defense: O'Kelly Variation, Venice System, Gambit Line",
    pgn: "1. e4 c5 2. Nf3 a6 3. c3 d5 4. exd5 Nf6",
    length: 8,
    fen: "rnbqkb1r/1p2pppp/p4n2/2pP4/8/2P2N2/PP1P1PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 Nxc3",
    length: 8,
    fen: "rnbqkb1r/pp1ppppp/8/2p1P3/8/2n2N2/PPPP1PPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Accelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6",
    length: 8,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Flohr Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qc7",
    length: 8,
    fen: "r1b1kbnr/ppqppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Godiva Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qb6",
    length: 8,
    fen: "r1b1kbnr/pp1ppppp/1qn5/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Löwenthal Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5",
    length: 8,
    fen: "r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Nimzo-American Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d5",
    length: 8,
    fen: "r1bqkbnr/pp2pppp/2n5/3p4/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Open",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6",
    length: 8,
    fen: "r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: French Variation, Normal",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6",
    length: 8,
    fen: "rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kveinis Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Qb6",
    length: 8,
    fen: "rnb1kbnr/pp1p1ppp/1q2p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Paulsen-Basman Defense",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Bc5",
    length: 8,
    fen: "rnbqk1nr/pp1p1ppp/4p3/2b5/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6",
    length: 8,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B44",
    name: "Sicilian Defense: Taimanov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6",
    length: 8,
    fen: "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order Accepted",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 Nf6 4. dxc5 Nxe4",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/3p4/2P5/4n3/5N2/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Dragon Variation, Accelerated Dragon",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 g6",
    length: 8,
    fen: "rnbqkbnr/pp2pp1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Modern Variations, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C00",
    name: "French Defense: Hoffmann Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Qe2 e5 4. f4 exf4",
    length: 8,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/3PPp2/8/PPP1Q1PP/RNB1KBNR w KQkq - 0 5",
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, St. George Gambit",
    pgn: "1. e4 e6 2. d4 a6 3. c4 b5 4. cxb5 axb5",
    length: 8,
    fen: "rnbqkbnr/2pp1ppp/4p3/1p6/3PP3/8/PP3PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6",
    length: 8,
    fen: "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "C04",
    name: "French Defense: Tarrasch Variation, Guimard Defense, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. Ngf3 Nf6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R w KQkq - 4 5",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Chistyakov Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5",
    length: 8,
    fen: "rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Open System, Shaposhnikov Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Nf6",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "C08",
    name: "French Defense: Tarrasch Variation, Open System",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Blackburne Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7",
    length: 8,
    fen: "r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Ellis Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 e5",
    length: 8,
    fen: "rnbqkbnr/ppp2ppp/8/4p3/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Maric Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Qd5",
    length: 8,
    fen: "rnb1kbnr/ppp2ppp/4p3/3q4/3PN3/8/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR w KQkq - 4 5",
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Normal Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7",
    length: 8,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR w KQkq - 4 5",
  },
  {
    eco: "C16",
    name: "French Defense: Winawer Variation, Petrosian Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 Qd7",
    length: 8,
    fen: "rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5",
    length: 8,
    fen: "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Weber Gambit",
    pgn: "1. e4 e5 2. d3 d5 3. exd5 c6 4. dxc6 Nxc6",
    length: 8,
    fen: "r1bqkbnr/pp3ppp/2n5/4p3/8/3P4/PPP2PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "C21",
    name: "Center Game: Kieseritzky Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4 b5",
    length: 8,
    fen: "rnbqkbnr/p2p1ppp/8/1pp5/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C22",
    name: "Center Game: Berger Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/4P3/4Q3/PPP2PPP/RNB1KBNR w KQkq - 3 5",
  },
  {
    eco: "C22",
    name: "Center Game: l'Hermet Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 f5",
    length: 8,
    fen: "r1bqkbnr/pppp2pp/2n5/5p2/4P3/4Q3/PPP2PPP/RNB1KBNR w KQkq - 0 5",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/5n2/2bBp3/4P3/2P5/PP1P1PPP/RNBQK1NR w KQkq - 1 5",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Kitchener Folly",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d3 Be7 4. Nf3 O-O",
    length: 8,
    fen: "rnbq1rk1/ppppbppp/5n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQ - 3 5",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Cunningham Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 Be7",
    length: 8,
    fen: "r1bqk1nr/ppppbppp/2n5/8/4Pp2/2N2N2/PPPP2PP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Quelle Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 Bc5 4. fxe5 d6",
    length: 8,
    fen: "r1bqk1nr/ppp2ppp/2np4/2b1P3/4P3/2N5/PPPP2PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C26",
    name: "Vienna Game: Mieses Variation, Erben Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3 d5 4. exd5 c6",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/2p2n2/3Pp3/8/2N3P1/PPPP1P1P/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "C26",
    name: "Vienna Game: Stanley Variation, Eifel Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. Nge2 b5",
    length: 8,
    fen: "rnbqk2r/p1pp1ppp/5n2/1pb1p3/2B1P3/2N5/PPPPNPPP/R1BQK2R w KQkq - 0 5",
  },
  {
    eco: "C27",
    name: "Bishop's Opening: Boden-Kieseritzky Gambit, Lichtenhein Defense",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3 d5",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/8/3pp3/2B1n3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 0 5",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Rubinstein Countergambit",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 f5",
    length: 8,
    fen: "rnbqk1nr/ppp3pp/3p4/2b1pp2/4PP2/2P2N2/PP1P2PP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical, Hanham Variation",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. Nc3 Nd7",
    length: 8,
    fen: "r1bqk1nr/pppn1ppp/3p4/2b1p3/4PP2/2N2N2/PPPP2PP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Hobbs-Zilbermints Gambit",
    pgn: "1. e4 e5 2. f4 Nc6 3. Nf3 g5 4. fxg5 h6",
    length: 8,
    fen: "r1bqkbnr/pppp1p2/2n4p/4p1P1/4P3/5N2/PPPP2PP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Shirazi Line",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ Ke7",
    length: 8,
    fen: "rnbq1bnr/ppppk1pp/8/5P1Q/5p2/8/PPPP2PP/RNB1KBNR w KQ - 2 5",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Pickler Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 c6 4. dxc6 Bc5",
    length: 8,
    fen: "rnbqk1nr/pp3ppp/2P5/2b1p3/5P2/8/PPPP2PP/RNBQKBNR w KQkq - 1 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Anderssen Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 c6",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/2p5/3B4/4Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bledow Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Nf6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/5n2/3B4/4Pp2/8/PPPP2PP/RNBQK1NR w KQkq - 1 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Boden Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nc6",
    length: 8,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 4 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 c6",
    length: 8,
    fen: "rnbqkb1r/pp1p1ppp/2p2n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR w KQkq - 0 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Bryan Countergambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 b5",
    length: 8,
    fen: "rnb1kbnr/p1pp1ppp/8/1p6/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 0 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Cozio Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d6",
    length: 8,
    fen: "rnb1kbnr/ppp2ppp/3p4/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 0 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, First Jaenisch Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nf6",
    length: 8,
    fen: "rnb1kb1r/pppp1ppp/5n2/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 4 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Greco Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Bc5",
    length: 8,
    fen: "rnb1k1nr/pppp1ppp/8/2b5/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 4 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Lopez Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5",
    length: 8,
    fen: "rnb1kbnr/pppp1p1p/8/6p1/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 0 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Tartakower Gambit, Weiss Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Be2 f5 4. exf5 d6",
    length: 8,
    fen: "rnbqkbnr/ppp3pp/3p4/5P2/5p2/8/PPPPB1PP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense, McCormick Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Nf6",
    length: 8,
    fen: "rnbqk2r/ppppbppp/5n2/8/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq - 4 5",
  },
  {
    eco: "C36",
    name: "King's Gambit Accepted: Abbazia Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5 Nf6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/5n2/3P4/5p2/5N2/PPPP2PP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Blachly Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Bc4 g5",
    length: 8,
    fen: "r1bqkbnr/pppp1p1p/2n5/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Traditional Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7",
    length: 8,
    fen: "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C40",
    name: "Elephant Gambit: Wasp Variation",
    pgn: "1. e4 e5 2. Nf3 d5 3. Nxe5 dxe4 4. Bc4 Qg5",
    length: 8,
    fen: "rnb1kbnr/ppp2ppp/8/4N1q1/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense, Damiano Gambit, Chigorin Gambit",
    pgn: "1. e4 e5 2. Nf3 f6 3. Nxe5 Qe7 4. Nf3 d5",
    length: 8,
    fen: "rnb1kbnr/ppp1q1pp/5p2/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Corkscrew Countergambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Nf6",
    length: 8,
    fen: "rnbqkb1r/pppp2pp/5n2/4N3/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Polerio-Svedenborg Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 d5",
    length: 8,
    fen: "rnbqkbnr/ppp3pp/8/3pN3/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Albin-Blackburne Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Nd7",
    length: 8,
    fen: "r2qkbnr/pppn1ppp/3p4/4P3/4P1b1/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Boden Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Qxd4 Bd7",
    length: 8,
    fen: "rn1qkbnr/pppb1ppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R w KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Exchange Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nf6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Sharp Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 Nb6",
    length: 8,
    fen: "r1bqkbnr/ppp2ppp/1n1p4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R w KQkq - 3 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Larsen Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 g6",
    length: 8,
    fen: "rnbqkbnr/ppp2p1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7",
    length: 8,
    fen: "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 3 5",
  },
  {
    eco: "C42",
    name: "Russian Game",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Damiano Variation, Kholmov Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qe2 Qe7",
    length: 8,
    fen: "rnb1kb1r/ppppqppp/8/4N3/4n3/8/PPPPQPPP/RNB1KB1R w KQkq - 2 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Stafford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6 4. Nxc6 dxc6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/8/PPPP1PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Murrey Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3 Nc6",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Caro Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Bd7",
    length: 8,
    fen: "r2qkbnr/pppb1ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq - 2 5",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Nf6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2n2n2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq - 2 5",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 f6",
    length: 8,
    fen: "r1bqkbnr/ppp3pp/2n2p2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Benima Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4",
    length: 8,
    fen: "r1bqk1nr/ppppbppp/2n5/8/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Haxo Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b5/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Dubois Réti Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Göring Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 d5",
    length: 8,
    fen: "r1bqkbnr/ppp2ppp/2n5/3p4/3pP3/2P2N2/PP3PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, London Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bb4+",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/2n5/8/1bBpP3/5N2/PPP2PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Malaniuk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bb4+",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/2n5/8/1b1NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Schmidt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4",
    length: 8,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/8/PPP2PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Schlechter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4 4. Nd5 Nf6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/3Np3/1b2P3/5N2/PPPP1PPP/R1BQKB1R w KQkq - 6 5",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bc5",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 6 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 6 5",
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Double Spanish",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 6 5",
  },
  {
    eco: "C50",
    name: "Four Knights Game: Italian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 Nf6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 6 5",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Lucchini Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5",
    length: 8,
    fen: "r1bqk1nr/pppp2pp/2n5/2b1pp2/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Normal",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Fontaine Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 b5",
    length: 8,
    fen: "r1bqk1nr/p1pp1ppp/2n5/1pb1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Hein Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 d5",
    length: 8,
    fen: "r1bqk1nr/ppp2ppp/2n5/2bpp3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Alexandre Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 f5",
    length: 8,
    fen: "r1bqk1nr/pppp2pp/2n5/2b1pp2/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Closed Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7",
    length: 8,
    fen: "r1b1k1nr/ppppqppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Invitation Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 d6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2np1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 Nxe4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n5/4p3/2BPn3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Knight Attack, Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Ponziani-Steinitz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Nxe4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n5/4p1N1/2B1n3/8/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq - 6 5",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Cozio Defense, Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. Nc3 g6",
    length: 8,
    fen: "r1bqkb1r/ppppnp1p/2n3p1/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq - 0 5",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Fianchetto Defense, Kevitz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 f5",
    length: 8,
    fen: "r1bqkbnr/pppp3p/2n3p1/1B2pp2/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Boden Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Qe7",
    length: 8,
    fen: "r1b1k1nr/ppppqppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Charousek Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Bb6",
    length: 8,
    fen: "r1bqk1nr/pppp1ppp/1bn5/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Cordel Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 f5",
    length: 8,
    fen: "r1bqk1nr/pppp2pp/2n5/1Bb1pp2/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Variation, Konikowski Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 d5",
    length: 8,
    fen: "r1bqk1nr/ppp2ppp/2n5/1Bbpp3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Beverwijk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 6 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Fishing Pole Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Ng4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n5/1B2p3/4P1n1/5N2/PPPP1PPP/RNBQ1RK1 w kq - 6 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Mortimer Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7",
    length: 8,
    fen: "r1bqkb1r/ppppnppp/5n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R w KQkq - 1 5",
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Improved Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6",
    length: 8,
    fen: "r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 5",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rio Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4",
    length: 8,
    fen: "r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 5",
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Lutikov Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6",
    length: 8,
    fen: "r1bqkbnr/2pp1ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Bird's Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nd4",
    length: 8,
    fen: "r1bqkbnr/1ppp1ppp/p7/4p3/B2nP3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Alapin's Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bb4",
    length: 8,
    fen: "r1bqk1nr/1ppp1ppp/p1n5/4p3/Bb2P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Caro Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5",
    length: 8,
    fen: "r1bqkbnr/2pp1ppp/p1n5/1p2p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Classical Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bc5",
    length: 8,
    fen: "r1bqk1nr/1ppp1ppp/p1n5/2b1p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Cozio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nge7",
    length: 8,
    fen: "r1bqkb1r/1pppnppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Fianchetto Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 g6",
    length: 8,
    fen: "r1bqkbnr/1ppp1p1p/p1n3p1/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5",
    length: 8,
    fen: "r1bqkbnr/1ppp2pp/p1n5/4pp2/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6",
    length: 8,
    fen: "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3",
    length: 8,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N2p2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Brombacher Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 c5",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/5n2/2p5/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Elbert Countergambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e5",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/5n2/4p3/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Gedult Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 a6",
    length: 8,
    fen: "rnbqkb1r/1pp1pppp/p4n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Lamb Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 Nc6",
    length: 8,
    fen: "r1bqkb1r/ppp1pppp/2n2n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Langeheinicke Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e3",
    length: 8,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N1pP2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: O'Kelly Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 c6",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Vienna Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 Bf5",
    length: 8,
    fen: "rn1qkb1r/ppp1pppp/5n2/5b2/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Declined: Weinsbach Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Two Knights System, Grünfeld Defense",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3 g6",
    length: 8,
    fen: "r1bqkb1r/pppnpp1p/5np1/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R w KQkq - 0 5",
  },
  {
    eco: "D02",
    name: "Queen's Gambit Declined: Baltic Defense, Pseudo-Slav",
    pgn: "1. d4 d5 2. Nf3 Bf5 3. c4 e6 4. Nc3 c6",
    length: 8,
    fen: "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: Levitsky Attack, Euwe Variation, Modern Line",
    pgn: "1. d4 d5 2. Nf3 c6 3. Bg5 h6 4. Bh4 Qb6",
    length: 8,
    fen: "rnb1kbnr/pp2ppp1/1qp4p/3p4/3P3B/5N2/PPP1PPPP/RN1QKB1R w KQkq - 2 5",
  },
  {
    eco: "D04",
    name: "Queen's Pawn Game: Colle System, Grünfeld Formation",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. e3 g6 4. Bd3 Bg7",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq - 2 5",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Pseudo-Chigorin",
    pgn: "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Nf3 Nc6",
    length: 8,
    fen: "r2qkbnr/ppp2ppp/2n1p3/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "D07",
    name: "Queen's Gambit Declined: Chigorin Defense, Modern Gambit",
    pgn: "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3 Nf6",
    length: 8,
    fen: "r1bqkb1r/ppp1pppp/2n2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Tartakower Defense",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 c5",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/8/2p1P3/2Pp4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "D11",
    name: "Slav Defense: Quiet Variation, Pin Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bg4",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/4PN2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "D12",
    name: "Slav Defense: Quiet Variation, Schallopp Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5",
    length: 8,
    fen: "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "D13",
    name: "Slav Defense: Exchange Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. cxd5 cxd5",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Chebanenko Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6",
    length: 8,
    fen: "rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Schlechter Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 g6",
    length: 8,
    fen: "rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Süchting Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 Qb6",
    length: 8,
    fen: "rnb1kb1r/pp2pppp/1qp2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 4 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Two Knights Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4",
    length: 8,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Central Variation, Rubinstein Defense, Yefimov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 b5",
    length: 8,
    fen: "rnbqkbnr/p3pppp/8/1ppP4/2p1P3/8/PP3PPP/RNBQKBNR w KQkq - 0 5",
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense, Haberditz Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 b5",
    length: 8,
    fen: "rnbqkbnr/2p1pppp/p7/1p6/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Janowski-Larsen Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Bg4",
    length: 8,
    fen: "rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Smyslov Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 g6",
    length: 8,
    fen: "rnbqkb1r/ppp1pp1p/5np1/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "D25",
    name: "Queen's Gambit Accepted: Winawer Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Be6",
    length: 8,
    fen: "rn1qkb1r/ppp1pppp/4bn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Normal Variation, Traditional System",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 h6",
    length: 8,
    fen: "rnbqkb1r/ppp2pp1/4pn1p/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R w KQkq - 0 5",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 Bb4+",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/5N2/PP2PPPP/RN1QKB1R w KQkq - 2 5",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D32",
    name: "Queen's Gambit Declined: Tarrasch Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Schara Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 cxd4",
    length: 8,
    fen: "rnbqkbnr/pp3ppp/4p3/3P4/3p4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Barmen Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7",
    length: 8,
    fen: "r1bqkb1r/pppn1ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Three Knights, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Ragozin Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "D40",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D43",
    name: "Semi-Slav Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 5",
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Been-Koomen Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5",
    length: 8,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq - 0 5",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7",
    length: 8,
    fen: "r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq - 4 5",
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7",
    length: 8,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq - 4 5",
  },
  {
    eco: "D85",
    name: "Grünfeld Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5",
    length: 8,
    fen: "rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Three Knights Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "E02",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4",
    length: 8,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5",
    length: 8,
    fen: "rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Bxd2+",
    length: 8,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1bPPPP/RN1QKB1R w KQkq - 0 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Haiti Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Nc6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq - 4 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Qe7",
    length: 8,
    fen: "rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq - 4 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Retreat Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Be7",
    length: 8,
    fen: "rnbqk2r/ppppbppp/4pn2/8/2PP4/5N2/PP1BPPPP/RN1QKB1R w KQkq - 4 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Vitolins Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 c5",
    length: 8,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq - 0 5",
  },
  {
    eco: "E11",
    name: "Bogo-Indian Defense: Wade-Smyslov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5",
    length: 8,
    fen: "rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq - 0 5",
  },
  {
    eco: "E12",
    name: "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4",
    length: 8,
    fen: "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 2 5",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6",
    length: 8,
    fen: "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Traditional Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7",
    length: 8,
    fen: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R w KQkq - 1 5",
  },
  {
    eco: "E33",
    name: "Nimzo-Indian Defense: Classical Variation, Zurich Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq - 4 5",
  },
  {
    eco: "E34",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5",
    length: 8,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq - 0 5",
  },
  {
    eco: "E38",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5",
    length: 8,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq - 0 5",
  },
  {
    eco: "E40",
    name: "Nimzo-Indian Defense: Normal Variation, Taimanov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 Nc6",
    length: 8,
    fen: "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq - 1 5",
  },
  {
    eco: "E41",
    name: "Nimzo-Indian Defense: Hübner Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5",
    length: 8,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "E43",
    name: "Nimzo-Indian Defense: St. Petersburg Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6",
    length: 8,
    fen: "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O",
    length: 8,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQ - 1 5",
  },
  {
    eco: "E60",
    name: "Grünfeld Defense: Counterthrust Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR w KQkq - 0 5",
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6",
    length: 8,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq - 0 5",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Burk Gambit",
    pgn: "1. g3 e5 2. a3 d5 3. Nf3 e4 4. Nh4 Be7 5. d3",
    length: 9,
    fen: "rnbqk1nr/ppp1bppp/8/3p4/4p2N/P2P2P1/1PP1PP1P/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Paris Gambit",
    pgn: "1. g3 e5 2. Nh3 d5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O",
    length: 9,
    fen: "rn1qkbnr/ppp2ppp/8/3p4/5p2/6PB/PPPPP2P/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "A02",
    name: "Bird Opening: From's Gambit, Lipke Variation",
    pgn: "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 Nh6 5. d4",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/3b3n/8/3P4/5N2/PPP1P1PP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "A03",
    name: "Bird Opening: Thomas Gambit",
    pgn: "1. f4 d5 2. b3 Nf6 3. Bb2 d4 4. Nf3 c5 5. e3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/5n2/2p5/3p1P2/1P2PN2/PBPP2PP/RN1QKB1R b KQkq - 0 5",
  },
  {
    eco: "A08",
    name: "Réti-Zukertort Opening: Reversed Grünfeld",
    pgn: "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. d4 e6 5. O-O",
    length: 9,
    fen: "r1bqkbnr/pp3ppp/2n1p3/2pp4/3P4/5NP1/PPP1PPBP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Catalan Defense",
    pgn: "1. Nf3 d5 2. c4 e6 3. g3 b6 4. Bg2 Bb7 5. O-O",
    length: 9,
    fen: "rn1qkbnr/pbp2ppp/1p2p3/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 b kq - 3 5",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Wimpy System",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. b3 d5 4. Bb2 c5 5. e3",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R b KQkq - 0 5",
  },
  {
    eco: "A14",
    name: "English Opening: Agincourt Defense, Neo-Catalan Declined",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. O-O",
    length: 9,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 b kq - 3 5",
  },
  {
    eco: "A17",
    name: "English Opening: Anglo-Indian Defense, Queen's Indian Variation",
    pgn: "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6 4. e4 Bb7 5. Bd3",
    length: 9,
    fen: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2P1P3/2NB1N2/PP1P1PPP/R1BQK2R b KQkq - 2 5",
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Closed System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3",
    length: 9,
    fen: "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR b KQkq - 0 5",
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Hungarian Attack",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Rb1",
    length: 9,
    fen: "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR b Kkq - 3 5",
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Botvinnik System",
    pgn: "1. e4 c5 2. c4 Nc6 3. Nc3 g6 4. g3 Bg7 5. Bg2",
    length: 9,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR b KQkq - 2 5",
  },
  {
    eco: "A37",
    name: "English Opening: Symmetrical Variation, Three Knights, Fianchetto Variation",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3",
    length: 9,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R b KQkq - 3 5",
  },
  {
    eco: "A40",
    name: "English Defense: Hartlaub Gambit Declined",
    pgn: "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. d5",
    length: 9,
    fen: "rn1qkbnr/pbpp2pp/1p2p3/3P1p2/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "A42",
    name: "Modern Defense: Averbakh Variation, Pseudo-Sämisch",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Be3 Nf6 5. f3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/4BP2/PP4PP/RN1QKBNR b KQkq - 0 5",
  },
  {
    eco: "A45",
    name: "Indian Defense: Gibbins-Weidenhagen Gambit, Stummer Gambit",
    pgn: "1. d4 Nf6 2. g4 Nxg4 3. e4 d6 4. Be2 Nf6 5. Nc3",
    length: 9,
    fen: "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP1BP1P/R1BQK1NR b KQkq - 3 5",
  },
  {
    eco: "A46",
    name: "Torre Attack: Classical Defense, Petrosian Gambit",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e3 b6 5. d5",
    length: 9,
    fen: "rnbqkb1r/p2p1ppp/1p2pn2/2pP2B1/8/4PN2/PPP2PPP/RN1QKB1R b KQkq - 0 5",
  },
  {
    eco: "A54",
    name: "Old Indian Defense: Dus-Khotimirsky Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. e3 Nbd7 5. Bd3",
    length: 9,
    fen: "r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2NBP3/PP3PPP/R1BQK1NR b KQkq - 2 5",
  },
  {
    eco: "A55",
    name: "Old Indian Defense: Normal Variation",
    pgn: "1. d4 Nf6 2. c4 d6 3. Nc3 Nbd7 4. e4 e5 5. Nf3",
    length: 9,
    fen: "r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Dlugy Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. f3",
    length: 9,
    fen: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/5P2/PP2P1PP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Modern Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. e3",
    length: 9,
    fen: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/4P3/PP3PPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "A57",
    name: "Benko Gambit Accepted: Pawn Return Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. b6",
    length: 9,
    fen: "rnbqkb1r/3ppppp/pP3n2/2pP4/8/8/PP2PPPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "A57",
    name: "Benko Gambit: Zaitsev System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. Nc3",
    length: 9,
    fen: "rnbqkb1r/3ppppp/p4n2/1PpP4/8/2N5/PP2PPPP/R1BQKBNR b KQkq - 1 5",
  },
  {
    eco: "A58",
    name: "Benko Gambit Accepted: Fully Accepted Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6",
    length: 9,
    fen: "rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Alekhine Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. h4",
    length: 9,
    fen: "rnbqkb1r/ppppp2p/5np1/6B1/3Pp2P/2N5/PPP2PP1/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "A83",
    name: "Dutch Defense: Staunton Gambit, Lasker Variation",
    pgn: "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. f3",
    length: 9,
    fen: "rnbqkb1r/ppppp2p/5np1/6B1/3Pp3/2N2P2/PPP3PP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "A87",
    name: "Dutch Defense: Leningrad Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 g6 4. Bg2 Bg7 5. Nf3",
    length: 9,
    fen: "rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq - 3 5",
  },
  {
    eco: "A91",
    name: "Dutch Defense: Classical Variation, Blackburne Attack",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nh3",
    length: 9,
    fen: "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6PN/PP2PPBP/RNBQK2R b KQkq - 3 5",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Richter Gambit",
    pgn: "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Nb8 5. f3",
    length: 9,
    fen: "rnbqkbnr/ppp1pppp/8/3P4/4p3/2N2P2/PPP3PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack, Goteborg System",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3",
    length: 9,
    fen: "rnb1kbnr/ppp2ppp/8/q3p3/3P4/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Banker Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. c4",
    length: 9,
    fen: "rn1qkb1r/ppp1pppp/5n2/3P1b2/2PP4/5P2/PP4PP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Correspondence Refutation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. g4",
    length: 9,
    fen: "rn1qkb1r/ppp1pppp/5n2/3P1b2/3P2P1/5P2/PPP4P/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Lusophobe Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Bb5+ Nbd7 5. Be2",
    length: 9,
    fen: "r2qkb1r/pppnpppp/5n2/3P4/3P2b1/8/PPP1BPPP/RNBQK1NR b KQkq - 4 5",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Kmoch Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. Bc4 Nb6 4. Bb3 c5 5. d3",
    length: 9,
    fen: "rnbqkb1r/pp1ppppp/1n6/2p1P3/8/1B1P4/PPP2PPP/RNBQK1NR b KQkq - 0 5",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Exchange Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. exd6",
    length: 9,
    fen: "rnbqkb1r/ppp1pppp/1n1P4/8/2PP4/8/PP3PPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4",
    length: 9,
    fen: "rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP1P2/8/PP4PP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Hunt Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. c5",
    length: 9,
    fen: "rnbqkb1r/ppp1pppp/1n1p4/2P1P3/3P4/8/PP3PPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Alekhine Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4",
    length: 9,
    fen: "rn1qkb1r/ppp1pppp/3p4/3nP3/2PP2b1/5N2/PP3PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Panov Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. h3",
    length: 9,
    fen: "rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N1P/PPP2PP1/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "B06",
    name: "Modern Defense: Anti-Modern",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Bc4 d6 5. Qe2",
    length: 9,
    fen: "rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N5/PPP1QPPP/R1B1K1NR b KQkq - 1 5",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Sicilian, Siroccopteryx",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Bc4",
    length: 9,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/2BPP3/2N2N2/PPP2PPP/R1BQK2R b KQkq - 3 5",
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2",
    length: 9,
    fen: "rnbqkb1r/pp2pp1p/2pp1np1/8/3PP3/2N1B3/PPPQ1PPP/R3KBNR b KQkq - 1 5",
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack, Sveshnikov-Jansa Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. h3",
    length: 9,
    fen: "rnbqkb1r/pp2pp1p/2pp1np1/8/3PP3/2N1B2P/PPP2PP1/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "B07",
    name: "Pirc Defense: Bayonet Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. h4",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP2P/2N5/PPP1BPP1/R1BQK1NR b KQkq - 0 5",
  },
  {
    eco: "B07",
    name: "Pirc Defense: Chinese Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. g4",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP1P1/2N5/PPP1BP1P/R1BQK1NR b KQkq - 0 5",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R b KQkq - 3 5",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Schlechter Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. h3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Ljubojevic Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Bc4",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2BPPP2/2N5/PPP3PP/R1BQK1NR b KQkq - 2 5",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Advance, Short Variation",
    pgn: "1. e4 c6 2. d4 d5 3. e5 Bf5 4. c3 e6 5. Be2",
    length: 9,
    fen: "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/2P5/PP2BPPP/RNBQK1NR b KQkq - 1 5",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Mieses Attack, Landau Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Bd3 Nf6 4. e5 Nfd7 5. e6",
    length: 9,
    fen: "rnbqkb1r/pp1npppp/2p1P3/3p4/3P4/3B4/PPP2PPP/RNBQK1NR b KQkq - 0 5",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR b KQkq - 2 5",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Gunderam Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. c5",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/5n2/2Pp4/3P4/8/PP3PPP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Alekhine Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Bd3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3PN3/3B4/PPP2PPP/R1BQK1NR b KQkq - 2 5",
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5",
    length: 9,
    fen: "r1bqkbnr/pp1npppp/2p5/6N1/3P4/8/PPP2PPP/R1BQKBNR b KQkq - 2 5",
  },
  {
    eco: "B20",
    name: "Sicilian Defense: Wing Gambit, Marienbad Variation",
    pgn: "1. e4 c5 2. b4 cxb4 3. a3 d5 4. exd5 Qxd5 5. Bb2",
    length: 9,
    fen: "rnb1kbnr/pp2pppp/8/3q4/1p6/P7/1BPP1PPP/RN1QKBNR b KQkq - 1 5",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Coles Sicilian Gambit",
    pgn: "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Bc4",
    length: 9,
    fen: "r1bqkb1r/pp1ppppp/2n2n2/8/2B1P3/8/PPP2PPP/RNBQK1NR b KQkq - 4 5",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Heidenfeld Variation",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Na3",
    length: 9,
    fen: "r1bqkb1r/pp1ppppp/2n5/2pnP3/8/N1P2N2/PP1P1PPP/R1BQKB1R b KQkq - 4 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Anhanguera",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Be3",
    length: 9,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N1BN2/PPP2PPP/R2QKB1R b KQkq - 4 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Benoni Gambit",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. d5",
    length: 9,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Rhamphorhynchus",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3",
    length: 9,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 2 5",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Unpin",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Bd2",
    length: 9,
    fen: "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N2N2/PPPB1PPP/R2QKB1R b KQkq - 4 5",
  },
  {
    eco: "B34",
    name: "Sicilian Defense: Accelerated Dragon, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nxc6",
    length: 9,
    fen: "r1bqkbnr/pp1ppp1p/2N3p1/8/4P3/8/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "B34",
    name: "Sicilian Defense: Accelerated Dragon, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3",
    length: 9,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "B36",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4",
    length: 9,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation, Maróczy Bind, Réti Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4",
    length: 9,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3",
    length: 9,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "B43",
    name: "Sicilian Defense: Kan Variation, Knight Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3",
    length: 9,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "B44",
    name: "Sicilian Defense: Taimanov Variation, Szén Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5",
    length: 9,
    fen: "r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "B45",
    name: "Sicilian Defense: Taimanov Variation, Normal Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3",
    length: 9,
    fen: "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq - 2 5",
  },
  {
    eco: "B52",
    name: "Sicilian Defense: Moscow Variation, Sokolsky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7 4. Bxd7+ Qxd7 5. c4",
    length: 9,
    fen: "rn2kbnr/pp1qpppp/3p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Modern Variations, Ginsberg Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Bc4",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/3p1n2/8/2BNP3/8/PPP2PPP/RNBQK2R b KQkq - 2 5",
  },
  {
    eco: "B54",
    name: "Sicilian Defense: Prins Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C00",
    name: "French Defense: Reversed Philidor Formation",
    pgn: "1. e4 e6 2. d3 d5 3. Nd2 Nf6 4. Ngf3 Nc6 5. Be2",
    length: 9,
    fen: "r1bqkb1r/ppp2ppp/2n1pn2/3p4/4P3/3P1N2/PPPNBPPP/R1BQK2R b KQkq - 5 5",
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Svenonius Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5 exd5 5. Bg5",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PPP2PPP/R2QKBNR b KQkq - 1 5",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Nimzowitsch Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4 cxd4 5. Nf3",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/4p3/3pP3/3p2Q1/5N2/PPP2PPP/RNB1KB1R b KQkq - 1 5",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Paulsen Attack",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3",
    length: 9,
    fen: "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Ruisdonk Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 cxd4 5. Bd3",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/4p3/3pP3/3p4/3B1N2/PPP2PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Pawn Center Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. f4",
    length: 9,
    fen: "rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation, Gledhill Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. Qg4",
    length: 9,
    fen: "rnbqkb1r/pppn1ppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R1B1KBNR b KQkq - 2 5",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Exchange Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. exd5",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/4pn2/3P2B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Richter Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6",
    length: 9,
    fen: "rnbqk2r/ppp1bppp/4pB2/3p4/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "C15",
    name: "French Defense: MacCutcheon Variation, Wolf Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 Nf6 5. Bg5",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP1NPPP/R2QKB1R b KQkq - 5 5",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation, Schwarz's Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 Ne7 5. Nb1",
    length: 9,
    fen: "rnbqk2r/ppp1nppp/4p3/3p4/1b1PP3/8/PPPB1PPP/RN1QKBNR b KQkq - 5 5",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3",
    length: 9,
    fen: "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Advance Variation, Moscow Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Qg4",
    length: 9,
    fen: "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P2Q1/2N5/PPP2PPP/R1B1KBNR b KQkq - 1 5",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Bogoljubov Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2",
    length: 9,
    fen: "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR b KQkq - 1 5",
  },
  {
    eco: "C21",
    name: "Center Game: Halasz-McDonnell Gambit, Crocodile Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. f4 Bc5 4. Nf3 Nc6 5. c3",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b5/3pPP2/2P2N2/PP4PP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C21",
    name: "Center Game: Lanc-Arnold Gambit, Schippler Gambit",
    pgn: "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3 dxc3 5. Bc4",
    length: 9,
    fen: "rnbqk1nr/pppp1ppp/8/2b5/2B1P3/2p2N2/PP3PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2",
    length: 9,
    fen: "rnbqkbnr/pppp1ppp/8/8/2B1P3/8/PB3PPP/RN1QK1NR b KQkq - 0 5",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lewis Countergambit, Walker Variation",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6 5. d4",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/5n2/2bBp3/3PP3/2P5/PP3PPP/RNBQK1NR b KQkq - 0 5",
  },
  {
    eco: "C23",
    name: "Bishop's Opening: Lopez Gambit",
    pgn: "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nc6 4. c3 Nf6 5. f4",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1PP2/2P5/PP1PQ1PP/RNB1K1NR b KQkq - 0 5",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Krejcik Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. f3 Bc5 4. Ne2 Nc6 5. b4",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/1PB1P3/5P2/P1PPN1PP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Pierce Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. d4",
    length: 9,
    fen: "r1bqkbnr/pppp1p1p/2n5/6p1/3PPp2/2N2N2/PPP3PP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Main Line",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR b kq - 2 5",
  },
  {
    eco: "C25",
    name: "Vienna Game: Stanley Variation, Meitner-Mieses Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Bc5 4. Qg4 Qf6 5. Nd5",
    length: 9,
    fen: "r1b1k1nr/pppp1ppp/2n2q2/2bNp3/2B1P1Q1/8/PPPP1PPP/R1B1K1NR b KQkq - 7 5",
  },
  {
    eco: "C28",
    name: "Bishop's Opening: Vienna Hybrid, Hromádka Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. d3 Bb4 5. Ne2",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP1NPPP/R1BQK2R b KQkq - 2 5",
  },
  {
    eco: "C28",
    name: "Vienna Game: Stanley Variation, Bronstein Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. f4 Nxe4 5. Nf3",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n5/4p3/2B1nP2/2N2N2/PPPP2PP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Modern Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. d3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/8/3pP3/4n3/2NP4/PPP3PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Paulsen Attack",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2Q2/PPPP2PP/R1B1KBNR b KQkq - 1 5",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Norwalde Variation, Bücker Gambit",
    pgn: "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. Nf3 Bb4 5. Bc4",
    length: 9,
    fen: "rnb1k1nr/pppp1ppp/8/4p3/1bB1Pq2/2N2N2/PPPP2PP/R1BQK2R b KQkq - 3 5",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Rubinstein Variation",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. Nc3 Nf6 5. Qe2",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/2N5/PPPPQ1PP/R1B1KBNR b KQkq - 3 5",
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit Accepted",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3P4/4PP2/8/PPP3PP/RNBQKBNR b KQkq - 0 5",
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Keres Variation",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Nd2",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/3P4/PPPN2PP/R1BQKBNR b KQkq - 2 5",
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Old Line",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Qe2",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/3P4/PPP1Q1PP/RNB1KBNR b KQkq - 2 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Classical Defense, Cozio Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Qf3",
    length: 9,
    fen: "rnb1kbnr/pppp1p1p/8/6p1/2B1Pp1q/5Q2/PPPP2PP/RNB2KNR b kq - 1 5",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Paulsen Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 Bb4 5. e5",
    length: 9,
    fen: "rnbqk2r/pppp1ppp/5n2/4P3/1bB2p2/2N5/PPPP2PP/R1BQK1NR b KQkq - 0 5",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Fischer Defense, Spanish Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 Nf6 5. Bd3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/3PPp2/3B1N2/PPP3PP/RNBQK2R b KQkq - 2 5",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Schallopp Defense, Tashkent Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Nf6 4. e5 Nh5 5. g4",
    length: 9,
    fen: "rnbqkb1r/pppp1ppp/8/4P2n/5pP1/5N2/PPPP3P/RNBQKB1R b KQkq g3 0 5",
  },
  {
    eco: "C35",
    name: "King's Gambit Accepted: Cunningham Defense, Bertin Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. g3",
    length: 9,
    fen: "rnbqk1nr/pppp1ppp/8/8/2B1Pp1b/5NP1/PPPP3P/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Australian Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. h4",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/2B1PppP/5N2/PPPP2P1/RNBQK2R b KQkq h3 0 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Ghulam-Kassim Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/2BPPpp1/5N2/PPP3PP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Lolli Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Bxf7+",
    length: 9,
    fen: "rnbqkbnr/pppp1B1p/8/8/4Ppp1/5N2/PPPP2PP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: McDonnell Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Nc3",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/2B1Ppp1/2N2N2/PPPP2PP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Wild Muzio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Sörensen Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Nc3",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/3PPpp1/2N2N2/PPP3PP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Testa Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Bxf4",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/8/3PPBp1/5N2/PPP3PP/RN1QKB1R b KQkq - 0 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/4N3/2B1Ppp1/8/PPPP2PP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Sörensen Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/4N3/3PPpp1/8/PPP3PP/RNBQKB1R b KQkq - 1 5",
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Hanstein Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. O-O",
    length: 9,
    fen: "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQ1RK1 b kq - 3 5",
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Philidor Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. h4",
    length: 9,
    fen: "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp1P/5N2/PPPP2P1/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Allgaier Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/6N1/4PppP/8/PPPP2P1/RNBQKB1R b KQkq - 1 5",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5",
    length: 9,
    fen: "rnbqkbnr/pppp1p1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R b KQkq - 1 5",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Bilguer Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4",
    length: 9,
    fen: "rnb1kbnr/ppp3pp/3p1q2/5p2/2NPP3/8/PPP2PPP/RNBQKB1R b KQkq - 1 5",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Foltys Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. d3",
    length: 9,
    fen: "rnb1kbnr/pppp2pp/5q2/8/2N1p3/3P4/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Leonhardt Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. Nc3",
    length: 9,
    fen: "rnb1kbnr/pppp2pp/5q2/8/2N1p3/2N5/PPPP1PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Delmar Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. c3",
    length: 9,
    fen: "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Krause Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O",
    length: 9,
    fen: "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Schlechter Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Nc3",
    length: 9,
    fen: "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham, Kmoch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Ng5",
    length: 9,
    fen: "r1bqkbnr/pp1n1ppp/2pp4/4p1N1/2BPP3/8/PPP2PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Shirov Gambit",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Nc3 Ngf6 5. g4",
    length: 9,
    fen: "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP1P1/2N2N2/PPP2P1P/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Rellstab Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Qd5",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/3QP3/4n3/5N2/PPP2PPP/RNB1KB1R b KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Nimzowitsch Variation, Sokolsky Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Nbd2",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/4P3/4n3/5N2/PPPN1PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Paulsen Attack",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 d5 5. exd5",
    length: 9,
    fen: "rnbqkbnr/ppp2ppp/8/3P4/3N4/8/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit, Bishop Check Line",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. Bc4+",
    length: 9,
    fen: "rnbq1b1r/ppp2kpp/3p1n2/8/2B1P3/8/PPPP1PPP/RNBQK2R b KQ - 1 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Cochrane Gambit, Center Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. d4",
    length: 9,
    fen: "rnbq1b1r/ppp2kpp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKB1R b KQ - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Cozio Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Qe2",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R b KQkq - 1 5",
  },
  {
    eco: "C42",
    name: "Russian Game: French Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/4n3/3P1N2/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Kaufmann Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. c4",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/2P1n3/5N2/PP1P1PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Millennium Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Bd3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/4n3/3B1N2/PPPP1PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C42",
    name: "Russian Game: Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/3p4/8/4n3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C43",
    name: "Bishop's Opening: Urusov Gambit, Keidansky Gambit",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 Nxe4 5. Qxd4",
    length: 9,
    fen: "rnbqkb1r/pppp1ppp/8/8/2BQn3/5N2/PPP2PPP/RNB1K2R b KQkq - 0 5",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qxd4",
    length: 9,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/3Qn3/5N2/PPP2PPP/RNB1KB1R b KQkq - 0 5",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2",
    length: 9,
    fen: "rnbqkb1r/pppp1ppp/8/4P3/3pn3/5N2/PPP1QPPP/RNB1KB1R b KQkq - 2 5",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Tal Gambit",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Bb5",
    length: 9,
    fen: "rnbqkb1r/pppp1ppp/8/1B2P3/3pn3/5N2/PPP2PPP/RNBQK2R b KQkq - 2 5",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening, Basman Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4 exd4 5. e5",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4P3/3p4/5N2/PPP1BPPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "King's Pawn Game: Tayler Opening, Inverted Hanham",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d3 d5 5. Nbd2",
    length: 9,
    fen: "r1bqkb1r/ppp2ppp/2n2n2/3pp3/4P3/3P1N2/PPPNBPPP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Ponziani Countergambit, Schmidt Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 f5 4. d4 d6 5. d5",
    length: 9,
    fen: "r1bqkbnr/ppp3pp/2np4/3Ppp2/4P3/2P2N2/PP3PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Double Pawn Sacrifice",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4",
    length: 9,
    fen: "r1bqkbnr/pppp1ppp/2n5/8/2B1P3/2p2N2/PP3PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Napoleon Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Bc4",
    length: 9,
    fen: "r1bqkbnr/pppp1ppp/8/8/2BpP3/8/PPP2PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Advance Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. e5",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4P3/2Bp4/5N2/PPP2PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Sarratt Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b3N1/2BpP3/8/PPP2PPP/RNBQK2R b KQkq - 3 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Alekhine Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. e5",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/4P3/3N4/8/PPP2PPP/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Braune Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Be3",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/4B3/PPP2PPP/RN1QKB1R b KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Fraser Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf3",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/4P2q/5N2/PPP2PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Horwitz Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/1N6/4P2q/8/PPP2PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf5",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/5N2/4P2q/8/PPP2PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Potter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b5/4P3/1N6/PPP2PPP/RNBQKB1R b KQkq - 2 5",
  },
  {
    eco: "C45",
    name: "Scotch Game: Steinitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3",
    length: 9,
    fen: "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/2N5/PPP2PPP/R1BQKB1R b KQkq - 2 5",
  },
  {
    eco: "C46",
    name: "Three Knights Opening: Steinitz-Rosenthal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6 4. d4 exd4 5. Nd5",
    length: 9,
    fen: "r1bqkbnr/pppp1p1p/2n3p1/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Italian Variation, Noa Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nxe4 5. Bxf7+",
    length: 9,
    fen: "r1bqkb1r/pppp1Bpp/2n5/4p3/4n3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 0 5",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Belgrade Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Krause Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/4N3/1b1PP3/2N5/PPP2PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Ranken Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 a6 5. Bxc6",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 0 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Be2",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/5n2/4p3/3nP3/2N2N2/PPPPBPPP/R1BQK2R b KQkq - 7 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxd4",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/5n2/1B2p3/3NP3/2N5/PPPP1PPP/R1BQK2R b KQkq - 0 5",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Henneberger Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. O-O",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq - 7 5",
  },
  {
    eco: "C50",
    name: "Italian Game: Classical Variation, Albin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. c3",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq - 0 5",
  },
  {
    eco: "C50",
    name: "Italian Game: Deutz Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 5",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Italian Four Knights Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R b KQkq - 2 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/1bn5/4p3/PPB1P3/5N2/2PP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Cordel Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. Bb2",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/PBPP1PPP/RN1QK2R b KQkq - 2 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Bird's Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. b4",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/1PB1P3/2P2N2/P2P1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Giuoco Pianissimo",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Perreux Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. Ng5",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/6N1/2BpP3/8/PPP2PPP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack, Bishop Sacrifice Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+",
    length: 9,
    fen: "r1bqk2r/pppp1Bpp/2n2n2/2b1p1N1/4P3/8/PPPP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Counterattack, Knight Sacrifice Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Nxf7",
    length: 9,
    fen: "r1bqk2r/pppp1Npp/2n2n2/2b1p3/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit, Harding Countergambit, Fricke Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. O-O",
    length: 9,
    fen: "r1b1kbnr/ppp2ppp/2n5/1B1pN1q1/4P3/8/PPPP1PPP/RNBQ1RK1 b kq - 2 5",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Spanish Countergambit, Harding Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. Nxc6",
    length: 9,
    fen: "r1b1kbnr/ppp2ppp/2N5/1B1p2q1/4P3/8/PPPP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Old Steinitz Defense, Semi-Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. c4",
    length: 9,
    fen: "r2qkbnr/pppb1ppp/2np4/1B2p3/2PPP3/5N2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense, Center Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 exd4 5. O-O",
    length: 9,
    fen: "r1bqkbnr/ppp2ppp/2np4/1B6/3pP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Zaitsev Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O Nd4 5. b4",
    length: 9,
    fen: "r1bqk1nr/pppp1ppp/8/1Bb1p3/1P1nP3/5N2/P1PP1PPP/RNBQ1RK1 b kq - 0 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. Bxc6+",
    length: 9,
    fen: "r1bqkb1r/ppp2ppp/2Bp1n2/4p3/4P3/3P1N2/PPP2PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. c4",
    length: 9,
    fen: "r1bqkb1r/ppp2ppp/2np1n2/1B2p3/2P1P3/3P1N2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Kaufmann Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. Be3",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/3PBN2/PPP2PPP/RN1QK2R b KQkq - 2 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Nyholm Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d4 exd4 5. O-O",
    length: 9,
    fen: "r1bqkb1r/pppp1ppp/2n2n2/1B6/3pP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Classical Variation, Zukertort Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3",
    length: 9,
    fen: "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq - 0 5",
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Keres Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Normal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred, Jaenisch Gambit Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5 5. exf5",
    length: 9,
    fen: "r1bqkbnr/1ppp2pp/p1n5/4pP2/B7/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Nc3",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 1 5",
  },
  {
    eco: "C71",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c4",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1np4/4p3/B1P1P3/5N2/PP1P1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C72",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. O-O",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 1 5",
  },
  {
    eco: "C74",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3",
    length: 9,
    fen: "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Bayreuth Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Bxc6",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Jaffe Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. c3",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Mackenzie Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d4",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Tarrasch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Nc3",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq - 3 5",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Wormald Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Qe2",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R b KQkq - 3 5",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O",
    length: 9,
    fen: "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 3 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ryder Gambit",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Qxf3",
    length: 9,
    fen: "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N2Q2/PPP3PP/R1B1KBNR b KQkq - 0 5",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit: Zeller Defense, Soller Attack",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5 4. f3 Nf6 5. Bc4",
    length: 9,
    fen: "rn1qkb1r/ppp1pppp/5n2/5b2/2BPp3/2N2P2/PPP3PP/R1BQK1NR b KQkq - 2 5",
  },
  {
    eco: "D02",
    name: "London System: Poisoned Pawn Variation",
    pgn: "1. d4 Nf6 2. Nf3 d5 3. Bf4 c5 4. e3 Qb6 5. Nc3",
    length: 9,
    fen: "rnb1kb1r/pp2pppp/1q3n2/2pp4/3P1B2/2N1PN2/PPP2PPP/R2QKB1R b KQkq - 2 5",
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Colle System, Traditional Colle",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. c3",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "D05",
    name: "Queen's Pawn Game: Zukertort Variation",
    pgn: "1. d4 d5 2. Nf3 c5 3. e3 Nf6 4. Nbd2 e6 5. b3",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P2PN2/P1PN1PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D05",
    name: "Rubinstein Opening",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. b3",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P1BPN2/P1P2PPP/RNBQK2R b KQkq - 0 5",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Modern Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2",
    length: 9,
    fen: "r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R b KQkq - 3 5",
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3",
    length: 9,
    fen: "r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "D10",
    name: "Slav Defense: Exchange Variation, Boor Attack",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. f3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2P2/PP2P1PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Alekhine Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e3",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Chebanenko Variation, Advance System",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6 5. c5",
    length: 9,
    fen: "rnbqkb1r/1p2pppp/p1p2n2/2Pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D15",
    name: "Slav Defense: Geller Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D16",
    name: "Slav Defense: Alapin Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4",
    length: 9,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D24",
    name: "Queen's Gambit Accepted: Bogoljubov Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3 a6 5. e4",
    length: 9,
    fen: "rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch Bishop Attack",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 c5 4. cxd5 exd5 5. Bg5",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/8/2pp2B1/3P4/5N2/PP2PPPP/RN1QKB1R b KQkq - 1 5",
  },
  {
    eco: "D30",
    name: "Semi-Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "D31",
    name: "Queen's Gambit Declined: Charousek Variation, Miladinovic Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. e4 dxe4 5. f3",
    length: 9,
    fen: "rnbqk1nr/ppp1bppp/4p3/8/2PPp3/2N2P2/PP4PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Anti-Noteboom, Stonewall Variation, Portisch Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e3 f5 5. g4",
    length: 9,
    fen: "rnbqkbnr/pp4pp/2p1p3/3p1p2/2PP2P1/2N1P3/PP3P1P/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Gunderam Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. f3",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/2p1p3/8/2PPp3/2N2P2/PP4PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. g3",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/2p1p3/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R b KQkq - 1 5",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Marshall Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. e4",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/8/2pp4/3PP3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Two Knights Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3",
    length: 9,
    fen: "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Positional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR b KQkq - 1 5",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4",
    length: 9,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq - 3 5",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Vienna Variation, Quiet Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4 5. e3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Ragozin Defense, Alekhine Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Qa4+",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p4/QbPP4/2N2N2/PP2PPPP/R1B1KB1R b KQkq - 3 5",
  },
  {
    eco: "D40",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. Bg5",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq - 1 5",
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Main Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5",
    length: 9,
    fen: "rnbqkb1r/pp3ppp/4pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3",
    length: 9,
    fen: "r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined: Modern Variation, Heral Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6",
    length: 9,
    fen: "rnbqk2r/ppp1bppp/4pB2/3p4/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq - 0 5",
  },
  {
    eco: "D85",
    name: "Grünfeld Defense: Exchange Variation, Nadanian Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. Na4",
    length: 9,
    fen: "rnbqkb1r/ppp1pp1p/6p1/3n4/N2P4/8/PP2PPPP/R1BQKBNR b KQkq - 1 5",
  },
  {
    eco: "D90",
    name: "Grünfeld Defense: Flohr Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qa4+",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/Q1PP4/2N2N2/PP2PPPP/R1B1KB1R b KQkq - 3 5",
  },
  {
    eco: "D91",
    name: "Grünfeld Defense: Three Knights Variation, Petrosian System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bg5",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq - 3 5",
  },
  {
    eco: "D92",
    name: "Grünfeld Defense: Three Knights Variation, Hungarian Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq - 3 5",
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Three Knights Variation, Burille Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "D96",
    name: "Grünfeld Defense: Russian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R b KQkq - 3 5",
  },
  {
    eco: "E04",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3",
    length: 9,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R b KQkq - 1 5",
  },
  {
    eco: "E06",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3",
    length: 9,
    fen: "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq - 3 5",
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit: Dus-Khotimirsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. Bg5",
    length: 9,
    fen: "rnbqkb1r/p2p1ppp/4pn2/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R b KQkq - 1 5",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3",
    length: 9,
    fen: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R b KQkq - 2 5",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qa4",
    length: 9,
    fen: "rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R b KQkq - 2 5",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Quiet Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3",
    length: 9,
    fen: "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R b KQkq - 0 5",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Timman's Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qb3",
    length: 9,
    fen: "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1Q3NP1/PP2PP1P/RNB1KB1R b KQkq - 2 5",
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Korchnoi Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5",
    length: 9,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2pP4/1bP5/2N2N2/PP2PPPP/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "E24",
    name: "Nimzo-Indian Defense: Sämisch Variation, Accelerated",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3",
    length: 9,
    fen: "rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3",
    length: 9,
    fen: "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR b KQkq - 0 5",
  },
  {
    eco: "E42",
    name: "Nimzo-Indian Defense: Hübner Variation, Rubinstein Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Ne2",
    length: 9,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "E44",
    name: "Nimzo-Indian Defense: Fischer Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2",
    length: 9,
    fen: "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Reshevsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2",
    length: 9,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQ - 2 5",
  },
  {
    eco: "E47",
    name: "Nimzo-Indian Defense: Normal Variation, Bishop Attack",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3",
    length: 9,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR b KQ - 2 5",
  },
  {
    eco: "E50",
    name: "Nimzo-Indian Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3",
    length: 9,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R b KQ - 2 5",
  },
  {
    eco: "E61",
    name: "King's Indian Defense: Smyslov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. Bg5",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq - 1 5",
  },
  {
    eco: "E62",
    name: "King's Indian Defense: Fianchetto Variation, Delayed Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. g3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq - 0 5",
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Accelerated Averbakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Bg5",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP3PPP/R2QKBNR b KQkq - 1 5",
  },
  {
    eco: "E70",
    name: "King's Indian Defense: Kramer Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nge2",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2NPPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "E71",
    name: "King's Indian Defense: Makogonov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. h3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "E72",
    name: "King's Indian Defense: Normal Variation, Deferred Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. g3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N3P1/PP3P1P/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Normal Variation, Standard Development",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR b KQkq - 1 5",
  },
  {
    eco: "E76",
    name: "King's Indian Defense: Four Pawns Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "E80",
    name: "King's Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR b KQkq - 0 5",
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Normal Variation, Rare Defenses",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3",
    length: 9,
    fen: "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq - 1 5",
  },
  {
    eco: "A00",
    name: "Grob Opening: Grob Gambit, Fritz Gambit, Romford Countergambit",
    pgn: "1. g4 d5 2. Bg2 Bxg4 3. c4 d4 4. Bxb7 Nd7 5. Bxa8 Qxa8",
    length: 10,
    fen: "q3kbnr/p1pnpppp/8/8/2Pp2b1/8/PP1PPP1P/RNBQK1NR w KQk - 0 6",
  },
  {
    eco: "A00",
    name: "Hungarian Opening: Asten Gambit",
    pgn: "1. g3 Nc6 2. Nc3 d5 3. d4 e5 4. dxe5 d4 5. Ne4 f5",
    length: 10,
    fen: "r1bqkbnr/ppp3pp/2n5/4Pp2/3pN3/6P1/PPP1PP1P/R1BQKBNR w KQkq f6 0 6",
  },
  {
    eco: "A04",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Accepted",
    pgn: "1. Nf3 g6 2. d4 Bg7 3. e4 d6 4. c4 c5 5. dxc5 Qa5+",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1P5/2P1P3/5N2/PP3PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "A05",
    name: "King's Indian Attack: Wahls Defense",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. O-O O-O 5. d3 d5",
    length: 10,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/8/3P1NP1/PPP1PPBP/RNBQ1RK1 w - - 0 6",
  },
  {
    eco: "A07",
    name: "King's Indian Attack: Pachman System",
    pgn: "1. Nf3 d5 2. g3 g6 3. Bg2 Bg7 4. O-O e5 5. d3 Ne7",
    length: 10,
    fen: "rnbqk2r/ppp1npbp/6p1/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1 w kq - 1 6",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nb6",
    length: 10,
    fen: "rnbqkb1r/ppp1pp1p/1n4p1/8/8/2N3P1/PP1PPPBP/R1BQK1NR w KQkq - 2 6",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation",
    pgn: "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nxc3",
    length: 10,
    fen: "rnbqkb1r/ppp1pp1p/6p1/8/8/2n3P1/PP1PPPBP/R1BQK1NR w KQkq - 0 6",
  },
  {
    eco: "A25",
    name: "English Opening: Closed, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Rb1 Nh6 5. Bg2 Bg7",
    length: 10,
    fen: "r1bqk2r/pppp1pbp/2n3pn/4p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR w Kkq - 4 6",
  },
  {
    eco: "A26",
    name: "English Opening: King's English Variation, Closed System, Full Symmetry",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6",
    length: 10,
    fen: "r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR w KQkq - 0 6",
  },
  {
    eco: "A28",
    name: "English Opening: King's English Variation, Four Knights Variation, Quiet Line",
    pgn: "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3 Bb4 5. Qc2 Bxc3",
    length: 10,
    fen: "r1bqk2r/pppp1ppp/2n2n2/4p3/2P5/2b1PN2/PPQP1PPP/R1B1KB1R w KQkq - 0 6",
  },
  {
    eco: "A33",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6",
    length: 10,
    fen: "r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "A34",
    name: "English Opening: Symmetrical Variation, Rubinstein Variation",
    pgn: "1. c4 Nf6 2. Nc3 c5 3. g3 d5 4. cxd5 Nxd5 5. Bg2 Nc7",
    length: 10,
    fen: "rnbqkb1r/ppn1pppp/8/2p5/8/2N3P1/PP1PPPBP/R1BQK1NR w KQkq - 2 6",
  },
  {
    eco: "A36",
    name: "English Opening: Symmetrical Variation, Botvinnik System Reversed, with e3",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 e5",
    length: 10,
    fen: "r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR w KQkq - 0 6",
  },
  {
    eco: "A37",
    name: "English Opening: Symmetrical Variation, Botvinnik System Reversed, with Nf3",
    pgn: "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3 e5",
    length: 10,
    fen: "r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R w KQkq - 0 6",
  },
  {
    eco: "A38",
    name: "English Opening: Symmetrical Variation, Full Symmetry Line",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. g3 g6 5. Bg2 Bg7",
    length: 10,
    fen: "r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R w KQkq - 2 6",
  },
  {
    eco: "A40",
    name: "Colle System: Siroccopteryx Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 cxd4 5. Nxd4 Qa5+",
    length: 10,
    fen: "rnb1k1nr/pp1pppbp/6p1/q7/3N4/3BP3/PPP2PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "A40",
    name: "English Defense: Hartlaub Gambit Accepted",
    pgn: "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. exf5 Nf6",
    length: 10,
    fen: "rn1qkb1r/pbpp2pp/1p2pn2/5P2/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "A40",
    name: "English Defense: Poli Gambit",
    pgn: "1. d4 e6 2. c4 b6 3. e4 Bb7 4. f3 f5 5. exf5 Nh6",
    length: 10,
    fen: "rn1qkb1r/pbpp2pp/1p2p2n/5P2/2PP4/5P2/PP4PP/RNBQKBNR w KQkq - 1 6",
  },
  {
    eco: "A40",
    name: "Modern Defense: Beefeater Variation",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 f5",
    length: 10,
    fen: "rnbqk1nr/pp1pp2p/6p1/2pP1p2/2P5/2P5/P3PPPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Fianchetto, Queen Pteranodon",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp1ppp1p/6p1/q1pP4/2P5/2P5/P3PPPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "A42",
    name: "Pterodactyl Defense",
    pgn: "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+ 5. Nc3 d6",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "A42",
    name: "Pterodactyl Defense: Central, Bogolubovia",
    pgn: "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Nc3 c5 5. Nge2 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N5/PP2NPPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "A46",
    name: "Queen's Pawn Game: Torre Attack, Breyer Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. e3 c5 5. c3 Qb6",
    length: 10,
    fen: "rnb1kb1r/pp3ppp/1q2pn2/2pp2B1/3P4/2P1PN2/PP3PPP/RN1QKB1R w KQkq - 1 6",
  },
  {
    eco: "A48",
    name: "Queen's Pawn Game: Torre Attack, Grünfeld Variation, Main Line",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 d5 5. e3 O-O",
    length: 10,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R w KQ - 1 6",
  },
  {
    eco: "A52",
    name: "Indian Defense: Budapest Defense, Alekhine Variation, Abonyi Variation",
    pgn: "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 Nxe5 5. f4 Nec6",
    length: 10,
    fen: "rnbqkb1r/pppp1ppp/2n5/8/2P1PP2/8/PP4PP/RNBQKBNR w KQkq - 1 6",
  },
  {
    eco: "A56",
    name: "Benoni Defense: King's Indian System",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e5 4. Nc3 d6 5. e4 g6",
    length: 10,
    fen: "rnbqkb1r/pp3p1p/3p1np1/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "A60",
    name: "Benoni Defense: Modern Variation, Snake Variation",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 Bd6",
    length: 10,
    fen: "rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "A81",
    name: "Dutch Defense: Leningrad, Basman System",
    pgn: "1. d4 f5 2. g3 c6 3. Bg2 g6 4. Nf3 Bg7 5. O-O Nh6",
    length: 10,
    fen: "rnbqk2r/pp1pp1bp/2p3pn/5p2/3P4/5NP1/PPP1PPBP/RNBQ1RK1 w kq - 4 6",
  },
  {
    eco: "A86",
    name: "Dutch Defense: Hort-Antoshin System",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 d6 4. Bg2 c6 5. Nc3 Qc7",
    length: 10,
    fen: "rnb1kb1r/ppq1p1pp/2pp1n2/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR w KQkq - 2 6",
  },
  {
    eco: "A90",
    name: "Dutch Defense: Nimzo-Dutch Variation, Alekhine Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Bb4+ 5. Bd2 Be7",
    length: 10,
    fen: "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP1BPPBP/RN1QK1NR w KQkq - 4 6",
  },
  {
    eco: "A92",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O",
    length: 10,
    fen: "rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R w KQ - 4 6",
  },
  {
    eco: "B00",
    name: "Owen Defense: Matovinsky Gambit",
    pgn: "1. e4 b6 2. d4 Bb7 3. Bd3 f5 4. exf5 Bxg2 5. Qh5+ g6",
    length: 10,
    fen: "rn1qkbnr/p1ppp2p/1p4p1/5P1Q/3P4/3B4/PPP2PbP/RNB1K1NR w KQkq - 0 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Anderssen Counterattack, Collijn Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3 Bg4",
    length: 10,
    fen: "rn2kbnr/ppp2ppp/8/q3p3/3P2b1/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Bronstein Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nf6 5. Nf3 a6",
    length: 10,
    fen: "rnb1kb1r/1pp1pppp/p2q1n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Classical Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bf5",
    length: 10,
    fen: "rn2kb1r/ppp1pppp/5n2/q4b2/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation, Ilundain Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 c6",
    length: 10,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Valencian Variation, Main Line",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd8 4. d4 Nf6 5. Nf3 Bg4",
    length: 10,
    fen: "rn1qkb1r/ppp1pppp/5n2/8/3P2b1/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Cambridge Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g5",
    length: 10,
    fen: "rnbqkb1r/ppp1pp1p/1n1p4/4P1p1/2PP1P2/8/PP4PP/RNBQKBNR w KQkq - 0 6",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Fianchetto Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g6",
    length: 10,
    fen: "rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/2PP1P2/8/PP4PP/RNBQKBNR w KQkq - 0 6",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Four Pawns Attack, Trifunovic Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 Bf5",
    length: 10,
    fen: "rn1qkb1r/ppp1pppp/1n1p4/4Pb2/2PP1P2/8/PP4PP/RNBQKBNR w KQkq - 1 6",
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Flohr Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. Be2 c6",
    length: 10,
    fen: "rn1qkb1r/pp2pppp/2pp4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "B06",
    name: "Modern Defense: Dunworthy Variation",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 d5 4. exd5 c6 5. dxc6 Bxd4",
    length: 10,
    fen: "rnbqk1nr/pp2pp1p/2P3p1/8/2Pb4/8/PP3PPP/RNBQKBNR w KQkq - 0 6",
  },
  {
    eco: "B06",
    name: "Modern Defense: Gurgenidze Defense",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. f4 d5 5. e5 h5",
    length: 10,
    fen: "rnbqk1nr/pp2ppb1/2p3p1/3pP2p/3P1P2/2N5/PPP3PP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "B06",
    name: "Modern Defense: Masur Gambit",
    pgn: "1. e4 g6 2. d4 Nh6 3. Nc3 f5 4. Bxh6 Bxh6 5. exf5 O-O",
    length: 10,
    fen: "rnbq1rk1/ppppp2p/6pb/5P2/3P4/2N5/PPP2PPP/R2QKBNR w KQ - 1 6",
  },
  {
    eco: "B06",
    name: "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Declined",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nf3 d6 5. Be2 Qa5+",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/5N2/PP2BPPP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Anhanguera",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. Be3 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N1B3/PP3PPP/R2QKBNR w KQkq - 2 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Benoni Quetzalcoatlus",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. d5 d6 5. Nc3 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1pP4/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq - 2 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Central, Quetzalcoatlus Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. dxc5 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1P5/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Benoni Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp1ppp1p/6p1/q1pP4/4P3/2P5/P1P2PPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Eastern, Pteranodon",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Bxc3+ 5. bxc3 Qa5",
    length: 10,
    fen: "rnb1k1nr/pp1ppp1p/6p1/q1P5/4P3/2P5/P1P2PPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Sicilian, Quetzalcoatlus",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Be2 d6",
    length: 10,
    fen: "rnb1k1nr/pp2ppbp/3p2p1/q1p5/3PP3/2N2N2/PPP1BPPP/R1BQK2R w KQkq - 0 6",
  },
  {
    eco: "B06",
    name: "Pterodactyl Defense: Western, Siroccopteryx",
    pgn: "1. e4 g6 2. Nf3 Bg7 3. d4 c5 4. Bc4 cxd4 5. Nxd4 Qa5+",
    length: 10,
    fen: "rnb1k1nr/pp1pppbp/6p1/q7/2BNP3/8/PPP2PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "B07",
    name: "Pirc Defense: 150 Attack, Inner Doll Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2 Bg4",
    length: 10,
    fen: "rn1qkb1r/pp2pp1p/2pp1np1/8/3PP1b1/2N1B3/PPPQ1PPP/R3KBNR w KQkq - 2 6",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O",
    length: 10,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R w KQ - 3 6",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Dragon Formation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 c5",
    length: 10,
    fen: "rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6",
    length: 10,
    fen: "r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 3 6",
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Fianchetto Defense",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6",
    length: 10,
    fen: "rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Tartakower Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "B16",
    name: "Caro-Kann Defense: Bronstein-Larsen Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ gxf6",
    length: 10,
    fen: "rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation, Ivanchuk Defense",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5 Ndf6",
    length: 10,
    fen: "r1bqkbnr/pp2pppp/2p2n2/6N1/3P4/8/PPP2PPP/R1BQKBNR w KQkq - 3 6",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Fianchetto Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 g6",
    length: 10,
    fen: "r1bqkbnr/pp1ppp1p/2n3p1/8/4P3/2N2N2/PP3PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Kan Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Nf3 a6",
    length: 10,
    fen: "rnbqkbnr/1p1p1ppp/p3p3/8/4P3/2N2N2/PP3PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense, Modern Line",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 Nf6 5. Nf3 Bg4",
    length: 10,
    fen: "rn2kb1r/pp2pppp/5n2/2pq4/3P2b1/2P2N2/PP3PPP/RNBQKB1R w KQkq - 3 6",
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6",
    length: 10,
    fen: "r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR w KQkq - 0 6",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: Kalashnikov Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6",
    length: 10,
    fen: "r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "B32",
    name: "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Geller Line",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 a6 5. c4 e5",
    length: 10,
    fen: "r1bqkbnr/1p1p1ppp/p1n5/4p3/2PNP3/8/PP3PPP/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5",
    length: 10,
    fen: "r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B37",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7",
    length: 10,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Alapin Variation, Sherzer Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. c3 Nf6 4. e5 Nd5 5. d4 Nc6",
    length: 10,
    fen: "r1bqkb1r/pp1p1ppp/2n1p3/2pnP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Four Knights Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6",
    length: 10,
    fen: "r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Gaw-Paw Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Qb6",
    length: 10,
    fen: "rnb1kb1r/pp1p1ppp/1q2pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4",
    length: 10,
    fen: "rnbqk2r/pp1p1ppp/4pn2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B41",
    name: "Sicilian Defense: Kan Variation, Maróczy Bind, Hedgehog Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 g6",
    length: 10,
    fen: "rnbqkbnr/1p1p1p1p/p3p1p1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Polugaevsky Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 Bc5",
    length: 10,
    fen: "rnbqk1nr/1p1p1ppp/p3p3/2b5/3NP3/3B4/PPP2PPP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "B42",
    name: "Sicilian Defense: Kan Variation, Swiss Cheese Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 g6",
    length: 10,
    fen: "rnbqkbnr/1p1p1p1p/p3p1p1/8/3NP3/3B4/PPP2PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "B43",
    name: "Sicilian Defense: Kan Variation, Wing Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 b5",
    length: 10,
    fen: "rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B46",
    name: "Sicilian Defense: Taimanov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6",
    length: 10,
    fen: "r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B47",
    name: "Sicilian Defense: Taimanov Variation, Bastrikov Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7",
    length: 10,
    fen: "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B53",
    name: "Sicilian Defense: Chekhover Variation, Zaitsev Defense",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Bb5 Qd7",
    length: 10,
    fen: "r1b1kbnr/pp1qpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R w KQkq - 3 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6",
    length: 10,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Kupreichik Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bd7",
    length: 10,
    fen: "rn1qkb1r/pp1bpppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6",
    length: 10,
    fen: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6",
    length: 10,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "C01",
    name: "French Defense: Exchange Variation, Bogoljubov Variation",
    pgn: "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nc3 Nf6 5. Bg5 Nc6",
    length: 10,
    fen: "r1bqkb1r/ppp2ppp/2n2n2/3p2B1/3P4/2N5/PPP2PPP/R2QKBNR w KQkq - 4 6",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Euwe Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Bd7",
    length: 10,
    fen: "r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq - 3 6",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Wade Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 Bd7",
    length: 10,
    fen: "rn2kbnr/pp1b1ppp/1q2p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq - 3 6",
  },
  {
    eco: "C03",
    name: "French Defense: Guimard Variation, Thunderbunny Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. c3 dxe4 5. Nxe4 e5",
    length: 10,
    fen: "r1bqkbnr/ppp2ppp/2n5/4p3/3PN3/2P5/PP3PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "C08",
    name: "French Defense: Tarrasch Variation, Open System, Advance Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 c4",
    length: 10,
    fen: "rnbqkbnr/pp3ppp/8/3p4/2pP4/5N2/PPPN1PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "C09",
    name: "French Defense: Tarrasch Variation, Open System, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 Nc6",
    length: 10,
    fen: "r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "C10",
    name: "French Defense: Rubinstein Variation, Fort Knox Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bd7 5. Nf3 Bc6",
    length: 10,
    fen: "rn1qkbnr/ppp2ppp/2b1p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Tartakower Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ne4",
    length: 10,
    fen: "rnbqk2r/ppp1bppp/4p3/3pP1B1/3Pn3/2N5/PPP2PPP/R2QKBNR w KQkq - 1 6",
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Vistaneckis Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8",
    length: 10,
    fen: "rnbqk1nr/ppp1bppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR w KQkq - 1 6",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine Gambit Accepted",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+",
    length: 10,
    fen: "rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1b5/1PP1NPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Fingerslip Variation, Kunin Double Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 dxe4 5. Qg4 Qxd4",
    length: 10,
    fen: "rnb1k1nr/ppp2ppp/4p3/8/1b1qp1Q1/2N5/PPPB1PPP/R3KBNR w KQkq - 0 6",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Retreat Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5",
    length: 10,
    fen: "rnbqk1nr/pp3ppp/4p3/b1ppP3/3P4/P1N5/1PP2PPP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Chigorin Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Qe7",
    length: 10,
    fen: "rnb1kbnr/ppppqppp/8/8/2B1P3/8/PB3PPP/RN1QK1NR w KQkq - 1 6",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Classical Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Nf6",
    length: 10,
    fen: "rnbqkb1r/pppp1ppp/5n2/8/2B1P3/8/PB3PPP/RN1QK1NR w KQkq - 1 6",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Copenhagen Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Bb4+",
    length: 10,
    fen: "rnbqk1nr/pppp1ppp/8/8/1bB1P3/8/PB3PPP/RN1QK1NR w KQkq - 1 6",
  },
  {
    eco: "C21",
    name: "Danish Gambit Accepted: Schlechter Defense",
    pgn: "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 d5",
    length: 10,
    fen: "rnbqkbnr/ppp2ppp/8/3p4/2B1P3/8/PB3PPP/RN1QK1NR w KQkq - 0 6",
  },
  {
    eco: "C22",
    name: "Center Game: Charousek Variation",
    pgn: "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Bb4+ 5. c3 Be7",
    length: 10,
    fen: "r1bqk1nr/ppppbppp/2n5/8/4P3/2P1Q3/PP3PPP/RNB1KBNR w KQkq - 1 6",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Fraser-Minckwitz Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 b6",
    length: 10,
    fen: "r1b1kbnr/p1pp1ppp/1pn5/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Paulsen Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d6",
    length: 10,
    fen: "r1b1kbnr/ppp2ppp/2np4/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Sörensen Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 g5",
    length: 10,
    fen: "r1b1kbnr/pppp1p1p/2n5/6p1/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Steinitz Gambit, Zukertort Defense",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d5",
    length: 10,
    fen: "r1b1kbnr/ppp2ppp/2n5/3p4/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3 Nf6 4. Bg2 Bc5 5. Nge2 d5",
    length: 10,
    fen: "r1bqk2r/ppp2ppp/2n2n2/2bpp3/4P3/2N3P1/PPPPNPBP/R1BQK2R w KQkq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Game: Paulsen Variation, Mariotti Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. g3 Bc5 4. Bg2 h5 5. Nf3 h4",
    length: 10,
    fen: "r1bqk1nr/pppp1pp1/2n5/2b1p3/4P2p/2N2NP1/PPPP1PBP/R1BQK2R w KQkq - 0 6",
  },
  {
    eco: "C27",
    name: "Vienna Game: Stanley Variation, Monster Declined",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Be7",
    length: 10,
    fen: "rnbqk2r/ppppbppp/3n4/4p2Q/8/1BN5/PPPP1PPP/R1B1K1NR w KQkq - 4 6",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5",
    length: 10,
    fen: "rnbqkb1r/ppp3pp/8/3pPp2/4n3/2N2Q2/PPPP2PP/R1B1KBNR w KQkq f6 0 6",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Breyer Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Be7",
    length: 10,
    fen: "rnbqk2r/ppp1bppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Boren-Svenonius Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Qh4+ 5. Kf1 Bd6",
    length: 10,
    fen: "rnb1k1nr/ppp2ppp/3b4/3B4/4Pp1q/8/PPPP2PP/RNBQ1KNR w kq - 3 6",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Brentano Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O d5",
    length: 10,
    fen: "rnbqkbnr/ppp2p1p/8/3p4/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Kling and Horwitz Counterattack",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O Qe7",
    length: 10,
    fen: "rnb1kbnr/ppppqp1p/8/8/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 w kq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Allgaier, Schlechter Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 Nf6",
    length: 10,
    fen: "rnbqkb1r/pppp1p1p/5n2/6N1/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6",
    length: 10,
    fen: "rnbqkb1r/pppp1p1p/5n2/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5",
    length: 10,
    fen: "rnbqkbnr/ppp2p1p/8/3pN3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Kolisch Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d6",
    length: 10,
    fen: "rnbqkbnr/ppp2p1p/3p4/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Long Whip",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 h5",
    length: 10,
    fen: "rnbqkbnr/pppp1p2/8/4N2p/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 0 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Neumann Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nc6",
    length: 10,
    fen: "r1bqkbnr/pppp1p1p/2n5/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Paulsen Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Bg7",
    length: 10,
    fen: "rnbqk1nr/pppp1pbp/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Rosenthal Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Qe7",
    length: 10,
    fen: "rnb1kbnr/ppppqp1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky, Polerio Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Be7",
    length: 10,
    fen: "rnbqk1nr/ppppbp1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq - 2 6",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Mayet Attack, Poisoned Pawn Variation",
    pgn: "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Qg5 5. d4 Qxg2",
    length: 10,
    fen: "rnb1kbnr/pppp2pp/8/4N3/2BPp3/8/PPP2PqP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Closed Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 Nf6",
    length: 10,
    fen: "rnbqkb1r/ppp2ppp/3p1n2/8/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Vukovic Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d4 Nxe4 5. d5 Bc5",
    length: 10,
    fen: "r1bqk2r/pppp1ppp/2n5/2bPp3/4n3/2P2N2/PP3PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Main Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Nxc3 Bb4",
    length: 10,
    fen: "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/2N2N2/PP3PPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Kingside Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. e5 Ng4",
    length: 10,
    fen: "r1bqkb1r/pppp1ppp/2n5/4P3/2Bp2n1/5N2/PPP2PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation, Intermezzo Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 Qf6",
    length: 10,
    fen: "r1b1k1nr/pppp1ppp/2N2q2/2b5/4P3/8/PPP2PPP/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Modern Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3 Bb4",
    length: 10,
    fen: "r1b1k1nr/pppp1ppp/2n5/8/1b1NP2q/2N5/PPP2PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Romanishin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3 Bb4+",
    length: 10,
    fen: "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/1N6/PPP2PPP/RNBQKB1R w KQkq - 3 6",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Krause Gambit, Leonhardt Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5 Qe7",
    length: 10,
    fen: "r1b1k2r/ppppqppp/2n2n2/4N3/1b1PP3/2N5/PPP2PPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Oxford Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. d5 Nd4",
    length: 10,
    fen: "r1bqk2r/pppp1ppp/5n2/3Pp3/1b1nP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Schmid Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Nxe4",
    length: 10,
    fen: "r1bqkb1r/pppp1ppp/2n5/8/3Nn3/2N5/PPP2PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Dubois Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5 5. Ng5 f4",
    length: 10,
    fen: "r1bqk1nr/pppp2pp/2n5/2b1p1N1/2B1Pp2/3P4/PPP2PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7",
    length: 10,
    fen: "r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Mayet Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bf8",
    length: 10,
    fen: "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, McDonnell Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5",
    length: 10,
    fen: "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Stone-Ware Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bd6",
    length: 10,
    fen: "r1bqk1nr/pppp1ppp/2nb4/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Main Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5",
    length: 10,
    fen: "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Center Holding Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6",
    length: 10,
    fen: "r1b1k1nr/ppppqppp/1bn5/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Janowski Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O d6",
    length: 10,
    fen: "r1bqkb1r/ppp2ppp/2np1n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Max Lange Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5",
    length: 10,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq - 4 6",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, de Riviere Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Be7",
    length: 10,
    fen: "r1bqk2r/ppppbppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq - 4 6",
  },
  {
    eco: "C56",
    name: "Italian Game: Scotch Gambit, Double Gambit Accepted",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4",
    length: 10,
    fen: "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Fritz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nd4",
    length: 10,
    fen: "r1bqkb1r/ppp2ppp/5n2/3Pp1N1/2Bn4/8/PPPP1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Kloss Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nb4",
    length: 10,
    fen: "r1bqkb1r/ppp2ppp/5n2/3Pp1N1/1nB5/8/PPPP1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Ulvestad Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 b5",
    length: 10,
    fen: "r1bqkb1r/p1p2ppp/2n2n2/1p1Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5",
    length: 10,
    fen: "r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C60",
    name: "Ruy Lopez: Alapin Defense, Alapin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4 4. c3 Ba5 5. Bxc6 dxc6",
    length: 10,
    fen: "r1bqk1nr/ppp2ppp/2p5/b3p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C61",
    name: "Ruy Lopez: Bird Variation, Paulsen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 exd4 5. O-O Ne7",
    length: 10,
    fen: "r1bqkb1r/ppppnppp/8/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1 w kq - 2 6",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, KostiÄ‡ Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Be7",
    length: 10,
    fen: "r1bqk1nr/ppppb1pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R w KQkq - 1 6",
  },
  {
    eco: "C63",
    name: "Ruy Lopez: Schliemann Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Nf6",
    length: 10,
    fen: "r1bqkb1r/pppp2pp/2n2n2/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R w KQkq - 1 6",
  },
  {
    eco: "C65",
    name: "Ruy Lopez: Berlin Defense, Mortimer Trap",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7 5. Nxe5 c6",
    length: 10,
    fen: "r1bqkb1r/pp1pnppp/2p2n2/1B2N3/4P3/3P4/PPP2PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Closed Berlin Defense, Chigorin Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Nd7",
    length: 10,
    fen: "r1bqkb1r/pppn1ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1 w kq - 1 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rio de Janeiro Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7",
    length: 10,
    fen: "r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq - 1 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Rosenthal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 a6",
    length: 10,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, l'Hermet Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6",
    length: 10,
    fen: "r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1 w kq - 1 6",
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Bronstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Qd6",
    length: 10,
    fen: "r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 2 6",
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Gligoric Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O f6",
    length: 10,
    fen: "r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, King's Bishop Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bd6",
    length: 10,
    fen: "r1bqk1nr/1pp2ppp/p1pb4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 2 6",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Graz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Bc5",
    length: 10,
    fen: "r1bqk1nr/2pp1ppp/p1n5/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Norwegian Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5",
    length: 10,
    fen: "r1bqkbnr/2pp1ppp/p7/np2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "C74",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Siesta Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 f5",
    length: 10,
    fen: "r1bqkbnr/1pp3pp/p1np4/4pp2/B3P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "C75",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7",
    length: 10,
    fen: "r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq - 1 6",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Brix Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O g6",
    length: 10,
    fen: "r1bqkb1r/1ppp1p1p/p1n2np1/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Central Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d5",
    length: 10,
    fen: "r1bqkb1r/1pp2ppp/p1n2n2/3pp3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense, Neo-Arkhangelsk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Bc5",
    length: 10,
    fen: "r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 4 6",
  },
  {
    eco: "C79",
    name: "Ruy Lopez: Morphy Defense, Steinitz Deferred",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6",
    length: 10,
    fen: "r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4",
    length: 10,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 0 6",
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7",
    length: 10,
    fen: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq - 4 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Bogoljubow Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 g6",
    length: 10,
    fen: "rnbqkb1r/ppp1pp1p/5np1/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Euwe Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 e6",
    length: 10,
    fen: "rnbqkb1r/ppp2ppp/4pn2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Gunderam Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bf5",
    length: 10,
    fen: "rn1qkb1r/ppp1pppp/5n2/5b2/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Holwell Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Qd6",
    length: 10,
    fen: "rnb1kb1r/ppp1pppp/3q1n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Kaulich Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c5",
    length: 10,
    fen: "rnbqkb1r/pp2pppp/5n2/2p5/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Pietrowsky Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nc6",
    length: 10,
    fen: "r1bqkb1r/ppp1pppp/2n2n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ritter Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 b6",
    length: 10,
    fen: "rnbqkb1r/p1p1pppp/1p3n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Schlutter Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nbd7",
    length: 10,
    fen: "r1bqkb1r/pppnpppp/5n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Teichmann Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bg4",
    length: 10,
    fen: "rn1qkb1r/ppp1pppp/5n2/8/3P2b1/2N2N2/PPP3PP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D00",
    name: "Blackmar-Diemer Gambit Accepted: Ziegler Defense",
    pgn: "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c6",
    length: 10,
    fen: "rnbqkb1r/pp2pppp/2p2n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D02",
    name: "Queen's Pawn Game: London System, Pterodactyl Variation",
    pgn: "1. d4 g6 2. Nf3 Bg7 3. Bf4 c5 4. c3 cxd4 5. cxd4 Qa5+",
    length: 10,
    fen: "rnb1k1nr/pp1pppbp/6p1/q7/3P1B2/5N2/PP2PPPP/RN1QKB1R w KQkq - 1 6",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Austrian Defense, Haberditz Variation",
    pgn: "1. d4 d5 2. c4 c5 3. cxd5 Nf6 4. e4 Nxe4 5. dxc5 Qa5+",
    length: 10,
    fen: "rnb1kb1r/pp2pppp/8/q1PP4/4n3/8/PP3PPP/RNBQKBNR w KQkq - 1 6",
  },
  {
    eco: "D06",
    name: "Queen's Gambit Declined: Baltic Defense, Argentinian Gambit",
    pgn: "1. d4 d5 2. c4 Bf5 3. cxd5 Bxb1 4. Qa4+ c6 5. dxc6 Nxc6",
    length: 10,
    fen: "r2qkbnr/pp2pppp/2n5/8/Q2P4/8/PP2PPPP/RbB1KBNR w KQkq - 0 6",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Balogh Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 Qe7",
    length: 10,
    fen: "r1b1kbnr/ppp1qppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R w KQkq - 4 6",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Janowski Variation",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 f6",
    length: 10,
    fen: "r1bqkbnr/ppp3pp/2n2p2/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D08",
    name: "Queen's Gambit Declined: Albin Countergambit, Lasker Trap",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. e3 Bb4+ 5. Bd2 dxe3",
    length: 10,
    fen: "rnbqk1nr/ppp2ppp/8/4P3/1bP5/4p3/PP1B1PPP/RN1QKBNR w KQkq - 0 6",
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Be6 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Be6",
    length: 10,
    fen: "r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bf5 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bf5",
    length: 10,
    fen: "r2qkbnr/ppp2ppp/2n5/4Pb2/2Pp4/5NP1/PP2PP1P/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "D09",
    name: "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bg4 Line",
    pgn: "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bg4",
    length: 10,
    fen: "r2qkbnr/ppp2ppp/2n5/4P3/2Pp2b1/5NP1/PP2PP1P/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "D16",
    name: "Slav Defense: Smyslov Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Na6",
    length: 10,
    fen: "r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D16",
    name: "Slav Defense: Soultanbeieff Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 e6",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D16",
    name: "Slav Defense: Steiner Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bg4",
    length: 10,
    fen: "rn1qkb1r/pp2pppp/2p2n2/8/P1pP2b1/2N2N2/1P2PPPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5",
    length: 10,
    fen: "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Linares Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 Nf6 5. Nc3 b5",
    length: 10,
    fen: "rnbqkb1r/p3pppp/5n2/1ppP4/2p1P3/2N5/PP3PPP/R1BQKBNR w KQkq - 0 6",
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Spielmann Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. e3 c6 5. Nbd2 g6",
    length: 10,
    fen: "rnbqkb1r/pp3p1p/2p1pnp1/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D30",
    name: "Semi-Slav Defense: Quiet Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7",
    length: 10,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation, Belyavsky Line",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5 f6",
    length: 10,
    fen: "rnbqkbnr/pp4pp/2p1pp2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R w KQkq - 0 6",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Symmetrical Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. e3 Nf6 5. Nf3 Nc6",
    length: 10,
    fen: "r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Positional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR w KQkq - 0 6",
  },
  {
    eco: "D39",
    name: "Queen's Gambit Declined: Ragozin Defense, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 dxc4",
    length: 10,
    fen: "rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R w KQkq - 0 6",
  },
  {
    eco: "D44",
    name: "Semi-Slav Defense Accepted",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4",
    length: 10,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R w KQkq - 0 6",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Accelerated Meran Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 a6",
    length: 10,
    fen: "rnbqkb1r/1p3ppp/p1p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Normal Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7",
    length: 10,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Canal Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5 Qb6",
    length: 10,
    fen: "rnb1kb1r/pp3ppp/1q2pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR w KQkq - 1 6",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Manhattan Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 Bb4",
    length: 10,
    fen: "r1bqk2r/pppn1ppp/4pn2/3p2B1/1bPP4/2N1P3/PP3PPP/R2QKBNR w KQkq - 1 6",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Modern Variation, Knight Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6",
    length: 10,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR w KQkq - 0 6",
  },
  {
    eco: "D53",
    name: "Queen's Gambit Declined: Lasker Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 Ne4",
    length: 10,
    fen: "rnbqk2r/ppp1bppp/4p3/3p2B1/2PPn3/2N1P3/PP3PPP/R2QKBNR w KQkq - 1 6",
  },
  {
    eco: "D71",
    name: "Neo-Grünfeld Defense: Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5 5. cxd5 Nxd5",
    length: 10,
    fen: "rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR w KQkq - 0 6",
  },
  {
    eco: "D83",
    name: "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O",
    length: 10,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR w KQ - 1 6",
  },
  {
    eco: "E05",
    name: "Catalan Opening: Open Defense, Classical Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Be7",
    length: 10,
    fen: "rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Andersson Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Ne4",
    length: 10,
    fen: "rn1qkb1r/pbpp1ppp/1p2p3/8/2PPn3/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Hedgehog Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 g6",
    length: 10,
    fen: "rn1qkb1r/pbpp1p1p/1p2pnp1/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5",
    length: 10,
    fen: "rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Marco Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Be7",
    length: 10,
    fen: "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 3 6",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Petrosian Variation, Farago Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Ba6 5. Qc2 Bb7",
    length: 10,
    fen: "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1PQ1PPPP/RNB1KB1R w KQkq - 3 6",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+",
    length: 10,
    fen: "rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R w KQkq - 1 6",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 c5",
    length: 10,
    fen: "rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq - 0 6",
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+",
    length: 10,
    fen: "rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R w KQkq - 3 6",
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Traditional Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7",
    length: 10,
    fen: "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq - 3 6",
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Euwe Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5 Ne4",
    length: 10,
    fen: "rnbqk2r/pp1p1ppp/4p3/2pP4/1bP1n3/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 1 6",
  },
  {
    eco: "E21",
    name: "Nimzo-Indian Defense: Three Knights Variation, Shocron Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nc3 c5 5. d5 b5",
    length: 10,
    fen: "rnbqk2r/p2p1ppp/4pn2/1ppP4/1bP5/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 6",
  },
  {
    eco: "E23",
    name: "Nimzo-Indian Defense: Spielmann Variation, Romanovsky Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6",
    length: 10,
    fen: "r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR w KQkq - 1 6",
  },
  {
    eco: "E27",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 O-O",
    length: 10,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR w KQ - 1 6",
  },
  {
    eco: "E33",
    name: "Nimzo-Indian Defense: Classical Variation, Milner-Barry Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6 5. Nf3 d6",
    length: 10,
    fen: "r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R w KQkq - 0 6",
  },
  {
    eco: "E35",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. cxd5 exd5",
    length: 10,
    fen: "rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR w KQkq - 0 6",
  },
  {
    eco: "E38",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Steiner Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 Bxc3+",
    length: 10,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2P5/2P5/2b5/PPQ1PPPP/R1B1KBNR w KQkq - 0 6",
  },
  {
    eco: "E39",
    name: "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Pirc Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 O-O",
    length: 10,
    fen: "rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR w KQ - 1 6",
  },
  {
    eco: "E41",
    name: "Nimzo-Indian Defense: Normal Variation, Hübner Deferred",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Nf3 O-O",
    length: 10,
    fen: "rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP3PPP/R1BQKB1R w KQ - 2 6",
  },
  {
    eco: "E45",
    name: "Nimzo-Indian Defense: Normal Variation, Bronstein (Byrne) Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2 Ba6",
    length: 10,
    fen: "rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R w KQkq - 2 6",
  },
  {
    eco: "E48",
    name: "Nimzo-Indian Defense: Normal Variation, Bishop Attack, Classical Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5",
    length: 10,
    fen: "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR w KQ - 0 6",
  },
  {
    eco: "E51",
    name: "Nimzo-Indian Defense: Normal Variation, Ragozin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5",
    length: 10,
    fen: "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R w KQ - 0 6",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Pterodactyl Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 c5 5. Bg2 Qa5+",
    length: 10,
    fen: "rnb1k2r/pp1pppbp/5np1/q1p5/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq - 2 6",
  },
  {
    eco: "E76",
    name: "King's Indian Defense: Four Pawns Attack, Modern Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 Na6",
    length: 10,
    fen: "r1bqk2r/ppp1ppbp/n2p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR w KQkq - 1 6",
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Normal Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O",
    length: 10,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR w KQ - 1 6",
  },
  {
    eco: "A00",
    name: "Amar Opening: Gent Gambit",
    pgn: "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O fxg3 6. hxg3",
    length: 11,
    fen: "rn1qkbnr/ppp2ppp/8/3p4/8/6PB/PPPPP3/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "A00",
    name: "Polish Opening: King's Indian Variation, Sokolsky Attack",
    pgn: "1. b4 Nf6 2. Bb2 g6 3. c4 Bg7 4. e3 d6 5. Nf3 O-O 6. d4",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/1PPP4/4PN2/PB3PPP/RN1QKB1R b KQ - 0 6",
  },
  {
    eco: "A00",
    name: "Polish Opening: Rooks Swap Line",
    pgn: "1. b4 e6 2. Bb2 Nf6 3. b5 a6 4. a4 axb5 5. axb5 Rxa1 6. Bxa1",
    length: 11,
    fen: "1nbqkb1r/1ppp1ppp/4pn2/1P6/8/8/2PPPPPP/BN1QKBNR b Kk - 0 6",
  },
  {
    eco: "A00",
    name: "Van Geet Opening: Jendrossek Gambit",
    pgn: "1. Nc3 d5 2. f4 d4 3. Ne4 f5 4. Nf2 Nf6 5. Nf3 c5 6. b4",
    length: 11,
    fen: "rnbqkb1r/pp2p1pp/5n2/2p2p2/1P1p1P2/5N2/P1PPPNPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "A05",
    name: "Réti-Zukertort Opening: Double Fianchetto Attack",
    pgn: "1. Nf3 Nf6 2. g3 g6 3. b3 Bg7 4. Bb2 O-O 5. Bg2 d6 6. O-O",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/8/1P3NP1/PBPPPPBP/RN1Q1RK1 b - - 1 6",
  },
  {
    eco: "A26",
    name: "English Opening: King's English Variation, Botvinnik System",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. e4",
    length: 11,
    fen: "r1bqk1nr/ppp2pbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR b KQkq - 0 6",
  },
  {
    eco: "A45",
    name: "Trompowsky Attack: Edge Variation, Hergert Gambit",
    pgn: "1. d4 Nf6 2. Bg5 Ne4 3. Bh4 d5 4. f3 Nf6 5. Nc3 Bf5 6. e4",
    length: 11,
    fen: "rn1qkb1r/ppp1pppp/5n2/3p1b2/3PP2B/2N2P2/PPP3PP/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "A47",
    name: "Marienbad System: Berg Variation",
    pgn: "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5 5. c4 cxd4 6. Qxd4",
    length: 11,
    fen: "rn1qkb1r/pb1ppppp/1p3n2/8/2PQ4/5NP1/PP2PPBP/RNB1K2R b KQkq - 0 6",
  },
  {
    eco: "A48",
    name: "Queen's Pawn Game: Barry Attack, Grünfeld Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Be2",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/3P1B2/2N1PN2/PPP1BPPP/R2QK2R b KQ - 2 6",
  },
  {
    eco: "A65",
    name: "Benoni Defense: King's Pawn Line",
    pgn: "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR b KQkq - 0 6",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, El Columpio Gambit",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. e6",
    length: 11,
    fen: "r1bqkb1r/ppp1pppp/2npP2n/8/3P4/5N1P/PPP2PP1/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, Exchange Variation",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. exd6",
    length: 11,
    fen: "r1bqkb1r/ppp1pppp/2nP3n/8/3P4/5N1P/PPP2PP1/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "B00",
    name: "Nimzowitsch Defense: El Columpio Defense, Pin Variation",
    pgn: "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. Bb5",
    length: 11,
    fen: "r1bqkb1r/ppp1pppp/2np3n/1B2P3/3P4/5N1P/PPP2PP1/RNBQK2R b KQkq - 2 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Lasker Variation",
    pgn: "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bg4 6. h3",
    length: 11,
    fen: "rn2kb1r/ppp1pppp/5n2/q7/3P2b1/2N2N1P/PPP2PP1/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Jadoul Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. Bb5+ Nbd7 6. c4",
    length: 11,
    fen: "r2qkb1r/pppnpppp/5n2/1B1P1b2/2PP4/5P2/PP4PP/RNBQK1NR b KQkq - 0 6",
  },
  {
    eco: "B01",
    name: "Scandinavian Defense: Portuguese Gambit, Melbourne Shuffle Variation",
    pgn: "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. f3 Bf5 5. Bb5+ Nbd7 6. Nc3",
    length: 11,
    fen: "r2qkb1r/pppnpppp/5n2/1B1P1b2/3P4/2N2P2/PPP3PP/R1BQK1NR b KQkq - 4 6",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Hunt Variation, Lasker Simul Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3",
    length: 11,
    fen: "rnbqkb1r/pppp1ppp/4p3/2PnP3/2B5/2N5/PP1P1PPP/R1BQK1NR b KQkq - 1 6",
  },
  {
    eco: "B03",
    name: "Alekhine Defense: Modern Variation, Alekhine Gambit",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. Nf3 Bg4 6. Be2",
    length: 11,
    fen: "rn1qkb1r/ppp1pppp/1n1p4/4P3/2PP2b1/5N2/PP2BPPP/RNBQK2R b KQkq - 4 6",
  },
  {
    eco: "B05",
    name: "Alekhine Defense: Modern Variation, Vitolins Attack",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4 Nb6 6. d5",
    length: 11,
    fen: "rn1qkb1r/ppp1pppp/1n1p4/3PP3/2P3b1/5N2/PP3PPP/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Kurajica Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Be3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N1BN2/PPP3PP/R2QKB1R b KQ - 4 6",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Unzicker Attack",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. e5",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/4P3/3P1P2/2N2N2/PPP3PP/R1BQKB1R b KQ - 0 6",
  },
  {
    eco: "B09",
    name: "Pirc Defense: Austrian Attack, Weiss Variation",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Bd3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R b KQ - 4 6",
  },
  {
    eco: "B12",
    name: "Caro-Kann Defense: Maróczy Variation, Maróczy Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. f3 dxe4 4. fxe4 e5 5. Nf3 exd4 6. Bc4",
    length: 11,
    fen: "rnbqkbnr/pp3ppp/2p5/8/2BpP3/5N2/PPP3PP/RNBQK2R b KQkq - 1 6",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Exchange Variation, Rubinstein Variation",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Bd3 Nc6 5. c3 Nf6 6. Bf4",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2PB4/PP3PPP/RN1QK1NR b KQkq - 2 6",
  },
  {
    eco: "B15",
    name: "Caro-Kann Defense: Forgacs Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6 6. Bc4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/2p2p2/8/2BP4/8/PPP2PPP/R1BQK1NR b KQkq - 1 6",
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Modern Variation, Kasparov Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Ng3",
    length: 11,
    fen: "r1bqkb1r/pp1npppp/2p2n2/8/3P4/5NN1/PPP2PPP/R1BQKB1R b KQkq - 4 6",
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Flohr Variation",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. Nh3",
    length: 11,
    fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P4/6NN/PPP2PPP/R1BQKB1R b KQkq - 4 6",
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4",
    length: 11,
    fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR b KQkq - 0 6",
  },
  {
    eco: "B18",
    name: "Caro-Kann Defense: Classical Variation, Maróczy Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. f4",
    length: 11,
    fen: "rn1qkbnr/pp2pppp/2p3b1/8/3P1P2/6N1/PPP3PP/R1BQKBNR b KQkq - 0 6",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Stoltz Attack",
    pgn: "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Bc4 Nb6 6. Bb3",
    length: 11,
    fen: "r1bqkb1r/pp1ppppp/1nn5/2p1P3/8/1BP2N2/PP1P1PPP/RNBQK2R b KQkq - 6 6",
  },
  {
    eco: "B23",
    name: "Sicilian Defense: Grand Prix Attack, Schofman Variation",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. f4 g6 4. Nf3 Bg7 5. Bc4 e6 6. f5",
    length: 11,
    fen: "r1bqk1nr/pp1p1pbp/2n1p1p1/2p2P2/2B1P3/2N2N2/PPPP2PP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4",
    length: 11,
    fen: "r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR b KQkq - 0 6",
  },
  {
    eco: "B26",
    name: "Sicilian Defense: Closed",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Be3",
    length: 11,
    fen: "r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR b KQkq - 1 6",
  },
  {
    eco: "B27",
    name: "Pterodactyl Defense: Sicilian, Pteranodon",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3",
    length: 11,
    fen: "rnb1k1nr/pp1ppp1p/6p1/q1P5/4P3/2P2N2/P1P2PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Gufeld Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 e5 6. d4",
    length: 11,
    fen: "r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Lutikov Gambit",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. d4",
    length: 11,
    fen: "r1bqk2r/pp1pppbp/2n2np1/1Bp5/3PP3/2P2N2/PP3PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Totsky Attack",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. Qa4",
    length: 11,
    fen: "r1bqk2r/pp1pppbp/2n2np1/1Bp5/Q3P3/2P2N2/PP1P1PPP/RNB2RK1 b kq - 2 6",
  },
  {
    eco: "B31",
    name: "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Gurgenidze Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. Re1 e5 6. b4",
    length: 11,
    fen: "r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/1P2P3/5N2/P1PP1PPP/RNBQR1K1 b kq - 0 6",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nxc6",
    length: 11,
    fen: "r1bqkb1r/pp1p1ppp/2N2n2/4p3/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Retreat Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nf3",
    length: 11,
    fen: "r1bqkb1r/pp1p1ppp/2n2n2/4p3/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Lasker-Pelikan Variation, Schlechter Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nb3",
    length: 11,
    fen: "r1bqkb1r/pp1p1ppp/2n2n2/4p3/4P3/1NN5/PPP2PPP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "B38",
    name: "Sicilian Defense: Accelerated Dragon, Maróczy Bind",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Be3",
    length: 11,
    fen: "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R b KQkq - 2 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Four Knights Variation, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Nxc6",
    length: 11,
    fen: "r1bqkb1r/pp1p1ppp/2N1pn2/8/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Kan Variation, Wing Attack, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. Nc3 a6 4. g3 b5 5. d4 cxd4 6. Nxd4",
    length: 11,
    fen: "rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation, Koch Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. e5",
    length: 11,
    fen: "rnbqk2r/pp1p1ppp/4pn2/4P3/1b1N4/2N5/PPP2PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B48",
    name: "Sicilian Defense: Taimanov Variation, Bastrikov Variation, English Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3",
    length: 11,
    fen: "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq - 4 6",
  },
  {
    eco: "B51",
    name: "Sicilian Defense: Moscow Variation, Dorfman Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. Bb5+ Nc6 4. O-O Bd7 5. Qe2 g6 6. e5",
    length: 11,
    fen: "r2qkbnr/pp1bpp1p/2np2p1/1Bp1P3/8/5N2/PPPPQPPP/RNB2RK1 b kq - 0 6",
  },
  {
    eco: "B55",
    name: "Sicilian Defense: Prins Variation, Venice Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3 e5 6. Bb5+",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R b KQkq - 1 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Classical Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. g3",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Spielmann Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Nde2",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/4P3/2N5/PPP1NPPP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Venice Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Bb5+",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/2N5/PPP2PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B56",
    name: "Sicilian Defense: Yates Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bd3",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2NB4/PPP2PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B57",
    name: "Sicilian Defense: Classical Variation, Sozin Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bc4",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq - 4 6",
  },
  {
    eco: "B58",
    name: "Sicilian Defense: Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq - 4 6",
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5",
    length: 11,
    fen: "r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R b KQkq - 4 6",
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B70",
    name: "Sicilian Defense: Dragon Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. g3",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B71",
    name: "Sicilian Defense: Dragon Variation, Levenfish Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B72",
    name: "Sicilian Defense: Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq - 1 6",
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation, Fianchetto Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g3",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B80",
    name: "Sicilian Defense: Scheveningen Variation, Vitolins Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bb5+",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/1B6/3NP3/2N5/PPP2PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B81",
    name: "Sicilian Defense: Scheveningen Variation, Keres Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B82",
    name: "Sicilian Defense: Scheveningen Variation, Matanovic Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. f4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B83",
    name: "Sicilian Defense: Scheveningen Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B86",
    name: "Sicilian Defense: Sozin Attack",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Adams Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. h3",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N4P/PPP2PP1/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Dekker Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g4",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, English Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq - 1 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Freak Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Rg1",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKBR1 b Qkq - 1 6",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, Lipnitsky Attack",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B91",
    name: "Sicilian Defense: Najdorf Variation, Zagreb Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g3",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B92",
    name: "Sicilian Defense: Najdorf Variation, Opocensky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "B93",
    name: "Sicilian Defense: Najdorf Variation, Amsterdam Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. f4",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "B94",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5",
    length: 11,
    fen: "rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R b KQkq - 1 6",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Main Line",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3",
    length: 11,
    fen: "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Milner-Barry Gambit",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Bd3",
    length: 11,
    fen: "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2PB1N2/PP3PPP/RNBQK2R b KQkq - 4 6",
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3",
    length: 11,
    fen: "rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Bernstein Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bh4",
    length: 11,
    fen: "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P3B/2N5/PPP2PPP/R2QKBNR b KQkq - 1 6",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Chigorin Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. exf6",
    length: 11,
    fen: "rnbqk2r/ppp2pp1/4pP1p/3p2B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Dr. Olland Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bc1",
    length: 11,
    fen: "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq - 1 6",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Janowski Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Be3",
    length: 11,
    fen: "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N1B3/PPP2PPP/R2QKBNR b KQkq - 1 6",
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4",
    length: 11,
    fen: "rnbqk2r/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Exchange Variation, Canal Attack",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5 exd5 5. Bd3 Ne7 6. Qh5",
    length: 11,
    fen: "rnbqk2r/ppp1nppp/8/3p3Q/1b1P4/2NB4/PPP2PPP/R1B1K1NR b KQkq - 3 6",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Kondratiyev Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd3 c5 5. exd5 Qxd5 6. Bd2",
    length: 11,
    fen: "rnb1k1nr/pp3ppp/4p3/2pq4/1b1P4/2NB4/PPPB1PPP/R2QK1NR b KQkq - 1 6",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Bogoljubov Variation, Icelandic Defense",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2 Ne7 6. f4",
    length: 11,
    fen: "rnbqk2r/pp2nppp/4p3/2ppP3/1b1P1P2/2N5/PPPB2PP/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3",
    length: 11,
    fen: "rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR b KQkq - 0 6",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Allgaier Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5",
    length: 11,
    fen: "r1bqkbnr/pppp1p1p/2n5/6N1/4PppP/2N5/PPPP2P1/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "C25",
    name: "Vienna Game: Hamppe-Muzio Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O",
    length: 11,
    fen: "r1bqkbnr/pppp1p1p/2n5/8/2B1Ppp1/2N2N2/PPPP2PP/R1BQ1RK1 b kq - 1 6",
  },
  {
    eco: "C26",
    name: "Vienna Game: Paulsen Variation, Pollock Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. g3 Bc5 4. Bg2 Nc6 5. Nge2 d5 6. exd5",
    length: 11,
    fen: "r1bqk2r/ppp2ppp/2n2n2/2bPp3/8/2N3P1/PPPPNPBP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "C27",
    name: "Vienna Game: Adams' Gambit",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Nc6 6. d4",
    length: 11,
    fen: "r1bqkb1r/pppp1ppp/2nn4/4p2Q/3P4/1BN5/PPP2PPP/R1B1K1NR b KQkq - 0 6",
  },
  {
    eco: "C29",
    name: "Vienna Game: Heyde Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5 6. d4",
    length: 11,
    fen: "rnbqkb1r/ppp3pp/8/3pPp2/3Pn3/2N2Q2/PPP3PP/R1B1KBNR b KQkq - 0 6",
  },
  {
    eco: "C29",
    name: "Vienna Game: Vienna Gambit, Kaufmann Variation",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Bg4 6. Qe2",
    length: 11,
    fen: "rn1qkb1r/ppp2ppp/8/3pP3/4n1b1/2N2N2/PPPPQ1PP/R1B1KB1R b KQkq - 3 6",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Classical Variation, Euwe Attack",
    pgn: "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 Bg4 5. fxe5 dxe5 6. Qa4+",
    length: 11,
    fen: "rn1qk1nr/ppp2ppp/8/2b1p3/Q3P1b1/2P2N2/PP1P2PP/RNB1KB1R b KQkq - 1 6",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Pawn Sacrifice Line",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ g6 5. fxg6 Qe7+ 6. Kd1",
    length: 11,
    fen: "rnb1kbnr/ppppq2p/6P1/7Q/5p2/8/PPPP2PP/RNBK1BNR b kq - 2 6",
  },
  {
    eco: "C30",
    name: "King's Gambit Declined: Panteldakis Countergambit, Symmetrical Variation",
    pgn: "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Nf3 d5 5. d4 Bd6 6. Bd3",
    length: 11,
    fen: "rnbqk1nr/ppp3pp/3b4/3p1P2/3P1p2/3B1N2/PPP3PP/RNBQK2R b KQkq - 2 6",
  },
  {
    eco: "C32",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit",
    pgn: "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Qe2",
    length: 11,
    fen: "rnbqkb1r/ppp2ppp/8/3P4/4nP2/8/PPP1Q1PP/RNB1KBNR b KQkq - 1 6",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Chigorin's Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d5 5. Bxd5 g5 6. g3",
    length: 11,
    fen: "rnb1kbnr/ppp2p1p/8/3B2p1/4Pp1q/6P1/PPPP3P/RNBQ1KNR b kq - 0 6",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, McDonnell Attack",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. g3",
    length: 11,
    fen: "rnb1k1nr/pppp1pbp/8/6p1/2B1Pp1q/2N3P1/PPPP3P/R1BQ1KNR b kq - 0 6",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Ghulam-Kassim Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Qxf3",
    length: 11,
    fen: "rnbqkbnr/pppp1p1p/8/8/2BPPp2/5Q2/PPP3PP/RNB1K2R b KQkq - 0 6",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Kotov Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Bxf4",
    length: 11,
    fen: "rnbqkbnr/pppp1p1p/8/8/2BPPB2/5p2/PPP3PP/RN1QK2R b KQkq - 0 6",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Rosentreter Gambit, Bird Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5 Qh4+ 6. g3",
    length: 11,
    fen: "rnb1kbnr/pppp1p1p/8/4N3/3PPppq/6P1/PPP4P/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C38",
    name: "King's Gambit Accepted: Mayet Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. d4 d6 6. c3",
    length: 11,
    fen: "rnbqk1nr/ppp2pbp/3p4/6p1/2BPPp2/2P2N2/PP4PP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, Rubinstein Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. d4",
    length: 11,
    fen: "rnbqkb1r/pppp1p1p/5n2/4N3/3PPppP/8/PPP3P1/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Cotter Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7",
    length: 11,
    fen: "rnbqkbnr/pppp1N2/7p/8/4PppP/8/PPPP2P1/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C40",
    name: "King's Pawn Game: Damiano Defense, Damiano Gambit",
    pgn: "1. e4 e5 2. Nf3 f6 3. Nxe5 fxe5 4. Qh5+ g6 5. Qxe5+ Qe7 6. Qxh8",
    length: 11,
    fen: "rnb1kbnQ/ppppq2p/6p1/8/4P3/8/PPPP1PPP/RNB1KB1R b KQq - 0 6",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Bronstein Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Be2",
    length: 11,
    fen: "rnb1kbnr/ppp3pp/3p1q2/8/2NPp3/8/PPP1BPPP/RNBQK2R b KQkq - 1 6",
  },
  {
    eco: "C40",
    name: "Latvian Gambit Accepted: Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Ne3",
    length: 11,
    fen: "rnb1kbnr/ppp3pp/3p1q2/8/3Pp3/4N3/PPP2PPP/RNBQKB1R b KQkq - 1 6",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Hanham Variation, Steiner Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O Be7 6. dxe5",
    length: 11,
    fen: "r1bqk1nr/pp1nbppp/2pp4/4P3/2B1P3/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Bishop Sacrifice",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. Bxf7+",
    length: 11,
    fen: "r1bqk2r/pppnbBpp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lopez Countergambit, Jaenisch Variation",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Bc4 exd4 5. Ng5 Nh6 6. Nxh7",
    length: 11,
    fen: "rnbqkb1r/ppp3pN/3p3n/5p2/2BpP3/8/PPP2PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Philidor Countergambit, del Rio Attack",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Ng5 d5 6. e6",
    length: 11,
    fen: "rnbqkbnr/ppp3pp/4P3/3p2N1/4p3/8/PPP2PPP/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C44",
    name: "Ponziani Opening: Spanish Variation, Harrwitz Attack, Nikitin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5 dxe4 5. Nxe5 Qd5 6. Qa4",
    length: 11,
    fen: "r1b1kbnr/ppp2ppp/2n5/1B1qN3/Q3p3/2P5/PP1P1PPP/RNB1K2R b KQkq - 2 6",
  },
  {
    eco: "C44",
    name: "Scotch Game: Vitzthum Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5 Nh6 6. Qh5",
    length: 11,
    fen: "r1bqk2r/pppp1ppp/2n4n/2b3NQ/2BpP3/8/PPP2PPP/RNB1K2R b KQkq - 5 6",
  },
  {
    eco: "C45",
    name: "Scotch Game",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2",
    length: 11,
    fen: "r1b1k1nr/pppp1ppp/2n5/1N6/1b2P2q/8/PPPB1PPP/RN1QKB1R b KQkq - 4 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Blumenfeld Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. Nb5",
    length: 11,
    fen: "r1b1k1nr/pppp1ppp/2n2q2/1Nb5/4P3/4B3/PPP2PPP/RN1QKB1R b KQkq - 4 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Ghulam-Kassim Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Bd3",
    length: 11,
    fen: "r1bqkbnr/ppp2ppp/3p4/8/3QP3/3B4/PPP2PPP/RNB1K2R b KQkq - 1 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Mieses Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. e5",
    length: 11,
    fen: "r1bqkb1r/p1pp1ppp/2p2n2/4P3/8/8/PPP2PPP/RNBQKB1R b KQkq - 0 6",
  },
  {
    eco: "C45",
    name: "Scotch Game: Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. Nd2",
    length: 11,
    fen: "r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPPN1PPP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. Nxe5 Qe7 6. f4",
    length: 11,
    fen: "r1b1kb1r/ppppqppp/5n2/1B2N3/3nPP2/2N5/PPPP2PP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Double Spanish",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3",
    length: 11,
    fen: "r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1 b - - 0 6",
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Nimzowitsch Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. Bxc6",
    length: 11,
    fen: "r1bq1rk1/pppp1ppp/2B2n2/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1 b - - 0 6",
  },
  {
    eco: "C50",
    name: "Italian Game: Giuoco Pianissimo, Canal Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3 d6 6. Bg5",
    length: 11,
    fen: "r1bqk2r/ppp2ppp/2np1n2/2b1p1B1/2B1P3/2NP1N2/PPP2PPP/R2QK2R b KQkq - 1 6",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4 a6 6. Nc3",
    length: 11,
    fen: "r1bqk1nr/1ppp1ppp/pbn5/4p3/PPB1P3/2N2N2/2PP1PPP/R1BQK2R b KQkq - 1 6",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Slow Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O",
    length: 11,
    fen: "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 b kq - 2 6",
  },
  {
    eco: "C53",
    name: "Italian Game: Giuoco Piano, Mestel Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6 6. Bg5",
    length: 11,
    fen: "r1b1k1nr/ppppqppp/1bn5/4p1B1/2BPP3/2P2N2/PP3PPP/RN1QK2R b KQkq - 2 6",
  },
  {
    eco: "C53",
    name: "Italian Game: Scotch Gambit, Walbrodt-Baird Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. O-O",
    length: 11,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/2P2N2/PP3PPP/RNBQ1RK1 b kq - 1 6",
  },
  {
    eco: "C54",
    name: "Italian Game: Classical Variation, Greco Gambit, Traditional Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4",
    length: 11,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b5/2BPP3/5N2/PP3PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C55",
    name: "Italian Game: Two Knights Defense, Max Lange Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5",
    length: 11,
    fen: "r1bqk2r/pppp1ppp/2n2n2/2b1P3/2Bp4/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C56",
    name: "Italian Game: Scotch Gambit, Nakhmanson Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4 6. Nc3",
    length: 11,
    fen: "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/2N2N2/PPP2PPP/R1BQ1RK1 b kq - 1 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Fried Liver Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Nxf7",
    length: 11,
    fen: "r1bqkb1r/ppp2Npp/2n5/3np3/2B5/8/PPPP1PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Lolli Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4",
    length: 11,
    fen: "r1bqkb1r/ppp2ppp/2n5/3np1N1/2BP4/8/PPP2PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Traxler Variation, Trencianske-Teplice Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+ Ke7 6. d4",
    length: 11,
    fen: "r1bq3r/ppppkBpp/2n2n2/2b1p1N1/3PP3/8/PPP2PPP/RNBQK2R b KQ - 0 6",
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense, Bishop Check Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+",
    length: 11,
    fen: "r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R b KQkq - 2 6",
  },
  {
    eco: "C58",
    name: "Italian Game: Two Knights Defense, Polerio Defense, Kieseritzky Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3",
    length: 11,
    fen: "r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/3P4/PPP2PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C62",
    name: "Ruy Lopez: Steinitz Defense, Nimzowitsch Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. Nc3 Nf6 6. Bxc6",
    length: 11,
    fen: "r2qkb1r/pppb1ppp/2Bp1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Minckwitz Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. dxe5",
    length: 11,
    fen: "r1bqk2r/ppppbppp/2n5/1B2P3/4n3/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Open Berlin Defense, Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Ba4",
    length: 11,
    fen: "r1bqkb1r/pppp1ppp/2nn4/4p3/B2P4/5N2/PPP2PPP/RNBQ1RK1 b kq - 2 6",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Open Berlin Defense, l'Hermet Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. dxe5",
    length: 11,
    fen: "r1bqkb1r/pppp1ppp/2nn4/1B2P3/8/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C68",
    name: "Ruy Lopez: Exchange Variation, Romanovsky Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3 f6 6. d3",
    length: 11,
    fen: "r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/2NP1N2/PPP2PPP/R1BQK2R b KQkq - 0 6",
  },
  {
    eco: "C70",
    name: "Ruy Lopez: Morphy Defense, Norwegian Variation, Nightingale Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5 6. Bxf7+",
    length: 11,
    fen: "r1bqkbnr/2pp1Bpp/p7/np2p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C73",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4",
    length: 11,
    fen: "r1bqkbnr/2p2ppp/p1pp4/4p3/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C77",
    name: "Ruy Lopez: Morphy Defense, Duras Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 d6 6. c4",
    length: 11,
    fen: "r1bqkb1r/1pp2ppp/p1np1n2/4p3/B1P1P3/3P1N2/PP3PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Morphy Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Qe2",
    length: 11,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPPQPPP/RNB2RK1 b kq - 1 6",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4",
    length: 11,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Knorre Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Nc3",
    length: 11,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq - 1 6",
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Center Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d4",
    length: 11,
    fen: "r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Martinez Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d3",
    length: 11,
    fen: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C84",
    name: "Ruy Lopez: Closed, Morphy Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Nc3",
    length: 11,
    fen: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq - 5 6",
  },
  {
    eco: "C85",
    name: "Ruy Lopez: Closed, Delayed Exchange",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Bxc6",
    length: 11,
    fen: "r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq - 0 6",
  },
  {
    eco: "C86",
    name: "Ruy Lopez: Closed, Worrall Attack",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Qe2",
    length: 11,
    fen: "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1 b kq - 5 6",
  },
  {
    eco: "D01",
    name: "Richter-Veresov Attack: Malich Gambit",
    pgn: "1. d4 Nf6 2. Nc3 d5 3. Bg5 c5 4. Bxf6 gxf6 5. e4 dxe4 6. d5",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/5p2/2pP4/4p3/2N5/PPP2PPP/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "D13",
    name: "Slav Defense: Exchange Variation, Schallopp Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5 5. cxd5 cxd5 6. Nc3",
    length: 11,
    fen: "rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 1 6",
  },
  {
    eco: "D15",
    name: "Slav Defense: Geller Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4 b5 6. e5",
    length: 11,
    fen: "rnbqkb1r/p3pppp/2p2n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Bled Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Nh4",
    length: 11,
    fen: "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP3N/2N5/1P2PPPP/R1BQKB1R b KQkq - 2 6",
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Krause Attack",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5",
    length: 11,
    fen: "rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R b KQkq - 2 6",
  },
  {
    eco: "D18",
    name: "Slav Defense: Czech Variation, Classical System",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3",
    length: 11,
    fen: "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Billinger Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. a3",
    length: 11,
    fen: "rnb1kbnr/ppp1qppp/8/8/2Bp4/PQ2P3/1P3PPP/RNB1K1NR b KQkq - 0 6",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Christensen Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nf3",
    length: 11,
    fen: "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2PN2/PP3PPP/RNB1K2R b KQkq - 3 6",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Korchnoi Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Kf1",
    length: 11,
    fen: "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2P3/PP3PPP/RNB2KNR b kq - 3 6",
  },
  {
    eco: "D20",
    name: "Queen's Gambit Accepted: Old Variation, Novikov Gambit",
    pgn: "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nd2",
    length: 11,
    fen: "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2P3/PP1N1PPP/R1B1K1NR b KQkq - 3 6",
  },
  {
    eco: "D22",
    name: "Queen's Gambit Accepted: Alekhine Defense, Alatortsev Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 Bg4 5. Bxc4 e6 6. d5",
    length: 11,
    fen: "rn1qkbnr/1pp2ppp/p3p3/3P4/2B3b1/4PN2/PP3PPP/RNBQK2R b KQkq - 0 6",
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Normal Line",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 b kq - 1 6",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Capablanca Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. c4 d5 4. Bg5 c6 5. Nbd2 Nbd7 6. e3",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/4PN2/PP1N1PPP/R2QKB1R b KQkq - 0 6",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit, Forgotten Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Nc3",
    length: 11,
    fen: "rnbqk1nr/pp3ppp/2p1p3/8/1bPP4/2N5/PP3PPP/R1BQKBNR b KQkq - 2 6",
  },
  {
    eco: "D31",
    name: "Semi-Slav Defense: Marshall Gambit, Main Line",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Bd2",
    length: 11,
    fen: "rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP1B1PPP/R2QKBNR b KQkq - 2 6",
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Rubinstein System",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3",
    length: 11,
    fen: "r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "D35",
    name: "Queen's Gambit Declined: Exchange Variation, Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. cxd5 exd5 6. Bf4",
    length: 11,
    fen: "r1bqkb1r/pppn1ppp/5n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq - 1 6",
  },
  {
    eco: "D36",
    name: "Queen's Gambit Declined: Exchange Variation, Reshevsky Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6 6. Qc2",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR b KQkq - 1 6",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Miles Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. Qc2",
    length: 11,
    fen: "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R b KQ - 5 6",
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Exchange Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "D41",
    name: "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e3",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R b KQkq - 0 6",
  },
  {
    eco: "D43",
    name: "Semi-Slav Defense: Anti-Moscow Gambit",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 h6 6. Bh4",
    length: 11,
    fen: "rnbqkb1r/pp3pp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R b KQkq - 1 6",
  },
  {
    eco: "D44",
    name: "Semi-Slav Defense: Botvinnik Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R b KQkq - 0 6",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Rubinstein System",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 Nf6 5. e3 Nbd7 6. Ne5",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3pN3/2PP4/2N1P3/PP3PPP/R1BQKB1R b KQkq - 2 6",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Stoltz Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Qc2",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R b KQkq - 3 6",
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Main Line",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R b KQkq - 3 6",
  },
  {
    eco: "D50",
    name: "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Primitive Pillsbury Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. Nf3 cxd4 6. Qxd4",
    length: 11,
    fen: "rnbqkb1r/pp3ppp/4pn2/3p2B1/2PQ4/2N2N2/PP2PPPP/R3KB1R b KQkq - 0 6",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Alekhine Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. Nf3 c6 6. e4",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PPP3/2N2N2/PP3PPP/R2QKB1R b KQkq - 0 6",
  },
  {
    eco: "D51",
    name: "Queen's Gambit Declined: Capablanca Variation, Anti-Cambridge Springs Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6 6. a3",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/P1N1P3/1P3PPP/R2QKBNR b KQkq - 0 6",
  },
  {
    eco: "D52",
    name: "Queen's Gambit Declined",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3",
    length: 11,
    fen: "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQkq - 0 6",
  },
  {
    eco: "D54",
    name: "Queen's Gambit Declined: Neo-Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 O-O 6. Rc1",
    length: 11,
    fen: "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/2RQKBNR b K - 2 6",
  },
  {
    eco: "D55",
    name: "Queen's Gambit Declined: Modern Variation, Normal Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3",
    length: 11,
    fen: "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQ - 0 6",
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b - - 0 6",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lutikov Variation, Murrey Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3 c5 5. cxd5 Nxd5 6. Na4",
    length: 11,
    fen: "rnbqkb1r/pp2pp1p/6p1/2pn4/N2P4/5P2/PP2P1PP/R1BQKBNR b KQkq - 1 6",
  },
  {
    eco: "D83",
    name: "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit, Capablanca Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Rc1",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/2RQKBNR b K - 2 6",
  },
  {
    eco: "D93",
    name: "Grünfeld Defense: Three Knights Variation, Hungarian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4 O-O 6. e3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R b KQ - 0 6",
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Makogonov Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. b4",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/1PPP4/2N1PN2/P4PPP/R1BQKB1R b KQ - 0 6",
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Opocensky Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd2",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP1B1PPP/R2QKB1R b KQ - 2 6",
  },
  {
    eco: "D94",
    name: "Grünfeld Defense: Three Knights Variation, Paris Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R b KQ - 2 6",
  },
  {
    eco: "D95",
    name: "Grünfeld Defense: Three Knights Variation, Vienna Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R b KQ - 2 6",
  },
  {
    eco: "E03",
    name: "Catalan Opening: Open Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Qa4+ Nbd7 6. Qxc4",
    length: 11,
    fen: "r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR b KQkq - 0 6",
  },
  {
    eco: "E12",
    name: "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation, Modern Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 Bb7 6. Nd2",
    length: 11,
    fen: "rn1qk2r/pbpp1ppp/1p2pn2/6B1/1bPP4/2N5/PP1NPPPP/R2QKB1R b KQkq - 5 6",
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Anti-Queen's Indian System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. Nc3",
    length: 11,
    fen: "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R b KQkq - 4 6",
  },
  {
    eco: "E17",
    name: "Queen's Indian Defense: Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O",
    length: 11,
    fen: "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b kq - 4 6",
  },
  {
    eco: "E20",
    name: "Nimzo-Indian Defense: Romanishin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. g3 O-O 6. Bg2",
    length: 11,
    fen: "rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PPBP/R1BQK2R b KQ - 2 6",
  },
  {
    eco: "E26",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3",
    length: 11,
    fen: "rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR b KQkq - 0 6",
  },
  {
    eco: "E28",
    name: "Nimzo-Indian Defense: Sämisch Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. a3 Bxc3+ 6. bxc3",
    length: 11,
    fen: "rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR b KQ - 0 6",
  },
  {
    eco: "E51",
    name: "Nimzo-Indian Defense: Normal Variation, Sämisch Deferred",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5 6. a3",
    length: 11,
    fen: "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1N1PN2/1P3PPP/R1BQKB1R b KQ - 0 6",
  },
  {
    eco: "E61",
    name: "King's Indian Defense: Semi-Classical Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R b KQ - 1 6",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR b KQ - 3 6",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Semi-Averbakh System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Be3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR b KQ - 3 6",
  },
  {
    eco: "E77",
    name: "King's Indian Defense: Four Pawns Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. f4",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP2B1PP/R1BQK1NR b KQ - 0 6",
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Steiner Attack",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Bg5",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR b KQ - 2 6",
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Bobotsov-Korchnoi-Petrosian Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Nge2",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP2N1PP/R1BQKB1R b KQ - 2 6",
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Larsen Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be3",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BN2/PP3PPP/R2QKB1R b KQ - 3 6",
  },
  {
    eco: "E90",
    name: "King's Indian Defense: Zinnowitz Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Bg5",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2N2/PP3PPP/R2QKB1R b KQ - 3 6",
  },
  {
    eco: "E91",
    name: "King's Indian Defense: Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2",
    length: 11,
    fen: "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R b KQ - 3 6",
  },
  {
    eco: "A00",
    name: "Grob Opening: Keene Defense, Main Line",
    pgn: "1. g4 d5 2. h3 e5 3. Bg2 c6 4. d4 e4 5. c4 Bd6 6. Nc3 Ne7",
    length: 12,
    fen: "rnbqk2r/pp2nppp/2pb4/3p4/2PPp1P1/2N4P/PP2PPB1/R1BQK1NR w KQkq - 3 7",
  },
  {
    eco: "A13",
    name: "English Opening: Agincourt Defense, Tarrasch Defense",
    pgn: "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. b3 c5 5. Bg2 Nc6 6. O-O Be7",
    length: 12,
    fen: "r1bqk2r/pp2bppp/2n1pn2/2pp4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1 w kq - 4 7",
  },
  {
    eco: "A16",
    name: "English Opening: Anglo-Grünfeld Defense, Korchnoi Variation",
    pgn: "1. Nf3 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. g3 Bg7 6. Bg2 e5",
    length: 12,
    fen: "rnbqk2r/ppp2pbp/6p1/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "A25",
    name: "English Opening: Closed, Taimanov Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Nh6",
    length: 12,
    fen: "r1bqk2r/ppp2pbp/2np2pn/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "A25",
    name: "English Opening: King's English Variation, Bremen-Hort Variation",
    pgn: "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Be6",
    length: 12,
    fen: "r2qk1nr/ppp2pbp/2npb1p1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "A30",
    name: "English Opening: Symmetrical Variation, Hedgehog Defense",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 e6 4. g3 b6 5. Bg2 Bb7 6. O-O Be7",
    length: 12,
    fen: "rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1 w kq - 4 7",
  },
  {
    eco: "A33",
    name: "English Opening: Symmetrical Variation, Anti-Benoni Variation, Geller Variation",
    pgn: "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6 6. g3 Qb6",
    length: 12,
    fen: "r1b1kb1r/pp1p1ppp/1qn1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "A40",
    name: "Pterodactyl Defense: Central, Benoni Beefeater Pterodactyl",
    pgn: "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 d6 5. e4 Bxc3+ 6. bxc3 Qa5",
    length: 12,
    fen: "rnb1k1nr/pp2pp1p/3p2p1/q1pP4/2P1P3/2P5/P4PPP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "A61",
    name: "Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6",
    length: 12,
    fen: "rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "A90",
    name: "Dutch Defense: Stonewall Variation, Modern Variation",
    pgn: "1. d4 f5 2. c4 e6 3. Nf3 Nf6 4. g3 c6 5. Bg2 d5 6. O-O Bd6",
    length: 12,
    fen: "rnbqk2r/pp4pp/2pbpn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w kq - 2 7",
  },
  {
    eco: "A92",
    name: "Dutch Defense: Alekhine Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O Ne4",
    length: 12,
    fen: "rnbq1rk1/ppppb1pp/4p3/5p2/2PPn3/5NP1/PP2PPBP/RNBQ1RK1 w - - 6 7",
  },
  {
    eco: "A92",
    name: "Dutch Defense: Stonewall Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d5",
    length: 12,
    fen: "rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 0 7",
  },
  {
    eco: "A96",
    name: "Dutch Defense: Classical Variation",
    pgn: "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6",
    length: 12,
    fen: "rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 0 7",
  },
  {
    eco: "B00",
    name: "St. George Defense: San Jorge Variation",
    pgn: "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 d6 5. O-O g6 6. c3 Bg7",
    length: 12,
    fen: "rn1qk1nr/1bp1ppbp/p2p2p1/1p6/3PP3/2PB1N2/PP3PPP/RNBQ1RK1 w kq - 1 7",
  },
  {
    eco: "B02",
    name: "Alekhine Defense: Two Pawns Attack, Mikenas Variation",
    pgn: "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3 d6",
    length: 12,
    fen: "rnbqkb1r/ppp2ppp/3pp3/2PnP3/2B5/2N5/PP1P1PPP/R1BQK1NR w KQkq - 0 7",
  },
  {
    eco: "B06",
    name: "Modern Defense: Two Knights Variation, Suttles Variation, Tal Gambit",
    pgn: "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3 c6 5. Bg5 Qb6 6. Qd2 Qxb2",
    length: 12,
    fen: "rnb1k1nr/pp2ppbp/2pp2p1/6B1/3PP3/2N2N2/PqPQ1PPP/R3KB1R w KQkq - 0 7",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Chigorin Line",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2np1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w - - 6 7",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Czech Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O c6",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w - - 0 7",
  },
  {
    eco: "B08",
    name: "Pirc Defense: Classical Variation, Quiet System, Parma Defense",
    pgn: "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Bg4",
    length: 12,
    fen: "rn1q1rk1/ppp1ppbp/3p1np1/8/3PP1b1/2N2N2/PPP1BPPP/R1BQ1RK1 w - - 6 7",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Carlsbad Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 e6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2n1pn2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Czerniak Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qa5",
    length: 12,
    fen: "r1b1kb1r/pp2pppp/2n2n2/q2p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq - 5 7",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Mieses Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Nf3 Bg4",
    length: 12,
    fen: "r2qkb1r/pp2pppp/2n2n2/3p4/2PP2b1/2N2N2/PP3PPP/R1BQKB1R w KQkq - 5 7",
  },
  {
    eco: "B13",
    name: "Caro-Kann Defense: Panov Attack, Modern Defense, Reifir-Spielmann Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qb6",
    length: 12,
    fen: "r1b1kb1r/pp2pppp/1qn2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq - 5 7",
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Fianchetto Defense, Fianchetto Gambit",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6 6. cxd5 Bg7",
    length: 12,
    fen: "rnbqk2r/pp2ppbp/5np1/3P4/3P4/2N5/PP3PPP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "B14",
    name: "Caro-Kann Defense: Panov Attack, Main Line",
    pgn: "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6 6. Nf3 Bb4",
    length: 12,
    fen: "rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R w KQkq - 2 7",
  },
  {
    eco: "B17",
    name: "Caro-Kann Defense: Karpov Variation, Tiviakov-Fischer Attack",
    pgn: "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Bc4 Ngf6 6. Nxf6+ Nxf6",
    length: 12,
    fen: "r1bqkb1r/pp2pppp/2p2n2/8/2BP4/8/PPP2PPP/R1BQK1NR w KQkq - 0 7",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Morphy Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bc5",
    length: 12,
    fen: "r1bqk1nr/pp1p1ppp/2n1p3/2b5/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Paulsen Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 a6",
    length: 12,
    fen: "r1bqkbnr/1p1p1ppp/p1n1p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Pin Defense",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bb4",
    length: 12,
    fen: "r1bqk1nr/pp1p1ppp/2n1p3/8/1bB1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Scheveningen Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 d6 6. Bc4 e6",
    length: 12,
    fen: "r1bqkbnr/pp3ppp/2npp3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B21",
    name: "Sicilian Defense: Smith-Morra Gambit Accepted, Taimanov Formation",
    pgn: "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Bc4 a6 6. Nf3 Ne7",
    length: 12,
    fen: "rnbqkb1r/1p1pnppp/p3p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B22",
    name: "Sicilian Defense: Alapin Variation, Barmen Defense, Central Exchange",
    pgn: "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 cxd4 5. cxd4 Nc6 6. Nf3 Bg4",
    length: 12,
    fen: "r3kbnr/pp2pppp/2n5/3q4/3P2b1/5N2/PP3PPP/RNBQKB1R w KQkq - 3 7",
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed, Botvinnik Defense, with Nge2",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Nge2 e5",
    length: 12,
    fen: "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4P3/2NP2P1/PPP1NPBP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B25",
    name: "Sicilian Defense: Closed, Botvinnik Defense, with f4",
    pgn: "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4 e5",
    length: 12,
    fen: "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP2P1/PPP3BP/R1BQK1NR w KQkq - 0 7",
  },
  {
    eco: "B27",
    name: "Sicilian Defense: Hyperaccelerated Pterodactyl, Exchange Variation",
    pgn: "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3 Qxc3+",
    length: 12,
    fen: "rnb1k1nr/pp1ppp1p/6p1/2P5/4P3/2q2N2/P1P2PPP/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "B29",
    name: "Sicilian Defense: Nimzowitsch Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 e6 5. Nxd5 exd5 6. d4 Nc6",
    length: 12,
    fen: "r1bqkb1r/pp1p1ppp/2n5/2ppP3/3P4/5N2/PPP2PPP/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "B30",
    name: "Sicilian Defense: Closed, Anti-Sveshnikov Variation, Kharlov-Kramnik Line",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. Nc3 e5 4. Bc4 Be7 5. d3 d6 6. Nd2 Bg5",
    length: 12,
    fen: "r1bqk1nr/pp3ppp/2np4/2p1p1b1/2B1P3/2NP4/PPPN1PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B33",
    name: "Sicilian Defense: Four Knights Variation, Cobra Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e6 6. Ndb5 Bc5",
    length: 12,
    fen: "r1bqk2r/pp1p1ppp/2n1pn2/1Nb5/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq - 2 7",
  },
  {
    eco: "B40",
    name: "Sicilian Defense: Pin Variation, Jaffe Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. Bd3 e5",
    length: 12,
    fen: "rnbqk2r/pp1p1ppp/5n2/4p3/1b1NP3/2NB4/PPP2PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B50",
    name: "Sicilian Defense: Delayed Alapin Variation, Basman-Palatnik Gambit",
    pgn: "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Be2 Nc6 5. d4 cxd4 6. cxd4 Nxe4",
    length: 12,
    fen: "r1bqkb1r/pp2pppp/2np4/8/3Pn3/5N2/PP2BPPP/RNBQK2R w KQkq - 0 7",
  },
  {
    eco: "B57",
    name: "Sicilian Defense: Classical Variation, Anti-Sozin Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 Qb6",
    length: 12,
    fen: "r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B58",
    name: "Sicilian Defense: Boleslavsky Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2 e5",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation, Dragon Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 g6",
    length: 12,
    fen: "r1bqkb1r/pp2pp1p/2np1np1/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq - 0 7",
  },
  {
    eco: "B60",
    name: "Sicilian Defense: Richter-Rauzer Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 Bd7",
    length: 12,
    fen: "r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq - 5 7",
  },
  {
    eco: "B62",
    name: "Sicilian Defense: Richter-Rauzer Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq - 0 7",
  },
  {
    eco: "B71",
    name: "Sicilian Defense: Dragon Variation, Levenfish Variation, Main Line",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4 Nbd7",
    length: 12,
    fen: "r1bqkb1r/pp1npp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "B83",
    name: "Sicilian Defense: Scheveningen Variation, Modern Variation",
    pgn: "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 d6 6. Be2 Nf6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "B84",
    name: "Sicilian Defense: Scheveningen Variation, Classical Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e6",
    length: 12,
    fen: "rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B88",
    name: "Sicilian Defense: Sozin Attack, Leonhardt Variation",
    pgn: "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "B90",
    name: "Sicilian Defense: Najdorf Variation, English Attack, Anti-English",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 Ng4",
    length: 12,
    fen: "rnbqkb1r/1p2pppp/p2p4/8/3NP1n1/2N1B3/PPP2PPP/R2QKB1R w KQkq - 2 7",
  },
  {
    eco: "B95",
    name: "Sicilian Defense: Najdorf Variation",
    pgn: "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6",
    length: 12,
    fen: "rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq - 0 7",
  },
  {
    eco: "C00",
    name: "French Defense: St. George Defense, Traditional Line",
    pgn: "1. e4 e6 2. d4 a6 3. Nf3 b5 4. Bd3 c5 5. c3 Bb7 6. O-O Nf6",
    length: 12,
    fen: "rn1qkb1r/1b1p1ppp/p3pn2/1pp5/3PP3/2PB1N2/PP3PPP/RNBQ1RK1 w kq - 3 7",
  },
  {
    eco: "C02",
    name: "French Defense: Advance Variation, Lputian Variation",
    pgn: "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3 Nh6",
    length: 12,
    fen: "r1b1kb1r/pp3ppp/1qn1p2n/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R w KQkq - 1 7",
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Botvinnik Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 b6",
    length: 12,
    fen: "rnbqkb1r/p2n1ppp/1p2p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR w KQkq - 0 7",
  },
  {
    eco: "C05",
    name: "French Defense: Tarrasch Variation, Closed Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6",
    length: 12,
    fen: "r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR w KQkq - 1 7",
  },
  {
    eco: "C07",
    name: "French Defense: Tarrasch Variation, Eliskases Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5 5. Ngf3 cxd4 6. Bc4 Qd8",
    length: 12,
    fen: "rnbqkbnr/pp3ppp/4p3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R w KQkq - 2 7",
  },
  {
    eco: "C11",
    name: "French Defense: Classical Variation, Burn Variation, Morozevich Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 dxe4 5. Nxe4 Be7 6. Bxf6 gxf6",
    length: 12,
    fen: "rnbqk2r/ppp1bp1p/4pp2/8/3PN3/8/PPP2PPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C11",
    name: "French Defense: Steinitz Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. dxc5 Nc6",
    length: 12,
    fen: "r1bqkb1r/pp1n1ppp/2n1p3/2PpP3/5P2/2N5/PPP3PP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Lasker Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Bxc3",
    length: 12,
    fen: "rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2b5/PPPB1PPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C12",
    name: "French Defense: MacCutcheon Variation, Tartakower Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Nfd7",
    length: 12,
    fen: "rnbqk2r/pppn1pp1/4p2p/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR w KQkq - 2 7",
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Breyer Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 c5",
    length: 12,
    fen: "rnbqk2r/pp1nbppp/4p3/2ppP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Maróczy Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 a6",
    length: 12,
    fen: "rnbqk2r/1ppnbppp/p3p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Spielmann Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 O-O",
    length: 12,
    fen: "rnbq1rk1/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQ - 1 7",
  },
  {
    eco: "C13",
    name: "French Defense: Alekhine-Chatard Attack, Teichmann Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 f6",
    length: 12,
    fen: "rnbqk2r/pppnb1pp/4pp2/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C13",
    name: "French Defense: Classical Variation, Frankfurt Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8 6. Be3 b6",
    length: 12,
    fen: "rnbqk1nr/p1p1bppp/1p2p3/3pP3/3P4/2N1B3/PPP2PPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C14",
    name: "French Defense: Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7",
    length: 12,
    fen: "rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C15",
    name: "French Defense: Winawer Variation, Alekhine Gambit, Kan Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+ 6. Nxc3 Nc6",
    length: 12,
    fen: "r1bqk1nr/ppp2ppp/2n1p3/8/3Pp3/P1N5/1PP2PPP/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Maróczy-Wallis Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 cxd4 6. axb4 dxc3",
    length: 12,
    fen: "rnbqk1nr/pp3ppp/4p3/3pP3/1P6/2p5/1PP2PPP/R1BQKBNR w KQkq - 0 7",
  },
  {
    eco: "C17",
    name: "French Defense: Winawer Variation, Retreat Variation, Armenian Line",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5 6. b4 cxd4",
    length: 12,
    fen: "rnbqk1nr/pp3ppp/4p3/b2pP3/1P1p4/P1N5/2P2PPP/R1BQKBNR w KQkq - 0 7",
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Classical Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qc7",
    length: 12,
    fen: "rnb1k1nr/ppq2ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "C18",
    name: "French Defense: Winawer Variation, Portisch-Hook Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qa5",
    length: 12,
    fen: "rnb1k1nr/pp3ppp/4p3/q1ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "C19",
    name: "French Defense: Winawer Variation, Advance Variation",
    pgn: "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7",
    length: 12,
    fen: "rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq - 1 7",
  },
  {
    eco: "C20",
    name: "King's Pawn Game: Wayward Queen Attack, Mellon Gambit",
    pgn: "1. e4 e5 2. Qh5 Nc6 3. Bc4 Nh6 4. d3 g6 5. Qf3 f6 6. Ne2 d5",
    length: 12,
    fen: "r1bqkb1r/ppp4p/2n2ppn/3pp3/2B1P3/3P1Q2/PPP1NPPP/RNB1K2R w KQkq - 0 7",
  },
  {
    eco: "C20",
    name: "King's Pawn Opening: Van Hooydoon Gambit",
    pgn: "1. e4 e5 2. Qe2 Nc6 3. c3 Nf6 4. Nf3 Bc5 5. d4 exd4 6. cxd4 Nxd4",
    length: 12,
    fen: "r1bqk2r/pppp1ppp/5n2/2b5/3nP3/5N2/PP2QPPP/RNB1KB1R w KQkq - 0 7",
  },
  {
    eco: "C24",
    name: "Bishop's Opening: Urusov Gambit, Panov Variation",
    pgn: "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 d5 5. exd5 Bb4+ 6. c3 Qe7+",
    length: 12,
    fen: "rnb1k2r/ppp1qppp/5n2/3P4/1bBp4/2P2N2/PP3PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Allgaier Gambit, Alapin Variation",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5 d6",
    length: 12,
    fen: "r1bqkbnr/ppp2p1p/2np4/6N1/4PppP/2N5/PPPP2P1/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "C25",
    name: "Vienna Gambit, with Max Lange Defense: Hamppe-Muzio Gambit",
    pgn: "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O gxf3",
    length: 12,
    fen: "r1bqkbnr/pppp1p1p/2n5/8/2B1Pp2/2N2p2/PPPP2PP/R1BQ1RK1 w kq - 0 7",
  },
  {
    eco: "C31",
    name: "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Morphy Defense",
    pgn: "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. exd5 e4 5. d3 Bb4 6. Bd2 e3",
    length: 12,
    fen: "rnbqk2r/ppp2ppp/5n2/3P4/1b3P2/2NPp3/PPPB2PP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Bishop's Gambit, Classical Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Ne7 5. Nc3 g5 6. d4 Bg7",
    length: 12,
    fen: "rnb1k2r/ppppnpbp/8/6p1/2BPPp1q/2N5/PPP3PP/R1BQ1KNR w kq - 1 7",
  },
  {
    eco: "C33",
    name: "King's Gambit Accepted: Lopez-Gianutio Countergambit, Hein Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Bc4 f5 4. Qe2 Qh4+ 5. Kd1 fxe4 6. Nc3 Kd8",
    length: 12,
    fen: "rnbk1bnr/pppp2pp/8/8/2B1pp1q/2N5/PPPPQ1PP/R1BK2NR w - - 2 7",
  },
  {
    eco: "C34",
    name: "King's Gambit Accepted: Greco Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 h6 5. d4 g5 6. h4 Bg7",
    length: 12,
    fen: "rnbqk1nr/ppp2pb1/3p3p/6p1/2BPPp1P/5N2/PPP3P1/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit Accepted, From's Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qe7",
    length: 12,
    fen: "rnb1kbnr/ppppqp1p/8/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq - 1 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Holloway Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Nc6",
    length: 12,
    fen: "r1bqkbnr/pppp1p1p/2n5/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq - 1 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Muzio Gambit, Sarratt Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6",
    length: 12,
    fen: "rnb1kbnr/pppp1p1p/5q2/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq - 1 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Cochrane Gambit",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 f3",
    length: 12,
    fen: "rnb1kbnr/pppp1p1p/8/4N3/2B1P1pq/5p2/PPPP2PP/RNBQ1K1R w kq - 0 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Santa Maria Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nf6",
    length: 12,
    fen: "rnb1kb1r/pppp1p1p/5n2/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq - 4 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Silberschmidt Defense",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nh6",
    length: 12,
    fen: "rnb1kb1r/pppp1p1p/7n/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq - 4 7",
  },
  {
    eco: "C37",
    name: "King's Gambit Accepted: Salvio Gambit, Viennese Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nc6",
    length: 12,
    fen: "r1b1kbnr/pppp1p1p/2n5/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq - 4 7",
  },
  {
    eco: "C39",
    name: "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, de Riviere Variation",
    pgn: "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Nxg4 d5",
    length: 12,
    fen: "rnbqkb1r/ppp2p1p/5n2/3p4/4PpNP/8/PPPP2P1/RNBQKB1R w KQkq - 0 7",
  },
  {
    eco: "C40",
    name: "Latvian Gambit: Corkscrew Gambit",
    pgn: "1. e4 e5 2. Nf3 f5 3. Nxe5 Nf6 4. Bc4 fxe4 5. Nf7 Qe7 6. Nxh8 d5",
    length: 12,
    fen: "rnb1kb1N/ppp1q1pp/5n2/3p4/2B1p3/8/PPPP1PPP/RNBQK2R w KQq - 0 7",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Lion's Claw, with Bc4 and h6",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. O-O h6",
    length: 12,
    fen: "r1bqk2r/pppnbpp1/3p1n1p/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq - 0 7",
  },
  {
    eco: "C41",
    name: "Philidor Defense: Lion Variation, Lion's Claw, with Be2 and c6",
    pgn: "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Be2 Be7 6. O-O c6",
    length: 12,
    fen: "r1bqk2r/pp1nbppp/2pp1n2/4p3/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w kq - 0 7",
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Marshall Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6",
    length: 12,
    fen: "rnbqk2r/ppp2ppp/3b4/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq - 2 7",
  },
  {
    eco: "C42",
    name: "Russian Game: Classical Attack, Mason-Showalter Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6",
    length: 12,
    fen: "r1bqkb1r/ppp2ppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq - 2 7",
  },
  {
    eco: "C43",
    name: "Russian Game: Modern Attack, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2 Nc5 6. Nxd4 Nc6",
    length: 12,
    fen: "r1bqkb1r/pppp1ppp/2n5/2n1P3/3N4/8/PPP1QPPP/RNB1KB1R w KQkq - 1 7",
  },
  {
    eco: "C44",
    name: "Scotch Game: Göring Gambit, Bardeleben Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4 Nf6 6. Nxc3 Bb4",
    length: 12,
    fen: "r1bqk2r/pppp1ppp/2n2n2/8/1bB1P3/2N2N2/PP3PPP/R1BQK2R w KQkq - 1 7",
  },
  {
    eco: "C44",
    name: "Scotch Game: Scotch Gambit, Cochrane-Anderssen Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. O-O d6 6. c3 Bg4",
    length: 12,
    fen: "r2qk1nr/ppp2ppp/2np4/2b5/2BpP1b1/2P2N2/PP3PPP/RNBQ1RK1 w kq - 1 7",
  },
  {
    eco: "C45",
    name: "Scotch Game: Classical Variation, Millennium Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Qg6",
    length: 12,
    fen: "r1b1k1nr/pppp1ppp/2n3q1/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R w KQkq - 1 7",
  },
  {
    eco: "C47",
    name: "Four Knights Game: Scotch Variation, Belgrade Gambit, Modern Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5 Nxe4 6. Qe2 f5",
    length: 12,
    fen: "r1bqkb1r/pppp2pp/2n5/3N1p2/3pn3/5N2/PPP1QPPP/R1B1KB1R w KQkq - 0 7",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Marshall Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Ba4 Bc5 6. Nxe5 O-O",
    length: 12,
    fen: "r1bq1rk1/pppp1ppp/5n2/2b1N3/B2nP3/2N5/PPPP1PPP/R1BQK2R w KQ - 1 7",
  },
  {
    eco: "C48",
    name: "Four Knights Game: Spanish Variation, Rubinstein Variation, Marshall Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxe5 Bc5 6. O-O O-O",
    length: 12,
    fen: "r1bq1rk1/pppp1ppp/5n2/1Bb1N3/3nP3/2N5/PPPP1PPP/R1BQ1RK1 w - - 3 7",
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Bxc3",
    length: 12,
    fen: "r1bq1rk1/pppp1ppp/2n2n2/1B2p3/4P3/2bP1N2/PPP2PPP/R1BQ1RK1 w - - 0 7",
  },
  {
    eco: "C49",
    name: "Four Knights Game: Spanish Variation, Symmetrical Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6",
    length: 12,
    fen: "r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1 w - - 0 7",
  },
  {
    eco: "C50",
    name: "Italian Game: Hungarian Defense, Tartakower Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4 5. c3 Nf6 6. e5 Ne4",
    length: 12,
    fen: "r1bqk2r/ppppbppp/2n5/4P3/2Bpn3/2P2N2/PP3PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Hirschbach Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Qg5",
    length: 12,
    fen: "r1b1k1nr/pppp1ppp/1b6/nP2N1q1/2B1P3/8/P1PP1PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit Declined, Lange Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Nh6",
    length: 12,
    fen: "r1bqk2r/pppp1ppp/1b5n/nP2N3/2B1P3/8/P1PP1PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C51",
    name: "Italian Game: Evans Gambit, Anderssen Variation, Cordel Line",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7 6. d4 Na5",
    length: 12,
    fen: "r1bqk1nr/ppppbppp/8/n3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6",
    length: 12,
    fen: "r1bqk1nr/ppp2ppp/2np4/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Bronstein Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6",
    length: 12,
    fen: "r1bqk1nr/ppp2ppp/2np4/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq - 0 7",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Laroche Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 Nf6",
    length: 12,
    fen: "r1bqk2r/pppp1ppp/2n2n2/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Leonhardt Countergambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 b5",
    length: 12,
    fen: "r1bqk1nr/p1pp1ppp/2n5/bp2p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq - 0 7",
  },
  {
    eco: "C52",
    name: "Italian Game: Evans Gambit, Pierce Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4",
    length: 12,
    fen: "r1bqk1nr/pppp1ppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQK2R w KQkq - 0 7",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, Greco Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. c3 Nf6 6. e5 d5",
    length: 12,
    fen: "r1bqk2r/ppp2ppp/2n2n2/2bpP3/2Bp4/2P2N2/PP3PPP/RNBQK2R w KQkq d6 0 7",
  },
  {
    eco: "C53",
    name: "Italian Game: Classical Variation, La Bourdonnais Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 d6 5. d4 exd4 6. cxd4 Bb6",
    length: 12,
    fen: "r1bqk1nr/ppp2ppp/1bnp4/8/2BPP3/5N2/PP3PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C55",
    name: "Italian Game: Scotch Gambit, Max Lange Attack, Spielmann Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5 Ng4",
    length: 12,
    fen: "r1bqk2r/pppp1ppp/2n5/2b1P3/2Bp2n1/5N2/PPP2PPP/RNBQ1RK1 w kq - 1 7",
  },
  {
    eco: "C57",
    name: "Italian Game: Two Knights Defense, Pincus Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4 Bb4+",
    length: 12,
    fen: "r1bqk2r/ppp2ppp/2n5/3np1N1/1bBP4/8/PPP2PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C64",
    name: "Ruy Lopez: Classical Defense, Benelux Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3 O-O 6. d4 Bb6",
    length: 12,
    fen: "r1bq1rk1/pppp1ppp/1bn2n2/1B2p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1 w - - 1 7",
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Closed Wolf Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 exd4",
    length: 12,
    fen: "r2qkb1r/pppb1ppp/2np1n2/1B6/3pP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq - 0 7",
  },
  {
    eco: "C66",
    name: "Ruy Lopez: Berlin Defense, Hedgehog Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 Be7",
    length: 12,
    fen: "r2qk2r/pppbbppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq - 3 7",
  },
  {
    eco: "C67",
    name: "Ruy Lopez: Berlin Defense, Trifunovic Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 d5",
    length: 12,
    fen: "r1bqk2r/ppp1bppp/2n5/1B1pp3/3Pn3/5N2/PPP1QPPP/RNB2RK1 w kq - 0 7",
  },
  {
    eco: "C69",
    name: "Ruy Lopez: Exchange Variation, Alapin Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bg4 6. h3 h5",
    length: 12,
    fen: "r2qkbnr/1pp2pp1/p1p5/4p2p/4P1b1/5N1P/PPPP1PP1/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "C73",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4 f6",
    length: 12,
    fen: "r1bqkbnr/2p3pp/p1pp1p2/4p3/3PP3/5N2/PPP2PPP/RNBQK2R w KQkq - 0 7",
  },
  {
    eco: "C75",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7 6. d4 Nge7",
    length: 12,
    fen: "r2qkb1r/1ppbnppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C76",
    name: "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Fianchetto Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 a6 5. Ba4 d6 6. d4 Bd7",
    length: 12,
    fen: "r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R w KQkq - 1 7",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 d6",
    length: 12,
    fen: "r1bqkb1r/2p2ppp/p1np1n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "C78",
    name: "Ruy Lopez: Morphy Defense, Arkhangelsk Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 Bb7",
    length: 12,
    fen: "r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1 w kq - 2 7",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Riga Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 exd4",
    length: 12,
    fen: "r1bqkb1r/1ppp1ppp/p1n5/8/B2pn3/5N2/PPP2PPP/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "C80",
    name: "Ruy Lopez: Open, Skipworth Gambit",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Re1 d5",
    length: 12,
    fen: "r1bqkb1r/1pp2ppp/p1n5/3pp3/B3n3/5N2/PPPP1PPP/RNBQR1K1 w kq - 0 7",
  },
  {
    eco: "C87",
    name: "Ruy Lopez: Closed, Averbakh Variation",
    pgn: "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 d6",
    length: 12,
    fen: "r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 w kq - 0 7",
  },
  {
    eco: "D14",
    name: "Slav Defense: Exchange Variation, Symmetrical Line",
    pgn: "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. Nf3 Nc6 6. Bf4 Bf5",
    length: 12,
    fen: "r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R w KQkq - 6 7",
  },
  {
    eco: "D17",
    name: "Slav Defense: Czech Variation, Wiesbaden Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 e6",
    length: 12,
    fen: "rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "D18",
    name: "Slav Defense: Czech Variation, Lasker Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 Na6",
    length: 12,
    fen: "r2qkb1r/pp2pppp/n1p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Development Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O Nc6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 w kq - 2 7",
  },
  {
    eco: "D26",
    name: "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Exchange Variation",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O cxd4",
    length: 12,
    fen: "rnbqkb1r/pp3ppp/4pn2/8/2Bp4/4PN2/PP3PPP/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "D27",
    name: "Queen's Gambit Accepted: Classical Defense, Main Line",
    pgn: "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6",
    length: 12,
    fen: "rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 w kq - 0 7",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Semmering Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7 6. Bd3 c5",
    length: 12,
    fen: "r1bqkb1r/pp1n1ppp/4pn2/2pp4/2PP4/3BPN2/PP1N1PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "D30",
    name: "Queen's Gambit Declined: Stonewall Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Ne4 6. Bd3 f5",
    length: 12,
    fen: "rnbqkb1r/pp4pp/2p1p3/3p1p2/2PPn3/3BPN2/PP1N1PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "D32",
    name: "Tarrasch Defense: Tarrasch Gambit",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. dxc5 d4 6. Na4 b5",
    length: 12,
    fen: "rnbqkbnr/p4ppp/8/1pP5/N2p4/8/PP2PPPP/R1BQKBNR w KQkq b6 0 7",
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Prague Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6",
    length: 12,
    fen: "r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R w KQkq - 1 7",
  },
  {
    eco: "D33",
    name: "Tarrasch Defense: Swedish Variation",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 c4",
    length: 12,
    fen: "r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Fianchetto Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bf4 O-O 6. e3 b6",
    length: 12,
    fen: "rnbq1rk1/p1p1bppp/1p2pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ - 0 7",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Orthodox Defense",
    pgn: "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 Be7 5. Bf4 O-O 6. e3 c6",
    length: 12,
    fen: "rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ - 0 7",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Harrwitz Attack, Two Knights Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 Nbd7",
    length: 12,
    fen: "r1bq1rk1/pppnbppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ - 1 7",
  },
  {
    eco: "D37",
    name: "Queen's Gambit Declined: Knight Defense, Alekhine Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. Bg5 h6 6. Bh4 dxc4",
    length: 12,
    fen: "r1bqkb1r/pppn1pp1/4pn1p/8/2pP3B/2N2N2/PP2PPPP/R2QKB1R w KQkq - 0 7",
  },
  {
    eco: "D38",
    name: "Queen's Gambit Declined: Westphalian Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 Nbd7 6. e3 c5",
    length: 12,
    fen: "r1bqk2r/pp1n1ppp/4pn2/2pp2B1/1bPP4/2N1PN2/PP3PPP/R2QKB1R w KQkq - 0 7",
  },
  {
    eco: "D45",
    name: "Semi-Slav Defense: Stonewall Defense",
    pgn: "1. d4 d5 2. Nf3 Nf6 3. c4 c6 4. Nc3 e6 5. e3 Ne4 6. Bd3 f5",
    length: 12,
    fen: "rnbqkb1r/pp4pp/2p1p3/3p1p2/2PPn3/2NBPN2/PP3PPP/R1BQK2R w KQkq - 0 7",
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Bogoljubov Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Be7",
    length: 12,
    fen: "r1bqk2r/pp1nbppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R w KQkq - 4 7",
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Chigorin Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bd6",
    length: 12,
    fen: "r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R w KQkq - 4 7",
  },
  {
    eco: "D46",
    name: "Semi-Slav Defense: Romih Variation",
    pgn: "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bb4",
    length: 12,
    fen: "r1bqk2r/pp1n1ppp/2p1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQkq - 4 7",
  },
  {
    eco: "D52",
    name: "Queen's Gambit Declined: Cambridge Springs Defense",
    pgn: "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5",
    length: 12,
    fen: "r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQkq - 1 7",
  },
  {
    eco: "D55",
    name: "Queen's Gambit Declined: Neo-Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 h6",
    length: 12,
    fen: "rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQ - 0 7",
  },
  {
    eco: "D60",
    name: "Queen's Gambit Declined: Orthodox Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7",
    length: 12,
    fen: "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQ - 1 7",
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation, Modern Defense",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 dxc4",
    length: 12,
    fen: "rnbq1rk1/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 0 7",
  },
  {
    eco: "D77",
    name: "Neo-Grünfeld Defense: Classical Variation, Polgar Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2n2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 1 7",
  },
  {
    eco: "D78",
    name: "Neo-Grünfeld Defense: Classical Variation, Original Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 c6 6. O-O d5",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 0 7",
  },
  {
    eco: "D80",
    name: "Grünfeld Defense: Lundin Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5 Ne4 5. Nxe4 dxe4 6. Qd2 c5",
    length: 12,
    fen: "rnbqkb1r/pp2pp1p/6p1/2p3B1/2PPp3/8/PP1QPPPP/R3KBNR w KQkq - 0 7",
  },
  {
    eco: "D95",
    name: "Grünfeld Defense: Botvinnik Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3 e6",
    length: 12,
    fen: "rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R w KQ - 0 7",
  },
  {
    eco: "E04",
    name: "Catalan Opening: Open Defense, Modern Sharp Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Nc6 6. Qa4 Bb4+",
    length: 12,
    fen: "r1bqk2r/ppp2ppp/2n1pn2/8/QbpP4/5NP1/PP2PPBP/RNB1K2R w KQkq - 4 7",
  },
  {
    eco: "E07",
    name: "Catalan Opening: Closed",
    pgn: "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O Nbd7",
    length: 12,
    fen: "r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 6 7",
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit Accepted",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. dxe6 fxe6 6. cxb5 d5",
    length: 12,
    fen: "rnbqkb1r/p5pp/4pn2/1Ppp4/8/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 7",
  },
  {
    eco: "E10",
    name: "Blumenfeld Countergambit: Spielmann Variation",
    pgn: "1. d4 Nf6 2. Nf3 e6 3. c4 c5 4. d5 b5 5. Bg5 exd5 6. cxd5 h6",
    length: 12,
    fen: "rnbqkb1r/p2p1pp1/5n1p/1ppP2B1/8/5N2/PP2PPPP/RN1QKB1R w KQkq - 0 7",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Classical Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 exd5",
    length: 12,
    fen: "rn1qkb1r/pbp2ppp/1p3n2/3p4/3P4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "E12",
    name: "Queen's Indian Defense: Kasparov-Petrosian Variation, Modern Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5",
    length: 12,
    fen: "rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq - 0 7",
  },
  {
    eco: "E13",
    name: "Queen's Indian Defense: Kasparov Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 h6 6. Bh4 Bb7",
    length: 12,
    fen: "rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R w KQkq - 2 7",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation, Intermezzo Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Be7",
    length: 12,
    fen: "rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R w KQkq - 3 7",
  },
  {
    eco: "E15",
    name: "Queen's Indian Defense: Fianchetto Variation, Check Variation, Modern Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Qe7",
    length: 12,
    fen: "rn2k2r/p1ppqppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R w KQkq - 3 7",
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Riumin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+ 6. Bd2 Be7",
    length: 12,
    fen: "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP1BPPBP/RN1QK2R w KQkq - 5 7",
  },
  {
    eco: "E16",
    name: "Queen's Indian Defense: Yates Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5 5. g3 b6 6. Bg2 Bb7",
    length: 12,
    fen: "rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/5NP1/PP1BPPBP/RN1QK2R w KQkq - 2 7",
  },
  {
    eco: "E26",
    name: "Nimzo-Indian Defense: Sämisch Variation, O'Kelly Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3 b6",
    length: 12,
    fen: "rnbqk2r/p2p1ppp/1p2pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR w KQkq - 0 7",
  },
  {
    eco: "E30",
    name: "Nimzo-Indian Defense: Leningrad Variation, Averbakh Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 b5",
    length: 12,
    fen: "rnbqk2r/p2p1pp1/4pn1p/1ppP4/1bP4B/2N5/PP2PPPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "E31",
    name: "Nimzo-Indian Defense: Leningrad Variation, Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 d6",
    length: 12,
    fen: "rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR w KQkq - 0 7",
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation, Keres Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b6",
    length: 12,
    fen: "rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQ - 0 7",
  },
  {
    eco: "E32",
    name: "Nimzo-Indian Defense: Classical Variation, Vitolins-Adorjan Gambit",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b5",
    length: 12,
    fen: "rnbq1rk1/p1pp1ppp/4pn2/1p6/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQ - 0 7",
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation, Botvinnik Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Nc6",
    length: 12,
    fen: "r1bqk2r/ppp2ppp/2n1pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQkq - 1 7",
  },
  {
    eco: "E36",
    name: "Nimzo-Indian Defense: Classical Variation, Noa Variation, Main Line",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Ne4",
    length: 12,
    fen: "rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P1Q5/1P2PPPP/R1B1KBNR w KQkq - 1 7",
  },
  {
    eco: "E46",
    name: "Nimzo-Indian Defense: Simagin Variation",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2 d5 6. a3 Bd6",
    length: 12,
    fen: "rnbq1rk1/ppp2ppp/3bpn2/3p4/2PP4/P1N1P3/1P2NPPP/R1BQKB1R w KQ - 1 7",
  },
  {
    eco: "E52",
    name: "Nimzo-Indian Defense: Normal Variation, Schlechter Defense",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 b6",
    length: 12,
    fen: "rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQ - 0 7",
  },
  {
    eco: "E53",
    name: "Nimzo-Indian Defense: Normal Variation, Gligoric System",
    pgn: "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5",
    length: 12,
    fen: "rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQ - 0 7",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Fianchetto Variation, Yugoslav System",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c5",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - - 0 7",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Semi-Classical Variation, Benoni Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2 c6",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R w KQ - 0 7",
  },
  {
    eco: "E60",
    name: "King's Indian Defense: Semi-Classical Variation, Hollywood Variation",
    pgn: "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 O-O 5. e3 d6 6. Be2 Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R w KQ - 2 7",
  },
  {
    eco: "E62",
    name: "King's Indian Defense: Fianchetto Variation, Karlsbad Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ - 2 7",
  },
  {
    eco: "E64",
    name: "King's Indian Defense: Fianchetto Variation, Yugoslav Variation, Rare Line",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 c5",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ - 0 7",
  },
  {
    eco: "E67",
    name: "King's Indian Defense: Fianchetto Variation, Debrecen Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nbd7",
    length: 12,
    fen: "r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ - 2 7",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Flexible Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 h6",
    length: 12,
    fen: "rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 0 7",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Geller Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nbd7",
    length: 12,
    fen: "r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 4 7",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Modern Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Na6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 4 7",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Nc6 Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2np1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 4 7",
  },
  {
    eco: "E73",
    name: "King's Indian Defense: Averbakh Variation, Spanish Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 a6",
    length: 12,
    fen: "rnbq1rk1/1pp1ppbp/p2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 0 7",
  },
  {
    eco: "E74",
    name: "King's Indian Defense: Averbakh Variation, Benoni Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ - 0 7",
  },
  {
    eco: "E81",
    name: "King's Indian Defense: Sämisch Variation, Sämisch Gambit",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 c5",
    length: 12,
    fen: "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ - 0 7",
  },
  {
    eco: "E82",
    name: "King's Indian Defense: Sämisch Variation, Double Fianchetto",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 b6",
    length: 12,
    fen: "rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ - 0 7",
  },
  {
    eco: "E83",
    name: "King's Indian Defense: Sämisch Variation, Yates Defense",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ - 3 7",
  },
  {
    eco: "E85",
    name: "King's Indian Defense: Sämisch Variation, Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5",
    length: 12,
    fen: "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ - 0 7",
  },
  {
    eco: "E91",
    name: "King's Indian Defense: Kazakh Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 Na6",
    length: 12,
    fen: "r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQ - 4 7",
  },
  {
    eco: "E92",
    name: "King's Indian Defense: Orthodox Variation",
    pgn: "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5",
    length: 12,
    fen: "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQ - 0 7",
  },
];
