import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import { darken } from "@mui/system";
import { customAds } from "../data/customAds/customAds";

import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import ChessableIcon from "../icons/ChessableIcon";
import PatreonIcon from "../icons/PatreonIcon";
import TwitchIcon from "../icons/TwitchIcon";

const PromotionComponent = () => {
  const [isGoogleAd, setIsGoogleAd] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const socialIcons = {
    YouTube: {
      icon: <YouTubeIcon />,
      color: "#FF0000", // Red for YouTube
      tooltip: "Visit YouTube",
    },
    Website: {
      icon: <LanguageIcon />,
      color: "#000000", // Black for Website
      tooltip: "Visit Website",
    },
    Chessable: {
      icon: <ChessableIcon />,
      color: "#024fdf", // Blue for Chessable
      tooltip: "Visit Chessable",
    },
    Patreon: {
      icon: <PatreonIcon />,
      color: "#000000", // Black for Patreon
      tooltip: "Visit Patreon",
    },
    Twitch: {
      icon: <TwitchIcon />,
      color: "#9146FF", // Black for Patreon
      tooltip: "Visit Twitch",
    },
  };

  useEffect(() => {
    // Determine randomly whether to show a Google Ad or a custom ad
    const showGoogleAd = Math.random() < 0; // 50% chance

    if (showGoogleAd) {
      setIsGoogleAd(true);
    } else {
      // Select a random custom ad
      const randomAd = customAds[Math.floor(Math.random() * customAds.length)];
      setIsGoogleAd(false);
      setSelectedAd(randomAd);
    }
  }, []);

  // Render Google Ad
  if (isGoogleAd) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "#f9f9f9",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: "block",
            width: "100%",
            height: "100px",
          }}
          data-ad-client="ca-pub-7896462974654815"
          data-ad-slot="2285489852"
          data-ad-format="auto"
          data-full-width-responsive="false"
        ></ins>
      </Box>
    );
  }

  // Render Custom Ad
  return selectedAd ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundImage: `url('${process.env.PUBLIC_URL}/img/ads/adsbackground.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Ad Image */}
      <Box
        sx={{
          width: "auto",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/ads/${selectedAd.image}`}
          alt={selectedAd.name}
          style={{
            height: "100%",
            aspectRatio: "1",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* Ad Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          gap: 1,
        }}
      >
        {/* Title and Name */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          {selectedAd.title && (
            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "4px",
                padding: "0px 5px",
                fontWeight: "bold",
              }}
            >
              {selectedAd.title}
            </Box>
          )}
          <Typography sx={{ color: "black", fontWeight: "bold", mt: 0.05 }}>
            {selectedAd.name}
          </Typography>
        </Box>

        {/* Social Media Buttons */}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {selectedAd.socials.map((social, index) => {
            const { icon, color, tooltip } = socialIcons[social.type] || {
              icon: <LanguageIcon />,
              color: "#000000",
              tooltip: "Visit",
            };

            return (
              <Tooltip key={index} title={tooltip} arrow>
                <IconButton
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    width: "35px",
                    height: "35px",
                    backgroundColor: color,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: darken(color, 0.2),
                    },
                    borderRadius: "50%",
                    padding: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    "&:active": {
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  {icon}
                </IconButton>
              </Tooltip>
            );
          })}
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default PromotionComponent;
