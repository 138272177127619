import React from "react";
import { SvgIcon } from "@mui/material";

function KnightIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(1.3) translate(-150, -150)">
        <path d="m600 259.93c187.65 0 340.07 152.42 340.07 340.07s-152.42 340.07-340.07 340.07-340.07-152.42-340.07-340.07 152.42-340.07 340.07-340.07zm0 28.34c-172.33 0-311.73 139.4-311.73 311.73s139.4 311.73 311.73 311.73 311.73-139.4 311.73-311.73-139.4-311.73-311.73-311.73z" />
        <path d="m600 316.61c75.16 0 147.24 29.859 200.39 83.004 53.148 53.145 83.004 125.23 83.004 200.38 0.003906 49.746-13.09 98.617-37.961 141.7-24.875 43.082-60.648 78.859-103.73 103.73-43.082 24.875-91.953 37.969-141.7 37.969s-98.617-13.094-141.7-37.969c-43.082-24.871-78.859-60.648-103.73-103.73s-37.965-91.953-37.965-141.7c0-75.156 29.859-147.24 83.004-200.38 53.148-53.145 125.23-83.004 200.39-83.004zm19.207 75.805-46.816 51.812h-20.145l-17.555-16.309-58.273 94.723v167.95h13.137v12.188h9.3477v14.609h-21.742v75.598h-5.8203v14.609h223.22v-14.609h-5.832v-75.598h-21.766v-14.609h21.023v-34.594l-91.918-97.578v-22.859l100.35 40.695 32.23-36.168-80.117-84.262-30.68-17.449 1.3477-58.16z" />
      </g>
    </SvgIcon>
  );
}

export default KnightIcon;
