import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  //useTheme,
  Alert,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  handleGoogleSignIn,
  handleEmailLogin,
  handleEmailSignUp,
  handlePasswordReset,
} from "../../../features/Authentication";
//import { tokens } from "../../../styles/theme";
import GoogleIcon from "../../../icons/GoogleIcon";
import Turnstile from "react-turnstile";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  const { control, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [captchaResponse, setCaptchaResponse] = useState("");

  // Get the "from" location or default to "/"
  const from = location.state?.from?.pathname || "/";

  const handleGoogleLogin = async () => {
    const result = await handleGoogleSignIn();
    if (result) {
      navigate(from); // Redirect to the previous page
    } else {
      setError(result.error);
    }
  };

  const onSubmit = async () => {
    if (!captchaResponse) {
      setError("Please complete the CAPTCHA.");
      return;
    }
    try {
      if (isCreatingAccount) {
        if (password !== confirmPassword) {
          setError("Passwords do not match.");
          return;
        }
        const result = await handleEmailSignUp(email, password);
        if (result) {
          setMessage("Verification email sent. Please check your inbox.");
          setIsCreatingAccount(false); // Switch to login view
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setError("");
        } else {
          setError(result.error);
        }
      } else {
        const result = await handleEmailLogin(email, password);
        if (result.success) {
          navigate(from); // Redirect to the previous page
        } else {
          setError(result.error);
        }
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handlePasswordResetRequest = async () => {
    try {
      const result = await handlePasswordReset(email);
      if (result) {
        setMessage("Password reset email sent!");
        setIsResettingPassword(false);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        padding: 2, // Optional padding for smaller screens
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium+
          width: "800px", // Total width of the container
          maxWidth: "90%", // Ensure it doesn't overflow on smaller screens
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional shadow
          borderRadius: 10, // Optional rounded corners
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: "rgba(0, 0, 0, 1)", // Card background color
          color: "white", // Text color
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            border: "1px solid rgba(0, 0, 0, 0.5)", // Red outline
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 0, // Padding for the card content
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "40%" }, // Full width on small screens, 40% on medium+
            display: "flex",
            flexDirection: "column", // Stack items vertically
            justifyContent: "top",
            alignItems: "center",
            backgroundColor: "#000000", // Optional: Light background for the image section
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
            alt="Chessboard Magic"
            style={{ maxWidth: "90%", height: "auto" }}
          />
          <Typography variant="h5">Chessboard Magic</Typography>
        </Box>

        {/* Login Details Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "60%" }, // Full width on small screens, 60% on medium+
            padding: 4, // Padding for spacing
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#000000",
          }}
        >
          {/* Alerts */}
          {error && (
            <Alert
              severity="error"
              sx={{ marginBottom: 2 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setError("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          )}
          {message && (
            <Alert
              severity="success"
              sx={{ marginBottom: 2 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setMessage("")}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ marginBottom: 2 }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  fullWidth
                  autoComplete="email"
                />
              )}
            />
            {!isResettingPassword && (
              <>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      variant="filled"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{ marginBottom: 2 }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      fullWidth
                      autoComplete="current-password"
                    />
                  )}
                />
                {isCreatingAccount && (
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Confirm Password"
                        variant="filled"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ marginBottom: 2 }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        fullWidth
                        autoComplete="new-password"
                      />
                    )}
                  />
                )}
              </>
            )}
            <Turnstile
              sitekey="0x4AAAAAAAgKQaDETBl-vYMI"
              onVerify={(response) => setCaptchaResponse(response)}
              onError={() => setCaptchaResponse("")}
              onExpire={() => setCaptchaResponse("")}
              size="flexible"
            />
            {isResettingPassword ? (
              <Button
                variant="contained"
                onClick={handlePasswordResetRequest}
                sx={{ marginTop: 2 }}
                fullWidth
              >
                Reset Password
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: 2 }}
                fullWidth
              >
                {isCreatingAccount ? "Sign Up" : "Log In"}
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setIsCreatingAccount(!isCreatingAccount);
                  setMessage("");
                  setError("");
                }}
              >
                {isCreatingAccount
                  ? "Already have an account? Log In"
                  : "Create a new account"}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsResettingPassword(!isResettingPassword);
                  setMessage("");
                  setError("");
                }}
              >
                {isResettingPassword ? "Back to Log In" : "Forgot Password?"}
              </Button>
            </Box>
            <Divider sx={{ marginY: 3, width: "100%" }}>OR</Divider>
            <Button
              variant="contained"
              onClick={handleGoogleLogin}
              sx={{ width: "100%" }}
            >
              <GoogleIcon /> Log In with Google
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
