import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import {
  Box,
  Button,
  Typography,
  TextField,
  Alert,
  useTheme,
  Collapse,
} from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Helmet } from "react-helmet";
import { usergames } from "../../../data/usergames/usergames";
import { worldChampionshipGames } from "../../../data/historicgames/worldChampionship";
import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import { updateUserData } from "../../../features/Firestore";
import { openings } from "../../../data/eco/eco";
import NotationTrainerHelp from "../../../help/NotationTrainerHelp";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function NotationTrainer() {
  const [game, setGame] = useState(new Chess());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [moveIndex, setMoveIndex] = useState(0);
  const [pgnMoves, setPgnMoves] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const { gameId } = useParams();
  const { userData, userRef } = useUser();
  const [userMoves, setUserMoves] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const [openingName, setOpeningName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [showAlert, setShowAlert] = useState(false);
  const [moveTimes, setMoveTimes] = useState([]);
  const inputRef = useRef(null);
  const [completed, setCompleted] = useState(false);
  const [gameName, setGameName] = useState("");
  const [wrongAttempts, setWrongAttempts] = useState(0);
  const [wrongGuesses, setWrongGuesses] = useState([]);
  const [arrow, setArrow] = useState(null);

  const { setShareData } = useShare();
  const { t } = useTranslation("Learn");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/notationtrainer",
      title: "Chessboard Magic - Chess Notation Trainer",
      description:
        "Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const formattedWorldChampionshipGames = useMemo(
    () => transformGames(worldChampionshipGames),
    []
  );
  const allGames = useMemo(
    () => [...formattedWorldChampionshipGames],
    [formattedWorldChampionshipGames]
  );

  function transformGames(gamesData) {
    let transformedGames = [];

    gamesData.forEach((event) => {
      event.Games.forEach((game) => {
        if (game.Moves.length < 10) {
          // Skip games with less than 10 characters in the Moves string
          return;
        }
        const year = game.Date.split(".")[0]; // Extract the year from the date

        // Function to convert name from "Last, First Middle" to "F. Last"
        const formatName = (fullName) => {
          const names = fullName.split(", ");
          const lastName = names[0];
          const firstNameParts = names[1].split(" ");
          const initials = firstNameParts
            .map((name) => name[0] + ".")
            .join(" ");
          return `${initials} ${lastName}`;
        };

        const whiteName = formatName(game.White);
        const blackName = formatName(game.Black);

        const gameName = `World Championship ${year} - ${whiteName} vs ${blackName} (Round ${game.Round})`;

        transformedGames.push({
          Name: gameName,
          Site: event.Site,
          Date: game.Date,
          White: game.White,
          Black: game.Black,
          Result: game.Result,
          WhiteElo: game.WhiteElo,
          BlackElo: game.BlackElo,
          PlyCount: game.PlyCount,
          Moves: game.Moves,
        });
      });
    });

    return transformedGames;
  }

  const handleNewGame = useCallback(
    (gameId) => {
      let game;

      const numericGameId = Number(gameId);
      if (
        !isNaN(numericGameId) &&
        numericGameId >= 0 &&
        numericGameId < usergames.length
      ) {
        game = allGames[numericGameId];
      } else {
        // Otherwise, pick a random game
        let randomNumber = getRandomNumber(allGames.length);
        game = allGames[randomNumber];
      }

      const newGame = new Chess();
      newGame.loadPgn(game.Moves);
      let newPgnMoves = newGame.history();

      setPgnMoves(newPgnMoves);
      setGameName(game.Name);
      // Just getting the list of moves.

      newGame.reset(); // Reset the Board
      newGame.move(newPgnMoves[0]);
      const lastMove = newGame.history({ verbose: true }).slice(-1)[0];
      const from = lastMove.from;
      const to = lastMove.to;

      // Update arrow state
      setArrow([[from, to, "red"]]);
      setGame(newGame); // Update the game state with the new game
      setMoveIndex(0);
      setCompleted(false);
      setUserMoves([]);
      setCurrentInput("");
      setOpeningName(""); // Reset opening name
      setGameStarted(true);
      setMoveTimes([]); // Reset move times
      setWrongAttempts(0); // Reset wrong attempts
      setWrongGuesses(Array(newPgnMoves.length).fill("")); // Initialize wrong guesses with empty strings

      if (inputRef.current) {
        inputRef.current.focus(); // Focus the input field
      }

      // GA Tracking
      trackEvent("Games", "NotationTrainer-Play", "Notation Trainer");
      // Internal Tracking
      if (userData) {
        if (!userData.Puzzles) {
          userData.Puzzles = {};
        }
        if (userData.Puzzles.NotationTrainer) {
          userData.Puzzles.NotationTrainer.Played =
            (userData.Puzzles.NotationTrainer.Played || 0) + 1;
        } else {
          userData.Puzzles.NotationTrainer = {
            Played: 1,
            Completed: 0,
          };
        }
        updateUserData(userRef, userData);
      }
    },
    [userData, userRef, allGames]
  );

  useEffect(() => {
    if (gameId) {
      handleNewGame(gameId);
    }
  }, [gameId, handleNewGame]);

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  const handleInputChange = (e) => {
    setCurrentInput(e.target.value);
  };

  const updateOpeningName = useCallback((newUserMoves) => {
    const formattedMoves = newUserMoves
      .map((move, index) =>
        index % 2 === 0 ? `${Math.floor(index / 2) + 1}. ${move}` : move
      )
      .join(" ");

    const matchingOpenings = openings.filter(
      (opening) => formattedMoves.toUpperCase() === opening.pgn.toUpperCase()
    );
    if (matchingOpenings.length > 0) {
      setOpeningName(matchingOpenings[0].name);
    }
  }, []);

  const handleInputSubmit = useCallback(() => {
    const currentTime = new Date();
    if (
      currentInput.trim().toUpperCase() === pgnMoves[moveIndex].toUpperCase()
    ) {
      const newUserMoves = [...userMoves, currentInput];

      // Check if the game is completed before making the next move
      if (newUserMoves.length === pgnMoves.length) {
        setCompleted(true);
        setGameStarted(false);
        // GA Tracking
        trackEvent("Games", "NotationTrainer-Success", "NotationTrainer");
        // Internal Tracking
        if (userData) {
          userData.Puzzles.NotationTrainer.Completed =
            (userData.Puzzles.NotationTrainer.Completed || 0) + 1;
          updateUserData(userRef, userData);
        }
        return;
      }
      setUserMoves(newUserMoves);
      const newGame = game; // Create a new Chess.js instance
      newGame.move(pgnMoves[moveIndex + 1]);
      setMoveIndex(moveIndex + 1);
      setGame(newGame); // Update the game state with the new instance

      // Get the starting and ending positions of the move
      const lastMove = newGame.history({ verbose: true }).slice(-1)[0];
      const from = lastMove.from;
      const to = lastMove.to;

      // Update arrow state
      setArrow([[from, to, "red"]]);

      // Store the current timestamp for correct move
      setMoveTimes([...moveTimes, currentTime]);

      setCurrentInput("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
      updateOpeningName(newUserMoves);
      setWrongAttempts(0); // Reset wrong attempts
      showAlertWithMessage(t("NotationTrainer.game.correctMove"), "success");
    } else {
      setCurrentInput("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setWrongAttempts((prev) => prev + 1);

      if (wrongAttempts + 1 >= 3) {
        const newUserMoves = [...userMoves, pgnMoves[moveIndex]];
        const newGame = game;
        newGame.move(pgnMoves[moveIndex + 1]);
        setUserMoves(newUserMoves);
        setMoveIndex(moveIndex + 1);
        setGame(newGame);
        const updatedWrongGuesses = [...wrongGuesses];
        updatedWrongGuesses[moveIndex] = "Y"; // Mark the move as incorrect
        setWrongGuesses(updatedWrongGuesses);

        // Get the starting and ending positions of the move
        const lastMove = newGame.history({ verbose: true }).slice(-1)[0];
        const from = lastMove.from;
        const to = lastMove.to;

        // Update arrow state
        setArrow([[from, to, "red"]]);

        setWrongAttempts(0); // Reset wrong attempts
        showAlertWithMessage(
          t("NotationTrainer.game.wrongAttempts") +
            " " +
            pgnMoves[moveIndex] +
            ".",
          "info",
          5000
        );
      } else {
        showAlertWithMessage(
          t("NotationTrainer.game.wrongMove"),
          "error",
          5000
        );
      }
    }
  }, [
    currentInput,
    moveIndex,
    pgnMoves,
    userMoves,
    game,
    updateOpeningName,
    moveTimes,
    userData,
    userRef,
    wrongAttempts,
    wrongGuesses,
    t,
  ]);

  const showAlertWithMessage = (message, severity, duration = 3000) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, duration);
  };

  const calculateAverageMoveTime = (times) => {
    if (times.length <= 1) return 0;
    const totalMoveTime = times.reduce((acc, time, index) => {
      if (index === 0) return acc;
      const prevTime = times[index - 1];
      return acc + (time - prevTime);
    }, 0);
    return totalMoveTime / (times.length - 1) / 1000; // Convert milliseconds to seconds
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleInputSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleInputSubmit]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("NotationTrainer.header.title")}
        subtitle={t("NotationTrainer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Notation Trainer</title>
        <meta
          name="description"
          content="Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!"
        />
        <meta property="og:title" content="Notation Trainer" />
        <meta
          property="og:description"
          content="Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/notationtrainer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/notationtrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Notation Trainer" />
        <meta
          name="twitter:description"
          content="Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/notationtrainer.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[10] }}
            />
          }
        >
          {t("NotationTrainer.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
        >
          {t("NotationTrainer.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("NotationTrainer.helpDetails.title")}
          content={<NotationTrainerHelp />}
        ></HelpModal>
      </Box>
      <Box
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column", // All screens smaller than 'sm'
            sm: "column", // All screens smaller than 'md'
            md: "row", // Medium screens and larger
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          width: "100%", // Use the full width of the container
        }}
      >
        <div
          id="chessboard"
          style={{
            width: "100%", // Full width in column layout
            maxWidth: "500px", // Maximum width to constrain the chessboard
            padding: "0px 10px 10px 0px", // Uniform padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          <Chessboard
            position={game.fen()}
            customArrows={arrow}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
            arePiecesDraggable={false}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="h7">{gameName}</Typography>
          </Box>
          {openingName && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h7">
                {t("NotationTrainer.game.opening")}:<b> {openingName}</b>
              </Typography>
            </Box>
          )}
          {moveTimes.length > 1 && (
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h7">
                {t("NotationTrainer.game.moveTime")}
                <b>
                  {" "}
                  {calculateAverageMoveTime(moveTimes).toFixed(2)}{" "}
                  {t("NotationTrainer.game.seconds")}
                </b>
              </Typography>
            </Box>
          )}
        </div>

        <div
          id="inputs"
          style={{
            flex: 1,
            overflow: "auto",
            padding: "0px 0px 10px 0px",
          }}
        >
          <Box sx={{ marginBottom: 1 }}>
            {userMoves.map((move, index) => {
              const moveNumber = Math.floor(index / 2) + 1;
              const isWrongGuess = wrongGuesses[index] === "Y";
              return (
                <Typography
                  component="span"
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "0 5px",
                    color: isWrongGuess ? "red" : "inherit", // Highlight wrong guesses in red
                  }}
                  key={index}
                >
                  {index % 2 === 0 ? `${moveNumber}. ${move}` : move}
                </Typography>
              );
            })}
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            {completed && (
              <>
                <p>
                  <CheckCircleOutlineIcon
                    style={{
                      color: "green",
                      marginRight: "10px",
                      marginBottom: "-10px",
                      verticalAlign: "middle",
                      fontSize: "32px",
                    }}
                  />
                  {t("NotationTrainer.game.congrats")} <b>{moveIndex}</b>{" "}
                  {t("NotationTrainer.game.successfully")}
                </p>
              </>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              value={currentInput}
              onChange={handleInputChange}
              autoComplete="off"
              sx={{
                ml: 1,
                width: 60,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Focused border color
                  },
                },
              }}
              inputProps={{
                style: { textAlign: "center", padding: "6px" }, // Center the text and adjust padding
              }}
              inputRef={inputRef}
            />
            <Button
              variant="contained"
              onClick={handleInputSubmit}
              disabled={!gameStarted && !completed}
              sx={{ marginLeft: 1 }}
            >
              {t("NotationTrainer.buttons.notate")}
            </Button>
          </Box>

          <Collapse in={showAlert}>
            <Alert severity={alertSeverity} sx={{ marginTop: 2 }}>
              {alertMessage}
            </Alert>
          </Collapse>
        </div>
      </Box>
    </Box>
  );
}

export default NotationTrainer;
