import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const JumpingKnightHelp = () => {
  const { t } = useTranslation("Arcade");

  return (
    <>
      <Typography>{t("JumpingKnight.helpDetails.welcome")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t("JumpingKnight.helpDetails.instructions.jump.title")}
            secondary={t(
              "JumpingKnight.helpDetails.instructions.jump.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "JumpingKnight.helpDetails.instructions.obstacles.title"
            )}
            secondary={t(
              "JumpingKnight.helpDetails.instructions.obstacles.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("JumpingKnight.helpDetails.instructions.score.title")}
            secondary={t(
              "JumpingKnight.helpDetails.instructions.score.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "JumpingKnight.helpDetails.instructions.highScore.title"
            )}
            secondary={t(
              "JumpingKnight.helpDetails.instructions.highScore.description"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("JumpingKnight.helpDetails.instructions.newGame.title")}
            secondary={t(
              "JumpingKnight.helpDetails.instructions.newGame.description"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default JumpingKnightHelp;
