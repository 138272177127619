import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import Button from "@mui/material/Button";
import { gsap } from "gsap";
import { Typography, Box, Select, MenuItem, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { Helmet } from "react-helmet";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import HelpModal from "../../../components/HelpModal";
import "./WhackAPawn.css";

import { ColorModeContext } from "../../../styles/theme";
import { themeColors } from "../../../styles/boardtheme";
import { tokens } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";
import { updateUserData } from "../../../features/Firestore";
import WhackAPawnHelp from "../../../help/WhackAPawnHelp";

const WhackAPawn = () => {
  const [score, setScore] = useState(0);
  const [activePieces, setActivePieces] = useState([]);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [spawnRate, setSpawnRate] = useState(1000);
  const [level, setLevel] = useState(1);
  const [lives, setLives] = useState(5);
  const deductedPiecesSet = useRef(new Set());
  const [squareSize, setSquareSize] = useState(500 / 8);
  const [boardSize, setBoardSize] = useState(500);
  const [paused, setPaused] = useState(true);
  const [firstPlay, setFirstPlay] = useState(false);
  const { mode } = useContext(ColorModeContext);
  const [difficultyInterval, setDifficultyInterval] = useState(null);
  const [difficulty, setDifficulty] = useState(1);
  const [updateScore, setUpdateScore] = useState(true);
  const [playSound, setPlaySound] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Arcade");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const [isGameActive, setIsGameActive] = useState(false);

  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const musicRef = useRef(null);

  const toggleMusic = () => {
    setIsMusicPlaying((prev) => !prev);
  };

  // Initialize the audio in the useEffect
  useEffect(() => {
    // Ensure musicRef.current is set to an Audio object
    musicRef.current = new Audio(
      `${process.env.PUBLIC_URL}/sounds/relaxing.mp3`
    );
    musicRef.current.loop = true; // Set the music to loop

    return () => {
      if (musicRef.current) {
        musicRef.current.pause();
        musicRef.current.currentTime = 0; // Reset the music
      }
    };
  }, []);

  useEffect(() => {
    if (isMusicPlaying && isGameActive && !paused) {
      musicRef.current?.play();
    } else {
      musicRef.current?.pause();
    }
  }, [isGameActive, isMusicPlaying, paused]);

  const pieces = useMemo(
    () => ({
      pawn: { points: 1, duration: 5000 },
      knight: { points: 3, duration: 4000 },
      bishop: { points: 3, duration: 4000 },
      rook: { points: 5, duration: 3000 },
      queen: { points: 9, duration: 2000 },
      king: { points: 20, duration: 2000 },
      enemypawn: { points: -1, duration: 5000 },
      enemyknight: { points: -3, duration: 4000 },
      enemybishop: { points: -3, duration: 4000 },
      enemyrook: { points: -5, duration: 3000 },
      enemyqueen: { points: -9, duration: 2000 },
      enemyking: { points: -20, duration: 2000 },
      bomb: { points: 0, duration: 5000 },
      life: { points: 0, duration: 3000 },
    }),
    []
  );

  const weightedPieces = useMemo(
    () => [
      ...Array(70).fill("pawn"),
      ...Array(10).fill("knight"),
      ...Array(10).fill("bishop"),
      ...Array(5).fill("rook"),
      ...Array(3).fill("queen"),
      ...Array(2).fill("king"),
      ...Array(2).fill("enemypawn"),
      ...Array(2).fill("enemyknight"),
      ...Array(2).fill("enemybishop"),
      ...Array(2).fill("enemyrook"),
      ...Array(2).fill("enemyqueen"),
      ...Array(2).fill("enemyking"),
      ...Array(5).fill("bomb"),
      ...Array(3).fill("life"),
    ],
    []
  );

  const generateUniquePosition = useCallback(() => {
    const occupiedPositions = new Set(
      activePieces.map((piece) => piece.position)
    );
    let position;

    do {
      position = Math.floor(Math.random() * 64);
    } while (occupiedPositions.has(position));

    return position;
  }, [activePieces]);

  const spawnPiece = useCallback(() => {
    const randomChance = Math.random();
    const numberOfPieces = randomChance < 0.7 ? 1 : randomChance < 0.9 ? 2 : 3;

    setActivePieces((prev) => {
      const newPieces = [...prev];
      for (let i = 0; i < numberOfPieces; i++) {
        const pieceType =
          weightedPieces[Math.floor(Math.random() * weightedPieces.length)];
        const position = generateUniquePosition();

        newPieces.push({
          id: Date.now() + i,
          type: pieceType,
          position,
          duration: pieces[pieceType].duration * speedMultiplier,
          startTime: Date.now(),
          clicked: false, // New property to track if the piece has been clicked
          showScore: true,
        });
      }
      return newPieces;
    });
  }, [weightedPieces, pieces, speedMultiplier, generateUniquePosition]);

  const toggleSound = () => {
    setPlaySound((prev) => !prev); // Toggle playSound state
  };

  const handlePieceClick = (pieceId, pieceType) => {
    if (!paused) {
      setActivePieces((prev) =>
        prev.map((piece) => {
          if (piece.id === pieceId && !piece.clicked) {
            // Only process if the piece hasn't been clicked yet
            const points = pieces[pieceType].points;
            setScore((prevScore) => prevScore + points);

            // Handle life increment or decrement based on piece type
            if (pieceType === "life") {
              setLives((prevLives) => Math.min(prevLives + 1, 5));
            } else if (pieceType === "bomb") {
              setLives((prevLives) => Math.max(prevLives - 1, 0));
            }

            // Trigger fade-out animation for this piece
            fadeOutPiece(pieceId, "click", pieceType);

            // Mark piece as clicked
            return { ...piece, clicked: true };
          }
          return piece;
        })
      );
    }
  };

  const fadeOutPiece = useCallback(
    (pieceId, type, pieceType) => {
      // Added pieceType as a parameter
      const animation =
        type === "click"
          ? { opacity: 0, scale: 0, duration: 0.7, rotation: 360 }
          : { opacity: 0, scale: 0, duration: 0.5 };

      if (type === "click" && playSound) {
        // Play sound based on piece type
        let soundFile;
        if (pieceType === "life") {
          soundFile = `${process.env.PUBLIC_URL}/sounds/life.mp3`;
          const clickSound = new Audio(soundFile);
          clickSound.volume = 0.02;
          clickSound.play();
        } else if (pieceType === "bomb") {
          soundFile = `${process.env.PUBLIC_URL}/sounds/explosion.mp3`;
          const clickSound = new Audio(soundFile);
          clickSound.volume = 0.02;
          clickSound.play();
        } else {
          soundFile = `${process.env.PUBLIC_URL}/sounds/bang.mp3`;
          const clickSound = new Audio(soundFile);
          clickSound.volume = 0.1;
          clickSound.play();
        }
      } else {
        // Prevent score display if faded out due to timeout
        setActivePieces((prev) =>
          prev.map((piece) =>
            piece.id === pieceId ? { ...piece, showScore: false } : piece
          )
        );
      }

      // Clear the timeout for this piece if clicked to prevent life deduction
      if (timeoutRefs.current[pieceId]) {
        clearTimeout(timeoutRefs.current[pieceId]);
        delete timeoutRefs.current[pieceId];
      }

      gsap.to(`#piece-${pieceId}`, {
        ...animation,
        onComplete: () => {
          setActivePieces((prev) =>
            prev.filter((piece) => piece.id !== pieceId)
          );
          deductedPiecesSet.current.delete(pieceId); // Clean up set
        },
      });
    },
    [setActivePieces, playSound]
  );

  const resetGame = () => {
    setScore(0);
    setActivePieces([]);
    setSpawnRate(1000);
    setSpeedMultiplier(1);
    setLevel(1);
    setLives(5);
    setPaused(false);
    setFirstPlay(true);
    setUpdateScore(true);
    deductedPiecesSet.current.clear();
    setIsGameActive(true);

    // Restart the difficulty interval when resetting the game
    clearDifficultyInterval(); // Clear the existing interval
    startDifficultyInterval(); // Start a new interval

    trackEvent("Arcade", "WhackAMole-Play", "WhackAMole");
    // Internal Tracking
    if (userData) {
      if (!userData.Arcade) {
        userData.Arcade = {};
      }
      if (userData.Arcade.WhackAPawn) {
        userData.Arcade.WhackAPawn.Played =
          (userData.Arcade.WhackAPawn.Played || 0) + 1;
      } else {
        userData.Arcade.WhackAPawn = {
          Played: 1,
          MusicEnabled: true,
          SoundEnabled: true,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const adjustDifficulty = useCallback(() => {
    if (lives > 0 && !paused) {
      setSpeedMultiplier((prev) => prev * difficulty);
      setSpawnRate((prev) => prev * difficulty);
      setLevel((prev) => prev + 1);
    }
  }, [difficulty, lives, paused]);

  const clearDifficultyInterval = useCallback(() => {
    if (difficultyInterval) {
      clearInterval(difficultyInterval);
      setDifficultyInterval(null);
    }
  }, [difficultyInterval]);

  const startDifficultyInterval = useCallback(() => {
    const interval = setInterval(adjustDifficulty, 15000);
    setDifficultyInterval(interval);
  }, [adjustDifficulty]);

  const togglePause = () => {
    setPaused((prev) => {
      const newPausedState = !prev;
      if (newPausedState) {
        clearDifficultyInterval(); // Pause the level timer
      } else {
        startDifficultyInterval(); // Resume the level timer
      }
      return newPausedState;
    });
  };

  const getDifficultyLabel = (value) => {
    switch (value) {
      case 1:
        return "Chilled";
      case 0.99:
        return "Easy";
      case 0.95:
        return "Normal";
      case 0.9:
        return "Hard";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/whackapawn",
      title: "Chessboard Magic - Whack A Pawn",
      description:
        "Score by hitting White pieces, avoid hazards, collect lives, and keep up as the game speeds up with each level.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData, t]);

  // Spawn loop using setTimeout for precise control and to respect pause
  useEffect(() => {
    let timeoutId;

    const spawnLoop = () => {
      if (paused) return;
      timeoutId = setTimeout(() => {
        spawnPiece();
        spawnLoop();
      }, spawnRate);
    };

    spawnLoop();

    return () => clearTimeout(timeoutId);
  }, [spawnRate, spawnPiece, paused]);

  const timeoutRefs = useRef({});

  // Manage fade-out timeouts for each piece
  useEffect(() => {
    const currentTimeoutRefs = timeoutRefs.current;
    const currentDeductedSet = deductedPiecesSet.current;

    if (paused) {
      Object.keys(currentTimeoutRefs).forEach((id) => {
        clearTimeout(currentTimeoutRefs[id]);
        delete currentTimeoutRefs[id];
      });
    } else {
      activePieces.forEach((piece) => {
        if (
          !currentTimeoutRefs[piece.id] &&
          !currentDeductedSet.has(piece.id)
        ) {
          currentTimeoutRefs[piece.id] = setTimeout(() => {
            // Check if the piece was clicked before timeout
            if (
              !piece.clicked &&
              !(
                piece.type.startsWith("enemy") ||
                piece.type === "bomb" ||
                piece.type === "life"
              )
            ) {
              // Deduct a life only if the piece wasn't clicked and is not life-related
              setLives((prevLives) => Math.max(prevLives - 1, 0));
            }

            currentDeductedSet.add(piece.id);
            fadeOutPiece(piece.id, "timeout", piece.type);
            delete currentTimeoutRefs[piece.id];
          }, piece.duration);
        }
      });
    }

    return () => {
      Object.keys(currentTimeoutRefs).forEach((id) => {
        if (!activePieces.find((piece) => piece.id === parseInt(id, 10))) {
          clearTimeout(currentTimeoutRefs[id]);
          delete currentTimeoutRefs[id];
          currentDeductedSet.delete(parseInt(id, 10));
        }
      });
    };
  }, [activePieces, fadeOutPiece, paused]);

  useEffect(() => {
    const updateSquareSize = () => {
      const boardSize = Math.min(
        document.documentElement.clientWidth - 100,
        500
      );
      setSquareSize(boardSize / 8);
      setBoardSize(boardSize);
    };

    updateSquareSize();
    window.addEventListener("resize", updateSquareSize);

    return () => window.removeEventListener("resize", updateSquareSize);
  }, []);

  useEffect(() => {
    if (lives === 0) {
      // Game over: pause the game and hide the resume button
      setPaused(true);
      setFirstPlay(false);
      setIsGameActive(false);

      trackEvent("Arcade", "WhackAPawn-End", "Whack A Pawn");
      // Internal Tracking
      if (userData && updateScore) {
        // Check if the difficulty level exists, and if not, initialize it
        if (!userData.Arcade.WhackAPawn[getDifficultyLabel(difficulty)]) {
          userData.Arcade.WhackAPawn[getDifficultyLabel(difficulty)] = {
            HighScore: 0,
          };
        }

        userData.Arcade.WhackAPawn.SoundEnabled = playSound;
        userData.Arcade.WhackAPawn.MusicEnabled = isMusicPlaying;

        // Update the high score for the current difficulty
        userData.Arcade.WhackAPawn[getDifficultyLabel(difficulty)].HighScore =
          Math.max(
            userData.Arcade.WhackAPawn[getDifficultyLabel(difficulty)]
              .HighScore,
            score
          );

        setUpdateScore(false);
        updateUserData(userRef, userData);
      }
    }
  }, [
    lives,
    score,
    userData,
    userRef,
    difficulty,
    updateScore,
    playSound,
    isMusicPlaying,
  ]);

  useEffect(() => {
    if (userData?.Arcade?.WhackAPawn) {
      const { SoundEnabled, MusicEnabled } = userData.Arcade.WhackAPawn;

      if (SoundEnabled !== undefined) {
        setPlaySound(SoundEnabled);
      }
      if (MusicEnabled !== undefined) {
        setIsMusicPlaying(MusicEnabled);
      }
    }
  }, [userData]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title={t("WhackAPawn.header.title")}
        subtitle={t("WhackAPawn.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Whack A Pawn</title>
        <meta
          name="description"
          content="Score by hitting White pieces, avoid hazards, collect lives, and keep up as the game speeds up with each level."
        />
        <meta property="og:title" content="Whack A Pawn" />
        <meta
          property="og:description"
          content="Score by hitting White pieces, avoid hazards, collect lives, and keep up as the game speeds up with each level."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/arcade/whackapawn.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/whackapawn`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Whack A Pawn" />
        <meta
          name="twitter:description"
          content="Score by hitting White pieces, avoid hazards, collect lives, and keep up as the game speeds up with each level."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/arcade/whackapawn.png`}
        />
      </Helmet>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap" // Enables wrapping of buttons
        sx={{ mb: 2 }}
      >
        <Select
          value={difficulty}
          onChange={(e) => setDifficulty(parseFloat(e.target.value))}
          variant="outlined"
          sx={{ mr: 1, mb: 1, height: "40px" }}
        >
          <MenuItem value={1}>{t("WhackAPawn.buttons.chilled")}</MenuItem>
          <MenuItem value={0.99}>{t("WhackAPawn.buttons.easy")}</MenuItem>
          <MenuItem value={0.95}>{t("WhackAPawn.buttons.normal")}</MenuItem>
          <MenuItem value={0.9}>{t("WhackAPawn.buttons.hard")}</MenuItem>
        </Select>
        <Button
          variant="contained"
          onClick={resetGame}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          {t("WhackAPawn.buttons.newGame")}
        </Button>
        {firstPlay && (
          <Button
            variant="contained"
            onClick={togglePause}
            sx={{
              mr: 1,
              mb: 1,
              ...(mode === "dark" && {
                boxShadow: "0px 0px 4px rgba(255, 255, 255, 0.4)", // Soft white shadow for dark mode
              }),
              "&:hover": {
                boxShadow:
                  mode === "dark"
                    ? "0px 0px 6px rgba(255, 255, 255, 0.6)" // Stronger white shadow on hover in dark mode
                    : "0px 0px 6px rgba(0, 0, 0, 0.4)", // Darker shadow for light mode hover
              },
            }}
          >
            {paused
              ? t("WhackAPawn.buttons.resume")
              : t("WhackAPawn.buttons.pause")}{" "}
            {t("WhackAPawn.buttons.game")}
          </Button>
        )}
        <Button
          onClick={toggleSound}
          variant="contained"
          sx={{
            mb: 1,
            mr: 1,
            height: "37px", // Explicit height
            width: "37px", // Explicit width (same as height for square)
            padding: 0, // Remove internal padding
            minWidth: 0, // Override default minWidth
            borderRadius: "4px", // Optional: Rounded corners
            display: "flex", // Ensure proper alignment using flexbox
            justifyContent: "center", // Horizontally center content
            alignItems: "center", // Vertically center content
          }}
        >
          {playSound ? (
            <VolumeUpIcon sx={{ fontSize: "20px" }} />
          ) : (
            <VolumeOffIcon
              sx={{ fontSize: "20px", color: colors.material[1] }}
            />
          )}
        </Button>
        <Button
          onClick={toggleMusic}
          variant="contained"
          sx={{
            mb: 1,
            mr: 1,
            height: "37px", // Explicit height
            width: "37px", // Explicit width (same as height for square)
            padding: 0, // Remove internal padding
            minWidth: 0, // Override default minWidth
            borderRadius: "4px", // Optional: Rounded corners
            display: "flex", // Ensure proper alignment using flexbox
            justifyContent: "center", // Horizontally center content
            alignItems: "center", // Vertically center content
          }}
        >
          {isMusicPlaying ? (
            <MusicNoteIcon sx={{ fontSize: "20px" }} />
          ) : (
            <MusicOffIcon
              sx={{ fontSize: "20px", color: colors.material[1] }}
            />
          )}
        </Button>

        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
          sx={{ mb: 1 }}
        >
          {t("WhackAPawn.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("WhackAPawn.helpDetails.title")}
          content={<WhackAPawnHelp />}
        ></HelpModal>
      </Box>

      <Box
        display="flex"
        flexWrap="wrap" // Enables wrapping into multiple rows on smaller screens
        justifyContent="space-between"
        alignItems="flex-start" // Top align all items
        sx={{
          mb: 1,
          width: `${boardSize}px`, // Set total width based on boardSize
        }}
      >
        {/* Lives Box */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start" // Align content to the left
          justifyContent="flex-start" // Top align content
          sx={{
            width: { xs: "50%", sm: `${boardSize / 4}px` }, // 50% width for xs, 1/4 boardSize for sm and above
            minHeight: 50, // Reduced height
            mb: { xs: 1, sm: 0 }, // Add bottom margin for xs
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontSize: "0.8rem", color: "gray", mb: 0.2 }}
          >
            {t("WhackAPawn.labels.lives")}
          </Typography>
          <Box display="flex" mt={0.5}>
            {Array.from({ length: 5 }).map((_, index) => (
              <FavoriteIcon
                key={index}
                sx={{
                  color: index < lives ? "red" : "gray",
                  ml: 0.5,
                  fontSize: 19,
                }}
              />
            ))}
          </Box>
        </Box>

        {/* Level Box */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start" // Align content to the left
          justifyContent="flex-start" // Top align content
          sx={{
            width: { xs: "50%", sm: `${boardSize / 4}px` }, // 50% width for xs, 1/4 boardSize for sm and above
            minHeight: 50, // Reduced height
            mb: { xs: 1, sm: 0 }, // Add bottom margin for xs
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontSize: "0.8rem", color: "gray" }}
          >
            {t("WhackAPawn.labels.level")}
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {level}
          </Typography>
        </Box>

        {/* Score Box */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start" // Align content to the left
          justifyContent="flex-start" // Top align content
          sx={{
            width: { xs: "50%", sm: `${boardSize / 4}px` }, // 50% width for xs, 1/4 boardSize for sm and above
            minHeight: 50, // Reduced height
            mb: { xs: 1, sm: 0 }, // Add bottom margin for xs
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontSize: "0.8rem", color: "gray" }}
          >
            {t("WhackAPawn.labels.score")}
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {score}
          </Typography>
        </Box>

        {/* Conditionally Render High Score Box */}
        {userData && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start" // Align content to the left
            justifyContent="flex-start" // Top align content
            sx={{
              width: { xs: "50%", sm: `${boardSize / 4}px` }, // 50% width for xs, 1/4 boardSize for sm and above
              minHeight: 50, // Reduced height
              mb: { xs: 1, sm: 0 }, // Add bottom margin for xs
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontSize: "0.8rem", color: "gray" }}
            >
              {t("WhackAPawn.labels.highScore")}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {userData?.Arcade?.WhackAPawn?.[getDifficultyLabel(difficulty)]
                ?.HighScore || 0}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          width: `${squareSize * 8}px`,
          height: `${squareSize * 8}px`,
          border: "1px solid #CCCCCC",
        }}
        sx={{ mb: 2 }}
      >
        {Array(64)
          .fill(null)
          .map((_, index) => {
            const row = Math.floor(index / 8);
            const col = index % 8;
            const isLightSquare = (row + col) % 2 === 0;
            const backgroundColor = isLightSquare
              ? themeColors[userData?.theme || "Modern Minimal"].lightSquare
              : themeColors[userData?.theme || "Modern Minimal"].darkSquare;
            const backgroundImage = isLightSquare
              ? themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern
              : themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern;

            return (
              <Box
                key={index}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: backgroundColor,
                  backgroundImage: backgroundImage,
                  position: "relative",
                }}
              >
                {activePieces.map((piece) =>
                  piece.position === index ? (
                    <PieceImage
                      key={piece.id}
                      piece={piece}
                      squareSize={squareSize}
                      handlePieceClick={handlePieceClick}
                      pieces={pieces} // Pass pieces object here
                      paused={paused}
                    />
                  ) : null
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

// PieceImage component with GSAP fade-in and fade-out animations
const PieceImage = ({
  piece,
  squareSize,
  handlePieceClick,
  pieces,
  paused,
}) => {
  const imgRef = useRef(null);
  const scoreRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      gsap.fromTo(
        imgRef.current,
        { scale: 0.5 },
        { scale: 1, duration: 0.5, ease: "power2.out" }
      );
    }
  }, []);

  const showScoreAnimation = (points) => {
    // Prevent score animation if the game is paused
    if (paused) {
      return;
    }

    // Only animate score if the piece type is not "bomb" or "life"
    if (
      scoreRef.current &&
      piece.showScore &&
      piece.type !== "bomb" &&
      piece.type !== "life"
    ) {
      const isEnemyPiece = piece.type.startsWith("enemy");
      const textColor = isEnemyPiece ? "red" : "green";
      const scoreText = isEnemyPiece ? `${points}` : `+${points}`;

      // Set the score text and color based on piece type
      scoreRef.current.innerText = scoreText;
      scoreRef.current.style.color = textColor;

      gsap.fromTo(
        scoreRef.current,
        { opacity: 1, y: 0, display: "block" },
        {
          opacity: 0,
          y: -30, // Move upwards
          duration: 1.5,
          ease: "power2.out",
          onComplete: () => {
            if (scoreRef.current) {
              scoreRef.current.style.display = "none";
            }
          },
        }
      );
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: `${squareSize}px`,
        height: `${squareSize}px`,
      }}
      onClick={() => {
        handlePieceClick(piece.id, piece.type);
        showScoreAnimation(pieces[piece.type].points);
      }}
    >
      <img
        id={`piece-${piece.id}`}
        ref={imgRef}
        src={`${process.env.PUBLIC_URL}/img/arcade/whackapawn/${piece.type}.png`}
        alt={piece.type}
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          opacity: 0,
          animation: "fadeIn 0.5s forwards",
        }}
      />
      {/* Score animation text positioned above the piece */}
      <div
        ref={scoreRef}
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          fontSize: "1rem",
          fontWeight: "bold",
          pointerEvents: "none",
          display: "none",
        }}
      >
        {/* Display controlled by showScoreAnimation */}
      </div>
    </div>
  );
};

export default WhackAPawn;
