import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const CrosswordHelp = () => {
  const { t } = useTranslation("Games");

  return (
    <Box>
      <Typography>{t("Crossword.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "Crossword.helpDetails.instructions.start_puzzle.primary"
            )}
            secondary={t(
              "Crossword.helpDetails.instructions.start_puzzle.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Crossword.helpDetails.instructions.fill_in_blanks.primary"
            )}
            secondary={t(
              "Crossword.helpDetails.instructions.fill_in_blanks.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Crossword.helpDetails.instructions.understand_hints.primary"
            )}
            secondary={t(
              "Crossword.helpDetails.instructions.understand_hints.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Crossword.helpDetails.instructions.check_answers.primary"
            )}
            secondary={t(
              "Crossword.helpDetails.instructions.check_answers.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "Crossword.helpDetails.instructions.reset_puzzle.primary"
            )}
            secondary={t(
              "Crossword.helpDetails.instructions.reset_puzzle.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t("Crossword.helpDetails.instructions.have_fun.primary")}
            secondary={t(
              "Crossword.helpDetails.instructions.have_fun.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default CrosswordHelp;
