import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useUser } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { themeColors } from "../../../styles/boardtheme";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import HelpModal from "../../../components/HelpModal";
import CoordinateTrainerHelp from "../../../help/CoordinateTrainerHelp";

import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";

function CoordinateTrainer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { t } = useTranslation("Learn");

  const { setShareData } = useShare();
  const [isGameActive, setIsGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [score, setScore] = useState(0);
  const [currentTarget, setCurrentTarget] = useState("");
  const [grid, setGrid] = useState([]);
  const [gridSize, setGridSize] = useState(600); // Default grid size
  const [preGameCountdown, setPreGameCountdown] = useState(null); // 3-2-1 countdown

  useEffect(() => {
    const handleResize = () => {
      // Dynamically calculate the grid size, considering the viewport width and ensuring it doesn't exceed the screen.
      const availableWidth = Math.min(
        window.innerWidth,
        document.documentElement.clientWidth
      );
      const newSize = Math.min(availableWidth - 100, 500); // Reduce by 20px for potential padding/margin
      setGridSize(newSize); // Set the grid size dynamically
    };

    handleResize(); // Initial size on mount
    window.addEventListener("resize", handleResize); // Listen for resize events
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  // Update share data when component mounts
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/coordinatetrainer",
      title: "Chessboard Magic - Coordinate Trainer",
      description:
        "Learn chess board coordinates with a fun and interactive trainer! Perfect for sharpening your spatial awareness.",
    };
    setShareData(newShareData);
  }, [setShareData]);

  // Generate chessboard grid
  useEffect(() => {
    const rows = "87654321";
    const cols = "ABCDEFGH";
    const squares = [];
    rows.split("").forEach((row) => {
      cols.split("").forEach((col) => {
        squares.push(`${col}${row}`);
      });
    });
    setGrid(squares);
  }, []);

  // Handle main game countdown
  useEffect(() => {
    if (isGameActive && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(
          (prevTime) => Math.max(0, (prevTime - 0.1).toFixed(1)) // Reduce by 0.1 and format to 1dp
        );
      }, 100); // Update every 100ms
      return () => clearTimeout(timer);
    } else if (timeLeft <= 0) {
      setIsGameActive(false); // End game
      // Track event for analytics
      trackEvent("Learn", "CoordinateTrainer-Completed", "Coordinate Trainer");

      // Internal Tracking and Highest Score Logic
      if (userData) {
        // Increment completed games count
        userData.Learn.CoordinateTrainer.Completed =
          (userData.Learn.CoordinateTrainer.Completed || 0) + 1;

        // Initialize the HighestScore field if it doesn't exist
        if (!userData.Learn.CoordinateTrainer.HighestScore) {
          userData.Learn.CoordinateTrainer.HighestScore = 0;
        }

        // Compare and update the highest score
        if (score > userData.Learn.CoordinateTrainer.HighestScore) {
          userData.Learn.CoordinateTrainer.HighestScore = score; // Update with the new highest score
        }

        // Save updated data
        updateUserData(userRef, userData);
      }
    }
  }, [isGameActive, timeLeft, score, userData, userRef]);

  // Set a new random target square
  const setNewTarget = useCallback(() => {
    let randomIndex;
    let newTarget;

    // Keep generating a new random target until it's different from the current one
    do {
      randomIndex = Math.floor(Math.random() * grid.length);
      newTarget = grid[randomIndex];
    } while (newTarget === currentTarget);

    setCurrentTarget(newTarget);
  }, [grid, currentTarget]);

  // Start the main game
  const startMainGame = useCallback(() => {
    setIsGameActive(true);
    setNewTarget();
  }, [setIsGameActive, setNewTarget]);

  // Handle pre-game countdown
  useEffect(() => {
    if (preGameCountdown !== null && preGameCountdown > 0) {
      const timer = setTimeout(
        () => setPreGameCountdown(preGameCountdown - 1),
        1000
      );
      return () => clearTimeout(timer);
    } else if (preGameCountdown === 0) {
      setPreGameCountdown(null);
      startMainGame();
    }
  }, [preGameCountdown, startMainGame]);

  // Start a new game with 3-2-1 countdown
  const startGame = () => {
    setScore(0);
    setTimeLeft(60);
    setPreGameCountdown(3); // Start countdown

    // GA Tracking
    trackEvent("Learn", "CoordinateTrainer-Play", "Coordinate Trainer");
    // Internal Tracking

    if (userData) {
      if (!userData.Learn) {
        userData.Learn = {};
      }
      if (userData.Learn.CoordinateTrainer) {
        userData.Learn.CoordinateTrainer.Played =
          (userData.Learn.CoordinateTrainer.Played || 0) + 1;
      } else {
        userData.Learn.CoordinateTrainer = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  // Stop the game
  const stopGame = () => {
    setIsGameActive(false); // Pause the game

    // Track event for analytics
    trackEvent("Learn", "CoordinateTrainer-Completed", "Coordinate Trainer");

    // Internal Tracking and Highest Score Logic
    if (userData) {
      // Increment completed games count
      userData.Learn.CoordinateTrainer.Completed =
        (userData.Learn.CoordinateTrainer.Completed || 0) + 1;

      // Initialize the HighestScore field if it doesn't exist
      if (!userData.Learn.CoordinateTrainer.HighestScore) {
        userData.Learn.CoordinateTrainer.HighestScore = 0;
      }

      // Compare and update the highest score
      if (score > userData.Learn.CoordinateTrainer.HighestScore) {
        userData.Learn.CoordinateTrainer.HighestScore = score; // Update with the new highest score
      }

      // Save updated data
      updateUserData(userRef, userData);
    }
  };

  // Handle square clicks
  const handleSquareClick = (square) => {
    if (!isGameActive) return;
    if (square === currentTarget) {
      setScore(score + 1); // Increment score
    }
    setNewTarget(); // Move to next target
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {/* Content Header */}
      <ContentHeader
        title={t("CoordinateTrainer.header.title")}
        subtitle={t("CoordinateTrainer.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />

      {/* Helmet Meta Tags */}
      <Helmet>
        <title>Coordinate Trainer</title>
        <meta
          name="description"
          content="Learn chess board coordinates with a fun and interactive trainer! Perfect for sharpening your spatial awareness."
        />
        <meta property="og:title" content="Coordinate Trainer" />
        <meta
          property="og:description"
          content="Learn chess board coordinates with a fun and interactive trainer! Perfect for sharpening your spatial awareness."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/coordinatetrainer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/coordinatetrainer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Coordinate Trainer" />
        <meta
          name="twitter:description"
          content="Learn chess board coordinates with a fun and interactive trainer! Perfect for sharpening your spatial awareness."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/coordinatetrainer.png`}
        />
      </Helmet>

      {/* Game Controls */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start" // Use "flex-start" to align items at the top
        marginBottom="16px"
        gap="10px"
        flexWrap="wrap" // Allow buttons to wrap to the next line on overflow
      >
        <Button
          variant="contained"
          onClick={startGame}
          disabled={preGameCountdown !== null || isGameActive}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[10] }}
            />
          }
        >
          {t("CoordinateTrainer.buttons.newGame")}
        </Button>
        <Button
          variant="contained"
          onClick={stopGame}
          disabled={!isGameActive}
          startIcon={<StopCircleIcon style={{ color: colors.material[10] }} />}
        >
          {t("CoordinateTrainer.buttons.stop")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
        >
          {t("CoordinateTrainer.buttons.help")}
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("CoordinateTrainer.helpDetails.title")}
          content={<CoordinateTrainerHelp />}
        />
      </Box>

      {/* Game Info */}

      <Box
        display="flex"
        justifyContent="space-between" // Spread items evenly across the box
        alignItems="center" // Center items vertically
        sx={{ mb: 2, width: `${gridSize}px`, maxWidth: `${gridSize}px` }} // Match the width of the grid
      >
        <Typography>
          {t("CoordinateTrainer.labels.timeLeft")}:{" "}
          <b>{timeLeft.toFixed(1)}s</b>
        </Typography>
        <Typography>
          {t("CoordinateTrainer.labels.score")}: <b>{score}</b>
        </Typography>
        {userData?.Learn?.CoordinateTrainer?.HighestScore !== undefined && (
          <Typography>
            {t("CoordinateTrainer.labels.highScore")}:{" "}
            <b>{userData.Learn.CoordinateTrainer.HighestScore}</b>
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: "relative", // Parent container for the overlay
          display: "flex",
          flexDirection: "column", // Chessboard rows
          width: `${gridSize}px`, // Dynamic grid size
          height: `${gridSize}px`, // Dynamic grid size
          margin: "0", // Center the chessboard horizontally
        }}
      >
        {/* Pre-Game Countdown Overlay */}
        {preGameCountdown !== null && (
          <Typography
            variant="h1"
            sx={{
              fontSize: `${gridSize / 3}px`, // Scale the font size relative to grid size
              position: "absolute",
              top: "50%", // Center vertically within the chessboard
              left: "50%", // Center horizontally within the chessboard
              transform: "translate(-50%, -50%)", // Adjust for centering
              zIndex: 20, // Ensure it is on top of the chessboard and target overlay
              color: "rgba(0, 0, 0, 0.5)", // Semi-transparent red
              pointerEvents: "none", // Allow clicks to pass through
            }}
          >
            {preGameCountdown}
          </Typography>
        )}

        {/* Target Coordinate Overlay */}
        {preGameCountdown === null && currentTarget && (
          <Typography
            variant="h2"
            sx={{
              fontSize: `${gridSize / 3}px`, // Adjust font size relative to grid
              position: "absolute",
              top: "50%", // Center vertically within the chessboard
              left: "50%", // Center horizontally within the chessboard
              transform: "translate(-50%, -50%)", // Adjust for centering
              zIndex: 10, // Ensure it's on top of the board but below the countdown
              color: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
              pointerEvents: "none", // Allow clicks to pass through
            }}
          >
            {currentTarget}
          </Typography>
        )}

        {/* Chessboard Grid */}
        <Box
          sx={{
            position: "relative", // Parent container for the overlay
            display: "flex",
            flexDirection: "column", // Chessboard rows
            width: `${gridSize}px`, // Dynamic grid size
            maxWidth: `${gridSize}px`,
            height: `${gridSize}px`, // Dynamic grid size
            margin: "0", // Center the chessboard horizontally
            overflow: "hidden",
          }}
        >
          {/* Chessboard Grid */}
          <Box
            sx={{
              pointerEvents: isGameActive ? "auto" : "none",
              display: "flex",
              flexDirection: "column", // Chessboard orientation
              width: "100%", // Match parent width
              height: "100%", // Match parent height
            }}
          >
            {[...Array(8)].map((_, rowIdx) => (
              <Box
                key={`row-${rowIdx}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: "1", // Equal height for each row
                }}
              >
                {[...Array(8)].map((_, colIdx) => {
                  const colLetter = String.fromCharCode(65 + colIdx); // A-H
                  const rowNumber = 8 - rowIdx; // 8 to 1
                  const square = `${colLetter}${rowNumber}`;

                  // Determine the square style based on position
                  const isLightSquare = (colIdx + rowIdx) % 2 === 0;
                  const squareStyle = isLightSquare
                    ? {
                        backgroundColor:
                          themeColors[userData?.theme || "Modern Minimal"]
                            .lightSquare,
                        backgroundImage:
                          themeColors[userData?.theme || "Modern Minimal"]
                            .lightSquarePattern,
                      }
                    : {
                        backgroundColor:
                          themeColors[userData?.theme || "Modern Minimal"]
                            .darkSquare,
                        backgroundImage:
                          themeColors[userData?.theme || "Modern Minimal"]
                            .darkSquarePattern,
                      };

                  // Calculate square size as 1/8th of grid size
                  const squareSize = `${gridSize / 8}px`;

                  return (
                    <Box
                      key={square}
                      sx={{
                        ...squareStyle, // Apply the dynamic styles
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        width: squareSize, // Set width dynamically
                        height: squareSize, // Set height dynamically
                      }}
                      onClick={() => handleSquareClick(square)}
                    ></Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CoordinateTrainer;
