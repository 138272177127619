import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";

const RookRacer = () => {
  const LANES = 4; // Number of lanes
  const LANE_WIDTH = 100 / LANES; // Width of each lane in percentage
  const PLAYER_HEIGHT = 10; // Player height (% of screen height)
  const OBSTACLE_HEIGHT = 10; // Obstacle height (% of screen height)
  const OBSTACLE_SPEED = 0.3; // Speed of obstacle movement
  const SPAWN_INTERVAL = 2000; // Interval between obstacle spawns (ms)

  const [playerLane, setPlayerLane] = useState(Math.floor(LANES / 2)); // Player's starting lane
  const [obstacles, setObstacles] = useState([]); // Array to track obstacles
  const [isCollision, setIsCollision] = useState(false); // Track collisions
  const [isPaused, setIsPaused] = useState(false); // Track pause state

  const [level, setLevel] = useState(1); // Track pause state

  const playerLaneRef = useRef(playerLane); // Ref for real-time tracking of player lane
  const obstaclesRef = useRef(obstacles); // Ref for real-time tracking of obstacles
  const requestRef = useRef(null); // Ref for managing requestAnimationFrame
  const lastUpdateTimeRef = useRef(null); // Ref to track the last update time

  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const timerRef = useRef(null); // Ref to store the timer

  // Touch tracking
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const SWIPE_THRESHOLD = 30; // Minimum horizontal swipe distance in pixels

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const diffX = touchEndX.current - touchStartX.current;

    // Only register movement if the swipe exceeds the threshold
    if (Math.abs(diffX) >= SWIPE_THRESHOLD) {
      setPlayerLane((prev) => {
        let newLane = prev;

        if (diffX > 0) {
          newLane = prev + 1; // Swipe right
        } else if (diffX < 0) {
          newLane = prev - 1; // Swipe left
        }

        // Cap the lane between 0 and LANES - 1
        return Math.max(0, Math.min(newLane, LANES - 1));
      });
    }

    // Reset the touch values
    touchStartX.current = 0;
    touchEndX.current = 0;
  };

  // Sync playerLane state with ref
  useEffect(() => {
    playerLaneRef.current = playerLane;
  }, [playerLane]);

  // Sync obstacles state with ref
  useEffect(() => {
    obstaclesRef.current = obstacles;
  }, [obstacles]);

  // Handle player movement
  const handleKeyDown = useCallback(
    (e) => {
      if (isPaused) return; // Ignore input when paused
      if (e.key === "ArrowLeft" && playerLaneRef.current > 0) {
        setPlayerLane((prev) => prev - 1);
      } else if (e.key === "ArrowRight" && playerLaneRef.current < LANES - 1) {
        setPlayerLane((prev) => prev + 1);
      }
    },
    [isPaused, playerLaneRef] // Dependencies: isPaused and playerLaneRef
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isPaused, handleKeyDown]);

  // Update the Level
  useEffect(() => {
    if (elapsedTime > 0 && elapsedTime % 200 === 0) {
      // Every 20 seconds (200 tenths of a second)
      setLevel((prev) => prev + 1);
    }
  }, [elapsedTime]);

  // Spawn new rows of obstacles
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPaused) return; // Do not spawn obstacles when paused

      const gapLane = Math.floor(Math.random() * LANES); // Randomly pick one lane for the gap
      const newRow = Array.from(
        { length: LANES },
        (_, lane) => (lane === gapLane ? null : { lane, y: -OBSTACLE_HEIGHT }) // Obstacle in all lanes except the gap
      );

      setObstacles((prevObstacles) => [
        ...prevObstacles,
        ...newRow.filter(Boolean),
      ]);
    }, SPAWN_INTERVAL);

    return () => clearInterval(interval);
  }, [isPaused]);

  // Animation loop with collision detection
  const updateGame = useCallback(
    (time) => {
      if (isPaused) {
        lastUpdateTimeRef.current = time; // Maintain current time for accurate delta calculation when resumed
        requestRef.current = requestAnimationFrame(updateGame); // Keep the loop active while paused
        return;
      }

      if (lastUpdateTimeRef.current == null) {
        lastUpdateTimeRef.current = time;
      }
      const delta = time - lastUpdateTimeRef.current;

      // Move obstacles and check for collisions
      setObstacles((prevObstacles) => {
        const updatedObstacles = prevObstacles.map((obstacle) => ({
          ...obstacle,
          y: obstacle.y + OBSTACLE_SPEED * (delta / 16.67),
        }));

        // Collision detection
        const adjustment = OBSTACLE_HEIGHT * 0.5; // Adjust top and bottom by 50% of obstacle height
        const playerTop = 90 - adjustment; // Adjusted top edge of the player
        const playerBottom = 100 - adjustment; // Adjusted bottom edge of the player
        const playerLeft = playerLaneRef.current * LANE_WIDTH; // Player's left edge
        const playerRight = playerLeft + LANE_WIDTH; // Player's right edge

        const collisionDetected = updatedObstacles.some((obstacle) => {
          const obstacleTop = obstacle.y; // Obstacle's top edge
          const obstacleBottom = obstacle.y + OBSTACLE_HEIGHT; // Obstacle's bottom edge
          const obstacleLeft = obstacle.lane * LANE_WIDTH; // Obstacle's left edge
          const obstacleRight = obstacleLeft + LANE_WIDTH; // Obstacle's right edge

          // Detect collision:
          return (
            playerRight > obstacleLeft && // Player's right overlaps obstacle's left
            playerLeft < obstacleRight && // Player's left overlaps obstacle's right
            playerBottom > obstacleTop && // Player's adjusted bottom overlaps obstacle's top
            playerTop < obstacleBottom // Player's adjusted top overlaps obstacle's bottom
          );
        });

        setIsCollision(collisionDetected);

        // Update obstacles and remove those off-screen
        const filteredObstacles = updatedObstacles.filter(
          (obstacle) => obstacle.y < 100
        );
        return filteredObstacles;
      });

      lastUpdateTimeRef.current = time;
      requestRef.current = requestAnimationFrame(updateGame);
    },
    [isPaused, playerLaneRef, OBSTACLE_SPEED, OBSTACLE_HEIGHT, LANE_WIDTH] // Dependencies
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateGame);
    return () => cancelAnimationFrame(requestRef.current);
  }, [isPaused, updateGame]);

  // Restart the game
  const handleNewGame = () => {
    setObstacles([]);
    setPlayerLane(Math.floor(LANES / 2));
    setIsCollision(false);
    lastUpdateTimeRef.current = null;

    // Reset and start the timer
    setElapsedTime(0);
    setLevel(1);
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1); // Increment by 1 tenth of a second
    }, 100); // 100ms interval
  };

  // Toggle pause state
  const togglePause = () => {
    setIsPaused((prev) => {
      if (prev) {
        // Resume the timer
        timerRef.current = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 100);
      } else {
        // Pause the timer
        if (timerRef.current) clearInterval(timerRef.current);
      }
      return !prev;
    });
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  return (
    <Box>
      {/* Control Buttons */}
      <Button variant="contained" onClick={handleNewGame}>
        New Game
      </Button>
      <Button
        variant="contained"
        onClick={togglePause}
        style={{ marginLeft: "10px" }}
      >
        {isPaused ? "Play" : "Pause"}
      </Button>
      <Typography variant="body1">
        Time Elapsed: {(elapsedTime / 10).toFixed(1)} seconds
      </Typography>
      <Typography>Level: {level}</Typography>

      <Box
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          position: "relative",
          width: "300px",
          height: "600px",
          backgroundColor: isCollision ? "#ff9999" : "#EEEEEE",
          overflow: "hidden",
          margin: "auto",
          transition: "background-color 0.1s ease",
        }}
      >
        {/* Player */}
        <Box
          style={{
            position: "absolute",
            bottom: "5%",
            left: `${playerLane * LANE_WIDTH}%`,
            width: `${LANE_WIDTH}%`,
            height: `${PLAYER_HEIGHT}%`,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/arcade/rookracer/rook.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></Box>

        {/* Obstacles */}
        {obstacles.map((obstacle, index) => (
          <Box
            key={index}
            style={{
              position: "absolute",
              top: `${obstacle.y}%`,
              left: `${obstacle.lane * LANE_WIDTH}%`,
              width: `${LANE_WIDTH}%`,
              height: `${OBSTACLE_HEIGHT}%`,
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/arcade/rookracer/rook.png)`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></Box>
        ))}
      </Box>
    </Box>
  );
};

export default RookRacer;
