import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const WhackAPawnHelp = () => {
  const { t } = useTranslation("Arcade");

  return (
    <Box>
      <Typography>{t("WhackAPawn.helpDetails.description")}</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.start_game.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.hit_pieces.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.hit_pieces.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.avoid_enemies.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.avoid_enemies.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.collect_lives.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.collect_lives.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.advance_levels.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.advance_levels.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "WhackAPawn.helpDetails.instructions.difficulty_level.primary"
            )}
            secondary={t(
              "WhackAPawn.helpDetails.instructions.difficulty_level.secondary"
            )}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default WhackAPawnHelp;
