import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import HelpModal from "../../../components/HelpModal";
import ChessClockHelp from "../../../help/ChessClockHelp";

import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";

import "./ChessClock.css";

function ChessClock() {
  const [whiteTime, setWhiteTime] = useState(600000); // Default 10 mins in milliseconds
  const [blackTime, setBlackTime] = useState(600000); // Default 10 mins in milliseconds
  const [activePlayer, setActivePlayer] = useState("white"); // "white" or "black"
  const [isRunning, setIsRunning] = useState(false);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [whiteCustomTime, setWhiteCustomTime] = useState(600000);
  const [blackCustomTime, setBlackCustomTime] = useState(600000);
  const [whiteIncrement, setWhiteIncrement] = useState(0); // Default 0 increment in milliseconds
  const [blackIncrement, setBlackIncrement] = useState(0); // Default 0 increment in milliseconds
  const whiteTimeRef = useRef(600000); // Store white's time independently
  const blackTimeRef = useRef(600000); // Store black's time independently
  const lastUpdateRef = useRef(null); // Track the last update timestamp
  const animationFrameRef = useRef(null); // Track the animation frame

  const [tempWhiteCustomTime, setTempWhiteCustomTime] =
    useState(whiteCustomTime);
  const [tempBlackCustomTime, setTempBlackCustomTime] =
    useState(blackCustomTime);
  const [tempWhiteIncrement, setTempWhiteIncrement] = useState(whiteIncrement);
  const [tempBlackIncrement, setTempBlackIncrement] = useState(blackIncrement);
  const [tempWhiteTime, setTempWhiteTime] = useState(whiteTime);
  const [tempBlackTime, setTempBlackTime] = useState(blackTime);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState("ten_min_no_increment");

  const whiteBoxRef = useRef(null);
  const blackBoxRef = useRef(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Tools");

  const presets = useMemo(
    () => [
      { type: "one_min_no_increment", time: 1 * 60 * 1000, increment: 0 },
      {
        type: "one_min_one_sec_increment",
        time: 1 * 60 * 1000,
        increment: 1000,
      },
      { type: "two_min_no_increment", time: 2 * 60 * 1000, increment: 0 },
      {
        type: "two_min_one_sec_increment",
        time: 2 * 60 * 1000,
        increment: 1000,
      },
      { type: "three_min_no_increment", time: 3 * 60 * 1000, increment: 0 },
      {
        type: "three_min_two_sec_increment",
        time: 3 * 60 * 1000,
        increment: 2000,
      },
      {
        type: "three_min_three_sec_increment",
        time: 3 * 60 * 1000,
        increment: 3000,
      },
      { type: "five_min_no_increment", time: 5 * 60 * 1000, increment: 0 },
      {
        type: "five_min_two_sec_increment",
        time: 5 * 60 * 1000,
        increment: 2000,
      },
      {
        type: "five_min_three_sec_increment",
        time: 5 * 60 * 1000,
        increment: 3000,
      },
      { type: "ten_min_no_increment", time: 10 * 60 * 1000, increment: 0 },
      {
        type: "ten_min_five_sec_increment",
        time: 10 * 60 * 1000,
        increment: 5000,
      },
      {
        type: "ten_min_ten_sec_increment",
        time: 10 * 60 * 1000,
        increment: 10000,
      },
      { type: "fifteen_min_no_increment", time: 15 * 60 * 1000, increment: 0 },
      {
        type: "fifteen_min_ten_sec_increment",
        time: 15 * 60 * 1000,
        increment: 10000,
      },
      {
        type: "twenty_five_min_no_increment",
        time: 25 * 60 * 1000,
        increment: 0,
      },
      {
        type: "twenty_five_min_ten_sec_increment",
        time: 25 * 60 * 1000,
        increment: 10000,
      },
      { type: "thirty_min_no_increment", time: 30 * 60 * 1000, increment: 0 },
      {
        type: "thirty_min_ten_sec_increment",
        time: 30 * 60 * 1000,
        increment: 10000,
      },
      {
        type: "sixty_min_thirty_sec_increment",
        time: 60 * 60 * 1000,
        increment: 30000,
      },
      { type: "ninety_min_no_increment", time: 90 * 60 * 1000, increment: 0 },
      {
        type: "ninety_min_ten_sec_increment",
        time: 90 * 60 * 1000,
        increment: 10000,
      },
      {
        type: "ninety_min_thirty_sec_increment",
        time: 90 * 60 * 1000,
        increment: 30000,
      },
      {
        type: "one_twenty_min_no_increment",
        time: 120 * 60 * 1000,
        increment: 0,
      },
      {
        type: "one_twenty_min_thirty_sec_increment",
        time: 120 * 60 * 1000,
        increment: 30000,
      },
    ],
    []
  );

  const { setShareData } = useShare();
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/chessclock",
      title: "Chessboard Magic - Chess Clock",
      description:
        "A customizable and simple chess clock with time controls, pause/restart, fullscreen mode, and intuitive player switching. Designed for Mobile Phones.",
    };

    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    const updateTimer = () => {
      const now = performance.now();
      const elapsed = now - (lastUpdateRef.current || now); // Calculate elapsed time
      lastUpdateRef.current = now; // Update the last timestamp

      if (isRunning) {
        if (activePlayer === "white") {
          whiteTimeRef.current = Math.max(0, whiteTimeRef.current - elapsed); // Decrement white time
          setWhiteTime(whiteTimeRef.current); // Update React state
        } else if (activePlayer === "black") {
          blackTimeRef.current = Math.max(0, blackTimeRef.current - elapsed); // Decrement black time
          setBlackTime(blackTimeRef.current); // Update React state
        }
      }

      animationFrameRef.current = requestAnimationFrame(updateTimer); // Schedule next update
    };

    if (isRunning) {
      lastUpdateRef.current = performance.now(); // Initialize the timer
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    } else {
      cancelAnimationFrame(animationFrameRef.current); // Stop the timer
    }

    return () => cancelAnimationFrame(animationFrameRef.current); // Cleanup
  }, [isRunning, activePlayer]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours > 0 ? `${hours}:` : ""}${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handlePlayerClick = (player) => {
    if (!isRunning) return;

    const whiteLayer = whiteBoxRef.current.querySelector(".green-layer");
    const blackLayer = blackBoxRef.current.querySelector(".green-layer");

    // Prevent interaction if time is zero
    if (
      (player === "white" && whiteTime === 0) ||
      (player === "black" && blackTime === 0)
    ) {
      return;
    }

    if (player === activePlayer) {
      const timeline = gsap.timeline({
        onComplete: () => {
          setActivePlayer(player === "white" ? "black" : "white");
          if (player === "white") {
            gsap.set(whiteLayer, { height: "0%" });
            gsap.set(blackLayer, { height: "100%" });
          } else {
            gsap.set(whiteLayer, { height: "100%" });
            gsap.set(blackLayer, { height: "0%" });
          }
        },
      });

      timeline.to(whiteLayer, {
        height: player === "white" ? "0%" : "100%",
        duration: 0.3,
        ease: "power2.out",
      });

      timeline.to(
        blackLayer,
        {
          height: player === "white" ? "100%" : "0%",
          duration: 0.3,
          ease: "power2.out",
        },
        0
      );
    }
  };

  const handleStartStop = () => {
    setIsRunning(!isRunning);
  };

  const toggleFullscreen = () => {
    const element = document.documentElement; // Or use a specific element like a wrapper div
    if (!document.fullscreenElement) {
      // Request fullscreen
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // For Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // For Safari
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // For IE/Edge
        element.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // For Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // For Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // For IE/Edge
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setWhiteTime(whiteCustomTime);
    setBlackTime(blackCustomTime);
    whiteTimeRef.current = whiteCustomTime;
    blackTimeRef.current = blackCustomTime;
  }, [whiteCustomTime, blackCustomTime]);

  return (
    <Box>
      <ContentHeader
        title={t("ChessClock.header.title")}
        subtitle={t("ChessClock.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>Chess Clock</title>
        <meta
          name="description"
          content="A customizable and simple chess clock with time controls, pause/restart, fullscreen mode, and intuitive player switching. Designed for Mobile Phones."
        />
        <meta property="og:title" content="Chess Clock" />
        <meta
          property="og:description"
          content="A customizable and simple chess clock with time controls, pause/restart, fullscreen mode, and intuitive player switching. Designed for Mobile Phones."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/chessclock.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/chessclock`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Clock" />
        <meta
          name="twitter:description"
          content="A customizable and simple chess clock with time controls, pause/restart, fullscreen mode, and intuitive player switching. Designed for Mobile Phones."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/chessclock.png`}
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: isFullscreen ? "100vh" : "calc(100vh - 64px)",
          justifyContent: "space-between",
          position: isFullscreen ? "fixed" : "relative",
          top: isFullscreen ? 0 : "unset",
          left: isFullscreen ? 0 : "unset",
          width: isFullscreen ? "100vw" : "100%",
          zIndex: isFullscreen ? 5000 : "auto",
          backgroundColor: isFullscreen ? "black" : "transparent",
        }}
      >
        {/* Wrapper Box for all three sections */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%", // Take full height of the parent container
            justifyContent: "space-between",
          }}
        >
          {/* Top Box (Player 1 - White) */}
          <Box
            className="clickable-box"
            onClick={() => handlePlayerClick("white")}
            onMouseDown={(e) => e.preventDefault()}
            onTouchStart={(e) => e.preventDefault()}
            ref={whiteBoxRef}
            sx={{
              position: "relative", // Enable stacking of layers
              flex: 1, // Take up equal space with Black Box
              display: "flex",
              flexDirection: "column", // Stack time and increment vertically
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "4rem",
              cursor: "pointer",
              transform: "rotate(180deg)", // Keep the rotation
              userSelect: "none",
              overflow: "hidden", // Ensure layers do not overflow the box
            }}
          >
            {/* Grey Layer */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%", // Fully covers the parent
                backgroundColor:
                  activePlayer === "white" && whiteTime === 0 ? "red" : "grey",
                zIndex: 1, // Behind green layer
              }}
            />

            {/* Green Layer */}
            <Box
              className="green-layer"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: activePlayer === "white" ? "100%" : "0%", // Dynamic height
                backgroundColor: whiteTime === 0 ? "red" : "green",
                zIndex: 2, // Above grey layer
              }}
            />

            {/* Content */}
            <Box
              sx={{
                position: "relative", // Ensure text stays above layers
                zIndex: 3, // Text above green and grey layers
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Time */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline", // Align the time and milliseconds
                }}
              >
                <span>{formatTime(whiteTime)}</span>
              </Box>

              {/* Increment */}
              {whiteIncrement > 0 && (
                <Typography
                  variant="caption" // Smaller text for increment
                  sx={{
                    fontSize: "1rem",
                    marginTop: "0.5rem",
                    color: "lightgrey",
                  }}
                >
                  +{Math.floor(whiteIncrement / 1000)}s increment
                </Typography>
              )}
            </Box>
          </Box>

          {/* Middle Box (Menu) */}
          <Box
            sx={{
              height: "50px", // Set a fixed height for the middle box
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 2,
              background: colors.black[100],
              padding: "1rem", // Optional: Add padding for better spacing
            }}
          >
            <IconButton color="inherit" onClick={handleStartStop}>
              {isRunning ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setIsRunning(false);
                setIsEditOpen(true);
                setTempWhiteTime(whiteTime);
                setTempBlackTime(blackTime);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setIsRunning(false);
                setIsConfirmOpen(true);
              }}
            >
              <RestartAltIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setIsRunning(false);
                setIsConfigurationOpen(true);
              }}
            >
              <SettingsIcon />
            </IconButton>

            <IconButton color="inherit" onClick={toggleFullscreen}>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            {!isFullscreen && (
              <IconButton
                color="inherit"
                onClick={() => {
                  setIsRunning(false); // Pause the clock if running
                  handleClickOpen();
                }}
              >
                <HelpOutlineIcon />
              </IconButton>
            )}
            <HelpModal
              open={open}
              onClose={handleClose}
              title={t("ChessClock.helpDetails.title")}
              content={<ChessClockHelp />}
            />
          </Box>

          {/* Bottom Box (Player 2 - Black) */}
          <Box
            className="clickable-box"
            onClick={() => handlePlayerClick("black")}
            onMouseDown={(e) => e.preventDefault()}
            onTouchStart={(e) => e.preventDefault()}
            ref={blackBoxRef}
            sx={{
              position: "relative", // Enable stacking of layers
              flex: 1, // Take equal space with White Box
              display: "flex",
              flexDirection: "column", // Stack time and increment vertically
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "4rem",
              cursor: "pointer",
              userSelect: "none",
              overflow: "hidden", // Ensure layers do not overflow the box
            }}
          >
            {/* Grey Layer */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%", // Fully covers the parent
                backgroundColor:
                  activePlayer === "black" && blackTime === 0 ? "red" : "grey",
                zIndex: 1, // Behind green layer
              }}
            />

            {/* Green Layer */}
            <Box
              className="green-layer"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: activePlayer === "black" ? "100%" : "0%", // Dynamic height
                backgroundColor: blackTime === 0 ? "red" : "green",
                zIndex: 2, // Above grey layer
              }}
            />

            {/* Content */}
            <Box
              sx={{
                position: "relative", // Ensure text stays above layers
                zIndex: 3, // Text above green and grey layers
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Time */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline", // Align the time and milliseconds
                }}
              >
                <span>{formatTime(blackTime)}</span>
              </Box>

              {/* Increment */}
              {blackIncrement > 0 && (
                <Typography
                  variant="caption" // Smaller text for increment
                  sx={{
                    fontSize: "1rem",
                    marginTop: "0.5rem",
                    color: "lightgrey",
                  }}
                >
                  +{Math.floor(blackIncrement / 1000)}s increment
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        {/* Confirmation Dialog for Restart */}
        <Dialog
          open={isConfirmOpen}
          onClose={() => {
            setIsRunning(false); // Ensure clocks remain paused
            setIsConfirmOpen(false);
          }}
          sx={{
            zIndex: 6000,
          }}
        >
          <DialogTitle>
            {t("ChessClock.labels.confirmRestartTitle")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t("ChessClock.labels.confirmRestartDescription")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setWhiteTime(whiteCustomTime); // Reset White time to the preset
                setBlackTime(blackCustomTime); // Reset Black time to the preset
                setWhiteCustomTime(tempWhiteCustomTime); // Update white custom time
                setBlackCustomTime(tempBlackCustomTime); // Update black custom time
                whiteTimeRef.current = whiteCustomTime; // Reset White time in reference
                blackTimeRef.current = blackCustomTime; // Reset Black time in reference

                // Reset active player and set the green layer
                setActivePlayer("white"); // Reset active player to white
                const whiteLayer =
                  whiteBoxRef.current.querySelector(".green-layer");
                const blackLayer =
                  blackBoxRef.current.querySelector(".green-layer");
                gsap.set(whiteLayer, { height: "100%" }); // Green layer fully visible for white
                gsap.set(blackLayer, { height: "0%" }); // Green layer hidden for black

                setIsRunning(false); // Ensure clocks remain paused
                setIsConfirmOpen(false); // Close the dialog
              }}
            >
              {t("ChessClock.buttons.confirm")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setIsRunning(false); // Ensure clocks remain paused
                setIsConfirmOpen(false); // Close the dialog
              }}
            >
              {t("ChessClock.buttons.cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Configuration Dialog */}
        <Dialog
          open={isConfigurationOpen}
          onClose={() => setIsConfigurationOpen(false)}
          sx={{
            zIndex: 6000,
          }}
        >
          <DialogTitle>{t("ChessClock.labels.configureTime")}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                select
                value={selectedPreset} // Bind the value to the temporary preset state
                onChange={(e) => {
                  const selectedType = e.target.value; // Get the selected preset type
                  setSelectedPreset(selectedType); // Update the selected preset state

                  const selectedPreset = presets.find(
                    (preset) => preset.type === selectedType
                  );

                  if (selectedPreset) {
                    // Update temporary custom times and increments, not the refs or main state
                    setTempWhiteCustomTime(selectedPreset.time);
                    setTempBlackCustomTime(selectedPreset.time);
                    setTempWhiteIncrement(selectedPreset.increment || 0);
                    setTempBlackIncrement(selectedPreset.increment || 0);
                  }
                }}
                SelectProps={{
                  native: true,
                }}
                fullWidth
              >
                <option value="" disabled>
                  {t("ChessClock.labels.selectPreset")}
                </option>
                {presets.map((preset) => (
                  <option key={preset.type} value={preset.type}>
                    {t(`ChessClock.presets.${preset.type}`)}
                  </option>
                ))}
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                // Apply the selected preset and close the dialog
                setWhiteTime(tempWhiteCustomTime); // Apply to main state
                setBlackTime(tempBlackCustomTime); // Apply to main state
                whiteTimeRef.current = tempWhiteCustomTime; // Apply to refs
                blackTimeRef.current = tempBlackCustomTime; // Apply to refs
                setWhiteIncrement(tempWhiteIncrement); // Apply to main state
                setBlackIncrement(tempBlackIncrement); // Apply to main state

                setIsConfigurationOpen(false); // Close the dialog
                setIsRunning(false); // Ensure clocks are paused
                setActivePlayer("white"); // Set the first active player
                const whiteLayer =
                  whiteBoxRef.current.querySelector(".green-layer");
                const blackLayer =
                  blackBoxRef.current.querySelector(".green-layer");
                gsap.set(whiteLayer, { height: "100%" }); // Green layer fully visible for white
                gsap.set(blackLayer, { height: "0%" }); // Green layer hidden for black
              }}
            >
              {t("ChessClock.buttons.save")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                // Discard changes and close the dialog
                setIsConfigurationOpen(false);
              }}
            >
              {t("ChessClock.buttons.cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog
          open={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          sx={{
            zIndex: 6000,
          }}
        >
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="h6">
                {t("ChessClock.labels.editCurrentTimes")}
              </Typography>

              {/* White Time */}
              <Typography variant="subtitle1">
                {t("ChessClock.labels.whiteTimes")}
              </Typography>
              <Box display="flex" gap={2}>
                <TextField
                  select
                  label={t("ChessClock.labels.hours")}
                  value={Math.floor(tempWhiteTime / 3600000)} // Temporary state for hours
                  onChange={(e) =>
                    setTempWhiteTime(
                      Number(e.target.value) * 3600000 +
                        (tempWhiteTime % 3600000)
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t("ChessClock.labels.minutes")}
                  value={Math.floor((tempWhiteTime % 3600000) / 60000)} // Temporary state for minutes
                  onChange={(e) =>
                    setTempWhiteTime(
                      Math.floor(tempWhiteTime / 3600000) * 3600000 +
                        Number(e.target.value) * 60000 +
                        (tempWhiteTime % 60000)
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t("ChessClock.labels.seconds")}
                  value={Math.floor((tempWhiteTime % 60000) / 1000)} // Temporary state for seconds
                  onChange={(e) =>
                    setTempWhiteTime(
                      Math.floor(tempWhiteTime / 60000) * 60000 +
                        Number(e.target.value) * 1000
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
              </Box>

              {/* Black Time */}
              <Typography variant="subtitle1">
                {t("ChessClock.labels.blackTimes")}
              </Typography>
              <Box display="flex" gap={2}>
                <TextField
                  select
                  label={t("ChessClock.labels.hours")}
                  value={Math.floor(tempBlackTime / 3600000)} // Temporary state for hours
                  onChange={(e) =>
                    setTempBlackTime(
                      Number(e.target.value) * 3600000 +
                        (tempBlackTime % 3600000)
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t("ChessClock.labels.minutes")}
                  value={Math.floor((tempBlackTime % 3600000) / 60000)} // Temporary state for minutes
                  onChange={(e) =>
                    setTempBlackTime(
                      Math.floor(tempBlackTime / 3600000) * 3600000 +
                        Number(e.target.value) * 60000 +
                        (tempBlackTime % 60000)
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t("ChessClock.labels.seconds")}
                  value={Math.floor((tempBlackTime % 60000) / 1000)} // Temporary state for seconds
                  onChange={(e) =>
                    setTempBlackTime(
                      Math.floor(tempBlackTime / 60000) * 60000 +
                        Number(e.target.value) * 1000
                    )
                  }
                  SelectProps={{ native: true }}
                  sx={{ width: "120px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </TextField>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                // Save changes to main states
                setWhiteTime(tempWhiteTime);
                setBlackTime(tempBlackTime);
                whiteTimeRef.current = tempWhiteTime; // Update refs
                blackTimeRef.current = tempBlackTime; // Update refs
                setIsEditOpen(false);
                setIsRunning(false); // Ensure clocks are paused
              }}
            >
              {t("ChessClock.buttons.save")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                // Discard changes and close the dialog
                setIsEditOpen(false);
                setIsRunning(false); // Ensure clocks are paused
              }}
            >
              {t("ChessClock.buttons.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ChessClock;
