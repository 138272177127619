import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CheckmateTrainerHelp = () => {
  const { t } = useTranslation("Learn");
  return (
    <>
      <Typography paragraph>
        {t("CheckmateTrainer.helpDetails.description")}
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t(
              "CheckmateTrainer.helpDetails.instructions.start_game.primary"
            )}
            secondary={t(
              "CheckmateTrainer.helpDetails.instructions.start_game.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "CheckmateTrainer.helpDetails.instructions.objective.primary"
            )}
            secondary={t(
              "CheckmateTrainer.helpDetails.instructions.objective.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "CheckmateTrainer.helpDetails.instructions.tips.primary"
            )}
            secondary={t(
              "CheckmateTrainer.helpDetails.instructions.tips.secondary"
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t(
              "CheckmateTrainer.helpDetails.instructions.draw_rules.primary"
            )}
            secondary={t(
              "CheckmateTrainer.helpDetails.instructions.draw_rules.secondary"
            )}
          />
        </ListItem>
      </List>
    </>
  );
};

export default CheckmateTrainerHelp;
