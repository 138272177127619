import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import PromotionComponent from "./PromotionComponent";

const ContentHeader = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack on small screens, side-by-side on large screens
        gap: 0, // Adds spacing between the two boxes
        mb: 3,
        pr: { xs: 0, md: 0 },
        mt: 1,
        justifyContent: "flex-start", // Left-align the boxes
        alignItems: "flex-start", // Align items to the top if their heights differ
      }}
    >
      {/* Left Content */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" }, // 100% on small screens, 50% on medium and larger screens
          flex: "0 0 auto", // Prevents flexbox from resizing
          p: "15px 20px 10px 10px",
          m: "10px 0px 0px 0px",
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundSize: "auto 50px",
          backgroundPosition: "left top",
          backgroundRepeat: "no-repeat",
          ...(props.borderColor && {
            borderLeft: `3px solid ${props.borderColor}`,
          }),
        }}
      >
        <Typography sx={{ color: props.color }} variant="h5">
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography
            sx={{
              color: theme.palette.primary,
            }}
          >
            {props.subtitle}
          </Typography>
        )}
      </Box>

      {/* Right Content */}
      {props.showAds !== false && (
        <Box
          sx={{
            width: { xs: "100%", md: "50%" }, // 100% on small screens, 50% on medium and larger screens
            height: "100px",
            flex: "0 0 auto", // Prevents flexbox from resizing
            pt: 1,
          }}
        >
          <PromotionComponent />
        </Box>
      )}
    </Box>
  );
};

export default ContentHeader;
