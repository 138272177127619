import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Box, Button, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { Helmet } from "react-helmet";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";

import HelpModal from "../../../components/HelpModal";
import { tokens } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import { updateUserData } from "../../../features/Firestore";
import { useShare } from "../../../context/ShareContext";
import JumpingKnightHelp from "../../../help/JumpingKnightHelp";

const JumpingKnight = () => {
  const initialContainerWidth = 600;
  const initialContainerHeight = 600;

  const [containerWidth, setContainerWidth] = useState(
    window.innerWidth < initialContainerWidth
      ? window.innerWidth
      : initialContainerWidth
  );
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight < initialContainerHeight
      ? window.innerHeight
      : initialContainerHeight
  );
  const [knightSize, setKnightSize] = useState(100);
  const [grace, setGrace] = useState(containerWidth * 0.01); // Set initial grace value based on container width
  const [yPosition, setYPosition] = useState((containerWidth - knightSize) / 2);
  const [isJumping, setIsJumping] = useState(false);
  const [obstacles, setObstacles] = useState([]);
  const [hasCollision, setHasCollision] = useState(false);
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(null);
  const [isGameActive, setIsGameActive] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const jumpHeight = useMemo(() => knightSize / 1.5, [knightSize]); // Jump height scales with knight size
  const maxYPosition = useMemo(
    () => containerHeight - knightSize - 20,
    [containerHeight, knightSize]
  );
  const obstacleWidth = useMemo(() => knightSize * 0.8, [knightSize]);
  const obstacleSpeed = useMemo(() => containerWidth / 100, [containerWidth]);
  const baseMinInterval = 3000;
  const baseMaxInterval = 4500;

  const gapHeight = useMemo(() => knightSize * 2, [knightSize]);

  const knightRef = useRef(null);
  const canvasRef = useRef(null);
  const obstacleTimeoutRef = useRef(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation("Arcade");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();

  const [playSound, setPlaySound] = useState(true);
  const toggleSound = () => {
    setPlaySound((prev) => {
      return !prev;
    });
  };

  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const musicRef = useRef(null);

  const toggleMusic = () => {
    setIsMusicPlaying((prev) => !prev);
  };

  // Initialize the audio in the useEffect
  useEffect(() => {
    // Ensure musicRef.current is set to an Audio object
    musicRef.current = new Audio(
      `${process.env.PUBLIC_URL}/sounds/relaxing.mp3`
    );
    musicRef.current.loop = true; // Set the music to loop

    return () => {
      if (musicRef.current) {
        musicRef.current.pause();
        musicRef.current.currentTime = 0; // Reset the music
      }
    };
  }, []);

  useEffect(() => {
    if (isMusicPlaying && isGameActive) {
      musicRef.current?.play();
    } else {
      musicRef.current?.pause();
    }
  }, [isGameActive, isMusicPlaying]);

  useEffect(() => {
    if (gameOver && musicRef.current) {
      musicRef.current.pause();
      musicRef.current.currentTime = 0; // Reset to the beginning
    }
  }, [gameOver]);

  useEffect(() => {
    if (userData?.Arcade?.JumpingKnight) {
      const { SoundEnabled, MusicEnabled } = userData.Arcade.JumpingKnight;

      if (SoundEnabled !== undefined) {
        setPlaySound(SoundEnabled);
      }
      if (MusicEnabled !== undefined) {
        setIsMusicPlaying(MusicEnabled);
      }
    }
  }, [userData]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Adjust container and element sizes based on window size
  useEffect(() => {
    const handleResize = () => {
      const newContainerWidth =
        window.innerWidth < initialContainerWidth
          ? window.innerWidth
          : initialContainerWidth;
      setContainerWidth(newContainerWidth - 100);
      setContainerHeight(newContainerWidth - 100);
      setKnightSize(newContainerWidth / 6); // Knight size is 1/6th of container width for scalability
      setGrace(newContainerWidth * 0.01); // Update grace based on the container width (1% of width)
      setYPosition((containerWidth - knightSize) / 2);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial sizes on mount

    return () => window.removeEventListener("resize", handleResize);
  }, [containerWidth, knightSize]);

  // Gravity effect
  useEffect(() => {
    if (!isGameActive) return;
    const interval = setInterval(() => {
      setYPosition((prevY) =>
        Math.min(prevY + knightSize * 0.05, maxYPosition)
      );
    }, 50);
    return () => clearInterval(interval);
  }, [isGameActive, maxYPosition, knightSize]);

  // Background scrolling effect
  useEffect(() => {
    if (!isGameActive) return;
    const backgroundInterval = setInterval(() => {
      setBackgroundPosition((prevPosition) => prevPosition - 1);
    }, 30);

    return () => clearInterval(backgroundInterval);
  }, [isGameActive]);

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/jumpingknight",
      title: "Chessboard Magic - Jumping Knight",
      description:
        "Jumping Knight is a fun arcade game where a knight leaps through obstacles, navigating a dynamic chess-themed adventure!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData, t]);

  // Handle jump logic
  const handleJump = useCallback(() => {
    if (isGameActive && !isJumping) {
      setIsJumping(true);
      setYPosition((prevY) => Math.max(prevY - jumpHeight, 0));
      setTimeout(() => setIsJumping(false), 200);

      if (playSound) {
        const sound = new Audio(`${process.env.PUBLIC_URL}/sounds/boing.mp3`); // Update path as needed
        sound.volume = 0.2;
        sound.play();
      }
    }
  }, [isJumping, jumpHeight, isGameActive, playSound]);

  useEffect(() => {
    const handleKeyPress = () => handleJump();
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [handleJump]);

  const handleTouchOrClick = (event) => {
    event.preventDefault();
    handleJump();
  };

  // Generate obstacles at random intervals
  useEffect(() => {
    if (!isGameActive) return;

    const createObstacle = () => {
      const minGapStart = gapHeight / 4;
      const maxGapStart = containerWidth - gapHeight - gapHeight / 4;
      const gapStart =
        minGapStart + Math.random() * (maxGapStart - minGapStart);

      setObstacles((prevObstacles) => [
        ...prevObstacles,
        { x: containerWidth, gapStart, passed: false },
      ]);

      if (obstacleTimeoutRef.current) clearTimeout(obstacleTimeoutRef.current);
      const scaleFactor = containerWidth / initialContainerWidth;
      const minInterval = baseMinInterval * scaleFactor;
      const maxInterval = baseMaxInterval * scaleFactor;
      const nextInterval =
        minInterval + Math.random() * (maxInterval - minInterval);
      obstacleTimeoutRef.current = setTimeout(createObstacle, nextInterval);
    };

    createObstacle();

    return () => clearTimeout(obstacleTimeoutRef.current);
  }, [isGameActive, gapHeight, containerWidth, containerHeight]);

  // Move obstacles and check for collisions
  useEffect(() => {
    if (!isGameActive) return;

    const moveObstacles = setInterval(() => {
      setObstacles((prevObstacles) => {
        return prevObstacles
          .map((obstacle) => {
            if (
              !obstacle.passed &&
              obstacle.x < (containerWidth - knightSize) / 2
            ) {
              setScore((prevScore) => prevScore + 1);
              obstacle.passed = true;
            }
            return { ...obstacle, x: obstacle.x - obstacleSpeed };
          })
          .filter((obstacle) => obstacle.x + obstacleWidth > 0);
      });
    }, 20);

    // Check for collisions after moving obstacles
    const checkCollisions = () => {
      const knightX = (containerWidth - knightSize) / 2;
      const knightY = yPosition;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      ctx.clearRect(0, 0, knightSize, knightSize);
      const knightImage = knightRef.current;

      ctx.drawImage(knightImage, 0, 0, knightSize, knightSize);
      const pixelData = ctx.getImageData(0, 0, knightSize, knightSize).data;

      for (const obstacle of obstacles) {
        // Define the inner bounds of the top and bottom obstacles
        const innerTopObstacleBottomY = obstacle.gapStart; // Bottom edge of the top obstacle (unaltered)
        const innerBottomObstacleTopY = obstacle.gapStart + gapHeight; // Top edge of the bottom obstacle (unaltered)
        const innerObstacleLeftX = obstacle.x + grace; // Left edge, accounting for grace
        const innerObstacleRightX = obstacle.x + obstacleWidth - grace; // Right edge, accounting for grace

        for (let y = 0; y < knightSize; y++) {
          for (let x = 0; x < knightSize; x++) {
            const alpha = pixelData[(y * knightSize + x) * 4 + 3];
            if (alpha > 0) {
              const knightPixelX = knightX + x;
              const knightPixelY = knightY + y;

              // Check collision with the adjusted inner bounds of the top obstacle
              if (
                knightPixelX >= innerObstacleLeftX &&
                knightPixelX <= innerObstacleRightX &&
                knightPixelY >= 0 &&
                knightPixelY <= innerTopObstacleBottomY - grace
              ) {
                setGameOver(true);
                setHasCollision(true);
                setIsGameActive(false);

                trackEvent(
                  "Arcade",
                  "JumpingKnight-Complete",
                  "Jumping Knight"
                );
                // Internal Tracking
                if (userData) {
                  // Save sound and music settings
                  userData.Arcade.JumpingKnight.SoundEnabled = playSound;
                  userData.Arcade.JumpingKnight.MusicEnabled = isMusicPlaying;

                  // Update the high score if applicable
                  userData.Arcade.JumpingKnight.HighScore = Math.max(
                    userData.Arcade.JumpingKnight.HighScore || 0,
                    score
                  );

                  // Save updated userData
                  updateUserData(userRef, userData);
                }

                return;
              }

              // Check collision with the adjusted inner bounds of the bottom obstacle
              if (
                knightPixelX >= innerObstacleLeftX &&
                knightPixelX <= innerObstacleRightX &&
                knightPixelY >= innerBottomObstacleTopY + grace &&
                knightPixelY <= containerHeight
              ) {
                setGameOver(true);
                setHasCollision(true);
                setIsGameActive(false);

                trackEvent(
                  "Arcade",
                  "JumpingKnight-Complete",
                  "Jumping Knight"
                );
                // Internal Tracking
                if (userData) {
                  // Save sound and music settings
                  userData.Arcade.JumpingKnight.SoundEnabled = playSound;
                  userData.Arcade.JumpingKnight.MusicEnabled = isMusicPlaying;

                  // Update the high score if applicable
                  userData.Arcade.JumpingKnight.HighScore = Math.max(
                    userData.Arcade.JumpingKnight.HighScore || 0,
                    score
                  );

                  // Save updated userData
                  updateUserData(userRef, userData);
                }
                return;
              }
            }
          }
        }
      }

      setHasCollision(false);
    };

    checkCollisions();

    return () => clearInterval(moveObstacles);
  }, [
    yPosition,
    obstacles,
    gapHeight,
    containerWidth,
    containerHeight,
    isGameActive,
    knightSize,
    obstacleWidth,
    obstacleSpeed,
    grace,
    score,
    userData,
    userRef,
    playSound,
    isMusicPlaying,
  ]);

  // New Game function with countdown
  const startNewGame = () => {
    setGameOver(false);
    setIsGameActive(false);
    setScore(0);
    setObstacles([]);
    setYPosition((containerHeight - knightSize) / 2);
    setHasCollision(false);

    setCountdown(3);
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(countdownInterval);
          setCountdown(null);
          setIsGameActive(true);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    trackEvent("Arcade", "JumpingKnight-Play", "Jumping Knight");
    // Internal Tracking
    if (userData) {
      if (!userData.Arcade) {
        userData.Arcade = {};
      }
      if (userData.Arcade.JumpingKnight) {
        userData.Arcade.JumpingKnight.Played =
          (userData.Arcade.JumpingKnight.Played || 0) + 1;
      } else {
        userData.Arcade.JumpingKnight = {
          Played: 1,
          MusicEnabled: true,
          SoundEnabled: true,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  return (
    <Box>
      <ContentHeader
        title={t("JumpingKnight.header.title")}
        subtitle={t("JumpingKnight.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Jumping Knight</title>
        <meta
          name="description"
          content="Jumping Knight is a fun arcade game where a knight leaps through obstacles, navigating a dynamic chess-themed adventure!"
        />
        <meta property="og:title" content="Jumping Knight" />
        <meta
          property="og:description"
          content="Jumping Knight is a fun arcade game where a knight leaps through obstacles, navigating a dynamic chess-themed adventure!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/arcade/jumpingknight.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/jumpingknight`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Jumping Knight" />
        <meta
          name="twitter:description"
          content="Jumping Knight is a fun arcade game where a knight leaps through obstacles, navigating a dynamic chess-themed adventure!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/arcade/jumpingknight.png`}
        />
      </Helmet>

      <Box
        style={{
          position: "relative",
          width: `${containerWidth}px`,
          height: `${containerHeight}px`,
          overflow: "hidden",
          backgroundColor: hasCollision ? "#ffcccc" : "#f0f0f0",
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/arcade/jumpingknight/background.png)`,
          backgroundPosition: `${backgroundPosition}px 0`,
          backgroundRepeat: "repeat-x",
          backgroundSize: "auto 100%",
        }}
        onClick={handleTouchOrClick}
        onTouchStart={handleTouchOrClick}
        sx={{ mb: 1 }}
      >
        {/* Knight */}
        <img
          ref={knightRef}
          src={`${process.env.PUBLIC_URL}/img/arcade/jumpingknight/jumpingknight.png`}
          alt="Jumping Knight"
          style={{
            position: "absolute",
            left: `${(containerWidth - knightSize) / 2}px`,
            width: `${knightSize}px`,
            height: `${knightSize}px`,
            transform: `translateY(${yPosition}px)`,
            userSelect: "none",
            outline: "none",
          }}
        />

        {/* Offscreen canvas for pixel-based collision detection */}
        <canvas
          ref={canvasRef}
          width={knightSize}
          height={knightSize}
          style={{ display: "none" }}
        />

        {/* Obstacles */}
        {obstacles.map((obstacle, index) => (
          <div key={index}>
            {/* Top Obstacle with Chessboard Pattern on Black */}
            <div
              style={{
                position: "absolute",
                left: `${obstacle.x}px`,
                top: 0,
                width: `${obstacleWidth}px`,
                height: `${obstacle.gapStart}px`,
                backgroundColor: "#000000",
                backgroundImage: `
                repeating-linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.05) 0,
                  rgba(255, 255, 255, 0.05) 20px,
                  transparent 20px,
                  transparent 40px
                ),
                repeating-linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0.05) 0,
                  rgba(255, 255, 255, 0.05) 20px,
                  transparent 20px,
                  transparent 40px
                )
              `,
                backgroundSize: "40px 40px",
              }}
            />

            {/* Bottom Obstacle with Chessboard Pattern on White */}
            <div
              style={{
                position: "absolute",
                left: `${obstacle.x}px`,
                top: `${obstacle.gapStart + gapHeight}px`,
                width: `${obstacleWidth}px`,
                height: `${
                  containerHeight - (obstacle.gapStart + gapHeight)
                }px`,
                backgroundColor: "#FFFFFF",
                backgroundImage: `
                repeating-linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.05) 0,
                  rgba(0, 0, 0, 0.05) 20px,
                  transparent 20px,
                  transparent 40px
                ),
                repeating-linear-gradient(
                  90deg,
                  rgba(0, 0, 0, 0.05) 0,
                  rgba(0, 0, 0, 0.05) 20px,
                  transparent 20px,
                  transparent 40px
                )
              `,
                backgroundSize: "40px 40px",
              }}
            />
          </div>
        ))}

        {/* Score Display during Game */}
        {isGameActive && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              display: "flex", // Align items
              flexDirection: "column", // Stack them vertically
              gap: "10px", // Add spacing between boxes
            }}
          >
            {/* Score Box */}
            <Box
              sx={{
                fontSize: "20px",
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "8px 12px",
                borderRadius: "8px",
              }}
            >
              {t("JumpingKnight.labels.score")}: {score}
            </Box>

            {/* High Score Box */}
            {userData?.Arcade?.JumpingKnight?.HighScore > 0 && (
              <Box
                sx={{
                  fontSize: "20px",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "8px 12px",
                  borderRadius: "8px",
                }}
              >
                {t("JumpingKnight.labels.high")}:{" "}
                {userData.Arcade.JumpingKnight.HighScore}
              </Box>
            )}
          </Box>
        )}

        {/* Countdown Display */}
        {countdown && countdown > 0 && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              textAlign: "center",
              fontSize: "100px",
            }}
          >
            {countdown}
          </div>
        )}

        {/* Overlay for Game Over Message */}
        {gameOver && !isGameActive && (
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            }}
            sx={{
              display: "flex",
              flexDirection: "column", // Stack items vertically
              justifyContent: "center", // Center items vertically
              alignItems: "center", // Center items horizontally
              gap: "20px", // Add space between items
            }}
          >
            {/* Game Over Text */}
            <div
              style={{
                fontSize: "36px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("JumpingKnight.labels.gameOver")}
            </div>

            {/* Final Score Text */}
            <div style={{ fontSize: "24px", textAlign: "center" }}>
              {t("JumpingKnight.labels.finalScore")}: {score}
            </div>

            {/* Buttons Row */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Layout buttons in a row
                flexWrap: "wrap", // Allow wrapping if needed
                justifyContent: "center", // Center buttons horizontally
                alignItems: "center", // Align buttons vertically
                gap: "10px", // Space between buttons
              }}
            >
              <Button
                onClick={startNewGame}
                variant="contained"
                startIcon={
                  <PlayCircleOutlineRoundedIcon
                    style={{ color: colors.material[1] }}
                  />
                }
                sx={{ mb: 1 }}
              >
                {t("JumpingKnight.buttons.newGame")}
              </Button>
              <Button
                onClick={toggleSound}
                variant="contained"
                sx={{
                  height: "37px", // Explicit height
                  width: "37px", // Explicit width (same as height for square)
                  padding: 0, // Remove internal padding
                  minWidth: 0, // Override default minWidth
                  borderRadius: "4px", // Optional: Rounded corners
                  mb: 1,
                }}
              >
                {playSound ? (
                  <VolumeUpIcon sx={{ fontSize: "20px" }} />
                ) : (
                  <VolumeOffIcon
                    sx={{ fontSize: "20px", color: colors.material[1] }}
                  />
                )}
              </Button>
              <Button
                onClick={toggleMusic}
                variant="contained"
                sx={{
                  height: "37px",
                  width: "37px",
                  padding: 0,
                  minWidth: 0,
                  borderRadius: "4px",
                  mb: 1,
                }}
              >
                {isMusicPlaying ? (
                  <MusicNoteIcon sx={{ fontSize: "20px" }} />
                ) : (
                  <MusicOffIcon
                    sx={{ fontSize: "20px", color: colors.material[1] }}
                  />
                )}
              </Button>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                startIcon={
                  <HelpOutlineIcon style={{ color: colors.material[1] }} />
                }
                sx={{ mb: 1 }}
              >
                {t("JumpingKnight.buttons.help")}
              </Button>
              <HelpModal
                open={open}
                onClose={handleClose}
                title={t("JumpingKnight.helpDetails.title")}
                content={<JumpingKnightHelp />}
              />
            </Box>
          </Box>
        )}

        {/* New Game Button (Standalone) */}
        {!isGameActive && !countdown && !gameOver && (
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            }}
            sx={{
              display: "flex",
              flexDirection: "row", // Layout elements in rows
              flexWrap: "wrap", // Allow wrapping
              justifyContent: "center", // Center items horizontally
              alignContent: "center", // Center rows vertically
              height: "100%", // Ensure full height usage
            }}
          >
            <Button
              onClick={startNewGame}
              variant="contained"
              sx={{ mr: 1, mb: 1 }}
              startIcon={
                <PlayCircleOutlineRoundedIcon
                  style={{ color: colors.material[1] }}
                />
              }
            >
              {t("JumpingKnight.buttons.newGame")}
            </Button>
            <Button
              onClick={toggleSound}
              variant="contained"
              sx={{
                mr: 1,
                mb: 1,
                height: "37px", // Explicit height
                width: "37px", // Explicit width (same as height for square)
                padding: 0, // Remove internal padding
                minWidth: 0, // Override default minWidth
                borderRadius: "4px", // Optional: Rounded corners
              }}
            >
              {playSound ? (
                <VolumeUpIcon sx={{ fontSize: "20px" }} />
              ) : (
                <VolumeOffIcon
                  sx={{ fontSize: "20px", color: colors.material[1] }}
                />
              )}
            </Button>
            <Button
              onClick={toggleMusic}
              variant="contained"
              sx={{
                mr: 1,
                mb: 1,
                height: "37px", // Explicit height
                width: "37px", // Explicit width (same as height for square)
                padding: 0, // Remove internal padding
                minWidth: 0, // Override default minWidth
                borderRadius: "4px", // Optional: Rounded corners
              }}
            >
              {isMusicPlaying ? (
                <MusicNoteIcon sx={{ fontSize: "20px" }} />
              ) : (
                <MusicOffIcon
                  sx={{ fontSize: "20px", color: colors.material[1] }}
                />
              )}
            </Button>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              startIcon={
                <HelpOutlineIcon style={{ color: colors.material[1] }} />
              }
              sx={{ mb: 1 }}
            >
              {t("JumpingKnight.buttons.help")}
            </Button>
            <HelpModal
              open={open}
              onClose={handleClose}
              title={t("JumpingKnight.helpDetails.title")}
              content={<JumpingKnightHelp />}
            ></HelpModal>
          </Box>
        )}
      </Box>
      <div m>Tap or press any Key to jump</div>
    </Box>
  );
};

export default JumpingKnight;
