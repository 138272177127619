import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  Alert,
  Collapse,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js"; // For handling game logic
import ContentHeader from "../../../components/ContentHeader";

import HelpModal from "../../../components/HelpModal";
import { Helmet } from "react-helmet";

import { themeColors } from "../../../styles/boardtheme";
import { tokens } from "../../../styles/theme";
import { pieceSets } from "../../../styles/pieceset";

import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";

import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";

import { trackEvent } from "../../../config/ga";
import { updateUserData } from "../../../features/Firestore";
import HandAndBrainHelp from "../../../help/HandAndBrainHelp";
import { useTranslation } from "react-i18next";

function HandAndBrain() {
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [color, setColor] = useState("white");
  const [strength, setStrength] = useState(12); // Depth of Stockfish
  const [movesHistory, setMovesHistory] = useState([]);
  const [isEngineStarted, setIsEngineStarted] = useState(false);
  const [recommendedPiece, setRecommendedPiece] = useState(null);
  const [recommendedMove, setRecommendedMove] = useState(null);
  const [previousRecommendedMove, setPreviousRecommendedMove] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false); // For showing the alert
  const [alertColor, setAlertColor] = useState("info"); // Default alert color
  const [gameOver, setGameOver] = useState(false); // For handling game over state
  const [gameResult, setGameResult] = useState(""); // Store the result of the game
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const stockfishWorker = useRef(null);
  const [gameReset, setGameReset] = useState(false);
  const { t } = useTranslation("Learn");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/openingexplorer",
      title: "Chessboard Magic - Hand and Brain",
      description:
        "The engine suggests which piece to move, and you decide how and where to move it.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  // Load Stockfish as a Web Worker from the public folder
  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-lite-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  const getPieceRecommendation = useCallback(
    (fen) => {
      const pieceNameMapping = {
        p: "Pawn",
        n: "Knight",
        b: "Bishop",
        r: "Rook",
        q: "Queen",
        k: "King",
      };

      if (game.isGameOver()) {
        return; // Exit early if the game is over
      }

      if (stockfishWorker.current) {
        // Post the current FEN and ask Stockfish for the best move
        stockfishWorker.current.postMessage(`position fen ${fen}`);
        stockfishWorker.current.postMessage(`go depth ${strength}`);

        stockfishWorker.current.onmessage = (event) => {
          const message = event.data;
          if (message.startsWith("bestmove")) {
            const move = message.split(" ")[1]; // e.g., "e2e4"
            const pieceSymbol = game.get(move.substring(0, 2)).type; // Get piece type from the best move (e.g., "p" for pawn)

            // Use the pieceNameMapping to get the full name of the piece
            const pieceName = pieceNameMapping[pieceSymbol];

            // Update the recommended piece with the full name
            setRecommendedPiece(pieceName);

            const from = move.substring(0, 2); // e.g., "e2"
            const to = move.substring(2, 4); // e.g., "e4"

            // Make a temporary move to retrieve SAN notation
            const gameCopy = new Chess(fen);
            const tempMove = gameCopy.move({ from, to });
            setRecommendedMove(tempMove.san);
          }
        };
      }
    },
    [game, stockfishWorker, strength]
  );

  const newGame = () => {
    const newGame = new Chess();
    setIsEngineStarted(false); // Set engine to off

    setGame(newGame);
    setFen(newGame.fen());
    setMovesHistory([]); // Clear moves history
    setRecommendedPiece(""); // Clear recommended piece
    setRecommendedMove(""); // Clear recommended move
    setPreviousRecommendedMove(""); // Clear previous recommendation
    setAlertOpen(false); // Close any open alerts
    setGameOver(false); // Reset game over state
    setGameResult(""); // Reset game result

    // GA Tracking and Internal Tracking
    trackEvent("Learn", "HandAndBrain-Play", "Hand and Brain");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.HandAndBrain) {
        userData.Puzzles.HandAndBrain.Played =
          (userData.Puzzles.HandAndBrain.Played || 0) + 1;
      } else {
        userData.Puzzles.HandAndBrain = {
          Played: 1,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const startEngine = () => {
    setIsEngineStarted(true); // Switch engine mode on

    // Determine which color's turn it is and start the engine move if it's the engine's turn
    if (game.turn() === color[0]) {
      // If it's the player's turn, recommend a move
      getPieceRecommendation(game.fen());
    } else {
      // If it's the engine's turn, make the engine move
      setTimeout(() => makeEngineMove(), 500); // Delay slightly to simulate engine thinking
    }
  };

  const makeEngineMove = useCallback(() => {
    if (stockfishWorker.current) {
      stockfishWorker.current.postMessage(`position fen ${game.fen()}`);
      stockfishWorker.current.postMessage(`go depth ${strength}`);

      stockfishWorker.current.onmessage = (event) => {
        const message = event.data;
        if (message.startsWith("bestmove")) {
          const move = message.split(" ")[1];
          const from = move.substring(0, 2);
          const to = move.substring(2, 4);

          const engineMove = game.move({ from, to, promotion: "q" });
          if (engineMove) {
            setFen(game.fen()); // Update the FEN state
            setGame(game); // Update the game state
            setMovesHistory((prevHistory) => [...prevHistory, engineMove]);

            // Recommend the next move for the player
            if (game.turn() === color[0]) {
              getPieceRecommendation(game.fen());
            }

            if (game.isCheckmate()) {
              setGameResult(t("HandAndBrain.game.checkmate"));
              setGameOver(true);
              return;
            } else if (game.isStalemate()) {
              setGameResult(t("HandAndBrain.game.stalemate"));
              setGameOver(true);
              return;
            } else if (game.isDraw()) {
              setGameResult(t("HandAndBrain.game.draw"));
              setGameOver(true);
              return;
            } else if (game.isThreefoldRepetition()) {
              setGameResult(t("HandAndBrain.game.threefoldRepetition"));
              setGameOver(true);
              return;
            } else if (game.isInsufficientMaterial()) {
              setGameResult(t("HandAndBrain.game.insufficientMaterial"));
              setGameOver(true);
              return;
            }
          }
        }
      };
    }
  }, [game, stockfishWorker, strength, color, getPieceRecommendation, t]);

  useEffect(() => {
    // Trigger engine move only after the game has been reset and the player is Black
    if (gameReset && color === "black") {
      setTimeout(() => {
        makeEngineMove(); // Engine makes the first move after reset
        setGameReset(false); // Reset the gameReset flag
      }, 500); // Delay the first engine move to simulate thinking
    }
  }, [gameReset, color, makeEngineMove]);

  const onDrop = (sourceSquare, targetSquare) => {
    try {
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // Always promote to a queen for simplicity
      });

      if (move === null) {
        setFen(game.fen()); // Reset to the current valid FEN
        return false;
      }

      setPreviousRecommendedMove(recommendedMove);
      setFen(game.fen());
      setGame(game);
      setMovesHistory((prevHistory) => [...prevHistory, move]);

      if (!isEngineStarted) return true;

      setAlertOpen(true);
      if (move.san === recommendedMove) {
        setAlertColor("success");
      } else {
        setAlertColor("error");
      }

      // Check if it's the engine's turn
      if (
        (color === "white" && game.turn() === "b") ||
        (color === "black" && game.turn() === "w")
      ) {
        setTimeout(makeEngineMove, 500); // Let the engine make its move
      }

      // Game end checks (e.g., checkmate, stalemate, draw)
      if (
        game.isCheckmate() ||
        game.isStalemate() ||
        game.isDraw() ||
        game.isThreefoldRepetition() ||
        game.isInsufficientMaterial()
      ) {
        handleGameOver();
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleGameOver = () => {
    if (game.isCheckmate()) {
      setGameResult(t("HandAndBrain.game.checkmate"));
    } else if (game.isStalemate()) {
      setGameResult(t("HandAndBrain.game.stalemate"));
    } else if (game.isDraw()) {
      setGameResult(t("HandAndBrain.game.draw"));
    } else if (game.isThreefoldRepetition()) {
      setGameResult(t("HandAndBrain.game.threefoldRepetition"));
    } else if (game.isInsufficientMaterial()) {
      setGameResult(t("HandAndBrain.game.insufficientMaterial"));
    }
    setGameOver(true);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title={t("HandAndBrain.header.title")}
        subtitle={t("HandAndBrain.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Hand and Brain</title>
        <meta
          name="description"
          content="The engine suggests which piece to move, but you decide how and where to move it, blending strategy with intuition."
        />
        <meta property="og:title" content="Hand and Brain" />
        <meta
          property="og:description"
          content="The engine suggests which piece to move, but you decide how and where to move it, blending strategy with intuition."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/handandbrain.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/handandbrain`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hand and Brain" />
        <meta
          name="twitter:description"
          content="The engine suggests which piece to move, but you decide how and where to move it, blending strategy with intuition."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/handandbrain.png`}
        />
      </Helmet>
      <Button
        variant="contained"
        onClick={newGame}
        style={{ marginRight: 10 }}
        startIcon={
          <PlayCircleOutlineRoundedIcon
            style={{ color: colors.material[10] }}
          />
        }
      >
        {t("HandAndBrain.buttons.newGame")}
      </Button>
      <Button
        variant="contained"
        onClick={startEngine}
        style={{ marginRight: 10 }}
        startIcon={<MemoryRoundedIcon style={{ color: colors.material[10] }} />}
      >
        {t("HandAndBrain.buttons.startEngine")}
      </Button>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<HelpOutlineIcon style={{ color: colors.material[10] }} />}
      >
        {t("HandAndBrain.buttons.help")}
      </Button>

      <HelpModal
        open={open}
        onClose={handleClose}
        title={t("HandAndBrain.helpDetails.title")}
        content={<HandAndBrainHelp />}
      ></HelpModal>

      {/* Settings Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth // Ensures the dialog uses full width
        maxWidth="sm" // You can choose 'xs', 'sm', 'md', 'lg', 'xl' for predefined sizes or leave out to set custom width using sx
        sx={{
          "& .MuiDialog-paper": {
            width: "400px", // Custom width if needed
            maxWidth: "100%", // Ensures the width is responsive
          },
        }}
      >
        <DialogTitle>{t("HandAndBrain.game.gameSettings")}</DialogTitle>
        <DialogContent>
          {/* Color Selection */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            {t("HandAndBrain.game.playingAs")}
          </Typography>
          <FormControl sx={{ mt: 1 }} fullWidth>
            <Select value={color} onChange={(e) => setColor(e.target.value)}>
              <MenuItem value="white">{t("HandAndBrain.game.white")}</MenuItem>
              <MenuItem value="black">{t("HandAndBrain.game.black")}</MenuItem>
            </Select>
          </FormControl>

          {/* Engine Strength Selection */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            {t("HandAndBrain.game.engineStrength")}
          </Typography>
          <FormControl sx={{ mt: 1 }} fullWidth>
            <Select
              value={strength}
              onChange={(e) => setStrength(e.target.value)}
            >
              {[...Array(20).keys()].map((depth) => (
                <MenuItem key={depth} value={depth + 1}>
                  {depth + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={startEngine}
            variant="contained"
            sx={{
              backgroundColor: "green",
              color: "white",
              "&:hover": {
                backgroundColor: "darkgreen", // optional hover effect
              },
            }}
          >
            {t("HandAndBrain.game.startGame")}
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        mt={2}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box flex={1}>
          <Chessboard
            position={fen}
            onPieceDrop={onDrop}
            boardOrientation={color}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
            areArrowsAllowed={false}
          />
        </Box>

        {/* Right Panel: Moves History and Engine Recommendation */}
        <Box flex={1} ml={{ md: 2, xs: 0 }}>
          {" "}
          {/* No margin-left on small screens */}
          <Typography sx={{ pb: 2 }}>
            {gameOver ? (
              <>
                {t("HandAndBrain.game.gameOver")}
                <b>{gameResult}</b>
              </>
            ) : isEngineStarted ? (
              <>
                {recommendedPiece && (
                  <>
                    {t("HandAndBrain.game.recommendedPiece")}{" "}
                    <b>{recommendedPiece}</b>
                  </>
                )}
              </>
            ) : (
              <>{t("HandAndBrain.game.engineNotStarted")}</>
            )}
          </Typography>
          <Collapse in={alertOpen}>
            <Alert
              severity={alertColor}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setAlertOpen(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {recommendedMove
                ? `${t(
                    "HandAndBrain.game.recommendedMove"
                  )} ${previousRecommendedMove}`
                : t("HandAndBrain.game.waitingRecommendation")}
            </Alert>
          </Collapse>
          <Typography sx={{ pb: 1, pt: 2 }}>
            {movesHistory
              .reduce((acc, move, index) => {
                const moveNumber = Math.floor(index / 2) + 1;
                if (index % 2 === 0) {
                  // White's move (even index)
                  return `${acc} ${moveNumber}. ${move.san}`;
                } else {
                  // Black's move (odd index)
                  return `${acc} ${move.san}`;
                }
              }, "")
              .trim()}
          </Typography>
          {/* Game over message */}
          {gameOver && (
            <h3>
              {t("HandAndBrain.game.gameOver")} {gameResult}
            </h3>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default HandAndBrain;
