import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GuessTheEloHelp from "../../../help/GuessTheEloHelp";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Helmet } from "react-helmet";
import { usergames } from "../../../data/usergames/usergames";
import { trackEvent } from "../../../config/ga";
import { useUser } from "../../../context/UserContext";
import { updateUserData } from "../../../features/Firestore";
import { useShare } from "../../../context/ShareContext";
import { useTranslation } from "react-i18next";

function GuessTheElo() {
  const [game, setGame] = useState(new Chess());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loadedGameData, setLoadedGameData] = useState(null);
  const [moveIndex, setMoveIndex] = useState(0);
  const [pgnMoves, setPgnMoves] = useState([]);
  const [check, setCheck] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [whiteInput, setWhiteInput] = useState("");
  const [blackInput, setBlackInput] = useState("");
  const [timecontrol, setTimecontrol] = useState("");
  const { gameId } = useParams();
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();
  const { t } = useTranslation("Games");

  const handleNewGame = useCallback(
    (gameId) => {
      let game;

      const numericGameId = Number(gameId);
      if (
        !isNaN(numericGameId) &&
        numericGameId >= 0 &&
        numericGameId < usergames.length
      ) {
        game = usergames[numericGameId];
      } else {
        // Otherwise, pick a random game
        let randomNumber = getRandomNumber(usergames.length);
        game = usergames[randomNumber];
      }

      setLoadedGameData(game);
      const newGame = new Chess();
      newGame.loadPgn(game.P);
      let newPgnMoves = newGame.history();
      setPgnMoves(newPgnMoves);
      let timecontrol =
        game.T.split("+")[0] / 60 +
        " minutes + " +
        game.T.split("+")[1] +
        " sec";
      setTimecontrol(timecontrol);

      newGame.reset(); // Reset the Board
      setGame(newGame); // Update the game state with the new game
      setMoveIndex(0); // Reset move index to the beginning

      setCheck(false);
      setGameStarted(true);
      // GA Tracking
      trackEvent("Games", "GuessTheElo-Play", "Guess The Elo");
      // Internal Tracking
      if (userData) {
        if (!userData.Puzzles) {
          userData.Puzzles = {};
        }
        if (userData.Puzzles.GuessTheElo) {
          userData.Puzzles.GuessTheElo.Played =
            (userData.Puzzles.GuessTheElo.Played || 0) + 1;
        } else {
          userData.Puzzles.GuessTheElo = {
            Played: 1,
            Completed: 0,
          };
        }
        updateUserData(userRef, userData);
      }

      setWhiteInput("");
      setBlackInput("");
    },
    [userData, userRef]
  );

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/guesstheelo",
      title: "Chessboard Magic - Guess The Elo",
      description:
        "Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    if (gameId) {
      handleNewGame(gameId);
    }
  }, [gameId, handleNewGame]);

  const handleResetBoard = () => {
    const newGame = new Chess(); // Create a new Chess.js instance
    setGame(newGame); // Update the game state with the new instance
    setMoveIndex(0); // Reset move index to 0
  };

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  const handleWhiteInputChange = (e) => {
    const newValue = e.target.value;
    // Use a regular expression to allow only numeric characters
    if (/^\d*$/.test(newValue)) {
      setWhiteInput(newValue);
    }
  };

  const handleBlackInputChange = (e) => {
    const newValue = e.target.value;
    // Use a regular expression to allow only numeric characters
    if (/^\d*$/.test(newValue)) {
      setBlackInput(newValue);
    }
  };

  const handlePrevMove = useCallback(() => {
    // Go back one move
    if (moveIndex > 0) {
      game.undo();
      setMoveIndex(moveIndex - 1);
    }
  }, [moveIndex, game]);

  const handleNextMove = useCallback(() => {
    if (moveIndex < pgnMoves.length) {
      game.move(pgnMoves[moveIndex]); // Make the next move
      setMoveIndex((prev) => prev + 1);
    }
  }, [moveIndex, pgnMoves, game]);

  useEffect(() => {
    // Add event listeners for left and right arrow keys
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrevMove();
      } else if (e.key === "ArrowRight") {
        handleNextMove();
      }
    };

    // Attach the event listener to the window
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [moveIndex, gameStarted, handleNextMove, handlePrevMove]);

  const handleGuessElo = () => {
    setGameStarted(false);
    setCheck(true);

    let blackGuess = blackInput - loadedGameData.B;
    let whiteGuess = whiteInput - loadedGameData.W;
    // If both guesses are less than 100 away, then it is a success, else failure.
    if (Math.abs(whiteGuess) <= 100 && Math.abs(blackGuess) <= 100) {
      trackEvent("Games", "GuessTheElo-Success", "Guess The Elo");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.GuessTheElo.Completed =
          (userData.Puzzles.GuessTheElo.Completed || 0) + 1;
        updateUserData(userRef, userData);
      }
    } else {
      trackEvent("Games", "GuessTheElo-Failed", "Guess The Elo");
      // Internal Tracking
      if (userData) {
        userData.Puzzles.GuessTheElo.Failed =
          (userData.Puzzles.GuessTheElo.Failed || 0) + 1;
        updateUserData(userRef, userData);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputFocus = () => {
    // Set the focus to the chessboard when an input is focused.
    const chessboard = document.getElementById("chessboard");
    if (chessboard) {
      chessboard.focus();
    }
  };

  useEffect(() => {
    // Start a new game when the component loads
    handleNewGame();
  }, [handleNewGame]);

  return (
    <Box>
      <ContentHeader
        title={t("GuessTheElo.header.title")}
        subtitle={t("GuessTheElo.header.subtitle")}
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Guess The Elo</title>
        <meta
          name="description"
          content="Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo."
        />
        <meta property="og:title" content="Guess The Elo" />
        <meta
          property="og:description"
          content="Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/guesstheelo.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/guesstheelo`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Guess The Elo" />
        <meta
          name="twitter:description"
          content="Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/guesstheelo.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.material[1] }}
            />
          }
        >
          {t("GuessTheElo.buttons.play")}
        </Button>
        <Button
          variant="contained"
          onClick={handleResetBoard}
          style={{ marginRight: 10 }}
          startIcon={
            <RestartAltRoundedIcon style={{ color: colors.material[1] }} />
          }
        >
          {t("GuessTheElo.buttons.reset")}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.material[1] }} />}
        >
          {t("GuessTheElo.buttons.help")}
        </Button>

        <HelpModal
          open={open}
          onClose={handleClose}
          title={t("GuessTheElo.helpDetails.title")}
          content={<GuessTheEloHelp />}
        ></HelpModal>
      </Box>
      <Box
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column", // All screens smaller than 'sm'
            sm: "column", // All screens smaller than 'md'
            md: "row", // Medium screens and larger
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          width: "100%", // Use the full width of the container
        }}
      >
        <div
          id="chessboard"
          style={{
            width: "100%", // Full width in column layout
            maxWidth: "500px", // Maximum width to constrain the chessboard
            padding: "0px 10px 10px 0px", // Uniform padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          <Chessboard
            position={game.fen()}
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
            areArrowsAllowed={false}
            arePiecesDraggable={false}
          />
          <Box
            sx={{
              p: "20px 0px 0px 0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              disabled={!gameStarted}
              variant="contained"
              onClick={handlePrevMove}
              style={{ marginRight: 10 }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
            <Button
              disabled={!gameStarted}
              variant="contained"
              onClick={handleNextMove}
              style={{ marginRight: 10 }}
            >
              <KeyboardDoubleArrowRightIcon />
            </Button>
            <Typography
              sx={{
                color: "#AAAAAA",
                marginTop: "0px",
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              {t("GuessTheElo.game.directions")}
            </Typography>
          </Box>
        </div>

        <div
          id="results"
          style={{
            flex: 1,
            overflow: "auto",
            padding: "0px 0px 10px 0px", // Uniform padding
          }}
        >
          <Button
            variant="contained"
            onClick={handleGuessElo}
            style={{ marginBottom: "10px" }}
            disabled={
              !gameStarted || whiteInput.length === 0 || blackInput.length === 0
            }
          >
            {t("GuessTheElo.buttons.guess")}
          </Button>
          {timecontrol && (
            <Typography
              sx={{
                margin: "0px 0px 10px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon
                sx={{ marginRight: "5px" }}
                style={{ color: colors.black[700] }}
              />
              {timecontrol}
            </Typography>
          )}

          <div>
            <input
              type="text"
              placeholder={t("GuessTheElo.game.white")}
              id="whiteInput"
              style={{ height: "35px", width: "80px", marginRight: "10px" }}
              autoComplete="off"
              value={whiteInput}
              onChange={handleWhiteInputChange}
              onFocus={handleInputFocus}
            />
            {check && (
              <span style={{ marginTop: "5px" }}>
                {t("GuessTheElo.game.white")} Elo: {loadedGameData.W}
                {whiteInput !== "" && (
                  <span> ({whiteInput - loadedGameData.W})</span>
                )}
              </span>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder={t("GuessTheElo.game.black")}
              id="blackInput"
              style={{
                height: "35px",
                marginTop: "10px",
                marginRight: "10px",
                width: "80px",
              }}
              autoComplete="off"
              value={blackInput}
              onChange={handleBlackInputChange}
              onFocus={handleInputFocus}
            />
            {check && (
              <span style={{ marginTop: "5px" }}>
                {t("GuessTheElo.game.black")} Elo: {loadedGameData.B}
                {blackInput !== "" && (
                  <span> ({blackInput - loadedGameData.B})</span>
                )}
              </span>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default GuessTheElo;
