import React from "react";
import { SvgIcon } from "@mui/material";

function ChessableIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15000 16000">
      <g
        transform="translate(-3400,19000) scale(1.1,-1.1)"
        fill="#00"
        stroke="none"
      >
        <path
          d="M7480 15487 c0 -1098 1 -1147 19 -1219 52 -204 184 -345 391 -421 46
-17 87 -21 248 -25 l194 -4 -6 -47 c-3 -25 -128 -1877 -277 -4116 -231 -3457
-275 -4072 -288 -4087 -9 -9 -266 -168 -571 -353 -305 -186 -568 -350 -584
-364 -72 -65 -97 -198 -55 -291 26 -59 53 -86 114 -118 l50 -27 3205 -3 c3569
-2 3266 -9 3353 68 103 91 110 265 12 360 -18 18 -282 189 -586 379 -304 190
-555 353 -560 361 -4 8 -129 1844 -279 4080 -149 2236 -273 4086 -276 4111
l-6 47 194 4 c161 4 202 8 248 25 105 38 165 76 236 147 70 70 106 128 148
236 21 55 21 61 24 1228 l2 1172 -1100 0 -1100 0 0 -885 0 -885 -275 0 -275 0
0 885 0 885 -1100 0 -1100 0 0 -1143z"
        />
        <path
          d="M6519 4081 c-153 -49 -250 -186 -249 -350 1 -158 103 -292 259 -341
63 -20 88 -20 3421 -20 3273 0 3360 0 3419 19 291 90 341 485 83 649 -105 67
195 62 -3516 61 -3210 0 -3364 -1 -3417 -18z"
        />
      </g>
    </SvgIcon>
  );
}

export default ChessableIcon;
